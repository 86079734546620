import { ReactElement } from "react";

import { env } from "@/env";
import { EnvStringType } from "@/types";

interface Props {
  allowedEnvs: EnvStringType[];
  children: ReactElement | ReactElement[] | JSX.Element | JSX.Element[];
}

function WithEnv({ allowedEnvs, children }: Props) {
  //check if the link is eligible for the env
  if ("local" === env.ENV || (allowedEnvs ?? []).includes(env.ENV)) {
    return <>{children}</>;
  }
  return <span />;
}

export default WithEnv;

import { AddCircleOutline } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";

interface Props {
  showAddForm: () => void;
  isReadOnly: boolean;
}

function EnvelopeDocumentsTableHeader({ showAddForm, isReadOnly }: Props) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h2">Documents</Typography>
      </Grid>
      {!isReadOnly && (
        <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
          <Button onClick={showAddForm} variant="contained" endIcon={<AddCircleOutline />}>
            Add
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default EnvelopeDocumentsTableHeader;

import useToast from "@/hooks/useToast";
import { bridgeV2ApiInstance } from "@/services";
import { CREATE_ENVELOPE_RECIPIENT, DELETE_ENVELOPE_RECIPIENT, GET_ENVELOPE_RECIPIENTS_BY_ENVELOPE_ID } from "@/services/routes";
import { EnvelopeRecipient, TemplateFieldAudience } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

export interface CreateEnvelopeRecipientInput {
  audience: TemplateFieldAudience;
  name: string;
  email: string;
}

export interface UseEnvelopeRecipientsHook {
  loading: boolean;
  envelopeRecipients: Array<EnvelopeRecipient>;
  createEnvelopeRecipient: (input: CreateEnvelopeRecipientInput) => Promise<void>;
  deleteEnvelopeRecipient: (envelopeRecipientId: string) => Promise<void>;
}

export const useEnvelopeRecipients = (envelopeId: string): UseEnvelopeRecipientsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [envelopeRecipients, setEnvelopeRecipients] = useState<Array<EnvelopeRecipient>>([]);

  const { addToast } = useToast();

  const getEnvelopeRecipients = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<EnvelopeRecipient>>(GET_ENVELOPE_RECIPIENTS_BY_ENVELOPE_ID(envelopeId));
      setEnvelopeRecipients(response.data);
    } catch (error) {
      const err = mapUnknownToAxiosError(error);
      addToast({
        message: err.response.data.message,
        type: "error",
      });
    }
    setLoading(false);
  };

  const createEnvelopeRecipient = async (input: CreateEnvelopeRecipientInput) => {
    try {
      const response = await bridgeV2ApiInstance.post<EnvelopeRecipient>(CREATE_ENVELOPE_RECIPIENT(envelopeId), input);
      setEnvelopeRecipients([...envelopeRecipients, response.data]);
    } catch (error) {
      const err = mapUnknownToAxiosError(error);
      addToast({
        message: err.response.data.message,
        type: "error",
      });
    }
  };

  const deleteEnvelopeRecipient = async (envelopeRecipientId: string) => {
    try {
      const response = await bridgeV2ApiInstance.delete<EnvelopeRecipient>(DELETE_ENVELOPE_RECIPIENT(envelopeId, envelopeRecipientId));
      setEnvelopeRecipients(envelopeRecipients.filter((envelopeRecipient) => envelopeRecipient.id !== response.data.id));
    } catch (error) {
      const err = mapUnknownToAxiosError(error);
      addToast({
        message: err.response.data.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (envelopeId) getEnvelopeRecipients();
  }, [envelopeId]);

  return {
    loading,
    envelopeRecipients,
    createEnvelopeRecipient,
    deleteEnvelopeRecipient,
  };
};

import { FullScreenLoading, ManualBreadcrumbs } from "@/components/base";
import { FillTemplate } from "@/components/partial";
import { useEnvelope, useEnvelopeStatus, useTemplate, useTemplateFill, useToast } from "@/hooks";
import { EnvelopeStatus, EnvelopeStatusOrder, Person } from "@/types";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  person: Person;
}

function TemplateFillDetail({ person }: Props) {
  const { envelopeId, templateFillId } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { envelope, loading: envelopeLoading } = useEnvelope(envelopeId);
  const { templateFill, loading: templateFillLoading } = useTemplateFill(envelopeId, templateFillId);
  const { template, loading: templateLoading } = useTemplate(templateFill?.templateId);

  const envelopeStatus = useEnvelopeStatus({
    envelope,
  });

  const isReadOnly: boolean = useMemo(() => {
    return EnvelopeStatusOrder[envelopeStatus] >= EnvelopeStatusOrder[EnvelopeStatus.DRAFTED];
  }, [envelopeStatus]);

  useEffect(() => {
    if (!envelope && !envelopeLoading) {
      addToast({
        message: "Envelope not found",
        type: "error",
      });
      navigate("/");
    }
  }, [envelope, envelopeLoading]);

  useEffect(() => {
    if (!templateFill && !templateFillLoading) {
      addToast({
        message: "Document not found",
        type: "error",
      });
      navigate("/");
    }
  }, [templateFill, templateFillLoading]);

  if (envelopeLoading || templateFillLoading || templateLoading) return <FullScreenLoading height="200px" />;

  return (
    <>
      <ManualBreadcrumbs
        isHomeIncluded={false}
        title
        links={[
          {
            title: person.nameFirst + "'s Envelopes",
            url: `/client/${person.id}/envelopes`,
          },
          {
            title: envelope.name,
            url: `/client/${person.id}/envelopes/${envelopeId}`,
          },
          {
            title: templateFill.templateName,
            url: `/client/${person.id}/envelopes/${envelopeId}/${templateFillId}`,
          },
        ]}
      />
      <Box sx={{ mt: 1 }} />
      {template && (
        <FillTemplate
          personId={person.id}
          isReadOnly={isReadOnly}
          templateFill={templateFill}
          envelopeId={envelope.id}
          template={template}
        />
      )}
    </>
  );
}

export default TemplateFillDetail;

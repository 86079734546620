import { useEffect, useState } from "react";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { TemplateFill } from "@/types";
import { bridgeV2ApiInstance } from "@/services";
import { CREATE_TEMPLATE_FILL, GET_TEMPLATE_FILLS_BY_ENVELOPE_ID } from "@/services/routes";

export interface UseTemplateFillsHook {
  templateFills: Array<TemplateFill>;
  loading: boolean;
  createTemplateFill: (templateId: string) => Promise<void>;
}

export const useTemplateFills = (envelopeId: string): UseTemplateFillsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templateFills, setTemplateFills] = useState<Array<TemplateFill>>([]);

  const { addToast } = useToast();

  const getTemplateFills = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<TemplateFill>>(GET_TEMPLATE_FILLS_BY_ENVELOPE_ID(envelopeId));
      setTemplateFills(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const createTemplateFill = async (templateId: string) => {
    try {
      const response = await bridgeV2ApiInstance.post<TemplateFill>(CREATE_TEMPLATE_FILL(envelopeId), {
        templateId,
      });
      setTemplateFills([...templateFills, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    if (envelopeId) {
      getTemplateFills();
    }
  }, [envelopeId]);

  return { templateFills, loading, createTemplateFill };
};

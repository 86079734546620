import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useJourney } from "@/hooks/data-hooks/milestones";
import ClientPath from "./ClientPath";
import { ClientJourneyDtoModel, JourneyDtoModel, JourneyPathDtoModel } from "@/types";
import { useEffect, useMemo, useState } from "react";
import JourneyStepper from "./JourneyStepper";

interface Props {
  clientJourney: ClientJourneyDtoModel;
}

const ClientJourney = ({ clientJourney }: Props) => {
  const { journey, refetchJourney } = useJourney(clientJourney.id);

  const onMilestoneUpdate = async () => {
    await refetchJourney();
  };

  const toKeyedPath = (path: JourneyPathDtoModel): { key: string; path: JourneyPathDtoModel } => {
    return {
      key: path.map((milestone) => milestone.id).join("-"),
      path: path,
    };
  };

  const theme = useTheme();

  const [currentPath, setCurrentPath] = useState<JourneyPathDtoModel>([]);
  const [journeyHistory, setJourneyHistory] = useState<JourneyDtoModel>([]);

  useEffect(() => {
    if (journey?.length) {
      setCurrentPath(journey[0]);
      setJourneyHistory(journey.slice(1));
    }
  }, [journey]);

  const keyedCurrentPath = useMemo(() => {
    return toKeyedPath(currentPath);
  }, [currentPath]);

  const keyedHistoricalPath = useMemo(() => {
    return journeyHistory.map((path) => toKeyedPath(path));
  }, [journeyHistory]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ border: "0" }}>
          <JourneyStepper previousPath={journeyHistory[0]} currentPath={currentPath} key={keyedCurrentPath.key} />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box sx={{ width: "100%", padding: theme.spacing(2), borderRadius: 1, display: "flex", backgroundColor: "white" }}>
              <Typography variant="h3">Next Steps</Typography>
            </Box>
            <ClientPath
              path={keyedCurrentPath.path}
              clientJourney={clientJourney}
              onMilestoneUpdate={onMilestoneUpdate}
              key={keyedCurrentPath.key}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%", padding: theme.spacing(2), borderRadius: 1, backgroundColor: "white" }}>
            <Typography variant="h3">Journey History</Typography>
          </Box>
          {journeyHistory ? (
            keyedHistoricalPath.map(({ key, path }, i) => {
              return (
                <Grid item xs={12} key={key}>
                  <ClientPath path={path} clientJourney={clientJourney} onMilestoneUpdate={onMilestoneUpdate} />
                </Grid>
              );
            })
          ) : (
            <Typography>No journeys</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientJourney;

export enum Field {
  NAME = "name",
  ADDRESS_STREET = "addressStreet",
  ADDRESS_UNIT = "addressUnit",
  ADDRESS_CITY = "addressCity",
  ADDRESS_STATE = "addressState",
  ADDRESS_ZIP = "addressZip",
  PHONE = "phone",
  EMAIL = "email",
  WEBSITE = "website",
}

import { IconHome2, IconSettings } from "@tabler/icons";
import { ReactElement } from "react";

export enum PropertyTab {
  OVERVIEW = "overview",
  MANAGEMENT = "management",
  PAYMENTS = "payments",
}

export interface PropertyTabItem {
  label: string;
  value: PropertyTab;
  icon?: ReactElement;
}

export const tabItems: Array<PropertyTabItem> = [
  {
    label: "Overview",
    value: PropertyTab.OVERVIEW,
    icon: <IconHome2 />,
  },
  {
    label: "Property Management",
    value: PropertyTab.MANAGEMENT,
    icon: <IconSettings />,
  },
  {
    label: "Payment Information",
    value: PropertyTab.PAYMENTS,
    icon: <IconSettings />,
  },
];

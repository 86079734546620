// third-party
import { IconAffiliate, IconHome, IconUser } from "@tabler/icons";
import { NavItemType } from "@/types";
import { env } from "@/env";

const oldBridge: NavItemType = {
  id: "old-bridge",
  title: <span>Legacy Bridge</span>,
  type: "group",
  children: [
    {
      id: "LegacyBridgeClients",
      title: <span>Clients</span>,
      type: "item",
      url: `https://${!env.isProdEnv ? "dev-" : ""}bridge.landis.com/clients`,
      icon: IconUser,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "LegacyBridgeProperties",
      title: <span>Properties</span>,
      type: "item",
      url: `https://${!env.isProdEnv ? "dev-" : ""}bridge.landis.com/properties`,
      icon: IconHome,
      breadcrumbs: true,
      external: true,
    },
    {
      id: "LegacyBridgePartners",
      title: <span>Partners</span>,
      type: "item",
      url: `https://${!env.isProdEnv ? "dev-" : ""}bridge.landis.com/partners`,
      icon: IconAffiliate,
      breadcrumbs: true,
      external: true,
    },
  ],
};

export default oldBridge;

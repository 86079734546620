import { TableLayout } from "@/components/layout";
import { StateCode, Tax } from "@/types";
import { formatAddress } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TaxForm, { TaxTypeDisplayMap } from "./TaxForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import TaxesTableHeader from "./TaxesTableHeader";
import { UseTaxes } from "@/hooks";

interface Props {
  useTaxesInstance: UseTaxes;
  propertyId: string;
}

const TaxesList = ({ useTaxesInstance, propertyId }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { propertyTaxes } = useTaxesInstance;
  const [selectedTax, setSelectedTax] = useState<Tax>(null);

  function handleClick(row: Tax) {
    setDrawerOpen(true);
    setSelectedTax(row);
  }
  return (
    <>
      <TableLayout maxHeight="70vh" title={<TaxesTableHeader useTaxesInstance={useTaxesInstance} propertyId={propertyId} title="Taxes" />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Parcel Number</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Remit To</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Website</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyTaxes.map((row) => (
              <TableRow sx={{ width: "100%" }} tabIndex={0} hover key={row.id}>
                <TableCell>{row.parcelNumber}</TableCell>
                <TableCell>{TaxTypeDisplayMap[row.type]}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {formatAddress(row.addressStreet, null, row.addressCity, row.addressZip, row.addressState as StateCode)}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.website}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <TaxForm close={() => setDrawerOpen(false)} tax={selectedTax} useTaxesInstance={useTaxesInstance} propertyId={propertyId} />
        </Box>
      </Drawer>
    </>
  );
};

export default TaxesList;

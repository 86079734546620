import { Market } from "@l4s/qualification-models";

interface SimpleMarketToFormat {
  name: Market["name"];
  state: Market["state"];
}

export function formatMarket(market: SimpleMarketToFormat) {
  return `${market.name}, ${market.state}`;
}

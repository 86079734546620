import { Grid } from "@mui/material";
import { useFinancialQuestionnaire, useHousingQuestionnaire, useToast } from "@/hooks";
import ClientFinancialQuestionnaire from "./FinancialQuestionnaires/ClientFinancialQuestionnaire";
import { useEffect } from "react";
import ClientHousingQuestionnaire from "./ClientHousingQuesitonnaire";
import { mapUnknownToError } from "@/utils";
import { MainCard } from "@/components/berry";
import { TypeOfLandisProgram } from "@l4s/program-models";
import { ProgramClient } from "@/types";

interface Props {
  applicationId: string;
  programClient: ProgramClient;
}

const ClientApplication = ({ applicationId, programClient }: Props) => {
  const { financialQuestionnaire, getFinancialQuestionnaire, isFinancialQuestionnaireLoading } = useFinancialQuestionnaire(applicationId);
  const { housingQuestionnaire, getHousingQuestionnaire, isHousingQuestionnaireLoading } = useHousingQuestionnaire(applicationId);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      getFinancialQuestionnaire();
      getHousingQuestionnaire();
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <MainCard title="Financial Questionnaire">
          {isFinancialQuestionnaireLoading ? (
            "Loading..."
          ) : financialQuestionnaire ? (
            <ClientFinancialQuestionnaire financialQuestionnaire={financialQuestionnaire} programClient={programClient} />
          ) : (
            "No Financial Questionnaire"
          )}
        </MainCard>
      </Grid>
      {programClient.programDetails.type !== TypeOfLandisProgram.COACHING_ONLY && (
        <Grid item>
          <MainCard title="Housing Questionnaire">
            {isHousingQuestionnaireLoading ? (
              "Loading..."
            ) : housingQuestionnaire ? (
              <ClientHousingQuestionnaire housingQuestionnaire={housingQuestionnaire} />
            ) : (
              "No Housing Questionnaire"
            )}
          </MainCard>
        </Grid>
      )}
    </Grid>
  );
};

export default ClientApplication;

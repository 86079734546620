import { bridgeV2ApiInstance, routes } from "@/services";
import { HousingQuestionnaireDtoModel } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseHousingQuestionnaire {
  housingQuestionnaire: HousingQuestionnaireDtoModel;
  getHousingQuestionnaire: () => Promise<void>;
  isHousingQuestionnaireLoading: boolean;
}

export const useHousingQuestionnaire = (applicationid: string): UseHousingQuestionnaire => {
  const [housingQuestionnaire, setHousingQuestionnaire] = useState<HousingQuestionnaireDtoModel>();
  const [isHousingQuestionnaireLoading, setIsLoading] = useState<boolean>(false);

  const getHousingQuestionnaire = async () => {
    setIsLoading(true);
    try {
      setHousingQuestionnaire(undefined);
      const response = await bridgeV2ApiInstance.get<HousingQuestionnaireDtoModel>(routes.GET_HOUSING_QUESTIONNAIRE(applicationid));
      setHousingQuestionnaire(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching housing questionnaire details.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    housingQuestionnaire,
    getHousingQuestionnaire,
    isHousingQuestionnaireLoading,
  };
};

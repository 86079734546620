import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useTheme } from "@mui/material/styles";
import { HoverDataCard } from "@/components/berry";
import moment from "moment";
import { formatPercentage } from "@/utils";
import { SvgIconComponent } from "@mui/icons-material";

export interface DataPoint {
  x: Date;
  y: number;
}

interface Props {
  data?: DataPoint[];
  suppliedIcon?: SvgIconComponent;
  cardTitle: string;
  inquiryCount?: number;
}

interface DataCardOptions {
  text: string;
  icon: typeof HorizontalRuleIcon;
  color: string;
  primaryValue: number;
}

function findFirstDataPointOlderThan30Days(data: DataPoint[]): DataPoint | null {
  let dataPoint: DataPoint | null = null;

  const priorDate = new Date(moment(moment().subtract(30, "days")).format());

  for (let dp of data) {
    if (new Date(dp.x) < priorDate) {
      dataPoint = dp;
      return dataPoint;
    }
  }

  return dataPoint;
}

function CreditDashHoverDataCard({ data, suppliedIcon, cardTitle, inquiryCount }: Props) {
  const theme = useTheme();

  function getIconTextAndColor(): DataCardOptions {
    if (data) {
      if (data?.length >= 2) {
        const mostRecentFigure = data[0].y;
        const previousFigure = findFirstDataPointOlderThan30Days(data)?.y;

        if (!previousFigure) {
          return {
            text: "Not enough data",
            icon: HorizontalRuleIcon,
            color: theme.palette.primary.dark,
            primaryValue: data[0] ? data[0].y : 0,
          };
        }

        if (mostRecentFigure === previousFigure) {
          return {
            text: "No change in last 30 days",
            icon: HorizontalRuleIcon,
            color: theme.palette.primary.dark,
            primaryValue: data[0] ? data[0].y : 0,
          };
        }

        if (mostRecentFigure > previousFigure) {
          const relativeChange = formatPercentage((mostRecentFigure - previousFigure) / previousFigure);

          return {
            text: `${relativeChange} increase in last 30 days`,
            icon: ArrowUpwardIcon,
            color: theme.palette.success.dark,
            primaryValue: data[0] ? data[0].y : 0,
          };
        }

        if (mostRecentFigure < previousFigure) {
          const relativeChange = formatPercentage((mostRecentFigure - previousFigure) / previousFigure);

          return {
            text: `${relativeChange} decrease in last 30 days`,
            icon: ArrowDownwardIcon,
            color: theme.palette.error.main,
            primaryValue: data[0] ? data[0].y : 0,
          };
        }
      }

      return {
        text: "Not enough data",
        icon: HorizontalRuleIcon,
        color: theme.palette.primary.dark,
        primaryValue: data[0] ? data[0].y : 0,
      };
    } else {
      if (inquiryCount === 0) {
        return {
          text: "No change in last 30 days",
          icon: HorizontalRuleIcon,
          color: theme.palette.primary.dark,
          primaryValue: 0,
        };
      } else {
        return {
          text: "In Past 30 days",
          icon: ArrowUpwardIcon,
          color: theme.palette.primary.dark,
          primaryValue: inquiryCount,
        };
      }
    }
  }
  const { icon, text, color, primaryValue } = getIconTextAndColor();
  return (
    <>
      <HoverDataCard
        title={cardTitle}
        iconPrimary={suppliedIcon ? suppliedIcon : icon}
        primary={primaryValue}
        secondary={text}
        color={color}
      />
    </>
  );
}

export default CreditDashHoverDataCard;

import { bridgeV2ApiInstance } from "@/services";
import { GET_PROPERTY, UPDATE_PROPERTY_ADDRESS, UPDATE_PROPERTY_HOME_DETAILS } from "@/services/routes";
import { PropertyDto, StateCode, TypeOfProperty } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

export interface UpdateAddressInput {
  addressStreet: string;
  addressUnit: string | null;
  addressCity: string;
  addressState: StateCode;
  addressZip: string;
}

export interface UpdateHomeDetailsInput {
  beds: number | null;
  baths: number | null;
  sqft: number | null;
  built: number | null;
  style: string | null;
  lotSizeAcre: number | null;
  county: string | null;
  hoaName: string | null;
  type: TypeOfProperty | null;
  hvacFurnaceAge: number | null;
  hvacAcAge: number | null;
  hvacLastServicedDate: Date | null;
  approximateRoofAgeInYears: number | null;
  evidenceOfWaterInstrusionFromSD: boolean | null;
  specialFloodHazardArea: string | null;
  evidenceOfDamageFromWildlifeIncludingFungi: boolean | null;
  leadPaintPresent: boolean | null;
  leadDisclosureReceived: boolean | null;
  hoaRestrictionsOnRentals: string | null;
  waterHeaterAge: number | null;
  stoveRangeAge: number | null;
  refrigeratorAge: number | null;
  dishwasherAge: number | null;
}

export interface UsePropertyHook {
  loading: boolean;
  property: PropertyDto | undefined;
  error: string | undefined;
  updateAddress: (input: UpdateAddressInput) => Promise<void>;
  updateHomeDetails: (input: UpdateHomeDetailsInput) => Promise<void>;
}

export const useProperty = (propertyId: string): UsePropertyHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [property, setProperty] = useState<PropertyDto>();
  const [error, setError] = useState<string>();

  const getProperty = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await bridgeV2ApiInstance.get<PropertyDto>(GET_PROPERTY(propertyId));
      setProperty(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property.");
    }
    setLoading(false);
  };

  const updateAddress = async (input: UpdateAddressInput) => {
    try {
      const response = await bridgeV2ApiInstance.put<PropertyDto>(UPDATE_PROPERTY_ADDRESS(property.id), input);
      setProperty({ ...property, ...response.data });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating address.");
    }
  };

  const updateHomeDetails = async (input: UpdateHomeDetailsInput) => {
    try {
      const response = await bridgeV2ApiInstance.put<PropertyDto>(UPDATE_PROPERTY_HOME_DETAILS(property.id), input);
      setProperty({ ...property, ...response.data });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating home details.");
    }
  };

  useEffect(() => {
    if (propertyId) getProperty();
  }, [propertyId]);

  return {
    property,
    loading,
    error,
    updateAddress,
    updateHomeDetails,
  };
};

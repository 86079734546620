import { createSlice } from "@reduxjs/toolkit";
import { AuthenticatedAccount } from "@/types";

const initialState: AuthenticatedAccount | null = null;

const authenticatedAccount = createSlice({
  name: "authenticatedAccount",
  initialState,
  reducers: {
    setAuthenticatedAccount(_, action) {
      return action.payload;
    },
    resetAuthenticatedAccount() {
      return null;
    },
  },
});

export const authenticatedAccountReducer = authenticatedAccount.reducer;

export const { setAuthenticatedAccount, resetAuthenticatedAccount } = authenticatedAccount.actions;

export enum FinancialQuestionnaireTypeOfIncome {
  SALARIED = "SALARIED",
  CONTRACT = "CONTRACT",
  SELF_EMPLOYED = "SELF_EMPLOYED",
}

export interface FinancialQuestionnaireDtoModel {
  applicationId: string;
  industry: string | null;
  hasUnrecordedIncome: boolean | null;
  hasSideHustles: boolean | null;
  isIncomeSeasonal: boolean | null;
  mortgageFamiliarityScale: number | null;
  hasCheckingAccount: boolean | null;
  checkingAccountsCount: number | null;
  hasSavingsAccount: boolean | null;
  savingsAccountsCount: number | null;
  keepsBudget: boolean | null;
  isInterestedInBudget: boolean | null;
  budgetToolName: string | null;
  monthlyChildcareCostDollars: number | null;
  monthlyInsuranceCostDollars: number | null;
  noteForCoach: string | null;
  questionForCoach: string | null;
  typesOfIncome: Array<FinancialQuestionnaireTypeOfIncome>;
  monthlyTakeHomePayDollars: number | null;
  hasPurchaseHistory: boolean | null;
  taxesFiledYear: string | null;
}

export enum HousingQuestionnaireAgeOfHome {
  FIVE_YEARS_OLD_OR_OLDER = "FIVE_YEARS_OLD_OR_OLDER",
  NEWER_THAN_FIVE_YEARS_OLD = "NEWER_THAN_FIVE_YEARS_OLD",
  NO_PREFERENCE = "NO_PREFERENCE",
}

export enum HousingQuestionnaireKindOfHome {
  ONE_STORY = "ONE_STORY",
  TWO_STORY_SPLIT = "TWO_STORY_SPLIT",
  FOYER = "FOYER",
  BI_LEVEL = "BI_LEVEL",
  TRI_LEVEL = "TRI_LEVEL",
  TOWN_HOUSE_OR_CONDO = "TOWN_HOUSE_OR_CONDO",
  MOBILE_HOME = "MOBILE_HOME",
  NO_PREF = "NO_PREF",
}

export enum HousingQuestionnaireStyleOfHome {
  CONTEMPORARY = "CONTEMPORARY",
  TRADITIONAL = "TRADITIONAL",
  COLONIAL = "COLONIAL",
  SOUTHWESTERN = "SOUTHWESTERN",
  NO_PREF = "NO_PREF",
}

export enum HousingQuestionnaireRenovationEffort {
  NONE = "NONE",
  LITTLE = "LITTLE",
  LOT = "LOT",
}

export enum HousingQuestionnaireDesire {
  MUST = "MUST",
  LIKE = "LIKE",
}

export enum HousingQuestionnaireBaths {
  ONE = "ONE",
  ONE_POINT_FIVE = "ONE_POINT_FIVE",
  TWO = "TWO",
  TWO_POINT_FIVE = "TWO_POINT_FIVE",
  THREE = "THREE",
  THREE_POINT_FIVE = "THREE_POINT_FIVE",
  FOUR = "FOUR",
  FOUR_POINT_FIVE = "FOUR_POINT_FIVE",
  FIVE = "FIVE",
}

export interface HousingQuestionnaireDtoModel {
  applicationId: string;
  partOfTown: string | null;
  priceRangeMinDollars: number | null;
  priceRangeMaxDollars: number | null;
  isSchoolFactor: boolean | null;
  schoolFactorDetails: string | null;
  interestedHomeAge: HousingQuestionnaireAgeOfHome | null;
  interestedHomeKinds: Array<HousingQuestionnaireKindOfHome>;
  interestedHomeStyles: Array<HousingQuestionnaireStyleOfHome>;
  renovationEffort: HousingQuestionnaireRenovationEffort | null;
  desiresClosePublicTransit: boolean | null;
  hasPhysicalNeeds: boolean | null;
  requiresSpecialPetFacilities: boolean | null;
  largeYard: HousingQuestionnaireDesire | null;
  smallYard: HousingQuestionnaireDesire | null;
  fence: HousingQuestionnaireDesire | null;
  garage: HousingQuestionnaireDesire | null;
  carport: HousingQuestionnaireDesire | null;
  patioDeck: HousingQuestionnaireDesire | null;
  pool: HousingQuestionnaireDesire | null;
  outdoorSpa: HousingQuestionnaireDesire | null;
  extraParking: HousingQuestionnaireDesire | null;
  otherBuildings: HousingQuestionnaireDesire | null;
  specialView: HousingQuestionnaireDesire | null;
  requiredBedroomCount: number | null;
  desiredBedroomCount: number | null;
  baths: HousingQuestionnaireBaths | null;
  sizeMinSqft: number | null;
  sizeMaxSqft: number | null;
  centralAir: HousingQuestionnaireDesire | null;
  hardwoodFloors: HousingQuestionnaireDesire | null;
  wallToWallCarpet: HousingQuestionnaireDesire | null;
  basement: HousingQuestionnaireDesire | null;
  laundry: HousingQuestionnaireDesire | null;
  updatedAppliances: HousingQuestionnaireDesire | null;
  walkInClosets: HousingQuestionnaireDesire | null;
  openFloorPlan: HousingQuestionnaireDesire | null;
}

import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { AddressFormNoValidation } from "@/components/partial/AddressFormNoValidation";
import { useToast, UseUtilities } from "@/hooks";
import { TypeOfUtility, Utility } from "@/types/PropertyManagement";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

interface Props {
  close: () => void;
  utility?: Utility;
  useUtilitiesInstance: UseUtilities;
  propertyId: string;
}

export const UtilityTypeDisplayMap: Record<TypeOfUtility, string> = {
  [TypeOfUtility.GARBAGE]: "Garbage",
  [TypeOfUtility.ELECTRIC]: "Electric",
  [TypeOfUtility.GAS]: "Gas",
  [TypeOfUtility.SEWAGE]: "Sewage",
  [TypeOfUtility.WATER]: "Water",
};

function UtilityForm({ utility, useUtilitiesInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createUtility, updateUtility } = useUtilitiesInstance;

  const formTitle = () => (utility?.id ? "Update utility" : "Add utility");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure().required("Name is a required field"),
    website: Yup.string().url().ensure().optional(),
    type: Yup.mixed().oneOf(Object.values(TypeOfUtility)).required("Type is a required field"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: utility?.name ?? "",
      addressStreet: utility?.addressStreet ?? "",
      addressUnit: "",
      addressCity: utility?.addressCity ?? "",
      addressState: utility?.addressState ?? undefined,
      addressZip: utility?.addressZip ?? "",
      phone: utility?.phone ?? "",
      email: utility?.email ?? "",
      website: utility?.website ?? "",
      type: utility?.type ?? ("" as TypeOfUtility),
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (utility) {
          await updateUtility({
            ...values,
            id: utility.id,
            updated: String(Date.now()),
          });
          addToast({ message: "Successfully updated utility" });
          close();
        } else {
          await createUtility({
            ...values,
            propertyId,
          });
          addToast({ message: "Successfully created utility" });
          close();
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              label="Name"
              id="name"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <Grid item>
            <Dropdown
              id="type"
              label="Type Of Utility"
              size="small"
              items={Object.values(TypeOfUtility).map((type) => ({
                label: <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>{UtilityTypeDisplayMap[type]}</div>,
                value: type,
              }))}
              value={formik.values.type}
              handleChange={(value) => formik.setFieldValue("type", value)}
              error={!!formik.errors.type}
              helperText={formik.errors.type}
            />
          </Grid>
          <AddressFormNoValidation formik={formik}></AddressFormNoValidation>
          <Grid item>
            <TextField
              id="phone"
              label="Phone"
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 10 }}
              type="tel"
              error={!!formik.errors.phone}
              helperText={formik.errors.phone}
            />
          </Grid>
          <Grid item>
            <TextField
              id="email"
              label="Email"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              type="email"
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item>
            <TextField
              id="website"
              label="Website"
              value={formik.values.website}
              onChange={(e) => formik.setFieldValue("website", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.website}
              helperText={formik.errors.website}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default UtilityForm;

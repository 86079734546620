import { Route, Routes, useParams } from "react-router-dom";

import { NotFoundPage } from "@/components/view";
import { WithPerson } from "@/components/providers";
import { Person } from "@/types";
import { ProgramClientRouterWithProgramClient, ProgramClientRouterWithoutProgramClient } from "./ProgramClientRouter";

const ClientRouter = (props: { person: Person }) => {
  return (
    <Routes>
      <Route path="/" element={<ProgramClientRouterWithoutProgramClient {...props} />} />
      <Route path="/program-client/:programClientId/*" element={<ProgramClientRouterWithProgramClient {...props} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const ClientRouterWithPerson = () => {
  const { personId } = useParams();
  return WithPerson({ personId, ChildComponent: ClientRouter });
};

export default ClientRouterWithPerson;

import { ListApplicationPropertyDto } from "@/types";
import { StatusOfApplicationProperty } from "@l4s/client-property-models";
import { sortByDate } from "./sort";

export function sortApplicationPropertiesByPriority(first: ListApplicationPropertyDto, second: ListApplicationPropertyDto): -1 | 0 | 1 {
  const applicationPropertiesStatusPriorities: Record<StatusOfApplicationProperty, number> = {
    [StatusOfApplicationProperty.ELIGIBILITY_PENDING]: 0,
    [StatusOfApplicationProperty.ELIGIBLE]: 2,
    [StatusOfApplicationProperty.INELIGIBLE]: 2,
    [StatusOfApplicationProperty.OFFER_REJECTED]: 5,
    [StatusOfApplicationProperty.OFFER_REQUESTED]: 7,
    [StatusOfApplicationProperty.OFFER_PENDING]: 7,
    [StatusOfApplicationProperty.OFFER_COUNTERED]: 7,
    [StatusOfApplicationProperty.UNDER_CONTRACT]: 8,
    [StatusOfApplicationProperty.CLOSED]: 10,
    [StatusOfApplicationProperty.ON_MARKET_TO_SELL]: 15,
    [StatusOfApplicationProperty.ACTIVE_RENTAL]: 16,
    [StatusOfApplicationProperty.ON_MARKET_TO_RENT]: 17,
    [StatusOfApplicationProperty.VACANT]: 18,
    [StatusOfApplicationProperty.SOLD_TO_CLIENT]: 20,
    [StatusOfApplicationProperty.SOLD_TO_OTHER]: 20,
    [StatusOfApplicationProperty.TERMINATED]: 20,
    [StatusOfApplicationProperty.ZOMBIE]: 100,
  };

  if (applicationPropertiesStatusPriorities[first.status] < applicationPropertiesStatusPriorities[second.status]) {
    return -1;
  }

  if (applicationPropertiesStatusPriorities[first.status] > applicationPropertiesStatusPriorities[second.status]) {
    return 1;
  }

  return sortByDate<ListApplicationPropertyDto>("created", false)(first, second);
}

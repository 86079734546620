import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery } from "@mui/material";

import { Chip } from "@/components/berry";
import { LogoSection } from "@/components/partial";
import { LAYOUT_CONST } from "@/constant";
import { env } from "@/env";
import useConfig from "@/hooks/useConfig";
import { useDispatch, useSelector } from "@/store";
import { openMenuDrawer } from "@/store/slices/menu";

import ProfileSection from "./ProfileSection";

import { IconMenu2 } from "@tabler/icons";
import PersonSearchSection from "./PersonSearchSection";

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="div" sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
          <Box component="div" sx={{ paddingTop: 1 }}>
            <LogoSection />
            {env.ENV !== "prd" && (
              <Box component="div" sx={{ position: "relative" }}>
                <Box component="div" sx={{ position: "absolute", left: "104px", top: "-24px" }}>
                  <Chip label={`${env.ENV}`} size="small" chipcolor={"primary"} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.light,
              color: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
                color: theme.palette.mode === "dark" ? theme.palette.secondary.light : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openMenuDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        )}
      </Box>

      {/* header search */}
      <PersonSearchSection />

      <Box sx={{ flexGrow: 1 }} />

      {/* mega-menu
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <MegaMenuSection />
      </Box>
      */}

      {/* localization
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <LocalizationSection />
      </Box>
      */}

      {/* notification
      <NotificationSection />
      */}

      {/* profile */}
      <ProfileSection />

      {/* mobile header
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
       */}
    </>
  );
};

export default Header;

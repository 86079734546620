import { useEffect, useMemo, useState } from "react";
import { Grid, Chip, Box, Button, useTheme, CardActions, Divider } from "@mui/material";
import { Grid3x3TwoTone, AlternateEmailTwoTone, LockTwoTone, PhoneIphoneTwoTone, House } from "@mui/icons-material";

import { MainCard } from "@/components/berry";
import { HeaderOption, Drawer } from "@/components/base";
import { WithEnv } from "@/components/providers";
import { ClientPersonNote } from "@/components/partial";

import { impersonateClient } from "@/hooks/data-hooks/Impersonation";
import { useSelector } from "@/store";
import { AuthenticatedAccount, Person, ProgramClient } from "@/types";
import { formatAddress } from "@/utils";
import { useRelatedProgramClientByProgramClientApplicationId } from "@/hooks";
import { useNavigate } from "react-router-dom";

interface Props {
  person: Person;
  programClient: ProgramClient;
  tabs?: JSX.Element;
}

function ClientPersonHeader({ person, programClient, tabs }: Props) {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const authenticatedAccount = useSelector<AuthenticatedAccount | null>((state) => state.authenticatedAccount);
  const employeeAccountId = authenticatedAccount?.accountId;
  const theme = useTheme();

  const navigate = useNavigate();

  const { relatedProgramClientByApplicationId, getRelatedProgramClientByApplicationId } =
    useRelatedProgramClientByProgramClientApplicationId(programClient);

  useEffect(() => {
    if (programClient) getRelatedProgramClientByApplicationId();
  }, [programClient]);

  const formattedAddress = useMemo(() => {
    if (!person.property) return null;
    return formatAddress(
      person.property.addressStreet,
      person.property.addressUnit,
      person.property.addressCity,
      person.property.addressZip,
      person.property.addressState
    );
  }, [person]);

  return (
    <>
      <MainCard>
        <Grid container columnSpacing={4}>
          <HeaderOption
            variant={"h2"}
            value={person.nameFirst + " " + person.nameLast}
            RightComponent={
              <Box sx={{ marginLeft: "6px", paddingTop: "6px", display: "none" }}>
                <Chip
                  label={`Payment Plan`}
                  size="small"
                  sx={{
                    background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.orange.light,
                    color: theme.palette.orange.dark,
                  }}
                />
              </Box>
            }
          />
          <HeaderOption value={person.id} Icon={<Grid3x3TwoTone fontSize="small" />} allowCopy={true} />
          {person.email && <HeaderOption value={person.email} Icon={<AlternateEmailTwoTone fontSize="small" />} allowCopy={true} />}
          {person.phone && <HeaderOption value={person.phone} Icon={<PhoneIphoneTwoTone fontSize="small" />} allowCopy={true} />}
          {person.property && <HeaderOption value={formattedAddress} Icon={<House fontSize="small" />} allowCopy={true} />}
          {person.ssn && <HeaderOption value={`XXX-XX-${person.ssn}`} Icon={<LockTwoTone fontSize="small" />} />}
        </Grid>
        <WithEnv allowedEnvs={["local", "dev", "prd"]}>
          <Box sx={{ margin: "12px -12px -12px" }}>
            <Divider />
            <CardActions>
              {employeeAccountId ? (
                <Button variant="contained" size="small" onClick={() => impersonateClient(employeeAccountId, person.accountId)}>
                  Impersonate Client
                </Button>
              ) : (
                <span>Loading Impersonation</span>
              )}
              {relatedProgramClientByApplicationId ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    navigate(
                      `/client/${relatedProgramClientByApplicationId.personId}/program-client/${relatedProgramClientByApplicationId.id}`
                    )
                  }
                >
                  View Related Person
                </Button>
              ) : null}
              {tabs}
            </CardActions>
          </Box>
        </WithEnv>
      </MainCard>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <ClientPersonNote />
      </Drawer>
    </>
  );
}

export default ClientPersonHeader;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { HocData, ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseHocData {
  loadHocData: () => Promise<void>;
  hocData: HocData | undefined;
}

export const useHocData = (programClient: ProgramClient): UseHocData => {
  const [hocData, setHocData] = useState<HocData>();

  const loadHocData = async () => {
    try {
      const response: { data: HocData | undefined } = await bridgeV2ApiInstance.get(
        routes.GET_OPTIONAL_HOC_DATA_BY_PROGRAM_CLIENT_ID(programClient.id)
      );
      setHocData(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching hoc data.");
    }
  };

  return {
    hocData,
    loadHocData,
  };
};

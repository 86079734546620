import { MainCard } from "@/components/berry";
import { UseCoachingHistory } from "@/hooks";
import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import NoCoachingHistory from "./NoCoachingHistory";
import CoachingHistoryRow from "./CoachingHistoryRow";

interface Props {
  useCoachingHistory: UseCoachingHistory;
}

function CoachingHistory({ useCoachingHistory }: Props) {
  const { coachingHistory, isLoading } = useCoachingHistory;

  return (
    <MainCard title="Coaching History">
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || !coachingHistory?.length ? (
                <NoCoachingHistory isLoading={isLoading} />
              ) : (
                coachingHistory?.map((cH) => (
                  <CoachingHistoryRow key={cH.id} useCoachingHistory={useCoachingHistory} coachingHistoryRecord={cH} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </MainCard>
  );
}

export default CoachingHistory;

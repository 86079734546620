export enum Field {
  RESPONSIBLE_PARTY = "responsibleParty",
  PAYMENT_METHOD = "paymentMethod",
  PAYMENT_FREQUENCY = "paymentFrequency",
  INITIAL_PAYMENT_DATE = "initialPaymentDate",
  START_DATE = "startDate",
  END_DATE = "endDate",
  HOA_ID = "hoaId",
  ACCOUNT_ID = "externalAccountId",
  DUES_CENTS = "duesCents",
  PROPERTY_MANAGEMENT_COMPANY_ID = "propertyManagementCompanyId",
}

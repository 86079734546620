import { Grid, Typography, useTheme } from "@mui/material";
import { FinancialQuestionnaireDtoModel } from "@/types";
import { displayUsDollars } from "@/utils";
import { isBoolean } from "lodash";
import { displayYesOrNo, titleCase } from "../utis";

interface Props {
  financialQuestionnaire: FinancialQuestionnaireDtoModel;
}

const RentToOwnFinancialQuestionnaire = ({ financialQuestionnaire }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} sx={{ padding: theme.spacing(2) }}>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Industry</Typography>
          <Typography>{financialQuestionnaire?.industry || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Unrecorded Income</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasUnrecordedIncome)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Side Hustles</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasSideHustles)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Is Income Seasonal</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.isIncomeSeasonal)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Mortgage Familiarity Scale</Typography>
          <Typography>
            {financialQuestionnaire?.mortgageFamiliarityScale ? financialQuestionnaire.mortgageFamiliarityScale : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Checking Account</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasCheckingAccount)}</Typography>
        </Grid>
        {financialQuestionnaire?.hasCheckingAccount ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Checking Accounts Count</Typography>
            <Typography>{financialQuestionnaire?.checkingAccountsCount ? financialQuestionnaire.checkingAccountsCount : "-"}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Savings Account</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasSavingsAccount)}</Typography>
        </Grid>
        {financialQuestionnaire?.hasSavingsAccount ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Savings Accounts Count</Typography>
            <Typography>{financialQuestionnaire?.savingsAccountsCount ? financialQuestionnaire.savingsAccountsCount : "-"}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Keeps Budget</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.keepsBudget)}</Typography>
        </Grid>
        {financialQuestionnaire?.keepsBudget ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Budget Tool Name</Typography>
            <Typography>{financialQuestionnaire?.budgetToolName || "-"}</Typography>
          </Grid>
        ) : null}
        {isBoolean(financialQuestionnaire?.keepsBudget) && financialQuestionnaire?.keepsBudget ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Is Interested In Budget</Typography>
            <Typography>{displayYesOrNo(financialQuestionnaire?.isInterestedInBudget)}</Typography>
          </Grid>
        ) : null}

        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Monthly Childcare Cost</Typography>
          <Typography>
            {financialQuestionnaire?.monthlyChildcareCostDollars
              ? displayUsDollars(financialQuestionnaire.monthlyChildcareCostDollars)
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Monthly Insurance Cost</Typography>
          <Typography>
            {financialQuestionnaire?.monthlyInsuranceCostDollars
              ? displayUsDollars(financialQuestionnaire.monthlyInsuranceCostDollars)
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Note For Coach</Typography>
          <Typography>{financialQuestionnaire?.noteForCoach || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Question For Coach</Typography>
          <Typography>{financialQuestionnaire?.questionForCoach || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Types of Income</Typography>
          <Typography>
            {financialQuestionnaire?.typesOfIncome ? titleCase(financialQuestionnaire?.typesOfIncome.join(", ")) : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Monthly Take Home Pay</Typography>
          <Typography>
            {financialQuestionnaire?.monthlyTakeHomePayDollars ? displayUsDollars(financialQuestionnaire.monthlyTakeHomePayDollars) : "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RentToOwnFinancialQuestionnaire;

import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import { Props as ChartProps } from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

// project import
import { useConfig } from "@/hooks";

const getBarChartOptions = (
  categories,
  { stacked, navType, primary, darkLight, grey200, secondary, yMin, yMax, formatter }
): ChartProps => {
  return {
    chart: {
      type: "bar",
      stacked: stacked || false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: categories || [],
      labels: {
        datetimeFormatter: {
          month: "MMM 'yy",
        },
        style: {
          colors: primary,
        },
      },
      type: "datetime",
    },
    yaxis: {
      min: yMin,
      max: yMax,
      labels: {
        style: {
          colors: primary,
        },
        formatter: formatter || undefined,
      },
    },
    grid: {
      borderColor: navType === "dark" ? darkLight + 20 : grey200,
    },
    tooltip: {
      theme: navType === "dark" ? "dark" : "light",
      custom: function customTooltip({ series, seriesIndex, dataPointIndex, w }) {
        const total = series.reduce((sum, seriesVals) => sum + seriesVals[dataPointIndex], 0);
        const currentValue = series[seriesIndex][dataPointIndex];

        const rawDateLabel = w.globals.seriesX[0][dataPointIndex];
        const dateObj = new Date(rawDateLabel);
        const dateLabel = dateObj.toLocaleString("default", { month: "short", year: "2-digit" });

        const seriesColor = w.globals.colors[seriesIndex];
        return (
          `<div class="apexcharts-tooltip-title">${dateLabel} (${formatter ? formatter(total) : total})</div>` +
          `<div style="margin-right: 10px;">` +
          `<span style="height: 10px; width: 10px; background-color: ${seriesColor}; border-radius: 50%; display: inline-block; margin-left: 10px; margin-right: 10px;"></span>` +
          `${w.globals.seriesNames[seriesIndex]}: ${formatter ? formatter(currentValue) : currentValue}</div>`
        );
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [primary],
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
            formatter,
          },
        },
      },
    },
    // colors: [secondary],
  };
};

interface Props {
  categories: string[] | number[];
  name: string;
  yMin: number;
  yMax: number;
  series: { name: string; data: number[] }[];
  stacked?: boolean;
  formatter?: (value: number) => string;
}

// ==============================|| LINE CHART ||============================== //

const ApexBarChart = ({ stacked, categories, name, yMin, yMax, series, formatter }: Props) => {
  const theme = useTheme();
  const { navType } = useConfig();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.light;
  const grey200 = theme.palette.grey[200];
  const secondary = theme.palette.secondary.main;

  const options = useMemo(
    () => getBarChartOptions(categories, { stacked, navType, primary, darkLight, grey200, secondary, yMin, yMax, formatter }),
    [navType, primary, darkLight, grey200, secondary, yMin, yMax]
  );

  return (
    <div id="chart">
      <ReactApexChart options={options} type="bar" series={series || []} height={350} />
    </div>
  );
};

export default ApexBarChart;

import { StatusOfApplicationProperty } from "@l4s/client-property-models";

export const statusOfApplicationPropertyOrderMap: Record<StatusOfApplicationProperty, number> = {
  [StatusOfApplicationProperty.ELIGIBILITY_PENDING]: 0,
  [StatusOfApplicationProperty.ELIGIBLE]: 2,
  [StatusOfApplicationProperty.INELIGIBLE]: 2,
  [StatusOfApplicationProperty.OFFER_REJECTED]: 5,
  [StatusOfApplicationProperty.OFFER_REQUESTED]: 7,
  [StatusOfApplicationProperty.OFFER_PENDING]: 7,
  [StatusOfApplicationProperty.OFFER_COUNTERED]: 7,
  [StatusOfApplicationProperty.UNDER_CONTRACT]: 8,
  [StatusOfApplicationProperty.CLOSED]: 10,
  [StatusOfApplicationProperty.ON_MARKET_TO_SELL]: 15,
  [StatusOfApplicationProperty.ACTIVE_RENTAL]: 16,
  [StatusOfApplicationProperty.ON_MARKET_TO_RENT]: 17,
  [StatusOfApplicationProperty.VACANT]: 18,
  [StatusOfApplicationProperty.SOLD_TO_CLIENT]: 20,
  [StatusOfApplicationProperty.SOLD_TO_OTHER]: 20,
  [StatusOfApplicationProperty.TERMINATED]: 20,
  [StatusOfApplicationProperty.ZOMBIE]: 100,
};

export const statusOfApplicationPropertyLabels: Record<StatusOfApplicationProperty, string> = {
  [StatusOfApplicationProperty.CLOSED]: "Closed",
  [StatusOfApplicationProperty.ON_MARKET_TO_SELL]: "On Market to Sell",
  [StatusOfApplicationProperty.ELIGIBILITY_PENDING]: "Eligibility Pending",
  [StatusOfApplicationProperty.ELIGIBLE]: "Eligible",
  [StatusOfApplicationProperty.INELIGIBLE]: "Ineligible",
  [StatusOfApplicationProperty.OFFER_REQUESTED]: "Offer Requested",
  [StatusOfApplicationProperty.OFFER_PENDING]: "Offer Pending",
  [StatusOfApplicationProperty.OFFER_COUNTERED]: "Offer Countered",
  [StatusOfApplicationProperty.OFFER_REJECTED]: "Offer Rejected",
  [StatusOfApplicationProperty.ACTIVE_RENTAL]: "Active Rental",
  [StatusOfApplicationProperty.ON_MARKET_TO_RENT]: "On Market to Rent",
  [StatusOfApplicationProperty.VACANT]: "Vacant",
  [StatusOfApplicationProperty.SOLD_TO_CLIENT]: "Sold to Client",
  [StatusOfApplicationProperty.SOLD_TO_OTHER]: "Sold to Other",
  [StatusOfApplicationProperty.TERMINATED]: "Terminated",
  [StatusOfApplicationProperty.UNDER_CONTRACT]: "Under Contract",
  [StatusOfApplicationProperty.ZOMBIE]: "Zombie",
};

import { Box } from "@mui/material";
import { ClientPersonHeader } from "@/components/partial";

import { Person, ProgramClient } from "@/types";
import { EnvelopesTable } from "@/components/partial/EnvelopesList";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientEnvelopes({ person, programClient }: Props) {
  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box sx={{ marginBottom: "12px" }} />
      {Boolean(person.programClients.length) && <EnvelopesTable personId={person.id} />}
    </>
  );
}

import { Box } from "@mui/material";
import { MainCard } from "@/components/berry";

import { DEFAULT_PADDING } from "@/constant";
import { UseGoals, useToast } from "@/hooks";
import { useEffect, useState } from "react";
import { startCase } from "lodash";
import { mapUnknownToError } from "@/utils";
import { Dropdown, LandisButton } from "@/components/base";
import { useFormik } from "formik";
import { TypeOfMortgage } from "@/types";

interface Props {
  useGoals: UseGoals;
  close: () => void;
}

function ClientGraduationSelectGoalPlan({ useGoals, close }: Props) {
  const { createInitialGoals, goals, loadGoals } = useGoals;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadGoals();
    setLoading(false);
  }, []);

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      mortgageType: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await createInitialGoals(values.mortgageType);
        addToast({ message: `Successfully created initial goals for ${values.mortgageType}` });
        close();
      } catch (err) {
        const error = mapUnknownToError(err);
        addToast({ message: error.message, type: "error" });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.mortgageType) {
        errors.mortgageType = "Mortgage Type is a required field";
      }
      return errors;
    },
  });

  useEffect(() => {
    for (let key in goals) {
      const keyString = key.toString();
      formik.setFieldValue(keyString, goals[key]);
    }
  }, [goals]);

  return (
    <MainCard title="Create A Goal Plan" content={false}>
      <Box sx={{ padding: DEFAULT_PADDING }}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ padding: DEFAULT_PADDING }}>
            <Dropdown
              items={[
                { label: TypeOfMortgage.FHA, value: TypeOfMortgage.FHA },
                { label: TypeOfMortgage.VA, value: TypeOfMortgage.VA },
                { label: TypeOfMortgage.USDA, value: TypeOfMortgage.USDA },
                {
                  label: startCase(TypeOfMortgage.CONVENTIONAL.toLowerCase()),
                  value: startCase(TypeOfMortgage.CONVENTIONAL.toLowerCase()),
                },
              ]}
              label="Mortgage Type"
              id="mortgageType"
              required
              value={formik.values.mortgageType}
              handleChange={(val) => formik.setFieldValue("mortgageType", val)}
              error={Boolean(formik.errors.mortgageType)}
              helperText={formik.errors?.mortgageType?.toString() ?? ""}
            />
          </Box>
          <Box sx={{ paddingLeft: DEFAULT_PADDING, paddingRight: DEFAULT_PADDING, paddingBottom: DEFAULT_PADDING }}>
            <LandisButton loading={loading} type="submit" variant="contained" size="large" sx={{ width: "100%" }}>
              Create Plan
            </LandisButton>
          </Box>
        </form>
      </Box>
    </MainCard>
  );
}

export default ClientGraduationSelectGoalPlan;

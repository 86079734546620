import { TemplateFillStatus } from "@/types";
import { Chip } from "@mui/material";

interface Props {
  status: TemplateFillStatus;
}

const TemplateFillStatusChip = ({ status }: Props) => {
  if (status === TemplateFillStatus.NOT_STARTED) {
    return <Chip label="Not Started" color="default" />;
  }

  if (status === TemplateFillStatus.IN_PROGRESS) {
    return <Chip label="In Progress" color="warning" />;
  }

  if (status === TemplateFillStatus.COMPLETED) {
    return <Chip label="Completed" color="success" />;
  }

  return null;
};

export default TemplateFillStatusChip;

export enum UtilitiesStatusOptions {
  YES = "YES",
  NO = "NO",
  UNKNOWN = "UNKNOWN",
}

export interface ExitPlan {
  id: string;
  created: string;
  createdBy: string;
  programClientId: string;
  cashForKeysOfferDate?: Date;
  cashForKeysAmountCents?: number;
  cashForKeysAcceptedDate?: Date;
  scheduledMoveOutDate?: Date;
  vacancyConfirmedDate?: Date;
  referredToEvictionDate?: Date;
  utilitiesOn?: UtilitiesStatusOptions;
}

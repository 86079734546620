import { LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { useToast } from "@/hooks";
import { Card, Grid } from "@mui/material";
import { useState } from "react";
import { UsePropertyEvaluationsHookInstance } from "@/hooks/data-hooks/client-properties/usePropertyEvaluations";
import Evaluation from "./Evaluation";
import { StatusOfPropertyEvaluation } from "@l4s/client-property-models";

interface Props {
  usePropertyEvaluationsInstance: UsePropertyEvaluationsHookInstance;
  propertyId: string;
  handleClose: () => void;
}

function EvaluationForm({ usePropertyEvaluationsInstance, propertyId, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const { propertyEvaluations, createPropertyEvaluation, getPropertyEvaluations } = usePropertyEvaluationsInstance;

  const handleCreatePropertyEvaluation = async () => {
    setLoading(true);
    try {
      await createPropertyEvaluation(propertyId);
      await getPropertyEvaluations();
    } catch (err) {
      addToast({
        type: "error",
        message: err.message,
      });
    }
    setLoading(false);
  };

  const mostRecentPropertyEvaluation = propertyEvaluations[0];

  return (
    <>
      <Grid container direction="column" gap={1} style={{ display: "flex", minHeight: "100vh" }}>
        <Grid item>
          <MainCard title="Property Eligibility">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <LandisButton
                onClick={handleCreatePropertyEvaluation}
                variant="contained"
                loading={loading}
                disabled={mostRecentPropertyEvaluation?.status === StatusOfPropertyEvaluation.NEEDS_REVIEW}
              >
                Start New Evaluation
              </LandisButton>
            </div>
          </MainCard>
        </Grid>

        {propertyEvaluations.map((propertyEvaluation, index) => (
          <Grid item key={propertyEvaluation.id}>
            <Card>
              <Evaluation
                propertyEvaluation={propertyEvaluation}
                usePropertyEvaluationsInstance={usePropertyEvaluationsInstance}
                isUnsubmittedEvaluation={propertyEvaluation.status === StatusOfPropertyEvaluation.NEEDS_REVIEW}
                isMostRecentEvaluation={index === 0}
                handleClose={handleClose}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default EvaluationForm;

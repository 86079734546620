import { CoachingHistoryWithCreatorAccount } from "@l4s/coaching-models";
import { DateFormat } from "@/utils";
import { getAvatarUrlFromAccountId } from "@/utils/avatar";
import { Avatar, Box, Drawer, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import CoachingSnapshotForm from "./CoachingSnapshotForm";
import { UseCoachingHistory } from "@/hooks";

interface Props {
  coachingHistoryRecord: CoachingHistoryWithCreatorAccount;
  useCoachingHistory: UseCoachingHistory;
}

function CoachingHistoryRow({ coachingHistoryRecord, useCoachingHistory }: Props) {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow hover key={coachingHistoryRecord.id} onClick={() => setDrawerOpen(true)}>
        <TableCell>{format(new Date(coachingHistoryRecord.created), DateFormat.DISPLAY_DATE)}</TableCell>
        <TableCell>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {" "}
              <Avatar
                alt={`${coachingHistoryRecord.creator.nameFirst} ${coachingHistoryRecord.creator.nameLast}`}
                src={getAvatarUrlFromAccountId(coachingHistoryRecord.createdByAccountId)}
              />
            </Grid>
            <Grid item>
              <Typography>{`${coachingHistoryRecord.creator.nameFirst} ${coachingHistoryRecord.creator.nameLast}`}</Typography>
              <Typography variant="caption">{coachingHistoryRecord.creator.roles}</Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
            boxShadow: "none",
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <CoachingSnapshotForm
            useCoachingHistory={useCoachingHistory}
            coachingHistoryRecord={coachingHistoryRecord}
            close={() => setDrawerOpen(false)}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default CoachingHistoryRow;

import { Box } from "@mui/material";
import { ClientPersonHeader, ClientProgramHeader } from "@/components/partial";

import { Person, ProgramClient } from "@/types";
import { CoachingRentalPayment } from "@/components/partial";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientRemediation(props: Props) {
  return (
    <>
      <ClientPersonHeader {...props} />
      <Box sx={{ marginBottom: "12px" }} />
      <ClientProgramHeader {...props} />
      <Box sx={{ marginBottom: "12px" }} />
      <CoachingRentalPayment {...props} />
      <Box sx={{ marginBottom: "100px" }} />
    </>
  );
}

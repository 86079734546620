import { Plugin, PluginOnTextLayerRender } from "@react-pdf-viewer/core";

interface DetectClicksPluginProps {
  handleClick: (input: { x: number; y: number; pageIndex: number; pageHeight: number; pageWidth: number }) => void;
}

export const detectClicksPlugin = ({ handleClick }: DetectClicksPluginProps): Plugin => {
  const clickHandler = (e: PluginOnTextLayerRender) => {
    e.ele.addEventListener("mouseup", function (event) {
      const textLayer = e.ele;
      const pageRect = textLayer.getBoundingClientRect();
      // Get the x and y coordinates of the click relative to the viewport
      const x = ((event.clientX - pageRect.left) * 100) / pageRect.width;
      const y = ((event.clientY - pageRect.top) * 100) / pageRect.height;

      handleClick({ x, y, pageIndex: e.pageIndex, pageWidth: pageRect.width, pageHeight: pageRect.height });
    });
  };

  return {
    onTextLayerRender: clickHandler,
  };
};

import { MainCard } from "@/components/berry";
import { useToast } from "@/hooks";
import { UseCoachingHistory } from "@/hooks";
import {
  CONFIRM_DROPDOWN_ITEMS,
  ConfirmOption,
  DateFormat,
  FormHelpers,
  boolToConfirm,
  confirmToBool,
  displayUsCents,
  mapUnknownToError,
} from "@/utils";
import {
  Box,
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { CoachingSnapshotField } from "./useCoachingSnapshot";
import { CurrencyCentsTextField, Dropdown, LandisButton } from "@/components/base";
import { DEFAULT_PADDING } from "@/constant";
import { format } from "date-fns";
import * as Yup from "yup";
import { CoachingHistoryWithCreatorAccount } from "@l4s/coaching-models";

interface Props {
  close: () => void;
  useCoachingHistory: UseCoachingHistory;
  coachingHistoryRecord?: CoachingHistoryWithCreatorAccount;
}

const validationSchema = Yup.object().shape({
  monthlyIncomeCents: Yup.string().nullable(),
  creditScore: Yup.string().nullable(),
  frontEndDti: Yup.string().nullable(),
  backEndDti: Yup.string().nullable(),
  savingsTotalCents: Yup.number().nullable(),
  monthsInProgram: Yup.string().nullable(),
  debtCents: Yup.number().nullable(),
  balanceCents: Yup.number().nullable(),
  inHardship: Yup.string().nullable(),
});

function CoachingSnapshotForm({ close, useCoachingHistory, coachingHistoryRecord }: Props) {
  const [loading, setLoading] = useState(false);
  const { coachingHistory, createCoachingHistoryRecord, updateCoachingHistoryRecord } = useCoachingHistory;
  const { addToast } = useToast();

  const getFormattedTimeString = (date: Date): String => {
    return format(new Date(date), DateFormat.DISPLAY_DATE);
  };

  const latestSnapshot = coachingHistoryRecord || coachingHistory[0];

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      monthlyIncomeCents: latestSnapshot?.monthlyIncomeCents ?? null,
      creditScore: latestSnapshot?.creditScore?.toString() ?? "",
      frontEndDti: latestSnapshot?.frontEndDti?.toString() ?? "",
      backEndDti: latestSnapshot?.backEndDti?.toString() ?? "",
      savingsTotalCents: latestSnapshot?.savingsTotalCents ?? null,
      monthsInProgram: latestSnapshot?.monthsInProgram?.toString() ?? "",
      debtCents: latestSnapshot?.debtCents ?? null,
      balanceCents: latestSnapshot?.balanceCents ?? null,
      inHardship: typeof latestSnapshot?.inHardship === "boolean" ? boolToConfirm(latestSnapshot.inHardship) : ("" as ConfirmOption),
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (coachingHistoryRecord) {
          await updateCoachingHistoryRecord(coachingHistoryRecord.id, {
            ...values,
            creditScore: parseInt(values?.creditScore),
            frontEndDti: parseFloat(values?.frontEndDti),
            backEndDti: parseFloat(values?.backEndDti),
            monthsInProgram: parseInt(values?.monthsInProgram),
            inHardship: confirmToBool(values?.inHardship),
          });
          addToast({ message: "Successfully updated coaching snapshot" });
          return;
        }
        await createCoachingHistoryRecord({
          ...values,
          creditScore: parseInt(values?.creditScore),
          frontEndDti: parseFloat(values?.frontEndDti),
          backEndDti: parseFloat(values?.backEndDti),
          monthsInProgram: parseInt(values?.monthsInProgram),
          inHardship: confirmToBool(values?.inHardship),
        });
        addToast({ message: "Successfully created coaching snapshot" });
      } catch (err) {
        const error = mapUnknownToError(err);
        addToast({ message: error.message, type: "error" });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title="Coaching Snapshot" content={false}>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box>
                        <Typography variant="h5">Key Metrics</Typography>
                        <Typography variant="caption">All fields optional</Typography>
                      </Box>
                    </TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <Box>
                          <Typography variant="h5">Prior</Typography>
                          <Typography variant="caption">
                            {latestSnapshot?.created ? getFormattedTimeString(latestSnapshot.created) : "No history"}
                          </Typography>
                        </Box>
                      </TableCell>
                    )}
                    <TableCell>
                      <Box>
                        <Typography variant="h5">Current</Typography>
                        <Typography variant="caption">{getFormattedTimeString(new Date())}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Monthly Income</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorMonthlyIncome"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={displayUsCents(latestSnapshot?.monthlyIncomeCents ?? 0)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <CurrencyCentsTextField
                        id={CoachingSnapshotField.MONTHLY_INCOME_CENTS}
                        variant="outlined"
                        fullWidth
                        label="Current Value"
                        value={formik.values[CoachingSnapshotField.MONTHLY_INCOME_CENTS]}
                        handleChange={(val) => formik.setFieldValue(CoachingSnapshotField.MONTHLY_INCOME_CENTS, val)}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.MONTHLY_INCOME_CENTS)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.MONTHLY_INCOME_CENTS)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Credit Score</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorCreditScore"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={latestSnapshot?.creditScore ?? ""}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        id={CoachingSnapshotField.CREDIT_SCORE}
                        label="Current Value"
                        fullWidth
                        spellCheck={false}
                        onChange={(e) => {
                          formik.setFieldValue(CoachingSnapshotField.CREDIT_SCORE, e?.target?.value);
                        }}
                        value={formik.values[CoachingSnapshotField.CREDIT_SCORE]}
                        inputProps={{ maxLength: 3 }}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.CREDIT_SCORE)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.CREDIT_SCORE)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Front End DTI</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorFrontEndDti"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={latestSnapshot?.frontEndDti ?? ""}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        id={CoachingSnapshotField.FRONT_END_DTI}
                        label="Current Value"
                        fullWidth
                        spellCheck={false}
                        onChange={(e) => {
                          formik.setFieldValue(CoachingSnapshotField.FRONT_END_DTI, e?.target?.value);
                        }}
                        value={formik.values[CoachingSnapshotField.FRONT_END_DTI]}
                        helperText={
                          FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.FRONT_END_DTI) ??
                          "Should be a number between 0 and 1"
                        }
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.FRONT_END_DTI)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Back End DTI</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorBackEndDti"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={latestSnapshot?.backEndDti ?? ""}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        id={CoachingSnapshotField.BACK_END_DTI}
                        label="Current Value"
                        fullWidth
                        spellCheck={false}
                        onChange={(e) => {
                          formik.setFieldValue(CoachingSnapshotField.BACK_END_DTI, e.target.value);
                        }}
                        value={formik.values[CoachingSnapshotField.BACK_END_DTI]}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.BACK_END_DTI)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.BACK_END_DTI)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Savings</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorTotalSavings"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={displayUsCents(latestSnapshot?.savingsTotalCents ?? 0)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <CurrencyCentsTextField
                        id={CoachingSnapshotField.SAVINGS_TOTAL_CENTS}
                        variant="outlined"
                        fullWidth
                        label="Current Value"
                        value={formik.values[CoachingSnapshotField.SAVINGS_TOTAL_CENTS]}
                        handleChange={(val) => formik.setFieldValue(CoachingSnapshotField.SAVINGS_TOTAL_CENTS, val)}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.SAVINGS_TOTAL_CENTS)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.SAVINGS_TOTAL_CENTS)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Months In Program</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorMonthsInProgram"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={latestSnapshot?.monthsInProgram ?? ""}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        id={CoachingSnapshotField.MONTHS_IN_PROGRAM}
                        label="Current Value"
                        fullWidth
                        spellCheck={false}
                        onChange={(e) => {
                          formik.setFieldValue(CoachingSnapshotField.MONTHS_IN_PROGRAM, e.target.value);
                        }}
                        value={formik.values[CoachingSnapshotField.MONTHS_IN_PROGRAM]}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.MONTHS_IN_PROGRAM)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.MONTHS_IN_PROGRAM)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Debt</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorDebt"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={displayUsCents(latestSnapshot?.debtCents ?? 0)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <CurrencyCentsTextField
                        id={CoachingSnapshotField.DEBT_CENTS}
                        variant="outlined"
                        fullWidth
                        label="Current Value"
                        value={formik.values[CoachingSnapshotField.DEBT_CENTS]}
                        handleChange={(val) => formik.setFieldValue(CoachingSnapshotField.DEBT_CENTS, val)}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.DEBT_CENTS)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.DEBT_CENTS)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Balance</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <TextField
                          id="priorBalance"
                          label="Prior Value"
                          fullWidth
                          spellCheck={false}
                          disabled
                          value={displayUsCents(latestSnapshot?.balanceCents ?? 0)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <CurrencyCentsTextField
                        id={CoachingSnapshotField.BALANCE_CENTS}
                        variant="outlined"
                        fullWidth
                        label="Current Value"
                        value={formik.values[CoachingSnapshotField.BALANCE_CENTS]}
                        handleChange={(val) => formik.setFieldValue(CoachingSnapshotField.BALANCE_CENTS, val)}
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.BALANCE_CENTS)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.BALANCE_CENTS)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>In Hardship</TableCell>
                    {coachingHistoryRecord ? null : (
                      <TableCell>
                        <Dropdown
                          id="priorInHardship"
                          disabled
                          items={CONFIRM_DROPDOWN_ITEMS}
                          handleChange={(value) => value} // Method required but not needed
                          value={
                            typeof latestSnapshot?.inHardship === "boolean"
                              ? boolToConfirm(latestSnapshot.inHardship)
                              : ("" as ConfirmOption)
                          }
                          label="Prior Value"
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Dropdown
                        id={CoachingSnapshotField.IN_HARDSHIP}
                        items={CONFIRM_DROPDOWN_ITEMS}
                        value={formik.values[CoachingSnapshotField.IN_HARDSHIP]}
                        handleChange={(value) => FormHelpers.handleFakeEvent(formik, CoachingSnapshotField.IN_HARDSHIP, value)}
                        label="Current Value"
                        helperText={FormHelpers.formikErrorMessage(formik, CoachingSnapshotField.IN_HARDSHIP)}
                        error={FormHelpers.formikCheckError(formik, CoachingSnapshotField.IN_HARDSHIP)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ padding: DEFAULT_PADDING }}>
            <LandisButton loading={loading} type="submit" variant="contained" size="large" sx={{ width: "100%" }}>
              {coachingHistoryRecord ? "Update" : "Submit"} Snapshot
            </LandisButton>
            <Box sx={{ paddingTop: DEFAULT_PADDING }} />
            <Button variant="outlined" size="large" sx={{ width: "100%" }} onClick={close}>
              Close
            </Button>
          </Box>
        </form>
      </CardContent>
    </MainCard>
  );
}

export default CoachingSnapshotForm;

import LineChart, { StringifiedDataPoint } from "./LineChart";
import { MainCard } from "@/components/berry";
import { Box } from "@mui/system";
import { SvgIconComponent } from "@mui/icons-material";
import CreditDashHoverDataCard from "./CreditDashHoverDataCard";

export interface DataPoint {
  x: Date;
  y: number;
}

interface Props {
  data: DataPoint[];
  dataPointName: string;
  yMin: number;
  yMax: number;
  chartTitle: string;
  cardTitle: string;
  suppliedIcon?: SvgIconComponent;
}

function convertDataPointXToString(data: DataPoint[]): StringifiedDataPoint[] {
  return data.map((dp) => ({
    x: dp.x.toString(),
    y: dp.y,
  }));
}

function CreditDashLineChartDataCard({ data, dataPointName, yMin, yMax, chartTitle, cardTitle, suppliedIcon }: Props) {
  return (
    <>
      <CreditDashHoverDataCard cardTitle={cardTitle} suppliedIcon={suppliedIcon ? suppliedIcon : undefined} data={data} />
      <Box sx={{ marginBottom: "12px" }} />
      <MainCard title={chartTitle}>
        <LineChart data={convertDataPointXToString(data)} name={dataPointName} yMin={yMin} yMax={yMax} />
      </MainCard>
    </>
  );
}

export default CreditDashLineChartDataCard;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { Action, StatusOfAction, TypeOfAction, ActionPriorityLevel } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";
import { sortByDate } from "@/utils/sort";

interface UpdateActionInput {
  id: string;
  name: string;
  description: string;
  type: TypeOfAction;
  status: StatusOfAction;
  dueDate?: string;
  dueDateLabel?: string;
  publishDate?: string;
  link?: string;
  buttonText?: string;
  response?: string;
  pulseRating?: string;
  completionDate?: string;
  learnMoreLink?: string;
  priority?: ActionPriorityLevel;
  intercomArticleId?: string;
}

interface CreateActionInput {
  name: string;
  description: string;
  type: TypeOfAction;
  status?: StatusOfAction;
  dueDate?: string;
  dueDateLabel?: string;
  publishDate?: string;
  link?: string;
  learnMoreLink?: string;
  buttonText?: string;
  programClientId: string;
  priority?: ActionPriorityLevel;
  // join with actions_goals
  goalIds?: string[];
  intercomArticleId?: string;
}

export interface UseActions {
  loadActions: () => Promise<void>;
  actions: Array<Action>;
  isLoading: boolean;
  createAction: (input: CreateActionInput) => Promise<Action>;
  updateAction: (input: UpdateActionInput) => Promise<Action>;
}

const sortActionsDesc = (action: Array<Action>) => {
  return action.sort(sortByDate("created", false));
};

export const useActions = (programClientId: string): UseActions => {
  const [actions, setActions] = useState<Array<Action>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadActions = async () => {
    if (!programClientId || Boolean(actions?.length)) return;
    try {
      setIsLoading(true);
      const response: { data: Array<Action> } = await bridgeV2ApiInstance.get(routes.ACTIONS, { params: { programClientId } });
      setActions(sortActionsDesc(response.data));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching actions.");
    }
    setIsLoading(false);
  };

  const createAction = async (input: CreateActionInput) => {
    if (!programClientId) return;
    try {
      const response: { data: Action } = await bridgeV2ApiInstance.post(routes.ACTIONS, input);
      setActions((existingActions) => sortActionsDesc([...existingActions, response.data]));
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating action.");
    }
  };

  const updateAction = async (input: UpdateActionInput) => {
    if (!input.id) return;
    try {
      const response: { data: Action } = await bridgeV2ApiInstance.put(routes.ACTIONS, input);
      setActions((existingActions) =>
        existingActions.map((action) => (action.id === response.data.id ? { ...action, ...response.data } : action))
      );
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error updating action.");
    }
  };

  return {
    actions,
    isLoading,
    loadActions,
    createAction,
    updateAction,
  };
};

import { StatusOfApplication } from "@l4s/ground-models";

export enum AdverseActionReasonType {
  INELIGIBLE = "INELIGIBLE",
  WITHDRAWN = "WITHDRAWN",
}

export interface AdverseActionDefinitionDto {
  id: string;
  adverseActionReason: string;
  reasonType?: AdverseActionReasonType;
  applicationStatus?: StatusOfApplication; // Deprecated
  description?: string;
  hmdaDenialReason?: string;
  createdDate: string;
  isActive: boolean;
  key: AdverseActionKey;
}

export interface ApplicationAdverseActionsDto {
  id: string;
  applicationId: string;
  adverseActionDefinition: AdverseActionDefinitionDto;
  description?: string;
  createdDate: string;
  createdBy: string;
}

export enum AdverseActionKey {
  INELIGIBLE__BANKRUPTCY = "INELIGIBLE__BANKRUPTCY",
  INELIGIBLE__CHANGE_IN_CIRCUMSTANCE_ASSETS = "INELIGIBLE__CHANGE_IN_CIRCUMSTANCE_ASSETS",
  INELIGIBLE__CHANGE_IN_CIRCUMSTANCE_INCOME = "INELIGIBLE__CHANGE_IN_CIRCUMSTANCE_INCOME",
  INELIGIBLE__DELINQUENT_CREDIT_HISTORY_WITH_OTHERS = "INELIGIBLE__DELINQUENT_CREDIT_HISTORY_WITH_OTHERS",
  INELIGIBLE__EXCESSIVE_DTI = "INELIGIBLE__EXCESSIVE_DTI",
  INELIGIBLE__GARNISHMENT_ATTACHMENT_FORECLOSURE_REPOSSESSION = "INELIGIBLE__GARNISHMENT_ATTACHMENT_FORECLOSURE_REPOSSESSION",
  INELIGIBLE__MISSING_CRITICAL_CREDIT_INFORMATION = "INELIGIBLE__MISSING_CRITICAL_CREDIT_INFORMATION",
  INELIGIBLE__MISSING_CRITICAL_INCOME_DOCUMENTATION = "INELIGIBLE__MISSING_CRITICAL_INCOME_DOCUMENTATION",
  INELIGIBLE__OTHER = "INELIGIBLE__OTHER",
  INELIGIBLE__VIOLATION_OF_PROGRAM_TERMS = "INELIGIBLE__VIOLATION_OF_PROGRAM_TERMS",
  WITHDRAWN__6_PLUS_MONTH_MOVE_IN_TIMELINE = "WITHDRAWN__6_PLUS_MONTH_MOVE_IN_TIMELINE",
  WITHDRAWN__ALTERNATE_OPPORTUNITY_COMPETITOR = "WITHDRAWN__ALTERNATE_OPPORTUNITY_COMPETITOR",
  WITHDRAWN__ALTERNATE_OPPORTUNITY_RENTAL = "WITHDRAWN__ALTERNATE_OPPORTUNITY_RENTAL",
  WITHDRAWN__ALTERNATE_OPPORTUNITY_TRADITIONAL_LENDER = "WITHDRAWN__ALTERNATE_OPPORTUNITY_TRADITIONAL_LENDER",
  WITHDRAWN__APPROVED_APPROVAL_EXPIRED = "WITHDRAWN__APPROVED_APPROVAL_EXPIRED",
  WITHDRAWN__APPROVED_CANT_FIND_SUITABLE_HOMES = "WITHDRAWN__APPROVED_CANT_FIND_SUITABLE_HOMES",
  WITHDRAWN__APPROVED_CLIENT_UNRESPONSIVE = "WITHDRAWN__APPROVED_CLIENT_UNRESPONSIVE",
  WITHDRAWN__APPROVED_DOWN_PAYMENT_REQUIREMENT_TOO_HIGH = "WITHDRAWN__APPROVED_DOWN_PAYMENT_REQUIREMENT_TOO_HIGH",
  WITHDRAWN__APPROVED_MONTHLY_PAYMENT_TOO_HIGH = "WITHDRAWN__APPROVED_MONTHLY_PAYMENT_TOO_HIGH",
  WITHDRAWN__APPROVED_PROGRAM_TERMS_NOT_ACCEPTED = "WITHDRAWN__APPROVED_PROGRAM_TERMS_NOT_ACCEPTED",
  WITHDRAWN__APPROVED_UNABLE_TO_FIND_PROPERTY = "WITHDRAWN__APPROVED_UNABLE_TO_FIND_PROPERTY",
  WITHDRAWN__CHANGE_IN_CIRCUMSTANCE_PERSONAL = "WITHDRAWN__CHANGE_IN_CIRCUMSTANCE_PERSONAL",
  WITHDRAWN__CLIENT_DISSATISFIED_WITH_SERVICE_LEVELS = "WITHDRAWN__CLIENT_DISSATISFIED_WITH_SERVICE_LEVELS",
  WITHDRAWN__INACTIVE_APPLICATION = "WITHDRAWN__INACTIVE_APPLICATION",
  WITHDRAWN__INELIGIBLE_PET_BREED = "WITHDRAWN__INELIGIBLE_PET_BREED",
  WITHDRAWN__TARGET_MSA_OUTSIDE_OPERATING_AREA = "WITHDRAWN__TARGET_MSA_OUTSIDE_OPERATING_AREA",
  OLD_WTLSTD__CREDIT_APPLICATION_INCOMPLETE = "OLD_WTLSTD__CREDIT_APPLICATION_INCOMPLETE",
  OLD_WTLSTD__INSUFFICIENT_NUMBER_OF_CREDIT_REFERENCES_PROVIDED = "OLD_WTLSTD__INSUFFICIENT_NUMBER_OF_CREDIT_REFERENCES_PROVIDED",
  OLD_WTLSTD__UNACCEPTABLE_TYPE_OF_CREDIT_REFERENCES_PROVIDED = "OLD_WTLSTD__UNACCEPTABLE_TYPE_OF_CREDIT_REFERENCES_PROVIDED",
  OLD_WTLSTD__UNABLE_TO_VERIFY_CREDIT_REFERENCES = "OLD_WTLSTD__UNABLE_TO_VERIFY_CREDIT_REFERENCES",
  OLD_WTLSTD__TEMPORARY_OR_IRREGULAR_EMPLOYMENT = "OLD_WTLSTD__TEMPORARY_OR_IRREGULAR_EMPLOYMENT",
  OLD_WTLSTD__UNABLE_TO_VERIFY_EMPLOYMENT = "OLD_WTLSTD__UNABLE_TO_VERIFY_EMPLOYMENT",
  OLD_WTLSTD__LENGTH_OF_EMPLOYMENT = "OLD_WTLSTD__LENGTH_OF_EMPLOYMENT",
  OLD_WTLSTD__INCOME_INSUFFICIENT_FOR_PROGRAM_REQUESTED = "OLD_WTLSTD__INCOME_INSUFFICIENT_FOR_PROGRAM_REQUESTED",
  OLD_WTLSTD__EXCESSIVE_OBLIGATIONS_IN_RELATION_TO_INCOME = "OLD_WTLSTD__EXCESSIVE_OBLIGATIONS_IN_RELATION_TO_INCOME",
  OLD_WTLSTD__UNABLE_TO_VERIFY_INCOME = "OLD_WTLSTD__UNABLE_TO_VERIFY_INCOME",
  OLD_WTLSTD__LENGTH_OF_RESIDENCE = "OLD_WTLSTD__LENGTH_OF_RESIDENCE",
  OLD_WTLSTD__TEMPORARY_RESIDENCE = "OLD_WTLSTD__TEMPORARY_RESIDENCE",
  OLD_WTLSTD__UNABLE_TO_VERIFY_RESIDENCE = "OLD_WTLSTD__UNABLE_TO_VERIFY_RESIDENCE",
  OLD_WTLSTD__NO_CREDIT_FILE = "OLD_WTLSTD__NO_CREDIT_FILE",
  OLD_WTLSTD__LIMITED_CREDIT_EXPERIENCE = "OLD_WTLSTD__LIMITED_CREDIT_EXPERIENCE",
  OLD_WTLSTD__POOR_CREDIT_PERFORMANCE_WITH_US = "OLD_WTLSTD__POOR_CREDIT_PERFORMANCE_WITH_US",
  OLD_WTLSTD__DELINQUENT_PAST_OR_PRSNT_CRDIT_OBLGTNS_WITH_OTHERS = "OLD_WTLSTD__DELINQUENT_PAST_OR_PRSNT_CRDIT_OBLGTNS_WITH_OTHERS",
  OLD_WTLSTD__COLLECTION_ACTION_OR_JUDGMENT = "OLD_WTLSTD__COLLECTION_ACTION_OR_JUDGMENT",
  OLD_WTLSTD__GARNISHMENT_OR_ATTACHMENT = "OLD_WTLSTD__GARNISHMENT_OR_ATTACHMENT",
  OLD_WTLSTD__FORECLOSURE_OR_REPOSSESSION = "OLD_WTLSTD__FORECLOSURE_OR_REPOSSESSION",
  OLD_WTLSTD__BANKRUPTCY = "OLD_WTLSTD__BANKRUPTCY",
  OLD_WTLSTD__VALUE_OR_TYPE_OF_COLLATERAL_NOT_SUFFICIENT = "OLD_WTLSTD__VALUE_OR_TYPE_OF_COLLATERAL_NOT_SUFFICIENT",
  OLD_WTLSTD__OTHER = "OLD_WTLSTD__OTHER",
  OLD_WTLSTD__INSUFFICIENT_ASSETS = "OLD_WTLSTD__INSUFFICIENT_ASSETS",
  OLD_WITHDRAWN__DOESNT_WANT_TO_MOVE_FORWARD_WITH_GIVEN_BUDGET = "OLD_WITHDRAWN__DOESNT_WANT_TO_MOVE_FORWARD_WITH_GIVEN_BUDGET",
  OLD_UND_INV__BANK_STATEMENTS_MISSING_OR_INCOMPLETE = "OLD_UND_INV__BANK_STATEMENTS_MISSING_OR_INCOMPLETE",
  OLD_UND_INV__MOST_RECENT_TAX_RETURN_MISSING_OR_INCOMPLETE = "OLD_UND_INV__MOST_RECENT_TAX_RETURN_MISSING_OR_INCOMPLETE",
  OLD_UND_INV__MOST_RECENT_BUSNSS_TAX_RETURN_MISSING_OR_INCMPLT = "OLD_UND_INV__MOST_RECENT_BUSNSS_TAX_RETURN_MISSING_OR_INCMPLT",
  OLD_UND_INV__PROFIT_AND_LOSS_MISSING_OR_INCOMPLETE = "OLD_UND_INV__PROFIT_AND_LOSS_MISSING_OR_INCOMPLETE",
  OLD_UND_INV__MOST_RECENT_W2_MISSING_OR_INCOMPLETE = "OLD_UND_INV__MOST_RECENT_W2_MISSING_OR_INCOMPLETE",
  OLD_UND_INV__PAY_STUBS_MISSING = "OLD_UND_INV__PAY_STUBS_MISSING",
  OLD_UND_INV__PENSION_SSI_DOCS_MISSING = "OLD_UND_INV__PENSION_SSI_DOCS_MISSING",
  OLD_UND_INV__CHILD_SUPPORT_PAYMENT_HISTORY_MISSING = "OLD_UND_INV__CHILD_SUPPORT_PAYMENT_HISTORY_MISSING",
  OLD_UND_INV__CRT_ORD_FINL_DIV_DECREE_OR_CHLD_SUP_AGRMNT_MISSING = "OLD_UND_INV__CRT_ORD_FINL_DIV_DECREE_OR_CHLD_SUP_AGRMNT_MISSING",
  OLD_UND_INV__EMPLOYMENT_OFFER_LETTER_MISSING = "OLD_UND_INV__EMPLOYMENT_OFFER_LETTER_MISSING",
  OLD_UND_INV__GIFT_LETTER_MISSING = "OLD_UND_INV__GIFT_LETTER_MISSING",
  OLD_UND_INV__DONOR_STATEMENT_MISSING = "OLD_UND_INV__DONOR_STATEMENT_MISSING",
  OLD_UND_INV__BANKRUPTCY_DOCS_AND_PYMNT_HISTORY_MISSING = "OLD_UND_INV__BANKRUPTCY_DOCS_AND_PYMNT_HISTORY_MISSING",
  OLD_UND_INV__EMPLOYMENT_LETTER_FOR_RELOCATION_MISSING = "OLD_UND_INV__EMPLOYMENT_LETTER_FOR_RELOCATION_MISSING",
  OLD_UND_INV__MORTGAGE_STATEMENT_MISSING = "OLD_UND_INV__MORTGAGE_STATEMENT_MISSING",
  OLD_UND_INV__MORTGAGE_INSURANCE_STATEMENT_MISSING = "OLD_UND_INV__MORTGAGE_INSURANCE_STATEMENT_MISSING",
  OLD_UND_INV__LEASE_AGRMNT_FOR_MTG_ON_CRDT_MISSING = "OLD_UND_INV__LEASE_AGRMNT_FOR_MTG_ON_CRDT_MISSING",
  OLD_UND_INV__LISTING_AGRMNT_FOR_MTG_ON_CRDT_MISSING = "OLD_UND_INV__LISTING_AGRMNT_FOR_MTG_ON_CRDT_MISSING",
  OLD_UND_INV__BANK_STATEMENTS_OUTDATED = "OLD_UND_INV__BANK_STATEMENTS_OUTDATED",
  OLD_UND_INV__TAX_RETURN_OUTDATED = "OLD_UND_INV__TAX_RETURN_OUTDATED",
  OLD_UND_INV__PROFIT_AND_LOSS_OUTDATED = "OLD_UND_INV__PROFIT_AND_LOSS_OUTDATED",
  OLD_UND_INV__PAY_STUBS_OUTDATED = "OLD_UND_INV__PAY_STUBS_OUTDATED",
  OLD_UND_INV__BANK_BALANCE_INSUFFICIENT_TO_COVER_MIN_DP = "OLD_UND_INV__BANK_BALANCE_INSUFFICIENT_TO_COVER_MIN_DP",
  OLD_UND_INV__UNSOURCED_LARGE_DEPOSITS = "OLD_UND_INV__UNSOURCED_LARGE_DEPOSITS",
  OLD_UND_INV__FROZEN_CREDIT_REPORT = "OLD_UND_INV__FROZEN_CREDIT_REPORT",
  OLD_UND_INV__NEW_LINE_OF_CREDIT_OPENED = "OLD_UND_INV__NEW_LINE_OF_CREDIT_OPENED",
  OLD_UND_INV__PEND_LET_OF_EXPLNTION_FOR_PAST_DELNQNCIES = "OLD_UND_INV__PEND_LET_OF_EXPLNTION_FOR_PAST_DELNQNCIES",
  OLD_UND_INV__OTHER_INCOME_SOURCE_NOT_SPECIFIED = "OLD_UND_INV__OTHER_INCOME_SOURCE_NOT_SPECIFIED",
  OLD_UND_INV__EMPLOYMENT_HISTORY_DEMONSTRATES_GAPS = "OLD_UND_INV__EMPLOYMENT_HISTORY_DEMONSTRATES_GAPS",
  OLD_WITHDRAWN__CANT_FIND_SUITABLE_HOMES_AT_AVAILABLE_BUDGET = "OLD_WITHDRAWN__CANT_FIND_SUITABLE_HOMES_AT_AVAILABLE_BUDGET",
  OLD_WITHDRAWN__APPROVED_THROUGH_LANDIS_COMPETITOR = "OLD_WITHDRAWN__APPROVED_THROUGH_LANDIS_COMPETITOR",
  OLD_WITHDRAWN__APPROVED_THROUGH_TRAD_MORTGAGE_LENDER = "OLD_WITHDRAWN__APPROVED_THROUGH_TRAD_MORTGAGE_LENDER",
  OLD_WITHDRAWN__WITHDRAWN_FOR_PERSONAL_REASONS = "OLD_WITHDRAWN__WITHDRAWN_FOR_PERSONAL_REASONS",
  OLD_WITHDRAWN__CLIENT_DISSATISFIED_WITH_SERVICE_LEVELS = "OLD_WITHDRAWN__CLIENT_DISSATISFIED_WITH_SERVICE_LEVELS",
  OLD_WITHDRAWN__TARGET_MSA_OUTSIDE_OF_OPERATING_AREA = "OLD_WITHDRAWN__TARGET_MSA_OUTSIDE_OF_OPERATING_AREA",
  OLD_WITHDRAWN__MONTHLY_PAYMENT_IS_TOO_HIGH = "OLD_WITHDRAWN__MONTHLY_PAYMENT_IS_TOO_HIGH",
  OLD_WITHDRAWN__DOWN_PAYMENT_REQUIREMENT_IS_TOO_HIGH = "OLD_WITHDRAWN__DOWN_PAYMENT_REQUIREMENT_IS_TOO_HIGH",
  OLD_WITHDRAWN__6_PLUS_MONTH_MOVE_IN_TIMELINE = "OLD_WITHDRAWN__6_PLUS_MONTH_MOVE_IN_TIMELINE",
  OLD_WITHDRAWN__VERIFIED_INCOME_BELOW_REQUIREMENTS = "OLD_WITHDRAWN__VERIFIED_INCOME_BELOW_REQUIREMENTS",
  OLD_WITHDRAWN__VERIFIED_ASSETS_BELOW_REQUIREMENTS = "OLD_WITHDRAWN__VERIFIED_ASSETS_BELOW_REQUIREMENTS",
  OLD_WITHDRAWN__DEROGATORY_CREDIT_EVENT = "OLD_WITHDRAWN__DEROGATORY_CREDIT_EVENT",
  OLD_WITHDRAWN__CHANGES_TO_EMPLOYMENT = "OLD_WITHDRAWN__CHANGES_TO_EMPLOYMENT",
  OLD_WITHDRAWN__CONTINUING_AS_RENTER = "OLD_WITHDRAWN__CONTINUING_AS_RENTER",
  OLD_WITHDRAWN__ISNT_COMFORTABLE_WITH_MONTHLY_PAYMENTS_DP = "OLD_WITHDRAWN__ISNT_COMFORTABLE_WITH_MONTHLY_PAYMENTS_DP",
  OLD_WITHDRAWN__WANTS_TO_BUILD_SAVINGS_ON_OWN = "OLD_WITHDRAWN__WANTS_TO_BUILD_SAVINGS_ON_OWN",
  OLD_WITHDRAWN__WANTS_TO_BLD_CRDT_ON_OWN_TO_INCREASE_BUDGET = "OLD_WITHDRAWN__WANTS_TO_BLD_CRDT_ON_OWN_TO_INCREASE_BUDGET",
  OLD_WITHDRAWN__1_PLUS_OFFERS_REJECTED_ALREADY = "OLD_WITHDRAWN__1_PLUS_OFFERS_REJECTED_ALREADY",
  OLD_WITHDRAWN__WANTED_TO_KNOW_THEIR_BUDGET_ONLY = "OLD_WITHDRAWN__WANTED_TO_KNOW_THEIR_BUDGET_ONLY",
  OLD_WITHDRAWN__WANTS_TO_STOP_LOOKING_FOR_A_HOME_RIGHT_NOW = "OLD_WITHDRAWN__WANTS_TO_STOP_LOOKING_FOR_A_HOME_RIGHT_NOW",
  OLD_WITHDRAWN__NOT_IN_A_RUSH_TO_FIND_A_HOME = "OLD_WITHDRAWN__NOT_IN_A_RUSH_TO_FIND_A_HOME",
  OLD_WITHDRAWN__NEEDS_TO_MOVE_AT_A_FASTER_PACE = "OLD_WITHDRAWN__NEEDS_TO_MOVE_AT_A_FASTER_PACE",
  OLD_WITHDRAWN__FRUSTRATED_WITH_REAL_ESTATE_MARKET = "OLD_WITHDRAWN__FRUSTRATED_WITH_REAL_ESTATE_MARKET",
  OLD_WITHDRAWN__UNCERTAINTY_WITH_THE_REAL_ESTATE_MARKET = "OLD_WITHDRAWN__UNCERTAINTY_WITH_THE_REAL_ESTATE_MARKET",
  OLD_WITHDRAWN__FRUSTRATED_WITH_REAL_ESTATE_AGENT = "OLD_WITHDRAWN__FRUSTRATED_WITH_REAL_ESTATE_AGENT",
}

import { Dropdown } from "@/components/base";
import { usePortfolioProperty, useToast } from "@/hooks";
import { Entity } from "@/hooks/data-hooks/remediation/useEviction";
import { PortfolioProperty } from "@/types";
import { useFormik } from "formik";

interface Props {
  portfolioProperty: PortfolioProperty;
}

function EntitySection({ portfolioProperty }: Props) {
  const { updatePortfolioProperty } = usePortfolioProperty(portfolioProperty.propertyId);
  const { addToast } = useToast();
  const formik = useFormik({
    initialValues: {
      entity: portfolioProperty?.entity || "",
    },
    onSubmit: async (values) => {
      portfolioProperty.entity = values.entity;
      await updatePortfolioProperty(portfolioProperty);
      addToast({
        message: `Successfully changed Entity.`,
        type: "success",
      });
    },
  });

  const handleDropdownChange = (val: string) => {
    formik.setFieldValue("entity", val);
    formik.submitForm();
  };

  return (
    <>
      <Dropdown
        items={[
          { label: "Landis Properties I", value: Entity.LANDIS_I },
          { label: "Landis Properties II", value: Entity.LANDIS_II },
        ]}
        label="Entity"
        id="entity"
        required
        value={formik.values.entity}
        handleChange={handleDropdownChange}
        error={Boolean(formik.errors?.entity)}
        helperText={formik.errors?.entity?.toString() ?? ""}
      />
    </>
  );
}

export default EntitySection;

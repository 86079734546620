import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { useDefinedEnvelopes } from "@/hooks";
import { useMemo, useState } from "react";
import { Drawer, SkeletonList } from "@/components/base";

import DefinedEnvelopesTableHeader from "./DefinedEnvelopesTableHeader";
import { DefinedEnvelope } from "@/types";
import DefinedEnvelopeDetail from "./DefinedEnvelopeDetail";
import CreateDefinedEnvelopeForm from "./CreateDefinedEnvelopeForm";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No defined envelopes.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

const filterDefinedEnvelopes = (search: string) => (definedEnvelope: DefinedEnvelope) => {
  if (!search) return true;
  return (
    definedEnvelope.name.toLowerCase().includes(search.toLowerCase()) ||
    definedEnvelope.description.toLowerCase().includes(search.toLowerCase())
  );
};

function DefinedEnvelopesTable() {
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState<boolean>();
  const [search, setSearch] = useState<string>();
  const [selectedDefinedEnvelope, setSelectedDefinedEnvelope] = useState<DefinedEnvelope>();

  const { definedEnvelopes, loading, createDefinedEnvelope } = useDefinedEnvelopes();

  const filteredDefinedEnvelopes = useMemo(() => definedEnvelopes.filter(filterDefinedEnvelopes(search)), [search, definedEnvelopes]);

  const onCreateDefinedEnvelope = async (input: DefinedEnvelope) => {
    try {
      const newDefinedEnvelope = await createDefinedEnvelope(input);
      setIsAddDrawerOpen(false);
      setSelectedDefinedEnvelope(newDefinedEnvelope);
    } catch (e) {}
  };

  return (
    <>
      <TableLayout title={<DefinedEnvelopesTableHeader showAddForm={() => setIsAddDrawerOpen(true)} setSearch={setSearch} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredDefinedEnvelopes.length && !loading && <EmptyBody />}
            {loading && <LoadingBody />}
            {filteredDefinedEnvelopes.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => setSelectedDefinedEnvelope(row)}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="right">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setIsAddDrawerOpen(false)}
        open={isAddDrawerOpen}
        sx={{ width: 500, maxWidth: "85vw", mb: "12px" }}
      >
        <CreateDefinedEnvelopeForm onCreateDefinedEnvelope={onCreateDefinedEnvelope} />
      </Drawer>
      <Drawer
        anchor="right"
        onClose={() => setSelectedDefinedEnvelope(undefined)}
        open={!!selectedDefinedEnvelope}
        sx={{ width: 500, maxWidth: "85vw", mb: "12px" }}
      >
        <DefinedEnvelopeDetail definedEnvelope={selectedDefinedEnvelope} />
      </Drawer>
    </>
  );
}

export default DefinedEnvelopesTable;

import { bridgeV2ApiInstance } from "@/services";
import { DocumentsAutofillResponsePayload } from "@l4s/documents-autofill-models";
import { useEffect, useState } from "react";

export interface AutofillTemplateDisplayValue extends DocumentsAutofillResponsePayload {
  displayValue: string;
  urlToResource: string;
}

interface UsePersonTemplateAutofillDisplayValuesHook {
  displayValues: Array<AutofillTemplateDisplayValue>;
  loading: boolean;
}

export const usePersonTemplateAutofillDisplayValues = (
  personId: string,
  templateIds: Array<string>
): UsePersonTemplateAutofillDisplayValuesHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [displayValues, setDisplayValues] = useState<Array<AutofillTemplateDisplayValue>>([]);

  const getDisplayValues = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<AutofillTemplateDisplayValue>>(
        `/template-documents/template-autofills/person/${personId}/display`,
        {
          params: {
            templateIds: templateIds.join(","),
          },
        }
      );
      setDisplayValues(response.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDisplayValues();
  }, [personId, templateIds]);

  return {
    displayValues,
    loading,
  };
};

import { useToast } from "@/hooks";
import { bridgeV2ApiInstance } from "@/services";
import {
  ADD_TEMPLATE_TO_DEFINED_ENVELOPE,
  GET_TEMPLATES_IN_DEFINED_ENVELOPE,
  REMOVE_TEMPLATE_FROM_DEFINED_ENVELOPE,
} from "@/services/routes";
import { Template } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

export interface UseTemplatesInDefinedEnvelopeHook {
  templatesInDefinedEnvelope: Array<Template>;
  removeTemplateFromDefinedEnvelope: (templateId: string) => Promise<void>;
  addTemplateToDefinedEnvelope: (templateId: string) => Promise<void>;
  loading: boolean;
}

export const useTemplatesInDefinedEnvelope = (definedEnvelopeId: string | undefined) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templatesInDefinedEnvelope, setTemplatesInDefinedEnvelope] = useState<Array<Template>>([]);

  const { addToast } = useToast();

  const getTemplatesInDefinedEnvelope = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<Template>>(GET_TEMPLATES_IN_DEFINED_ENVELOPE(definedEnvelopeId));
      setTemplatesInDefinedEnvelope(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.response.data.message || "Failed to get templates in defined envelope",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const removeTemplateFromDefinedEnvelope = async (templateId: string) => {
    try {
      await bridgeV2ApiInstance.delete<void>(REMOVE_TEMPLATE_FROM_DEFINED_ENVELOPE(definedEnvelopeId, templateId));
      setTemplatesInDefinedEnvelope(templatesInDefinedEnvelope.filter((template) => template.id !== templateId));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.response.data.message || "Failed to remove template from defined envelope",
        type: "error",
      });
      throw error;
    }
  };

  const addTemplateToDefinedEnvelope = async (templateId: string) => {
    try {
      const response = await bridgeV2ApiInstance.post<Template>(ADD_TEMPLATE_TO_DEFINED_ENVELOPE(definedEnvelopeId), {
        templateId,
      });
      setTemplatesInDefinedEnvelope([...templatesInDefinedEnvelope, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.response.data.message || "Failed to add template to defined envelope",
        type: "error",
      });
      throw error;
    }
  };

  useEffect(() => {
    if (definedEnvelopeId) getTemplatesInDefinedEnvelope();
  }, [definedEnvelopeId]);

  return {
    addTemplateToDefinedEnvelope,
    removeTemplateFromDefinedEnvelope,
    templatesInDefinedEnvelope,
    loading,
  };
};

import { MainCard } from "@/components/berry";
import { DefinedEnvelope, FeatureFlag } from "@/types";
import { Grid, Typography } from "@mui/material";
import { AddTemplateToEnvelopeForm } from "../AddTemplateToEnvelopes";
import { Box } from "@mui/system";
import TemplatesInDefinedEnvelopeList from "./TemplatesInDefinedEnvelopeList";
import { useTemplatesInDefinedEnvelope } from "@/hooks";
import { useFeature } from "@/contexts";

interface Props {
  definedEnvelope: DefinedEnvelope | undefined;
}

const DefinedEnvelopeDetail = ({ definedEnvelope }: Props) => {
  const releaseCreateDefinedEnvelopes = useFeature(FeatureFlag.RELEASE_CREATE_DEFINED_ENVELOPES);
  const useTemplatesInDefinedEnvelopeInstance = useTemplatesInDefinedEnvelope(definedEnvelope?.id);
  const { addTemplateToDefinedEnvelope, templatesInDefinedEnvelope } = useTemplatesInDefinedEnvelopeInstance;

  const onAddTemplateToDefinedEnvelope = async (templateId: string) => {
    await addTemplateToDefinedEnvelope(templateId);
  };

  if (!definedEnvelope) return null;

  return (
    <Grid direction="column" container>
      <MainCard title={definedEnvelope.name}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: "normal" }}>
              {definedEnvelope.description}
            </Typography>
          </Grid>
        </Grid>
      </MainCard>
      <Box sx={{ mt: 1 }} />
      <TemplatesInDefinedEnvelopeList
        releaseCreateDefinedEnvelopes={releaseCreateDefinedEnvelopes}
        useTemplatesInDefinedEnvelopeInstance={useTemplatesInDefinedEnvelopeInstance}
      />
      <Box sx={{ mt: 1 }} />
      {releaseCreateDefinedEnvelopes && (
        <AddTemplateToEnvelopeForm
          templatesToFilter={templatesInDefinedEnvelope}
          title="Add Template to Defined Envelope"
          onAddTemplateToEnvelope={onAddTemplateToDefinedEnvelope}
        />
      )}
    </Grid>
  );
};

export default DefinedEnvelopeDetail;

import { useEffect, useState } from "react";
import { CardContent, Grid, Box, Typography, ToggleButton, ToggleButtonGroup, TextField, Button } from "@mui/material";

import { SubCard } from "@/components/berry";
import { DEFAULT_PADDING, GRID_SPACING } from "@/config";
import { FormikProps } from "formik";
import { ActionPriorityLevel, TypeOfAction } from "@/types";
import { mapActionTypes, mapActionTypesToSelectedButton } from "../ClientNote/actionMappers";
import { FormHelpers } from "@/utils";
import { Dropdown } from "@/components/base";

type TFormik = {
  name: string;
  description: string;
  type: TypeOfAction;
  dueDate?: string;
  publishDate?: string;
  link?: string;
  buttonText?: string;
  learnMoreLink?: string;
  priority?: ActionPriorityLevel;
  complete?: boolean;
};

interface Props {
  formik: FormikProps<TFormik>;
  modifying: boolean;
  isCompleted: boolean;
}

export enum ChooseActionType {
  BUTTON = "button",
  RESPONSE = "response",
  PULSE = "pulse",
  NONE = "none",
}

function ClientNoteActions({ formik, modifying, isCompleted }: Props) {
  const [actionType, setActionType] = useState<ChooseActionType>(ChooseActionType.NONE);
  const [titleText, setTitleText] = useState<string>("Add Action");

  useEffect(() => {
    if (formik.values.type) {
      const type: ChooseActionType = mapActionTypesToSelectedButton[formik.values.type];
      setActionType(type);
    }
    if (modifying) {
      setTitleText("Update Action");
    }
  }, [formik]);

  const label = actionType === ChooseActionType.BUTTON ? "Label" : "Prompt your client...";

  const handleActionType = (_event: React.SyntheticEvent, actionTypeSelected: ChooseActionType) => {
    setActionType(actionTypeSelected);
    formik.setFieldValue("type", mapActionTypes(actionTypeSelected));
  };

  return (
    <SubCard title={titleText} content={false}>
      <CardContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <Box sx={{ paddingBottom: DEFAULT_PADDING }}>
              <Typography variant="body1">Select the type of action you would like the client to complete</Typography>
            </Box>
            <Box>
              <ToggleButtonGroup value={actionType} exclusive onChange={handleActionType} aria-label="Select Action" color="primary">
                <ToggleButton value="none" aria-label="None">
                  None
                </ToggleButton>
                <ToggleButton value="button" aria-label="Button">
                  Button
                </ToggleButton>
                <ToggleButton value="response" aria-label="Response">
                  Response
                </ToggleButton>
                <ToggleButton value="pulse" aria-label="Pulse">
                  Pulse
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          {actionType !== ChooseActionType.NONE ? (
            <Grid item xs={12}>
              <Dropdown
                items={[
                  { label: "High", value: ActionPriorityLevel.HIGH },
                  { label: "Medium", value: ActionPriorityLevel.MEDIUM },
                  { label: "Low", value: ActionPriorityLevel.LOW },
                ]}
                label="Priority"
                id="priority"
                value={formik.values.priority}
                handleChange={(val) => formik.setFieldValue("priority", val)}
                error={FormHelpers.formikCheckError<TFormik>(formik, "priority")}
                helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "priority")}
              />
            </Grid>
          ) : (
            ""
          )}
          {actionType !== ChooseActionType.NONE ? (
            <Grid item xs={12}>
              <TextField
                id="ClientNoteActions.label"
                label={label}
                fullWidth
                spellCheck={false}
                onChange={(e) => {
                  formik.setFieldValue("buttonText", e?.target?.value);
                }}
                required={formik.values.type !== TypeOfAction.NO_ACTION}
                value={formik.values.buttonText}
                helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "buttonText")}
                error={FormHelpers.formikCheckError<TFormik>(formik, "buttonText")}
              />
            </Grid>
          ) : (
            ""
          )}
          {actionType === "button" && (
            <Grid item xs={12}>
              <TextField
                id="ClientNoteActions.link"
                label="Link URL"
                fullWidth
                spellCheck={false}
                onChange={(e) => {
                  formik.setFieldValue("link", e?.target?.value);
                }}
                value={formik.values.link}
              />
            </Grid>
          )}
          {modifying && !isCompleted ? (
            <Grid item xs={12}>
              <Button
                variant={formik.values.complete ? "outlined" : "contained"}
                id="ClientNoteActions.complete"
                onClick={() => {
                  if (!formik.values.complete) {
                    formik.setFieldValue("complete", true);
                  } else {
                    formik.setFieldValue("complete", false);
                  }
                }}
              >
                {formik.values.complete ? "Mark Action Incomplete" : "Mark Action Complete"}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </CardContent>
    </SubCard>
  );
}

export default ClientNoteActions;

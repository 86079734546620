import { OKTA_AUTH_REDIRECT } from "@/constant";
import { OKTA_AUTH } from "@/constant";

type AuthType = {
  oktaAuthToken: string | null;
  logout: () => void;
};

export function useAuth(): AuthType {
  function logout() {
    //this allows for the user to return to the same request after login complete
    localStorage.setItem(OKTA_AUTH_REDIRECT, window.location.href);
    localStorage.removeItem(OKTA_AUTH);
    window.open(`${window.location.origin}/login`, "_self");
  }

  const oktaAuthToken: string | null = localStorage.getItem(OKTA_AUTH);
  return {
    oktaAuthToken,
    logout,
  };
}

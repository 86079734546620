import { FilledTemplateFieldInTemplate, TemplateFieldToFill, isDocusignTemplateField } from "@/types";
import { colors } from "@mui/material";
import { useTheme } from "@mui/system";

export interface FieldStatusTheme {
  color: string;
  themeColor: "success" | "error" | "default" | "primary" | "secondary" | "info" | "warning";
  isSatisfied: boolean;
}

export const isTemplateFieldFilled = (templateField: TemplateFieldToFill): boolean => {
  return !!templateField.value || isDocusignTemplateField(templateField.type);
};

export const isFillTemplateFieldInTemplateFilled = (templateField: FilledTemplateFieldInTemplate): boolean => {
  return !!templateField.value || isDocusignTemplateField(templateField.templateFieldType);
};

export const useFieldStatusTheme = (templateField: TemplateFieldToFill | FilledTemplateFieldInTemplate): FieldStatusTheme => {
  const theme = useTheme();

  if ("templateFieldType" in templateField) {
    const filledTemplateField = templateField as FilledTemplateFieldInTemplate;
    const isSatisfied = isFillTemplateFieldInTemplateFilled(filledTemplateField) || !filledTemplateField.isRequired;

    return {
      color: isFillTemplateFieldInTemplateFilled(filledTemplateField)
        ? theme.palette.success.dark
        : !filledTemplateField.isRequired
        ? colors.grey[500]
        : theme.palette.error.main,
      themeColor: isFillTemplateFieldInTemplateFilled(filledTemplateField)
        ? "success"
        : !filledTemplateField.isRequired
        ? "default"
        : "error",
      isSatisfied,
    };
  }

  const templateFieldToFill = templateField as TemplateFieldToFill;
  const isSatisfied = isTemplateFieldFilled(templateFieldToFill) || !templateFieldToFill.isRequired;
  return {
    color: isTemplateFieldFilled(templateFieldToFill)
      ? theme.palette.success.dark
      : !templateFieldToFill.isRequired
      ? colors.grey[500]
      : theme.palette.error.main,
    themeColor: isTemplateFieldFilled(templateFieldToFill) ? "success" : !templateFieldToFill.isRequired ? "default" : "error",
    isSatisfied,
  };
};

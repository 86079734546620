// types
import { CustomizationDrawerProps } from "@/types/customization";
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState: CustomizationDrawerProps = {
  drawerOpen: false,
};

// ==============================|| SLICE - MENU ||============================== //

const customization = createSlice({
  name: "customization",
  initialState,
  reducers: {
    openCustomizationDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
  },
});

export const customizationReducer = customization.reducer;

export const { openCustomizationDrawer } = customization.actions;

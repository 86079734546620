import { CurrencyCentsTextField, LandisButton, PercentageTextField } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

const rawPercentageToString = (val: number | null) => {
  if (val === null) return "";
  return (val * 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
};

const percentageStringToNumber = (val: string) => {
  if (val === "") return null;
  return parseFloat(val) / 100;
};

function FinancialRatiosForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updateFinancialRatios } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      purchaseVsAsking: rawPercentageToString(applicationsPropertyFinance.purchaseVsAsking),
      purchaseVsAppraisals: rawPercentageToString(applicationsPropertyFinance.purchaseVsAppraisals),
      tenantsHocAfterYearCents: applicationsPropertyFinance.tenantsHocAfterYearCents,
      noiCents: applicationsPropertyFinance.noiCents,
      grossYield: rawPercentageToString(applicationsPropertyFinance.grossYield),
      capRate: rawPercentageToString(applicationsPropertyFinance.capRate),
      insurancePercentPrice: rawPercentageToString(applicationsPropertyFinance.insurancePercentPrice),
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateFinancialRatios({
          purchaseVsAsking: percentageStringToNumber(values.purchaseVsAsking),
          purchaseVsAppraisals: percentageStringToNumber(values.purchaseVsAppraisals),
          tenantsHocAfterYearCents: values.tenantsHocAfterYearCents,
          noiCents: values.noiCents,
          grossYield: percentageStringToNumber(values.grossYield),
          capRate: percentageStringToNumber(values.capRate),
          insurancePercentPrice: percentageStringToNumber(values.insurancePercentPrice),
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!!values.purchaseVsAsking && !isFinite(Number(values.purchaseVsAsking))) {
        errors.purchaseVsAsking = "Please enter a valid percentage.";
      }
      if (!!values.purchaseVsAppraisals && !isFinite(Number(values.purchaseVsAppraisals))) {
        errors.purchaseVsAppraisals = "Please enter a valid percentage.";
      }
      if (!!values.grossYield && !isFinite(Number(values.grossYield))) {
        errors.grossYield = "Please enter a valid percentage.";
      }
      if (!!values.capRate && !isFinite(Number(values.capRate))) {
        errors.capRate = "Please enter a valid percentage.";
      }
      if (!!values.insurancePercentPrice && !isFinite(Number(values.insurancePercentPrice))) {
        errors.insurancePercentPrice = "Please enter a valid percentage.";
      }

      return errors;
    },
  });

  return (
    <MainCard title="Edit Financial Ratios">
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid>
            <PercentageTextField
              variant="outlined"
              fullWidth
              label="Purchase vs. Asking"
              value={formik.values.purchaseVsAsking}
              handleChange={(val) => formik.setFieldValue("purchaseVsAsking", val)}
              error={!!formik.errors.purchaseVsAsking}
              helperText={formik.errors.purchaseVsAsking}
            />
          </Grid>
          <Grid>
            <PercentageTextField
              variant="outlined"
              fullWidth
              label="Purchase vs. Appraisals"
              value={formik.values.purchaseVsAppraisals}
              handleChange={(val) => formik.setFieldValue("purchaseVsAppraisals", val)}
              error={!!formik.errors.purchaseVsAppraisals}
              helperText={formik.errors.purchaseVsAppraisals}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Tenants HOC After a Year"
              value={formik.values.tenantsHocAfterYearCents}
              handleChange={(val) => formik.setFieldValue("tenantsHocAfterYearCents", val)}
              error={!!formik.errors.tenantsHocAfterYearCents}
              helperText={formik.errors.tenantsHocAfterYearCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="NOI"
              value={formik.values.noiCents}
              handleChange={(val) => formik.setFieldValue("noiCents", val)}
              error={!!formik.errors.noiCents}
              helperText={formik.errors.noiCents}
            />
          </Grid>
          <Grid>
            <PercentageTextField
              variant="outlined"
              fullWidth
              label="Gross Yield"
              value={formik.values.grossYield}
              handleChange={(val) => formik.setFieldValue("grossYield", val)}
              error={!!formik.errors.grossYield}
              helperText={formik.errors.grossYield}
            />
          </Grid>
          <Grid>
            <PercentageTextField
              variant="outlined"
              fullWidth
              label="Cap Rate"
              value={formik.values.capRate}
              handleChange={(val) => formik.setFieldValue("capRate", val)}
              error={!!formik.errors.capRate}
              helperText={formik.errors.capRate}
            />
          </Grid>
          <Grid>
            <PercentageTextField
              variant="outlined"
              fullWidth
              label="Insurance Percent of Price"
              value={formik.values.insurancePercentPrice}
              handleChange={(val) => formik.setFieldValue("insurancePercentPrice", val)}
              error={!!formik.errors.insurancePercentPrice}
              helperText={formik.errors.insurancePercentPrice}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default FinancialRatiosForm;

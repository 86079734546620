import { useEffect, useState } from "react";
import { Document, Template } from "@/types";
import { bridgeV2ApiInstance } from "@/services";
import {
  GET_AUTOFILL_DOCUMENT_BY_TEMPLATE_ID,
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATE_DOCUMENT_BY_TEMPLATE_ID,
  UPDATE_TEMPLATE_AUTOFILL_DOCUMENT,
} from "@/services/routes";
import { mapUnknownToAxiosError } from "@/utils";
import useToast from "@/hooks/useToast";

export interface UseTemplateHook {
  template: Template | undefined;
  updateAutofillDocument: (file: Blob) => Promise<void>;
  loading: boolean;
}

export interface UpdateAutofillDocumentInput {
  file: Blob;
}

export interface UpdateAutofillDocumentData extends FormData {
  append(name: keyof UpdateAutofillDocumentInput, value: string | Blob): void;
}

export const useTemplate = (templateId?: string): UseTemplateHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [template, setTemplate] = useState<Template>();

  const { addToast } = useToast();

  const getTemplate = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Template>(GET_TEMPLATE_BY_ID(templateId));
      setTemplate(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateAutofillDocument = async (file: Blob) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await bridgeV2ApiInstance.put<Template>(UPDATE_TEMPLATE_AUTOFILL_DOCUMENT(templateId), formData);
      setTemplate((prevTemplate) => ({ ...prevTemplate, ...response.data }));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  const getTemplateDocumentUrl = async () => {
    try {
      const response = await bridgeV2ApiInstance.get<Document & { url: string }>(GET_TEMPLATE_DOCUMENT_BY_TEMPLATE_ID(templateId));
      setTemplate((prevTemplate) => ({ ...prevTemplate, templateDocument: response.data }));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  const getAutofillReadyDocumentUrl = async () => {
    try {
      const response = await bridgeV2ApiInstance.get<Document & { url: string }>(GET_AUTOFILL_DOCUMENT_BY_TEMPLATE_ID(templateId));
      setTemplate((prevTemplate) => ({ ...prevTemplate, autofillReadyDocument: response.data }));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    setTemplate(undefined);
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  useEffect(() => {
    if (template && !template.templateDocument) {
      getTemplateDocumentUrl();
    }
  }, [template]);

  useEffect(() => {
    if (template && template.autofillReadyDocumentId && !template.autofillReadyDocument) {
      getAutofillReadyDocumentUrl();
    }
  }, [template, template?.autofillReadyDocumentId]);

  return {
    template,
    updateAutofillDocument,
    loading,
  };
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateTaxesPaymentDetailsInput, UpdateTaxesPaymentDetailsInput } from "@l4s/client-property-models";

import { TaxesPaymentDetails } from "@/types/PropertyManagement";

export interface UseTaxesPaymentDetails {
  loading: boolean;
  taxesPaymentDetails: Array<TaxesPaymentDetails>;
  createTaxesPaymentDetails: (input: CreateTaxesPaymentDetailsInput) => Promise<void>;
  updateTaxesPaymentDetails: (input: UpdateTaxesPaymentDetailsInput) => Promise<void>;
  reloadTaxesPaymentDetails: () => Promise<void>;
  error: string | undefined;
}

export const useTaxesPaymentDetails = (propertyId: string): UseTaxesPaymentDetails => {
  const [loading, setLoading] = useState<boolean>(false);
  const [taxesPaymentDetails, setTaxesPaymentDetails] = useState<Array<TaxesPaymentDetails>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getTaxesPaymentDetails = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<TaxesPaymentDetails>>(routes.GET_TAXES_PAYMENT_DETAILS, {
        params: { propertyId },
      });
      setTaxesPaymentDetails(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching application property tax payment details.");
    }
    setLoading(false);
  };

  const createTaxesPaymentDetails = async (input: CreateTaxesPaymentDetailsInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<TaxesPaymentDetails>(routes.CREATE_TAX_PAYMENT_DETAIL, {
        ...input,
      });
      setTaxesPaymentDetails((existingTaxesPaymentDetails) => [...existingTaxesPaymentDetails, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating tax payment detail.");
    }
  };

  const updateTaxesPaymentDetails = async (input: UpdateTaxesPaymentDetailsInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<TaxesPaymentDetails>(routes.UPDATE_TAX_PAYMENT_DETAIL(input.id), input);
      setTaxesPaymentDetails((existingTaxesPaymentDetailss) =>
        existingTaxesPaymentDetailss.map((taxPaymentDetail) =>
          taxPaymentDetail.id === response.data.id ? { ...taxPaymentDetail, ...response.data } : taxPaymentDetail
        )
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating tax payment detail.");
    }
  };

  useEffect(() => {
    if (propertyId) {
      getTaxesPaymentDetails();
    }
  }, [propertyId]);

  return {
    loading,
    taxesPaymentDetails,
    createTaxesPaymentDetails,
    updateTaxesPaymentDetails,
    reloadTaxesPaymentDetails: getTaxesPaymentDetails,
    error,
  };
};

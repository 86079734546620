import { LAYOUT_CONST, DEFAULT_PADDING as C_DEFAULT_PADDING } from "@/constant";

// types
import { ConfigProps } from "@/types/config";
import { env } from "./env";

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

export const DASHBOARD_PATH = "/";

export const HORIZONTAL_MAX_ITEM = 6;

export const LAYOUT_CONFIGS: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Inter', sans-serif`,
  borderRadius: 4,
  outlinedFilled: true,
  navType: "light", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
  breadcrumbs: true,
};

// theme constant
export const GRID_SPACING = 3;
export const DRAWER_WIDTH = 300;
export const APP_DRAWER_WIDTH = 320;
export const DEFAULT_PADDING = C_DEFAULT_PADDING;
export const DEFAULT_PADDING_2X = "24px";
export const DEFAULT_PADDING_3X = "36px";

// okta auth parameters
export const OKTA_AUTH_CLIENT_ID = "0oa2m74fjd1w1WITC697";
export const OKTA_AUTH_ISSUER = "https://landis.okta.com/oauth2/aus2t01c0usNMMnrG697";
export const OKTA_AUTH_CALLBACK = "/login/callback";

//Impersonation Path URL
export const PATH_IMPERSONATION_URL = env.isProdEnv
  ? "https://internal-path.landis.com/dashboard/main"
  : "https://dev-internal-path.landis.com/dashboard/main";

import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { SkeletonList } from "@/components/base";
import { useNavigate } from "react-router-dom";
import { LiabilityWithEntityName } from "@l4s/person-finances-models";
import { DateFormat, displayUsCents } from "@/utils";
import moment from "moment";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No Credit Tradelines.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={1} height={50} />
      </TableCell>
    </TableRow>
  );
}

interface Props {
  liabilities: Array<LiabilityWithEntityName>;
}

function CreditLiabilityTable({ liabilities }: Props) {
  const navigate = useNavigate();

  return (
    <TableLayout
      maxHeight="70vh"
      title={
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={5} container direction="column" gap={0.5}>
            <Typography variant="h2">{"Credit Tradelines"}</Typography>
          </Grid>
        </Grid>
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date Opened</TableCell>
            <TableCell>Loan Type</TableCell>
            <TableCell>Credit Limit</TableCell>
            <TableCell>Unpaid Balance</TableCell>
            <TableCell>Monthly Payment</TableCell>
            <TableCell>Amount Pastdue</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Term</TableCell>
            <TableCell>Recent Rating</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!liabilities.length && <EmptyBody />}
          {!liabilities && <LoadingBody />}
          {liabilities.map((row) => (
            <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
              <TableCell>{row.entityName}</TableCell>
              <TableCell>{moment(row.accountTimelineOpened).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
              <TableCell>{row.businessLoan}</TableCell>
              <TableCell>{displayUsCents(row.amountCreditLimitCents)}</TableCell>
              <TableCell>{displayUsCents(row.amountBalanceUnpaidCents)}</TableCell>
              <TableCell>{displayUsCents(row.amountMonthlyPaymentCents)}</TableCell>
              <TableCell>{displayUsCents(row.amountPastdueCents)}</TableCell>
              <TableCell>{row.accountStatus}</TableCell>
              <TableCell>{row.termsMonths}</TableCell>
              <TableCell>{row.ratingsRecentType}</TableCell>
              <TableCell align="right">
                <ChevronRight />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableLayout>
  );
}

export default CreditLiabilityTable;

import { Tab } from "@mui/material";

import { Tabs } from "@/components/base";
import { CoachExitPlan, CoachHardshipReason, EvictionForm } from "@/components/partial";
import { CoachPaymentPlan } from "../CoachPaymentPlan";
import { ProgramClient, SearchParamKeys } from "@/types";

//assets
import { RemediationTabOptions, tabsOptions } from "./TabOptions";
import { TabPanel } from "@mui/lab";
import { useCashForKeysOffers, useExitPlan, useHardships, usePaymentPlans } from "@/hooks";

interface Props {
  programClient: ProgramClient;
}

function CoachingRentalPayment({ programClient }: Props) {
  const useExitPlanInstance = useExitPlan(programClient.id);

  return (
    <Tabs
      tabsIds={Object.values(RemediationTabOptions).map((opt) => opt)}
      urlSearchParamName={SearchParamKeys.REMEDIATION_PAYMENT_PLAN_TAB}
      tabToStart={RemediationTabOptions.PAYMENT_PLAN}
      orientation="horizontal"
      TabListComponent={
        <>
          {tabsOptions.map((tab, _index) => (
            <Tab key={tab.id} value={tab.id} iconPosition="start" icon={tab.icon} label={tab.label} />
          ))}
        </>
      }
      TabPanelComponent={
        <>
          <TabPanel sx={{ padding: 0 }} value={RemediationTabOptions.PAYMENT_PLAN}>
            {/* usePaymentPlans instantiated at the top level to prevent refresh in between tab switch */}
            <CoachPaymentPlan usePaymentPlans={usePaymentPlans(programClient.id)} programClientId={programClient.id} />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value={RemediationTabOptions.HARD_SHIP}>
            <CoachHardshipReason useHardships={useHardships(programClient.id)} />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value={RemediationTabOptions.EXIT_PLAN}>
            <CoachExitPlan
              useCashForKeysOffers={useCashForKeysOffers(useExitPlanInstance.exitPlan?.id)}
              useExitPlan={useExitPlanInstance}
              programClient={programClient}
            />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value={RemediationTabOptions.EVICTION}>
            <EvictionForm programClientId={programClient.id} personId={programClient.personId} />
          </TabPanel>
        </>
      }
    />
  );
}

export default CoachingRentalPayment;

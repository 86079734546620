import { useEffect, useState } from "react";

import { Box, Button, CardActions, CardContent, Divider, Grid, TableCell, TableRow, Typography, useTheme } from "@mui/material";

import { Drawer, LandisButton, TableFrame } from "@/components/base";
import { MainCard } from "@/components/berry";

import ClientGraduationAddUpdateGoal from "./ClientGraduationAddUpdateGoal";
import ClientGraduationSelectGoalPlan from "./ClientGraduationSelectGoalPlan";
import { GoalTypeMap, Goal, GoalStatusMap, ProgramClient } from "@/types";
import { UseCoachingHistory, useGoals } from "@/hooks/data-hooks/coaching";

import { DeleteOutline } from "@mui/icons-material";
import { mapUnknownToError } from "@/utils";
import { useToast } from "@/hooks";
import CoachingSnapshotForm from "./CoachingSnapshots/CoachingSnapshotForm";

interface Props {
  useCoachingHistory: UseCoachingHistory;
  programClient: ProgramClient;
}

function ClientGraduation({ useCoachingHistory, programClient }: Props) {
  const useGoalInstance = useGoals(programClient.id);
  const theme = useTheme();
  const { goals, loadGoals, deactivateGoal } = useGoalInstance;

  useEffect(() => {
    loadGoals();
  }, []);

  const { addToast } = useToast();

  const handleDeactivate = async (goalId) => {
    try {
      await deactivateGoal(goalId);
      addToast({ message: "Successfully deactivated goal!" });
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
  };

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerShow, setDrawerShow] = useState<"MODIFY" | "ADD" | "CHECKIN">("ADD");
  const [goal, setGoal] = useState<Goal>();

  return (
    <MainCard title={"Goals"} content={false}>
      <CardContent sx={{ p: 0 }}>
        {Boolean(goals.length) ? (
          <TableFrame
            TableHeaderCellComponents={
              <>
                <TableCell sx={{ pl: 3 }}>Goal</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Current</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </>
            }
            TableBodyComponents={
              <>
                {(goals ?? []).map(
                  (row, index) =>
                    row.isActive && (
                      <TableRow hover key={index}>
                        <TableCell
                          sx={{ pl: 3 }}
                          onClick={() => {
                            setDrawerOpen(true);
                            setDrawerShow("MODIFY");
                            setGoal(row);
                          }}
                        >
                          <Grid container spacing={2} alignItems="center" sx={{ flexWrap: "nowrap" }}>
                            <Grid item>
                              <Box
                                sx={{
                                  borderRadius: "50%",
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: theme.palette.primary.light,
                                }}
                              />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                              <Typography component="div" align="left" variant="subtitle1">
                                {row.goalTitle || GoalTypeMap[row.type.name]}
                              </Typography>
                              <Typography component="div" align="left" variant="subtitle2">
                                {row.goalDescription}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>{row.targetValue}</TableCell>
                        <TableCell>{row.currentValue}</TableCell>
                        <TableCell>{GoalStatusMap[row.status]}</TableCell>
                        <TableCell>
                          <LandisButton
                            onClick={() => {
                              handleDeactivate(row.id);
                            }}
                          >
                            <DeleteOutline />
                          </LandisButton>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </>
            }
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              Create Goal Plan
            </Button>
          </Box>
        )}
      </CardContent>
      {Boolean(goals.length) ? (
        <>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setDrawerOpen(true);
                setDrawerShow("CHECKIN");
              }}
            >
              Check In
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setDrawerOpen(true);
                setDrawerShow("ADD");
              }}
            >
              Add more goals
            </Button>
          </CardActions>
        </>
      ) : null}
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: drawerShow === "CHECKIN" ? 1000 : 600,
          },
        }}
      >
        {!goals.length ? (
          /*If the client does not have an goals set pick a goal plan */
          <ClientGraduationSelectGoalPlan useGoals={useGoalInstance} close={() => setDrawerOpen(false)} />
        ) : /*If the client has goals set modify */
        drawerShow === "MODIFY" ? (
          <ClientGraduationAddUpdateGoal useGoals={useGoalInstance} goal={goal} close={() => setDrawerOpen(false)} type={"Update"} />
        ) : /*If the coach is doing a checkin */
        drawerShow === "CHECKIN" ? (
          <CoachingSnapshotForm useCoachingHistory={useCoachingHistory} close={() => setDrawerOpen(false)} />
        ) : drawerShow === "ADD" ? (
          /*If the client has goals set add */
          <ClientGraduationAddUpdateGoal useGoals={useGoalInstance} close={() => setDrawerOpen(false)} type={"Add"} />
        ) : null}
      </Drawer>
    </MainCard>
  );
}

export default ClientGraduation;

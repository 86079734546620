import { Route, Routes } from "react-router-dom";

import { NotFoundPage, AdminListProperties, PropertyDetail } from "@/components/view";

const AdminPropertiesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminListProperties />} />
      <Route path="/:propertyId" element={<PropertyDetail />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AdminPropertiesRouter;

import { bridgeV2ApiInstance } from "@/services";
import {
  CREATE_PROPERTY_EVALUATION,
  GET_PROPERTY_EVALUATIONS,
  SEND_PROPERTY_EVALUATION_UPDATE_NOTIFICATION,
  SUBMIT_PROPERTY_EVALUATION,
} from "@/services/routes";
import { mapUnknownToAxiosError } from "@/utils";
import { PropertyEvaluationDto, StatusOfPropertyEvaluation } from "@l4s/client-property-models";
import { useState } from "react";

export interface UsePropertyEvaluationsHookInstance {
  loading: boolean;
  error?: string;
  getPropertyEvaluations: () => Promise<void>;
  propertyEvaluations: Array<PropertyEvaluationDto>;
  currentPropertyEvaluationStatus?: StatusOfPropertyEvaluation;
  createPropertyEvaluation: (propertyId: string) => Promise<void>;
  creatingPropertyEvaluation: boolean;
  submitPropertyEvaluation: (propertyEvaluationId: string, shouldSendClientUpdateNotification: boolean) => Promise<void>;
  submittingPropertyEvaluation: boolean;
}

export const usePropertyEvaluations = (propertyId: string): UsePropertyEvaluationsHookInstance => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [propertyEvaluations, setPropertyEvaluations] = useState<Array<PropertyEvaluationDto>>([]);
  const [currentPropertyEvaluationStatus, setCurrentPropertyEvaluationStatus] = useState<StatusOfPropertyEvaluation>();
  const [creatingPropertyEvaluation, setCreatingPropertyEvaluation] = useState<boolean>(false);
  const [submittingPropertyEvaluation, setSubmittingPropertyEvaluation] = useState<boolean>(false);

  const getPropertyEvaluations = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<PropertyEvaluationDto>>(GET_PROPERTY_EVALUATIONS(propertyId));
      setPropertyEvaluations(response.data);
      const currentPropertyEvaluation = response.data[0];
      if (currentPropertyEvaluation) {
        setCurrentPropertyEvaluationStatus(currentPropertyEvaluation.status);
      }
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property evaluations.");
      setLoading(false);
      throw new Error(err.response.data.message || "Error fetching property evaluations.");
    }
    setLoading(false);
  };

  const createPropertyEvaluation = async (): Promise<void> => {
    setCreatingPropertyEvaluation(true);
    try {
      await bridgeV2ApiInstance.post<PropertyEvaluationDto>(CREATE_PROPERTY_EVALUATION, {
        propertyId,
      });
    } catch (e) {
      setCreatingPropertyEvaluation(false);
      throw new Error(e.response.data.message || "Error creating property evaluation.");
    }
    setCreatingPropertyEvaluation(false);
  };

  const submitPropertyEvaluation = async (propertyEvaluationId: string, shouldSendClientUpdateNotification: boolean): Promise<void> => {
    setSubmittingPropertyEvaluation(true);

    try {
      await bridgeV2ApiInstance.put<PropertyEvaluationDto>(SUBMIT_PROPERTY_EVALUATION, {
        propertyEvaluationId,
      });

      // send the client an update notification if requested
      if (shouldSendClientUpdateNotification) {
        await bridgeV2ApiInstance.post<undefined>(SEND_PROPERTY_EVALUATION_UPDATE_NOTIFICATION, {
          propertyEvaluationId,
        });
      }
    } catch (e) {
      throw new Error(e.response.data.message || "Error submitting property evaluation.");
    } finally {
      setSubmittingPropertyEvaluation(false);
    }
  };

  return {
    loading,
    error,
    getPropertyEvaluations,
    propertyEvaluations,
    currentPropertyEvaluationStatus,
    createPropertyEvaluation,
    creatingPropertyEvaluation,
    submitPropertyEvaluation,
    submittingPropertyEvaluation,
  };
};

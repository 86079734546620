import { DateFormat } from "@/utils";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

interface Props {
  latestReportDate: string;
}

function CurrentCreditScoreAndDateHeader({ latestReportDate }: Props) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5} container direction="column" gap={0.5}>
        <Typography variant="h2">Latest Credit Report: {moment(latestReportDate).format(DateFormat.DISPLAY_DATE_RANGE)}</Typography>
      </Grid>
    </Grid>
  );
}

export default CurrentCreditScoreAndDateHeader;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { Goal } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";
import { sortByDate } from "@/utils/sort";

interface ActionGoal {
  actionId: string;
  goalId: string;
}

export interface UseActionsGoals {
  loadGoalsForAction: () => Promise<void>;
  goalsForAction: Array<Goal>;
  isLoading: boolean;
  assignGoalsToAction: (input: string[]) => Promise<Array<ActionGoal>>;
}

const sortActionsDesc = (goal: Array<Goal>) => {
  return goal.sort(sortByDate("created", false));
};

export const useActionsGoals = (actionId: string): UseActionsGoals => {
  const [goalsForAction, setGoalsForAction] = useState<Array<Goal>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadGoalsForAction = async () => {
    if (!actionId || Boolean(goalsForAction?.length)) return;
    try {
      setIsLoading(true);
      const response = await bridgeV2ApiInstance.get<Array<Goal>>(routes.GET_GOALS_BY_ACTION_ID(actionId));
      setGoalsForAction(sortActionsDesc(response.data));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals for action.");
    } finally {
      setIsLoading(false);
    }
  };

  const assignGoalsToAction = async (input: string[]): Promise<Array<ActionGoal>> => {
    if (!actionId) return;
    try {
      const response = await bridgeV2ApiInstance.post<Array<ActionGoal>>(routes.ASSIGN_GOALS_TO_ACTION(actionId), input);
      loadGoalsForAction();
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error assigning goals to action.");
    }
  };

  return {
    goalsForAction,
    isLoading,
    loadGoalsForAction,
    assignGoalsToAction,
  };
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateRentalInsuranceInput, UpdateRentalInsuranceInput } from "@l4s/client-property-models";

import { RentalInsurance } from "@/types/PropertyManagement";

export interface UseRentalInsurance {
  loading: boolean;
  propertyRentalInsurance: Array<RentalInsurance>;
  reloadPropertyRentalInsurance: () => Promise<void>;
  error: string | undefined;
  createRentalInsurance: (input: CreateRentalInsuranceInput) => Promise<void>;
  updateRentalInsurance: (input: UpdateRentalInsuranceInput) => Promise<void>;
}

export const useRentalInsurance = (propertyId: string): UseRentalInsurance => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyRentalInsurance, setPropertyRentalInsurance] = useState<Array<RentalInsurance>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getRentalInsuranceByPropertyId = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<RentalInsurance>>(routes.GET_RENTAL_INSURANCE_BY_PROPERTY_ID, {
        params: { propertyId },
      });
      setPropertyRentalInsurance(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property rental insurance.");
    }
    setLoading(false);
  };

  const createRentalInsurance = async (input: CreateRentalInsuranceInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<RentalInsurance>(routes.CREATE_RENTAL_INSURANCE, { ...input });
      setPropertyRentalInsurance((existingRentalInsurance) => [...existingRentalInsurance, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating rental insurance.");
    }
  };

  const updateRentalInsurance = async (input: UpdateRentalInsuranceInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<RentalInsurance>(routes.UPDATE_RENTAL_INSURANCE(input.id), input);
      setPropertyRentalInsurance((existingRentalInsurance) =>
        existingRentalInsurance.map((rentalInsurance) =>
          rentalInsurance.id === response.data.id ? { ...rentalInsurance, ...response.data } : rentalInsurance
        )
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating rental insurance.");
    }
  };

  useEffect(() => {
    if (propertyId) getRentalInsuranceByPropertyId();
  }, [propertyId]);

  return {
    loading,
    propertyRentalInsurance,
    reloadPropertyRentalInsurance: getRentalInsuranceByPropertyId,
    error,
    createRentalInsurance,
    updateRentalInsurance,
  };
};

import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ToastProvider, ConfigProvider, FeatureHubProvider } from "@/contexts";
import { env } from "@/env";
import { store } from "@/store";
import { ThemeCustomization } from "@/styles/themes";
import { logToConsoleDebug } from "@/utils";

import { Locales, RTLLayout, Snackbar } from "@/components/berry";
import { NavigationScroll, Customization } from "@/components/layout";
import { RootRouter } from "@/components/router";

const Root = () => {
  logToConsoleDebug("App.tsx", "Initializing Application", [env]);

  return (
    <Provider store={store}>
      <FeatureHubProvider>
        <ToastProvider>
          <ConfigProvider>
            <ThemeCustomization>
              <RTLLayout>
                <Locales>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <NavigationScroll>
                      <>
                        <RootRouter />
                        <Snackbar />
                        <Customization showLiveCustomizationWidget={false} />
                      </>
                    </NavigationScroll>
                  </LocalizationProvider>
                </Locales>
              </RTLLayout>
            </ThemeCustomization>
          </ConfigProvider>
        </ToastProvider>
      </FeatureHubProvider>
    </Provider>
  );
};

export default Root;

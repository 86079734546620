import { ReactNode, useEffect } from "react";

import { useToast, useAuth, useAuthenticatedAccount, useClientMenuItems } from "@/hooks";
import { useDispatch, useSelector } from "@/store";
import { setAuthenticatedAccount } from "@/store/slices";
import { logToConsoleDebug } from "@/utils";

interface Props {
  children: ReactNode;
}

const UserProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  // const { initializeGlobalClientMenuItems } = useClientMenuItems();
  const authenticatedAccount = useSelector((state) => state.authenticatedAccount);

  // useEffect(() => {
  //   dispatch(initializeGlobalClientMenuItems());
  // }, []);

  const { getAuthenticatedAccount } = useAuthenticatedAccount();

  //make sure this doesn't create too many network requests
  //we have a lightweight check in OktaAuthCheck.tsx
  //we can handle this same action in bridge-v2 client when response = 401
  useEffect(() => {
    (async () => {
      try {
        dispatch(setAuthenticatedAccount(await getAuthenticatedAccount()));
        logToConsoleDebug("UserProvider.tsx", "Bridge user loaded", [authenticatedAccount]);
      } catch (e) {
        addToast({ message: "", type: "error" });
        useAuth().logout();
      }
    })();
  }, []);

  return <>{children}</>;
};

export default UserProvider;

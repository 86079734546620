import { Dropdown } from "@/components/base";
import { DropdownItem } from "@/components/base/Dropdown";
import { GRID_SPACING } from "@/config";
import { CreateOrderFormInput, Field, LABEL } from "@/hooks/data-hooks/inspection-orders";
import { TypeOfOrderStakeholder } from "@/types";
import { FormHelpers, snakeCaseToDisplay } from "@/utils";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";

type TypeOfStakeholderDropDownItem = DropdownItem<TypeOfOrderStakeholder>;
const TYPE_OF_STAKEHOLDER_OPTIONS: Array<TypeOfStakeholderDropDownItem> = Object.values(TypeOfOrderStakeholder)
  .filter((tos) => ![TypeOfOrderStakeholder.LISTING_AGENT].includes(tos))
  .map((v) => ({ value: v, label: snakeCaseToDisplay(v) }));

interface OrderStakeholderFieldsetProps {
  index: number;
  formik: ReturnType<typeof useFormik<CreateOrderFormInput>>;
  disabled?: boolean;
}

function OrderStakeholderFieldset({ index, formik, disabled = false }: OrderStakeholderFieldsetProps) {
  const ORDER_STAKEHOLDERS_TYPE_KEY = `${Field.ORDER_STAKEHOLDERS}[${index}].${Field.ORDER_STAKEHOLDERS__TYPE}`;
  const ORDER_STAKEHOLDERS_NAME_KEY = `${Field.ORDER_STAKEHOLDERS}[${index}].${Field.ORDER_STAKEHOLDERS__NAME}`;
  const ORDER_STAKEHOLDERS_PHONE_KEY = `${Field.ORDER_STAKEHOLDERS}[${index}].${Field.ORDER_STAKEHOLDERS__PHONE}`;
  const ORDER_STAKEHOLDERS_EMAIL_KEY = `${Field.ORDER_STAKEHOLDERS}[${index}].${Field.ORDER_STAKEHOLDERS__EMAIL}`;
  return (
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={6}>
        <Dropdown
          id={ORDER_STAKEHOLDERS_TYPE_KEY}
          items={TYPE_OF_STAKEHOLDER_OPTIONS}
          value={formik.values[Field.ORDER_STAKEHOLDERS][index][Field.ORDER_STAKEHOLDERS__TYPE]}
          handleChange={(value) => FormHelpers.handleFakeEvent(formik, ORDER_STAKEHOLDERS_TYPE_KEY, value)}
          label={LABEL[Field.ORDER_STAKEHOLDERS__TYPE]}
          disabled={disabled}
          helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_TYPE_KEY)}
          error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_TYPE_KEY)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={ORDER_STAKEHOLDERS_NAME_KEY}
          name={ORDER_STAKEHOLDERS_NAME_KEY}
          label={LABEL[Field.ORDER_STAKEHOLDERS__NAME]}
          fullWidth
          spellCheck={false}
          onChange={formik.handleChange}
          value={formik.values[Field.ORDER_STAKEHOLDERS][index][Field.ORDER_STAKEHOLDERS__NAME]}
          helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_NAME_KEY)}
          error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_NAME_KEY)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={ORDER_STAKEHOLDERS_PHONE_KEY}
          name={ORDER_STAKEHOLDERS_PHONE_KEY}
          label={LABEL[Field.ORDER_STAKEHOLDERS__PHONE]}
          fullWidth
          spellCheck={false}
          onChange={formik.handleChange}
          value={formik.values[Field.ORDER_STAKEHOLDERS][index][Field.ORDER_STAKEHOLDERS__PHONE]}
          helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_PHONE_KEY)}
          error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_PHONE_KEY)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={ORDER_STAKEHOLDERS_EMAIL_KEY}
          name={ORDER_STAKEHOLDERS_EMAIL_KEY}
          label={LABEL[Field.ORDER_STAKEHOLDERS__EMAIL]}
          fullWidth
          spellCheck={false}
          onChange={formik.handleChange}
          value={formik.values[Field.ORDER_STAKEHOLDERS][index][Field.ORDER_STAKEHOLDERS__EMAIL]}
          helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_EMAIL_KEY)}
          error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, ORDER_STAKEHOLDERS_EMAIL_KEY)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export default OrderStakeholderFieldset;

import { TypeOfTax } from "@l4s/client-property-models";

export enum Field {
  PAYMENT_FREQUENCY = "paymentFrequency",
  INITIAL_PAYMENT_DATE = "initialPaymentDate",
  START_DATE = "startDate",
  END_DATE = "endDate",
  TAX_ID = "taxId",
}

export const TypeOfTaxDisplayMap: Record<TypeOfTax, string> = {
  [TypeOfTax.CITY]: "City",
  [TypeOfTax.COUNTY]: "County",
  [TypeOfTax.MUNICIPAL]: "Municipal",
  [TypeOfTax.OTHER]: "Other",
  [TypeOfTax.SCHOOL]: "School",
};

import { WorkOrderStatus } from "@/types";
import { Chip, ChipProps, Typography } from "@mui/material";
import { FC } from "react";
import {
  CheckCircleOutlineOutlined,
  CancelOutlined,
  HelpOutlineOutlined,
  HourglassEmptyOutlined,
  InfoOutlined,
  MonetizationOnOutlined,
  PeopleOutlined,
  ScheduleOutlined,
  SettingsOutlined,
  SyncProblemOutlined,
} from "@mui/icons-material";

interface WorkOrderStatusChipOption {
  icon?: ChipProps["icon"];
  color?: ChipProps["color"];
}
const workOrderStatusToChipOptionMap: Record<WorkOrderStatus, WorkOrderStatusChipOption> = {
  [WorkOrderStatus.PENDING]: {
    icon: <HourglassEmptyOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.CANCELLED]: {
    icon: <CancelOutlined />,
    color: "error",
  },
  [WorkOrderStatus.COMPLETED]: {
    icon: <CheckCircleOutlineOutlined />,
    color: "success",
  },
  [WorkOrderStatus.WAITING_FOR_MATERIALS]: {
    icon: <HelpOutlineOutlined />,
    color: "info",
  },
  [WorkOrderStatus.TROUBLESHOOTING]: {
    icon: <SyncProblemOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.DIAGNOSING_ISSUE]: {
    icon: <InfoOutlined />,
    color: "info",
  },
  [WorkOrderStatus.PENDING_AWAITING_PAYMENT_DETAILS]: {
    icon: <MonetizationOnOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.FORWARDED_TO_PM]: {
    icon: <SettingsOutlined />,
    color: "info",
  },
  [WorkOrderStatus.FINDING_SERVICE_PROVIDER]: {
    icon: <PeopleOutlined />,
    color: "info",
  },
  [WorkOrderStatus.AWAITING_WORK_DATE]: {
    icon: <ScheduleOutlined />,
    color: "info",
  },
  [WorkOrderStatus.CONFIRMING_COMPLETION]: {
    icon: <CheckCircleOutlineOutlined />,
    color: "info",
  },
  [WorkOrderStatus.NEEDS_OWNER_APPROVAL]: {
    icon: <InfoOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.WAITING_FOR_PM]: {
    icon: <HourglassEmptyOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.NEEDS_APPROVAL]: {
    icon: <InfoOutlined />,
    color: "warning",
  },
  [WorkOrderStatus.AWAITING_INVOICE]: {
    icon: <MonetizationOnOutlined />,
    color: "info",
  },
  [WorkOrderStatus.SCHEDULING_RESIDENT]: {
    icon: <ScheduleOutlined />,
    color: "info",
  },
  [WorkOrderStatus.SCHEDULING_SERVICE_PROVIDER]: {
    icon: <ScheduleOutlined />,
    color: "info",
  },
  [WorkOrderStatus.RESCHEDULING_RESIDENT]: {
    icon: <ScheduleOutlined />,
    color: "info",
  },
};

interface WorkOrderStatusChipProps extends ChipProps {
  status: WorkOrderStatus;
}

const WorkOrderStatusChip: FC<WorkOrderStatusChipProps> = ({ status, ...props }) => {
  const { icon, color } = workOrderStatusToChipOptionMap[status];
  return (
    <Chip
      size="small"
      icon={icon}
      color={color}
      label={
        <Typography color="inherit" variant="h6">
          {status}
        </Typography>
      }
      {...props}
    />
  );
};

export default WorkOrderStatusChip;

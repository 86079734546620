import { useEffect, useState } from "react";
import { Box, Button, TableCell, TableRow, CardActions, Divider, Drawer } from "@mui/material";

import { TableFrame } from "@/components/base";
import { MainCard } from "@/components/berry";
import { DEFAULT_PADDING } from "@/config";

import CreateClientNote from "./CreateClientNote";
import { useActions, useCoachingArticles } from "@/hooks";
import moment from "moment";
import { mapActionTypesToDisplayText } from "./actionMappers";
import { Action, StatusOfAction } from "@/types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
  programClientId: string;
}

/*   If the action is completed it should be at the bottom of the list (the bigger number).
If the action is not complete sort it by Due Date. 
First Due Date, then not complete, then complete */
const sortActionsPrioritizeDueDate = (firstAction: Action, nextAction: Action): number => {
  if (firstAction.completionDate && nextAction.completionDate) {
    return new Date(nextAction.created) > new Date(firstAction.created) ? -1 : 1;
  }
  if (firstAction.completionDate) {
    return 1;
  }
  if (nextAction.completionDate) {
    return -1;
  }
  if (firstAction.dueDate && nextAction.dueDate) {
    const nextDueDate = new Date(nextAction.dueDate);
    const firstDueDate = new Date(firstAction.dueDate);

    if (firstDueDate.getTime() === nextDueDate.getTime()) {
      return new Date(nextAction.created) > new Date(firstAction.created) ? -1 : 1;
    }

    return firstDueDate.getTime() > nextDueDate.getTime() ? 1 : -1;
  }
  return firstAction.dueDate ? -1 : 1;
};

function ClientNoteTableModule({ programClientId }: Props) {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerShow, setDrawerShow] = useState<"MODIFY" | "ADD">("ADD");
  const [action, setAction] = useState<Partial<Action>>({});

  const onFormSubmission = () => {
    setDrawerOpen(false);
  };

  const useActionsHook = useActions(programClientId);
  const { actions, loadActions } = useActionsHook;
  const { coachingArticles, isLoading: isLoadingCoachingArticles, loadCoachingArticles } = useCoachingArticles();

  useEffect(() => {
    loadActions();
    loadCoachingArticles();
  }, []);

  return isLoadingCoachingArticles ? (
    <p>Loading...</p>
  ) : (
    <>
      <MainCard title={actions ? "Active Notes" : "Create New Note"} content={false}>
        {actions ? (
          <TableFrame
            TableHeaderCellComponents={
              <>
                <TableCell sx={{ pl: 3 }}>Title</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell align="center">Completed</TableCell>
                <TableCell align="right" sx={{ pr: 3 }}>
                  Actions
                </TableCell>
              </>
            }
            TableBodyComponents={
              <>
                {(actions ?? [])
                  .sort((firstAction, nextAction) => sortActionsPrioritizeDueDate(firstAction, nextAction))
                  .map((row, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => {
                        setDrawerOpen(true);
                        setAction(row);
                        setDrawerShow("MODIFY");
                      }}
                    >
                      <TableCell sx={{ pl: 3 }}>{row.name}</TableCell>
                      <TableCell>{row.dueDate ? moment(row.dueDate).format("MM/DD/YY") : "N/A"}</TableCell>
                      <TableCell align="center">
                        {row.status === StatusOfAction.COMPLETE ? <CheckCircleIcon sx={{ color: "#3f51b5" }} /> : ""}
                      </TableCell>
                      <TableCell align="right" sx={{ pr: 3 }}>
                        {mapActionTypesToDisplayText[row.type]}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            }
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="contained" size="large" onClick={() => {}}>
              Create New Note
            </Button>
          </Box>
        )}
        {actions && (
          <>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setDrawerOpen(true);
                  setDrawerShow("ADD");
                }}
              >
                Add New Note
              </Button>
            </CardActions>
          </>
        )}
      </MainCard>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        {drawerShow === "ADD" ? (
          <Box sx={{ padding: DEFAULT_PADDING }}>
            <CreateClientNote
              coachingArticles={coachingArticles}
              programClientId={programClientId}
              onFormSubmission={onFormSubmission}
              useActionsHook={useActionsHook}
            />
          </Box>
        ) : (
          <Box sx={{ padding: DEFAULT_PADDING }}>
            <CreateClientNote
              coachingArticles={coachingArticles}
              programClientId={programClientId}
              onFormSubmission={onFormSubmission}
              useActionsHook={useActionsHook}
              action={action}
            />
          </Box>
        )}
      </Drawer>
    </>
  );
}

export default ClientNoteTableModule;

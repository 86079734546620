export enum SearchParamKeys {
  REMEDIATION_PAYMENT_PLAN_TAB = "remediation-tab",
  PAYMENT_PLAN_TAB = "payment-plan-tab",
  HARDSHIP_REASON_TAB = "hardship-tab",
  MAINTENANCE_TAB = "maintenance-tab",
  INSPECTIONS_TAB = "inspections-tab",
  PROPERTY_INSPECTIONS_TAB = "property-inspections-tab",
  PROPERTY_TAB = "property-tab",
  CLIENT_PROPERTY_TAB = "client-property-tab",
  APPLICATION_TAB = "application-tab",
}

export enum TypeOfInspection {
  ADU_INSPECTION = "adu_inspection",
  ASBESTOS_INSPECTION = "asbestos_inspection",
  BUY_BEFORE_YOU_SELL_QUALIFICATION_REVIEW = "buy_before_you_sell_qualification_review",
  DENVER_RENTAL_COMPLIANCE = "denver_rental_compliance",
  DRAW_INSPECTION = "draw_inspection",
  ENERGY_AUDIT = "energy_audit",
  FINAL_INSPECTION = "final_inspection",
  FLOOR_PLAN = "floor_plan",
  FOUNDATION_INSPECTION = "foundation_inspection",
  FOUR_POINT_INSPECTION = "four_point_inspection",
  HOA_INSPECTION = "hoa_inspection",
  HOME_INSPECTION = "home_inspection",
  HOME_INSPECTION_PLUS_MEASUREMENTS = "home_inspection_plus_measurements",
  HVAC_INSPECTION = "hvac_inspection",
  HYBRID_APPRAISAL = "hybrid_appraisal",
  INSURANCE_INSPECTION = "insurance_inspection",
  LOCKBOX = "lockbox",
  METH_INSPECTION = "meth_inspection",
  MIDLEASE_WALK = "midlease_walk",
  MAJOR_SYSTEMS_PLUS = "major_systems_plus",
  MOLD_INSPECTION = "mold_inspection",
  MOVE_IN_INSPECTION = "move_in_inspection",
  MOVE_OUT_INSPECTION = "move_out_inspection",
  OIL_TANK_LOCATE = "oil_tank_locate",
  POOL_INSPECTION = "pool_inspection",
  PRE_INSPECTION = "pre_inspection",
  PRE_DRYWALL_INSPECTION = "pre_drywall_inspection",
  RADON_INSPECTION = "radon_inspection",
  RE_INSPECTION = "re_inspection",
  RE_VISIT = "re_visit",
  RENT_READY_INSPECTION = "rent_ready_inspection",
  REPAIR_ESTIMATE = "repair_estimate",
  ROOF_INSPECTION = "roof_inspection",
  SEPTIC_INSPECTION = "septic_inspection",
  SEPTIC_LINE_SCOPE = "septic_line_scope",
  SEWER_INSPECTION = "sewer_inspection",
  SOW_INSPECTION = "sow_inspection",
  SPA_INSPECTION = "spa_inspection",
  TERMITE_INSPECTION = "termite_inspection",
  VIDEO = "video",
  WELL_INSPECTION = "well_inspection",
  WIND_MITIGATION = "wind_mitigation",
  WINTERIZATION = "winterization",
}

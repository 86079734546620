import { TabPanel } from "@mui/lab";
import { Tabs } from "@/components/base";
import { Tab } from "@mui/material";
import { Person } from "@/types";
import CreditDataDashboard from "@/components/view/Client/ClientCreditDashboard/CreditDataDashboard";

interface Props {
  persons: Array<Person>;
}

const CreditDashboardTabs = ({ persons }: Props) => {
  if (!persons.length) {
    return <></>;
  }

  return (
    <div>
      <Tabs
        tabsIds={persons.map((applicant) => applicant.id)}
        tabToStart={persons[0].id}
        orientation="horizontal"
        verticalLeftGridItemSize={4}
        TabListComponent={
          <>
            {persons.map((applicant) => (
              <Tab key={applicant.id} value={applicant.id} label={`${applicant.nameFirst} ${applicant.nameLast}`} sx={{ border: 0 }} />
            ))}
          </>
        }
        TabPanelComponent={
          <>
            {persons.map((applicant) => (
              <TabPanel key={applicant.id} value={applicant.id} sx={{ padding: "0rem 0rem 1rem 0rem" }}>
                <CreditDataDashboard personId={applicant.id} />
              </TabPanel>
            ))}
          </>
        }
      />
    </div>
  );
};

export default CreditDashboardTabs;

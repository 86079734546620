// material-ui
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";

// project imports
import { useMenuItems } from "@/components/layout/navigation";
import NavGroup from "./NavGroup";
import { NavItemType } from "@/types";
import { LAYOUT_CONST } from "@/constant";
import { HORIZONTAL_MAX_ITEM } from "@/config";
import useConfig from "@/hooks/useConfig";
import { useClientMenuItems } from "@/hooks";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  const navigation = useMenuItems();
  const { clientMenu } = useClientMenuItems();

  if (!clientMenu) {
    return <>Loading...</>;
  }

  // last menu-item to show in horizontal menu bar
  const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

  const menuItems = [clientMenu, ...navigation.items];

  let lastItemIndex = menuItems.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  if (lastItem && lastItem < menuItems.length) {
    lastItemId = menuItems[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.slice(lastItem - 1, [clientMenu, ...navigation.items].length).map((item) => ({
      title: item.title,
      elements: item.children,
    }));
  }

  return (
    <>
      {menuItems.slice(0, lastItemIndex + 1).map((item, index) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id || index} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
          default:
            return (
              <Typography key={item.id || index} variant="h6" color="error" align="center">
                Menu Items Error
              </Typography>
            );
        }
      })}
      <div style={{ minHeight: "10rem" }} />
    </>
  );
};

export default MenuList;

/*
  Modify this file as you see fit.
  It's up to the developer to configure the env.
*/

import { EnvType } from "@/types";

const localEnv: EnvType = {
  GOOGLE_MAPS_API_KEY: "AIzaSyDAHOoRcSS10m4oTHNkyfeT8g2XKMxp0cU",
  BRIDGE_V2_API_BASE_URL: "https://dev-bridgev2-api.landis.network",
  // BRIDGE_V2_API_BASE_URL: "http://localhost:8000",
  BRIDGE_V1_BASE_URL: "https://dev-bridge.landis.com",
  ENV: "prd",
  DEBUG_MODE: true,
  FF_ENABLED: true,
};

export default localEnv;

import { PropertyBasicHomeStats } from "@/components/partial";
import { UsePropertyHook } from "@/hooks";

interface Props {
  usePropertyInstance: UsePropertyHook;
}

function ClientPropertyOverview({ usePropertyInstance }: Props) {
  return <PropertyBasicHomeStats isReadOnly usePropertyInstance={usePropertyInstance} />;
}

export default ClientPropertyOverview;

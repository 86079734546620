import { EnvType } from "@/types";

const devEnv: EnvType = {
  GOOGLE_MAPS_API_KEY: "AIzaSyBZL12mwhjV04xPUAHIqlKEOcXiVNXX9CY",
  BRIDGE_V2_API_BASE_URL: "https://dev-bridgev2-api.landis.network",
  BRIDGE_V1_BASE_URL: "https://dev-bridge.landis.com",
  ENV: "dev",
  DEBUG_MODE: true,
  FF_ENABLED: true,
};

export default devEnv;

import { ReactNode } from "react";
import { OktaAuth, TokenParams } from "@okta/okta-auth-js";
import { useLocation } from "react-router-dom";

import { OKTA_AUTH_REDIRECT } from "@/constant";
import { OKTA_AUTH_CLIENT_ID, OKTA_AUTH_CALLBACK, OKTA_AUTH_ISSUER } from "@/config";
import { useAuth } from "@/hooks";
import { FullScreenLoading } from "@/components/base";

interface Props {
  children: ReactNode;
  pathsToAllow?: string[];
}

function OktaAuthCheck({ children, pathsToAllow }: Props) {
  const { pathname } = useLocation();
  const { oktaAuthToken } = useAuth();

  //allow the login paths to load without authentication
  if (pathname === "/login" || pathname === "/login/callback" || (pathsToAllow ?? []).includes(pathname)) {
    return <>{children}</>;
  }

  //if auth doesn't pass sent session to okta for validation
  if (!oktaAuthToken) {
    const authClient: OktaAuth = new OktaAuth({
      clientId: OKTA_AUTH_CLIENT_ID,
      issuer: OKTA_AUTH_ISSUER,
      redirectUri: `${window.location?.origin}${OKTA_AUTH_CALLBACK}`,
      pkce: false,
    });

    //this allows for the user to return to the same request after login complete
    localStorage.setItem(OKTA_AUTH_REDIRECT, window.location.href);
    const tokenParams: TokenParams = {
      scopes: ["openid", "email", "profile"],
    };
    authClient.token.getWithRedirect(tokenParams);

    return <FullScreenLoading />;
  }

  //if auth passed return page
  return <>{children}</>;
}

export default OktaAuthCheck;

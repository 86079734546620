import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientRequirement from "./ClientRequirement";
import { ClientJourneyDtoModel, JourneyMilestoneDtoModel } from "@/types";
import { useMilestone, useRequirements } from "@/hooks/data-hooks/milestones";
import { useEffect, useState } from "react";
import RequirementChip from "./Chip";
import MilestoneCaption from "./MilestoneCaption";
import AddRequirementAction from "./AddRequirementAction";
import { Box } from "@mui/material";
import AddAdverseAction from "../AdverseActions/AddAdverseAction";
import { MilestoneDefinitionKey } from "@l4s/milestone-models";
import ApplicationAdverseActionList from "../AdverseActions/ApplicationAdverseActionList";
import { useApplicationAdverseActions } from "@/hooks/data-hooks/adverse-actions/useApplicationAdverseActions";
import { isLastOpenRequirementOnLevel } from "./utils";

interface Props {
  milestone: JourneyMilestoneDtoModel;
  clientJourney: ClientJourneyDtoModel;
  milestoneCompleted: boolean;
  onMilestoneUpdate: () => Promise<void>;
}

const ClientMilestone = ({ milestone, clientJourney, milestoneCompleted, onMilestoneUpdate }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (milestone.focus) {
      setExpanded(true);
    }
  }, [milestone]);

  const { requirements, updateRequirement, refetchRequirements } = useRequirements(milestone.id);
  const { getMilestone } = useMilestone(milestone.id);

  const { applicationAdverseActions, getApplicationAdverseActions } = useApplicationAdverseActions(clientJourney.applicationId);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const checkIfMilestoneWasUpdated = async (fetchedMilestone: JourneyMilestoneDtoModel) => {
    if (fetchedMilestone.closedReason !== milestone.closedReason) {
      await onMilestoneUpdate();
    }
  };

  const onRequirementUpdate = async () => {
    await refetchRequirements();
    const fetchedMilestone = await getMilestone();
    await checkIfMilestoneWasUpdated(fetchedMilestone);
  };

  if (!requirements.length) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <Box display="flex" flex="row nowrap" justifyContent="flex-start">
          <Box>
            <Typography sx={{ flexShrink: 0 }}>{milestone.definition.managerName}</Typography>
            <MilestoneCaption input={milestone} />
          </Box>
          {!milestone.closedAt ? (
            <Box>
              <AddRequirementAction openRequirements={requirements} milestone={milestone} onAdd={refetchRequirements} />
            </Box>
          ) : null}
          {[MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
            <ApplicationAdverseActionList applicationAdverseActions={applicationAdverseActions} />
          ) : null}
        </Box>
        <div style={{ justifySelf: "flex-end", marginRight: `${milestoneCompleted ? "2.5rem" : "1.5rem"}` }}>
          <RequirementChip input={milestone} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange} disableGutters={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingRight: milestoneCompleted ? "0rem" : "1rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box display="flex" flex="row nowrap" justifyContent="flex-start">
              <Box>
                <Typography sx={{ flexShrink: 0 }}>{milestone.definition.managerName}</Typography>
                <MilestoneCaption input={milestone} />
              </Box>
              {!milestone.closedAt ? (
                [MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
                  <Box>
                    <AddAdverseAction clientJourney={clientJourney} milestone={milestone} onAdd={() => getApplicationAdverseActions()} />
                  </Box>
                ) : (
                  <Box>
                    <AddRequirementAction openRequirements={requirements} milestone={milestone} onAdd={refetchRequirements} />
                  </Box>
                )
              ) : null}
            </Box>
            <div style={{ justifySelf: "flex-end", marginRight: "2rem" }}>
              <RequirementChip input={milestone} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingRight: "0" }}>
          {[MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
            <ApplicationAdverseActionList applicationAdverseActions={applicationAdverseActions} />
          ) : null}
          {requirements?.map((r) => {
            return (
              <ClientRequirement
                requirement={r}
                key={r.id}
                updateRequirement={updateRequirement}
                onRequirementUpdate={onRequirementUpdate}
                milestoneCompleted={milestoneCompleted}
                isLastOpenRequirement={isLastOpenRequirementOnLevel(r, requirements)}
                clientJourney={clientJourney}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ClientMilestone;

import { CurrencyCentsTextField, Dropdown, LandisButton, SearchableDropdown } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseHoas, UseHoasPaymentDetails, UsePropertyManagementCompanyInstance, useToast } from "@/hooks";
import { HoasPaymentDetails } from "@/types";
import { DateFormat, FormHelpers } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Field } from "./useHoaPaymentDetailsForm";
import { DatePicker } from "@mui/x-date-pickers";
import { ResponsiblePartyOptions, PaymentMethodOptions, PaymentFrequencyOptions } from "../../useDetailForms";
import * as Yup from "yup";
import { FrequencyOfPayments, MethodOfPayment, TypeOfParty } from "@l4s/client-property-models";

interface Props {
  close: () => void;
  hoaPaymentDetail?: HoasPaymentDetails;
  useHoasPaymentDetailsInstance: UseHoasPaymentDetails;
  useHoasInstance: UseHoas;
  usePropertyManagementCompanyInstance: UsePropertyManagementCompanyInstance;
  propertyId: string;
}

function HoaPaymentDetailsForm({
  hoaPaymentDetail,
  useHoasInstance,
  useHoasPaymentDetailsInstance,
  usePropertyManagementCompanyInstance,
  propertyId,
  close,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createHoaPaymentDetail, updateHoaPaymentDetail } = useHoasPaymentDetailsInstance;
  const { propertyHoas } = useHoasInstance;
  const { propertyManagementCompanies } = usePropertyManagementCompanyInstance;
  const formTitle = () => (hoaPaymentDetail?.id ? "Update HOA payment detail" : "Add HOA payment detail");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    hoaId: Yup.string().required("Related HOA is a required field").typeError("Related HOA is a required field"),
    externalAccountId: Yup.string(),
    responsibleParty: Yup.string().required("Responsible party is a required field").typeError("You are required to select a party"),
    paymentMethod: Yup.string().required("Payment method is a required field").typeError("You are required to select a payment method"),
    paymentFrequency: Yup.string().required("Payment frequency is a required field").typeError("Payment frequency is a required field"),
    initialPaymentDate: Yup.date()
      .required("Initial payment date is a required field")
      .typeError("Initial payment date must be a valid date (mm/dd/yyyy)"),
    startDate: Yup.date().required("Start date is a required field").typeError("Start date must be a valid date (mm/dd/yyyy)"),
    endDate: Yup.date().nullable(),
    duesCents: Yup.number().required().typeError("Dues is a required field"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      hoaId: hoaPaymentDetail?.hoaId ?? null,
      externalAccountId: hoaPaymentDetail?.externalAccountId ?? "",
      responsibleParty: hoaPaymentDetail?.responsibleParty ?? ("" as TypeOfParty),
      paymentMethod: hoaPaymentDetail?.paymentMethod ?? ("" as MethodOfPayment),
      paymentFrequency: hoaPaymentDetail?.paymentFrequency ?? ("" as FrequencyOfPayments),
      initialPaymentDate: hoaPaymentDetail?.initialPaymentDate ?? null,
      startDate: hoaPaymentDetail?.startDate ?? null,
      endDate: hoaPaymentDetail?.endDate ?? null,
      duesCents: hoaPaymentDetail?.duesCents ?? null,
      propertyManagementCompanyId: hoaPaymentDetail?.propertyManagementCompanyId ?? null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (hoaPaymentDetail) {
          await updateHoaPaymentDetail({
            ...values,
            id: hoaPaymentDetail.id,
            propertyId,
          });
        } else {
          await createHoaPaymentDetail({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
      close();
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item xs={12}>
            <SearchableDropdown
              label="HOA"
              id="add-hoa-id-to-payment-detail"
              items={propertyHoas.map((hoa) => ({
                label: hoa.name,
                value: hoa.id,
              }))}
              handleChange={(newValue) => {
                formik.setFieldValue(Field.HOA_ID, newValue);
              }}
              value={formik.values[Field.HOA_ID]}
              error={!!formik.errors.hoaId}
              helperText={formik.errors.hoaId}
            />
          </Grid>
          <Grid item>
            <TextField
              id="accountId"
              label="Account ID"
              value={formik.values[Field.ACCOUNT_ID]}
              onChange={(e) => formik.setFieldValue(Field.ACCOUNT_ID, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.externalAccountId}
              helperText={formik.errors.externalAccountId}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={ResponsiblePartyOptions}
              label="Responsible Party"
              id="responsibleParty"
              fullWidth
              value={formik.values[Field.RESPONSIBLE_PARTY]}
              handleChange={(val) => formik.setFieldValue(Field.RESPONSIBLE_PARTY, val)}
              error={!!formik.errors.responsibleParty}
              helperText={formik.errors.responsibleParty}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={PaymentMethodOptions}
              label="Payment Method"
              id="paymentMethod"
              fullWidth
              value={formik.values[Field.PAYMENT_METHOD]}
              handleChange={(val) => formik.setFieldValue(Field.PAYMENT_METHOD, val)}
              error={!!formik.errors.paymentMethod}
              helperText={formik.errors.paymentMethod}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={PaymentFrequencyOptions}
              label="Payment Frequency"
              id="paymentFrequency"
              fullWidth
              value={formik.values[Field.PAYMENT_FREQUENCY]}
              handleChange={(val) => formik.setFieldValue(Field.PAYMENT_FREQUENCY, val)}
              error={!!formik.errors.paymentFrequency}
              helperText={formik.errors.paymentFrequency}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Initial Payment Date"
              value={formik.values[Field.INITIAL_PAYMENT_DATE]}
              onChange={(val) => formik.setFieldValue(Field.INITIAL_PAYMENT_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id={Field.INITIAL_PAYMENT_DATE}
                  fullWidth
                  {...params}
                  name={Field.INITIAL_PAYMENT_DATE}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.INITIAL_PAYMENT_DATE)}
                  error={FormHelpers.formikCheckError(formik, Field.INITIAL_PAYMENT_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Start Date"
              value={formik.values[Field.START_DATE]}
              onChange={(val) => formik.setFieldValue(Field.START_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id={Field.START_DATE}
                  fullWidth
                  {...params}
                  name={Field.START_DATE}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.START_DATE)}
                  error={FormHelpers.formikCheckError(formik, Field.START_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="End Date"
              value={formik.values[Field.END_DATE]}
              onChange={(val) => formik.setFieldValue(Field.END_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id={Field.END_DATE}
                  fullWidth
                  {...params}
                  name={Field.END_DATE}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.END_DATE)}
                  error={FormHelpers.formikCheckError(formik, Field.END_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Dues"
              value={formik.values[Field.DUES_CENTS]}
              handleChange={(val) => formik.setFieldValue(Field.DUES_CENTS, val)}
              error={!!formik.errors.duesCents}
              helperText={formik.errors.duesCents}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableDropdown
              label="Property Management Company"
              id="add-property-management-company-to-payment-detail"
              items={propertyManagementCompanies.map((company) => ({
                label: company.name,
                value: company.id,
              }))}
              handleChange={(newValue) => {
                formik.setFieldValue(Field.PROPERTY_MANAGEMENT_COMPANY_ID, newValue);
              }}
              value={formik.values[Field.PROPERTY_MANAGEMENT_COMPANY_ID]}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default HoaPaymentDetailsForm;

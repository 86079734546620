import { HousingQuestionnaireDesire } from "@/types";

export function displayYesOrNo(value: boolean | null) {
  if (value == null) {
    return "-";
  }
  return value ? "Yes" : "No";
}

export function titleCase(s: string | null): string {
  if (s == null) {
    return "-";
  }
  return s
    .toLowerCase()
    .split("_")
    .map((st) => st.slice(0, 1).toUpperCase() + st.slice(1))
    .join(" ");
}

export function displayDesire(desire: HousingQuestionnaireDesire | null) {
  if (desire == null) {
    return "-";
  }
  return desire === HousingQuestionnaireDesire.MUST ? "Must have" : "Like to have";
}

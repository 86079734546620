import { useApplicationsProperty, useApplicationsPropertyFinance, useProperty, useSearchParamsState } from "@/hooks";
import { Person, ProgramClient, SearchParamKeys } from "@/types";
import { TabContext, TabPanel } from "@mui/lab";
import { Grid } from "@mui/material";
import { ClientPropertyHeader } from "./ClientPropertyHeader";
import { useParams } from "react-router-dom";
import { ManualBreadcrumbs } from "@/components/base";

import ClientPropertyOverview from "./ClientPropertyOverview";
import { ClientPropertyFinance } from "./ClientPropertyFinance";
import { ClientPropertyTab } from "./useClientPropertyDetailTabs";
import { ClientMaintenanceTabs, PropertyInspectionsManage } from "@/components/partial";
import UnderConstructionCard from "./UnderConstructionCard";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

function ClientPropertyDetail({ person, programClient }: Props) {
  const { applicationsPropertyId } = useParams();
  const [clientPropertyTabValue] = useSearchParamsState<ClientPropertyTab>(SearchParamKeys.CLIENT_PROPERTY_TAB, ClientPropertyTab.PROPERTY);

  const useApplicationsPropertyInstance = useApplicationsProperty(applicationsPropertyId);
  const { applicationsProperty } = useApplicationsPropertyInstance;
  const usePropertyInstance = useProperty(applicationsProperty?.propertyId);
  const { property } = usePropertyInstance;

  const useApplicationsPropertyFinanceInstance = useApplicationsPropertyFinance(applicationsProperty);

  if (!clientPropertyTabValue) return null;

  return (
    <TabContext value={clientPropertyTabValue}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ManualBreadcrumbs
            title={property?.addressStreet || ""}
            links={[
              {
                title: `${person.nameFirst}'s Properties`,
                url: `/client/${person.id}/properties`,
              },
              {
                title: property?.addressStreet,
                url: `/client/${person.id}/properties/${applicationsPropertyId}`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ClientPropertyHeader
            useApplicationsPropertyInstance={useApplicationsPropertyInstance}
            usePropertyInstance={usePropertyInstance}
          />
        </Grid>
        {applicationsProperty && (
          <Grid item xs={12}>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.PROPERTY}>
              <ClientPropertyOverview usePropertyInstance={usePropertyInstance} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.INSPECTIONS}>
              {property && applicationsProperty && (
                <PropertyInspectionsManage property={property} applicationsProperty={applicationsProperty} />
              )}
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.OFFERS}>
              <UnderConstructionCard bridgeUrl={`/clients/${applicationsProperty?.applicationId}/close`} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.CLOSING_REQUIREMENTS}>
              <UnderConstructionCard bridgeUrl={`/properties/${applicationsPropertyId}/dates`} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.FINANCES}>
              <ClientPropertyFinance useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.MAINTENANCE}>
              <ClientMaintenanceTabs programClientId={programClient.id} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.DOCUMENTS}>
              <UnderConstructionCard bridgeUrl={`/properties/${applicationsPropertyId}/docs`} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={ClientPropertyTab.TOUCHPOINTS}>
              <UnderConstructionCard bridgeUrl={`/properties/${applicationsPropertyId}`} />
            </TabPanel>
          </Grid>
        )}
      </Grid>
    </TabContext>
  );
}

export default ClientPropertyDetail;

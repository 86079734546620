import { DocusignIcon, Dropdown } from "@/components/base";
import { UseTemplateFillFieldValuesHook } from "@/hooks";
import {
  TemplateFieldAudience,
  TemplateFieldAudienceDisplayMap,
  TemplateFieldToFill,
  TemplateFieldType,
  isDocusignTemplateField,
} from "@/types";
import { DocumentsAutofillResponsePayload } from "@l4s/documents-autofill-models";
import { CheckCircle } from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { renderAutofillIndicatorMessage } from "./renderAutofillIndicatorMessage";

interface Props {
  templateFieldToFill: TemplateFieldToFill;
  templateFillFieldValuesInstance: UseTemplateFillFieldValuesHook;
  templateFillId: string;
  autofillValue: DocumentsAutofillResponsePayload | undefined;
}

const FilledByDocusignDisplay = ({ audience }: { audience: TemplateFieldAudience }) => {
  return (
    <Grid sx={{ paddingY: 1, paddingLeft: 1 }} item container flexWrap="nowrap" gap={0.5}>
      <DocusignIcon style={{ width: "1rem" }} />
      <Typography variant="h4">Completed in Docusign by {TemplateFieldAudienceDisplayMap[audience]}.</Typography>
    </Grid>
  );
};

const formStyle: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
};

const TemplateFieldValueForm = ({ templateFieldToFill, templateFillFieldValuesInstance, templateFillId, autofillValue }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>("");

  useEffect(() => {
    if (templateFieldToFill) {
      setValue(templateFieldToFill.value || "");
    }
  }, [templateFieldToFill]);

  useEffect(() => {
    if (!templateFieldToFill.value && autofillValue && autofillValue[templateFieldToFill.autofillEntityField]) {
      setValue(autofillValue[templateFieldToFill.autofillEntityField]);
    }
  }, [autofillValue, templateFieldToFill]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!templateFieldToFill.templateFillFieldValueId) {
        await templateFillFieldValuesInstance.createTemplateFillFieldValue({
          templateFieldId: templateFieldToFill.id,
          value,
          templateFillId,
        });
      } else {
        if (!value) {
          await templateFillFieldValuesInstance.deleteTemplateFillFieldValue(templateFieldToFill.templateFillFieldValueId);
          return;
        }
        await templateFillFieldValuesInstance.updateTemplateFillFieldValue(templateFieldToFill.templateFillFieldValueId, value);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isDocusignTemplateField(templateFieldToFill.type)) return <FilledByDocusignDisplay audience={templateFieldToFill.audience} />;

  if (templateFieldToFill.type === TemplateFieldType.CHECKBOX) {
    return (
      <form style={formStyle} onSubmit={handleSubmit}>
        <Grid sx={{ paddingY: 1, paddingLeft: 1 }} item container flexWrap="nowrap" spacing={0.5} alignItems="center">
          <Grid item flex={1}>
            <Dropdown
              size="small"
              id="templateFieldToFillCheckboxDropdown"
              label="Checked?"
              value={value}
              handleChange={(nextValue) => setValue(nextValue)}
              items={[
                { label: "", value: "" },
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
            />
          </Grid>
          <Grid item>
            <IconButton type="submit" sx={{ padding: 0 }}>
              {!loading && <CheckCircle fontSize="large" />}
              {loading && <CircularProgress size={24} />}
            </IconButton>
          </Grid>
        </Grid>
        {renderAutofillIndicatorMessage(autofillValue, templateFieldToFill)}
      </form>
    );
  }

  return (
    <form style={formStyle} onSubmit={handleSubmit}>
      <Grid sx={{ paddingY: 1, paddingLeft: 1 }} item container flexWrap="nowrap" spacing={0.5} alignItems="center">
        <Grid item flex={1}>
          <TextField value={value} onChange={(e) => setValue(e.target.value)} fullWidth size="small" />
        </Grid>
        <Grid item>
          <IconButton type="submit" sx={{ padding: 0 }}>
            {!loading && <CheckCircle color="primary" fontSize="large" />}
            {loading && <CircularProgress size={24} />}
          </IconButton>
        </Grid>
      </Grid>
      {renderAutofillIndicatorMessage(autofillValue, templateFieldToFill)}
    </form>
  );
};

export default TemplateFieldValueForm;

import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { GRID_SPACING } from "@/config";
import { ExpectedPayment } from "@/types";
import { CurrencyCentsTextField, LandisButton } from "@/components/base";
import { Box } from "@mui/system";
import { Check, Close, DeleteOutline } from "@mui/icons-material";
import { UsePaymentPlans, useToast } from "@/hooks";
import { mapUnknownToError } from "@/utils";

interface Props {
  planId: string;
  expectedPayment?: ExpectedPayment;
  onCancel?: () => void;
  onSave?: () => void;
  createExpectedPayment?: UsePaymentPlans["createExpectedPayment"];
  deleteExpectedPaymentById?: UsePaymentPlans["deleteExpectedPaymentById"];
  updateExpectedPayment?: UsePaymentPlans["updateExpectedPayment"];
}

const initialFormData = {
  paymentDate: null,
  paymentAmountCents: 0,
};

const ExpectedPaymentForm = ({
  expectedPayment,
  planId,
  onCancel,
  createExpectedPayment,
  deleteExpectedPaymentById,
  updateExpectedPayment,
}: Props) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [expectedPaymentFormData, setExpectedPaymentFormData] = useState<Omit<ExpectedPayment, "isPaid">>(initialFormData);

  const { addToast } = useToast();

  useEffect(() => {
    setExpectedPaymentFormData(expectedPayment ? { ...expectedPaymentFormData, ...expectedPayment } : initialFormData);
  }, [expectedPayment]);

  const handleCancel = () => {
    setExpectedPaymentFormData(initialFormData);
    onCancel();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    try {
      if (expectedPayment?.id) {
        await updateExpectedPayment({ ...expectedPayment, ...expectedPaymentFormData });
      } else {
        await createExpectedPayment({ planId, ...expectedPaymentFormData });
      }
      handleCancel();
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
    setSaveLoading(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await deleteExpectedPaymentById(expectedPayment);
      handleCancel();
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
    setDeleteLoading(false);
  };

  return (
    <form onSubmit={handleSave} style={{ width: "100%" }}>
      <Grid item xs={12} container flexDirection="column">
        <Grid item xs={12} container spacing={GRID_SPACING}>
          <Grid item xs={6}>
            <DatePicker
              label="Payment Date"
              value={expectedPaymentFormData?.paymentDate}
              onChange={(val) => setExpectedPaymentFormData({ ...expectedPaymentFormData, paymentDate: val })}
              renderInput={(params) => <TextField required fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyCentsTextField
              required
              fullWidth
              label="Expected Payment Value"
              value={expectedPaymentFormData?.paymentAmountCents}
              handleChange={(centsValue) => setExpectedPaymentFormData({ ...expectedPaymentFormData, paymentAmountCents: centsValue })}
            />
          </Grid>
        </Grid>
        <Box style={{ marginTop: "12px" }} />
        <Grid item xs={12} justifyContent="flex-end" container gap="10px">
          <Grid item>
            <LandisButton disableElevation onClick={handleCancel} startIcon={<Close />} color="inherit" variant="outlined">
              Cancel
            </LandisButton>
          </Grid>
          {expectedPayment?.id && (
            <Grid item>
              <LandisButton
                disableElevation
                onClick={handleDelete}
                loading={deleteLoading}
                startIcon={<DeleteOutline />}
                color="error"
                variant="contained"
              >
                Delete
              </LandisButton>
            </Grid>
          )}
          <Grid item>
            <LandisButton disableElevation type="submit" loading={saveLoading} startIcon={<Check />} variant="contained">
              Save
            </LandisButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ExpectedPaymentForm;

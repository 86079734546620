import { useState } from "react";
//TODO: use yup for schema validation on inputs
//import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { Dropdown, LandisButton } from "@/components/base";
import { GRID_SPACING } from "@/config";
import { CreateHardshipInput, HardshipReason, Hardship } from "@/hooks";
import { ConfirmOption, CONFIRM_DROPDOWN_ITEMS } from "@/utils";
import { mapHardshipPrimaryReason } from "./mapHardship";

interface Props {
  save: (input: CreateHardshipInput, hardship: Hardship, onSuccessOrFailure: () => void) => void;
  hardshipToEdit?: Hardship;
}

export const CoachHardshipForm = ({ hardshipToEdit, save }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //first set of condiations checks if a hardship object is passed for edit (this object can be undefined)
  //if not hardship, then the second set of conditions sets defaults for the form
  const formik = useFormik({
    initialValues: {
      hardshipDate: hardshipToEdit?.hardshipDate ? new Date(hardshipToEdit.hardshipDate) : new Date(),
      hardshipEndDate: hardshipToEdit?.hardshipEndDate ? new Date(hardshipToEdit.hardshipEndDate) : null,
      primaryReason: hardshipToEdit?.primaryReason ?? HardshipReason.ACT_OF_GOD,
      dueToIncomeReduction: (hardshipToEdit?.dueToIncomeReduction ? ConfirmOption.YES : ConfirmOption.NO) ?? ConfirmOption.NO,
      dueToIncreasedCosts: (hardshipToEdit?.dueToIncreasedCosts ? ConfirmOption.YES : ConfirmOption.NO) ?? ConfirmOption.NO,
      isTemporary: (hardshipToEdit?.isTemporary ? ConfirmOption.YES : ConfirmOption.NO) ?? ConfirmOption.NO,
      description: hardshipToEdit?.description ?? "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      save(
        {
          hardshipDate: values.hardshipDate.toISOString(),
          hardshipEndDate: values?.hardshipEndDate ? values.hardshipEndDate.toISOString() : null,
          primaryReason: values.primaryReason,
          dueToIncomeReduction: ConfirmOption.YES === values.dueToIncomeReduction,
          dueToIncreasedCosts: ConfirmOption.YES === values.dueToIncreasedCosts,
          isTemporary: ConfirmOption.YES === values.isTemporary,
          description: values.description,
        },
        hardshipToEdit,
        () => {
          setIsLoading(false);
        }
      );
    },
  });

  //validate whether the end date > start date.
  //function user on both start / end data inputs
  function validateDate(start: Date, end: Date): boolean {
    if (start && end) {
      if (end.getTime() < start.getTime()) {
        formik.setFieldError("hardshipEndDate", "End date must be greater than start data");
        return false;
      }
    }
    formik.setFieldError("hardshipEndDate", null);
    return true;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <DatePicker
            label="Hardship Start Date"
            value={formik.values.hardshipDate}
            onChange={(val) => {
              formik.setFieldValue("hardshipDate", val, false);
              validateDate(val, formik.values.hardshipEndDate);
            }}
            renderInput={(params) => <TextField fullWidth {...params} name="hardshipDate" />}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label="Hardship End Date"
            value={formik.values.hardshipEndDate}
            onChange={(val) => {
              formik.setFieldValue("hardshipEndDate", val, false);
              validateDate(formik.values.hardshipDate, val);
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                name="hardshipEndDate"
                //TODO: look into what is causing the type issue, NB but annoying
                //this returns a string but typescript validation is saying Date
                helperText={typeof formik.errors.hardshipEndDate === "string" ? formik.errors.hardshipEndDate : null}
                error={Boolean(formik.values.hardshipEndDate) && Boolean(formik.errors.hardshipEndDate)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            items={Object.keys(HardshipReason).map((opt: HardshipReason) => ({ value: opt, label: mapHardshipPrimaryReason(opt) }))}
            value={formik.values.primaryReason}
            handleChange={(value) => {
              formik.setFieldValue("primaryReason", value ?? HardshipReason.ACT_OF_GOD);
            }}
            label="Primary Reason"
            id="primaryReason"
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            items={CONFIRM_DROPDOWN_ITEMS}
            value={formik.values.dueToIncomeReduction}
            handleChange={(value) => {
              formik.setFieldValue("dueToIncomeReduction", value ?? ConfirmOption.NO);
            }}
            label="Due To Reduction In Income"
            id="dueToIncomeReduction"
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            items={CONFIRM_DROPDOWN_ITEMS}
            value={formik.values.dueToIncreasedCosts}
            handleChange={(value) => {
              formik.setFieldValue("dueToIncreasedCosts", value ?? ConfirmOption.NO);
            }}
            label="Due To Increase Costs"
            id="dueToIncreasedCosts"
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            items={CONFIRM_DROPDOWN_ITEMS}
            value={formik.values.isTemporary}
            handleChange={(value) => {
              formik.setFieldValue("isTemporary", value ?? ConfirmOption.NO);
            }}
            label="Is Temporary"
            id="isTemporary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            label="Description"
            multiline
            fullWidth
            rows={4}
            spellCheck={false}
            onChange={(e) => {
              formik.setFieldValue("description", e?.target?.value ?? "");
            }}
            value={formik.values.description}
          />
        </Grid>
        <Grid item xs={12}>
          <LandisButton
            loading={isLoading}
            type="submit"
            variant="contained"
            size="large"
            sx={{ width: "100%" }}
            //disable button on errors
            //make sure to check value + error
            disabled={Boolean(formik.values.hardshipEndDate) && Boolean(formik.errors.hardshipEndDate)}
          >
            Save Hardship
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
};

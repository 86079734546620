import { Route, Routes } from "react-router-dom";

import { NotFoundPage } from "@/components/view";
import DocumentsRouter from "./DocumentsRouter";
import AdminPropertiesRouter from "./PropertiesRouter";

const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/documents/*" element={<DocumentsRouter />} />
      <Route path="/properties/*" element={<AdminPropertiesRouter />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AdminRouter;

import { StateCode } from "@/types";

export function formatAddress(
  street: string | undefined,
  unit: string | undefined,
  city: string | undefined,
  zip: string | undefined,
  state: StateCode | undefined
): string | null {
  if (street && city && zip && state) {
    if (unit) {
      return `${street}, ${unit}, ${city}, ${state} ${zip}`;
    }

    return `${street}, ${city}, ${state} ${zip}`;
  }

  return "";
}

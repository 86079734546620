import BarChart from "./BarChart";
import { MainCard } from "@/components/berry";

interface Props {
  series: { name: string; data: number[] }[];
  categories: string[] | number[];
  dataPointName: string;
  yMin: number | null;
  yMax: number | null;
  chartTitle: string;
  cardTitle: string;
  stacked?: boolean;
  formatter?: (value: number) => string;
}

function BarChartDataCard({ categories, series, dataPointName, yMin, yMax, chartTitle, cardTitle, stacked, formatter }: Props) {
  return (
    <>
      <MainCard title={chartTitle}>
        <BarChart
          categories={categories}
          series={series}
          name={dataPointName}
          yMin={yMin}
          yMax={yMax}
          stacked={stacked}
          formatter={formatter}
        />
      </MainCard>
    </>
  );
}

export default BarChartDataCard;

import { Box, Button, TextField } from "@mui/material";
import { MainCard } from "@/components/berry";

import { DEFAULT_BRIDGE_V2_ACCOUNT_ID, DEFAULT_PADDING } from "@/constant";
import { UseGoals } from "@/hooks/data-hooks";
import { AuthenticatedAccount, Goal, GoalStatus, goalDropdownTypes, GoalTypeEnum } from "@/types";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { mapUnknownToError } from "@/utils";
import { Dropdown, LandisButton } from "@/components/base";
import { useToast } from "@/hooks";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import ClientGraduationGoalHistory from "./ClientGraduationGoalHistory";

interface Props {
  close: () => void;
  type: string;
  goal?: Goal;
  useGoals: UseGoals;
}

function ClientGraduationAddUpdateGoal({ useGoals, close, type, goal }: Props) {
  const { createGoal, updateGoal, loadTypesOfGoals, typesOfGoals } = useGoals;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTypesOfGoals();
  }, []);

  const authenticatedAccountId =
    useSelector<RootState, AuthenticatedAccount | null>((state) => state.authenticatedAccount)?.accountId ?? DEFAULT_BRIDGE_V2_ACCOUNT_ID;

  const { addToast } = useToast();
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues:
      type === "Add"
        ? {
            typeName: "",
            goalTitle: "",
            goalDescription: "",
            currentValue: "",
            creator: authenticatedAccountId,
            targetValue: "",
            showToClient: "",
            status: "",
          }
        : {
            typeName: goal.type.name,
            goalTitle: goal.goalTitle,
            goalDescription: goal.goalDescription,
            currentValue: goal.currentValue,
            updater: authenticatedAccountId,
            targetValue: goal.targetValue,
            showToClient: goal.showToClient,
            status: goal.status,
          },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (type === "Add") {
          await createGoal(values);
          addToast({ message: "Successfully created goal!" });
        } else if (type === "Update") {
          await updateGoal({ ...values, id: goal.id });
          addToast({ message: "Successfully modified goal!" });
        }
      } catch (err) {
        const error = mapUnknownToError(err);
        addToast({ message: error.message, type: "error" });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.typeName) {
        errors.typeName = "Goal Type is a required field";
      }
      if (!values.goalDescription) {
        errors.goalDescription = "Description is a required field";
      }
      if (!values.currentValue) {
        errors.currentValue = "Current value is a required field";
      }
      if (!values.targetValue) {
        errors.targetValue = "Target value is a required field";
      }
      if (Boolean(values.showToClient) !== true && Boolean(values.showToClient) !== false) {
        errors.showToClient = "Show to client is a required field";
      }
      if (!values.status) {
        errors.targetValue = "Status is a required field";
      }
      // TODO maybe add checks like these?
      // if (!values.goalDescription) {
      //   values.goalDescription = null;
      // }
      return errors;
    },
  });

  function handleGoalTypeSelect(val: string) {
    formik.setFieldValue("typeName", val);
    let goalTitle = "";
    for (let typeOfGoal of typesOfGoals) {
      if (typeOfGoal.name === GoalTypeEnum[val]) {
        goalTitle = typeOfGoal.defaultGoalTitle || "";
      }
    }
    formik.setFieldValue("goalTitle", goalTitle);
  }

  useEffect(() => {
    for (let key in goal) {
      const keyString = key.toString();
      formik.setFieldValue(keyString, goal[key]);
    }
  }, [goal]);
  return (
    <MainCard title={type === "Add" ? "Add Goal" : "Modify Goal"} content={false}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <Dropdown
            items={goalDropdownTypes}
            label="Goal Type"
            id="typeName"
            required
            value={formik.values.typeName}
            handleChange={(val) => handleGoalTypeSelect(val)}
            error={Boolean(formik.errors.typeName)}
            helperText={formik.errors?.typeName?.toString() ?? ""}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <TextField
            required
            value={formik.values.goalTitle}
            onChange={(e) => formik.setFieldValue("goalTitle", e?.target?.value)}
            size="medium"
            fullWidth
            id="goalTitle"
            label="Title"
            helperText={formik.errors?.goalTitle?.toString() ?? ""}
            error={Boolean(formik.errors.goalTitle)}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <TextField
            required
            value={formik.values.goalDescription}
            onChange={(e) => formik.setFieldValue("goalDescription", e?.target?.value)}
            size="medium"
            fullWidth
            id="goalDescription"
            label="Description"
            helperText={formik.errors?.goalDescription?.toString() ?? ""}
            error={Boolean(formik.errors.goalDescription)}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <TextField
            required
            value={formik.values.currentValue}
            onChange={(e) => formik.setFieldValue("currentValue", e?.target?.value)}
            size="medium"
            fullWidth
            id="currentValue"
            label="Current Value"
            helperText={formik.errors?.currentValue?.toString() ?? ""}
            error={Boolean(formik.errors.currentValue)}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <TextField
            required
            value={formik.values.targetValue}
            onChange={(e) => formik.setFieldValue("targetValue", e?.target?.value)}
            size="medium"
            fullWidth
            id="targetValue"
            label="Target Value"
            helperText={formik.errors?.targetValue?.toString() ?? ""}
            error={Boolean(formik.errors.targetValue)}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <Dropdown
            items={[
              { label: "True", value: "true" },
              { label: "False", value: "false" },
            ]}
            label="Show To Client"
            id="showToClient"
            required
            value={formik.values.showToClient}
            handleChange={(val) => formik.setFieldValue("showToClient", val)}
            error={Boolean(formik.errors.showToClient)}
            helperText={formik.errors?.showToClient?.toString() ?? ""}
          />
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <Dropdown
            items={[
              { label: "Incomplete", value: GoalStatus.INCOMPLETE },
              { label: "Complete", value: GoalStatus.COMPLETE },
            ]}
            label="Status"
            id="status"
            required
            value={formik.values.status}
            handleChange={(val) => formik.setFieldValue("status", val)}
            error={Boolean(formik.errors.status)}
            helperText={formik.errors?.status?.toString() ?? ""}
          />
        </Box>
        <Box sx={{ paddingLeft: DEFAULT_PADDING, paddingRight: DEFAULT_PADDING, paddingBottom: DEFAULT_PADDING }}>
          <LandisButton loading={loading} type="submit" variant="contained" size="large" sx={{ width: "100%" }}>
            {type === "Add" ? "Add Goal" : "Modify Goal"}
          </LandisButton>
          <Box sx={{ paddingTop: DEFAULT_PADDING }} />
          <Button variant="outlined" size="large" sx={{ width: "100%" }} onClick={close}>
            Close
          </Button>
        </Box>
      </form>
      {type === "Update" ? <ClientGraduationGoalHistory goal={goal} /> : <div></div>}
    </MainCard>
  );
}

export default ClientGraduationAddUpdateGoal;

import { DocusignIcon, Dropdown, LandisButton } from "@/components/base";
import { CreateTemplateFieldInTemplateInput, useToast } from "@/hooks";
import {
  TemplateFieldAudience,
  TemplateFieldAudienceDisplayMap,
  TemplateFieldType,
  TemplateFieldTypeDisplayMap,
  isDocusignTemplateField,
} from "@/types";
import { Check } from "@mui/icons-material";
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface Props {
  onAddNewTemplateField: (input: CreateTemplateFieldInTemplateInput & { isRequired?: boolean }) => Promise<void>;
  inlineCreation?: boolean;
}

function AddNewTemplateFieldForm({ onAddNewTemplateField, inlineCreation = false }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
      description: "",
      type: "" as TemplateFieldType,
      isRequired: true,
      audience: null,
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        await onAddNewTemplateField({
          name: values.name,
          description: values.description,
          type: values.type,
          isRequired: values.isRequired,
          audience: values.audience,
        });
        resetForm();
      } catch (e) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.name) {
        errors.name = "You are required to enter a name.";
      }

      if (!values.description) {
        errors.description = "You are required to enter a description.";
      }

      if (!values.type) {
        errors.type = "You are required to select a field type.";
      }

      if (!values.audience && isDocusignTemplateField(values.type)) {
        errors.type = "You are required to select a party completing the field.";
      }

      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  useEffect(() => {
    formik.setFieldValue("audience", null);
  }, [formik.values.type]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item xs={12} direction="column" container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            size="small"
            label="Field Name"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formik.values.description}
            onChange={(e) => formik.setFieldValue("description", e.target.value)}
            multiline
            minRows={4}
            size="small"
            label="Field Description"
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            id="add-new-template-field-type"
            label="Field Type"
            size="small"
            items={Object.values(TemplateFieldType).map((type) => ({
              label: (
                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                  {isDocusignTemplateField(type) && <DocusignIcon style={{ width: "1rem" }} />}
                  {TemplateFieldTypeDisplayMap[type]}
                </div>
              ),
              value: type,
            }))}
            value={formik.values.type}
            handleChange={(value) => formik.setFieldValue("type", value)}
          />
        </Grid>
        {formik.values.type && isDocusignTemplateField(formik.values.type) && (
          <Grid item xs={12}>
            <Dropdown
              id="add-new-template-field-audience"
              label="Party Completing Field"
              size="small"
              items={Object.values(TemplateFieldAudience).map((audience) => ({
                label: TemplateFieldAudienceDisplayMap[audience],
                value: audience,
              }))}
              value={formik.values.audience}
              handleChange={(value) => formik.setFieldValue("audience", value)}
            />
          </Grid>
        )}
        {inlineCreation && (
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch checked={formik.values.isRequired} onChange={(e) => formik.setFieldValue("isRequired", e.target.checked)} />}
              label={formik.values.isRequired ? "Required" : "Optional"}
            />
          </Grid>
        )}
        <Grid item>
          <LandisButton loading={loading} type="submit" endIcon={<Check />} size="small" variant="contained">
            Submit
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddNewTemplateFieldForm;

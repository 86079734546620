import { Box } from "@mui/material";
import { BudgetCalculator, ClientPersonHeader, ClientProgramHeader } from "@/components/partial";

import { Person, ProgramClient } from "@/types";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientBudgetCalculator(props: Props) {
  return (
    <>
      <ClientPersonHeader {...props} />
      <Box sx={{ marginBottom: "12px" }} />
      <ClientProgramHeader {...props} />
      <Box sx={{ marginBottom: "12px" }} />
      <BudgetCalculator {...props} />
    </>
  );
}

import { TypeOfTax } from "@l4s/client-property-models";

export enum Field {
  PARCEL_NUMBER = "parcelNumber",
  ADDRESS_STREET = "addressStreet",
  ADDRESS_UNIT = "addressUnit",
  ADDRESS_CITY = "addressCity",
  ADDRESS_STATE = "addressState",
  ADDRESS_ZIP = "addressZip",
  PHONE = "phone",
  EMAIL = "email",
  WEBSITE = "website",
  TYPE = "type",
  NAME = "name",
}

export const TypeOfTaxDisplayMap: Record<TypeOfTax, string> = {
  [TypeOfTax.CITY]: "City",
  [TypeOfTax.COUNTY]: "County",
  [TypeOfTax.MUNICIPAL]: "Municipal",
  [TypeOfTax.OTHER]: "Other",
  [TypeOfTax.SCHOOL]: "School",
};

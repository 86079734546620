import { ApplicationAdverseActionsDto } from "@/types";
import { Paper } from "@mui/material";
import { ReactEventHandler } from "react";
import { Chip } from "@/components/berry/ui-component/extended";

interface Props {
  applicationAdverseActions: ApplicationAdverseActionsDto[];
}

const ApplicationAdverseActionList = ({ applicationAdverseActions }: Props) => {
  const stopPropogration: ReactEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={stopPropogration}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
          listStyle: "none",
          gap: 1,
          p: 1,
          m: 0,
        }}
        component="ul"
      >
        {applicationAdverseActions?.map((aa) => {
          return <Chip key={aa.id} chipcolor={"secondary"} label={aa.adverseActionDefinition.adverseActionReason} disabled={false} />;
        })}
      </Paper>
    </div>
  );
};
export default ApplicationAdverseActionList;

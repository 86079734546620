import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { HousingQuestionnaireDesire, HousingQuestionnaireDtoModel } from "@/types";
import { displayYesOrNo, titleCase } from "./utis";

interface Props {
  housingQuestionnaire: HousingQuestionnaireDtoModel;
}

const ClientHousingQuestionnaire = ({ housingQuestionnaire }: Props) => {
  const theme = useTheme();

  const homeFeatureData = [
    {
      displayName: "Large Yard",
      value: housingQuestionnaire?.largeYard,
    },
    {
      displayName: "Small Yard",
      value: housingQuestionnaire?.smallYard,
    },
    {
      displayName: "Fence",
      value: housingQuestionnaire?.fence,
    },
    {
      displayName: "Garage",
      value: housingQuestionnaire?.garage,
    },
    {
      displayName: "Carport",
      value: housingQuestionnaire?.carport,
    },
    {
      displayName: "Patio/Deck",
      value: housingQuestionnaire?.patioDeck,
    },
    {
      displayName: "Pool",
      value: housingQuestionnaire?.pool,
    },
    {
      displayName: "Outdoor Spa",
      value: housingQuestionnaire?.outdoorSpa,
    },
    {
      displayName: "Extra Parking",
      value: housingQuestionnaire?.extraParking,
    },
    {
      displayName: "Other Buildings",
      value: housingQuestionnaire?.otherBuildings,
    },
    {
      displayName: "Special View",
      value: housingQuestionnaire?.specialView,
    },
    {
      displayName: "Central Air",
      value: housingQuestionnaire?.centralAir,
    },
    {
      displayName: "Hardwood Floors",
      value: housingQuestionnaire?.hardwoodFloors,
    },
    {
      displayName: "Wall-to-Wall Carpet",
      value: housingQuestionnaire?.wallToWallCarpet,
    },
    {
      displayName: "Basement",
      value: housingQuestionnaire?.basement,
    },
    {
      displayName: "Laundry",
      value: housingQuestionnaire?.laundry,
    },
    {
      displayName: "Updated Appliances",
      value: housingQuestionnaire?.updatedAppliances,
    },
    {
      displayName: "Walk-In Closets",
      value: housingQuestionnaire?.walkInClosets,
    },
    {
      displayName: "Open Floor Plan",
      value: housingQuestionnaire?.openFloorPlan,
    },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ padding: theme.spacing(2) }}>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Desired Part of Town</Typography>
          <Typography>{housingQuestionnaire?.partOfTown || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Price Range</Typography>
          <Typography>
            {housingQuestionnaire?.priceRangeMinDollars && housingQuestionnaire?.priceRangeMaxDollars
              ? `$${housingQuestionnaire.priceRangeMinDollars} - $${housingQuestionnaire.priceRangeMaxDollars}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Is School a Factor</Typography>
          <Typography>{displayYesOrNo(housingQuestionnaire?.isSchoolFactor)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">School Factor Details</Typography>
          <Typography>{housingQuestionnaire?.schoolFactorDetails || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Interested Home Age</Typography>
          <Typography>{titleCase(housingQuestionnaire?.interestedHomeAge)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Interested Home Kinds</Typography>
          <Typography>{titleCase(housingQuestionnaire?.interestedHomeKinds?.join(", "))}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Interested Home Styles</Typography>
          <Typography>{titleCase(housingQuestionnaire?.interestedHomeStyles?.join(", "))}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Willingness to Renovate</Typography>
          <Typography>{titleCase(housingQuestionnaire?.renovationEffort)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Desires Close Public Transit</Typography>
          <Typography>{displayYesOrNo(housingQuestionnaire?.desiresClosePublicTransit)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Physical Needs</Typography>
          <Typography>{displayYesOrNo(housingQuestionnaire?.hasPhysicalNeeds)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Required Bedroom Count</Typography>
          <Typography>{housingQuestionnaire?.requiredBedroomCount ? housingQuestionnaire.requiredBedroomCount : "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Desired Bedroom Count</Typography>
          <Typography>{housingQuestionnaire?.desiredBedroomCount ? housingQuestionnaire.desiredBedroomCount : "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Desured Bathroom Count</Typography>
          <Typography>{titleCase(housingQuestionnaire?.baths)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Size (Min - Max Sqft)</Typography>
          <Typography>
            {housingQuestionnaire?.sizeMinSqft && housingQuestionnaire?.sizeMaxSqft
              ? `${housingQuestionnaire.sizeMinSqft} - ${housingQuestionnaire.sizeMaxSqft} sqft`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Must Have Home Features</Typography>
          <Typography>
            {homeFeatureData
              .filter((feature) => feature.value === HousingQuestionnaireDesire.MUST)
              .map((feature) => feature.displayName)
              .join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Like to Have Home Features</Typography>
          <Typography>
            {homeFeatureData
              .filter((feature) => feature.value === HousingQuestionnaireDesire.LIKE)
              .map((feature) => feature.displayName)
              .join(", ")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientHousingQuestionnaire;

import {
  AccordionDetails,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import EvaluationCriteriaRow from "./EvaluationCriteriaRow";
import { PropertyEvaluationCriteriaDto } from "@l4s/client-property-models";
import moment from "moment";
import { LandisButton } from "@/components/base";
import { useMemo, useState } from "react";
import { DateFormat } from "@/utils";

interface Props {
  evaluationCriteria: PropertyEvaluationCriteriaDto[];
  setEvaluationCriteriaSaving: (isSaving: boolean) => void;
  handleSubmit: (sendNotificationToClient: boolean) => Promise<void>;
  submittingPropertyEvaluation: boolean;
  isUnsubmittedEvaluation: boolean;
}

export default function EvaluationCriteria({
  evaluationCriteria,
  setEvaluationCriteriaSaving,
  handleSubmit,
  submittingPropertyEvaluation,
  isUnsubmittedEvaluation,
}: Props) {
  const theme = useTheme();
  const [sendNotificationToClient, setSendNotificationToClient] = useState(false);

  function handleCheckSendNotificationToClient(): void {
    setSendNotificationToClient(!sendNotificationToClient);
  }

  function handleSubmitPropertyEvaluation(): void {
    handleSubmit(sendNotificationToClient);
  }

  const getMostRecentUpdatedDate = (arr: PropertyEvaluationCriteriaDto[]): Date | null => {
    if (arr.length === 0) {
      return null;
    }

    return arr.reduce((mostRecentDate, currentCriteria) => {
      const currentMoment = moment(currentCriteria.updated);
      const mostRecentMoment = mostRecentDate ? moment(mostRecentDate) : null;

      if (!mostRecentMoment || currentMoment.isAfter(mostRecentMoment)) {
        return currentCriteria.updated;
      }

      return mostRecentDate;
    }, null as Date | null);
  };

  const mostRecentUpdatedDate = useMemo<Date | null>(() => getMostRecentUpdatedDate(evaluationCriteria), [evaluationCriteria]);

  const mostRecentUpdatedDateString = mostRecentUpdatedDate
    ? moment(mostRecentUpdatedDate).format(DateFormat.DISPLAY_DATE_TIME_YEAR_VERBOSE)
    : "Never";

  return (
    <AccordionDetails
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "0",
        paddingLeft: `${theme.spacing(6)}`,
        paddingBottom: "0",
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Property evaluations">
          <TableHead>
            <TableRow>
              <TableCell>Evaluation Criteria</TableCell>
              <TableCell>Passed</TableCell>
              <TableCell>Failed</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationCriteria.map((criteria) => (
              <EvaluationCriteriaRow
                key={criteria.id}
                evaluationCriteria={criteria}
                setEvaluationCriteriaSaving={setEvaluationCriteriaSaving}
                isUnsubmittedEvaluation={isUnsubmittedEvaluation}
              />
            ))}

            {isUnsubmittedEvaluation && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ paddingTop: "4rem", fontWeight: theme.typography.fontWeightBold }}>
                  Send client a notification for new eligible properties
                </TableCell>
                <TableCell sx={{ paddingTop: "4rem" }}>
                  <Checkbox
                    checked={sendNotificationToClient}
                    onClick={handleCheckSendNotificationToClient}
                    disabled={!isUnsubmittedEvaluation}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <caption>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "2rem", alignItems: "center" }}>
              <Typography>Last updated {mostRecentUpdatedDateString}</Typography>
              {isUnsubmittedEvaluation && (
                <LandisButton onClick={handleSubmitPropertyEvaluation} variant="contained" loading={submittingPropertyEvaluation}>
                  {submittingPropertyEvaluation ? "Submitting" : "Submit Eligibility"}
                </LandisButton>
              )}
            </div>
          </caption>
        </Table>
      </TableContainer>
    </AccordionDetails>
  );
}

import { PropertyBasicHomeStats } from "@/components/partial";
import { UsePropertyHook } from "@/hooks";

interface Props {
  usePropertyInstance: UsePropertyHook;
}

function PropertyOverview({ usePropertyInstance }: Props) {
  return <PropertyBasicHomeStats usePropertyInstance={usePropertyInstance} />;
}

export default PropertyOverview;

import { useState, SyntheticEvent } from "react";
import { Box, Grid, Tab, Tabs, TextField } from "@mui/material";
import { MainCard, SubCard } from "@/components/berry";
import { useAuth } from "@/hooks";
import { gridSpacing } from "@/store/constant";
import { AuthenticatedAccount, TabsProps } from "@/types";
import { Link } from "react-router-dom";

import { IconUser } from "@tabler/icons";
import { RootState } from "@/store";
import { useSelector } from "react-redux";

function TabPanel({ children, value, index, ...other }: TabsProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabsOption = [
  {
    label: "Details",
    icon: <IconUser />,
  },
];

export function EmployeeProfile() {
  const authenticatedAccount = useSelector<RootState, AuthenticatedAccount | null>((state) => state.authenticatedAccount);
  const auth = useAuth();

  const [value, setValue] = useState<number>(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            sx={{
              margin: "-12px -12px 12px",
              mb: 3,
            }}
          >
            {tabsOption.map((tab, index) => (
              <Link to="#">
                <Tab key={index} icon={tab.icon} iconPosition="start" label={tab.label} {...a11yProps(index)} />
              </Link>
            ))}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <SubCard title="Account Information">
                  <form noValidate autoComplete="off">
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="accountId"
                          fullWidth
                          label="Landis Account ID"
                          value={authenticatedAccount?.accountId || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField id="role" fullWidth label="Role" value={authenticatedAccount?.role || ""} disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField id="fullName" fullWidth label="Full Name" value={authenticatedAccount?.fullName || ""} disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField id="email" fullWidth label="Email" value={authenticatedAccount?.email || ""} disabled />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="oktaToken"
                          label="Token"
                          multiline
                          fullWidth
                          rows={12}
                          spellCheck={false}
                          value={auth?.oktaAuthToken ?? ""}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </SubCard>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </MainCard>
  );
}

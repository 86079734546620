import { useDebounce } from "@/hooks";
import { bridgeV2ApiInstance } from "@/services";
import { CREATE_PROPERTY, GET_PROPERTIES } from "@/services/routes";
import { PropertyDto } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface CreatePropertyInput {
  addressStreet: PropertyDto["addressStreet"];
  addressUnit: PropertyDto["addressUnit"];
  addressState: PropertyDto["addressState"];
  addressCity: PropertyDto["addressCity"];
  addressZip: PropertyDto["addressZip"];
}

export interface UsePropertiesHook {
  properties: Array<PropertyDto>;
  loading: boolean;
  error?: string;
  page: number;
  setPage: (value: number) => void;
  totalPages?: number;
  totalCount?: number;
  createProperty: (input: CreatePropertyInput) => Promise<void>;
}

interface PagingResponse {
  previous: string | null;
  next: string | null;
  totalCount: number;
  totalPages: number;
}

interface GetPropertiesResponse {
  results: Array<PropertyDto>;
  paging: PagingResponse;
}

// Can change this to be dynamic in state at a later time
const DEFAULT_LIMIT = 25;

export const useProperties = (search?: string): UsePropertiesHook => {
  const [properties, setProperties] = useState<Array<PropertyDto>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | undefined>();
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const debouncedSearch = useDebounce(search, 1000);

  const navigate = useNavigate();

  const resetState = (): void => {
    setLoading(true);
    setError(undefined);
    setProperties([]);
  };

  const getProperties = async (pageNumber: number) => {
    resetState();
    try {
      const response = await bridgeV2ApiInstance.get<GetPropertiesResponse>(GET_PROPERTIES, {
        params: {
          search: search || undefined,
          limit: DEFAULT_LIMIT,
          offset: (pageNumber - 1) * DEFAULT_LIMIT,
        },
      });
      setProperties(response.data.results);
      setTotalPages(response.data.paging.totalPages);
      setTotalCount(response.data.paging.totalCount);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching properties.");
    }
    setLoading(false);
  };

  const createProperty = async (input: CreatePropertyInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<PropertyDto>(CREATE_PROPERTY, input);
      navigate(`${response.data.id}`);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error creating property.");
    }
  };

  const changePage = (value: number): void => {
    setPage(value);
    getProperties(value);
  };

  useEffect(() => {
    changePage(1);
  }, [debouncedSearch]);

  useEffect(() => {
    resetState();
  }, [search]);

  return {
    properties,
    loading,
    error,
    page,
    setPage: changePage,
    totalPages,
    totalCount,
    createProperty,
  };
};

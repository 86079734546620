import { SkeletonList } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseTemplatesInDefinedEnvelopeHook } from "@/hooks";
import { CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IconLink, IconTrash } from "@tabler/icons";
import { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  useTemplatesInDefinedEnvelopeInstance: UseTemplatesInDefinedEnvelopeHook;
  releaseCreateDefinedEnvelopes: boolean;
}

const TemplatesInDefinedEnvelopeList = ({ useTemplatesInDefinedEnvelopeInstance, releaseCreateDefinedEnvelopes }: Props) => {
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const { templatesInDefinedEnvelope, removeTemplateFromDefinedEnvelope, loading } = useTemplatesInDefinedEnvelopeInstance;

  const onRemoveTemplateFromDefinedEnvelope = async (templateId: string) => {
    setRemoveLoading(true);
    try {
      await removeTemplateFromDefinedEnvelope(templateId);
    } catch (e) {}
    setRemoveLoading(false);
  };

  const TrashIconBtn = ({ templateId }: { templateId: string }) => {
    if (releaseCreateDefinedEnvelopes)
      return <IconTrash onClick={() => onRemoveTemplateFromDefinedEnvelope(templateId)} size={16} style={{ cursor: "pointer" }} />;
    return <></>;
  };

  return (
    <MainCard title="Templates in Defined Envelope">
      {loading ? (
        <SkeletonList count={3} height={25} />
      ) : !!!templatesInDefinedEnvelope.length ? (
        <Typography variant="body1">
          <i>No templates in this envelope. Add some below.</i>
        </Typography>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templatesInDefinedEnvelope.map((template) => (
              <TableRow key={template.id}>
                <TableCell>
                  <Grid container alignItems="center" gap={0.5}>
                    <IconLink size={16} />
                    <Link to={`/admin/documents/templates/${template.id}`}>{template.name}</Link>
                  </Grid>
                </TableCell>
                <TableCell align="right">
                  {removeLoading ? <CircularProgress size={12} /> : <TrashIconBtn templateId={template.id} />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </MainCard>
  );
};

export default TemplatesInDefinedEnvelopeList;

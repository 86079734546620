import { combineReducers } from "redux";

import { authenticatedAccountReducer, snackbarReducer, customizationReducer, menuReducer, clientMenuItemsReducer } from "./slices";

const reducer = combineReducers({
  snackbar: snackbarReducer,
  menu: menuReducer,
  customization: customizationReducer,
  authenticatedAccount: authenticatedAccountReducer,
  clientMenuItems: clientMenuItemsReducer,
});

export default reducer;

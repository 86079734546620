import { DateFormat, displayUsDollars } from "@/utils";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Legend,
  Tooltip,
  ChartDataset,
  ChartOptions,
} from "chart.js";
import { backgroundColorOptions, skipped } from "./chartUtils";
import { AccountBalances } from "@/hooks";
import { useMemo } from "react";
import { isNumber } from "lodash";

const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    line: {
      tension: 0.4,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value: string | number) {
          if (typeof value === "number") {
            return displayUsDollars(value);
          } else {
            return "$" + value;
          }
        },
      },
    },
  },
};

interface Props {
  startDate: Date;
  endDate: Date;
  accounts: Array<AccountBalances>;
}

function AccountBalancesGraph({ startDate, endDate, accounts }: Props) {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  // Create a label for every day in the span
  const labels = useMemo(() => {
    const spanInDays = moment(endDate).diff(moment(startDate), "days") + 1;
    return Array.from(Array(spanInDays).keys()).map((i) => moment(startDate).add(i, "days").format(DateFormat.MONTH_DAY_WRITTEN));
  }, [startDate, endDate]);

  const datasets: Array<ChartDataset<"line">> = useMemo(() => {
    return accounts.map((account, index) => {
      return {
        label: account.plaidAccountName,
        backgroundColor: backgroundColorOptions[index],
        borderColor: backgroundColorOptions[index],
        // missing dates get a hashed line
        spanGaps: true,
        segment: {
          borderColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.3)"),
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        // allow for a larger point radius if there is only one data point
        pointRadius: account.balances.length > 1 ? 1 : 3,
        // Fill in the data for each label, return NaN if missing
        data: labels.map((label) => {
          const balance = account.balances.find((_balance) => moment(_balance.created).format(DateFormat.MONTH_DAY_WRITTEN) === label);
          return isNumber(balance?.balanceDollars) ? balance.balanceDollars : NaN;
        }),
      };
    });
  }, [accounts]);

  return (
    <>
      <Line
        datasetIdKey="id"
        options={options}
        data={{
          labels,
          datasets,
        }}
      />
    </>
  );
}

export default AccountBalancesGraph;

export enum DocumentCategory {
  TEMPLATE = "template",
  AUTOFILL_READY_TEMPLATE = "autofill_ready_template",
}

export interface Document {
  id: string;
  created: string;
  name: string;
  mime: string;
  size: number;
  uploaderAccountId: string | null;
  description: string | null;
  classifiedDocumentType: string | null;
  category: DocumentCategory;

  url?: string;
}

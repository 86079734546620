export interface Goal {
  id: string;
  programClientId: string;
  typeId: string;
  goalTitle: string;
  goalDescription: string;
  created: string;
  creator: string;
  currentValue: string;
  targetValue: string;
  status: string;
  showToClient: string;
  isActive: boolean;
  type: TypeOfGoal;
}

export const GoalTypeMap: Record<GoalTypeEnum, string> = {
  CREDIT_SCORE: "Credit Score",
  // CH_7_BANKRUPTCY: "Ch 7 Bankruptcy",
  // CH_13_BANKRUPTCY: "Ch 13 Bankruptcy",
  // FORECLOSURE: "Foreclosure",
  // EDU_COLLECTIONS: "Student Loans Collections",
  // DELINQUENCY: "Delinquency",
  // PRIMARY_EMPLOYMENT: "Primary Employment",
  // SECONDARY_INCOME: "Secondary Employment",
  // BONUS_INCOME: "Bonus Income",
  // OVERTIME_INCOME: "Overtime Income",
  // SELF_EMPLOYMENT: "Self Employment",
  SAVINGS: "Savings",
  // TOTAL_DEBT: "Total Debt",
  // SPECIFIC_DEBT: "Specific Debt",
  // CUSTOM: "Custom",
  // MAX_DTI: "Max DTI",
  // CH_13_BANKRUPTCY_DISCHARGE: "Ch 13 Bankruptcy Discharge",
  // CH_13_BANKRUPTCY_DISMISSAL: "Ch 13 Bankruptcy Dismissal",
  // FORECLOSURE_CHARGEOFF: "Foreclosure Chargeoff",
  // MORTGAGE_DEBT_DISCHARGED: "Mortgage Debt Discharged",
  FINANCIAL_WELLNESS: "Financial Wellness",
  GRADUATION: "Graduation",
};

export enum GoalTypeEnum {
  CREDIT_SCORE = "CREDIT_SCORE",
  // CH_7_BANKRUPTCY = "CH_7_BANKRUPTCY",
  // CH_13_BANKRUPTCY = "CH_13_BANKRUPTCY",
  // FORECLOSURE = "FORECLOSURE",
  // EDU_COLLECTIONS = "EDU_COLLECTIONS",
  // DELINQUENCY = "DELINQUENCY",
  // PRIMARY_EMPLOYMENT = "PRIMARY_EMPLOYMENT",
  // SECONDARY_INCOME = "SECONDARY_INCOME",
  // BONUS_INCOME = "BONUS_INCOME",
  // OVERTIME_INCOME = "OVERTIME_INCOME",
  // SELF_EMPLOYMENT = "SELF_EMPLOYMENT",
  SAVINGS = "SAVINGS",
  // TOTAL_DEBT = "TOTAL_DEBT",
  // SPECIFIC_DEBT = "SPECIFIC_DEBT",
  // CUSTOM = "CUSTOM",
  // MAX_DTI = "MAX_DTI",
  // CH_13_BANKRUPTCY_DISCHARGE = "CH_13_BANKRUPTCY_DISCHARGE",
  // CH_13_BANKRUPTCY_DISMISSAL = "CH_13_BANKRUPTCY_DISMISSAL",
  // FORECLOSURE_CHARGEOFF = "FORECLOSURE_CHARGEOFF",
  // MORTGAGE_DEBT_DISCHARGED = "MORTGAGE_DEBT_DISCHARGED",
  FINANCIAL_WELLNESS = "FINANCIAL_WELLNESS",
  GRADUATION = "GRADUATION",
}

export enum GoalStatus {
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export const GoalStatusMap = {
  INCOMPLETE: "Incomplete",
  COMPLETE: "Complete",
};

export interface GoalHistory {
  id: string;
  typeId: string;
  goalDescription: string;
  created: string;
  updated: string;
  creator: string;
  updater: string;
  currentValue: string;
  targetValue: string;
  isActive: string;
  status: GoalStatus;
  showToClient: boolean;
  goalId: string;
}
export const goalDropdownTypes = [
  { label: GoalTypeMap.CREDIT_SCORE, value: GoalTypeEnum.CREDIT_SCORE },
  // { label: GoalTypeMap.CH_7_BANKRUPTCY, value: GoalTypeEnum.CH_7_BANKRUPTCY },
  // { label: GoalTypeMap.CH_13_BANKRUPTCY, value: GoalTypeEnum.CH_13_BANKRUPTCY },
  // { label: GoalTypeMap.CH_13_BANKRUPTCY_DISCHARGE, value: GoalTypeEnum.CH_13_BANKRUPTCY_DISCHARGE },
  // { label: GoalTypeMap.CH_13_BANKRUPTCY_DISMISSAL, value: GoalTypeEnum.CH_13_BANKRUPTCY_DISMISSAL },
  // { label: GoalTypeMap.FORECLOSURE_CHARGEOFF, value: GoalTypeEnum.FORECLOSURE_CHARGEOFF },
  // { label: GoalTypeMap.MORTGAGE_DEBT_DISCHARGED, value: GoalTypeEnum.MORTGAGE_DEBT_DISCHARGED },
  // { label: GoalTypeMap.FORECLOSURE, value: GoalTypeEnum.FORECLOSURE },
  // { label: GoalTypeMap.EDU_COLLECTIONS, value: GoalTypeEnum.EDU_COLLECTIONS },
  // { label: GoalTypeMap.DELINQUENCY, value: GoalTypeEnum.DELINQUENCY },
  // { label: GoalTypeMap.PRIMARY_EMPLOYMENT, value: GoalTypeEnum.PRIMARY_EMPLOYMENT },
  // { label: GoalTypeMap.SECONDARY_INCOME, value: GoalTypeEnum.SECONDARY_INCOME },
  // { label: GoalTypeMap.BONUS_INCOME, value: GoalTypeEnum.BONUS_INCOME },
  // { label: GoalTypeMap.OVERTIME_INCOME, value: GoalTypeEnum.OVERTIME_INCOME },
  // { label: GoalTypeMap.SELF_EMPLOYMENT, value: GoalTypeEnum.SELF_EMPLOYMENT },
  { label: GoalTypeMap.SAVINGS, value: GoalTypeEnum.SAVINGS },
  // { label: GoalTypeMap.TOTAL_DEBT, value: GoalTypeEnum.TOTAL_DEBT },
  // { label: GoalTypeMap.SPECIFIC_DEBT, value: GoalTypeEnum.SPECIFIC_DEBT },
  // { label: GoalTypeMap.MAX_DTI, value: GoalTypeEnum.MAX_DTI },
  // { label: GoalTypeMap.CUSTOM, value: GoalTypeEnum.CUSTOM },
  { label: GoalTypeMap.FINANCIAL_WELLNESS, value: GoalTypeEnum.FINANCIAL_WELLNESS },
  { label: GoalTypeMap.GRADUATION, value: GoalTypeEnum.GRADUATION },
];

export const goalStatusDropdownTypes = [
  { label: GoalStatusMap.INCOMPLETE, value: GoalStatus.INCOMPLETE },
  { label: GoalStatusMap.COMPLETE, value: GoalStatus.COMPLETE },
];

export enum TypeOfTypeOfGoalEnum { // Don't blame me!
  STRING = "STRING",
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
}

export interface TypeOfGoal {
  id: string;
  name: GoalTypeEnum;
  isEditable: boolean;
  type: TypeOfTypeOfGoalEnum;
  defaultGoalTitle: string;
}

import { useState, useCallback } from "react";
import { bridgeV2ApiInstance, routes } from "@/services";
import { Person } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { debounce } from "lodash";

export interface UseSearchForPersons {
  persons: Array<Person>;
  searchForPersons: (searchString: string) => Promise<void>;
}

export const useSearchForPersons = (): UseSearchForPersons => {
  const [persons, setPersons] = useState<Array<Person>>([]);

  const searchForPersons = async (searchString: string): Promise<void> => {
    if (!searchString) {
      setPersons([]);

      return;
    }

    try {
      const response = await bridgeV2ApiInstance.get<Array<Person>>(routes.SEARCH_FOR_PERSONS(searchString));
      setPersons(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching person details.");
    }
  };

  const debouncedSearchForPersons = useCallback(debounce(searchForPersons, 250), []);

  return {
    persons,
    searchForPersons: debouncedSearchForPersons,
  };
};

import { isMobile } from "react-device-detect";
import { NavItemType } from "@/types";

import oldBridge from "./oldBridge";
import { useAdminRoutes } from "./admin";

export const useMenuItems = (): { items: NavItemType[] } => {
  const adminRouteGroup = useAdminRoutes();

  return {
    items: [!isMobile && oldBridge, ...(adminRouteGroup ? [adminRouteGroup] : [])],
  };
};

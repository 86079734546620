import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { MainCard } from "@/components/berry";
import { Goal, GoalTypeMap } from "@/types";
import { useGoalHistory } from "@/hooks/data-hooks";
import { useEffect } from "react";
import moment from "moment";

interface Props {
  goal: Goal;
}

function ClientGraduationGoalHistory({ goal }: Props) {
  const { goalHistoryRecords, loadGoalHistoryRecords } = useGoalHistory(goal?.id);

  useEffect(() => {
    loadGoalHistoryRecords();
  }, []);

  return (
    <MainCard title={`${goal.goalTitle || GoalTypeMap[goal.type.name]} History`} content={false}>
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Date</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(goalHistoryRecords ?? []).map((row, index) => (
                <TableRow hover key={index} onClick={() => {}}>
                  <TableCell sx={{ pl: 3 }}>
                    <Typography component="div" align="left" variant="subtitle1">
                      {moment(row.updated).format("MM/DD/YY")}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.targetValue}</TableCell>
                  <TableCell>{row.currentValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </MainCard>
  );
}

export default ClientGraduationGoalHistory;

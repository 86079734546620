import { CurrencyCentsTextField, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

function SalesFinancialsForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updateSalesFinancials } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      actualSaleDate: applicationsPropertyFinance.actualSaleDate
        ? moment.utc(applicationsPropertyFinance.actualSaleDate).format("M/D/YYYY")
        : null,
      actualPriceSaleCents: applicationsPropertyFinance.actualPriceSaleCents,
      aggClosingCostsSaleCents: applicationsPropertyFinance.aggClosingCostsSaleCents,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateSalesFinancials({
          actualSaleDate: values.actualSaleDate,
          actualPriceSaleCents: values.actualPriceSaleCents,
          aggClosingCostsSaleCents: values.aggClosingCostsSaleCents,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      return errors;
    },
  });

  return (
    <MainCard title="Edit Fixed Payments">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <DatePicker
              value={formik.values.actualSaleDate}
              onChange={(val) => {
                formik.setFieldValue("actualSaleDate", moment(val).format("M/D/YYYY"));
              }}
              label="Lease Start Date"
              renderInput={(params) => (
                <TextField
                  id="actualSaleDate"
                  fullWidth
                  {...params}
                  helperText={formik.errors.actualSaleDate}
                  error={!!formik.errors.actualSaleDate}
                />
              )}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Actual Sale Price"
              value={formik.values.actualPriceSaleCents}
              handleChange={(val) => formik.setFieldValue("actualPriceSaleCents", val)}
              error={!!formik.errors.actualPriceSaleCents}
              helperText={formik.errors.actualPriceSaleCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Aggregate Closing Costs"
              value={formik.values.aggClosingCostsSaleCents}
              handleChange={(val) => formik.setFieldValue("aggClosingCostsSaleCents", val)}
              error={!!formik.errors.aggClosingCostsSaleCents}
              helperText={formik.errors.aggClosingCostsSaleCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default SalesFinancialsForm;

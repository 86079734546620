import { CancelPresentationTwoTone, DescriptionTwoTone, ExitToAppTwoTone, PaymentTwoTone } from "@mui/icons-material";

export enum RemediationTabOptions {
  PAYMENT_PLAN = "payment-plan",
  HARD_SHIP = "hard-ship",
  EXIT_PLAN = "exit-plan",
  EVICTION = "eviction",
}

export const tabsOptions = [
  {
    id: RemediationTabOptions.PAYMENT_PLAN,
    label: "Payment Plan",
    icon: <PaymentTwoTone sx={{ fontSize: "1.3rem" }} />,
  },
  {
    id: RemediationTabOptions.HARD_SHIP,
    label: "Hardship",
    icon: <DescriptionTwoTone sx={{ fontSize: "1.3rem" }} />,
  },
  {
    id: RemediationTabOptions.EXIT_PLAN,
    label: "Exit Plan",
    icon: <ExitToAppTwoTone sx={{ fontSize: "1.3rem" }} />,
  },
  {
    id: RemediationTabOptions.EVICTION,
    label: "Eviction",
    icon: <CancelPresentationTwoTone sx={{ fontSize: "1.3rem" }} />,
  },
];

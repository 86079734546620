import { ClosedReason, JourneyMilestoneDtoModel, JourneyPathDtoModel } from "@/types";
import { Box, Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses, styled } from "@mui/material";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";

interface Props {
  previousPath: JourneyPathDtoModel;
  currentPath: JourneyPathDtoModel;
}

const JourneyStepper = ({ previousPath, currentPath }: Props) => {
  const [previouslyCompletedMilestone, setPreviouslyCompletedMilestone] = useState<JourneyMilestoneDtoModel | undefined>();
  const [focusMilestone, setFocusMilestone] = useState<JourneyMilestoneDtoModel | undefined>();
  const [steps, setSteps] = useState<Array<string>>([]);

  const StepIconRoot = styled("div")<{ ownerState: { active?: boolean; completed?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.grey[700],
    display: "flex",
    height: 20,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      color: theme.palette.primary.main,
    }),
    "& .StepIcon-locationOnIcon": {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
    "& .StepIcon-scheduleIcon": {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
    "& .StepIcon-checkIcon": {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
  }));

  const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.grey[800],
      borderTopWidth: 1,
      borderRadius: 2,
    },
  }));

  function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <CheckIcon />,
      2: <LocationOnIcon />,
      3: <ScheduleIcon />,
    };

    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </StepIconRoot>
    );
  }

  useEffect(() => {
    if (previousPath?.length && currentPath?.length) {
      previousPath.forEach((milestone) => {
        if (milestone.closedReason === ClosedReason.COMPLETED) {
          setPreviouslyCompletedMilestone(milestone);
        }
      });
      currentPath.forEach((milestone) => {
        if (milestone.focus) {
          setFocusMilestone(milestone);
        }
      });
    }
  }, [previousPath, currentPath]);

  useEffect(() => {
    setSteps([
      `${previouslyCompletedMilestone?.definition?.managerName ?? "N/A"}`,
      "You are here",
      `${focusMilestone?.definition?.managerName ?? "N/A"}`,
    ]);
  }, [focusMilestone]);

  if (!steps.length) {
    return <></>;
  }

  return (
    <Box sx={{ width: "100%", padding: ".5rem 0rem .5rem 0rem" }}>
      <Stepper activeStep={1} connector={<Connector />}>
        {steps.map((label, i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default JourneyStepper;

import { TableLayout } from "@/components/layout";
import { DateFormat, displayUsCents } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import HoaPaymentDetailsForm from "./HoaPaymentDetailsForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import HoasPaymentsTableHeader from "./HoasPaymentDetailsTableHeader";
import { UseHoas, UseHoasPaymentDetails, UsePropertyManagementCompanyInstance } from "@/hooks";
import { HoasPaymentDetails } from "@/types/PropertyManagement";
import { PaymentFrequencyDisplayMap, PaymentMethodDisplayMap, TypeOfPartyDisplayMap } from "../../useDetailForms";
import moment from "moment";

interface Props {
  useHoasPaymentDetailsInstance: UseHoasPaymentDetails;
  useHoasInstance: UseHoas;
  propertyId: string;
  usePropertyManagementCompanyInstance: UsePropertyManagementCompanyInstance;
}

const HoasPaymentDetailsList = ({
  useHoasPaymentDetailsInstance,
  useHoasInstance,
  propertyId,
  usePropertyManagementCompanyInstance,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedHoaPaymentDetail, setSelectedHoaPaymentDetail] = useState<HoasPaymentDetails>(null);
  const { hoasPaymentDetails } = useHoasPaymentDetailsInstance;

  function handleClick(row: HoasPaymentDetails) {
    setDrawerOpen(true);
    setSelectedHoaPaymentDetail(row);
  }

  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={
          <HoasPaymentsTableHeader
            propertyId={propertyId}
            useHoasPaymentDetailsInstance={useHoasPaymentDetailsInstance}
            useHoasInstance={useHoasInstance}
            usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance}
            title="HOA Payment Details"
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Responsible Party</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Payment Frequency</TableCell>
              <TableCell>External Account Id</TableCell>
              <TableCell>Initial Payment Date</TableCell>
              <TableCell>Dues</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Related HOA</TableCell>
              <TableCell>Property Management Company</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hoasPaymentDetails.map((row) => (
              <TableRow tabIndex={0} sx={{ width: "100%" }} hover key={row.id}>
                <TableCell>{TypeOfPartyDisplayMap[row?.responsibleParty]}</TableCell>
                <TableCell>{PaymentMethodDisplayMap[row?.paymentMethod]}</TableCell>
                <TableCell>{PaymentFrequencyDisplayMap[row?.paymentFrequency]}</TableCell>
                <TableCell>{row?.externalAccountId}</TableCell>
                <TableCell>{moment(row?.initialPaymentDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{displayUsCents(row?.duesCents)}</TableCell>
                <TableCell>{moment(row?.startDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{row?.endDate ? moment(row.endDate).format(DateFormat.DISPLAY_DATE_RANGE) : ""}</TableCell>
                <TableCell>{row?.hoa?.name}</TableCell>
                <TableCell>{row?.propertyManagementCompany?.name ?? ""}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <HoaPaymentDetailsForm
            close={() => setDrawerOpen(false)}
            hoaPaymentDetail={selectedHoaPaymentDetail}
            useHoasPaymentDetailsInstance={useHoasPaymentDetailsInstance}
            useHoasInstance={useHoasInstance}
            propertyId={propertyId}
            usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default HoasPaymentDetailsList;

import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { IconCheck, IconX } from "@tabler/icons";
import { useToast, useBudgetCalculation, useCalculatorInitialValues } from "@/hooks";
import { CalculateBudgetCheck, CombinedCalculateBudgetCheck, TypeOfMortgage } from "@l4s/qualification-models";
import { Box, Card, CardContent, Grid, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { displayUsDollars, formatMarket, handleCurrencyInput } from "@/utils";
import { isNil } from "lodash";
import { ProgramLengthOptions, InitialDepositPaymentOptions, InitialDepositPayment, Person } from "@/types";

interface FailedBudgetCalculatorCombinedCheckDetailsProps {
  description: string;
  sx?: Record<string, string>;
}

const FailedBudgetCalculatorSimpleCheckDetails = ({ description, sx }: FailedBudgetCalculatorCombinedCheckDetailsProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      sx={{
        backgroundColor: theme.palette.grey[100],
        marginLeft: "1rem",
        ...sx,
      }}
    >
      <Grid item>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
  );
};

interface FailedBudgetCalculatorCheckDetailsProps {
  check: CalculateBudgetCheck;
  requiredText?: string;
}

const FailedBudgetCalculatorCheckDetails = ({ check, requiredText }: FailedBudgetCalculatorCheckDetailsProps) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} container sx={{ backgroundColor: theme.palette.grey[100], marginLeft: "1rem", paddingBottom: "1rem" }}>
      <Grid item xs={12} container direction="row">
        <Grid item xs={4}>
          <Typography fontSize={14}>{requiredText ?? "Required"}:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography fontSize={14}>{displayUsDollars(check.expected)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="row">
        <Grid item xs={4}>
          <Typography fontSize={14}>Calculated:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography fontSize={14}>{displayUsDollars(check.received)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface BudgetCalculatorCheckResultProps {
  check?: CalculateBudgetCheck | CombinedCalculateBudgetCheck;
}

const BudgetCalculatorCheckResult = ({ check }: BudgetCalculatorCheckResultProps) => {
  return (
    <Grid item>
      <Typography>{isNil(check?.pass) ? "-" : check?.pass ? <IconCheck color="green" /> : <IconX color="red" />}</Typography>
    </Grid>
  );
};

interface Props {
  person: Person;
}

const BudgetCalculatorForm = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const theme = useTheme();

  const { calculatorInitialValues, loadInitialValues } = useCalculatorInitialValues(props.person.id);

  const { budgetCalculationResults, loadBudgetCalculationResults } = useBudgetCalculation();

  useEffect(() => {
    loadInitialValues();
  }, []);

  function handleCalculatorNumericValues(value: string | number | undefined, initialValue: number | undefined | null): number {
    if (value === 0) {
      return 0;
    }
    if (value) {
      return Number.parseInt(`${value}`);
    }

    return initialValue ?? undefined;
  }

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      monthlyGrossIncomeDollars: "",
      monthlyNetIncomeDollars: "",
      monthlyNonHousingDebtDollars: "",
      totalLiquidAssetsDollars: "",
      creditScore: "",
      marketId: "",
      familySize: "",
      payPartialInitialDeposit: InitialDepositPayment.FULL,
      homePriceDollars: "",
      closingCostsDollars: "",
      repurchaseDownpaymentDollars: "",
      initialDeposit: "",
      mortgageType: TypeOfMortgage.FHA,
      programLength: 12,
    },

    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      return errors;
    },

    onSubmit: async (values) => {
      setLoading(true);

      try {
        await loadBudgetCalculationResults({
          monthlyGrossIncomeDollars: handleCalculatorNumericValues(
            values.monthlyGrossIncomeDollars,
            calculatorInitialValues.monthlyGrossIncomeDollars
          ),
          monthlyNonHousingDebtDollars: handleCalculatorNumericValues(
            values.monthlyNonHousingDebtDollars,
            calculatorInitialValues.monthlyNonHousingDebtDollars
          ),
          totalLiquidAssetsDollars: handleCalculatorNumericValues(
            values.totalLiquidAssetsDollars,
            calculatorInitialValues.totalLiquidAssetsDollars
          ),
          creditScore: handleCalculatorNumericValues(values.creditScore, Number.parseInt(calculatorInitialValues.creditScore)),
          marketId: values.marketId,
          familySize: handleCalculatorNumericValues(values.familySize, calculatorInitialValues.familySize),
          payPartialInitialDeposit: values.payPartialInitialDeposit === InitialDepositPayment.PARTIAL,
          monthlyNetIncomeDollars: handleCalculatorNumericValues(
            values.monthlyNetIncomeDollars,
            calculatorInitialValues.monthlyNetIncomeDollars
          ),
          initialDownPaymentDollars: handleCalculatorNumericValues(values.initialDeposit, calculatorInitialValues.initialDepositDollars),
          homeCostDollars: handleCalculatorNumericValues(values.homePriceDollars, calculatorInitialValues.homePriceDollars),
          closingCostsDollars: handleCalculatorNumericValues(values.closingCostsDollars, calculatorInitialValues.closingCostsDollars),
          repurchaseDownPaymentDollars: handleCalculatorNumericValues(
            values.repurchaseDownpaymentDollars,
            calculatorInitialValues.repurchaseDownpaymentDollars
          ),
          mortgageType: values.mortgageType,
          programLength: values.programLength,
          rentYieldId: calculatorInitialValues.rentYieldId ?? undefined,
        });
      } catch (err) {
        addToast({ message: err.message ?? "Oops! THere was an error.", type: "error" });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  useEffect(() => {
    if (calculatorInitialValues?.mortgageType) {
      formik.setFieldValue("mortgageType", calculatorInitialValues.mortgageType);
    }
    if (calculatorInitialValues?.programLength) {
      formik.setFieldValue("programLength", calculatorInitialValues.programLength);
    }
  }, [calculatorInitialValues]);

  if (!calculatorInitialValues) {
    return <>Loading client details...</>;
  }

  function handleCreditScoreChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (
      // user selected whole value and deleted it
      (e.target.value === "" && Number.parseInt(calculatorInitialValues.creditScore) < 600) ||
      // user entered credit score override less than the limit
      Number.parseInt(e.target.value) < 600
    ) {
      formik.setFieldValue("payPartialInitialDeposit", InitialDepositPayment.FULL);
      addToast({ message: "600+ credit score required to pay a partial upfront initial deposit", type: "info" });
    }

    formik.setFieldValue("creditScore", e.target.value);
  }

  return (
    <MainCard title="Calculate Client's Recommended Max Budget" content={false} sx={{ paddingTop: "1rem" }}>
      <CardContent sx={{ marginTop: "1rem" }}>
        <Typography variant="h5" color={"gray"} style={{ marginTop: "-2rem", marginBottom: "2rem" }}>
          *** Note: The calculator will default to the client and home values provided to the left of the inputs if no input is entered.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid spacing={5} container direction="row">
            <Grid item xs={12} sm={6} container alignItems="flex-start" spacing={2}>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Typography variant="h4" color={theme.palette.primary.dark}>
                    Client Details
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" color={theme.palette.primary.dark}>
                    Overrides
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Monthly Gross Income</Typography>
                    <Typography>{displayUsDollars(calculatorInitialValues?.monthlyGrossIncomeDollars)}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.monthlyGrossIncomeDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "monthlyGrossIncomeDollars", e.target.value)}
                    label="Monthly Gross Income"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Monthly Net Income</Typography>
                    <Typography>
                      {calculatorInitialValues?.monthlyNetIncomeDollars
                        ? displayUsDollars(calculatorInitialValues?.monthlyNetIncomeDollars)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.monthlyNetIncomeDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "monthlyNetIncomeDollars", e.target.value)}
                    label="Monthly Net Income"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Monthly Non-Housing Debt</Typography>
                    <Typography>{displayUsDollars(calculatorInitialValues?.monthlyNonHousingDebtDollars)}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.monthlyNonHousingDebtDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "monthlyNonHousingDebtDollars", e.target.value)}
                    label="Monthly Non-Housing Debt"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Total Liquid Assets</Typography>
                    <Typography>{displayUsDollars(calculatorInitialValues?.totalLiquidAssetsDollars)}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.totalLiquidAssetsDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "totalLiquidAssetsDollars", e.target.value)}
                    label="Total Liquid Assets"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Credit Score</Typography>
                    <Typography>{calculatorInitialValues?.creditScore}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={formik.values.creditScore}
                    onChange={handleCreditScoreChange}
                    label="Credit Score"
                    fullWidth
                    type="number"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Market</Typography>
                    <Typography>See dropdown for selected markets</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    items={calculatorInitialValues.markets.map((market) => {
                      return { label: formatMarket(market), value: market.id };
                    })}
                    label="Market"
                    id="marketId"
                    value={formik.values.marketId}
                    handleChange={(val) => formik.setFieldValue("marketId", val)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Family Size</Typography>
                    <Typography>{calculatorInitialValues?.familySize || "-"}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={formik.values.familySize}
                    onChange={(e) => formik.setFieldValue("familySize", e.target.value)}
                    label="Family Size"
                    fullWidth
                    type="number"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Upfront Initial Deposit</Typography>
                    <Typography>-</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    items={InitialDepositPaymentOptions}
                    label="Upfront Initial Deposit"
                    id="payingInitialDepositInFullId"
                    fullWidth
                    value={
                      (formik.values.creditScore ? Number.parseInt(formik.values.creditScore) >= 600 : true) &&
                      formik.values.payPartialInitialDeposit === InitialDepositPayment.PARTIAL
                        ? InitialDepositPayment.PARTIAL
                        : InitialDepositPayment.FULL
                    }
                    disabled={
                      (!formik.values.creditScore && !calculatorInitialValues.creditScore) ||
                      (formik.values.creditScore
                        ? Number.parseInt(formik.values.creditScore) < 600
                        : Number.parseInt(calculatorInitialValues.creditScore) < 600)
                    }
                    handleChange={(val) => formik.setFieldValue("payPartialInitialDeposit", val)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                <Typography variant="h4" color={theme.palette.primary.dark}>
                  Home Details
                </Typography>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Home Price</Typography>
                    <Typography>
                      {calculatorInitialValues?.homePriceDollars ? displayUsDollars(calculatorInitialValues?.homePriceDollars) : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.homePriceDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "homePriceDollars", e.target.value)}
                    label="Home Price"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Closing Costs</Typography>
                    <Typography>
                      {calculatorInitialValues?.closingCostsDollars ? displayUsDollars(calculatorInitialValues?.closingCostsDollars) : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.closingCostsDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "closingCostsDollars", e.target.value)}
                    label="Closing Costs"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Repurchase Downpayment</Typography>
                    <Typography>
                      {calculatorInitialValues?.repurchaseDownpaymentDollars
                        ? displayUsDollars(calculatorInitialValues?.repurchaseDownpaymentDollars)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.repurchaseDownpaymentDollars}`}
                    onChange={(e) => handleCurrencyInput(formik, "repurchaseDownpaymentDollars", e.target.value)}
                    label="Repurchase Downpayment"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Initial Deposit</Typography>
                    <Typography>
                      {calculatorInitialValues?.initialDepositDollars
                        ? displayUsDollars(calculatorInitialValues?.initialDepositDollars)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`$${formik.values.initialDeposit}`}
                    onChange={(e) => handleCurrencyInput(formik, "initialDeposit", e.target.value)}
                    label="Initial Deposit"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Mortgage Type</Typography>
                    <Typography>{calculatorInitialValues.mortgageType || "-"}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    items={[
                      { label: TypeOfMortgage.FHA, value: TypeOfMortgage.FHA },
                      { label: TypeOfMortgage.VA, value: TypeOfMortgage.VA },
                    ]}
                    label="Mortgage Type"
                    id="mortgageType"
                    value={formik.values.mortgageType}
                    handleChange={(val) => formik.setFieldValue("mortgageType", val)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight="bold">Program Length</Typography>
                    <Typography>{calculatorInitialValues.programLength || "-"}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    items={ProgramLengthOptions}
                    label="Program Length"
                    id="programLength"
                    value={formik.values.programLength}
                    handleChange={(val) => formik.setFieldValue("programLength", val)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* outputs */}
            <Grid item container xs={12} sm={6} spacing={2} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="h4" color={theme.palette.primary.dark}>
                  Results
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ border: `solid ${theme.palette.secondary.light} 1px` }}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <Typography fontWeight="bold">Home budget</Typography>
                          <Typography>
                            {budgetCalculationResults?.budgetDollars ? displayUsDollars(budgetCalculationResults?.budgetDollars) : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight="bold">Initial deposit</Typography>
                          <Typography>
                            {budgetCalculationResults?.initialDownPaymentDollars
                              ? displayUsDollars(budgetCalculationResults?.initialDownPaymentDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <Typography fontWeight="bold">Rent</Typography>
                          <Typography>
                            {budgetCalculationResults?.monthlyRentDollars
                              ? displayUsDollars(budgetCalculationResults?.monthlyRentDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight="bold">Deferred initial deposit monthly fee</Typography>
                          <Typography>
                            {budgetCalculationResults?.monthlyDeferredInitialDownPaymentFeeDollars
                              ? displayUsDollars(budgetCalculationResults?.monthlyDeferredInitialDownPaymentFeeDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <Typography fontWeight="bold">Total program savings</Typography>
                          <Typography>
                            {budgetCalculationResults?.monthlyHocDollars
                              ? displayUsDollars(budgetCalculationResults?.totalProgramSavingsDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight="bold">Suggested monthly program savings</Typography>
                          <Typography>
                            {budgetCalculationResults?.monthlyHocDollars
                              ? displayUsDollars(budgetCalculationResults?.monthlyHocDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <Typography fontWeight="bold">Total monthly payment (with savings)</Typography>
                          <Typography>
                            {budgetCalculationResults?.totalMonthlyPaymentWithSavingsDollars
                              ? displayUsDollars(budgetCalculationResults?.totalMonthlyPaymentWithSavingsDollars)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {/* checks outputs */}
              <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                <Typography variant="h4" color={theme.palette.primary.dark}>
                  Eligibility Criteria
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ border: `solid ${theme.palette.secondary.light} 1px` }}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item container spacing={2}>
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Under max budget</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult check={budgetCalculationResults?.checks?.maxBudgetCheck} />
                        </Grid>
                        {budgetCalculationResults?.checks?.maxBudgetCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails check={budgetCalculationResults?.checks?.maxBudgetCheck} />
                        )}
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Over min budget</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult check={budgetCalculationResults?.checks?.minBudgetCheck} />
                        </Grid>
                        {budgetCalculationResults?.checks?.minBudgetCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails check={budgetCalculationResults?.checks?.minBudgetCheck} />
                        )}
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Meets income requirements</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult check={budgetCalculationResults?.checks?.allIncomeCheck} />
                        </Grid>
                        {(budgetCalculationResults?.checks?.maxBackendRtiCheck.pass === false ||
                          budgetCalculationResults?.checks?.maxFrontendRtiCheck.pass === false) && (
                          <FailedBudgetCalculatorSimpleCheckDetails
                            description="Income ratios do not pass."
                            sx={{
                              paddingBottom:
                                budgetCalculationResults?.checks?.residualMinIncomeCheck.pass &&
                                budgetCalculationResults?.checks?.minIncomeCheck.pass
                                  ? "1rem"
                                  : "0",
                            }}
                          />
                        )}
                        {budgetCalculationResults?.checks?.minIncomeCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails
                            check={budgetCalculationResults?.checks?.minIncomeCheck}
                            requiredText="Required min income"
                          />
                        )}
                        {budgetCalculationResults?.checks?.residualMinIncomeCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails
                            check={budgetCalculationResults?.checks?.residualMinIncomeCheck}
                            requiredText="Required min residual income"
                          />
                        )}
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Min initial deposit</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult check={budgetCalculationResults?.checks?.minInitialDepositCheck} />
                        </Grid>
                        {budgetCalculationResults?.checks?.minInitialDepositCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails check={budgetCalculationResults?.checks?.minInitialDepositCheck} />
                        )}
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Min assets for initial deposit</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult
                            check={budgetCalculationResults?.checks?.totalLiquidAssetsExceedsInitialDepositCheck}
                          />
                        </Grid>
                        {budgetCalculationResults?.checks?.totalLiquidAssetsExceedsInitialDepositCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails
                            check={budgetCalculationResults?.checks?.totalLiquidAssetsExceedsInitialDepositCheck}
                          />
                        )}
                        <Grid item xs={12} container direction="row">
                          <Grid item xs={8}>
                            <Typography fontWeight="bold">Min down payment</Typography>
                          </Grid>
                          <BudgetCalculatorCheckResult check={budgetCalculationResults?.checks?.minDownPaymentCheck} />
                        </Grid>
                        {budgetCalculationResults?.checks?.minDownPaymentCheck.pass === false && (
                          <FailedBudgetCalculatorCheckDetails check={budgetCalculationResults?.checks?.minDownPaymentCheck} />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <LandisButton loading={loading} type="submit" variant="contained" disabled={!formik.isValid}>
                Submit
              </LandisButton>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </MainCard>
  );
};

export default BudgetCalculatorForm;

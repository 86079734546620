import { bridgeV2ApiInstance, routes } from "@/services";
import { CalculateBudgetAndProgramCostRequestBody, CalculateBudgetAndProgramCostResponseBody } from "@l4s/qualification-models";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseBudgetCalculationValues {
  budgetCalculationResults: CalculateBudgetAndProgramCostResponseBody;
  loadBudgetCalculationResults: (input: CalculateBudgetAndProgramCostRequestBody) => Promise<void>;
}

export const useBudgetCalculation = (): UseBudgetCalculationValues => {
  const [budgetCalculationResults, setBudgetCalculationResults] = useState<CalculateBudgetAndProgramCostResponseBody>();

  const loadBudgetCalculationResults = async (input: CalculateBudgetAndProgramCostRequestBody) => {
    try {
      setBudgetCalculationResults(undefined);
      const response = await bridgeV2ApiInstance.post<CalculateBudgetAndProgramCostResponseBody>(
        routes.CALCULATE_BUDGET_AND_PROGRAM_COST,
        input
      );
      setBudgetCalculationResults(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching calculator initial values.");
    }
  };

  return {
    budgetCalculationResults,
    loadBudgetCalculationResults,
  };
};

import {
  HoasList,
  PropertyManagementCompaniesList,
  RentalInsuranceList,
  RentalRegistrationList,
  TaxesList,
  UtilitiesList,
} from "@/components/partial";
import { useTaxes, useHoas, useUtilities, useRentalInsurance, useRentalRegistration, usePropertyManagementCompany } from "@/hooks";
import { Box, Grid } from "@mui/material";

interface Props {
  propertyId: string;
}

function PropertyManagement({ propertyId }: Props) {
  const useTaxesInstance = useTaxes(propertyId);
  const useHoasInstance = useHoas(propertyId);
  const useUtilitiesInstance = useUtilities(propertyId);
  const useRentalInsuranceInstance = useRentalInsurance(propertyId);
  const useRentalRegistrationInstance = useRentalRegistration(propertyId);
  const usePropertyManagementCompanyInstance = usePropertyManagementCompany();
  return (
    <Grid container spacing={1} sx={{ pb: 10 }}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column" gap={1}>
          <HoasList useHoasInstance={useHoasInstance} propertyId={propertyId} />
          <TaxesList useTaxesInstance={useTaxesInstance} propertyId={propertyId} />
          <UtilitiesList useUtilitiesInstance={useUtilitiesInstance} propertyId={propertyId} />
          <RentalInsuranceList useRentalInsuranceInstance={useRentalInsuranceInstance} propertyId={propertyId} />
          <RentalRegistrationList useRentalRegistrationInstance={useRentalRegistrationInstance} propertyId={propertyId} />
          <PropertyManagementCompaniesList usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PropertyManagement;

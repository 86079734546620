import { bridgeV2ApiInstance, routes } from "@/services";
import { useEffect, useState } from "react";
import { ClientJourneyDtoModel } from "@/types";
import useToast from "@/hooks/useToast";

export interface UseClientJourneyHook {
  clientJourney: ClientJourneyDtoModel;
  loading: boolean;
}

export const useClientJourney = (programClientId: string): UseClientJourneyHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast } = useToast();
  const [clientJourney, setClientJourney] = useState<ClientJourneyDtoModel>(null);

  const getClientJourneyByProgramClientId = async () => {
    setLoading(true);
    try {
      const { data } = await bridgeV2ApiInstance.get<ClientJourneyDtoModel>(
        routes.GET_CLIENT_JOURNEY_BY_PROGRAM_CLIENT_ID(programClientId)
      );

      setClientJourney(data);
    } catch (error) {
      console.log(error);
      addToast({ message: error.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientJourneyByProgramClientId();
  }, [programClientId]);

  return {
    clientJourney,
    loading,
  };
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateUtilityInput, UtilityDto, UpdateUtilityInput } from "@l4s/client-property-models";

import { Utility } from "@/types/PropertyManagement";

export interface UseUtilities {
  loading: boolean;
  propertyUtilities: Array<Utility>;
  createUtility: (input: CreateUtilityInput) => Promise<void>;
  updateUtility: (input: UpdateUtilityInput) => Promise<void>;
  reloadPropertyUtilities: () => Promise<void>;
  error: string | undefined;
}

export const useUtilities = (propertyId: string): UseUtilities => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyUtilities, setPropertyUtilities] = useState<Array<Utility>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getUtilitiesByPropertyId = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<Utility>>(routes.GET_UTILITIES_BY_PROPERTY_ID(propertyId), {
        params: { propertyId },
      });
      setPropertyUtilities(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property utilities.");
    }
    setLoading(false);
  };

  const createUtility = async (input: CreateUtilityInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<UtilityDto>(routes.CREATE_UTILITY, {
        ...input,
      });
      setPropertyUtilities((existingUtility) => [...existingUtility, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating utility.");
    }
  };

  const updateUtility = async (input: UpdateUtilityInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<UtilityDto>(routes.UPDATE_UTILITY(input.id), input);
      setPropertyUtilities((existingUtilities) =>
        existingUtilities.map((utility) => (utility.id === response.data.id ? { ...utility, ...response.data } : utility))
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating Utility.");
    }
  };

  useEffect(() => {
    if (propertyId) getUtilitiesByPropertyId();
  }, [propertyId]);

  return {
    loading,
    createUtility,
    updateUtility,
    propertyUtilities,
    reloadPropertyUtilities: getUtilitiesByPropertyId,
    error,
  };
};

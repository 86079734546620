import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";

import { useEnvelopes } from "@/hooks";
import { useState } from "react";
import { Drawer, SkeletonList } from "@/components/base";

import EnvelopesTableHeader from "./EnvelopesTableHeader";
import { AddEnvelopeForm } from "../AddEnvelopeForm";
import { useNavigate } from "react-router-dom";
import { EnvelopeStatusChip } from "../EnvelopeStatusChip";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No envelopes.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

interface Props {
  personId: string;
}

function EnvelopesTable({ personId }: Props) {
  const [isAddDrawerOpen, setAddDrawerOpen] = useState<boolean>();
  const [search, setSearch] = useState<string>();
  const navigate = useNavigate();

  const useEnvelopesInstance = useEnvelopes(personId, search);

  const { envelopes, loading: envelopesLoading } = useEnvelopesInstance;

  const onCreateEnvelope = () => {
    setAddDrawerOpen(false);
  };

  return (
    <>
      <TableLayout title={<EnvelopesTableHeader showAddForm={() => setAddDrawerOpen(true)} setSearch={setSearch} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!envelopes.length && !envelopesLoading && <EmptyBody />}
            {envelopesLoading && <LoadingBody />}
            {envelopes.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.name}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{moment(row.created).format("MM/DD/YY")}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <EnvelopeStatusChip envelope={row} />
                </TableCell>
                <TableCell align="right">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setAddDrawerOpen(false)} open={isAddDrawerOpen}>
        <AddEnvelopeForm onCreateEnvelope={onCreateEnvelope} useEnvelopesInstance={useEnvelopesInstance} />
      </Drawer>
    </>
  );
}

export default EnvelopesTable;

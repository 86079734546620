import { LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseRentalInsurance, useToast } from "@/hooks";
import { RentalInsurance } from "@/types/PropertyManagement";
import { DateFormat, FormHelpers } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Field } from "./useRentalInsuranceForm";
import * as Yup from "yup";

interface Props {
  rentalInsurance?: RentalInsurance;
  useRentalInsuranceInstance: UseRentalInsurance;
  propertyId: string;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().ensure().required("Account number is a required field"),
  name: Yup.string().ensure().required("Name is a required field"),
  startDate: Yup.date().required("Start date is a required field").typeError("Start date is a required field"),
});

function RentalInsuranceForm({ rentalInsurance, useRentalInsuranceInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const formTitle = () => (rentalInsurance?.id ? "Update rental insurance" : "Add rental insurance");
  const formButton = () => (rentalInsurance?.id ? "Update" : "Add");

  const { addToast } = useToast();
  const { createRentalInsurance, updateRentalInsurance } = useRentalInsuranceInstance;

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: rentalInsurance?.name ?? "",
      accountNumber: rentalInsurance?.accountNumber ?? "",
      startDate: rentalInsurance?.startDate ?? null,
      endDate: rentalInsurance?.endDate ?? null,
      linkToPolicy: rentalInsurance?.linkToPolicy ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (rentalInsurance) {
          await updateRentalInsurance({
            ...values,
            id: rentalInsurance.id,
            propertyId,
          });
        } else {
          await createRentalInsurance({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              label="Name"
              id="name"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue(Field.NAME, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <Grid item>
            <TextField
              id="accountNumber"
              label="Account Number"
              value={formik.values.accountNumber}
              onChange={(e) => formik.setFieldValue(Field.ACCOUNT_NUMBER, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.accountNumber}
              helperText={formik.errors.accountNumber}
            />
          </Grid>
          <Grid item>
            <TextField
              id="linkToPolicy"
              label="Link to Policy"
              value={formik.values.linkToPolicy}
              onChange={(e) => formik.setFieldValue(Field.LINK_TO_POLICY, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.linkToPolicy}
              helperText={formik.errors.linkToPolicy}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Start Date"
              value={formik.values.startDate}
              onChange={(val) => formik.setFieldValue(Field.START_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id={Field.START_DATE}
                  fullWidth
                  {...params}
                  name={Field.START_DATE}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.START_DATE)}
                  error={FormHelpers.formikCheckError(formik, Field.START_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="End Date"
              value={formik.values.endDate}
              onChange={(val) => formik.setFieldValue(Field.END_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id={Field.END_DATE}
                  fullWidth
                  {...params}
                  name={Field.END_DATE}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.END_DATE)}
                  error={FormHelpers.formikCheckError(formik, Field.END_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              {formButton()}
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default RentalInsuranceForm;

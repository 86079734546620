import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { UpdateTemplateFieldAutofillInput, UseTemplateFieldOptionsHook, useTemplateFieldOptions } from "@/hooks";
import { useState } from "react";
import { DocusignIcon, Drawer, SkeletonList } from "@/components/base";

import TemplatesTableHeader from "./TemplateFieldsTableHeader";
import { TemplateFieldOption, TemplateFieldTypeDisplayMap, isDocusignTemplateField } from "@/types";
import { AddNewTemplateFieldForm } from "../AddNewTemplateFieldForm";
import { MainCard } from "@/components/berry";
import TemplateFieldsDetail from "./TemplateFieldsDetail";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No templates fields.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

const filterTemplateFields = (search: string) => (templateField: TemplateFieldOption) => {
  if (!search) return true;
  return (
    templateField.name.toLowerCase().includes(search.toLowerCase()) ||
    templateField.description.toLowerCase().includes(search.toLowerCase()) ||
    TemplateFieldTypeDisplayMap[templateField.type].toLowerCase().includes(search.toLowerCase())
  );
};

function TemplateFieldsTable() {
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState<boolean>();
  const [search, setSearch] = useState<string>();
  const [selectedTemplateField, setSelectedTemplateField] = useState<TemplateFieldOption>();

  const useTemplateFieldOptionsInstance = useTemplateFieldOptions();
  const {
    templateFieldOptions: templateFields,
    loading: templateFieldsLoading,
    addTemplateField,
    removeTemplateFieldAutofill,
    updateTemplateFieldAutofill,
  } = useTemplateFieldOptionsInstance;

  const onAddNewTemplateField: UseTemplateFieldOptionsHook["addTemplateField"] = async (input) => {
    try {
      await addTemplateField(input);
      setIsAddDrawerOpen(false);
    } catch (e) {}
  };

  const onRemoveTemplateFieldAutofill = async (templateFieldId: string) => {
    try {
      await removeTemplateFieldAutofill(templateFieldId);
      setSelectedTemplateField((prevSelectedTemplateField) => {
        if (!prevSelectedTemplateField) return prevSelectedTemplateField;
        return { ...prevSelectedTemplateField, autofillEntity: null, autofillEntityField: null };
      });
    } catch (e) {}
  };

  const onAddTemplateFieldAutofill = async (input: UpdateTemplateFieldAutofillInput) => {
    try {
      await updateTemplateFieldAutofill(input);
      setSelectedTemplateField((prevSelectedTemplateField) => {
        if (!prevSelectedTemplateField) return prevSelectedTemplateField;
        return { ...prevSelectedTemplateField, autofillEntity: input.entity, autofillEntityField: input.entityField };
      });
    } catch (e) {}
  };

  return (
    <>
      <TableLayout title={<TemplatesTableHeader showAddForm={() => setIsAddDrawerOpen(true)} setSearch={setSearch} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!templateFields.length && !templateFieldsLoading && <EmptyBody />}
            {templateFieldsLoading && <LoadingBody />}
            {templateFields.filter(filterTemplateFields(search)).map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => setSelectedTemplateField(row)}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.name}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <Chip
                    icon={isDocusignTemplateField(row.type) ? <DocusignIcon style={{ width: "1rem", borderRadius: "50%" }} /> : null}
                    size="small"
                    label={TemplateFieldTypeDisplayMap[row.type]}
                  />
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="right">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setIsAddDrawerOpen(false)}
        open={isAddDrawerOpen}
        sx={{ width: 500, maxWidth: "85vw", mb: "12px" }}
      >
        <MainCard title="Add Template Field">
          <AddNewTemplateFieldForm onAddNewTemplateField={onAddNewTemplateField} />
        </MainCard>
      </Drawer>
      <Drawer
        anchor="right"
        onClose={() => setSelectedTemplateField(undefined)}
        open={!!selectedTemplateField}
        sx={{ width: 500, maxWidth: "85vw", mb: "12px" }}
      >
        <TemplateFieldsDetail
          onRemoveTemplateFieldAutofill={onRemoveTemplateFieldAutofill}
          onAddTemplateFieldAutofill={onAddTemplateFieldAutofill}
          templateField={selectedTemplateField}
        />
      </Drawer>
    </>
  );
}

export default TemplateFieldsTable;

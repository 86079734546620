import { StateCode } from "@/types";
import { startCase, toLower } from "lodash";

export const snakeCaseToDisplay = (w) => startCase(toLower(w.split("_").join(" ")));

export function formatName(first?: string, last?: string) {
  return `${first || ""} ${last || ""}`.trim();
}

/**
 * Returns a human readable phone number.  Only handles 10, 11 digit numbers
 * @param {number} phone
 * @returns {string} 1-800-555-5555
 */
export function formatPhoneNumber(phone?: string) {
  if (!phone) {
    return "";
  }

  if (phone.length === 11) {
    return [phone.slice(0, 1), phone.slice(1, 4), phone.slice(4, 7), phone.slice(7)].join("-");
  } else if (phone.length === 10) {
    return [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6)].join("-");
  }

  // fallback: we handle structured this to handle lengths other than
  // 10 & 11 digits.
  return phone;
}

import { TableLayout } from "@/components/layout";
import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ClientPropertyFinanceRow } from "./types";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  tableRows: Array<ClientPropertyFinanceRow>;
  title: string | React.ReactNode;
}

const ClientPropertyFinanceTable = ({ useApplicationsPropertyFinanceInstance, tableRows, title }: Props) => {
  const { applicationsPropertyFinance } = useApplicationsPropertyFinanceInstance;

  return (
    <TableLayout title={title}>
      <Table>
        <TableBody>
          {tableRows.map(({ label, fieldKey, displayFn }) => (
            <TableRow key={fieldKey}>
              <TableCell sx={{ width: "50%" }}>
                <b>{label}</b>
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                {applicationsPropertyFinance[fieldKey] !== null ? displayFn(applicationsPropertyFinance[fieldKey]) : "-----"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableLayout>
  );
};

export default ClientPropertyFinanceTable;

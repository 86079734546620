import { StateCode } from "..";

export enum TypeOfTax {
  COUNTY = "COUNTY",
  SCHOOL = "SCHOOL",
  MUNICIPAL = "MUNICIPAL",
  CITY = "CITY",
  OTHER = "OTHER",
}

export interface Tax {
  id: string;
  parcelNumber: string;
  type: TypeOfTax;
  addressStreet: string;
  addressCity: string;
  addressState: StateCode;
  addressZip: string;
  phone: string;
  email: string | null;
  website: string;
  created: string;
  createdByAccountId: string;
  updated: string;
  updatedByAccountId: string;
  name: string;
  // join with properties
  propertyId?: string;
}

import { Route, Routes } from "react-router-dom";

import { ClientPropertiesList, ClientPropertyDetail, NotFoundPage } from "@/components/view";
import { Person, ProgramClient } from "@/types";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

const ClientPropertiesRouter = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<ClientPropertiesList {...props} />} />
      <Route path="/:applicationsPropertyId" element={<ClientPropertyDetail {...props} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default ClientPropertiesRouter;

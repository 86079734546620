import { Home } from "@mui/icons-material";
import { Typography, Breadcrumbs, useTheme } from "@mui/material";
import { IconChevronRight } from "@tabler/icons";
import { Link } from "react-router-dom";
import BreadcrumbsContainer from "./BreadcrumbsContainer";

interface BreadcrumbLinks {
  title: string;
  url: string;
}

interface Props {
  isHomeIncluded?: boolean;
  links: Array<BreadcrumbLinks>;
  title?: string | boolean;
}

function ManualBreadcrumbs({ isHomeIncluded = true, title, links }: Props) {
  const theme = useTheme();

  const linkSX = {
    display: "flex",
    textDecoration: "none",
    alignContent: "center",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "16px",
    height: "16px",
    color: theme.palette.secondary.main,
  };

  return (
    <BreadcrumbsContainer title={typeof title === "boolean" ? (title ? links[links.length - 1]?.title : undefined) : title}>
      <Breadcrumbs
        sx={{ "& .MuiBreadcrumbs-separator": { width: 16, ml: 1.25, mr: 1.25 } }}
        aria-label="breadcrumb"
        maxItems={3}
        separator={<IconChevronRight stroke={1.5} size="16px" />}
      >
        {isHomeIncluded && (
          <Link to="/">
            <Typography color="inherit" variant="subtitle1" sx={linkSX}>
              <Home sx={iconStyle} />
            </Typography>
          </Link>
        )}
        {links.map((link, i) => (
          <Link key={i} to={link.url}>
            <Typography
              variant="subtitle1"
              sx={{
                ...linkSX,
                // This assumes the last link is the active page - see README.md
                color: i === links.length - 1 ? "grey.500" : "grey.900",
              }}
            >
              {link.title}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </BreadcrumbsContainer>
  );
}

export default ManualBreadcrumbs;

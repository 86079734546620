import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { ReactElement } from "react";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.js";
import { toolbarPlugin, TransformToolbarSlot } from "@react-pdf-viewer/toolbar";
import { Viewer, Worker, SpecialZoomLevel, Plugin } from "@react-pdf-viewer/core";
import { ToolbarSlot, defaultLayoutPlugin, ToolbarProps } from "@react-pdf-viewer/default-layout";
import { Document } from "@/types";
import { SkeletonList } from "../SkeletonList";

const renderToolbar = (fileName: string) => (Toolbar: (props: ToolbarProps) => ReactElement) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    CurrentPageInput: () => <></>,
    CurrentPageLabel: () => <></>,
    NumberOfPages: () => <span>{fileName}</span>,
    ShowSearchPopover: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    Open: () => <></>,
    Rotate: () => <></>,
    RotateBackwardMenuItem: () => <></>,
    RotateForwardMenuItem: () => <></>,
    GoToFirstPage: () => <></>,
    GoToFirstPageMenuItem: () => <></>,
    GoToLastPage: () => <></>,
    GoToLastPageMenuItem: () => <></>,
    GoToNextPage: () => <></>,
    GoToNextPageMenuItem: () => <></>,
    GoToPreviousPage: () => <></>,
    GoToPreviousPageMenuItem: () => <></>,
    SwitchScrollMode: () => <></>,
    SwitchScrollModeMenuItem: () => <></>,
    SwitchSelectionMode: () => <></>,
    SwitchSelectionModeMenuItem: () => <></>,
  });
  return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
};

interface Props {
  document: Document | undefined;
  plugins?: Array<Plugin>;
  defaultScale?: SpecialZoomLevel;
}

function PDFViewer({ document, plugins = [], defaultScale, ...props }: Props) {
  if (!document) return <SkeletonList count={15} height={30} />;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar: renderToolbar(document.name),
  });

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.js`}>
      <Viewer fileUrl={document.url} plugins={[defaultLayoutPluginInstance, ...plugins]} defaultScale={defaultScale} />
    </Worker>
  );
}

export default PDFViewer;

import { bridgeV2ApiInstance } from "@/services";
import { useState } from "react";

interface UseInspectionReportURLInstance {
  inspectionReportURL: string | undefined;
  isLoading: boolean;
  error: Error | undefined;
  loadInspectionReportUrl: () => void;
}

interface UseInspectionReportURL {
  (inspectionReportId: string): UseInspectionReportURLInstance;
}

export const useInspectionReportURL: UseInspectionReportURL = (inspectionReportId) => {
  const [inspectionReportURL, setInspectionReportURL] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const loadInspectionReportUrl = async () => {
    try {
      setIsLoading(true);

      const response = await bridgeV2ApiInstance.get<string>(`/inspections/report/${inspectionReportId}/pdf-url/`);
      setInspectionReportURL(response.data);
    } catch (err: unknown) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    inspectionReportURL,
    isLoading,
    error,
    loadInspectionReportUrl,
  };
};

import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

import AddExistingTemplateFieldForm from "./AddExistingTemplateFieldForm";
import { AddNewTemplateFieldForm } from "@/components/partial";
import { CreateTemplateFieldInTemplateInput, UseTemplateFieldsHook } from "@/hooks";
import { AddTemplateInFieldInput } from "../types";
import { useFeature } from "@/contexts";
import { FeatureFlag } from "@/types";

interface Props {
  templateFieldsHookInstance: UseTemplateFieldsHook;
  templateFieldLocation: AddTemplateInFieldInput;
}

function AddTemplateFieldForm({ templateFieldLocation, templateFieldsHookInstance }: Props) {
  const [isSelectingExistingField, setIsSelectingExistingField] = useState<boolean>(true);
  const releaseCreateTemplateFields = useFeature(FeatureFlag.RELEASE_CREATE_TEMPLATE_FIELDS);

  const { addExistingTemplateField, addNewTemplateField } = templateFieldsHookInstance;

  const handleChange = (e: React.SyntheticEvent, value: boolean) => {
    if (value !== null) {
      setIsSelectingExistingField(value);
    }
  };

  const onAddNewTemplateField = async (input: CreateTemplateFieldInTemplateInput) => {
    await addNewTemplateField({ ...input, ...templateFieldLocation });
  };

  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid item xs={12}>
          {releaseCreateTemplateFields && (
            <ToggleButtonGroup size="small" fullWidth color="primary" exclusive onChange={handleChange} value={isSelectingExistingField}>
              <ToggleButton value={true}>Existing Field</ToggleButton>
              <ToggleButton value={false}>New Field</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Grid>
        <Grid item xs={12}>
          {isSelectingExistingField && (
            <AddExistingTemplateFieldForm
              templateFieldLocation={templateFieldLocation}
              addExistingTemplateField={addExistingTemplateField}
            />
          )}
          {!isSelectingExistingField && releaseCreateTemplateFields && (
            <AddNewTemplateFieldForm inlineCreation onAddNewTemplateField={onAddNewTemplateField} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddTemplateFieldForm;

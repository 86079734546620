import { useAddRequirementToMilestone, useRequirementDefinitions } from "@/hooks/data-hooks/milestones";
import { JourneyMilestoneDtoModel, JourneyRequirementDtoModel } from "@/types";
import { RequirementDefinitionDtoModel } from "@/types/Milestones/RequirementDefinition";
import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { ReactEventHandler, useMemo } from "react";
import { DEFAULT_PADDING } from "@/config";

interface Props {
  milestone: JourneyMilestoneDtoModel;
  onAdd: (r: JourneyRequirementDtoModel) => void;
  openRequirements: Array<JourneyRequirementDtoModel>;
}

const AddRequirementAction = ({ milestone, onAdd, openRequirements }: Props) => {
  const milestoneId = milestone.id;
  const { requirementDefinitions } = useRequirementDefinitions(milestoneId);
  const { addRequirementToMilestone, saving } = useAddRequirementToMilestone();
  const stopPropogration: ReactEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };
  const onSelect = async (requirementDefinitionKey: RequirementDefinitionDtoModel["key"]) => {
    try {
      const addedRequirement = await addRequirementToMilestone({
        requirementDefinitionKey,
        milestoneId: milestoneId,
      });
      onAdd(addedRequirement);
    } catch {
      // do nothing
    }
  };

  const requirementOptions = useMemo(
    () =>
      (requirementDefinitions || []).filter((rd) => {
        return !openRequirements.some((or) => or.definition.key === rd.key);
      }),
    [requirementDefinitions, openRequirements]
  );

  if (!requirementOptions.length) return null;

  return (
    <div onClick={stopPropogration}>
      <PopupState variant="popover" popupId={`${milestoneId}-popup-menu`}>
        {(popupState) => (
          <>
            <Button {...bindTrigger(popupState)} startIcon={<AddTaskIcon />} variant="text" color="primary" disabled={saving}>
              Add requirement
            </Button>
            <Menu {...bindMenu(popupState)}>
              <Typography variant="h5" pl={DEFAULT_PADDING}>
                Add requirement
              </Typography>
              <Divider sx={{ my: 0.5 }} />
              {requirementOptions.map((rd) => {
                return (
                  <MenuItem
                    key={rd.key}
                    onClick={async () => {
                      popupState.close();
                      await onSelect(rd.key);
                    }}
                  >
                    {rd.managerName}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </PopupState>
    </div>
  );
};
export default AddRequirementAction;

import { UtilitiesStatusOptions } from "@/types";

export const UtilitiesStatuses = [
  { label: "Yes", value: UtilitiesStatusOptions.YES },
  { label: "No", value: UtilitiesStatusOptions.NO },
  { label: "Unknown", value: UtilitiesStatusOptions.UNKNOWN },
];

export enum Field {
  SCHEDULED_MOVE_OUT_DATE = "scheduledMoveOutDate",
  VACANCY_CONFIRMED_DATE = "vacancyConfirmedDate",
  REFERRED_TO_EVICTION_DATE = "referredToEvictionDate",
  UTILITIES_ON = "utilitiesOn",
}

export const mapUtilitiesStatusToDisplay = {
  [UtilitiesStatusOptions.YES]: "Yes",
  [UtilitiesStatusOptions.NO]: "No",
  [UtilitiesStatusOptions.UNKNOWN]: "Unknown",
};

import { CurrencyCentsTextField, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

function PropertyFinancialsForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updatePropertyFinancials } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      purchasePriceCents: applicationsPropertyFinance.purchasePriceCents,
      askingPriceCents: applicationsPropertyFinance.askingPriceCents,
      appraisedPriceCents: applicationsPropertyFinance.appraisedPriceCents,
      allInHudCents: applicationsPropertyFinance.allInHudCents,
      loanAmountCents: applicationsPropertyFinance.loanAmountCents,
      inspectionPriceCents: applicationsPropertyFinance.inspectionPriceCents,
      depositAmountCents: applicationsPropertyFinance.depositAmountCents,
      resalePriceCents: applicationsPropertyFinance.resalePriceCents,
      closingCosts: applicationsPropertyFinance.closingCosts,
      lenderCostCents: applicationsPropertyFinance.lenderCostCents,
      sellersCreditCents: applicationsPropertyFinance.sellersCreditCents,
      ddFeeCents: applicationsPropertyFinance.ddFeeCents,
      appraisalPriceCents: applicationsPropertyFinance.appraisalPriceCents,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updatePropertyFinancials({
          purchasePriceCents: values.purchasePriceCents,
          askingPriceCents: values.askingPriceCents,
          appraisedPriceCents: values.appraisedPriceCents,
          allInHudCents: values.allInHudCents,
          loanAmountCents: values.loanAmountCents,
          inspectionPriceCents: values.inspectionPriceCents,
          depositAmountCents: values.depositAmountCents,
          resalePriceCents: values.resalePriceCents,
          closingCosts: values.closingCosts,
          lenderCostCents: values.lenderCostCents,
          sellersCreditCents: values.sellersCreditCents,
          ddFeeCents: values.ddFeeCents,
          appraisalPriceCents: values.appraisalPriceCents,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      return errors;
    },
  });

  return (
    <MainCard title="Edit Fixed Payments">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Purchase Price"
              value={formik.values.purchasePriceCents}
              handleChange={(val) => formik.setFieldValue("purchasePriceCents", val)}
              error={!!formik.errors.purchasePriceCents}
              helperText={formik.errors.purchasePriceCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Asking Price"
              value={formik.values.askingPriceCents}
              handleChange={(val) => formik.setFieldValue("askingPriceCents", val)}
              error={!!formik.errors.askingPriceCents}
              helperText={formik.errors.askingPriceCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Appraised Price"
              value={formik.values.appraisedPriceCents}
              handleChange={(val) => formik.setFieldValue("appraisedPriceCents", val)}
              error={!!formik.errors.appraisedPriceCents}
              helperText={formik.errors.appraisedPriceCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="All in HUD"
              value={formik.values.allInHudCents}
              handleChange={(val) => formik.setFieldValue("allInHudCents", val)}
              error={!!formik.errors.allInHudCents}
              helperText={formik.errors.allInHudCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Loan Amount"
              value={formik.values.loanAmountCents}
              handleChange={(val) => formik.setFieldValue("loanAmountCents", val)}
              error={!!formik.errors.loanAmountCents}
              helperText={formik.errors.loanAmountCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Inspection Price"
              value={formik.values.inspectionPriceCents}
              handleChange={(val) => formik.setFieldValue("inspectionPriceCents", val)}
              error={!!formik.errors.inspectionPriceCents}
              helperText={formik.errors.inspectionPriceCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Deposit Amount"
              value={formik.values.depositAmountCents}
              handleChange={(val) => formik.setFieldValue("depositAmountCents", val)}
              error={!!formik.errors.depositAmountCents}
              helperText={formik.errors.depositAmountCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Resale Price"
              value={formik.values.resalePriceCents}
              handleChange={(val) => formik.setFieldValue("resalePriceCents", val)}
              error={!!formik.errors.resalePriceCents}
              helperText={formik.errors.resalePriceCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Closing Costs"
              value={formik.values.closingCosts}
              handleChange={(val) => formik.setFieldValue("closingCosts", val)}
              error={!!formik.errors.closingCosts}
              helperText={formik.errors.closingCosts}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Lender Cost"
              value={formik.values.lenderCostCents}
              handleChange={(val) => formik.setFieldValue("lenderCostCents", val)}
              error={!!formik.errors.lenderCostCents}
              helperText={formik.errors.lenderCostCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Seller's Credit"
              value={formik.values.sellersCreditCents}
              handleChange={(val) => formik.setFieldValue("sellersCreditCents", val)}
              error={!!formik.errors.sellersCreditCents}
              helperText={formik.errors.sellersCreditCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="DD Fee"
              value={formik.values.ddFeeCents}
              handleChange={(val) => formik.setFieldValue("ddFeeCents", val)}
              error={!!formik.errors.ddFeeCents}
              helperText={formik.errors.ddFeeCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Appraisal Price"
              value={formik.values.appraisalPriceCents}
              handleChange={(val) => formik.setFieldValue("appraisalPriceCents", val)}
              error={!!formik.errors.appraisalPriceCents}
              helperText={formik.errors.appraisalPriceCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default PropertyFinancialsForm;

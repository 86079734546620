import { TemplateFieldToFill } from "@/types";
import { DocumentsAutofillDataDictionary, DocumentsAutofillResponsePayload } from "@l4s/documents-autofill-models";
import { Grid, Typography } from "@mui/material";
import { IconWand, IconWandOff } from "@tabler/icons";

const AutofillIndicatorMessage = ({ Icon, Message }: { Icon: React.ReactNode; Message: React.ReactNode }) => {
  return (
    <Grid container alignItems="center" gap={1} flexWrap="nowrap" sx={{ paddingLeft: 1 }}>
      <Grid item>{Icon}</Grid>
      <Grid item>{Message}</Grid>
    </Grid>
  );
};

export const renderAutofillIndicatorMessage = (
  autofillValue: DocumentsAutofillResponsePayload | undefined,
  templateFieldToFill: TemplateFieldToFill
) => {
  if (!autofillValue) return null;

  // Autofill value doesn't exist but autofill configuration exists on the field
  if (!autofillValue[templateFieldToFill.autofillEntityField])
    return (
      <AutofillIndicatorMessage
        Icon={<IconWandOff size={20} />}
        Message={
          <Typography variant="caption">
            Value expected <u>but not found</u> on {DocumentsAutofillDataDictionary[templateFieldToFill.autofillEntity]?.name} :{" "}
            <a target="_none" href={autofillValue?.urlToResource}>
              {autofillValue?.displayValue}
            </a>
          </Typography>
        }
      />
    );

  // Autofill value exists and matches (or hasn't been saved yet)
  if (
    autofillValue[templateFieldToFill.autofillEntityField] &&
    (!templateFieldToFill.value || templateFieldToFill.value === autofillValue[templateFieldToFill.autofillEntityField])
  )
    return (
      <AutofillIndicatorMessage
        Icon={<IconWand size={20} />}
        Message={
          <Typography variant="caption">
            Value autofilled from {DocumentsAutofillDataDictionary[templateFieldToFill.autofillEntity]?.name} :{" "}
            <a target="_none" href={autofillValue?.urlToResource}>
              {autofillValue?.displayValue}
            </a>
          </Typography>
        }
      />
    );

  // Autofill value exists but doesn't match
  if (templateFieldToFill.value && templateFieldToFill.value !== autofillValue[templateFieldToFill.autofillEntityField])
    return (
      <AutofillIndicatorMessage
        Icon={<IconWandOff size={20} />}
        Message={
          <Typography variant="caption">
            Autofill value <u>does not match</u> suggested value ( {autofillValue[templateFieldToFill.autofillEntityField]} ) from{" "}
            {DocumentsAutofillDataDictionary[templateFieldToFill.autofillEntity]?.name} :{" "}
            <a target="_none" href={autofillValue?.urlToResource}>
              {autofillValue?.displayValue}
            </a>
          </Typography>
        }
      />
    );
};

import { FilledTemplateFieldInTemplate, TemplateFieldToFill, TemplateFillFieldValue } from "@/types";
import { UseTemplateFillFieldValuesHook } from "./useTemplateFillFieldValues";
import { UseTemplateFieldsHook } from "./useTemplateFields";
import { useMemo } from "react";

export interface UseFilledTemplateFieldsHook {
  templateFieldsToFill: Array<TemplateFieldToFill>;
  filledTemplateFieldsInTemplate: Array<FilledTemplateFieldInTemplate>;
  loading: boolean;
}

export const useFilledTemplateFields = (
  useTemplateFillFieldValuesInstance: UseTemplateFillFieldValuesHook,
  useTemplateFieldsInstance: UseTemplateFieldsHook
): UseFilledTemplateFieldsHook => {
  const { templateFillFieldValues, loading: templateFillFieldValueLoading } = useTemplateFillFieldValuesInstance;
  const { templateFields, loading: templateFieldsLoading } = useTemplateFieldsInstance;

  const filledTemplateFieldsInTemplate: Array<FilledTemplateFieldInTemplate> = useMemo(
    () =>
      templateFields
        .reduce((acc, templateField) => {
          const isTemplateFieldOptionInAcc = acc.some((accTemplateField) => accTemplateField.id === templateField.templateFieldId);

          if (isTemplateFieldOptionInAcc) return acc;

          const templateFillFieldValue: TemplateFillFieldValue | undefined = templateFillFieldValues.find(
            (fillFieldValue) => fillFieldValue.templateFieldId === templateField.templateFieldId
          );

          return [
            ...acc,
            {
              ...templateField,
              value: templateFillFieldValue?.value || null,
              templateFillFieldValueId: templateFillFieldValue?.id,
            },
          ];
        }, [] as Array<FilledTemplateFieldInTemplate>)
        .sort((a, b) => a.yCoord - b.yCoord)
        .sort((a, b) => a.page - b.page),
    [templateFields, templateFillFieldValues]
  );

  const templateFieldsToFill: Array<TemplateFieldToFill> = useMemo(
    () =>
      filledTemplateFieldsInTemplate.reduce((acc, filledTemplateFieldInTemplate) => {
        const isTemplateFieldOptionInAcc = acc.some(
          (accTemplateField) => accTemplateField.id === filledTemplateFieldInTemplate.templateFieldId
        );

        if (isTemplateFieldOptionInAcc)
          return acc.map((accTemplateField) => {
            if (accTemplateField.id === filledTemplateFieldInTemplate.templateFieldId) {
              return {
                ...accTemplateField,
                // If one field is required, and then other isn't, we need to collect a value for the required field
                isRequired: accTemplateField.isRequired || filledTemplateFieldInTemplate.isRequired,
              };
            }

            return accTemplateField;
          });

        return [
          ...acc,
          {
            id: filledTemplateFieldInTemplate.templateFieldId,
            name: filledTemplateFieldInTemplate.templateFieldName,
            description: filledTemplateFieldInTemplate.templateFieldDescription,
            type: filledTemplateFieldInTemplate.templateFieldType,
            audience: filledTemplateFieldInTemplate.templateFieldAudience,
            value: filledTemplateFieldInTemplate.value,
            isRequired: filledTemplateFieldInTemplate.isRequired,
            templateFillFieldValueId: filledTemplateFieldInTemplate.templateFillFieldValueId,
            autofillEntity: filledTemplateFieldInTemplate.templateFieldAutofillEntity,
            autofillEntityField: filledTemplateFieldInTemplate.templateFieldAutofillEntityField,
          },
        ];
      }, [] as Array<TemplateFieldToFill>),
    [filledTemplateFieldsInTemplate]
  );

  return {
    templateFieldsToFill,
    filledTemplateFieldsInTemplate,
    loading: templateFillFieldValueLoading || templateFieldsLoading,
  };
};

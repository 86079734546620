import { HardshipReason } from "@/hooks";

export const mapHardshipPrimaryReason = (reason: HardshipReason) => {
  switch (reason) {
    case HardshipReason.ACT_OF_GOD:
      return "Act of God";
    case HardshipReason.BUDGETING:
      return "Budgeting";
    case HardshipReason.FAMILY:
      return "Family";
    case HardshipReason.FRAUD:
      return "Fraud";
    case HardshipReason.GRADUATION:
      return "Graduation";
    case HardshipReason.INCOME_CONSISTENCY:
      return "Income Consistency";
    case HardshipReason.INCOME_LOSS:
      return "Income Loss";
    case HardshipReason.MEDICAL:
      return "Medical";
    case HardshipReason.MOVING_COSTS:
      return "Moving Costs";
    case HardshipReason.PROCESSING_ISSUES:
      return "Processing Issues";
    case HardshipReason.PROPERTY_ISSUES:
      return "Property Issues";
    case HardshipReason.THEFT:
      return "Theft";
    case HardshipReason.UNEXPECTED_COST:
      return "Unexpected Cost";
    case HardshipReason.UNRESPONSIVE:
      return "Unresponsive";
    case HardshipReason.VACANCY:
      return "Vacancy";
    default:
      break;
  }
};

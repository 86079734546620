import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Entity,
  EvictionCompany,
  EvictionFilingStatus,
  EvictionHearingStatus,
  EvictionNoticeSender,
  Jv,
  TypeOfEviction,
  useEviction,
  WorkflowStage,
} from "@/hooks/data-hooks/remediation/useEviction";
import { useFormik } from "formik";
import { GRID_SPACING } from "@/config";
import { mapUnknownToError } from "@/utils";
import { SubCard } from "@/components/berry";
import { Dropdown, LandisButton } from "@/components/base";
import { useDelinquencyData, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";

interface Props {
  programClientId: string;
  personId: string;
}

export const EvictionForm = (props: Props) => {
  const { programClientId } = props;
  const [loading, setLoading] = useState(false);
  const [address, setAddressField] = useState("");

  const { createEviction, eviction, updateEviction, loadEviction } = useEviction(programClientId);
  const { delinquencyData, loadDelinquencyData } = useDelinquencyData(props.personId);

  const { addToast } = useToast();

  const buttonText = eviction ? "Update Eviction" : "Create Eviction";

  useEffect(() => {
    setLoading(true);
    loadEviction();
    loadDelinquencyData();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (delinquencyData) setAddressField(delinquencyData.address);
  }, [delinquencyData]);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      jv: "" as Jv,
      entity: "" as Entity,
      company: "" as EvictionCompany,
      county: "",
      evictionType: "" as TypeOfEviction,
      dateToProvider: null,
      noticeSender: "" as EvictionNoticeSender,
      dateNoticeSent: null,
      bankruptcy: null,
      filingStatus: "" as EvictionFilingStatus,
      filingDate: null,
      vacancyDate: null,
      notes: "",
      hearingStatus: "" as EvictionHearingStatus,
      hearingDate: null,
      workflowStage: "" as WorkflowStage,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (eviction) {
          await updateEviction({ ...values, programClientId, id: eviction.id });
        } else {
          await createEviction({ ...values, programClientId });
        }
      } catch (err) {
        const error = mapUnknownToError(err);
        addToast({ message: error.message, type: "error" });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.jv) {
        errors.jv = "JV is a required field";
      }
      if (!values.entity) {
        errors.entity = "Entity is a required field";
      }
      if (!values.county) {
        errors.county = "County is a required field";
      }
      if (!values.evictionType) {
        errors.evictionType = "Eviction Type is a required field";
      }
      // fix any type discrepancies before submission
      if (!values.notes) {
        values.notes = null;
      }
      if (!values.noticeSender) {
        values.noticeSender = null;
      }
      if (!values.filingStatus) {
        values.filingStatus = null;
      }
      if (!values.hearingStatus) {
        values.hearingStatus = null;
      }
      if (!values.workflowStage) {
        values.workflowStage = null;
      }
      if (!values.company) {
        values.company = null;
      }

      return errors;
    },
  });

  useEffect(() => {
    for (let key in eviction) {
      const keyString = key.toString();
      formik.setFieldValue(keyString, eviction[key]);
    }
  }, [eviction]);

  return (
    <SubCard title="Track Eviction" content={true}>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12}>
                  <TextField disabled fullWidth id="outlined-disabled" label="Home" value={address} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "JV1", value: Jv.JV1 },
                      { label: "JV2", value: Jv.JV2 },
                    ]}
                    label="JV"
                    id="jv"
                    required
                    value={formik.values.jv}
                    handleChange={(val) => formik.setFieldValue("jv", val)}
                    error={Boolean(formik.errors.jv)}
                    helperText={formik.errors?.jv?.toString() ?? ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Landis Properties I", value: Entity.LANDIS_I },
                      { label: "Landis Properties II", value: Entity.LANDIS_II },
                    ]}
                    label="Entity"
                    id="entity"
                    required
                    value={formik.values.entity}
                    handleChange={(val) => formik.setFieldValue("entity", val)}
                    error={Boolean(formik.errors.entity)}
                    helperText={formik.errors?.entity?.toString() ?? ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Nationwide Compliant", value: EvictionCompany.NATIONWIDE_COMPLIANT },
                      { label: "End2End", value: EvictionCompany.END2END },
                    ]}
                    label="Eviction Company"
                    id="company"
                    value={formik.values.company}
                    handleChange={(val) => formik.setFieldValue("company", val)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    value={formik.values.county}
                    onChange={(e) => formik.setFieldValue("county", e?.target?.value)}
                    size="medium"
                    fullWidth
                    id="county"
                    label="County"
                    helperText={formik.errors?.county?.toString() ?? ""}
                    error={Boolean(formik.errors.county)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Non-payment", value: TypeOfEviction.NON_PAYMENT },
                      { label: "Lease Violation", value: TypeOfEviction.LEASE_VIOLATION },
                    ]}
                    label="Eviction Type"
                    id="evictionType"
                    required
                    value={formik.values.evictionType}
                    handleChange={(val) => formik.setFieldValue("evictionType", val)}
                    error={Boolean(formik.errors.evictionType)}
                    helperText={formik.errors?.evictionType?.toString() ?? ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Date To Provider"
                    value={formik.values.dateToProvider}
                    onChange={(val) => formik.setFieldValue("dateToProvider", val)}
                    renderInput={(params) => <TextField fullWidth {...params} name="dateToProvider" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Landis", value: EvictionNoticeSender.LANDIS },
                      { label: "Attorney", value: EvictionNoticeSender.ATTORNEY },
                      { label: "N/A", value: EvictionNoticeSender.NOT_APPLICABLE },
                    ]}
                    label="Notice Sender"
                    value={formik.values.noticeSender}
                    handleChange={(val) => formik.setFieldValue("noticeSender", val ?? null)}
                    id="noticeSender"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Date Notice Sent"
                    value={formik.values.dateNoticeSent}
                    onChange={(val) => formik.setFieldValue("dateNoticeSent", val)}
                    renderInput={(params) => <TextField fullWidth {...params} name="dateNoticeSent" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    label="Bankruptcy"
                    value={formik.values.bankruptcy}
                    handleChange={(val) => formik.setFieldValue("bankruptcy", val)}
                    id="bankruptcy"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Yes", value: EvictionFilingStatus.YES },
                      { label: "No", value: EvictionFilingStatus.NO },
                      { label: "Pending", value: EvictionFilingStatus.PENDING },
                    ]}
                    label="Filing Status"
                    value={formik.values.filingStatus}
                    handleChange={(val) => formik.setFieldValue("filingStatus", val)}
                    id="filingStatus"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Filing Date"
                    value={formik.values.filingDate}
                    onChange={(val) => formik.setFieldValue("filingDate", val)}
                    renderInput={(params) => <TextField fullWidth {...params} name="filingDate" id="filingDate" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Completed", value: EvictionHearingStatus.COMPLETED },
                      { label: "Scheduled", value: EvictionHearingStatus.SCHEDULED },
                      { label: "Pending", value: EvictionHearingStatus.PENDING },
                      { label: "Not Applicable", value: EvictionHearingStatus.NOT_APPLICABLE },
                    ]}
                    label="Hearing Status"
                    value={formik.values.hearingStatus}
                    handleChange={(val) => formik.setFieldValue("hearingStatus", val)}
                    id="hearingStatus"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Hearing Date"
                    value={formik.values.hearingDate}
                    onChange={(val) => formik.setFieldValue("hearingDate", val)}
                    renderInput={(params) => <TextField fullWidth {...params} name="hearingDate" id="hearingDate" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formik.values.notes}
                    onChange={(e) => formik.setFieldValue("notes", e?.target?.value ?? null)}
                    size="medium"
                    fullWidth
                    multiline
                    rows={6}
                    label="Notes"
                    id="notes"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    items={[
                      { label: "Eviction Submission and Counsel Review", value: WorkflowStage.EVICTION_SUBMISSION },
                      { label: "Notice Preparation and Delivery", value: WorkflowStage.NOTICE_PREP },
                      { label: "Notice Filing", value: WorkflowStage.NOTICE_FILING },
                      { label: "Complaint Service", value: WorkflowStage.COMPLAINT_SERVICE },
                      { label: "Initial Hearing Scheduled", value: WorkflowStage.HEARING_SCHEDULED },
                      { label: "Adjournment", value: WorkflowStage.ADJOURNMENT },
                      { label: "Rescheduled", value: WorkflowStage.RESCHEDULED },
                      { label: "Writ", value: WorkflowStage.WRIT },
                      { label: "Possession of House (Eviction Closed)", value: WorkflowStage.EVICTION_CLOSED },
                    ]}
                    label="Workflow Stage"
                    value={formik.values.workflowStage}
                    handleChange={(val) => formik.setFieldValue("workflowStage", val)}
                    id="workflowStage"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LandisButton loading={loading} type="submit" size="small" variant="contained" startIcon={<Check />}>
                    {buttonText}
                  </LandisButton>
                </Grid>
              </Grid>
            </form>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </SubCard>
  );
};

//@ts-nocheck
import { useState, useRef, useEffect } from "react";

import { useTheme, styled } from "@mui/material/styles";
import { Box, InputAdornment, OutlinedInput, TableRow, TableCell, Grid, Typography } from "@mui/material";
import { IconSearch } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { useSearchForPersons } from "@/hooks/data-hooks/person/useSearchForPersons";
import { Link } from "react-router-dom";
import { formatName, formatPhoneNumber, formatAddress } from "@/utils";
import { TableFrame } from "@/components/base";

// styles

const ParentContainer = styled(Box, { shouldForwardProp })(({ theme }) => ({
  "&::before": {
    display: "none",
    content: '""',
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: -1,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#dedede",
    opacity: 0.8,
    backdropFilter: "invert(100px)",
  },
  "&:focus-within::before": {
    display: "block",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 480,
  paddingLeft: 16,
  paddingRight: 16,
  "& input": {
    background: "transparent !important",
    paddingLeft: "4px !important",
  },
}));

// ==============================|| SEARCH INPUT ||============================== //

const PersonSearchSection = () => {
  const theme = useTheme();
  const { persons, searchForPersons } = useSearchForPersons();
  const [value, setValue] = useState("");
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const [shouldShowResults, setShouldShowResults] = useState(false);

  function blur(): void {
    if (!searchBoxRef.current) {
      return;
    }

    const input = searchBoxRef.current.querySelector("input");

    if (input) {
      input.blur();
    }
  }

  useEffect(() => {
    function onKeyDown({ key, metaKey: commandKeyPressed }: KeyboardEvent): void {
      // Cmd + K focuses the input for quick search
      if (commandKeyPressed && key === "k") {
        if (searchBoxRef.current) {
          const input = searchBoxRef.current.querySelector("input");
          input.focus();
        }
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!searchBoxRef.current) {
      return;
    }

    const input = searchBoxRef.current.querySelector("input");

    input.onfocus = () => {
      input.select();
      setShouldShowResults(true);
    };

    input.onblur = () => {
      const searchHasFocus = searchBoxRef.current.matches(":focus-within");

      // shouldn't hide results because user tabs to search result
      if (searchHasFocus) {
        return;
      }

      // user clicks away/tabs to some other element
      setShouldShowResults(false);
    };
  }, [searchBoxRef]);

  function onInput(e: HTMLInputEvent): void {
    setValue(e.target.value);

    searchForPersons(e.target.value);
  }

  return (
    <ParentContainer sx={{ marginLeft: 6 }} style={{ position: "relative" }} ref={searchBoxRef}>
      {/* search box */}
      <Box>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={onInput}
          onKeyDown={({ key }: KeyboardEvent): void => {
            if (key === "Escape") {
              blur();
            }
          }}
          placeholder="Search for a client"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>

      {/* search results */}
      {shouldShowResults && persons.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: "0",
            marginTop: "1px",
            minWidth: "max-content",
            bgcolor: "white",
          }}
        >
          <TableFrame
            TableHeaderCellComponents={
              <>
                <TableCell sx={{ pl: 3, py: 1 }}>Name</TableCell>
                <TableCell sx={{ py: 1 }}>Email</TableCell>
                <TableCell sx={{ py: 1 }}>Phone</TableCell>
                <TableCell sx={{ py: 1 }}>Property</TableCell>
              </>
            }
            TableBodyComponents={
              <>
                {persons.map((row) => (
                  <TableRow hover key={row.id} className="search_row">
                    <TableCell sx={{ pl: 3 }}>
                      <Link
                        to={`/client/${row.id}`}
                        onKeyUp={({ key }: KeyboardEvent): void => {
                          if (key === "Enter" || key === "Escape") {
                            setShouldShowResults(false);
                          }
                        }}
                        onClick={(): void => {
                          setShouldShowResults(false);
                        }}
                      >
                        <Grid container alignItems="center" sx={{ flexWrap: "nowrap" }}>
                          <Grid item xs zeroMinWidth>
                            <Typography component="div" align="left" variant="subtitle1">
                              {formatName(row.nameFirst, row.nameLast)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{formatPhoneNumber(row.phone)}</TableCell>
                    <TableCell>
                      {row.property
                        ? formatAddress(
                            row.property.addressStreet,
                            row.property.addressUnit,
                            row.property.addressCity,
                            row.property.addressZip,
                            row.property.addressState
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            }
          />
        </Box>
      )}
    </ParentContainer>
  );
};

export default PersonSearchSection;

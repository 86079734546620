import { DrawerProps, Drawer as MuiDrawer, Box, useTheme } from "@mui/material";

import { DEFAULT_PADDING } from "@/constant";
import { useConfig } from "@/hooks";

interface Props extends DrawerProps {}

function Drawer({ children, sx, ...props }: Props) {
  const theme = useTheme();
  const { navType } = useConfig();
  return (
    <MuiDrawer {...props}>
      <Box
        sx={{
          padding: DEFAULT_PADDING,
          //default background when light mode does not match background in layout
          background: navType === "light" ? theme.palette.primary.light : theme.palette.background.default,
          height: "100%",
          ...sx,
        }}
      >
        {children}
      </Box>
    </MuiDrawer>
  );
}

export default Drawer;

import { EnvType } from "@/types";

const prdEnv: EnvType = {
  GOOGLE_MAPS_API_KEY: "AIzaSyBZL12mwhjV04xPUAHIqlKEOcXiVNXX9CY",
  BRIDGE_V2_API_BASE_URL: "https://bridgev2-api.landis.network",
  BRIDGE_V1_BASE_URL: "https://bridge.landis.com",
  ENV: "prd",
  DEBUG_MODE: false,
  FF_ENABLED: true,
};

export default prdEnv;

import { bridgeV2ApiInstance, routes } from "@/services";
import { ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseRelatedProgramClientByApplicationId {
  relatedProgramClientByApplicationId: ProgramClient;
  getRelatedProgramClientByApplicationId: () => Promise<void>;
  loadingRelatedProgramClientByApplicationId: boolean;
}

export async function getProgramClientsByApplicationId(applicationId: string): Promise<Array<ProgramClient>> {
  const { data } = await bridgeV2ApiInstance.get<Array<ProgramClient>>(routes.GET_PROGRAM_CLIENTS_BY_APPLICATION_ID(applicationId));

  return data;
}

export const useRelatedProgramClientByProgramClientApplicationId = (
  programClient: ProgramClient
): UseRelatedProgramClientByApplicationId => {
  const [relatedProgramClientByApplicationId, setProgramClientsByApplicationId] = useState<ProgramClient | undefined>();
  const [loadingRelatedProgramClientByApplicationId, setLoadingProgramClientsByApplicationId] = useState<boolean>(false);

  const getRelatedProgramClientByApplicationId = async () => {
    try {
      setLoadingProgramClientsByApplicationId(true);

      if (!programClient.applicationId) {
        return;
      }

      const data = await getProgramClientsByApplicationId(programClient.applicationId);

      const relatedProgramClient: ProgramClient | undefined = data.find(
        (pc) => pc.applicationId && pc.applicationId === programClient.applicationId && pc.personId !== programClient.personId
      );

      setProgramClientsByApplicationId(relatedProgramClient);
    } catch (e) {
      console.error(e);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching related program clients.");
    } finally {
      setLoadingProgramClientsByApplicationId(false);
    }
  };

  return {
    relatedProgramClientByApplicationId,
    loadingRelatedProgramClientByApplicationId,
    getRelatedProgramClientByApplicationId,
  };
};

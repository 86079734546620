import { Card, Grid, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { ReactNode } from "react";

interface Props {
  title?: string;
  children: ReactNode;
}

function BreadcrumbsContainer({ title, children }: Props) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        marginBottom: 0,
        border: "1px solid",
        borderColor: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.primary[200] + 75,
      }}
    >
      <Box sx={{ p: 2 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item>
            {title && (
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default BreadcrumbsContainer;

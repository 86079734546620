import { bridgeV2ApiInstance, routes } from "@/services";
import { AdverseActionDefinitionDto, AdverseActionReasonType } from "@/types";
import { useEffect, useState } from "react";

export interface UseAdverseActionDefinitionsHook {
  loading: boolean;
  adverseActionDefinitions: Array<AdverseActionDefinitionDto>;
  getAdverseActionDefinitionsByReasonType: () => Promise<Array<AdverseActionDefinitionDto>>;
}

export const useAdverseActionDefinitions = (reasonType: AdverseActionReasonType): UseAdverseActionDefinitionsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [adverseActionDefinitions, setAdverseActionDefinitions] = useState<Array<AdverseActionDefinitionDto>>([]);

  const getAdverseActionDefinitionsByReasonType = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<AdverseActionDefinitionDto>>(
        routes.GET_ACTIVE_ADVERSE_ACTION_DEFINTIONS_BY_REASON_TYPE(reasonType)
      );
      setAdverseActionDefinitions(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdverseActionDefinitionsByReasonType();
  }, [reasonType]);

  return {
    loading,
    adverseActionDefinitions,
    getAdverseActionDefinitionsByReasonType,
  };
};

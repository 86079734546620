import { Link } from "react-router-dom";
import { DASHBOARD_PATH } from "@/config";
import { useConfig } from "@/hooks";

import { LandisLogo, LandisWhiteLogo } from "@/assets";

interface Props {
  href?: string;
  width?: number;
}

const LogoSection = ({ href = DASHBOARD_PATH, width = 100 }: Props) => {
  const { navType } = useConfig();
  return <Link to={href}>{navType === "light" ? <LandisLogo width={width} /> : <LandisWhiteLogo width={width} />}</Link>;
};

export default LogoSection;

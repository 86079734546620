import Typography from "@mui/material/Typography";
import ClientRequirement from "./ClientRequirement";
import { ClientJourneyDtoModel, JourneyMilestoneDtoModel } from "@/types";
import { useMilestone, useRequirements } from "@/hooks/data-hooks/milestones";
import AddRequirementAction from "./AddRequirementAction";
import { Box, Skeleton, createTheme } from "@mui/material";
import AddAdverseAction from "../AdverseActions/AddAdverseAction";
import { MilestoneDefinitionKey } from "@l4s/milestone-models";
import ApplicationAdverseActionList from "../AdverseActions/ApplicationAdverseActionList";
import { useApplicationAdverseActions } from "@/hooks/data-hooks/adverse-actions/useApplicationAdverseActions";
import { isLastOpenRequirementOnLevel } from "./utils";
import { useMemo } from "react";

interface Props {
  milestone: JourneyMilestoneDtoModel;
  clientJourney: ClientJourneyDtoModel;
  milestoneCompleted: boolean;
  onMilestoneUpdate: () => Promise<void>;
}

const CurrentPathMilestoneTab = ({ milestone, clientJourney, milestoneCompleted, onMilestoneUpdate }: Props) => {
  const { requirements, updateRequirement, refetchRequirements, loading } = useRequirements(milestone.id);
  const { getMilestone } = useMilestone(milestone.id);

  const theme = createTheme();

  const { applicationAdverseActions, getApplicationAdverseActions } = useApplicationAdverseActions(clientJourney.applicationId);

  const checkIfMilestoneWasUpdated = async (fetchedMilestone: JourneyMilestoneDtoModel) => {
    if (fetchedMilestone.closedReason !== milestone.closedReason) {
      await onMilestoneUpdate();
    }
  };

  const onRequirementUpdate = async () => {
    await refetchRequirements();
    const fetchedMilestone = await getMilestone();
    await checkIfMilestoneWasUpdated(fetchedMilestone);
  };

  const openRequirements = useMemo(() => requirements.filter((r) => !r.closedAt), [requirements]);

  // Just display skeleton the first time the section is loading
  if (loading && !requirements.length) return <Skeleton animation="wave" height={100} />;

  if (!requirements.length) {
    return (
      <Box>
        <div>
          {!milestone.closedAt ? (
            <Box>
              <AddRequirementAction openRequirements={openRequirements} milestone={milestone} onAdd={refetchRequirements} />
            </Box>
          ) : null}
          {[MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
            <ApplicationAdverseActionList applicationAdverseActions={applicationAdverseActions} />
          ) : null}
        </div>
        <div>
          <Typography sx={{ flexShrink: 0, paddingLeft: theme.spacing(1) }}>No requirements</Typography>
        </div>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginLeft: theme.spacing(1.5),
          paddingBottom: theme.spacing(0.5),
        }}
      >
        {/* If this grows, extract to own component */}
        {!milestone.closedAt ? (
          [MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
            <Box>
              <AddAdverseAction clientJourney={clientJourney} milestone={milestone} onAdd={() => getApplicationAdverseActions()} />
            </Box>
          ) : (
            <Box>
              <AddRequirementAction openRequirements={openRequirements} milestone={milestone} onAdd={refetchRequirements} />
            </Box>
          )
        ) : null}
      </div>
      <div>
        {/* If this grows, extract to own component */}
        {[MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(milestone.definition.key) ? (
          <ApplicationAdverseActionList applicationAdverseActions={applicationAdverseActions} />
        ) : null}
        {requirements?.map((r) => {
          return (
            <ClientRequirement
              requirement={r}
              key={r.id}
              updateRequirement={updateRequirement}
              onRequirementUpdate={onRequirementUpdate}
              milestoneCompleted={milestoneCompleted}
              isLastOpenRequirement={isLastOpenRequirementOnLevel(r, requirements)}
              clientJourney={clientJourney}
            />
          );
        })}
      </div>
    </>
  );
};

export default CurrentPathMilestoneTab;

import { LandisButton, SearchableDropdown } from "@/components/base";
import { UseTemplateFieldsHook, useTemplateFieldOptions, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { AddTemplateInFieldInput } from "../types";

interface Props {
  addExistingTemplateField: UseTemplateFieldsHook["addExistingTemplateField"];
  templateFieldLocation: AddTemplateInFieldInput;
}

function AddExistingTemplateFieldForm({ templateFieldLocation, addExistingTemplateField }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { templateFieldOptions } = useTemplateFieldOptions();
  const { addToast } = useToast();

  const items = useMemo(() => templateFieldOptions.map((option) => ({ label: option.name, value: option.id })), [templateFieldOptions]);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      templateFieldId: null,
      isRequired: true,
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        await addExistingTemplateField({
          ...templateFieldLocation,
          templateFieldId: values.templateFieldId,
          isRequired: values.isRequired,
        });
        resetForm();
      } catch (e) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.templateFieldId) {
        errors.templateFieldId = "You are required to select a template field.";
      }

      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <SearchableDropdown
            label="Template Fields"
            id="add-existing-template-field-form"
            items={items}
            handleChange={(newValue) => {
              formik.setFieldValue("templateFieldId", newValue);
            }}
            value={formik.values.templateFieldId}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={formik.values.isRequired} onChange={(e) => formik.setFieldValue("isRequired", e.target.checked)} />}
            label={formik.values.isRequired ? "Required" : "Optional"}
          />
        </Grid>
        <Grid item>
          <LandisButton loading={loading} type="submit" endIcon={<Check />} size="small" variant="contained">
            Submit
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddExistingTemplateFieldForm;

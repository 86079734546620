import { CurrencyCentsTextField, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { AddressFormNoValidation } from "@/components/partial/AddressFormNoValidation";
import { UseHoas, useToast } from "@/hooks";
import { Hoa } from "@/types/PropertyManagement";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

interface Props {
  close: () => void;
  hoa?: Hoa;
  useHoasInstance: UseHoas;
  propertyId: string;
}

function HoaForm({ hoa, useHoasInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createHoa, updateHoa } = useHoasInstance;

  const formTitle = () => (hoa?.id ? "Update HOA" : "Add HOA");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure().required("Name is a required field"),
    website: Yup.string().url().ensure().optional(),
    email: Yup.string().email().ensure().optional(),
    phone: Yup.string().ensure().optional(),
    initialDuesCents: Yup.number().required("Initial dues is a required field"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: hoa?.name ?? "",
      addressStreet: hoa?.addressStreet ?? "",
      addressUnit: "", // doesn't exist.
      addressCity: hoa?.addressCity ?? "",
      addressState: hoa?.addressState ?? undefined,
      addressZip: hoa?.addressZip ?? "",
      phone: hoa?.phone ?? "",
      email: hoa?.email ?? "",
      website: hoa?.website ?? "",
      initialDuesCents: hoa?.initialDuesCents ?? undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (hoa) {
          await updateHoa({
            ...values,
            id: hoa.id,
            updated: String(Date.now()),
          });
        } else {
          await createHoa({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              label="Name"
              id="name"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <AddressFormNoValidation formik={formik}></AddressFormNoValidation>
          <Grid item>
            <TextField
              id="phone"
              label="Phone"
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 10 }}
              type="tel"
              error={!!formik.errors.phone}
              helperText={formik.errors.phone}
            />
          </Grid>
          <Grid item>
            <TextField
              id="email"
              label="Email"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              type="email"
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item>
            <TextField
              id="website"
              label="Website"
              value={formik.values.website}
              onChange={(e) => formik.setFieldValue("website", e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.website}
              helperText={formik.errors.website}
            />
          </Grid>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Initial Dues"
              value={formik.values.initialDuesCents}
              handleChange={(val) => formik.setFieldValue("initialDuesCents", val)}
              error={!!formik.errors.initialDuesCents}
              helperText={formik.errors.initialDuesCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default HoaForm;

import { MainCard } from "@/components/berry";
import { Chip, Grid, Typography } from "@mui/material";
import TemplateFillFieldsList from "./TemplateFillFieldsList";
import { UseFilledTemplateFieldsHook, UsePersonTemplateAutofillValuesHook, UseTemplateFillFieldValuesHook } from "@/hooks";
import { useMemo } from "react";
import { isTemplateFieldFilled } from "../util";
import { SkeletonList } from "@/components/base";
import { TemplateFill } from "@/types";

interface Props {
  usePersonTemplateAutofillValuesInstance: UsePersonTemplateAutofillValuesHook;
  useFilledTemplateFieldsInstance: UseFilledTemplateFieldsHook;
  useTemplateFillFieldValuesInstance: UseTemplateFillFieldValuesHook;
  templateFill: TemplateFill;
  isReadOnly: boolean;
}

function TemplateFillFieldsListSection({
  usePersonTemplateAutofillValuesInstance,
  useFilledTemplateFieldsInstance,
  useTemplateFillFieldValuesInstance,
  templateFill,
  isReadOnly,
}: Props) {
  const { templateFieldsToFill, loading } = useFilledTemplateFieldsInstance;
  const { autofillValues } = usePersonTemplateAutofillValuesInstance;

  const areAllFieldsFilled = useMemo(
    () => templateFieldsToFill.every((tf) => isTemplateFieldFilled(tf) || !tf.isRequired),
    [templateFieldsToFill]
  );

  return (
    <Grid item>
      <MainCard
        title={
          <Grid container justifyContent="space-between">
            <Typography variant="h2">Fields</Typography>
            {!isReadOnly && (
              <Chip color={areAllFieldsFilled ? "success" : "error"} label={areAllFieldsFilled ? "Complete" : "Incomplete"} />
            )}
          </Grid>
        }
      >
        {!loading && (
          <TemplateFillFieldsList
            autofillValues={autofillValues}
            isReadOnly={isReadOnly}
            templateFillFieldValuesInstance={useTemplateFillFieldValuesInstance}
            templateFieldsToFill={templateFieldsToFill}
            templateFillId={templateFill.id}
          />
        )}
        {loading && <SkeletonList count={3} />}
      </MainCard>
    </Grid>
  );
}

export default TemplateFillFieldsListSection;

import { TypeOfParty, MethodOfPayment, FrequencyOfPayments } from "@l4s/client-property-models";

export const ResponsiblePartyOptions = [
  { label: "Client", value: TypeOfParty.CLIENT },
  { label: "Landis", value: TypeOfParty.LANDIS },
  { label: "TBD", value: TypeOfParty.PROSPECT },
];

export const PaymentMethodOptions = [
  { label: "AvidXchange", value: MethodOfPayment.AVIDXCHANGE },
  { label: "Client", value: MethodOfPayment.CLIENT },
  { label: "Manual", value: MethodOfPayment.MANUAL },
];

export const PaymentFrequencyOptions = [
  { label: "Annually", value: FrequencyOfPayments.ANNUALLY },
  { label: "Monthly", value: FrequencyOfPayments.MONTHLY },
  { label: "Quarterly", value: FrequencyOfPayments.QUARTERLY },
  { label: "Semi-annually", value: FrequencyOfPayments.SEMI_ANNUALLY },
];

export const TypeOfPartyDisplayMap: Record<TypeOfParty, string> = {
  [TypeOfParty.LANDIS]: "Landis",
  [TypeOfParty.CLIENT]: "Client",
  [TypeOfParty.PROSPECT]: "TBD",
};

export const PaymentMethodDisplayMap: Record<MethodOfPayment, string> = {
  [MethodOfPayment.AVIDXCHANGE]: "AvidXchange",
  [MethodOfPayment.CLIENT]: "Client",
  [MethodOfPayment.MANUAL]: "Manual",
};

export const PaymentFrequencyDisplayMap: Record<FrequencyOfPayments, string> = {
  [FrequencyOfPayments.ANNUALLY]: "Annually",
  [FrequencyOfPayments.MONTHLY]: "Monthly",
  [FrequencyOfPayments.QUARTERLY]: "Quarterly",
  [FrequencyOfPayments.SEMI_ANNUALLY]: "Semi-annually",
};

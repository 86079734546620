import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { UseTemplatesHook, useTemplates } from "@/hooks";
import { useState } from "react";
import { Drawer, SkeletonList } from "@/components/base";

import TemplatesTableHeader from "./TemplatesTableHeader";
import { AddTemplateForm } from "../AddTemplateForm";
import { useNavigate } from "react-router-dom";

const descriptionStyle: SxProps = { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: 350 };

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No templates.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

function TemplatesTable() {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>();
  const [search, setSearch] = useState<string>();

  const { templates, loading: templatesLoading, addTemplate } = useTemplates(search);
  const navigate = useNavigate();

  const onAddTemplate: UseTemplatesHook["addTemplate"] = async (input) => {
    try {
      await addTemplate(input);
      setIsDrawerOpen(false);
    } catch (e) {}
  };

  return (
    <>
      <TableLayout title={<TemplatesTableHeader showAddForm={() => setIsDrawerOpen(true)} setSearch={setSearch} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!templates.length && !templatesLoading && <EmptyBody />}
            {templatesLoading && <LoadingBody />}
            {templates.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.name}</TableCell>
                <TableCell sx={descriptionStyle}>{row.description}</TableCell>
                <TableCell align="right">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer anchor="right" onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen}>
        <AddTemplateForm addTemplate={onAddTemplate} />
      </Drawer>
    </>
  );
}

export default TemplatesTable;

import moment from "moment";
import { Grid, Typography } from "@mui/material";

import { MainCard } from "@/components/berry";
import { HeaderOption, SkeletonList } from "@/components/base";
import { useEffect, useState } from "react";
import { displayUsCents } from "@/utils";
import { useDelinquencyData, useHocData } from "@/hooks";
import { ProgramClient } from "@/types";

interface Props {
  programClient: ProgramClient;
}

function ClientProgramHeader({ programClient }: Props) {
  const [fetchAttempted, setFetchAttempted] = useState<boolean>(false);
  const { delinquencyData, loadDelinquencyData } = useDelinquencyData(programClient.personId);
  const { hocData } = useHocData(programClient);

  useEffect(() => {
    (async () => {
      try {
        setFetchAttempted(false);
        await loadDelinquencyData();
      } catch (e) {}
      setFetchAttempted(true);
    })();
  }, [programClient.personId]);

  const renderContent = () => {
    if (!fetchAttempted) return <SkeletonList count={3} height={25} />;

    if (!delinquencyData)
      return (
        <Grid item xs={12}>
          <Typography variant="body1">
            <i>Home program data is not available for this client.</i>
          </Typography>
        </Grid>
      );
    if (hocData) {
      return (
        <>
          <HeaderOption
            label="Close Date:"
            value={delinquencyData?.dateClose ? moment(delinquencyData?.dateClose).format("MM/DD/YY") : ""}
          />
          <HeaderOption
            label="Contract Graduation Date:"
            value={delinquencyData?.contractGraduationDate ? moment(delinquencyData?.contractGraduationDate).format("MM/DD/YY") : ""}
          />
          <HeaderOption label="Rent Payment (Monthly):" value={displayUsCents(delinquencyData?.rentMonthlyCents || 0)} />
          <HeaderOption label="Total Target Savings:" value={displayUsCents(hocData?.totalTargetSavings || 0)} />
          <HeaderOption label="Days Delinquent:" value={`${delinquencyData?.daysDq}`} />
          <HeaderOption label="Suggested Savings (Monthly):" value={displayUsCents(hocData?.suggestedMonthlySavings || 0)} />
          <HeaderOption label="Balance Due:" value={displayUsCents(delinquencyData?.balanceDueCents || 0)} />
        </>
      );
    }
    return (
      <>
        <HeaderOption label="Close Date:" value={delinquencyData?.dateClose ? moment(delinquencyData?.dateClose).format("MM/DD/YY") : ""} />
        <HeaderOption
          label="Contract Graduation Date:"
          value={delinquencyData?.contractGraduationDate ? moment(delinquencyData?.contractGraduationDate).format("MM/DD/YY") : ""}
        />
        <HeaderOption label="Rent Payment (Monthly):" value={displayUsCents(delinquencyData?.rentMonthlyCents || 0)} />
        <HeaderOption label="HOC Payment (Monthly):" value={displayUsCents(delinquencyData?.hocMonthlyCents || 0)} />
        <HeaderOption label="Balance Due:" value={displayUsCents(delinquencyData?.balanceDueCents || 0)} />
        <HeaderOption label="Days Delinquent:" value={`${delinquencyData?.daysDq}`} />
      </>
    );
  };

  return (
    <MainCard>
      <Grid container columnSpacing={4}>
        {renderContent()}
      </Grid>
    </MainCard>
  );
}

export default ClientProgramHeader;

import { StateCode } from "..";

export enum TypeOfUtility {
  WATER = "WATER",
  SEWAGE = "SEWAGE",
  GARBAGE = "GARBAGE",
  GAS = "GAS",
  ELECTRIC = "ELECTRIC",
}

export interface Utility {
  id: string;
  type: TypeOfUtility;
  name: string;
  addressStreet?: string;
  addressCity?: string;
  addressState?: StateCode;
  addressZip?: string;
  phone: string | null;
  email: string | null;
  website?: string;
  created: string;
  createdByAccountId: string;
  updated: string;
  updatedByAccountId: string;
  // join with properties
  propertyId?: string;
}

import { CurrencyCentsTextField, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

function ClientPaymentsForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updateClientPayments } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      firstMonthProratedRentCents: applicationsPropertyFinance.firstMonthProratedRentCents,
      monthlyRentCents: applicationsPropertyFinance.monthlyRentCents,
      securityDepositCents: applicationsPropertyFinance.securityDepositCents,
      monthlyHocCents: applicationsPropertyFinance.monthlyHocCents,
      upfrontHocCents: applicationsPropertyFinance.upfrontHocCents,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateClientPayments({
          firstMonthProratedRentCents: values.firstMonthProratedRentCents,
          monthlyRentCents: values.monthlyRentCents,
          securityDepositCents: values.securityDepositCents,
          monthlyHocCents: values.monthlyHocCents,
          upfrontHocCents: values.upfrontHocCents,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      return errors;
    },
  });

  return (
    <MainCard title="Edit Client Payments">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="First Month Prorated Rent"
              value={formik.values.firstMonthProratedRentCents}
              handleChange={(val) => formik.setFieldValue("firstMonthProratedRentCents", val)}
              error={!!formik.errors.firstMonthProratedRentCents}
              helperText={formik.errors.firstMonthProratedRentCents}
            />
          </Grid>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Monthly Rent"
              value={formik.values.monthlyRentCents}
              handleChange={(val) => formik.setFieldValue("monthlyRentCents", val)}
              error={!!formik.errors.monthlyRentCents}
              helperText={formik.errors.monthlyRentCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Security Deposit"
              value={formik.values.securityDepositCents}
              handleChange={(val) => formik.setFieldValue("securityDepositCents", val)}
              error={!!formik.errors.securityDepositCents}
              helperText={formik.errors.securityDepositCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Monthly HOC"
              value={formik.values.monthlyHocCents}
              handleChange={(val) => formik.setFieldValue("monthlyHocCents", val)}
              error={!!formik.errors.monthlyHocCents}
              helperText={formik.errors.monthlyHocCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Upfront HOC"
              value={formik.values.upfrontHocCents}
              handleChange={(val) => formik.setFieldValue("upfrontHocCents", val)}
              error={!!formik.errors.upfrontHocCents}
              helperText={formik.errors.upfrontHocCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default ClientPaymentsForm;

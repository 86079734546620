import { Route, Routes } from "react-router-dom";

import { EmployeeProfile, NotFoundPage } from "@/components/view";

const AccountRouter = () => {
  return (
    <Routes>
      <Route path="/settings" element={<EmployeeProfile />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AccountRouter;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { sortByCreated } from "@/utils/sort";
import { CoachingHistory, CoachingHistoryWithCreatorAccount } from "@l4s/coaching-models";
import { useEffect, useState } from "react";

interface CreateCoachingHistoryInput {
  monthlyIncomeCents: number | null;
  creditScore: number | null;
  frontEndDti: number | null;
  backEndDti: number | null;
  savingsTotalCents: number | null;
  monthsInProgram: number | null;
  debtCents: number | null;
  balanceCents: number | null;
  inHardship: boolean | null;
}

interface UpdateCoachingHistoryInput {
  monthlyIncomeCents?: number;
  creditScore?: number;
  frontEndDti?: number;
  backEndDti?: number;
  savingsTotalCents?: number;
  monthsInProgram?: number;
  debtCents?: number;
  balanceCents?: number;
  inHardship?: boolean;
}

export interface UseCoachingHistory {
  loadCoachingHistory: () => Promise<void>;
  coachingHistory: Array<CoachingHistoryWithCreatorAccount>;
  isLoading: boolean;
  error: string;
  createCoachingHistoryRecord: (input: CreateCoachingHistoryInput) => Promise<void>;
  updateCoachingHistoryRecord: (coachingHistoryRecordId: string, input: UpdateCoachingHistoryInput) => Promise<void>;
  updatingCoachingHistory: boolean;
}

export const useCoachingHistory = (programClientId: string): UseCoachingHistory => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [coachingHistory, setCoachingHistory] = useState([]);

  const loadCoachingHistory = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<CoachingHistory>>(routes.GET_COACHING_HISTORY, {
        params: { programClientId },
      });
      setCoachingHistory(sortByCreated(response.data));
    } catch (e) {
      const mappedError = mapUnknownToAxiosError(e);
      setError(mappedError.response.data.message || "Error loading coaching history");
    }
    setLoading(false);
  };

  const createCoachingHistoryRecord = async (input: CreateCoachingHistoryInput): Promise<void> => {
    try {
      await bridgeV2ApiInstance.post<CoachingHistory>(routes.CREATE_COACHING_HISTORY, { ...input, programClientId });
      await loadCoachingHistory();
    } catch (e) {
      const mappedError = mapUnknownToAxiosError(e);
      setError(mappedError.response.data.message || "Error creating coaching history record");
    }
  };

  const updateCoachingHistoryRecord = async (coachingHistoryRecordId: string, input: UpdateCoachingHistoryInput): Promise<void> => {
    try {
      await bridgeV2ApiInstance.patch<CoachingHistory>(routes.UPDATE_COACHING_HISTORY(coachingHistoryRecordId), input);
      loadCoachingHistory();
    } catch (e) {
      const mappedError = mapUnknownToAxiosError(e);
      setError(mappedError.response.data.message || "Error updating coaching history record");
    }
  };

  useEffect(() => {
    if (programClientId) {
      loadCoachingHistory();
    }
  }, [programClientId]);

  return {
    loadCoachingHistory,
    createCoachingHistoryRecord,
    updateCoachingHistoryRecord,
    updatingCoachingHistory: false,
    isLoading: loading,
    coachingHistory,
    error,
  };
};

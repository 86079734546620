import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { Grid, Typography } from "@mui/material";
import { MainCard } from "@/components/berry";

import { ClientPaymentsCard } from "./ClientPaymentsCard";
import { FixedPaymentsCard } from "./FixedPaymentsCard";
import { PropertyFinancialsCard } from "./PropertyFinancialsCard";
import { YearlyPaymentsCard } from "./YearlyPaymentsCard";
import { FinancialRatiosCard } from "./FinancialRatiosCard";
import { SalesFinancialsCard } from "./SalesFinancialsCard";
import { Box } from "@mui/system";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const ClientPropertyFinance = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const { applicationsPropertyFinance, error } = useApplicationsPropertyFinanceInstance;

  if (error)
    return (
      <MainCard>
        <Typography color="error">
          <i>{error}</i>
        </Typography>
      </MainCard>
    );

  if (applicationsPropertyFinance)
    return (
      <Grid container spacing={1} sx={{ pb: 10 }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <ClientPaymentsCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
            <FixedPaymentsCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
            <YearlyPaymentsCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
            <SalesFinancialsCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <PropertyFinancialsCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
            <FinancialRatiosCard useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance} />
          </Box>
        </Grid>
      </Grid>
    );

  return null;
};

export default ClientPropertyFinance;

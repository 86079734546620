import { AxiosError } from "axios";

export const mapUnknownToError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  } else if ("string" === typeof error) {
    return new Error(error);
  }

  return new Error("Unknown error.");
};

// This function is expecting an API to return error messages with body: { message: string }
export const mapUnknownToAxiosError = (error: unknown): AxiosError<{ message?: string }> => {
  if (error instanceof AxiosError) {
    return error;
  } else if ("string" === typeof error) {
    return new AxiosError(error);
  }

  return new AxiosError("Unknown error.");
};

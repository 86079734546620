import { orderBy } from "lodash";
import { bridgeV2ApiInstance } from "@/services";
import { ApplicationsPropertyDto, OrderWithInspectionsAndReportsDto } from "@/types";
import { useMemo, useState } from "react";

interface UseOrdersInstance {
  orders: Array<OrderWithInspectionsAndReportsDto>;
  isLoading: boolean;
  hasFetched: boolean;
  error: Error | undefined;
  loadOrders: () => void;
}

interface UseOrders {
  (applicationPropertyId: ApplicationsPropertyDto["id"]): UseOrdersInstance;
}

export const useOrders: UseOrders = (applicationPropertyId: ApplicationsPropertyDto["id"]) => {
  const [orders, setOrders] = useState<Array<OrderWithInspectionsAndReportsDto>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const sortedOrders = useMemo(() => {
    return orderBy(orders, "created", "desc");
  }, [orders]);

  const loadOrders = async () => {
    try {
      setHasFetched(true);
      setIsLoading(true);

      const response = await bridgeV2ApiInstance.get<Array<OrderWithInspectionsAndReportsDto>>(
        `/application-properties/${applicationPropertyId}/orders`
      );
      setOrders(response.data);
    } catch (err: unknown) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    orders: sortedOrders,
    isLoading,
    hasFetched,
    error,
    loadOrders,
  };
};

import { useToast } from "@/hooks";
import { bridgeV2ApiInstance } from "@/services";
import { CREATE_DEFINED_ENVELOPE, GET_ALL_DEFINED_ENVELOPES } from "@/services/routes";
import { DefinedEnvelope } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useMemo, useState } from "react";

export interface CreateDefinedEnvelopeInput {
  name: string;
  description: string;
}

export interface UseDefinedEnvelopesHook {
  definedEnvelopes: Array<DefinedEnvelope>;
  createDefinedEnvelope: (input: CreateDefinedEnvelopeInput) => Promise<DefinedEnvelope>;
  loading: boolean;
}

export const useDefinedEnvelopes = (): UseDefinedEnvelopesHook => {
  const [definedEnvelopes, setDefinedEnvelopes] = useState<Array<DefinedEnvelope>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { addToast } = useToast();

  const getDefinedEnvelopes = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<DefinedEnvelope>>(GET_ALL_DEFINED_ENVELOPES);
      setDefinedEnvelopes(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.response.data.message || "Failed to get defined envelopes",
        type: "error",
      });
    }
    setLoading(false);
  };

  const createDefinedEnvelope = async (input: CreateDefinedEnvelopeInput): Promise<DefinedEnvelope> => {
    try {
      const response = await bridgeV2ApiInstance.post<DefinedEnvelope>(CREATE_DEFINED_ENVELOPE, input);
      setDefinedEnvelopes([...definedEnvelopes, response.data]);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.response.data.message || "Failed to create defined envelope",
        type: "error",
      });
      throw error;
    }
  };

  useEffect(() => {
    getDefinedEnvelopes();
  }, []);

  const sortedDefinedEnvelopes = useMemo(() => definedEnvelopes.sort((a, b) => a.name.localeCompare(b.name)), [definedEnvelopes]);

  return { definedEnvelopes: sortedDefinedEnvelopes, loading, createDefinedEnvelope };
};

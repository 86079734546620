import { useState, useEffect, useCallback, useRef, ElementRef } from "react";
import { Box, Grid, Tab, Typography, Chip } from "@mui/material";
import { TabPanel } from "@mui/lab";
import moment from "moment";

import { LandisButton, Tabs, HeaderOption, FullScreenLoading } from "@/components/base";
import { GRID_SPACING, DEFAULT_PADDING } from "@/config";
import { CreateHardshipInput, Hardship, UseHardships, useToast } from "@/hooks";
import { SearchParamKeys } from "@/types";
import { mapUnknownToError, boolToConfirm, mapConfirmOptions } from "@/utils";
import { mapHardshipPrimaryReason } from "./mapHardship";

import { CoachHardshipForm } from "./CoachHardshipForm";

const ADD_HARDSHIP_PANEL_VALUE = "add-hardship";

interface Props {
  useHardships: UseHardships;
}

function CoachHardshipReason({ useHardships }: Props) {
  const { hardships, loadHardships, createHardship, editHardship } = useHardships;
  const tabsRef = useRef<ElementRef<typeof Tabs>>(null);
  const { addToast } = useToast();
  const [init, setInit] = useState<boolean>(false);
  const [hardshipToEdit, setHardshipToEdit] = useState<Hardship | undefined>(undefined);

  const setCurrentTab = (tabName: string) => {
    tabsRef.current?.setTab(tabName);
  };

  const getCurrentTab = (): string => {
    //using a ref with current requires a ? since component is not initialized the 1st time the function is called
    return tabsRef.current?.getTab() ?? "";
  };

  const reloadHardships = useCallback(async () => {
    try {
      // don't leverage these, use the return from useHardships
      const throwAwayHardships = await loadHardships();
      if (throwAwayHardships.length) setCurrentTab(throwAwayHardships[0].id);
    } catch (e) {
      const error = mapUnknownToError(e);
      addToast({ message: error.message, type: "error" });
    }
    setInit(true);
  }, []);

  const onSubmit = async (formInput: CreateHardshipInput, hardship: Hardship, onSuccessOrFailure: () => void) => {
    try {
      if (hardship?.id) {
        await editHardship(hardship, formInput);
      } else {
        await createHardship(formInput);
      }

      await reloadHardships();
      if (hardshipToEdit) {
        setCurrentTab(hardshipToEdit.id);
      }
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }

    //this stops the loading animation in the form screen
    onSuccessOrFailure();
  };

  useEffect(() => {
    reloadHardships();
  }, [reloadHardships]);

  //show loader until first api call happens for all hardships
  if (!init) {
    return <FullScreenLoading height="200px" />;
  }

  //make sure hardships can never be a null array
  const harshipsToRender: Hardship[] = hardships ?? [];
  return (
    <Tabs
      tabsIds={[...[ADD_HARDSHIP_PANEL_VALUE], ...harshipsToRender.map((opt) => opt.id)]}
      ref={tabsRef}
      tabToStart={hardships?.[0]?.id ?? ADD_HARDSHIP_PANEL_VALUE}
      verticalTabListCardTitle="Hardship Reason"
      urlSearchParamName={SearchParamKeys.HARDSHIP_REASON_TAB}
      orientation="vertical"
      TabListComponent={
        <>
          {harshipsToRender.map((opt, i) => (
            <Tab
              sx={{ maxWidth: "100%" }}
              key={i}
              value={opt.id}
              label={
                <Grid container columnSpacing={GRID_SPACING}>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="subtitle1" color="inherit">
                      {mapHardshipPrimaryReason(opt.primaryReason)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                      {opt.dueToIncomeReduction && <Chip size="small" label="Reduces Income" />}
                      {opt.dueToIncreasedCosts && <Chip size="small" label="Increases Costs" />}
                      {opt.isTemporary && <Chip size="small" label="Temporary" />}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <HeaderOption
                      gridItem={false}
                      label={`${opt.description ? "Description:" : "Start:"}`}
                      value={`${opt.description ? opt.description : moment(opt.hardshipDate).format("M/D/YY")}`}
                    />
                  </Grid>
                </Grid>
              }
            />
          ))}
          {/*This prevents MUI error.  Do not put landis button in here as it will cause style conflicts with tab list*/}
          <Tab
            sx={{ position: "fixed", top: "-9999px", left: "-9999px" }}
            key={ADD_HARDSHIP_PANEL_VALUE}
            value={ADD_HARDSHIP_PANEL_VALUE}
            label="ADD NEW"
          />
        </>
      }
      BelowTabListComponent={
        <>
          {harshipsToRender.length > 0 && <Box sx={{ paddingTop: DEFAULT_PADDING }} />}
          <Box sx={{ marginTop: harshipsToRender.length === 0 ? "-48px" : "0px" }}>
            <LandisButton
              disableElevation
              size="large"
              variant="contained"
              fullWidth
              disabled={getCurrentTab() === ADD_HARDSHIP_PANEL_VALUE && !hardshipToEdit}
              onClick={() => {
                setHardshipToEdit(undefined);
                setCurrentTab(ADD_HARDSHIP_PANEL_VALUE);
              }}
            >
              Add Hardship
            </LandisButton>
          </Box>
        </>
      }
      TabPanelComponent={
        <>
          <TabPanel value={ADD_HARDSHIP_PANEL_VALUE}>
            <Typography variant="h3">{hardshipToEdit ? "Update Hardship" : "New Hardship"}</Typography>
            <Box sx={{ marginTop: "24px" }} />
            <CoachHardshipForm save={onSubmit} hardshipToEdit={hardshipToEdit} />
          </TabPanel>
          {harshipsToRender.map((opt, i) => (
            <TabPanel key={i} value={opt.id}>
              <Typography variant="h3">{mapHardshipPrimaryReason(opt.primaryReason)}</Typography>
              <Box sx={{ paddingTop: DEFAULT_PADDING }} />
              <HeaderOption gridItem={false} label="Start:" value={moment(opt.hardshipDate).format("M/D/YY")} />
              {opt.hardshipEndDate && <HeaderOption gridItem={false} label="End:" value={moment(opt.hardshipEndDate).format("M/D/YY")} />}
              <HeaderOption
                gridItem={false}
                label="Due to income reduction:"
                value={mapConfirmOptions(boolToConfirm(opt.dueToIncomeReduction))}
              />
              <HeaderOption
                gridItem={false}
                label="Due to cost increases:"
                value={mapConfirmOptions(boolToConfirm(opt.dueToIncreasedCosts))}
              />
              <HeaderOption gridItem={false} label="Is temporary:" value={mapConfirmOptions(boolToConfirm(opt.isTemporary))} />
              <HeaderOption gridItem={false} label="Created at:" value={moment(opt.created).format("M/D/YY h:mma")} />
              {opt.description && <HeaderOption gridItem={false} label="Description:" value={opt.description} />}
              <Box sx={{ paddingTop: DEFAULT_PADDING }} />
              <LandisButton
                disableElevation
                size="large"
                variant="outlined"
                fullWidth
                onClick={() => {
                  setHardshipToEdit(opt);
                  setCurrentTab(ADD_HARDSHIP_PANEL_VALUE);
                }}
              >
                Edit Hardship
              </LandisButton>
            </TabPanel>
          ))}
        </>
      }
    />
  );
}

export default CoachHardshipReason;

import { useEffect, useMemo, FC, ReactNode } from "react";

// material-ui
import { styled, useTheme, Theme } from "@mui/material/styles";
import { Container, AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import HorizontalBar from "./HorizontalBar";
import { Breadcrumbs } from "@/components/berry";

import { useMenuItems } from "../navigation";
import { LAYOUT_CONST } from "@/constant";
import useConfig from "@/hooks/useConfig";
import { drawerWidth } from "@/store/constant";
import { openMenuDrawer } from "@/store/slices/menu";
import { useDispatch, useSelector } from "@/store";
import { useClientMenuItems } from "@/hooks";

// assets
import { IconChevronRight } from "@tabler/icons";

interface Props {
  children: ReactNode;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
  layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<MainStyleProps>(
  ({ theme, open, layout }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 84,
        padding: "12px",
        marginRight: "12px",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "12px",
        marginRight: "12px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "12px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "8px",
        marginRight: "8px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "12px",
        marginTop: 88,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.up("md")]: {
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 84,
        padding: "12px",
        marginRight: "12px",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "12px",
        marginRight: "12px",
        marginTop: 88,
        padding: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "8px",
        marginRight: "8px",
        marginTop: 88,
        padding: "12px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: FC<Props> = ({ children }) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigation = useMenuItems();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { drawerType, container, layout, breadcrumbs } = useConfig();
  const { clientMenu } = useClientMenuItems();

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      dispatch(openMenuDrawer(true));
    } else {
      dispatch(openMenuDrawer(false));
    }
  }, [drawerType]);

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      dispatch(openMenuDrawer(true));
    }
  }, []);

  useEffect(() => {
    if (matchDownMd) {
      dispatch(openMenuDrawer(true));
    }
  }, [matchDownMd]);

  const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

  const header = useMemo(
    () => (
      <Toolbar sx={{ p: condition ? "10px" : "16px" }}>
        <Header />
      </Toolbar>
    ),

    [layout, matchDownMd]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ bgcolor: theme.palette.background.default }}>
        {header}
      </AppBar>

      {/* horizontal menu-list bar */}
      {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

      {/* drawer */}
      {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

      {/* main content */}
      <Main theme={theme} open={drawerOpen} layout={layout}>
        <Container maxWidth={container ? "lg" : false} {...(!container && { sx: { px: { xs: 0 } } })}>
          {/* breadcrumb */}
          {breadcrumbs && clientMenu && (
            <Breadcrumbs separator={IconChevronRight} navigation={{ items: [clientMenu, ...navigation.items] }} icon title rightAlign />
          )}
          {children}
        </Container>
      </Main>
    </Box>
  );
};

export default MainLayout;

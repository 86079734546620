import { TypeExternalSource } from "./TypeExternalSource";

export enum WorkOrderSeverity {
  URGENT = "urgent",
  NORMAL = "normal",
  EMERGENCY = "emergency",
}

// https://www.figma.com/file/L1jDq5TR8qV7UZuzQSr0a9/Latchel-Integration?node-id=0-1&t=gBm1FfkEFvrNUbOh-0
export enum WorkOrderStatus {
  PENDING = "Pending",
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
  WAITING_FOR_MATERIALS = "Waiting for Materials",
  TROUBLESHOOTING = "Troubleshooting",
  DIAGNOSING_ISSUE = "Diagnosing Issue",
  PENDING_AWAITING_PAYMENT_DETAILS = "Pending - Awaiting Payment Details",
  FORWARDED_TO_PM = "Forwarded to PM",
  FINDING_SERVICE_PROVIDER = "Finding Service Provider",
  AWAITING_WORK_DATE = "Awaiting Work Date",
  CONFIRMING_COMPLETION = "Confirming Completion",
  NEEDS_OWNER_APPROVAL = "Needs Owner Approval",
  WAITING_FOR_PM = "Waiting for PM",
  NEEDS_APPROVAL = "Needs Approval",
  AWAITING_INVOICE = "Awaiting Invoice",
  SCHEDULING_RESIDENT = "Scheduling Resident",
  SCHEDULING_SERVICE_PROVIDER = "Scheduling Service Provider",
  RESCHEDULING_RESIDENT = "Rescheduling Resident",
}

export interface WorkOrderDto {
  id: string;
  created: string;
  updated?: string;
  externalId: string;
  externalSource: TypeExternalSource;
  externalTenantId?: string;
  severity?: WorkOrderSeverity;
  status?: WorkOrderStatus;
  name?: string;
  description?: string;
  vendorInstruction?: string;
  maxCostCents?: number;
  externalUrl?: string;
}

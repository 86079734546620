import { TableCell, TableRow } from "@mui/material";

interface Props {
  isLoading: boolean;
}
function NoCoachingHistory({ isLoading }: Props) {
  return (
    <TableRow>
      <TableCell>{isLoading ? "Coaching history loading..." : "No coaching history"}</TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

export default NoCoachingHistory;

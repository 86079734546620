import { useEffect, useState, useRef, ElementRef } from "react";
import { Chip, Grid, Tab, Typography } from "@mui/material";
import moment from "moment";

import { FullScreenLoading, Tabs } from "@/components/base";
import { TabPanel } from "@mui/lab";
import { useToast } from "@/hooks";
import { UsePaymentPlans } from "@/hooks";
import { SearchParamKeys } from "@/types";
import { sortByDate } from "@/utils/sort";
import { mapUnknownToError } from "@/utils";

import ViewPaymentPlan from "./ViewPaymentPlan";
import AddPaymentPlanBtn from "./AddPaymentPlanBtn";

interface Props {
  programClientId: string;
  usePaymentPlans: UsePaymentPlans;
}

const CoachPaymentPlan = ({ programClientId, usePaymentPlans }: Props) => {
  const tabsRef = useRef<ElementRef<typeof Tabs>>(null);
  const [init, setInit] = useState<boolean>(false);
  const { addToast } = useToast();

  const setCurrentTab = (tabName: string) => {
    tabsRef.current?.setTab(tabName);
  };

  const {
    paymentPlans,
    createPaymentPlan,
    loadPaymentPlans,
    createExpectedPayment,
    deleteExpectedPaymentById,
    updateExpectedPayment,
    activatePaymentPlan,
  } = usePaymentPlans;

  const fetchPaymentPlans = async (input?: { reload?: boolean }) => {
    try {
      const plans = await loadPaymentPlans(input);
      if (plans.length) setCurrentTab(plans[0].id);
    } catch (e) {
      const error = mapUnknownToError(e);
      addToast({ message: error.message, type: "error" });
    }
    setInit(true);
  };

  useEffect(() => {
    fetchPaymentPlans();
  }, []);

  // This is to navigate to the newly created plan tab
  const interceptCreatePaymentPlan: UsePaymentPlans["createPaymentPlan"] = async (input) => {
    const createdPlan = await createPaymentPlan(input);
    await fetchPaymentPlans();
    setCurrentTab(createdPlan.id);
    return createdPlan;
  };

  if (!init) {
    return <FullScreenLoading height="200px" />;
  }

  return (
    <Tabs
      tabsIds={paymentPlans.map((opt) => opt.id)}
      ref={tabsRef}
      verticalTabListCardTitle="Payment Plan"
      urlSearchParamName={SearchParamKeys.PAYMENT_PLAN_TAB}
      orientation="vertical"
      TabListComponent={
        <>
          {Boolean(paymentPlans.length) &&
            paymentPlans.sort(sortByDate("created")).map((paymentPlan, i) => (
              <Tab
                sx={{ maxWidth: "100%" }}
                key={i}
                value={paymentPlan.id}
                iconPosition="start"
                label={
                  <Grid item xs={12} container justifyContent="space-between">
                    <Grid item xs={9} container direction="column" alignItems="flex-start">
                      <Typography variant="subtitle1" color="inherit">
                        {paymentPlan.name}
                      </Typography>
                      <Typography component="div" variant="caption" sx={{ textTransform: "capitalize" }}>
                        Created {moment(paymentPlan.created).format("M/D/YY h:mma")}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end" item xs={3}>
                      {paymentPlan.isCurrent && <Chip size="small" label="Active" />}
                    </Grid>
                  </Grid>
                }
              />
            ))}
        </>
      }
      BelowTabListComponent={<AddPaymentPlanBtn createPaymentPlan={interceptCreatePaymentPlan} programClientId={programClientId} />}
      TabPanelComponent={
        <>
          {paymentPlans.map((plan, i) => (
            <TabPanel value={plan.id} key={i}>
              <ViewPaymentPlan
                activatePaymentPlan={activatePaymentPlan}
                updateExpectedPayment={updateExpectedPayment}
                createExpectedPayment={createExpectedPayment}
                deleteExpectedPaymentById={deleteExpectedPaymentById}
                paymentPlan={plan}
              />
            </TabPanel>
          ))}
        </>
      }
    />
  );
};

export default CoachPaymentPlan;

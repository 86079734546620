import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";
import { CreditData as CreditDashboardData } from "@l4s/person-finances-models";

export interface UsePersonFinancesCreditDashboard {
  creditDashboard: CreditDashboardData;
  personFinancesCreditDashboardLoading: boolean;
  loadPersonFinancesCreditDashboard: (personId: string) => Promise<void>;
}

export const UsePersonFinancesCreditDashboard = (): UsePersonFinancesCreditDashboard => {
  const [personFinancesCreditDashboardLoading, setPersonFinancesCreditDashboardLoading] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<CreditDashboardData>({
    latestReport: {
      reportId: null,
      reportDate: null,
      score: null,
      liabilities: [],
      newInquiryMonthCount: null,
    },
    creditReportAnalyzedDataOverTime: [],
  });

  const loadPersonFinancesCreditDashboard = async (personId: string): Promise<void> => {
    try {
      setPersonFinancesCreditDashboardLoading(true);
      const creditDashboardResponse = await bridgeV2ApiInstance.get<CreditDashboardData>(
        routes.GET_CREDIT_DASHBOARD_BY_PERSON_ID(personId)
      );

      setPersonFinancesCreditDashboardLoading(false);
      setDashboardData(creditDashboardResponse.data);
    } catch (e) {
      setPersonFinancesCreditDashboardLoading(false);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching person finances credit dashboard data.");
    }
  };

  return {
    creditDashboard: dashboardData,
    personFinancesCreditDashboardLoading: personFinancesCreditDashboardLoading,
    loadPersonFinancesCreditDashboard: loadPersonFinancesCreditDashboard,
  };
};

import { CashForKeysOffer } from "@/types";

export enum OfferStatus {
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
}

export const getOfferStatus = (offer: CashForKeysOffer): OfferStatus => {
  if (offer.acceptedDate) return OfferStatus.ACCEPTED;
  if (offer.rejectedDate) return OfferStatus.REJECTED;
  return OfferStatus.PENDING;
};

import DEV from "./DEV";
import STG from "./STG";
import PRD from "./PRD";
import LOCAL from "./LOCAL";

/*this is a run time config to pass dev/prd as env using public/config.js*/
const RUN_TIME = (window as any)?.RUN_TIME;

const isProdEnv = RUN_TIME?.REACT_APP_ENV === "prd" || process.env.REACT_APP_ENV === "prd";
const isStagingEnv = RUN_TIME?.REACT_APP_ENV === "stg" || process.env.REACT_APP_ENV === "stg";
const isDevEnv = RUN_TIME?.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "dev";
const isLocalEnv = process.env.REACT_APP_ENV === "local" || !process.env.REACT_APP_ENV;

const variables = isProdEnv ? PRD : isStagingEnv ? STG : isDevEnv ? DEV : LOCAL;

const DOCUSIGN_ENVELOPE_DETAIL_BASE_URL = isProdEnv
  ? "https://app.docusign.com/documents/details"
  : "https://appdemo.docusign.com/documents/details";

const FEATUREHUB_URL = "https://featurehub.landis.network";
const FEATUREHUB_API_KEY = isProdEnv
  ? "da81d130-ed29-44a8-8e6b-04e088497462/z4YOkIsXU9KnuKhlhMRFqpvoCl9X5g*Q2iEHlYkzuFZT5fERHKf"
  : isDevEnv
  ? "53dfb844-7df6-4cae-b2c1-25ee15674c68/hPAAUQdJfGEFoYenlxW7lEXraux6Eh*CXQPIrpVuFFEQq2HCh7a"
  : "802fdee6-804c-4511-a01a-36928766c40f/Kt6phJQ9ZT6mru3TBGUKuVw6BWvW1N*2XMWrS9x9FRxq9TZJwvy";

const AVATAR_IMAGE_DOMAIN = isProdEnv ? "https://landis-public.s3.amazonaws.com/prd" : "https://landis-public.s3.amazonaws.com/dev";

export default {
  ...variables,
  isProdEnv,
  isDevEnv,
  isLocalEnv,
  DOCUSIGN_ENVELOPE_DETAIL_BASE_URL,
  FEATUREHUB_URL,
  FEATUREHUB_API_KEY,
  AVATAR_IMAGE_DOMAIN,
};

import { useState } from "react";
import { Box } from "@mui/material";

import { sleep } from "@/utils";

import { ContentPasteTwoTone, InventoryTwoTone } from "@mui/icons-material";

interface Props {
  value: string;
}

function CopyToClipboard({ value }: Props) {
  const [copied, setCopied] = useState(false);

  function copyToClipboard() {
    const temp = document.createElement("textarea");
    temp.value = value;
    temp.setAttribute("readonly", "");
    temp.style.position = "absolute";
    temp.style.left = "-9999px";
    document.body.appendChild(temp);
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);

    setCopied(true);
    sleep(3000).then(() => {
      setCopied(false);
    });
  }

  return (
    <>
      {copied ? (
        <InventoryTwoTone fontSize="inherit" sx={{ color: "success.dark" }} />
      ) : (
        <Box onClick={copyToClipboard} sx={{ cursor: "pointer" }}>
          <ContentPasteTwoTone fontSize="inherit" />
        </Box>
      )}
    </>
  );
}

export default CopyToClipboard;

import { bridgeV2ApiInstance, routes } from "@/services";
import { Person } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UsePerson {
  person: Person;
  loadPerson: (personId: string) => Promise<void>;
}

export const usePerson = (): UsePerson => {
  const [person, setPerson] = useState<Person>();

  const loadPerson = async (personId: string) => {
    try {
      setPerson(undefined);
      const response = await bridgeV2ApiInstance.get<Person>(routes.GET_PERSON_BY_ID(personId));
      setPerson(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching person details.");
    }
  };

  return {
    person,
    loadPerson,
  };
};

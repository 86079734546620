import { ClosedReason, JourneyMilestoneDtoModel, JourneyRequirementDtoModel } from "@/types";
import { Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
  input: JourneyMilestoneDtoModel | JourneyRequirementDtoModel;
}

const RequirementChip = ({ input }: Props) => {
  if (input?.closedReason === ClosedReason.COMPLETED) {
    return <Chip label="Complete" icon={<CheckCircleIcon />} variant="outlined" color="primary" size="small" />;
  } else if (input?.closedReason === ClosedReason.VOIDED) {
    return <Chip label="Voided" variant="outlined" size="small" />;
  } else if (!input?.closedAt) {
    return <Chip label="Open" color="primary" size="small" />;
  }
};

export default RequirementChip;

import { TypeOfAction } from "@/types";
import { ChooseActionType } from "../ClientNoteActions/ClientNoteActions";

export const mapActionTypes = (actionType: ChooseActionType) => {
  switch (actionType) {
    case ChooseActionType.BUTTON:
      return TypeOfAction.LINK;
    case ChooseActionType.PULSE:
      return TypeOfAction.PULSE;
    case ChooseActionType.RESPONSE:
      return TypeOfAction.SHORT_RESPONSE;
    case ChooseActionType.NONE:
      return TypeOfAction.NO_ACTION;
    default:
      return TypeOfAction.NO_ACTION;
  }
};

export const mapActionTypesToDisplayText = {
  [TypeOfAction.LINK]: "Button",
  [TypeOfAction.PULSE]: "Pulse",
  [TypeOfAction.SHORT_RESPONSE]: "Response",
  [TypeOfAction.NO_ACTION]: "N/A",
};

export const mapActionTypesToSelectedButton = {
  [TypeOfAction.LINK]: ChooseActionType.BUTTON,
  [TypeOfAction.PULSE]: ChooseActionType.PULSE,
  [TypeOfAction.SHORT_RESPONSE]: ChooseActionType.RESPONSE,
  [TypeOfAction.NO_ACTION]: ChooseActionType.NONE,
};

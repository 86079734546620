import { createSlice } from "@reduxjs/toolkit";
import { ClientMenuItem } from "@/types/menu";

const initialState: Array<ClientMenuItem> = [];

const clientMenuItems = createSlice({
  name: "clientMenuItems",
  initialState,
  reducers: {
    // From localstorage
    setClientMenuItems(_, action) {
      return action.payload;
    },
  },
});

export const clientMenuItemsReducer = clientMenuItems.reducer;

export const { setClientMenuItems } = clientMenuItems.actions;

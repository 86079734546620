import { ReactElement } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";

interface TableProps {
  /** The MUI <TableCell> components that should be the column headers */
  TableHeaderCellComponents: ReactElement;
  /** The MUI <TableRow> components that should be the rows in the Table body */
  TableBodyComponents: ReactElement;
}

function TableFrameComponent({ TableHeaderCellComponents, TableBodyComponents }: TableProps) {
  //JSX does not allow Component array in props.
  function ExtractChildren(Component: ReactElement): ReactElement | ReactElement[] {
    return Component?.props?.children ?? <span />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{ExtractChildren(TableHeaderCellComponents)}</TableRow>
        </TableHead>
        <TableBody>{ExtractChildren(TableBodyComponents)}</TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * A base component to frame a table into a header and body
 *
 * @component TableFrame
 * @interface TableProps
 * @property {ReactElement} TableHeaderCellComponents - The MUI <TableCell> components that should be the column headers
 * @property {ReactElement} TableBodyComponents - The MUI <TableRow> components that should be the rows in the Table body
 */
export const TableFrame = TableFrameComponent;

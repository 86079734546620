import { PDFDocument, rgb } from "pdf-lib";
import { toBase64 } from "./utils";
import { TemplateFieldInTemplate } from "@/types";
import { env } from "@/env";

export async function composeTemplateFile(file: File, templateFields: Array<TemplateFieldInTemplate>): Promise<File> {
  const pdfDoc = await PDFDocument.load(await toBase64(file));

  const pages = pdfDoc.getPages();

  templateFields.forEach((templateField) => {
    const pageHeight = pages[templateField.page].getHeight();
    const pageWidth = pages[templateField.page].getWidth();
    pages[templateField.page].drawText(templateField.id, {
      x: (templateField.xCoord / 100) * pageWidth,
      // something with the line height requires the subtraction of 1% of the page height
      y: ((100 - templateField.yCoord - 1) / 100) * pageHeight,
      // Set to white and size of 1 to make the field invisible
      size: 1,
      color: rgb(1, 1, 1),
    });
    // In dev, we'll make the field name visible for debugging purposes
    if (!env.isProdEnv) {
      pages[templateField.page].drawText(templateField.templateFieldName, {
        x: (templateField.xCoord / 100) * pageWidth,
        // something with the line height requires the subtraction of 1% of the page height
        y: ((100 - templateField.yCoord - 1) / 100) * pageHeight,
        // for actual composition, change to size: 1 & color: rgb(1, 1, 1)
        // in dev, we'll make the text visible for debugging purposes
        size: 3,
        color: rgb(1, 0, 0),
      });
    }
  });

  const pdfBytes = await pdfDoc.save();

  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const templateFile = new File([blob], file.name, { type: "application/pdf" });

  return templateFile;
}

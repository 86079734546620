import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Grid, Typography } from "@mui/material";

import { CopyToClipboard } from "@/components/base/CopyToClipboard";

interface Props {
  variant?: "h2" | "h3";
  value: string;
  Icon?: any;
  label?: string;
  allowCopy?: boolean;
  RightComponent?: ReactJSXElement;
  lg?: number;
  gridItem?: boolean;
}

function HeaderOption({ variant, label, value, Icon, allowCopy = false, RightComponent, lg = 6, gridItem = true }: Props) {
  function Inside() {
    return (
      <Box sx={{ display: "flex" }}>
        {Icon && <Box sx={{ marginRight: "6px", paddingTop: "3px" }}>{Icon}</Box>}
        {label && (
          <Box sx={{ marginRight: "6px" }}>
            <Typography align="left" variant={"subtitle1"}>
              {label}
            </Typography>
          </Box>
        )}
        <Box sx={{ paddingTop: "4px" }}>
          <Typography align="left" variant={variant ?? "body1"}>
            {value}
          </Typography>
        </Box>
        {allowCopy && (
          <Box sx={{ marginLeft: "6px", paddingTop: "2px", display: "flex", alignItems: "center" }}>
            <CopyToClipboard value={value} />
          </Box>
        )}
        {RightComponent ? RightComponent : null}
      </Box>
    );
  }

  if (gridItem) {
    return (
      <Grid item xs={12} lg={lg}>
        <Inside />
      </Grid>
    );
  }

  return <Inside />;
}

export default HeaderOption;

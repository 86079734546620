import { Grid, Skeleton } from "@mui/material";
import { GridSpacing, ResponsiveStyleValue } from "@mui/system";

interface SkeletonListProps {
  count?: number;
  height?: number;
  orientation?: "vertical" | "horizontal";
  spacing?: ResponsiveStyleValue<GridSpacing>;
  width?: number;
}

function SkeletonList({ count = 1, height = 50, width, orientation = "vertical", spacing = 1 }: SkeletonListProps) {
  return (
    <Grid item xs={12} container direction={orientation === "vertical" ? "column" : "row"} spacing={spacing} flexWrap="nowrap">
      {Array.from({ length: count }, (_, i) => (
        <Grid item xs={12} key={`skeleton-${i}`}>
          <Skeleton width={width} height={height} />
        </Grid>
      ))}
    </Grid>
  );
}

export default SkeletonList;

import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { typeOfProgramLabelMap, typeOfValuationLabelMap } from "@/types";
import { displayUsCents } from "@/utils";
import { ClientPropertyFinanceRow } from "../types";
import { useState } from "react";

import ClientPropertyFinanceTable from "../ClientPropertyFinanceTable";
import ClientPropertyFinanceTableHeader from "../ClientPropertyFinanceTableHeader";
import FixedPaymentsForm from "./FixedPaymentsForm";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const tableRows: Array<ClientPropertyFinanceRow> = [
  {
    label: "Lease Start Date",
    fieldKey: "leaseStartDate",
    displayFn: (value) => value,
  },
  {
    label: "Current Lease Term",
    fieldKey: "currentLeaseTerm",
    displayFn: (value) => typeOfProgramLabelMap[value],
  },
  {
    label: "Valuation Type",
    fieldKey: "valuationType",
    displayFn: (value) => typeOfValuationLabelMap[value],
  },
  {
    label: "Recording Fee",
    fieldKey: "recordingFeeCents",
    displayFn: displayUsCents,
  },
  {
    label: "Title Fee",
    fieldKey: "titleFeeCents",
    displayFn: displayUsCents,
  },
];

const FixedPaymentsCard = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const isFormOpenStateInstance = useState(false);
  const [, setIsFormOpen] = isFormOpenStateInstance;

  return (
    <ClientPropertyFinanceTable
      useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
      tableRows={tableRows}
      title={
        <ClientPropertyFinanceTableHeader
          FormElement={
            <FixedPaymentsForm
              handleClose={() => setIsFormOpen(false)}
              useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
            />
          }
          isFormOpenStateInstance={isFormOpenStateInstance}
          title="Fixed Payments"
        />
      }
    />
  );
};

export default FixedPaymentsCard;

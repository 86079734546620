export enum InspectionSourceId {
  INSPECTIFY = 1,
}

export const inspectionSourceIdToDisplay = (sourceId: number) => {
  switch (sourceId) {
    case InspectionSourceId.INSPECTIFY:
      return "Inspectify";
    default:
      return "Unknown";
  }
};

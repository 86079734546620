import { LandisButton } from "@/components/base";
import { UseEnvelopeHook } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Card, Grid, Typography, useTheme } from "@mui/material";
import { IconBrandTelegram } from "@tabler/icons";
import { useState } from "react";

interface Props {
  useEnvelopeInstance: UseEnvelopeHook;
}

function SendToDocusignBanner({ useEnvelopeInstance }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const { sendEnvelope } = useEnvelopeInstance;

  const onSendEnvelope = async () => {
    setLoading(true);
    try {
      await sendEnvelope();
    } catch (e) {}
    setLoading(false);
  };

  return (
    <Card sx={{ background: theme.palette.primary.main, color: "white", padding: 2 }}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center" gap={2}>
        <Grid item>
          <Grid container gap={1} flexWrap="nowrap">
            <Grid item>
              <IconBrandTelegram size={36} style={{ color: "inherit" }} />
            </Grid>
            <Grid item container gap={1} direction="column">
              <Typography color="inherit" variant="h1">
                Send Envelope
              </Typography>
              <Typography color="inherit" variant="h5">
                Everything looks good! Send this envelope <br /> to Docusign to be signed.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ background: "white", borderRadius: 4 }}>
          <LandisButton
            loading={loading}
            onClick={onSendEnvelope}
            sx={{ background: "white", color: theme.palette.primary.main }}
            size="large"
            endIcon={<Check />}
            variant="contained"
            color="inherit"
          >
            Send to Docusign
          </LandisButton>
        </Grid>
      </Grid>
    </Card>
  );
}

export default SendToDocusignBanner;

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { AccountTransactions } from "@/hooks";
import { useState } from "react";
import moment from "moment";
import { DateFormat, displayUsDollars, snakeCaseToDisplay } from "@/utils";
import { Box } from "@mui/material";

interface Column {
  id: "merchantName" | "amountDollars" | "datetime" | "category";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "merchantName", label: "VENDOR", minWidth: 170 },
  { id: "amountDollars", label: "AMOUNT", minWidth: 100 },
  {
    id: "datetime",
    label: "DATE",
    minWidth: 170,
    align: "right",
  },
  {
    id: "category",
    label: "CATEGORY",
    minWidth: 170,
    align: "right",
  },
];

interface Props {
  accountTransactions: AccountTransactions;
}

function AccountsTransactionsTable({ accountTransactions }: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountTransactions.transactions.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Box display="flex" justifyContent="center">
                    <DataThresholdingIcon /> <span>No transaction history for this account.</span>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              accountTransactions.transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={transaction.extTransactionId}>
                    <TableCell align="left">{transaction.merchantName || transaction.name}</TableCell>
                    <TableCell align="right">{displayUsDollars(transaction.amountDollars)}</TableCell>
                    <TableCell align="right">{moment(transaction.date).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                    <TableCell align="right">{snakeCaseToDisplay(transaction.personalFinanceCategory?.primary) || "Unknown"}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={accountTransactions.transactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default AccountsTransactionsTable;

import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { GRID_SPACING } from "@/config";
import { ExitPlan, UtilitiesStatusOptions } from "@/types";
import { Dropdown, LandisButton } from "@/components/base";
import { Check, Close } from "@mui/icons-material";
import { useState } from "react";
import { UseExitPlan, useToast } from "@/hooks";
import { FormHelpers, mapUnknownToError } from "@/utils";
import { Field, UtilitiesStatuses } from "./ExitPlanFormTypes";
import * as Yup from "yup";
import { useFormik } from "formik";

interface Props {
  exitPlan?: Partial<ExitPlan>;
  onCancel: () => void;
  upsertExitPlan: UseExitPlan["upsertExitPlan"];
}

function CoachExitPlanForm({ exitPlan, onCancel, upsertExitPlan }: Props) {
  const [upsertLoading, setUpsertLoading] = useState(false);

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    scheduledMoveOutDate: Yup.date().optional().nullable().typeError("Scheduled Move Out Date must be a valid date"),
    vacancyConfirmedDate: Yup.date().optional().nullable().typeError("Confirmed Move Out Date must be a valid date"),
    referredToEvictionDate: Yup.date().optional().nullable().typeError("Referred to Eviction Date must be a valid date"),
    utilitiesOn: Yup.string().optional().nullable().typeError("Select option from the dropdown"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      id: exitPlan?.id ?? null,
      created: exitPlan?.created ?? null,
      scheduledMoveOutDate: exitPlan?.scheduledMoveOutDate ?? null,
      vacancyConfirmedDate: exitPlan?.vacancyConfirmedDate ?? null,
      referredToEvictionDate: exitPlan?.referredToEvictionDate ?? null,
      utilitiesOn: exitPlan?.utilitiesOn ?? ("" as UtilitiesStatusOptions),
    },
    validationSchema,
    onSubmit: async (values) => {
      setUpsertLoading(true);
      try {
        await upsertExitPlan(values);
        onCancel();
      } catch (e) {
        const error = mapUnknownToError(e);
        addToast({ message: error.message, type: "error" });
      } finally {
        setUpsertLoading(false);
      }
      close();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid style={{ paddingTop: 12 }} item xs={12} container spacing={GRID_SPACING}>
        {exitPlan?.id && (
          <>
            <Grid item xs={12}>
              <DatePicker
                label="Expected Move Out Date"
                value={formik.values.scheduledMoveOutDate}
                onChange={(val) => formik.setFieldValue(Field.SCHEDULED_MOVE_OUT_DATE, val)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!!formik.errors.scheduledMoveOutDate}
                    helperText={FormHelpers.formikErrorMessage(formik, Field.SCHEDULED_MOVE_OUT_DATE)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Confirmed Move Out Date"
                value={formik.values.vacancyConfirmedDate}
                onChange={(val) => formik.setFieldValue(Field.VACANCY_CONFIRMED_DATE, val)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!!formik.errors.vacancyConfirmedDate}
                    helperText={FormHelpers.formikErrorMessage(formik, Field.VACANCY_CONFIRMED_DATE)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                items={UtilitiesStatuses}
                label="Utilities On?"
                id="utilitiesOn"
                fullWidth
                value={formik.values[Field.UTILITIES_ON]}
                handleChange={(val) => formik.setFieldValue(Field.UTILITIES_ON, val)}
                error={!!formik.errors.utilitiesOn}
                helperText={formik.errors.utilitiesOn}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Referred To Eviction Date"
                value={formik.values.referredToEvictionDate}
                onChange={(val) => formik.setFieldValue(Field.REFERRED_TO_EVICTION_DATE, val)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!!formik.errors.referredToEvictionDate}
                    helperText={FormHelpers.formikErrorMessage(formik, Field.REFERRED_TO_EVICTION_DATE)}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid container item xs={12} spacing={GRID_SPACING}>
          <Grid item xs={6}>
            <LandisButton
              startIcon={<Close />}
              disableElevation
              size="large"
              color="inherit"
              onClick={onCancel}
              fullWidth
              variant="outlined"
            >
              Cancel
            </LandisButton>
          </Grid>
          <Grid item xs={6}>
            <LandisButton
              disableElevation
              type="submit"
              loading={upsertLoading}
              startIcon={<Check />}
              size="large"
              fullWidth
              variant="contained"
            >
              Save
            </LandisButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default CoachExitPlanForm;

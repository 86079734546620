import { useProperty, useSearchParamsState } from "@/hooks";
import { SearchParamKeys } from "@/types";
import { TabContext, TabPanel } from "@mui/lab";
import { Grid } from "@mui/material";
import { PropertyTab } from "./constants";
import { PropertyHeader } from "./PropertyHeader";
import PropertyOverview from "./PropertyOverview";
import PropertyManagement from "./PropertyManagement";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ManualBreadcrumbs } from "@/components/base";
import PaymentInformation from "./PaymentInformation";

function PropertyDetail() {
  const { propertyId } = useParams();
  const [propertyTabValue, setPropertyTabValue] = useSearchParamsState<PropertyTab>(SearchParamKeys.PROPERTY_TAB, PropertyTab.OVERVIEW);

  const usePropertyInstance = useProperty(propertyId);

  const { property } = usePropertyInstance;

  useEffect(() => {
    if (!propertyTabValue) setPropertyTabValue(PropertyTab.OVERVIEW, { replace: true });
  }, [propertyTabValue]);

  if (!propertyTabValue) return null;

  return (
    <TabContext value={propertyTabValue}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ManualBreadcrumbs
            title={property?.addressStreet || ""}
            links={[
              {
                title: "All Properties",
                url: "/admin/properties",
              },
              {
                title: property?.addressStreet,
                url: `/admin/properties/${propertyId}`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PropertyHeader usePropertyInstance={usePropertyInstance} propertyId={propertyId} />
        </Grid>
        <Grid item xs={12}>
          <TabPanel sx={{ padding: 0 }} value={PropertyTab.OVERVIEW}>
            <PropertyOverview usePropertyInstance={usePropertyInstance} />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value={PropertyTab.MANAGEMENT}>
            <PropertyManagement propertyId={propertyId} />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value={PropertyTab.PAYMENTS}>
            <PaymentInformation propertyId={propertyId} />
          </TabPanel>
        </Grid>
      </Grid>
    </TabContext>
  );
}

export default PropertyDetail;

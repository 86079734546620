import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { Goal, TypeOfGoal } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

interface CreateGoalInput {
  typeName: string;
  goalTitle: string;
  goalDescription: string;
  creator?: string;
  currentValue: string;
  targetValue: string;
  showToClient: string;
  status: string;
}

export interface UpdateGoalInput {
  id: string;
  typeName: string;
  goalTitle: string;
  goalDescription: string;
  updater?: string;
  currentValue: string;
  targetValue: string;
  showToClient: string;
  status: string;
}

export interface UseGoals {
  loadGoals: () => Promise<void>;
  loadTypesOfGoals: () => Promise<void>;
  goals: Array<Goal>;
  typesOfGoals: Array<TypeOfGoal>;
  isLoading: boolean;
  isLoadingTypesOfGoals: boolean;
  createGoal: (input: CreateGoalInput) => Promise<void>;
  createInitialGoals: (mortgageType: string) => Promise<void>;
  updateGoal: (input: UpdateGoalInput) => Promise<void>;
  goalsCheckin: (input: Array<UpdateGoalInput>, checkinId: string) => Promise<void>;
  deactivateGoal: (goalId: string) => Promise<void>;
}

export const useGoals = (programClientId: string): UseGoals => {
  const [goals, setGoals] = useState<Array<Goal>>([]);
  const [typesOfGoals, setTypesOfGoals] = useState<Array<TypeOfGoal>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTypesOfGoals, setIsLoadingTypesOfGoals] = useState<boolean>(false);

  const loadGoals = async () => {
    if (!programClientId || Boolean(goals?.length)) return;
    try {
      setIsLoading(true);
      const response = await bridgeV2ApiInstance.get<Array<Goal>>(routes.GET_GOALS_BY_PROGRAM_CLIENT_ID(programClientId));
      setGoals(response.data.filter((goal) => goal.isActive));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in load.");
    }
    setIsLoading(false);
  };

  const loadTypesOfGoals = async () => {
    try {
      setIsLoadingTypesOfGoals(true);
      const response = await bridgeV2ApiInstance.get<Array<TypeOfGoal>>(routes.GET_TYPES_OF_GOALS);
      setTypesOfGoals(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in load.");
    }
    setIsLoadingTypesOfGoals(false);
  };

  const createGoal = async (input: CreateGoalInput) => {
    if (!programClientId) return;
    try {
      const response = await bridgeV2ApiInstance.post<Goal>(routes.CREATE_GOAL(programClientId), input);
      setGoals((existingGoals) => [...existingGoals, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in create.");
    }
  };

  const createInitialGoals = async (mortgageType: string) => {
    if (!programClientId) return;
    const input = { programClientId, mortgageType };
    try {
      const response = await bridgeV2ApiInstance.post<Array<Goal>>(routes.CREATE_INITIAL_GOALS(programClientId), {
        ...input,
      });
      setGoals((existingGoals) => existingGoals.concat(response.data));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in create initial.");
    }
  };

  const updateGoal = async (input: UpdateGoalInput) => {
    if (!input.id) return;
    try {
      const response = await bridgeV2ApiInstance.put<Goal>(routes.UPDATE_GOAL(input.id), input);
      setGoals((existingGoals) => existingGoals.map((goal) => (goal.id === response.data.id ? { ...goal, ...response.data } : goal)));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in update.");
    }
  };

  const deactivateGoal = async (goalId: string) => {
    if (!goalId) return;
    try {
      await bridgeV2ApiInstance.put<Goal>(routes.DEACTIVATE_GOAL(goalId));
      setGoals((existingGoals) => existingGoals.filter((goal) => goal.id !== goalId));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error deactivating goal.");
    }
  };

  const goalsCheckin = async (input: Array<UpdateGoalInput>, checkinId: string) => {
    try {
      const goalsCheckinPayload = {
        goals: input,
        checkinId,
      };
      const response = await bridgeV2ApiInstance.put<Array<Goal>>(routes.GOALS_CHECKIN, goalsCheckinPayload);
      setGoals(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in check-in.");
    }
  };

  return {
    goals,
    typesOfGoals,
    isLoading,
    isLoadingTypesOfGoals,
    loadGoals,
    loadTypesOfGoals,
    createGoal,
    createInitialGoals,
    updateGoal,
    goalsCheckin,
    deactivateGoal,
  };
};

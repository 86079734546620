import { useSearchParamsState } from "@/hooks";
import { Tab, Tabs } from "@mui/material";
import { ApplicationTab, tabItems } from "./constants";
import { SearchParamKeys } from "@/types";

function ApplicationHeader() {
  const [propertyTabValue, setApplicationTabValue] = useSearchParamsState<ApplicationTab>(SearchParamKeys.APPLICATION_TAB);

  return (
    <>
      <Tabs value={propertyTabValue} indicatorColor="primary" textColor="primary" variant="scrollable">
        {tabItems.map((tabItem) => (
          <Tab key={tabItem.value} onClick={() => setApplicationTabValue(tabItem.value)} label={tabItem.label} value={tabItem.value} />
        ))}
      </Tabs>
    </>
  );
}

export default ApplicationHeader;

import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { displayUsCents, formatPercentage } from "@/utils";
import ClientPropertyFinanceTable from "../ClientPropertyFinanceTable";
import { ClientPropertyFinanceRow } from "../types";
import ClientPropertyFinanceTableHeader from "../ClientPropertyFinanceTableHeader";
import FinancialRatiosForm from "./FinancialRatiosForm";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const tableRows: Array<ClientPropertyFinanceRow> = [
  {
    label: "Purchase vs. Asking",
    fieldKey: "purchaseVsAsking",
    displayFn: formatPercentage,
  },
  {
    label: "Purchase vs. Appraisals",
    fieldKey: "purchaseVsAppraisals",
    displayFn: formatPercentage,
  },
  {
    label: "Tenants HOC After a Year",
    fieldKey: "tenantsHocAfterYearCents",
    displayFn: displayUsCents,
  },
  {
    label: "NOI",
    fieldKey: "noiCents",
    displayFn: displayUsCents,
  },
  {
    label: "Gross Yield",
    fieldKey: "grossYield",
    displayFn: formatPercentage,
  },
  {
    label: "Cap Rate",
    fieldKey: "capRate",
    displayFn: formatPercentage,
  },
  {
    label: "Insurance Percent of Price",
    fieldKey: "insurancePercentPrice",
    displayFn: formatPercentage,
  },
];

const FinancialRatiosCard = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const isFormOpenStateInstance = useState<boolean>(false);
  const [, setIsFormOpen] = isFormOpenStateInstance;

  return (
    <ClientPropertyFinanceTable
      title={
        <ClientPropertyFinanceTableHeader
          FormElement={
            <FinancialRatiosForm
              handleClose={() => setIsFormOpen(false)}
              useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
            />
          }
          isFormOpenStateInstance={isFormOpenStateInstance}
          title="Financial Ratios"
        />
      }
      tableRows={tableRows}
      useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
    />
  );
};

export default FinancialRatiosCard;

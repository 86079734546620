import { LinearProgress, Link } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { useInspectionReportURL } from "@/hooks";
import { useEffect } from "react";

interface Props {
  inspectionReportId: string;
}
const InspectionReportLink: React.FC<Props> = ({ inspectionReportId }) => {
  const { isLoading, error, inspectionReportURL, loadInspectionReportUrl } = useInspectionReportURL(inspectionReportId);

  useEffect(() => {
    loadInspectionReportUrl();
  }, []);

  return isLoading ? (
    <LinearProgress color="secondary" />
  ) : error ? (
    <>
      <Warning />
      {error.message}
    </>
  ) : (
    <Link href={inspectionReportURL} target="_blank">
      Report
    </Link>
  );
};

export default InspectionReportLink;

import { ClientPersonHeader } from "@/components/partial";
import ClientJourney from "@/components/partial/ClientMilestoneJourney/ClientJourney";
import { useClientJourney } from "@/hooks/data-hooks/milestones";

import { Person, ProgramClient } from "@/types";
import { Box } from "@mui/system";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientMilestones({ person, programClient }: Props) {
  const { clientJourney, loading } = useClientJourney(programClient.id);

  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box sx={{ marginBottom: "12px" }} />
      {loading ? "Loading..." : !clientJourney?.id ? "No client journey" : <ClientJourney clientJourney={clientJourney} />}
    </>
  );
}

import { LandisButton } from "@/components/base";
import { Chip, MainCard } from "@/components/berry";
import { UseTemplateFieldsHook } from "@/hooks";
import { TemplateFieldAudienceDisplayMap, TemplateFieldInTemplate, TemplateFieldTypeDisplayMap } from "@/types";
import { Delete } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";

interface Props {
  templateField: TemplateFieldInTemplate;
  templateFieldsHookInstance: UseTemplateFieldsHook;
  handleClose: () => void;
}

function EditTemplateFieldForm({ templateField, templateFieldsHookInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { removeTemplateField } = templateFieldsHookInstance;

  const onRemoveTemplateField = async () => {
    setLoading(true);
    try {
      await removeTemplateField(templateField.id);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container direction="column" spacing={1} sx={{ width: 500, maxWidth: "85vw" }}>
      <Grid item>
        <MainCard>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h2">{templateField.templateFieldName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" sx={{ fontWeight: "normal" }}>
                {templateField.templateFieldDescription}
              </Typography>
            </Grid>
            <Grid item>
              <Chip label={TemplateFieldTypeDisplayMap[templateField.templateFieldType]} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{templateField.isRequired ? "Required" : "Not required"}</Typography>
            </Grid>
            {templateField.templateFieldAudience && (
              <Grid item>
                <Typography variant="body1">
                  Party Completing Field: {TemplateFieldAudienceDisplayMap[templateField.templateFieldAudience]}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1">Page: {templateField.page + 1}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">X Position: {templateField.xCoord}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">Y Position: {templateField.yCoord}</Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <Grid item>
        <LandisButton loading={loading} onClick={onRemoveTemplateField} color="error" variant="contained" fullWidth endIcon={<Delete />}>
          Remove from Template
        </LandisButton>
      </Grid>
    </Grid>
  );
}

export default EditTemplateFieldForm;

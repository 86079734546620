import { Dropdown, LandisButton, SearchableDropdown } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseTaxes, UseTaxesPaymentDetails, useToast } from "@/hooks";
import { FrequencyOfPayments, TaxesPaymentDetails } from "@/types";
import { DateFormat, FormHelpers } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Field, TypeOfTaxDisplayMap } from "./useTaxPaymentDetailsForm";
import { DatePicker } from "@mui/x-date-pickers";
import { PaymentFrequencyOptions } from "../../useDetailForms";
import * as Yup from "yup";

interface Props {
  close: () => void;
  taxPaymentDetail?: TaxesPaymentDetails;
  useTaxesPaymentDetailsInstance: UseTaxesPaymentDetails;
  useTaxesInstance: UseTaxes;
  propertyId: string;
}

function TaxPaymentDetailsForm({ taxPaymentDetail, useTaxesInstance, useTaxesPaymentDetailsInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createTaxesPaymentDetails, updateTaxesPaymentDetails } = useTaxesPaymentDetailsInstance;
  const { propertyTaxes } = useTaxesInstance;
  const formTitle = () => (taxPaymentDetail?.id ? "Update tax payment detail" : "Add tax payment detail");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    taxId: Yup.string().ensure().required("Related tax is a required field"),
    paymentFrequency: Yup.string()
      .required("Payment frequency is a required field")
      .typeError("Select payment frequency from the dropdown"),
    initialPaymentDate: Yup.date()
      .required("Initial payment date is a required field")
      .typeError("Initial payment date is a required field"),
    startDate: Yup.date().required("Start date is a required field").typeError("Start date must be a valid date (mm/dd/yyyy)"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      taxId: taxPaymentDetail?.taxId ?? null,
      paymentFrequency: taxPaymentDetail?.paymentFrequency ?? ("" as FrequencyOfPayments),
      initialPaymentDate: taxPaymentDetail?.initialPaymentDate ?? null,
      startDate: taxPaymentDetail?.startDate ?? null,
      endDate: taxPaymentDetail?.endDate ?? null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (taxPaymentDetail) {
          await updateTaxesPaymentDetails({
            ...values,
            id: taxPaymentDetail.id,
          });
        } else {
          await createTaxesPaymentDetails({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
      close();
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item xs={12}>
            <SearchableDropdown
              label="Tax"
              id="add-tax-id-to-payment-detail"
              items={propertyTaxes.map((tax) => ({
                label: TypeOfTaxDisplayMap[tax.type],
                value: tax.id,
              }))}
              handleChange={(newValue) => {
                formik.setFieldValue(Field.TAX_ID, newValue);
              }}
              value={formik.values[Field.TAX_ID]}
              error={!!formik.errors.taxId}
              helperText={formik.errors.taxId}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={PaymentFrequencyOptions}
              label="Payment Frequency"
              id="paymentFrequency"
              fullWidth
              value={formik.values[Field.PAYMENT_FREQUENCY]}
              handleChange={(val) => formik.setFieldValue(Field.PAYMENT_FREQUENCY, val)}
              error={!!formik.errors.paymentFrequency}
              helperText={formik.errors.paymentFrequency}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Initial Payment Date"
              value={formik.values[Field.INITIAL_PAYMENT_DATE]}
              onChange={(val) => formik.setFieldValue(Field.INITIAL_PAYMENT_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="initialPaymentDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.initialPaymentDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.INITIAL_PAYMENT_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Start Date"
              value={formik.values[Field.START_DATE]}
              onChange={(val) => formik.setFieldValue(Field.START_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="startDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.startDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.START_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="End Date"
              value={formik.values[Field.END_DATE]}
              onChange={(val) => formik.setFieldValue(Field.END_DATE, val)}
              renderInput={(params) => <TextField id="endDate" fullWidth {...params} error={!!formik.errors.endDate} />}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default TaxPaymentDetailsForm;

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, Title, CategoryScale, Legend, Tooltip, BarElement, ChartOptions } from "chart.js";
import { PlaidMerchantSortTransactions } from "@l4s/plaid-models";
import { displayUsDollars } from "@/utils";
import { useMemo } from "react";
import { backgroundColorOptions } from "./chartUtils";

const options: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: true,
  indexAxis: "y",
  scales: {
    x: {
      ticks: {
        callback: function (value: string | number) {
          if (typeof value === "number") {
            return displayUsDollars(value);
          } else {
            return "$" + value;
          }
        },
      },
    },
  },
};

interface Props {
  merchantSorts: Array<PlaidMerchantSortTransactions>;
}
const merchantChartSize = 10;

function MerchantSpendingSummaryChart({ merchantSorts }: Props) {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const limitedMerchantSort = merchantSorts.slice(0, merchantChartSize);

  const chartData = useMemo(() => {
    const labels: Array<string> = [];
    const data: Array<number> = [];
    const backgroundColor: Array<string> = [];

    limitedMerchantSort.forEach((merchantRecord) => {
      labels.push(merchantRecord.merchant);
      data.push(merchantRecord.totalAmountSpentDollars);
      backgroundColor.push(backgroundColorOptions[0]);
    });

    return {
      labels,
      datasets: [
        {
          label: "Amount in $",
          data,
          backgroundColor,
        },
      ],
    };
  }, [limitedMerchantSort]);

  return (
    <>
      <Bar data={chartData} options={options} />
    </>
  );
}

export default MerchantSpendingSummaryChart;

import { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";

import { SubCard } from "@/components/berry";
import { GRID_SPACING } from "@/config";
import { Add, Edit } from "@mui/icons-material";

import { ExitPlanForm, ExitPlanDisplay } from "./ExitPlan";
import { UseCashForKeysOffers, UseExitPlan } from "@/hooks";
import { LandisButton, SkeletonList } from "@/components/base";
import { CashForKeys } from "./CashForKeys";
import { ProgramClient } from "@/types";

interface Props {
  useExitPlan: UseExitPlan;
  useCashForKeysOffers: UseCashForKeysOffers;
  programClient: ProgramClient;
}

function CoachExitPlan({ useExitPlan, useCashForKeysOffers, programClient }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const { exitPlan, exitPlanLoading, loadExitPlan, upsertExitPlan } = useExitPlan;

  useEffect(() => {
    loadExitPlan();
  }, []);

  return (
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={12}>
        <SubCard title="Exit Plan">
          {exitPlanLoading && <SkeletonList height={30} count={3} />}
          {!exitPlan && !exitPlanLoading && (
            <Grid item xs={12} md={4} container direction="column">
              {!exitPlan && (
                <>
                  <Typography variant="body1">
                    <i>No exit plan has been created for this client.</i>
                  </Typography>
                  <div>
                    <Button
                      disableElevation
                      onClick={() =>
                        upsertExitPlan({
                          scheduledMoveOutDate: null,
                          vacancyConfirmedDate: null,
                          referredToEvictionDate: null,
                          programClientId: programClient.id,
                        })
                      }
                      style={{ marginTop: "12px" }}
                      startIcon={<Add />}
                      variant="contained"
                    >
                      Add Exit Plan
                    </Button>
                  </div>
                </>
              )}
            </Grid>
          )}
          {exitPlan && !exitPlanLoading && (
            <Grid item xs={12} container spacing={GRID_SPACING}>
              <Grid item xs={12} md={4} container direction="column">
                <Typography style={{ marginBottom: "12px" }} variant="h3" gutterBottom>
                  Move Out Details
                </Typography>
                <Grid container direction="column">
                  {!isEditing ? (
                    <>
                      <ExitPlanDisplay exitPlan={exitPlan} />
                      <div style={{ marginTop: "12px" }}>
                        <LandisButton disableElevation onClick={() => setIsEditing(true)} variant="contained" startIcon={<Edit />}>
                          Edit
                        </LandisButton>
                      </div>
                    </>
                  ) : (
                    <ExitPlanForm onCancel={() => setIsEditing(false)} exitPlan={exitPlan} upsertExitPlan={upsertExitPlan} />
                  )}
                </Grid>
              </Grid>
              <Grid style={{ maxWidth: "40rem" }} container item xs={12} md={8} direction="column">
                <Typography style={{ marginBottom: "12px" }} variant="h3">
                  Cash For Keys
                </Typography>
                <CashForKeys useCashForKeysOffers={useCashForKeysOffers} />
              </Grid>
            </Grid>
          )}
        </SubCard>
      </Grid>
    </Grid>
  );
}

export default CoachExitPlan;

import { Tabs } from "@/components/base";
import { useWorkOrders } from "@/hooks";
import { SearchParamKeys } from "@/types";
import { TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { WorkOrdersList } from "..";
import { tabsOptions, MaintenanceTabOptions } from "./TabOptions";

interface Props {
  programClientId: string;
}

const ClientMaintenanceTabs = ({ programClientId }: Props) => {
  const useWorkOrdersInstance = useWorkOrders(programClientId);

  return (
    <Tabs
      tabsIds={Object.values(MaintenanceTabOptions).map((opt) => opt)}
      urlSearchParamName={SearchParamKeys.MAINTENANCE_TAB}
      tabToStart={MaintenanceTabOptions.WORK_ORDERS}
      orientation="horizontal"
      TabListComponent={
        <>
          {tabsOptions.map((tab, _index) => (
            <Tab key={tab.id} value={tab.id} iconPosition="start" icon={tab.icon} label={tab.label} />
          ))}
        </>
      }
      TabPanelComponent={
        <>
          <TabPanel sx={{ padding: 0 }} value={MaintenanceTabOptions.WORK_ORDERS}>
            <WorkOrdersList useWorkOrdersInstance={useWorkOrdersInstance} />
          </TabPanel>
        </>
      }
    />
  );
};

export default ClientMaintenanceTabs;

import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyHook, UsePropertyHook, useSearchParamsState } from "@/hooks";
import { Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { SearchParamKeys } from "@/types";
import { SkeletonList } from "@/components/base";
import { Link } from "react-router-dom";
import moment from "moment";
import { PropertyEvaluationChipSection } from "@/components/partial";
import { ClientPropertyTab, useClientPropertyDetailTabs } from "../useClientPropertyDetailTabs";

interface Props {
  usePropertyInstance: UsePropertyHook;
  useApplicationsPropertyInstance: UseApplicationsPropertyHook;
}

function ClientPropertyHeader({ usePropertyInstance, useApplicationsPropertyInstance }: Props) {
  const [clientPropertyTabValue, setClientPropertyTabValue] = useSearchParamsState<ClientPropertyTab>(SearchParamKeys.CLIENT_PROPERTY_TAB);
  const theme = useTheme();

  const { property } = usePropertyInstance;
  const { applicationsProperty, loading, error } = useApplicationsPropertyInstance;

  const tabItems = useClientPropertyDetailTabs(applicationsProperty);

  return (
    <MainCard
      contentSX={{
        p: 1.5,
        paddingBottom: "0px !important",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
            <Grid item xs={12} md={6} container direction="column" gap={0.5}>
              {loading && <SkeletonList count={3} height={15} />}
              {applicationsProperty && property && (
                <>
                  <Link to={`/admin/properties/${property.id}`}>
                    <Typography variant="h1">
                      {property.addressStreet} {property.addressUnit}
                    </Typography>
                  </Link>
                  <Typography variant="h3">
                    {property.addressCity}, {property.addressState} {property.addressZip}
                  </Typography>
                  <Typography sx={{ mt: 1 }} variant="body2">
                    #{applicationsProperty.seq}
                  </Typography>
                  {applicationsProperty.dateClose && (
                    <Typography variant="body2">Closed: {moment(applicationsProperty.dateClose).format("MMM Do, YYYY")}</Typography>
                  )}
                </>
              )}
              {error && (
                <Typography color="error">
                  <i>{error}</i>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6} container direction="column" gap={0.5} alignItems="flex-end">
              <PropertyEvaluationChipSection property={property} />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Tabs value={clientPropertyTabValue} indicatorColor="primary" textColor="primary" variant="scrollable">
              {tabItems.map((tabItem) => (
                <Tab
                  key={tabItem.value}
                  icon={tabItem.icon}
                  iconPosition="start"
                  onClick={() => setClientPropertyTabValue(tabItem.value)}
                  label={tabItem.label}
                  value={tabItem.value}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default ClientPropertyHeader;

import React, { useEffect } from "react";
import { setClientMenuItems } from "@/store/slices";

import { useNavigate } from "react-router-dom";
import { usePerson, useToast } from "@/hooks";
import { Person } from "@/types";
import { logToConsoleDebug } from "@/utils";
import { useDispatch } from "@/store";
import { ClientMenuItem } from "@/types/menu";

interface ChildProps {
  person: Person;
}

interface Props {
  ChildComponent: React.ComponentType<ChildProps>;
  personId: string;
}

const WithPerson = ({ personId, ChildComponent }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { person, loadPerson } = usePerson();

  const setClientMenuPerson = (clientMenuItem: ClientMenuItem) => () => {
    dispatch(setClientMenuItems([clientMenuItem]));
  };

  useEffect(() => {
    (async () => {
      try {
        await loadPerson(personId);
      } catch (e) {
        addToast({
          message: "Unable to find client.",
          type: "error",
        });
        navigate("/");
      }
    })();
  }, [personId]);

  useEffect(() => {
    logToConsoleDebug("WithPerson.tsx", "Person loaded", [person]);

    if (person) {
      dispatch(setClientMenuPerson({ name: `${person.nameFirst} ${person.nameLast}`, personId: person.id }));
    }
  }, [person]);

  return person ? <ChildComponent person={person} /> : <></>;
};

export default WithPerson;

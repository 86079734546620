import { bridgeV2ApiInstance } from "@/services";
import { GET_APPLICATIONS_PROPERTY_BY_ID } from "@/services/routes";
import { ApplicationsPropertyDto } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

export interface UseApplicationsPropertyHook {
  loading: boolean;
  applicationsProperty: ApplicationsPropertyDto | undefined;
  error: string | undefined;
}

export const useApplicationsProperty = (applicationsPropertyId: string): UseApplicationsPropertyHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationsProperty, setApplicationsProperty] = useState<ApplicationsPropertyDto>();
  const [error, setError] = useState<string | undefined>(undefined);

  const getApplicationsProperty = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<ApplicationsPropertyDto>(GET_APPLICATIONS_PROPERTY_BY_ID(applicationsPropertyId));
      setApplicationsProperty(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching client property.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (applicationsPropertyId) getApplicationsProperty();
  }, [applicationsPropertyId]);

  return {
    loading,
    applicationsProperty,
    error,
  };
};

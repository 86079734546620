export enum StatusOfPropertyEvaluation {
  NEEDS_REVIEW = "NEEDS_REVIEW",
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
}

export const labelStatusOfPropertyEvaluationMap: Record<StatusOfPropertyEvaluation, string> = {
  [StatusOfPropertyEvaluation.NEEDS_REVIEW]: "Needs Review",
  [StatusOfPropertyEvaluation.ELIGIBLE]: "Eligible",
  [StatusOfPropertyEvaluation.INELIGIBLE]: "Ineligible",
};

export interface PropertyEvaluationStatus {
  id: string;
  created: string;
  status: StatusOfPropertyEvaluation;
  propertyId: string;
  createdByAccountId: string;
}

import { PropertyEvaluationStatus, StatusOfPropertyEvaluation, labelStatusOfPropertyEvaluationMap } from "@/types";
import { Chip, ChipProps } from "@mui/material";

const colorStatusOfPropertyEvaluationMap: Record<
  StatusOfPropertyEvaluation,
  "error" | "warning" | "success" | "default" | "primary" | "secondary" | "info"
> = {
  [StatusOfPropertyEvaluation.NEEDS_REVIEW]: "warning",
  [StatusOfPropertyEvaluation.ELIGIBLE]: "success",
  [StatusOfPropertyEvaluation.INELIGIBLE]: "error",
};

interface Props extends ChipProps {
  propertyEvaluationStatus?: StatusOfPropertyEvaluation;
}

function PropertyEvaluationStatusChip({ propertyEvaluationStatus, ...props }: Props) {
  return (
    <Chip
      color={colorStatusOfPropertyEvaluationMap[propertyEvaluationStatus || StatusOfPropertyEvaluation.NEEDS_REVIEW]}
      label={labelStatusOfPropertyEvaluationMap[propertyEvaluationStatus || StatusOfPropertyEvaluation.NEEDS_REVIEW]}
      {...props}
    />
  );
}

export default PropertyEvaluationStatusChip;

import { Grid } from "@mui/material";
import { MainCard } from "@/components/berry";
import { useFilledTemplateFields, usePersonTemplateAutofillValues, useTemplateFields, useTemplateFillFieldValues } from "@/hooks";
import { Template, TemplateFill } from "@/types";

import { TemplateFillFieldsListSection } from "./TemplateFillFieldsListSection";
import { FillTemplatePDFViewer } from "./FillTemplatePDFViewer";
import { AutofillConfigurationSection } from "./AutofillConfigurationSection";

interface Props {
  envelopeId: string;
  template: Template;
  templateFill: TemplateFill;
  isReadOnly: boolean;
  personId: string;
}

function FillTemplate({ envelopeId, template, templateFill, isReadOnly, personId }: Props) {
  // This is getting all the fields to be displayed in the PDF
  const templateFieldsHookInstance = useTemplateFields(template.id);

  // This is getting the values that have been filled in for the template
  const templateFillFieldValuesInstance = useTemplateFillFieldValues(envelopeId, templateFill.id);

  const useFilledTemplateFieldsInstance = useFilledTemplateFields(templateFillFieldValuesInstance, templateFieldsHookInstance);
  const { filledTemplateFieldsInTemplate } = useFilledTemplateFieldsInstance;

  const usePersonTemplateAutofillValuesInstance = usePersonTemplateAutofillValues(personId, template.id);

  const fixedHeight = "80vh";

  return (
    <MainCard>
      <Grid item xs={12} container direction="row" spacing={2}>
        <Grid item xs={12} md={8} sx={{ overflowY: "scroll", height: fixedHeight, paddingRight: 1 }}>
          <FillTemplatePDFViewer document={template.templateDocument} filledTemplateFieldsInTemplate={filledTemplateFieldsInTemplate} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ overflowY: "scroll", height: fixedHeight, paddingBottom: 20, paddingRight: 2 }}>
          <Grid container direction="column" spacing={1}>
            <AutofillConfigurationSection usePersonTemplateAutofillValuesInstance={usePersonTemplateAutofillValuesInstance} />
            <TemplateFillFieldsListSection
              usePersonTemplateAutofillValuesInstance={usePersonTemplateAutofillValuesInstance}
              useFilledTemplateFieldsInstance={useFilledTemplateFieldsInstance}
              useTemplateFillFieldValuesInstance={templateFillFieldValuesInstance}
              templateFill={templateFill}
              isReadOnly={isReadOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default FillTemplate;

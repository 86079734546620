import { bridgeV2ApiInstance } from "@/services";
import { WorkOrderDto } from "@/types";
import { sortByDate } from "@/utils/sort";
import { useEffect, useState } from "react";

export interface UseWorkOrdersHook {
  workOrders: Array<WorkOrderDto>;
  loading: boolean;
}

export const useWorkOrders = (programClientId: string): UseWorkOrdersHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [workOrders, setWorkOrders] = useState<Array<WorkOrderDto>>([]);

  const getWorkOrders = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<WorkOrderDto>>("/maintenance/work-orders", { params: { programClientId } });
      setWorkOrders(response.data.sort(sortByDate("created", false)));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWorkOrders();
  }, [programClientId]);

  return {
    workOrders,
    loading,
  };
};

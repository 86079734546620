import { AdverseActionReasonType, ClientJourneyDtoModel, JourneyMilestoneDtoModel } from "@/types";
import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { ReactEventHandler } from "react";
import { DEFAULT_PADDING } from "@/config";
import { useAdverseActionDefinitions } from "@/hooks/data-hooks/adverse-actions";
import { MilestoneDefinitionKey } from "@l4s/milestone-models";
import { useApplicationAdverseActions } from "@/hooks/data-hooks/adverse-actions/useApplicationAdverseActions";

interface Props {
  milestone: JourneyMilestoneDtoModel;
  clientJourney: ClientJourneyDtoModel;
  onAdd: () => void;
}

function determineReasonType(milestone: JourneyMilestoneDtoModel): AdverseActionReasonType {
  if (milestone.definition.key === MilestoneDefinitionKey.WITHDRAWN) {
    return AdverseActionReasonType.WITHDRAWN;
  } else {
    return AdverseActionReasonType.INELIGIBLE;
  }
}

const AddAdverseAction = ({ milestone, clientJourney, onAdd }: Props) => {
  const reasonType = determineReasonType(milestone);
  const { adverseActionDefinitions } = useAdverseActionDefinitions(reasonType);
  const { insertingApplicationAdverseAction, insertApplicationAdverseAction } = useApplicationAdverseActions(clientJourney.applicationId);

  const stopPropogration: ReactEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };
  const onSelect = async (adverseActionId: string) => {
    await insertApplicationAdverseAction(adverseActionId);
    onAdd();
  };

  return (
    <div onClick={stopPropogration}>
      <PopupState variant="popover" popupId={`${milestone.id}-popup-menu`}>
        {(popupState) => (
          <>
            <Button
              {...bindTrigger(popupState)}
              startIcon={<PlaylistAddIcon />}
              variant="text"
              color="primary"
              disabled={insertingApplicationAdverseAction}
            >
              Add adverse action
            </Button>
            <Menu {...bindMenu(popupState)}>
              <Typography variant="h5" pl={DEFAULT_PADDING}>
                Add Adverse Action
              </Typography>
              <Divider sx={{ my: 0.5 }} />
              {adverseActionDefinitions?.map((aa) => {
                return (
                  <MenuItem
                    key={aa.id}
                    onClick={async () => {
                      popupState.close();
                      await onSelect(aa.id);
                    }}
                  >
                    {aa.adverseActionReason}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </PopupState>
    </div>
  );
};
export default AddAdverseAction;

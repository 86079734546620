import { LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { CreateDefinedEnvelopeInput, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface Props {
  onCreateDefinedEnvelope: (input: CreateDefinedEnvelopeInput) => Promise<void>;
}

function CreateDefinedEnvelopeForm({ onCreateDefinedEnvelope }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        await onCreateDefinedEnvelope({
          name: values.name,
          description: values.description,
        });
        resetForm();
      } catch (e) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      if (!values.name) {
        errors.name = "You are required to enter a name.";
      }

      if (!values.description) {
        errors.description = "You are required to enter a description.";
      }

      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  return (
    <MainCard title="Add Defined Envelope">
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} direction="column" container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
              size="small"
              label="Defined Envelope Name"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={formik.values.description}
              onChange={(e) => formik.setFieldValue("description", e.target.value)}
              multiline
              minRows={4}
              size="small"
              label="Defined Envelope Description"
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" endIcon={<Check />} size="small" variant="contained">
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default CreateDefinedEnvelopeForm;

import { bridgeV2ApiInstance } from "@/services";
import { DocumentsAutofillResponsePayload } from "@l4s/documents-autofill-models";
import { useEffect, useState } from "react";

export interface UsePersonTemplateAutofillValuesHook {
  autofillValues: Array<DocumentsAutofillResponsePayload>;
  loading: boolean;
}

export const usePersonTemplateAutofillValues = (personId: string, templateId: string): UsePersonTemplateAutofillValuesHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [autofillValues, setAutofillValues] = useState<Array<DocumentsAutofillResponsePayload>>([]);

  const getAutofillValues = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<DocumentsAutofillResponsePayload>>(
        `/template-documents/template-autofills/person/${personId}`,
        {
          params: {
            templateId,
          },
        }
      );
      setAutofillValues(response.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAutofillValues();
  }, [personId, templateId]);

  return {
    autofillValues,
    loading,
  };
};

import { Tab } from "@mui/material";
import { TabPanel } from "@mui/lab";

import { Tabs } from "@/components/base";
import { ApplicationsPropertyDto, InspectionDto, OrderDto, PropertyDto, SearchParamKeys } from "@/types";

import CreateInspectionOrder from "./CreateInspectionOrder";
import PropertyInspectionSummary from "./PropertyInspectionSummary";
import OrderMissingInspectionSummary from "./OrderMissingInspectionSummary";
import { ElementRef, useEffect, useRef } from "react";
import { useOrders } from "@/hooks";
import { DateFormat, snakeCaseToDisplay } from "@/utils";
import { NoteAdd } from "@mui/icons-material";
import { format } from "date-fns";

const CREATE_INSPECTION_ORDER_PANEL_VALUE = "create-inspection-order";

const inspectionToTabName = (inspection: InspectionDto) => {
  return [snakeCaseToDisplay(inspection.status), snakeCaseToDisplay(inspection.inspectionType)].join(" - ");
};

const pendingOrderToTabName = (o: OrderDto) => {
  return ["Pending Order", format(new Date(o.created), DateFormat.DISPLAY_DATE_TIME)].join(" - ");
};

interface Props {
  applicationsProperty: ApplicationsPropertyDto;
  property: PropertyDto;
}

function PropertyInspectionsManage({ applicationsProperty, property }: Props) {
  const tabsRef = useRef<ElementRef<typeof Tabs>>(null);
  const { orders, isLoading, hasFetched, loadOrders } = useOrders(applicationsProperty.id);

  const isAwaitingInspection = isLoading || !hasFetched;
  const tabToStart = orders?.[0]?.id || CREATE_INSPECTION_ORDER_PANEL_VALUE;

  useEffect(() => {
    loadOrders();
  }, []);

  return isAwaitingInspection ? (
    <p>Loading...</p>
  ) : (
    <Tabs
      tabsIds={orders.map((opt) => opt.id).concat([CREATE_INSPECTION_ORDER_PANEL_VALUE])}
      ref={tabsRef}
      urlSearchParamName={SearchParamKeys.PROPERTY_INSPECTIONS_TAB}
      tabToStart={tabToStart}
      orientation="vertical"
      verticalTabListCardTitle="Manage Orders"
      verticalLeftGridItemSize={4}
      TabListComponent={
        <>
          {orders.flatMap((order, _index) => {
            if (order.inspections.length) {
              return order.inspections.map((inspection) => (
                <Tab
                  key={inspection.id}
                  value={inspection.id}
                  sx={{ maxWidth: "100%" }}
                  label={<div>{inspectionToTabName(inspection)}</div>}
                />
              ));
            }

            return <Tab key={order.id} value={order.id} sx={{ maxWidth: "100%" }} label={<div>{pendingOrderToTabName(order)}</div>} />;
          })}
          <Tab
            value={CREATE_INSPECTION_ORDER_PANEL_VALUE}
            icon={<NoteAdd />}
            iconPosition="start"
            sx={{ maxWidth: "100%" }}
            label="Create Inspection Order"
          />
        </>
      }
      TabPanelComponent={
        <>
          <TabPanel value={CREATE_INSPECTION_ORDER_PANEL_VALUE}>
            <CreateInspectionOrder property={property} applicationsProperty={applicationsProperty} onSuccess={loadOrders} />
          </TabPanel>
          {orders.flatMap((order, _index) => {
            if (order.inspections.length) {
              return order.inspections.map((inspection) => (
                <TabPanel sx={{ padding: 0 }} key={inspection.id} value={inspection.id}>
                  <PropertyInspectionSummary inspection={inspection} />
                </TabPanel>
              ));
            }
            return (
              <TabPanel sx={{ padding: 0 }} key={order.id} value={order.id}>
                <OrderMissingInspectionSummary order={order} />
              </TabPanel>
            );
          })}
        </>
      }
    />
  );
}

export default PropertyInspectionsManage;

import { DateFormat } from "@/utils";
import moment from "moment";
import { AccountTransactions } from "@/hooks";
import { default as AccountsTransactionsTable } from "./AccountsTransactionsTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GRID_SPACING } from "@/config";

interface Props {
  startDate: Date;
  endDate: Date;
  accountsTransactions: Array<AccountTransactions>;
}

function AccountsTransactionsTables({ startDate, endDate, accountsTransactions }: Props) {
  const theme = useTheme();
  return (
    <>
      <Box marginBottom={GRID_SPACING / 2}>
        <Typography variant="h3">Transaction Summary by Account</Typography>
        <Typography variant="subtitle2">
          {moment(startDate).format(DateFormat.DISPLAY_DATE_RANGE)} - {moment(endDate).format(DateFormat.DISPLAY_DATE_RANGE)}
        </Typography>
      </Box>
      <Box>
        {accountsTransactions.map((accountTransactions) => {
          return (
            <Accordion key={accountTransactions.plaidAccountId}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <Typography variant="h5">{accountTransactions.plaidAccountName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    bgcolor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.grey[50],
                    border: theme.palette.mode === "dark" ? "none" : "1px solid",
                    borderColor: theme.palette.grey[300],
                  }}
                >
                  <AccountsTransactionsTable accountTransactions={accountTransactions} />
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
}

export default AccountsTransactionsTables;

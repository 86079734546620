import * as Yup from "yup";
import { DropdownOptionItem } from "@/components/base/DropdownOption/DropdownOption";
import { bridgeV2ApiInstance, routes } from "@/services";
import {
  TypeOfInspection,
  TypeOfOrderStakeholder,
  OrderAvailabilityPeriod,
  OrderDto,
  PropertyDto,
  OrderTypeOfHome,
  OrderOccupancy,
  ApplicationsPropertyDto,
  formatPropertyAddress,
} from "@/types";
import { ConfirmOption, confirmToBool, phoneRegExp, snakeCaseToDisplay } from "@/utils";
import { useState } from "react";

export interface InspectionOrderPropertyAddressInput {
  street: string;
  unit?: string | number;
  city: string;
  state: string;
  zip: string;
}

export interface InspectionOrderAvailabilityInput {
  availabilityDate: string;
  period: OrderAvailabilityPeriod;
  isAvailable: boolean;
}

export interface InspectionOrderStakeholderInput {
  stakeholderType: TypeOfOrderStakeholder | undefined;
  name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
}

export interface InspectionOrderPropertyDataInput {
  homeType: OrderTypeOfHome;
  totalSquareFeet: number | undefined;
  bathrooms: number | undefined;
  bedrooms: number | undefined;
  yearBuilt: number | undefined;
  hasPool: boolean | undefined;
  hasGarage: boolean | undefined;
}

interface CreateOrderInput {
  inspectionTypes: Array<TypeOfInspection>;
  propertyAddress: InspectionOrderPropertyAddressInput;
  specialInstructions: string | undefined;
  accessInformation: string | undefined;
  applicationsPropertiesId: string;
  contingencyDate: string | undefined;
  occupancyInfo: OrderOccupancy;
  orderAvailabilities: Array<InspectionOrderAvailabilityInput>;
  orderStakeholders: Array<InspectionOrderStakeholderInput>;
  propertyData: InspectionOrderPropertyDataInput;
}

/*
  Form Types
*/

export enum Field {
  PROPERTY_DATA = "propertyData",
  PROPERTY_DATA__ADDRESS = "propertyDataAddress",
  PROPERTY_DATA__YEAR_BUILT = "propertyDataYearBuilt",
  PROPERTY_DATA__SQFT = "propertyDataSqft",
  PROPERTY_DATA__TYPE_OF_HOME = "propertyDataTypeOfHome",
  INSPECTION_TYPES = "inspectionTypes",
  ORDER_AVAILABILITIES = "orderAvailabilities",
  ORDER_AVAILABILITIES__DATE = "orderAvailabilitiesDate",
  ORDER_AVAILABILITIES__PERIOD = "orderAvailabilitiesPeriod",
  ORDER_AVAILABILITIES__IS_AVAILABLE = "orderAvailabilitiesIsAvailable",
  ORDER_STAKEHOLDERS = "orderStakeholders",
  ORDER_STAKEHOLDERS__TYPE = "orderStakeholdersType",
  ORDER_STAKEHOLDERS__NAME = "orderStakeholdersName",
  ORDER_STAKEHOLDERS__PHONE = "orderStakeholdersPhone",
  ORDER_STAKEHOLDERS__EMAIL = "orderStakeholdersEmail",
  SPECIAL_INSTRUCTIONS = "specialInstructions",
  ACCESS_INFORMATION = "accessInformation",
  CONTINGENCY_DATE = "contingencyDate",
  OCCUPANCY_INFO = "occupancyInfo",
  HAS_POOL = "hasGarage",
  HAS_GARAGE = "hasPool",
}

export const LABEL: Record<Field, string> = {
  [Field.PROPERTY_DATA]: "Property summary",
  [Field.PROPERTY_DATA__ADDRESS]: "Property address",
  [Field.PROPERTY_DATA__YEAR_BUILT]: "Year built",
  [Field.PROPERTY_DATA__SQFT]: "Property square footage",
  [Field.PROPERTY_DATA__TYPE_OF_HOME]: "Type of home",
  [Field.INSPECTION_TYPES]: "Inspection types",
  [Field.ORDER_AVAILABILITIES]: "Availability",
  [Field.ORDER_AVAILABILITIES__DATE]: "Date",
  [Field.ORDER_AVAILABILITIES__PERIOD]: "Period",
  [Field.ORDER_AVAILABILITIES__IS_AVAILABLE]: "Is available",
  [Field.ORDER_STAKEHOLDERS]: "Landis agent",
  [Field.ORDER_STAKEHOLDERS__TYPE]: "Type",
  [Field.ORDER_STAKEHOLDERS__NAME]: "Name",
  [Field.ORDER_STAKEHOLDERS__PHONE]: "Phone",
  [Field.ORDER_STAKEHOLDERS__EMAIL]: "Email",
  [Field.SPECIAL_INSTRUCTIONS]: "Special instructions",
  [Field.ACCESS_INFORMATION]: "Access information",
  [Field.CONTINGENCY_DATE]: "Contingency date",
  [Field.OCCUPANCY_INFO]: "Occupancy Info",
  [Field.HAS_POOL]: "Does the home have a pool?",
  [Field.HAS_GARAGE]: "Does the home have a garage?",
};

interface OrderStakeholderFormInput {
  orderStakeholdersType: TypeOfOrderStakeholder | undefined;
  orderStakeholdersName: string;
  orderStakeholdersPhone: string;
  orderStakeholdersEmail: string;
}

export interface CreateOrderFormInput {
  [Field.PROPERTY_DATA__ADDRESS]: string;
  [Field.PROPERTY_DATA__SQFT]: number;
  [Field.PROPERTY_DATA__YEAR_BUILT]: number;
  propertyDataTypeOfHome: CreateOrderInput["propertyData"]["homeType"];
  inspectionTypes: CreateOrderInput["inspectionTypes"];
  specialInstructions: CreateOrderInput["specialInstructions"];
  accessInformation: CreateOrderInput["accessInformation"];
  contingencyDate: CreateOrderInput["contingencyDate"];
  occupancyInfo: CreateOrderInput["occupancyInfo"] | undefined;
  orderAvailabilities: CreateOrderInput["orderAvailabilities"];
  orderStakeholders: Array<OrderStakeholderFormInput>;
  hasPool: ConfirmOption;
  hasGarage: ConfirmOption;
}

/*
  Type mapping functions
*/

const toInspectionOrderPropertyAddressInput = (p: PropertyDto): InspectionOrderPropertyAddressInput => ({
  street: p.addressStreet,
  unit: p.addressUnit || undefined,
  city: p.addressCity,
  state: p.addressState,
  zip: p.addressZip,
});

const toOrderStakeholderInput = (sh: OrderStakeholderFormInput): InspectionOrderStakeholderInput => ({
  stakeholderType: sh.orderStakeholdersType,
  name: sh.orderStakeholdersName,
  phone: sh.orderStakeholdersPhone,
  email: sh.orderStakeholdersEmail,
});

const toInspectionOrderPropertyDataInput = (
  p: PropertyDto,
  {
    hasGarage,
    hasPool,
    typeOfHome,
  }: {
    hasPool: CreateOrderFormInput["hasPool"];
    hasGarage: CreateOrderFormInput["hasGarage"];
    typeOfHome: CreateOrderFormInput["propertyDataTypeOfHome"];
  }
): InspectionOrderPropertyDataInput => ({
  homeType: typeOfHome,
  totalSquareFeet: p.sqft,
  bathrooms: p.baths,
  bedrooms: p.beds,
  yearBuilt: p.built,
  hasPool: confirmToBool(hasPool),
  hasGarage: confirmToBool(hasGarage),
});

/*
  Form validation
*/
const PROPERTY_ADMIN_ERROR_MESSAGE = "Set this value within the property admin screen.";
export const validationSchema = Yup.object().shape({
  [Field.PROPERTY_DATA__ADDRESS]: Yup.string().typeError(PROPERTY_ADMIN_ERROR_MESSAGE).required(PROPERTY_ADMIN_ERROR_MESSAGE),
  [Field.PROPERTY_DATA__SQFT]: Yup.number().integer().typeError(PROPERTY_ADMIN_ERROR_MESSAGE).min(1).required(PROPERTY_ADMIN_ERROR_MESSAGE),
  [Field.PROPERTY_DATA__YEAR_BUILT]: Yup.number().integer().min(1900).required(PROPERTY_ADMIN_ERROR_MESSAGE),
  [Field.PROPERTY_DATA__TYPE_OF_HOME]: Yup.string().oneOf(Object.values(OrderTypeOfHome)).required("Please make a selection."),
  [Field.INSPECTION_TYPES]: Yup.array()
    .min(1)
    .of(Yup.string().oneOf(Object.values(TypeOfInspection)).required("Please make a selection."))
    .required(),
  [Field.ORDER_AVAILABILITIES]: Yup.array().max(0).required(),
  [Field.ORDER_STAKEHOLDERS]: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        [Field.ORDER_STAKEHOLDERS__TYPE]: Yup.string().oneOf(Object.values(TypeOfOrderStakeholder)).required("Please make a selection."),
        [Field.ORDER_STAKEHOLDERS__NAME]: Yup.string().min(1).required("Required"),
        [Field.ORDER_STAKEHOLDERS__PHONE]: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Required"),
        [Field.ORDER_STAKEHOLDERS__EMAIL]: Yup.string().email("Invalid email").required("Required"),
      })
    )
    .required(),
  [Field.SPECIAL_INSTRUCTIONS]: Yup.string().ensure().required("Required"),
  [Field.ACCESS_INFORMATION]: Yup.string().ensure(),
  [Field.CONTINGENCY_DATE]: Yup.date().min(new Date(), "Date cannot be in the past.").optional(),
  [Field.OCCUPANCY_INFO]: Yup.string().oneOf(Object.values(OrderOccupancy)).required("Please make a selection."),
  [Field.HAS_GARAGE]: Yup.string().oneOf(Object.values(ConfirmOption)).required("Please make a selection."),
  [Field.HAS_POOL]: Yup.string().oneOf(Object.values(ConfirmOption)).required("Please make a selection."),
});

/*
  Form state initialization
*/

export const ORDER_STAKEHOLDER_INITIAL_VALUES = {
  [Field.ORDER_STAKEHOLDERS__TYPE]: undefined,
  [Field.ORDER_STAKEHOLDERS__NAME]: "",
  [Field.ORDER_STAKEHOLDERS__PHONE]: "",
  [Field.ORDER_STAKEHOLDERS__EMAIL]: "",
};

export const ORDER_AVAILABILITY_INITIAL_VALUES = {
  [Field.ORDER_AVAILABILITIES__DATE]: "",
  [Field.ORDER_AVAILABILITIES__PERIOD]: "",
  [Field.ORDER_AVAILABILITIES__IS_AVAILABLE]: "",
};

export const generateInitialValuesFromProperty = (property: PropertyDto): CreateOrderFormInput =>
  Object.freeze({
    [Field.PROPERTY_DATA__ADDRESS]: formatPropertyAddress(property),
    [Field.PROPERTY_DATA__SQFT]: property.sqft,
    [Field.PROPERTY_DATA__YEAR_BUILT]: property.built,
    [Field.PROPERTY_DATA__TYPE_OF_HOME]: OrderTypeOfHome.SINGLE_FAMILY,
    [Field.INSPECTION_TYPES]: [TypeOfInspection.HOME_INSPECTION],
    [Field.ORDER_AVAILABILITIES]: [],
    [Field.ORDER_STAKEHOLDERS]: [ORDER_STAKEHOLDER_INITIAL_VALUES],
    [Field.SPECIAL_INSTRUCTIONS]: "",
    [Field.ACCESS_INFORMATION]: "",
    [Field.CONTINGENCY_DATE]: "",
    [Field.OCCUPANCY_INFO]: undefined,
    [Field.HAS_GARAGE]: ConfirmOption.NO,
    [Field.HAS_POOL]: ConfirmOption.NO,
  });

type InspectionTypeDropDownItem = DropdownOptionItem<TypeOfInspection, string>;
export const INSPECTION_TYPE_OPTIONS: Array<InspectionTypeDropDownItem> = Object.values(TypeOfInspection).map((v) => ({
  value: v,
  data: snakeCaseToDisplay(v),
}));

export const TYPE_OF_HOME_DROPDOWN_ITEMS = Object.values(OrderTypeOfHome).map((opt: OrderTypeOfHome) => ({
  value: opt,
  label: snakeCaseToDisplay(opt).toLowerCase(),
}));
export const ORDER_OCCUPANCY_DROPDOWN_ITEMS = Object.values(OrderOccupancy).map((opt: OrderOccupancy) => ({
  value: opt,
  label: snakeCaseToDisplay(opt).toLowerCase(),
}));

/*
  Form hook
*/

export interface UseInspectionOrdersInstance {
  createInspectionOrder: (input: CreateOrderFormInput) => Promise<OrderDto>;
  creatingInspectionOrder: boolean;
  createInspectinOrderError: Error | undefined;
}

export interface UseInspectionOrders {
  (applicationsProperty: ApplicationsPropertyDto, property: PropertyDto): UseInspectionOrdersInstance;
}

export const useInspectionOrders: UseInspectionOrders = (applicationsProperty, property) => {
  const [creatingInspectionOrder, setCreatingInspectionOrder] = useState<boolean>(false);
  const [createInspectionOrderError, setCreateInspectinOrderError] = useState<Error | undefined>();

  const createInspectionOrder: UseInspectionOrdersInstance["createInspectionOrder"] = async (input) => {
    setCreatingInspectionOrder(true);

    try {
      const propertyDataInput = {
        hasPool: input.hasPool,
        hasGarage: input.hasGarage,
        typeOfHome: input.propertyDataTypeOfHome,
      };
      const formattedInput: CreateOrderInput = {
        // Prefilled
        propertyAddress: toInspectionOrderPropertyAddressInput(property),
        applicationsPropertiesId: applicationsProperty.id,
        // dynamic
        inspectionTypes: input.inspectionTypes,
        specialInstructions: input.specialInstructions || undefined,
        accessInformation: input.accessInformation || undefined,
        contingencyDate: input.contingencyDate,
        occupancyInfo: input.occupancyInfo,
        orderAvailabilities: input.orderAvailabilities,
        orderStakeholders: input.orderStakeholders.map(toOrderStakeholderInput),
        propertyData: toInspectionOrderPropertyDataInput(property, propertyDataInput),
      };
      const response = await bridgeV2ApiInstance.post<OrderDto>(routes.CREATE_INSPECTION, formattedInput);

      return response.data;
    } catch (err: unknown) {
      setCreateInspectinOrderError(err as Error);
      throw err;
    } finally {
      setCreatingInspectionOrder(false);
    }
  };

  return {
    createInspectionOrder,
    creatingInspectionOrder,
    createInspectinOrderError: createInspectionOrderError,
  };
};

import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { AddOutlined, Clear, GpsFixed } from "@mui/icons-material";
import { MainCard } from "@/components/berry";
import { UseTemplateHook, useTemplateFields, useToast } from "@/hooks";
import { SkeletonList } from "@/components/base";
import { pdf } from "@/services";

import { AddTemplateInFieldInput } from "./types";
import { TemplateFieldsList } from "./TemplateFieldsList";
import ConfigureTemplateHeader from "./ConfigureTemplateHeader";
import { AddTemplateFieldForm } from "./AddTemplateFieldForm";
import { ConfigureTemplatePDFViewer } from "./ConfigureTemplatePDFViewer";
import { mapUnknownToError } from "@/utils";
import { sortByDate } from "@/utils/sort";
import moment from "moment";

interface Props {
  useTemplateHookInstance: UseTemplateHook;
}

function ConfigureTemplate({ useTemplateHookInstance }: Props) {
  const [isFieldNamesShown, setIsFieldNamesShown] = useState<boolean>(false);
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [inProgressTemplateField, setInProgressTemplateField] = useState<AddTemplateInFieldInput>();

  const { template, updateAutofillDocument } = useTemplateHookInstance;

  const templateFieldsHookInstance = useTemplateFields(template.id);

  const { templateFields, allTemplateFields, loading: templateFieldsLoading } = templateFieldsHookInstance;

  const isAutofillDocumentSaveRequired = useMemo(() => {
    return (
      !template.autofillReadyDocumentId ||
      moment(allTemplateFields.sort(sortByDate("updated", false))?.[0]?.updated).isAfter(template.autofillReadyDocumentUpdated)
    );
  }, [allTemplateFields, template]);

  const { addToast } = useToast();

  useEffect(() => {
    if (!inProgressTemplateField && isSelectMode) {
      setIsSelectMode(false);
    }
  }, [inProgressTemplateField]);

  const onCancelAdd = () => {
    setIsSelectMode(false);
    setInProgressTemplateField(undefined);
  };

  useEffect(() => {
    onCancelAdd();
  }, [allTemplateFields]);

  const onComposeTemplateDocument = async () => {
    try {
      const response = await fetch(template.templateDocument.url);
      const blob = await response.blob();
      const file = new File([blob], template.templateDocument.name, { type: template.templateDocument.mime });
      const templateFile = await pdf.composeTemplateFile(file, templateFields);
      await updateAutofillDocument(templateFile);
    } catch (e: unknown) {
      console.error(e);
      const error = mapUnknownToError(e);
      addToast({ message: error.message, type: "error" });
    }
  };

  const fixedHeight = "83vh";

  return (
    <MainCard
      title={
        <ConfigureTemplateHeader
          template={template}
          isAutofillDocumentSaveRequired={isAutofillDocumentSaveRequired}
          onComposeTemplateDocument={onComposeTemplateDocument}
          isFieldNamesShown={isFieldNamesShown}
          setIsFieldNamesShown={setIsFieldNamesShown}
        />
      }
    >
      <Grid item xs={12} container direction="row" spacing={2}>
        <Grid item xs={12} md={8} sx={{ overflowY: "scroll", height: fixedHeight, paddingRight: 1 }}>
          <ConfigureTemplatePDFViewer
            document={template.templateDocument}
            isSelectMode={isSelectMode}
            isFieldNamesShown={isFieldNamesShown}
            templateFields={inProgressTemplateField ? [...templateFields, inProgressTemplateField] : templateFields}
            onSelectFieldLocation={(value) => setInProgressTemplateField(value)}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ overflowY: "scroll", height: fixedHeight, paddingBottom: 10, paddingRight: 2 }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <MainCard title="Add Template Field">
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      {!isSelectMode && (
                        <Button onClick={() => setIsSelectMode(true)} fullWidth variant="contained" endIcon={<AddOutlined />}>
                          Add Template Field
                        </Button>
                      )}

                      {isSelectMode && (
                        <Button onClick={onCancelAdd} fullWidth variant="outlined" endIcon={<Clear />}>
                          Cancel
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {isSelectMode && !inProgressTemplateField && (
                    <Grid item xs={12} container sx={{ gap: 0.5 }} flexWrap="nowrap">
                      <GpsFixed fontSize="small" />
                      <Typography variant="h6">Select a location for the template field in the PDF.</Typography>
                    </Grid>
                  )}
                  {isSelectMode && inProgressTemplateField && (
                    <>
                      <Grid item xs={12}>
                        <Divider sx={{ marginY: 1 }} />
                      </Grid>
                      <AddTemplateFieldForm
                        templateFieldLocation={inProgressTemplateField}
                        templateFieldsHookInstance={templateFieldsHookInstance}
                      />
                    </>
                  )}
                </Grid>
              </MainCard>
            </Grid>
            <Grid item>
              <MainCard title="Template Fields">
                {!templateFieldsLoading && <TemplateFieldsList templateFieldsHookInstance={templateFieldsHookInstance} />}
                {templateFieldsLoading && <SkeletonList count={3} />}
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default ConfigureTemplate;

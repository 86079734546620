import { Drawer, LandisButton, SkeletonList } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseEnvelopeRecipientsHook, useToast } from "@/hooks";
import { EnvelopeRecipient, TemplateFieldAudience, TemplateFieldAudienceDisplayMap } from "@/types";
import { CircularProgress, useTheme } from "@mui/material";
import { AddCircleOutline, Check, Delete } from "@mui/icons-material";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface EnvelopeRecipientAudienceSectionProps {
  audience: TemplateFieldAudience;
  envelopeRecipient: EnvelopeRecipient | undefined;
  useEnvelopeRecipientsInstance: UseEnvelopeRecipientsHook;
  isReadOnly: boolean;
}

const EnvelopeRecipientForm = ({ audience, useEnvelopeRecipientsInstance }: EnvelopeRecipientAudienceSectionProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddingEnvelopeRecipient, setIsAddingEnvelopeRecipient] = useState<boolean>(false);

  const { addToast } = useToast();

  const { createEnvelopeRecipient } = useEnvelopeRecipientsInstance;

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
      email: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await createEnvelopeRecipient({ ...values, audience });
      } catch (err) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.name) {
        errors.name = "Please specify an envelope name.";
      }
      if (!values.email) {
        errors.email = "Please specify an email.";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  return (
    <>
      <Grid item xs={12} container direction="column" gap={0.5}>
        <Box sx={{ height: 1 }} />
        <div>
          <LandisButton onClick={() => setIsAddingEnvelopeRecipient(true)} endIcon={<AddCircleOutline />} variant="contained">
            {TemplateFieldAudienceDisplayMap[audience]}
          </LandisButton>
        </div>
      </Grid>
      <Drawer
        anchor="right"
        onClose={() => setIsAddingEnvelopeRecipient(false)}
        open={isAddingEnvelopeRecipient}
        sx={{ width: 500, maxWidth: "85vw" }}
      >
        <Grid container direction="column">
          <MainCard title={`Add ${TemplateFieldAudienceDisplayMap[audience]}`}>
            <form onSubmit={formik.handleSubmit}>
              <Grid spacing={2} container direction="column">
                <Grid item xs={12}>
                  <TextField
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue("name", e.target.value)}
                    size="small"
                    fullWidth
                    label="Name"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formik.values.email}
                    onChange={(e) => formik.setFieldValue("email", e.target.value)}
                    size="small"
                    fullWidth
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LandisButton loading={loading} type="submit" endIcon={<Check fontSize="small" />} variant="contained">
                    Submit
                  </LandisButton>
                </Grid>
              </Grid>
            </form>
          </MainCard>
        </Grid>
      </Drawer>
    </>
  );
};

const EnvelopeRecipientAudienceSection = ({
  audience,
  envelopeRecipient,
  useEnvelopeRecipientsInstance,
  isReadOnly,
}: EnvelopeRecipientAudienceSectionProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const { deleteEnvelopeRecipient } = useEnvelopeRecipientsInstance;

  const onDeleteEnvelopeRecipient = async (id: string) => {
    setLoading(true);
    try {
      await deleteEnvelopeRecipient(id);
    } catch (err) {}
    setLoading(false);
  };

  if (!envelopeRecipient && !isReadOnly)
    return (
      <EnvelopeRecipientForm
        useEnvelopeRecipientsInstance={useEnvelopeRecipientsInstance}
        audience={audience}
        envelopeRecipient={envelopeRecipient}
        isReadOnly={isReadOnly}
      />
    );

  if (!envelopeRecipient && isReadOnly)
    return (
      <Typography variant="body1">
        <i>No {TemplateFieldAudienceDisplayMap[audience]}</i>
      </Typography>
    );

  return (
    <Grid item xs={12} container direction="column" gap={0.5}>
      <Box sx={{ height: 1 }} />
      <Typography variant="body1">
        <b>Name:</b> {envelopeRecipient.name}
      </Typography>
      <Typography variant="body1">
        <b>Email:</b> {envelopeRecipient.email}
      </Typography>
      {!isReadOnly && (
        <>
          <Box sx={{ height: 2 }} />
          <Grid
            sx={{
              cursor: "pointer",
              color: theme.palette.error.dark,
              "&:hover": {
                textDecoration: "underline",
                opacity: 0.75,
              },
            }}
            gap={0.5}
            alignItems="center"
            container
            onClick={() => onDeleteEnvelopeRecipient(envelopeRecipient.id)}
          >
            {loading ? <CircularProgress size={16} /> : <Delete fontSize="small" />}
            <Typography>Remove</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

interface EnvelopeRecipientsProps {
  useEnvelopeRecipientsInstance: UseEnvelopeRecipientsHook;
  isReadOnly?: boolean;
}

const EnvelopeRecipients = ({ useEnvelopeRecipientsInstance, isReadOnly = false }: EnvelopeRecipientsProps) => {
  const { envelopeRecipients, loading } = useEnvelopeRecipientsInstance;

  return (
    <MainCard
      title={
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Recipients</Typography>
        </Grid>
      }
    >
      <Grid container item xs={12} direction="column" gap={1}>
        <Grid item xs={12} container spacing={1} flexWrap="nowrap">
          {loading && !!!envelopeRecipients.length && <SkeletonList count={1} height={30} />}
          {!loading &&
            Object.values(TemplateFieldAudience).map((audience) => (
              <Grid key={audience} item xs={4}>
                <Grid item container xs={12} direction="column" gap={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{TemplateFieldAudienceDisplayMap[audience]}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <EnvelopeRecipientAudienceSection
                      isReadOnly={isReadOnly}
                      key={audience}
                      audience={audience}
                      envelopeRecipient={envelopeRecipients.find((envelopeRecipient) => envelopeRecipient.audience === audience)}
                      useEnvelopeRecipientsInstance={useEnvelopeRecipientsInstance}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default EnvelopeRecipients;

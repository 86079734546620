import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { CashForKeysOffer } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

interface CreateCashForKeysOfferInput {
  offerDate: string;
  amountOfferedCents: number;
}

interface UpdateCashForKeysOfferInput {
  id: string;
  offerDate: string;
  amountOfferedCents: number;
  isActive?: boolean;
  acceptedDate?: string;
  rejectedDate?: string;
}

export interface UseCashForKeysOffers {
  loadCashForKeysOffers: () => Promise<void>;
  cashForKeysOffers: Array<CashForKeysOffer>;
  isLoading: boolean;
  createCashForKeysOffer: (input: CreateCashForKeysOfferInput) => Promise<void>;
  updateCashForKeysOffer: (input: UpdateCashForKeysOfferInput) => Promise<void>;
}

export const useCashForKeysOffers = (exitPlanId?: string): UseCashForKeysOffers => {
  const [cashForKeysOffers, setCashForKeysOffers] = useState<Array<CashForKeysOffer>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadCashForKeysOffers = async () => {
    if (!exitPlanId || Boolean(cashForKeysOffers?.length)) return;
    try {
      setIsLoading(true);
      const response: { data: Array<CashForKeysOffer> } = await bridgeV2ApiInstance.get(routes.GET_CASH_FOR_KEYS_OFFERS(exitPlanId));
      setCashForKeysOffers(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching cash for keys offers.");
    }
    setIsLoading(false);
  };

  const createCashForKeysOffer = async (input: CreateCashForKeysOfferInput) => {
    if (!exitPlanId) return;
    try {
      const response: { data: CashForKeysOffer } = await bridgeV2ApiInstance.post(routes.CREATE_CASH_FOR_KEYS_OFFER, {
        ...input,
        exitPlanId,
      });
      setCashForKeysOffers((existingOffers) => [...existingOffers, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching cash for keys offers.");
    }
  };

  const updateCashForKeysOffer = async (input: UpdateCashForKeysOfferInput) => {
    if (!exitPlanId) return;
    try {
      const response: { data: CashForKeysOffer } = await bridgeV2ApiInstance.put(routes.CREATE_CASH_FOR_KEYS_OFFER, input);
      setCashForKeysOffers((existingOffers) =>
        existingOffers.map((offer) => (offer.id === response.data.id ? { ...offer, ...response.data } : offer))
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching cash for keys offers.");
    }
  };

  return {
    cashForKeysOffers,
    isLoading,
    loadCashForKeysOffers,
    createCashForKeysOffer,
    updateCashForKeysOffer,
  };
};

import { Tabs } from "@/components/base";
import { UseWorkOrdersHook } from "@/hooks";
import { WorkOrderStatus } from "@/types";
import { TabPanel } from "@mui/lab";
import { Checkbox, FormControlLabel, FormGroup, Grid, Skeleton, Tab, Typography } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import WorkOrderCard from "./WorkOrderCard";
import WorkOrderSeverityChip from "./WorkOrderSeverityChip";
import WorkOrderStatusChip from "./WorkOrderStatusChip";

interface Props {
  useWorkOrdersInstance: UseWorkOrdersHook;
}

const WorkOrdersList = ({ useWorkOrdersInstance }: Props) => {
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { workOrders, loading } = useWorkOrdersInstance;

  const workOrdersToDisplay = useMemo(
    () =>
      showInactive
        ? workOrders
        : workOrders.filter((wo) => wo.status !== WorkOrderStatus.CANCELLED && wo.status !== WorkOrderStatus.COMPLETED),
    [showInactive, workOrders]
  );

  if (loading) return <Skeleton height={100} />;

  if (!workOrdersToDisplay.length) return <Typography variant="h6">No Work Orders</Typography>;

  return (
    <Grid container direction="column">
      <div style={{ width: "fit-content" }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox value={showInactive} onChange={(e) => setShowInactive(!showInactive)} />}
            label="Show Completed & Cancelled"
          />
        </FormGroup>
      </div>
      <Tabs
        tabsIds={workOrdersToDisplay.map((opt) => opt.id)}
        tabToStart={workOrdersToDisplay[0].id}
        orientation="vertical"
        verticalLeftGridItemSize={4}
        TabListComponent={
          <>
            {workOrdersToDisplay.map((workOrder, _index) => (
              <Tab
                key={workOrder.id}
                value={workOrder.id}
                iconPosition="start"
                label={
                  <Grid container direction="column">
                    <Grid item container justifyContent="space-between" flexWrap="nowrap">
                      <Typography variant="h4" sx={{ marginBottom: 1 }}>
                        {workOrder.name}
                      </Typography>
                      <WorkOrderSeverityChip sx={{ marginLeft: 1 }} hideLabel severity={workOrder.severity} />
                    </Grid>
                    <Typography sx={{ marginBottom: 1 }}>{moment(workOrder.created).format("MMM Do, YYYY h:mma")}</Typography>
                    <div>
                      <WorkOrderStatusChip status={workOrder.status} />
                    </div>
                  </Grid>
                }
              />
            ))}
          </>
        }
        TabPanelComponent={
          <>
            {workOrdersToDisplay.map((workOrder) => (
              <TabPanel key={workOrder.id} sx={{ padding: 0 }} value={workOrder.id}>
                <WorkOrderCard workOrder={workOrder} />
              </TabPanel>
            ))}
          </>
        }
      />
    </Grid>
  );
};

export default WorkOrdersList;

import { bridgeV2ApiInstance, routes } from "@/services";
import { ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseProgramClient {
  programClient: ProgramClient | null;
  getProgramClient: () => Promise<void>;
  loadingProgramClient: boolean;
}

export const useProgramClient = (programClientId: string): UseProgramClient => {
  const [programClient, setProgramClient] = useState<ProgramClient>(null);
  const [loadingProgramClient, setLoadingProgramClient] = useState<boolean>(false);

  const getProgramClient = async () => {
    try {
      setLoadingProgramClient(true);
      setProgramClient(undefined);
      const { data } = await bridgeV2ApiInstance.get<ProgramClient>(routes.GET_PROGRAM_CLIENT_BY_ID(programClientId));
      setProgramClient(data);
    } catch (e) {
      console.error(e);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error get program client by id.");
    } finally {
      setLoadingProgramClient(false);
    }
  };

  return {
    programClient,
    loadingProgramClient,
    getProgramClient,
  };
};

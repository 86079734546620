import { CreateClientNote } from "@/components/partial";
import { useCoachingArticles, useToast } from "@/hooks";
import { useEffect } from "react";

function ClientManageNote() {
  const { addToast } = useToast();

  const { coachingArticles, isLoading, loadCoachingArticles } = useCoachingArticles();

  useEffect(() => {
    loadCoachingArticles();
  }, []);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <div>
      <CreateClientNote
        coachingArticles={coachingArticles}
        isBulk={true}
        onFormSubmission={() => {
          addToast({ message: "Created new notes for Clients" });
        }}
        showCloseButton={false}
      />
    </div>
  );
}

export default ClientManageNote;

import { ClientPersonHeader } from "@/components/partial";
import { CircularProgress } from "@mui/material";
import { Person, ProgramClient } from "@/types";
import { usePlaidAccountsBalances, usePlaidCategorySorts, usePlaidMerchantSorts, usePlaidAccountsTransactions } from "@/hooks";
import { Box } from "@mui/system";
import AccountBalancesGraph from "./AccountBalancesGraph";
import moment from "moment";
import { DateFormat } from "@/utils";
import { Grid } from "@mui/material";
import { GRID_SPACING } from "@/config";
import CategorySpendingChart from "./CategorySpendingChart";
import { MainCard } from "@/components/berry";
import MerchantSpendingSummaryChart from "./MerchantSpendingSummaryChart";
import { default as AccountsTransactionsTables } from "./AccountsTransactionsTables";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

function ClientFinancialInsights({ person, programClient }: Props) {
  const startOfThisMonthMoment = moment().startOf("month");
  const startOfMonthTwoMonthsAgoMoment = moment().startOf("month").subtract(2, "month");
  const todayMoment = moment();
  const startOfMonthTwoMonthsAgoDate = startOfMonthTwoMonthsAgoMoment.toDate();
  const todayDate = todayMoment.toDate();
  const startOfThisMonthDate = startOfThisMonthMoment.toDate();

  const accountBalancesStartDate = startOfMonthTwoMonthsAgoDate;
  const accountBalancesEndDate = todayDate;
  const {
    loading: accountsBalancesLoading,
    result: accountsBalances,
    error: accountsBalancesError,
  } = usePlaidAccountsBalances(person.accountId, accountBalancesStartDate, accountBalancesEndDate);
  const accountBalancesSubtitle = `${moment(accountBalancesStartDate).format(DateFormat.MONTH_YEAR_WRITTEN)} - ${moment(
    accountBalancesEndDate
  ).format(DateFormat.MONTH_YEAR_WRITTEN)}`;

  const merchantSortStartDate = startOfThisMonthDate;
  const merchantSortEndDate = todayDate;
  const {
    loading: merchantSortsLoading,
    result: merchantSorts,
    error: merchantSortsError,
  } = usePlaidMerchantSorts(person.accountId, merchantSortStartDate, merchantSortEndDate);
  const merchantSortSubtitle = moment(merchantSortStartDate).format(DateFormat.MONTH_YEAR_WRITTEN);

  const categorySortStartDate = startOfThisMonthDate;
  const categorySortEndDate = todayDate;
  const {
    loading: categorySortsLoading,
    result: categorySorts,
    error: categorySortsError,
  } = usePlaidCategorySorts(person.accountId, categorySortStartDate, categorySortEndDate);
  const categorySortSubtitle = moment(categorySortStartDate).format(DateFormat.MONTH_YEAR_WRITTEN);

  const transactionsStartDate = startOfMonthTwoMonthsAgoDate;
  const transactionsEndDate = todayDate;
  const {
    loading: accountsTransactionsLoading,
    result: accountsTransactions,
    error: accountsTransactionsError,
  } = usePlaidAccountsTransactions(person.accountId, transactionsStartDate, transactionsEndDate);

  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box paddingTop={GRID_SPACING}>
        <Grid container spacing={GRID_SPACING} alignItems="stretch">
          <Grid item md={12} lg={6}>
            <MainCard title="Account balances" secondary={accountBalancesSubtitle} sx={{ height: "100%" }}>
              {accountsBalancesLoading ? (
                <CircularProgress />
              ) : accountsBalancesError ? (
                <p>Error {accountsBalancesError}</p>
              ) : (
                <AccountBalancesGraph startDate={accountBalancesStartDate} endDate={accountBalancesEndDate} accounts={accountsBalances} />
              )}
            </MainCard>
          </Grid>
          <Grid item md={12} lg={6}>
            <MainCard title="Merchant spending" secondary={merchantSortSubtitle} sx={{ height: "100%" }}>
              {merchantSortsLoading ? (
                <CircularProgress />
              ) : merchantSortsError ? (
                <p>Error {accountsBalancesError}</p>
              ) : (
                <MerchantSpendingSummaryChart merchantSorts={merchantSorts} />
              )}
            </MainCard>
          </Grid>
          <Grid item md={12} lg={6}>
            <MainCard title="Category spending" secondary={categorySortSubtitle} sx={{ height: "100%" }}>
              {categorySortsLoading ? (
                <CircularProgress />
              ) : categorySortsError ? (
                <p>Error {accountsBalancesError}</p>
              ) : (
                <CategorySpendingChart categorySorts={categorySorts} />
              )}
            </MainCard>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={GRID_SPACING}>
        <Grid container spacing={GRID_SPACING} alignItems="stretch">
          <Grid item xs={12}>
            {accountsTransactionsLoading ? (
              <CircularProgress />
            ) : accountsTransactionsError ? (
              <p>Error {accountsBalancesError}</p>
            ) : (
              <AccountsTransactionsTables
                startDate={transactionsStartDate}
                endDate={transactionsEndDate}
                accountsTransactions={accountsTransactions}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ClientFinancialInsights;

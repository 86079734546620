import { CircularProgress, Typography } from "@mui/material";
import { IconEdit } from "@tabler/icons";
import { PropertyDto } from "@/types";
import { useEffect, useState } from "react";
import { Drawer } from "@/components/base";
import EvaluationForm from "./EvaluationForm";
import { PropertyEvaluationStatusChip } from "@/components/partial";
import { usePropertyEvaluations } from "@/hooks/data-hooks/client-properties/usePropertyEvaluations";

interface Props {
  property: PropertyDto | undefined;
  isReadOnly?: boolean;
}

function EvaluationSection({ property, isReadOnly = false }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const usePropertyEvaluationsInstance = usePropertyEvaluations(property?.id);
  const { loading, error, currentPropertyEvaluationStatus, getPropertyEvaluations } = usePropertyEvaluationsInstance;

  useEffect(() => {
    if (property?.id) {
      getPropertyEvaluations();
    }
  }, [property?.id]);

  if (loading) return <CircularProgress size="2rem" />;

  function handleClose() {
    setIsDrawerOpen(false);
    getPropertyEvaluations();
  }

  if (error)
    return (
      <Typography color="error">
        <i>{error}</i>
      </Typography>
    );

  return (
    <>
      <PropertyEvaluationStatusChip
        propertyEvaluationStatus={currentPropertyEvaluationStatus}
        clickable={!isReadOnly}
        onClick={!isReadOnly ? () => setIsDrawerOpen(true) : undefined}
        onDelete={!isReadOnly ? () => setIsDrawerOpen(true) : undefined}
        deleteIcon={!isReadOnly ? <IconEdit /> : undefined}
      />
      <Drawer sx={{ maxWidth: "85vw", height: "none" }} anchor="right" onClose={() => handleClose()} open={isDrawerOpen}>
        <EvaluationForm
          handleClose={() => handleClose()}
          propertyId={property?.id}
          usePropertyEvaluationsInstance={usePropertyEvaluationsInstance}
        />
      </Drawer>
    </>
  );
}

export default EvaluationSection;

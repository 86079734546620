import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

import { UseEnvelopesHook, useDefinedEnvelopes } from "@/hooks";
import AddEmptyEnvelopeForm from "./AddEmptyEnvelopeForm";
import { MainCard } from "@/components/berry";
import AddDefinedEnvelopeForm from "./AddDefinedEnvelopeForm";

interface Props {
  onCreateEnvelope: () => void;
  useEnvelopesInstance: UseEnvelopesHook;
}

function AddEnvelopeForm({ onCreateEnvelope, useEnvelopesInstance }: Props) {
  const [isEmptyEnvelope, setIsEmptyEnvelope] = useState<boolean>(false);

  const { definedEnvelopes } = useDefinedEnvelopes();

  const handleChange = (e: React.SyntheticEvent, value: boolean) => {
    if (value !== null) {
      setIsEmptyEnvelope(value);
    }
  };

  return (
    <MainCard title="Add an Envelope">
      <Grid item xs={12}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <ToggleButtonGroup size="small" fullWidth color="primary" exclusive onChange={handleChange} value={isEmptyEnvelope}>
              <ToggleButton value={true}>Empty Envelope</ToggleButton>
              <ToggleButton value={false}>Defined Envelope</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            {isEmptyEnvelope && <AddEmptyEnvelopeForm onCreateEnvelope={onCreateEnvelope} useEnvelopesInstance={useEnvelopesInstance} />}
            {!isEmptyEnvelope && (
              <AddDefinedEnvelopeForm
                definedEnvelopes={definedEnvelopes}
                onCreateEnvelope={onCreateEnvelope}
                useEnvelopesInstance={useEnvelopesInstance}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default AddEnvelopeForm;

import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import {
  ClientApplication,
  ClientBudgetCalculator,
  ClientCreditDashboard,
  ClientFinancialInsights,
  ClientMilestones,
  ClientRemediation,
  ClientTreatment,
  NotFoundPage,
} from "@/components/view";
import { Person, ProgramClient } from "@/types";
import ClientPropertiesRouter from "../ClientPropertiesRouter";
import EnvelopeRouter from "../EnvelopeRouter";
import { WithProgramClient } from "@/components/providers";
import { useProgramClientsByPersonId } from "@/hooks";
import { useEffect } from "react";
import { useSelector } from "@/store";
import { StatusOfLandisProgram } from "@l4s/program-models";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

const ProgramClientRouter = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<ClientMilestones {...props} />} />
      <Route path="/milestones" element={<ClientMilestones {...props} />} />
      <Route path="/application" element={<ClientApplication {...props} />} />
      <Route path="/remediation" element={<ClientRemediation {...props} />} />
      <Route path="/budget-calculator" element={<ClientBudgetCalculator {...props} />} />
      <Route path="/goals" element={<ClientTreatment {...props} />} />
      <Route path="/credit-dashboard" element={<ClientCreditDashboard {...props} />} />
      <Route path="/financial-insights" element={<ClientFinancialInsights {...props} />} />
      <Route path="/properties/*" element={<ClientPropertiesRouter {...props} />} />
      <Route path="/documents/*" element={<EnvelopeRouter {...props} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

interface ProviderProps {
  person: Person;
}

const ProgramClientRouterWithProgramClient = (props: ProviderProps) => {
  const { programClientId } = useParams();
  return WithProgramClient({ programClientId, ...props, ChildComponent: ProgramClientRouter });
};

export default ProgramClientRouterWithProgramClient;

const ProgramClientRouterWithoutProgramClient = (props: ProviderProps) => {
  const { programClientsByPersonId, loadingProgramClientsByPersonId, fetchProgramClientsByPersonId } = useProgramClientsByPersonId(
    props.person.id
  );
  const clientMenuItem = useSelector((state) => state.clientMenuItems[0]);

  const navigate = useNavigate();

  useEffect(() => {
    if (clientMenuItem?.personId !== props.person.id) {
      fetchProgramClientsByPersonId();
    }
  }, [clientMenuItem, props.person.id]);

  useEffect(() => {
    if (programClientsByPersonId.length) {
      const programClientsSortedActiveThenStartedDesc = programClientsByPersonId.sort((a, b) => {
        if (a.status === b.status) {
          return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
        } else if (a.status === StatusOfLandisProgram.CLOSED) {
          return 1;
        }

        return -1;
      });

      const firstActiveRentalProgramClient = programClientsSortedActiveThenStartedDesc.find(
        (pc) => pc.status === StatusOfLandisProgram.ACTIVE && pc.programDetails.isRentalProgram
      );

      const pc = firstActiveRentalProgramClient || programClientsSortedActiveThenStartedDesc[0];

      navigate(`/client/${props.person.id}/program-client/${pc.id}/milestones`);
    }
  }, [programClientsByPersonId]);

  return (
    <div>
      {loadingProgramClientsByPersonId && "Loading..."}
      {!loadingProgramClientsByPersonId && !programClientsByPersonId.length && "No program clients found"}
    </div>
  );
};

export { ProgramClientRouterWithoutProgramClient, ProgramClientRouterWithProgramClient };

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

import { CreditScore } from "@l4s/credit-parser-models";

export interface CreditData {
  currentCredit: CreditScore | null;
  allCredit: CreditScore[];
}

export interface UsePersonFinancesCredit {
  credit: CreditData;
  personFinancesCreditLoading: boolean;
  loadPersonFinancesCredit: (personId: string) => Promise<void>;
}

export const usePersonFinancesCredit = (): UsePersonFinancesCredit => {
  const [personFinancesCreditLoading, setPersonFinancesCreditLoading] = useState<boolean>(true);
  const [credit, setCredit] = useState<CreditData>({
    currentCredit: null,
    allCredit: [],
  });

  const loadPersonFinancesCredit = async (personId: string): Promise<void> => {
    try {
      setPersonFinancesCreditLoading(true);
      const currentCreditResponse = bridgeV2ApiInstance.get<CreditScore>(routes.GET_CURRENT_CREDIT_BY_PERSON_ID(personId));
      const allCreditResponse = bridgeV2ApiInstance.get<Array<CreditScore>>(routes.GET_ALL_CREDIT_BY_PERSON_ID(personId));

      const data = await Promise.all([currentCreditResponse, allCreditResponse]).then(([{ data: currentCredit }, { data: allCredit }]) => ({
        currentCredit,
        allCredit,
      }));

      setPersonFinancesCreditLoading(false);
      setCredit(data);
    } catch (e) {
      setPersonFinancesCreditLoading(false);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching person finances credit data.");
    }
  };

  return {
    credit,
    personFinancesCreditLoading,
    loadPersonFinancesCredit,
  };
};

import { DocusignIcon } from "@/components/base";
import { MainCard } from "@/components/berry";
import { TemplateFieldAudienceDisplayMap, TemplateFieldOption, TemplateFieldTypeDisplayMap, isDocusignTemplateField } from "@/types";
import { Chip, Grid, Typography } from "@mui/material";
import { DocumentsAutofillConfiguration } from "./DocumentsAutofillConfiguration";
import { Box } from "@mui/system";
import { UpdateTemplateFieldAutofillInput } from "@/hooks";

interface Props {
  templateField: TemplateFieldOption | undefined;
  onRemoveTemplateFieldAutofill: (templateFieldId: string) => Promise<void>;
  onAddTemplateFieldAutofill: (input: UpdateTemplateFieldAutofillInput) => Promise<void>;
}

const TemplateFieldsDetail = ({ templateField, onAddTemplateFieldAutofill, onRemoveTemplateFieldAutofill }: Props) => {
  if (!templateField) return null;

  return (
    <Grid direction="column" container>
      <MainCard>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h2">{templateField.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: "normal" }}>
              {templateField.description}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              icon={isDocusignTemplateField(templateField.type) ? <DocusignIcon style={{ width: "1rem", borderRadius: "50%" }} /> : null}
              size="small"
              label={TemplateFieldTypeDisplayMap[templateField.type]}
            />
          </Grid>
          {templateField.audience && (
            <Grid item>
              <Typography variant="body1">Party Completing Field: {TemplateFieldAudienceDisplayMap[templateField.audience]}</Typography>
            </Grid>
          )}
        </Grid>
      </MainCard>
      {!isDocusignTemplateField(templateField.type) && (
        <>
          <Box sx={{ mt: 1 }} />
          <DocumentsAutofillConfiguration
            onAddTemplateFieldAutofill={onAddTemplateFieldAutofill}
            onRemoveTemplateFieldAutofill={onRemoveTemplateFieldAutofill}
            templateField={templateField}
          />
        </>
      )}
    </Grid>
  );
};

export default TemplateFieldsDetail;

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateRentalRegistrationInput, UpdateRentalRegistrationInput } from "@l4s/client-property-models";

import { RentalRegistration } from "@/types/PropertyManagement";

export interface UseRentalRegistration {
  loading: boolean;
  propertyRentalRegistration: Array<RentalRegistration>;
  reloadPropertyRentalRegistration: () => Promise<void>;
  error: string | undefined;
  createRentalRegistration: (input: CreateRentalRegistrationInput) => Promise<void>;
  updateRentalRegistration: (input: UpdateRentalRegistrationInput) => Promise<void>;
}

export const useRentalRegistration = (propertyId: string): UseRentalRegistration => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyRentalRegistration, setPropertyRentalRegistration] = useState<Array<RentalRegistration>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getRentalRegistrationByPropertyId = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<RentalRegistration>>(routes.GET_RENTAL_REGISTRATION_BY_PROPERTY_ID, {
        params: { propertyId },
      });
      setPropertyRentalRegistration(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property rental insurance.");
    }
    setLoading(false);
  };

  const createRentalRegistration = async (input: CreateRentalRegistrationInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<RentalRegistration>(routes.CREATE_RENTAL_REGISTRATION, { ...input });
      setPropertyRentalRegistration((existingRentalRegistration) => [...existingRentalRegistration, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating rental registration.");
    }
  };

  const updateRentalRegistration = async (input: UpdateRentalRegistrationInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<RentalRegistration>(routes.UPDATE_RENTAL_REGISTRATION(input.id), input);
      setPropertyRentalRegistration((existingRentalRegistration) =>
        existingRentalRegistration.map((rentalRegistration) =>
          rentalRegistration.id === response.data.id ? { ...rentalRegistration, ...response.data } : rentalRegistration
        )
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating rental registration.");
    }
  };

  useEffect(() => {
    if (propertyId) getRentalRegistrationByPropertyId();
  }, [propertyId]);

  return {
    loading,
    propertyRentalRegistration,
    reloadPropertyRentalRegistration: getRentalRegistrationByPropertyId,
    error,
    createRentalRegistration,
    updateRentalRegistration,
  };
};

import { TableLayout } from "@/components/layout";
import { StateCode } from "@/types";
import { formatAddress } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import UtilityForm, { UtilityTypeDisplayMap } from "./UtilityForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import UtilitiesTableHeader from "./UtilitiesTableHeader";
import { UseUtilities } from "@/hooks";
import { UtilityDto } from "@l4s/client-property-models";

interface Props {
  useUtilitiesInstance: UseUtilities;
  propertyId: string;
}

const UtilitiesList = ({ useUtilitiesInstance, propertyId }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedUtility, setSelectedUtility] = useState<UtilityDto>(null);
  const { propertyUtilities } = useUtilitiesInstance;

  function handleClick(row: UtilityDto) {
    setDrawerOpen(true);
    setSelectedUtility(row);
  }
  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={<UtilitiesTableHeader useUtilitiesInstance={useUtilitiesInstance} propertyId={propertyId} title="Utilities" />}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Website</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyUtilities.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} tabIndex={0}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{UtilityTypeDisplayMap[row.type]}</TableCell>
                <TableCell>
                  {formatAddress(row.addressStreet, null, row.addressCity, row.addressZip, row.addressState as StateCode)}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.website}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <UtilityForm
            close={() => setDrawerOpen(false)}
            utility={selectedUtility}
            useUtilitiesInstance={useUtilitiesInstance}
            propertyId={propertyId}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default UtilitiesList;

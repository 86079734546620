import { MainCard } from "@/components/berry";
import { AddressForm, AddressFormFields } from "@/components/partial";
import { UsePropertiesHook, useToast } from "@/hooks";

interface Props {
  usePropertiesInstance: UsePropertiesHook;
}

function CreatePropertyForm({ usePropertiesInstance }: Props) {
  const { createProperty } = usePropertiesInstance;

  const { addToast } = useToast();

  const handleAddProperty = async (values: AddressFormFields) => {
    try {
      await createProperty(values);
    } catch (e) {
      addToast({
        type: "error",
        message: e.message,
      });
    }
  };

  return (
    <MainCard title="Add Property">
      <AddressForm handleSubmit={handleAddProperty} />
    </MainCard>
  );
}

export default CreatePropertyForm;

import { bridgeV2ApiInstance } from "@/services";
import {
  GET_APPLICATIONS_PROPERTY_FINANCE,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_CLIENT_PAYMENTS,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_FINANCIAL_RATIOS,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_FIXED_PAYMENTS,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_PROPERTY_FINANCIALS,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_SALES_FINANCIALS,
  UPDATE_APPLICATIONS_PROPERTY_FINANCE_YEARLY_PAYMENTS,
} from "@/services/routes";
import { ApplicationsPropertyDto, ApplicationsPropertyFinance } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { StatusOfApplicationProperty } from "@l4s/client-property-models";
import { useEffect, useState } from "react";

export interface UpdateClientPaymentsInput {
  firstMonthProratedRentCents: ApplicationsPropertyFinance["firstMonthProratedRentCents"];
  monthlyRentCents: ApplicationsPropertyFinance["monthlyRentCents"];
  securityDepositCents: ApplicationsPropertyFinance["securityDepositCents"];
  monthlyHocCents: ApplicationsPropertyFinance["monthlyHocCents"];
  upfrontHocCents: ApplicationsPropertyFinance["upfrontHocCents"];
}

export interface UpdateFixedPaymentsInput {
  leaseStartDate: ApplicationsPropertyFinance["leaseStartDate"];
  currentLeaseTerm: ApplicationsPropertyFinance["currentLeaseTerm"];
  valuationType: ApplicationsPropertyFinance["valuationType"];
  recordingFeeCents: ApplicationsPropertyFinance["recordingFeeCents"];
  titleFeeCents: ApplicationsPropertyFinance["titleFeeCents"];
}

export interface UpdatePropertyFinancialsInput {
  purchasePriceCents: ApplicationsPropertyFinance["purchasePriceCents"];
  askingPriceCents: ApplicationsPropertyFinance["askingPriceCents"];
  appraisedPriceCents: ApplicationsPropertyFinance["appraisedPriceCents"];
  allInHudCents: ApplicationsPropertyFinance["allInHudCents"];
  loanAmountCents: ApplicationsPropertyFinance["loanAmountCents"];
  inspectionPriceCents: ApplicationsPropertyFinance["inspectionPriceCents"];
  depositAmountCents: ApplicationsPropertyFinance["depositAmountCents"];
  closingCosts: ApplicationsPropertyFinance["closingCosts"];
  resalePriceCents: ApplicationsPropertyFinance["resalePriceCents"];
  lenderCostCents: ApplicationsPropertyFinance["lenderCostCents"];
  sellersCreditCents: ApplicationsPropertyFinance["sellersCreditCents"];
  ddFeeCents: ApplicationsPropertyFinance["ddFeeCents"];
  appraisalPriceCents: ApplicationsPropertyFinance["appraisalPriceCents"];
}

export interface UpdateYearlyPaymentsInput {
  insuranceCents: ApplicationsPropertyFinance["insuranceCents"];
  hoaCents: ApplicationsPropertyFinance["hoaCents"];
  hoaPaymentFrequency: ApplicationsPropertyFinance["hoaPaymentFrequency"];
  taxesCents: ApplicationsPropertyFinance["taxesCents"];
}

export interface UpdateSalesFinancialsInput {
  actualSaleDate: ApplicationsPropertyFinance["actualSaleDate"];
  actualPriceSaleCents: ApplicationsPropertyFinance["actualPriceSaleCents"];
  aggClosingCostsSaleCents: ApplicationsPropertyFinance["aggClosingCostsSaleCents"];
}

export interface UpdateFinancialRatios {
  purchaseVsAsking: number;
  purchaseVsAppraisals: number;
  tenantsHocAfterYearCents: number;
  noiCents: number;
  grossYield: number;
  capRate: number;
  insurancePercentPrice: number;
}

export interface UseApplicationsPropertyFinanceHook {
  loading: boolean;
  error: string | undefined;
  applicationsPropertyFinance: ApplicationsPropertyFinance | undefined;
  updateClientPayments: (input: UpdateClientPaymentsInput) => Promise<void>;
  updateFixedPayments: (input: UpdateFixedPaymentsInput) => Promise<void>;
  updatePropertyFinancials: (input: UpdatePropertyFinancialsInput) => Promise<void>;
  updateSalesFinancials: (input: UpdateSalesFinancialsInput) => Promise<void>;
  updateYearlyPayments: (input: UpdateYearlyPaymentsInput) => Promise<void>;
  updateFinancialRatios: (input: UpdateFinancialRatios) => Promise<void>;
}

export const useApplicationsPropertyFinance = (applicationsProperty: ApplicationsPropertyDto) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [applicationsPropertyFinance, setApplicationsPropertyFinance] = useState<ApplicationsPropertyFinance | undefined>();

  const getApplicationsPropertyFinance = async () => {
    if (applicationsProperty.status !== StatusOfApplicationProperty.CLOSED) return;
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<ApplicationsPropertyFinance>(
        GET_APPLICATIONS_PROPERTY_FINANCE(applicationsProperty.id)
      );
      setApplicationsPropertyFinance(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching client property finances.");
    }
    setLoading(false);
  };

  const updateClientPayments = async (input: UpdateClientPaymentsInput) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_CLIENT_PAYMENTS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property client payments.");
    }
  };

  const updateFixedPayments = async (input: UpdateFixedPaymentsInput) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_FIXED_PAYMENTS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property fixed payments.");
    }
  };

  const updatePropertyFinancials = async (input: UpdatePropertyFinancialsInput) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_PROPERTY_FINANCIALS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property property financials.");
    }
  };

  const updateYearlyPayments = async (input: UpdateYearlyPaymentsInput) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_YEARLY_PAYMENTS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property yearly payments.");
    }
  };

  const updateSalesFinancials = async (input: UpdateSalesFinancialsInput) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_SALES_FINANCIALS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property sales financials.");
    }
  };

  const updateFinancialRatios = async (input: UpdateFinancialRatios) => {
    if (!applicationsPropertyFinance) return;
    try {
      const response = await bridgeV2ApiInstance.put<ApplicationsPropertyFinance>(
        UPDATE_APPLICATIONS_PROPERTY_FINANCE_FINANCIAL_RATIOS(applicationsProperty.id, applicationsPropertyFinance.id),
        input
      );
      setApplicationsPropertyFinance({
        ...applicationsPropertyFinance,
        ...response.data,
      });
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating client property financial ratios.");
    }
  };

  useEffect(() => {
    if (applicationsProperty) getApplicationsPropertyFinance();
  }, [applicationsProperty]);

  return {
    loading,
    error,
    applicationsPropertyFinance,
    updateClientPayments,
    updateFixedPayments,
    updatePropertyFinancials,
    updateSalesFinancials,
    updateYearlyPayments,
    updateFinancialRatios,
  };
};

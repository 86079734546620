import { ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";
import { getProgramClientsByPersonId } from "./useProgramClientsByPersonId";
import { StatusOfLandisProgram } from "@l4s/program-models";

export interface UseActiveProgramClientThatUsesHOCByPersonId {
  activeProgramClientThatUsesHOCByPersonId: ProgramClient | undefined;
  fetchActiveProgramClientThatUsesHOCByPersonId: () => Promise<void>;
  loadingActiveProgramClientThatUsesHOCByPersonId: boolean;
}

export const useActiveProgramClientThatUsesHOCByPersonId = (personId: string): UseActiveProgramClientThatUsesHOCByPersonId => {
  const [activeProgramClientThatUsesHOCByPersonId, setProgramClientsByPersonId] = useState<ProgramClient>();
  const [loadingActiveProgramClientThatUsesHOCByPersonId, setLoadingActiveProgramClientThatUsesHOCByPersonId] = useState<boolean>(false);

  const fetchActiveProgramClientThatUsesHOCByPersonId = async () => {
    try {
      setLoadingActiveProgramClientThatUsesHOCByPersonId(true);
      const data = await getProgramClientsByPersonId(personId);

      const programClient = data.find((pc) => pc.status === StatusOfLandisProgram.ACTIVE && pc.programDetails.usesHoc);

      setProgramClientsByPersonId(programClient);
    } catch (e) {
      console.error(e);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching program clients.");
    } finally {
      setLoadingActiveProgramClientThatUsesHOCByPersonId(false);
    }
  };

  return {
    activeProgramClientThatUsesHOCByPersonId,
    loadingActiveProgramClientThatUsesHOCByPersonId,
    fetchActiveProgramClientThatUsesHOCByPersonId,
  };
};

export enum Field {
  RESPONSIBLE_PARTY = "responsibleParty",
  PAYMENT_METHOD = "paymentMethod",
  PAYMENT_FREQUENCY = "paymentFrequency",
  INITIAL_PAYMENT_DATE = "initialPaymentDate",
  START_DATE = "startDate",
  END_DATE = "endDate",
  UTILITY_ID = "utilityId",
  EXTERNAL_ACCOUNT_ID = "externalAccountId",
}

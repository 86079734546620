import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { BudgetCalculatorInitialValues } from "@l4s/qualification-models";
import { useState } from "react";

export interface UseCalculatorInitialValues {
  calculatorInitialValues: BudgetCalculatorInitialValues;
  loadInitialValues: () => Promise<void>;
}

export const useCalculatorInitialValues = (personId: string): UseCalculatorInitialValues => {
  const [calculatorInitialValues, setCalculatorInitialValues] = useState<BudgetCalculatorInitialValues>();

  const loadInitialValues = async () => {
    try {
      setCalculatorInitialValues(undefined);
      const response = await bridgeV2ApiInstance.get<BudgetCalculatorInitialValues>(routes.GET_INITIAL_CALCULATOR_VALUES(personId));
      setCalculatorInitialValues(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching calculator initial values.");
    }
  };

  return {
    calculatorInitialValues,
    loadInitialValues,
  };
};

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Card, Grid, Typography } from "@mui/material";

// project imports
import { gridSpacing } from "@/store/constant";

// assets
import { Launch } from "@mui/icons-material";
import moment from "moment";
import WorkOrderStatusChip from "./WorkOrderStatusChip";
import WorkOrderSeverityChip from "./WorkOrderSeverityChip";
import { displayUsCents } from "@/utils";

// ==============================|| USER DETAILS CARD ||============================== //

const WorkOrderCard = ({ workOrder }) => {
  const theme = useTheme();

  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={gridSpacing}>
        <Grid container item xs={12} justifyContent="space-between" flexWrap="nowrap">
          <Grid item>
            <Typography variant="h3" component="div">
              {workOrder.name}
            </Typography>
            <Typography variant="caption">{moment(workOrder.created).format("MMM Do, YYYY h:mma")}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={6} sm={6} container direction="column">
              <Typography variant="caption">Status</Typography>
              <div>
                <WorkOrderStatusChip status={workOrder.status} />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} container direction="column">
              <Typography variant="caption">Severity</Typography>
              <div>
                <WorkOrderSeverityChip severity={workOrder.severity} />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} container direction="column">
              <Typography variant="caption">Max Cost</Typography>
              <Typography variant="h6">{displayUsCents(workOrder.maxCostCents)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} direction="column" container>
          <Typography variant="caption">Description</Typography>
          {workOrder.description.split("\n").map((item: string) => (
            <Typography key={item} variant="h6" sx={{ color: theme.palette.grey[700] }}>
              {item}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">Vendor Instruction</Typography>
          <Typography variant="h6">{workOrder.vendorInstruction}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <a style={{ textDecoration: "none" }} href={workOrder.externalUrl} target="_none">
                <Button variant="contained" startIcon={<Launch />}>
                  Go to Latchel
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WorkOrderCard;

import { ClientPersonHeader } from "@/components/partial";
import Application from "@/components/partial/ClientApplication/ClientApplication";
import { Person, ProgramClient, SearchParamKeys } from "@/types";
import { ApplicationTab } from "./constants";
import { useSearchParamsState } from "@/hooks";
import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ClientApplicationTabs from "./ClientApplicationTabs";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientApplication({ person, programClient }: Props) {
  const [applicationTabValue, setApplicationTabValue] = useSearchParamsState<ApplicationTab>(
    SearchParamKeys.APPLICATION_TAB,
    ApplicationTab.QUESTIONNAIRES
  );

  useEffect(() => {
    if (!applicationTabValue) setApplicationTabValue(ApplicationTab.QUESTIONNAIRES, { replace: true });
  }, [applicationTabValue]);

  if (!applicationTabValue) return null;

  return (
    <TabContext value={applicationTabValue}>
      <ClientPersonHeader person={person} programClient={programClient} tabs={<ClientApplicationTabs />} />
      {programClient ? (
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          {/* Placeholder for application data section */}
          {/* <TabPanel sx={{ padding: 0 }} value={ApplicationTab.APPLICATION}>
            <ApplicationOverview applicationId={clientJourneey.applicationid} />
          </TabPanel> */}
          <TabPanel sx={{ padding: 0 }} value={ApplicationTab.QUESTIONNAIRES}>
            <Application applicationId={programClient.applicationId} programClient={programClient} />
          </TabPanel>
        </Grid>
      ) : (
        <Typography sx={{ paddingTop: 2 }}>Client is not a primary applicant on any applications</Typography>
      )}
    </TabContext>
  );
}

import { ManualBreadcrumbs, SkeletonList } from "@/components/base";
import { ConfigureTemplate } from "@/components/partial";
import { useTemplate } from "@/hooks";
import { Box } from "@mui/material";
import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

function AdminDocumentTemplateDetail() {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const useTemplateHookInstance = useTemplate(templateId);

  const { template, loading } = useTemplateHookInstance;

  useEffect(() => {
    if (!loading && !template) {
      navigate("/admin/documents/templates");
    }
  }, [template, loading]);

  return (
    <>
      <ManualBreadcrumbs
        title="Configure Template"
        links={[
          {
            title: "Templates",
            url: "/admin/documents/templates",
          },
          {
            title: template?.name,
            url: `/admin/documents/templates/${templateId}`,
          },
        ]}
      />
      <Box mt={1} />
      {template && <ConfigureTemplate useTemplateHookInstance={useTemplateHookInstance} />}
      {!template && (
        <div style={{ width: "100%" }}>
          <SkeletonList orientation="horizontal" height={1000} count={2} />
        </div>
      )}
    </>
  );
}

export default AdminDocumentTemplateDetail;

import { useEffect, useState } from "react";
import { Envelope, EnvelopeStatus, EnvelopeStatusOrder, ExternalEnvelope } from "@/types";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { bridgeV2ApiInstance } from "@/services";
import { GET_CURRENT_EXTERNAL_ENVELOPE, GET_ENVELOPE_BY_ID, SEND_ENVELOPE } from "@/services/routes";

export interface UseEnvelopeHook {
  envelope: Envelope | undefined;
  currentExternalEnvelope: ExternalEnvelope | undefined;
  loading: boolean;
  currentExternalEnvelopeLoading: boolean;
  sendEnvelope: () => Promise<void>;
}

export const useEnvelope = (envelopeId: string): UseEnvelopeHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [envelope, setEnvelope] = useState<Envelope>();
  const [currentExternalEnvelopeLoading, setCurrentExternalEnvelopeLoading] = useState<boolean>(false);
  const [currentExternalEnvelope, setCurrentExternalEnvelope] = useState<ExternalEnvelope>();
  const { addToast } = useToast();

  const getEnvelope = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Envelope>(GET_ENVELOPE_BY_ID(envelopeId));
      setEnvelope(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message || "Could not find envelope.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const sendEnvelope = async () => {
    try {
      const response = await bridgeV2ApiInstance.post<{
        envelope: Envelope;
        externalEnvelope: ExternalEnvelope;
      }>(SEND_ENVELOPE(envelopeId));
      setCurrentExternalEnvelope(response.data.externalEnvelope);
      setEnvelope((prev) => ({ ...prev, ...response.data.envelope }));
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message || "Could not send envelope.", type: "error" });
    }
  };

  const getCurrentExternalEnvelope = async () => {
    setCurrentExternalEnvelopeLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<ExternalEnvelope>(GET_CURRENT_EXTERNAL_ENVELOPE(envelopeId));
      setCurrentExternalEnvelope(response.data);
    } catch (e) {}
    setCurrentExternalEnvelopeLoading(false);
  };

  useEffect(() => {
    getEnvelope();
  }, [envelopeId]);

  useEffect(() => {
    if (envelope && EnvelopeStatusOrder[envelope.status] >= EnvelopeStatusOrder[EnvelopeStatus.DRAFTED]) {
      getCurrentExternalEnvelope();
    }
  }, [envelope]);

  return {
    envelope,
    loading,
    sendEnvelope,
    currentExternalEnvelope,
    currentExternalEnvelopeLoading,
  };
};

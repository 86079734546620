import { Route, Routes } from "react-router-dom";

import { CallbackPage, LoginPage, NotFoundPage } from "@/components/view";

const LoginRouter = () => (
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/callback" element={<CallbackPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default LoginRouter;

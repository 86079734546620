import { CardContent, Grid } from "@mui/material";
import { SubCard } from "@/components/berry";
import { GRID_SPACING } from "@/config";
import { Action, Goal, GoalTypeMap, TypeOfAction } from "@/types";
import { DropdownOption } from "@/components/base";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

type TFormik = {
  name: string;
  description: string;
  type: TypeOfAction;
  dueDate?: string;
  publishDate?: string;
  link?: string;
  buttonText?: string;
  learnMoreLink?: string;
  goalIds?: string[];
};

interface Props {
  action: Partial<Action>;
  formik: FormikProps<TFormik>;
  goalsForAction?: Goal[];
  programClientGoals?: Goal[];
}

function ClientNoteGoals({ action, formik, goalsForAction, programClientGoals }: Props) {
  const [goalIds, setGoalIds] = useState<Array<string>>([]);

  //create the label for a client in multi-select dropdown
  const goalDropdownSelectedLabel = (id: string): string => {
    let goal: Goal | undefined = programClientGoals.find((programGoal) => programGoal.id === id);
    return goal ? `${GoalTypeMap[goal.type.name]}` : id;
  };

  useEffect(() => {
    formik.setFieldValue("goalIds", goalIds);
  }, [goalIds]);

  useEffect(() => {
    if (goalsForAction.length) {
      setGoalIds(goalsForAction.map((goal) => goal.id));
    }
  }, [goalsForAction]);

  return (
    <SubCard title="Associated Goals" content={false}>
      <CardContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <DropdownOption
              id="noteGoalDropdown"
              label="Goals"
              handleChange={setGoalIds}
              required
              values={goalIds}
              multiple
              items={(programClientGoals ?? []).map((data: Goal): { value: string; data: Goal } => {
                if (data.isActive) return { value: data.id, data };
              })}
              optionComponent={(data: Goal) => {
                return (
                  <div>
                    <div>{data.goalTitle || GoalTypeMap[data.type.name]}</div>
                    <div>
                      <Typography variant={"caption"}>
                        {data.goalDescription
                          ? data.goalDescription?.length < 60
                            ? data.goalDescription
                            : `${data.goalDescription?.slice(0, 60)}...`
                          : undefined}
                      </Typography>
                    </div>
                  </div>
                );
              }}
              labelSelected={goalDropdownSelectedLabel}
            />
          </Grid>
        </Grid>
      </CardContent>
    </SubCard>
  );
}

export default ClientNoteGoals;

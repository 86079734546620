import { bridgeV2ApiInstance, routes } from "@/services";
import { JourneyDtoModel } from "@/types";
import { useEffect, useState } from "react";

export interface UseJourneyHook {
  journey: JourneyDtoModel;
  loading: boolean;
  refetchJourney: () => Promise<void>;
}

export const useJourney = (clientJourneyId: string): UseJourneyHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [journey, setJourney] = useState<JourneyDtoModel>([]);

  const getJourney = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<JourneyDtoModel>(routes.GET_JOURNEY(clientJourneyId), { params: { clientJourneyId } });
      setJourney(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJourney();
  }, [clientJourneyId]);

  return {
    journey,
    loading,
    refetchJourney: getJourney,
  };
};

import { useEffect, useState } from "react";
import { FeatureHub } from "./FeatureHub";
import { useSelector } from "@/store";
import { env } from "@/env";

export const FeatureHubProvider = ({ children }: { children: JSX.Element }) => {
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const authenticatedAccount = useSelector((state) => state.authenticatedAccount);

  useEffect(() => {
    if (authenticatedAccount) {
      setAccountId(authenticatedAccount.accountId);
    }
  }, [authenticatedAccount]);

  return (
    <FeatureHub username={accountId} url={env.FEATUREHUB_URL} apiKey={env.FEATUREHUB_API_KEY}>
      {children}
    </FeatureHub>
  );
};

import { SkeletonList } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseEnvelopeHook, useDocusignEvents } from "@/hooks";
import { DocusignEvent, docusignEventDisplayMap } from "@/types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";

interface Props {
  useEnvelopeInstance: UseEnvelopeHook;
}

const DocusignEventRow = ({ event }: { event: DocusignEvent }) => {
  const [isDescriptionShown, setIsDescriptionShown] = useState<boolean>(false);

  return (
    <>
      <TableRow onClick={() => setIsDescriptionShown(!isDescriptionShown)} hover sx={{ cursor: "pointer" }}>
        <TableCell sx={{ borderBottom: isDescriptionShown ? "none" : "inherit", fontWeight: 600 }}>
          {docusignEventDisplayMap[event.eventType].label}
        </TableCell>
        <TableCell sx={{ borderBottom: isDescriptionShown ? "none" : "inherit" }}>
          {moment(event.generatedDateTime).format("MMM Do, YYYY \\at h:mma")}
        </TableCell>
        <TableCell sx={{ borderBottom: isDescriptionShown ? "none" : "inherit" }} align="right">
          {isDescriptionShown ? <ExpandLess /> : <ExpandMore />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={isDescriptionShown} unmountOnExit>
            <Typography sx={{ marginBottom: 1 }}>{docusignEventDisplayMap[event.eventType].description}</Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DocusignEventsTable = ({ useEnvelopeInstance }: Props) => {
  const [isTableShown, setIsTableShown] = useState<boolean>(false);
  const { envelope, currentExternalEnvelope } = useEnvelopeInstance;
  const { docusignEvents, loading } = useDocusignEvents(envelope?.id, currentExternalEnvelope?.id);

  if (loading) return <SkeletonList count={3} height={20} />;

  return (
    <MainCard
      sx={{ width: "100%" }}
      content={isTableShown}
      title={
        <Grid
          sx={{ cursor: "pointer" }}
          onClick={() => setIsTableShown(!isTableShown)}
          container
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Typography variant="h3">Docusign Events</Typography>
          {isTableShown ? <ExpandLess /> : <ExpandMore />}
        </Grid>
      }
    >
      <Collapse in={isTableShown} unmountOnExit>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Event</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!!docusignEvents.length && (
              <TableRow>
                <TableCell colSpan={2}>No events found.</TableCell>
              </TableRow>
            )}
            {docusignEvents.map((event) => (
              <DocusignEventRow event={event} key={event.id} />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </MainCard>
  );
};

export default DocusignEventsTable;

import { Link } from "react-router-dom";
// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

// project imports
import { Page, AnimateButton } from "@/components/berry";
import { DASHBOARD_PATH } from "@/config";
import { gridSpacing } from "@/store/constant";

// assets
import { HomeTwoTone } from "@mui/icons-material";

const imageBackground = "/assets/images/maintenance/img-error-bg.svg";
const imageDarkBackground = "/assets/images/maintenance/img-error-bg-dark.svg";
const imageBlue = "/assets/images/maintenance/img-error-blue.svg";
const imageText = "/assets/images/maintenance/img-error-text.svg";
const imagePurple = "/assets/images/maintenance/img-error-purple.svg";

// styles
const CardMediaWrapper = styled("div")({
  maxWidth: 720,
  margin: "0 auto",
  position: "relative",
});

const ErrorWrapper = styled("div")({
  maxWidth: 350,
  margin: "0 auto",
  textAlign: "center",
});

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CardMediaBlock = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "3s bounce ease-in-out infinite",
});

const CardMediaBlue = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "15s wings ease-in-out infinite",
});

const CardMediaPurple = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  animation: "12s wings ease-in-out infinite",
});

// ==============================|| ERROR PAGE ||============================== //

const NotFoundPage = () => {
  const theme = useTheme();

  return (
    <Page title="Error 404">
      <ErrorCard>
        <CardContent>
          <Grid container justifyContent="center" spacing={gridSpacing}>
            <Grid item xs={12}>
              <CardMediaWrapper>
                <CardMedia
                  component="img"
                  image={theme.palette.mode === "dark" ? imageDarkBackground : imageBackground}
                  title="Slider5 image"
                />
                <CardMediaBlock src={imageText} title="Slider 1 image" />
                <CardMediaBlue src={imageBlue} title="Slider 2 image" />
                <CardMediaPurple src={imagePurple} title="Slider 3 image" />
              </CardMediaWrapper>
            </Grid>
            <Grid item xs={12}>
              <ErrorWrapper>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Typography variant="h1" component="div">
                      Something is wrong
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">We were unable to find what you are looking for</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AnimateButton>
                      <Link to={DASHBOARD_PATH}>
                        <Button variant="contained" size="large">
                          <HomeTwoTone sx={{ fontSize: "1.3rem", mr: 0.75 }} /> Home
                        </Button>
                      </Link>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </ErrorWrapper>
            </Grid>
          </Grid>
        </CardContent>
      </ErrorCard>
    </Page>
  );
};

export default NotFoundPage;

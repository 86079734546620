import { TemplateFieldInTemplate } from "@/types";
import { useEffect, useMemo, useState } from "react";
import { bridgeV2ApiInstance } from "@/services";
import {
  ASSIGN_TEMPLATE_FIELD_TO_TEMPLATE,
  CREATE_TEMPLATE_FIELD_IN_TEMPLATE,
  DELETE_TEMPLATE_FIELD_IN_TEMPLATE,
  GET_TEMPLATE_FIELDS_IN_TEMPLATE,
} from "@/services/routes";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { CreateTemplateFieldInput } from "./useTemplateFieldOptions";

export interface AddExistingTemplateFieldInput {
  templateFieldId: string;
  isRequired: boolean;

  page: number;
  xCoord: number;
  yCoord: number;
}

export type CreateTemplateFieldInTemplateInput = CreateTemplateFieldInput & { isRequired: boolean };

export type AddNewTemplateFieldInput = CreateTemplateFieldInTemplateInput & {
  page: number;
  xCoord: number;
  yCoord: number;
};

export interface UseTemplateFieldsHook {
  loading: boolean;
  templateFields: Array<TemplateFieldInTemplate>;
  allTemplateFields: Array<TemplateFieldInTemplate>;
  addExistingTemplateField: (input: AddExistingTemplateFieldInput) => Promise<void>;
  addNewTemplateField: (input: AddNewTemplateFieldInput) => Promise<void>;
  removeTemplateField: (templateFieldInTemplateId: string) => Promise<void>;
}

export const useTemplateFields = (templateId: string): UseTemplateFieldsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templateFields, setTemplateFields] = useState<Array<TemplateFieldInTemplate>>([]);

  const { addToast } = useToast();

  const remindUserToSaveTemplate = (isCreation: boolean) =>
    addToast({
      message: `Template field ${
        isCreation ? "added" : "removed"
      } successfully. Make sure to save the template to apply the change to the document.`,
      type: "success",
    });

  const getTemplateFields = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<TemplateFieldInTemplate>>(GET_TEMPLATE_FIELDS_IN_TEMPLATE(templateId));
      setTemplateFields(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
    setLoading(false);
  };

  // Scaffolded for future implementation
  const addExistingTemplateField: UseTemplateFieldsHook["addExistingTemplateField"] = async (input) => {
    try {
      const response = await bridgeV2ApiInstance.post<TemplateFieldInTemplate>(
        ASSIGN_TEMPLATE_FIELD_TO_TEMPLATE(templateId, input.templateFieldId),
        {
          isRequired: input.isRequired,
          page: input.page,
          xCoord: input.xCoord,
          yCoord: input.yCoord,
        }
      );
      setTemplateFields([...templateFields, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw e;
    }
    remindUserToSaveTemplate(true);
  };

  const addNewTemplateField: UseTemplateFieldsHook["addNewTemplateField"] = async (input) => {
    try {
      const response = await bridgeV2ApiInstance.post<TemplateFieldInTemplate>(CREATE_TEMPLATE_FIELD_IN_TEMPLATE(templateId), {
        isRequired: input.isRequired,
        page: input.page,
        xCoord: input.xCoord,
        yCoord: input.yCoord,
        name: input.name,
        description: input.description,
        type: input.type,
        audience: input.audience,
      });

      setTemplateFields([...templateFields, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw e;
    }
    remindUserToSaveTemplate(true);
  };

  const removeTemplateField: UseTemplateFieldsHook["removeTemplateField"] = async (templateFieldInTemplateId) => {
    try {
      const response = await bridgeV2ApiInstance.delete<TemplateFieldInTemplate>(
        DELETE_TEMPLATE_FIELD_IN_TEMPLATE(templateId, templateFieldInTemplateId)
      );
      setTemplateFields(
        templateFields.map((templateField) =>
          templateField.id === templateFieldInTemplateId ? { ...templateField, ...response.data } : templateField
        )
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw e;
    }
    remindUserToSaveTemplate(false);
  };

  useEffect(() => {
    getTemplateFields();
  }, []);

  const activeTemplateFields = useMemo(() => templateFields.filter((templateField) => templateField.isActive), [templateFields]);

  return {
    loading,
    templateFields: activeTemplateFields,
    allTemplateFields: templateFields,
    addExistingTemplateField,
    addNewTemplateField,
    removeTemplateField,
  };
};

import { useEffect, useState } from "react";
import useFeatureHubClient from "./useFeatureHubClient";
import { env } from "@/env";

/**
 * React hook to subscribe to FeatureHub feature key.
 * NOTE: The key must be defined in your FeatureHub Admin Console.
 *
 * @param {string} key - the feature key
 * @returns {T} value - generic type of feature value (default boolean)
 */
export function useFeature<T = boolean>(key: string): T {
  const client = useFeatureHubClient();
  const [value, setValue] = useState(client.feature<T>(key).value);

  useEffect(() => {
    const feature = client.feature<T>(key);
    if (feature) {
      setValue(feature.value);
    }
  }, [client, key]);

  if (!env.FF_ENABLED) return true as T;

  return value;
}

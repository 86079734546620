import { TableLayout } from "@/components/layout";
import { StateCode } from "@/types";
import { displayUsCents, formatAddress } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import HoaForm from "./HoaForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import HoasTableHeader from "./HoaTableHeader";
import { UseHoas } from "@/hooks";
import { HoaDto } from "@l4s/client-property-models";

interface Props {
  useHoasInstance: UseHoas;
  propertyId: string;
}

const HoasList = ({ useHoasInstance, propertyId }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedHoa, setSelectedHoa] = useState<HoaDto>(null);
  const { propertyHoas } = useHoasInstance;

  function handleClick(row: HoaDto) {
    setDrawerOpen(true);
    setSelectedHoa(row);
  }

  return (
    <>
      <TableLayout maxHeight="70vh" title={<HoasTableHeader propertyId={propertyId} useHoasInstance={useHoasInstance} title="HOAs" />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Initial Dues</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyHoas.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} tabIndex={0}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {formatAddress(row.addressStreet, null, row.addressCity, row.addressZip, row.addressState as StateCode)}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.website}</TableCell>
                <TableCell>{displayUsCents(row.initialDuesCents)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <HoaForm close={() => setDrawerOpen(false)} hoa={selectedHoa} useHoasInstance={useHoasInstance} propertyId={propertyId} />
        </Box>
      </Drawer>
    </>
  );
};

export default HoasList;

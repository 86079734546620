export const convertUsCentsToDecimalWithCommas = (cents: number): string => {
  return (cents / 100).toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2 });
};

export const formatUsDollarsWithCommas = (dollars: number): string => {
  return dollars.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 0 });
};

export const displayUsCents = (cents: number): string => {
  return "$" + convertUsCentsToDecimalWithCommas(cents);
};

export const displayUsDollars = (dollars: number): string => {
  return "$" + formatUsDollarsWithCommas(dollars);
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseProgramClientsByPersonId {
  programClientsByPersonId: ProgramClient[];
  fetchProgramClientsByPersonId: () => Promise<void>;
  loadingProgramClientsByPersonId: boolean;
}

export async function getProgramClientsByPersonId(personId: string): Promise<Array<ProgramClient>> {
  const { data } = await bridgeV2ApiInstance.get<Array<ProgramClient>>(routes.GET_PROGRAM_CLIENTS_BY_PERSON_ID(personId));

  return data;
}

export const useProgramClientsByPersonId = (personId: string): UseProgramClientsByPersonId => {
  const [programClientsByPersonId, setProgramClientsByPersonId] = useState<Array<ProgramClient>>([]);
  const [loadingProgramClientsByPersonId, setLoadingProgramClientsByPersonId] = useState<boolean>(false);

  const fetchProgramClientsByPersonId = async () => {
    try {
      setLoadingProgramClientsByPersonId(true);
      const data = await getProgramClientsByPersonId(personId);
      setProgramClientsByPersonId(data);
    } catch (e) {
      console.error(e);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching program clients.");
    } finally {
      setLoadingProgramClientsByPersonId(false);
    }
  };

  return {
    programClientsByPersonId,
    loadingProgramClientsByPersonId,
    fetchProgramClientsByPersonId,
  };
};

import { MainCard } from "@/components/berry";
import { UsePropertyHook, usePortfolioProperty, useSearchParamsState } from "@/hooks";
import { Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { PropertyTab, tabItems } from "../constants";
import { SearchParamKeys } from "@/types";
import { SkeletonList } from "@/components/base";
import AddressSection from "./AddressSection";
import { PropertyEvaluationChipSection } from "@/components/partial";
import EntitySection from "./EntitySection";

interface Props {
  usePropertyInstance: UsePropertyHook;
  propertyId: string;
}

function PropertyHeader({ usePropertyInstance, propertyId }: Props) {
  const [propertyTabValue, setPropertyTabValue] = useSearchParamsState<PropertyTab>(SearchParamKeys.PROPERTY_TAB);
  const theme = useTheme();

  const { property, loading, error } = usePropertyInstance;
  const { portfolioProperty, loading: portPropertyloading } = usePortfolioProperty(propertyId);

  return (
    <MainCard
      contentSX={{
        p: 1.5,
        paddingBottom: "0px !important",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6} container direction="column" gap={0.5}>
              {loading && <SkeletonList count={3} height={15} />}
              {property && <AddressSection usePropertyInstance={usePropertyInstance} />}
              {error && (
                <Typography color="error">
                  <i>{error}</i>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6} container direction="column" gap={0.5} alignItems="flex-end">
              <PropertyEvaluationChipSection property={property} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={2} md={2} marginTop={2} container direction="column" gap={2}>
              {portPropertyloading && <SkeletonList count={3} height={15} />}
              {portfolioProperty && <EntitySection portfolioProperty={portfolioProperty}></EntitySection>}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Tabs value={propertyTabValue} indicatorColor="primary" textColor="primary" variant="scrollable">
              {tabItems.map((tabItem) => (
                <Tab
                  key={tabItem.value}
                  icon={tabItem.icon}
                  iconPosition="start"
                  onClick={() => setPropertyTabValue(tabItem.value)}
                  label={tabItem.label}
                  value={tabItem.value}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default PropertyHeader;

import { Button, Grid, TextField, useTheme, Divider, CardActions } from "@mui/material";
import { SubCard, AnimateButton } from "@/components/berry";
import { WithEnv } from "@/components/providers";
import { gridSpacing } from "@/store/constant";

function ClientPersonEdit() {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard title="Person Information">
            <form noValidate autoComplete="off">
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={6}>
                  <TextField id="firstName" fullWidth label="First Name" defaultValue={""} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField id="lastName" fullWidth label="Last Name" defaultValue={""} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField id="email" fullWidth label="Email" defaultValue={""} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField id="phoneNumber" fullWidth label="Phone Number" defaultValue={""} />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Grid spacing={2} container justifyContent="flex-end">
                  <Grid item>
                    <Button sx={{ color: theme.palette.error.main }}>Clear</Button>
                  </Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button variant="contained">Update Person</Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <WithEnv allowedEnvs={["dev"]}>
              <>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="text" size="small" onClick={() => {}}>
                    Client Note
                  </Button>
                </CardActions>
              </>
            </WithEnv>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ClientPersonEdit;

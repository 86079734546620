export enum ApplicationTab {
  // APPLICATION = "application",
  QUESTIONNAIRES = "questionnaires",
}

export interface ApplicationTabItem {
  label: string;
  value: ApplicationTab;
}

export const tabItems: Array<ApplicationTabItem> = [
  // {
  //   label: "Application",
  //   value: ApplicationTab.APPLICATION,
  // },
  {
    label: "Questionnaires",
    value: ApplicationTab.QUESTIONNAIRES,
  },
];

import { usePersonTemplateAutofillDisplayValues } from "@/hooks";
import { DocumentAutofillConfigurationDisplay } from "@/components/partial";

interface Props {
  personId: string;
  templateIds: Array<string>;
}

const EnvelopeAutofillDetail = ({ personId, templateIds }: Props) => {
  const { displayValues, loading } = usePersonTemplateAutofillDisplayValues(personId, templateIds);

  return <DocumentAutofillConfigurationDisplay displayValues={displayValues} loading={loading} />;
};

export default EnvelopeAutofillDetail;

import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { displayUsCents } from "@/utils";
import ClientPropertyFinanceTable from "../ClientPropertyFinanceTable";
import { ClientPropertyFinanceRow } from "../types";
import ClientPropertyFinanceTableHeader from "../ClientPropertyFinanceTableHeader";
import SalesFinancialsForm from "./SalesFinancialsForm";
import { useState } from "react";
import moment from "moment";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const tableRows: Array<ClientPropertyFinanceRow> = [
  {
    label: "Actual Sale Date",
    fieldKey: "actualSaleDate",
    displayFn: (value) => moment.utc(value).format("M/D/YYYY"),
  },
  {
    label: "Actual Sale Price",
    fieldKey: "actualPriceSaleCents",
    displayFn: displayUsCents,
  },
  {
    label: "Aggregate Closing Costs",
    fieldKey: "aggClosingCostsSaleCents",
    displayFn: displayUsCents,
  },
];

const SalesFinancialsCard = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const isFormOpenStateInstance = useState<boolean>(false);
  const [, setIsFormOpen] = isFormOpenStateInstance;

  return (
    <ClientPropertyFinanceTable
      useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
      tableRows={tableRows}
      title={
        <ClientPropertyFinanceTableHeader
          FormElement={
            <SalesFinancialsForm
              handleClose={() => setIsFormOpen(false)}
              useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
            />
          }
          isFormOpenStateInstance={isFormOpenStateInstance}
          title="Sales Financials"
        />
      }
    />
  );
};

export default SalesFinancialsCard;

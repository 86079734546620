import { JourneyRequirementDtoModel } from "@/types";
import { Button, CardContent, CardActions, Divider, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MainCard } from "@/components/berry";

interface Props {
  open: boolean;
  requirement: JourneyRequirementDtoModel;
  onClickComplete: () => void;
  onClickCancel: () => void;
}

const ConfirmCompleteRequirementModal = ({ open, requirement, onClickComplete, onClickCancel }: Props) => {
  return (
    <Modal open={open} onClose={onClickCancel} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <MainCard
        sx={{
          position: "absolute",
          width: { xs: 280, lg: 450 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title="Are you ready to complete this requirement?"
        content={false}
        secondary={
          <IconButton onClick={onClickCancel} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <CardContent>
          <Typography variant="body1">
            Completing the last requirement on a milestone will automatically mark the milestone as complete.
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2 }}>
            This cannot be undone.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" color="primary" type="button" onClick={onClickComplete}>
            Complete
          </Button>
          <Button variant="contained" color="primary" type="button" onClick={onClickCancel}>
            Cancel
          </Button>
        </CardActions>
      </MainCard>
    </Modal>
  );
};

export default ConfirmCompleteRequirementModal;

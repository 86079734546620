import { useState, ReactElement, useImperativeHandle, forwardRef, ForwardRefRenderFunction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { TabContext } from "@mui/lab";

import TabsOrientation, { DOES_NOT_EXIST } from "./TabsOrientation";

interface TabsProps {
  /** a list of ids for all tabs and tab panels that will be rendered */
  tabsIds: string[];
  /** an [OPTIONAL] id from the tabsIds property that this component will start at */
  tabToStart?: string;
  /** a fragment that will be render as children a list of all <Tab /> components */
  TabListComponent: ReactElement;
  /** an [OPTIONAL] component that will load below the TabListComponent property */
  BelowTabListComponent?: ReactElement;
  /** a fragment that will render all the <TabPanel /> components */
  TabPanelComponent: ReactElement;
  /** an [OPTIONAL] title used when orientation = "vertical" that shows in the rendered card containing the list/panel */
  verticalTabListCardTitle?: string;
  /** an [OPTIONAL] number used when orientation = "vertical". Defaults to 6. */
  verticalLeftGridItemSize?: number;
  /** an [OPTIONAL] string that will change or load the search param in the URL */
  urlSearchParamName?: string;
  /** determines whether the TabListComponent will be horizontal or vertical */
  orientation: "vertical" | "horizontal";
}

type HandleImperativeRefType = {
  setTab: (tabName: string) => void;
  getTab: () => string;
};

const TabsComponent: ForwardRefRenderFunction<HandleImperativeRefType, TabsProps> = (
  {
    tabsIds,
    tabToStart = DOES_NOT_EXIST,
    TabListComponent,
    BelowTabListComponent,
    TabPanelComponent,
    verticalTabListCardTitle,
    verticalLeftGridItemSize = 6,
    urlSearchParamName,
    orientation,
  }: TabsProps,
  ref
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(tabToStart);

  const handleChangeTab = (_event: React.SyntheticEvent, id: string) => {
    setCurrentTab(id);
    if (urlSearchParamName) {
      searchParams.set(urlSearchParamName, id);
      setSearchParams(searchParams, { replace: true });
    }
  };

  //when the component starts, allow the current tab to be set from the search param in URL
  //tabsIds is excluded from effect params to prevent adding new tab and this effect changing current tab
  useEffect(() => {
    if (urlSearchParamName) {
      const id = searchParams.get(urlSearchParamName);
      if (id && tabsIds.includes(id)) {
        setCurrentTab(id);
      }
    }
  }, [urlSearchParamName]);

  //allow the parent component to call functions from inside this component
  useImperativeHandle(
    ref,
    (): HandleImperativeRefType => ({
      setTab: setCurrentTab,
      getTab: (): string => {
        return currentTab;
      },
    })
  );

  return (
    <TabContext value={currentTab}>
      <TabsOrientation
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        orientation={orientation}
        verticalTabListCardTitle={verticalTabListCardTitle}
        verticalLeftGridItemSize={verticalLeftGridItemSize}
        TabListComponent={TabListComponent}
        BelowTabListComponent={BelowTabListComponent}
        TabPanelComponent={TabPanelComponent}
      />
    </TabContext>
  );
};

/**
 * A base component for handling vertical and horizontal tabs with associated styles
 * @demo http://localhost:6006?path=/story/base-tabs
 *
 * @component Tabs
 * @interface TabsProps
 * @ref {Tabs} usage: ElementRef<typeof Tabs> @returns 2 methods to access state: setTab(tabId: string) => void, getTab() => string
 * @property {"vertical" | "horizontal"} orientation - determines whether the TabListComponent will be horizontal or vertical
 * @property  {string[]} tabsIds - a list of ids for all tabs and tab panels that will be rendered
 * @property {string} tabToStart - an [OPTIONAL] id from the tabsIds property that this component will start at
 * @property {ReactElement} TabListComponent - a fragment that will be render as children a list of all <Tab /> components
 * @property {ReactElement} BelowTabListComponent - an [OPTIONAL] component that will load below the TabListComponent property
 * @property {ReactElement} TabPanelComponent - a fragment that will render all the <TabPanel /> components
 * @property {string} verticalTabListCardTitle - an [OPTIONAL] title used when orientation = "vertical" that shows in the rendered card containing the list/panel
 * @property {number} verticalLeftGridItemSize - an [OPTIONAL] number used when orientation = "vertical". Defaults to 6.
 * @property {string} urlSearchParamName - an [OPTIONAL] string that will change or load the search param in the URL
 */
export const Tabs = forwardRef(TabsComponent);

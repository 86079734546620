import useToast from "@/hooks/useToast";
import { bridgeV2ApiInstance } from "@/services";
import {
  CREATE_TEMPLATE_FIELD,
  DELETE_TEMPLATE_FIELD_AUTOFILL,
  GET_ALL_TEMPLATE_FIELDS,
  UPDATE_TEMPLATE_FIELD_AUTOFILL,
} from "@/services/routes";
import { TemplateFieldAudience, TemplateFieldOption, TemplateFieldType } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useMemo, useState } from "react";
import { Entity } from "@l4s/documents-autofill-models";

export interface CreateTemplateFieldInput {
  name: string;
  description: string;
  type: TemplateFieldType;
  audience: TemplateFieldAudience | null;
}

export interface UpdateTemplateFieldAutofillInput {
  templateFieldId: string;
  entity: Entity;
  entityField: string;
}

export interface UseTemplateFieldOptionsHook {
  loading: boolean;
  templateFieldOptions: Array<TemplateFieldOption>;
  addTemplateField: (input: CreateTemplateFieldInput) => Promise<void>;
  updateTemplateFieldAutofill: (input: UpdateTemplateFieldAutofillInput) => Promise<void>;
  removeTemplateFieldAutofill: (templateFieldId: string) => Promise<void>;
}

export const useTemplateFieldOptions = (): UseTemplateFieldOptionsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templateFieldOptions, setTemplateFieldOptions] = useState<Array<TemplateFieldOption>>([]);

  const { addToast } = useToast();

  const getAllTemplateFields = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<TemplateFieldOption>>(GET_ALL_TEMPLATE_FIELDS);
      setTemplateFieldOptions(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
    setLoading(false);
  };

  const addTemplateField = async (input: CreateTemplateFieldInput) => {
    try {
      const response = await bridgeV2ApiInstance.post<TemplateFieldOption>(CREATE_TEMPLATE_FIELD, input);
      setTemplateFieldOptions((prevTemplateFieldOptions) => [...prevTemplateFieldOptions, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw e;
    }
  };

  const updateTemplateFieldAutofill = async (input: UpdateTemplateFieldAutofillInput) => {
    try {
      const response = await bridgeV2ApiInstance.put<TemplateFieldOption>(UPDATE_TEMPLATE_FIELD_AUTOFILL(input.templateFieldId), {
        entity: input.entity,
        entityField: input.entityField,
      });
      setTemplateFieldOptions((prevTemplateFieldOptions) =>
        prevTemplateFieldOptions.map((templateFieldOption) => {
          if (templateFieldOption.id === response.data.id) {
            return {
              ...templateFieldOption,
              ...response.data,
            };
          }
          return templateFieldOption;
        })
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message || "Error configuring autofill.", type: "error" });
      throw e;
    }
  };

  const removeTemplateFieldAutofill = async (templateFieldId: string) => {
    try {
      const response = await bridgeV2ApiInstance.delete<TemplateFieldOption>(DELETE_TEMPLATE_FIELD_AUTOFILL(templateFieldId));
      setTemplateFieldOptions((prevTemplateFieldOptions) =>
        prevTemplateFieldOptions.map((templateFieldOption) => {
          if (templateFieldOption.id === response.data.id)
            return {
              ...templateFieldOption,
              ...response.data,
            };
          return templateFieldOption;
        })
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message || "Error removing autofill configuration.", type: "error" });
      throw e;
    }
  };

  useEffect(() => {
    getAllTemplateFields();
  }, []);

  const sortedTemplateFieldOptions = useMemo(
    () => templateFieldOptions.sort((a, b) => a.name.localeCompare(b.name)),
    [templateFieldOptions]
  );

  return {
    loading,
    templateFieldOptions: sortedTemplateFieldOptions,
    addTemplateField,
    updateTemplateFieldAutofill,
    removeTemplateFieldAutofill,
  };
};

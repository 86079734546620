export enum TypeOfValuation {
  BPO = "BPO",
  INTERIOR_BPO = "INTERIOR_BPO",
  EXTERIOR_BPO = "EXTERIOR_BPO",
  APPRAISAL = "APPRAISAL",
  ARBPO = "ARBPO",
}

export const typeOfValuationLabelMap: Record<TypeOfValuation, string> = {
  BPO: "BPO",
  INTERIOR_BPO: "Interior BPO",
  EXTERIOR_BPO: "Exterior BPO",
  APPRAISAL: "Appraisal",
  ARBPO: "ARBPO",
};

export enum TypeOfHOAPaymentFrequency {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
}

export enum TypeOfProgram {
  _12_MONTH = "_12_MONTH",
  _24_MONTH = "_24_MONTH",
}

export const typeOfProgramLabelMap: Record<TypeOfProgram, string> = {
  _12_MONTH: "12 Month",
  _24_MONTH: "24 Month",
};

export interface ApplicationsPropertyFinance {
  id: string;
  created: string;
  applicationsPropertiesId: string;
  monthlyRentCents: number | null;
  securityDepositCents: number | null;
  monthlyHocCents: number | null;
  upfrontHocCents: number | null;
  leaseStartDate: string | null;
  valuationType: TypeOfValuation | null;
  recordingFeeCents: number | null;
  titleFeeCents: number | null;
  lateFeeCents: number | null;
  purchasePriceCents: number | null;
  askingPriceCents: number | null;
  appraisedPriceCents: number | null;
  allInHudCents: number | null;
  loanAmountCents: number | null;
  inspectionPriceCents: number | null;
  depositAmountCents: number | null;
  resalePriceCents: number | null;
  titleCostCents: number | null;
  lenderCostCents: number | null;
  sellersCreditCents: number | null;
  ddFeeCents: number | null;
  appraisalPriceCents: number | null;
  insuranceCents: number | null;
  hoaCents: number | null;
  taxesCents: number | null;
  purchaseVsAsking: number | null;
  purchaseVsAppraisals: number | null;
  tenantsHocAfterYearCents: number | null;
  noiCents: number | null;
  grossYield: number | null;
  capRate: number | null;
  closingCosts: number | null;
  insurancePercentPrice: number | null;
  hoaPaymentFrequency: TypeOfHOAPaymentFrequency | null;
  currentLeaseTerm: TypeOfProgram | null;
  originalLengthOfLease: number | null;
  actualSaleDate: string | null;
  originalDti: number | null;
  projectedPriceSaleCents: number | null;
  actualPriceSaleCents: number | null;
  aggClosingCostsSaleCents: number | null;
  hoaName: string | null;
  firstMonthProratedRentCents: number | null;
}

import { ClientPersonHeader } from "@/components/partial";

import { Person, ProgramClient } from "@/types";
import { Box } from "@mui/system";
import ClientPropertiesTable from "./ClientPropertiesTable";
import { useApplicationsProperties } from "@/hooks";
import { StatusOfApplicationProperty } from "@l4s/client-property-models";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

export default function ClientPropertiesList({ person, programClient }: Props) {
  const useApplicationsPropertiesInstance = useApplicationsProperties(person.id);
  const { applicationsProperties } = useApplicationsPropertiesInstance;

  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box sx={{ mt: 1 }} />
      <ClientPropertiesTable
        title="Closed Property"
        disableAddButton
        useApplicationsPropertiesInstance={{
          ...useApplicationsPropertiesInstance,
          applicationsProperties: applicationsProperties.filter((ap) => ap.status === StatusOfApplicationProperty.CLOSED),
        }}
      />
      <Box sx={{ mt: 1 }} />
      <ClientPropertiesTable
        title={`${person.nameFirst}'s Properties`}
        useApplicationsPropertiesInstance={{
          ...useApplicationsPropertiesInstance,
          applicationsProperties: applicationsProperties.filter((ap) => ap.status !== StatusOfApplicationProperty.CLOSED),
        }}
      />
      <Box sx={{ mt: 50 }} />
    </>
  );
}

import { Route, BrowserRouter, Routes } from "react-router-dom";

import LoginRouter from "./LoginRouter";
import AuthenticatedRouter from "./AuthenticatedRouter";
import { NotFoundPage } from "@/components/view";

const RootRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login/*" element={<LoginRouter />} />
      <Route path="*" element={<AuthenticatedRouter />} />
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

export default RootRouter;

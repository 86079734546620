import { WorkOrderSeverity } from "@/types";
import { Chip, ChipProps, Typography } from "@mui/material";
import { FC } from "react";
import { PriorityHighOutlined } from "@mui/icons-material";

interface WorkOrderSeverityChipOption {
  icon?: ChipProps["icon"];
  color?: ChipProps["color"];
  label: string;
}
const workOrderSeverityToChipOptionMap: Record<WorkOrderSeverity, WorkOrderSeverityChipOption> = {
  [WorkOrderSeverity.URGENT]: {
    icon: <PriorityHighOutlined />,
    color: "warning",
    label: "Urgent",
  },
  [WorkOrderSeverity.NORMAL]: {
    color: "info",
    label: "Normal",
  },
  [WorkOrderSeverity.EMERGENCY]: {
    icon: <PriorityHighOutlined />,
    color: "error",
    label: "Emergency",
  },
};

interface WorkOrderSeverityChipProps extends ChipProps {
  severity: WorkOrderSeverity;
  hideLabel?: boolean;
}

const WorkOrderSeverityChip: FC<WorkOrderSeverityChipProps> = ({ severity, hideLabel = false, sx, ...props }) => {
  const { icon, color, label } = workOrderSeverityToChipOptionMap[severity];

  if (hideLabel && !icon) return null;

  return (
    <Chip
      size="small"
      icon={icon}
      color={color}
      label={
        !hideLabel && (
          <Typography color="inherit" variant="h6">
            {label}
          </Typography>
        )
      }
      sx={
        hideLabel
          ? {
              ".MuiChip-label": {
                paddingLeft: 0,
              },
              ...sx,
            }
          : sx
      }
      {...props}
    />
  );
};

export default WorkOrderSeverityChip;

import { useEffect, useMemo } from "react";

// material-ui
import { Box, Grid } from "@mui/material";

import { ClientPersonHeader, ClientProgramHeader, ClientNoteTableModule, ClientGraduation } from "@/components/partial";
import { GRID_SPACING } from "@/config";
import { Person, ProgramClient } from "@/types";
import { useActiveProgramClientThatUsesHOCByPersonId, useCoachingHistory, useToast } from "@/hooks";
import { usePersonFinancesCredit, usePersonFinancesHOC } from "@/hooks/data-hooks/person-finances";
import { LineChartDataCard } from "@/components/partial/LineChartDataCard";
import { DataPoint } from "@/components/partial/LineChartDataCard/LineChartDataCard";
import CoachingHistory from "@/components/partial/ClientGraduation/CoachingSnapshots/CoachingHistory";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

interface HOCData {
  data: DataPoint[];
  min: number;
  max: number;
}

function ClientTreatmentPlan(props: Props) {
  const { person, programClient } = props;
  const { addToast } = useToast();
  const { credit, loadPersonFinancesCredit } = usePersonFinancesCredit();
  const { HOC, loadPersonFinancesHOC } = usePersonFinancesHOC();

  const {
    activeProgramClientThatUsesHOCByPersonId,
    loadingActiveProgramClientThatUsesHOCByPersonId,
    fetchActiveProgramClientThatUsesHOCByPersonId,
  } = useActiveProgramClientThatUsesHOCByPersonId(programClient.personId);

  const useCoachingHistoryInstance = useCoachingHistory(programClient.id);

  useEffect(() => {
    (async () => {
      try {
        await loadPersonFinancesCredit(person.id);
      } catch (e) {
        addToast({
          message: "Unable to fetch current credit details.",
          type: "error",
        });
      }

      try {
        await fetchActiveProgramClientThatUsesHOCByPersonId();
      } catch (e) {
        addToast({
          message: "Unable to fetch active rental program client.",
          type: "error",
        });
      }
    })();
  }, [person.id]);

  useEffect(() => {
    (async () => {
      if (activeProgramClientThatUsesHOCByPersonId?.id) {
        try {
          await loadPersonFinancesHOC(activeProgramClientThatUsesHOCByPersonId.id);
        } catch (e) {
          addToast({
            message: "Unable to fetch current HOC details.",
            type: "error",
          });
        }
      }
    })();
  }, [activeProgramClientThatUsesHOCByPersonId]);

  const creditData: DataPoint[] = useMemo(
    () =>
      credit.allCredit.map((c) => ({
        x: c.created,
        y: c.score,
      })),
    [credit]
  );

  const hocData: HOCData = useMemo(() => {
    let max = HOC.allHOC[0] ? HOC.allHOC[0].totalProgramClientHocCents / 100 + 1000 : 10000;
    let min = Math.max((HOC.allHOC[0]?.totalProgramClientHocCents || 0) / 100 - 1000, 0);

    const data = HOC.allHOC.map((hoc) => {
      max = Math.max(max, hoc.totalProgramClientHocCents / 100);
      min = Math.min(min, hoc.totalProgramClientHocCents / 100);

      return {
        x: hoc.transactionDate,
        y: hoc.totalProgramClientHocCents / 100,
      };
    });

    return {
      data,
      min,
      max,
    };
  }, [HOC]);

  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box sx={{ marginBottom: "12px" }} />
      <ClientProgramHeader {...props} />
      <Box sx={{ marginBottom: "12px" }} />
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={18} md={6}>
          <LineChartDataCard
            data={creditData}
            dataPointName="Credit Score"
            yMin={300}
            yMax={900}
            chartTitle="Credit Score History"
            cardTitle="Current Credit Score"
          />
        </Grid>
        {activeProgramClientThatUsesHOCByPersonId && (
          <Grid item xs={18} md={6}>
            <LineChartDataCard
              data={hocData.data}
              dataPointName="Total HOC Balance"
              yMin={hocData.min}
              yMax={hocData.max}
              chartTitle="Home Ownership Credit"
              cardTitle="Total HOC Balance"
              textOverride={
                loadingActiveProgramClientThatUsesHOCByPersonId
                  ? "Loading..."
                  : !activeProgramClientThatUsesHOCByPersonId
                  ? "No active rental with savings program client"
                  : undefined
              }
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ marginBottom: "12px" }} />
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <ClientGraduation {...props} useCoachingHistory={useCoachingHistoryInstance} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClientNoteTableModule programClientId={programClient.id} />
        </Grid>
        <Grid alignItems={"center"} item xs={12} md={6}>
          <CoachingHistory useCoachingHistory={useCoachingHistoryInstance} />
        </Grid>
      </Grid>
    </>
  );
}

export default ClientTreatmentPlan;

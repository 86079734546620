import { ClosedReason, JourneyMilestoneDtoModel } from "@/types";
import Typography from "@mui/material/Typography";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { DateFormat } from "@/utils";
import { format } from "date-fns";

interface CaptionProps {
  input: JourneyMilestoneDtoModel;
}

const MilestoneCaption = ({ input }: CaptionProps) => {
  // if milestone has been completed
  if (input?.closedReason === ClosedReason.COMPLETED) {
    return (
      <Typography sx={{ display: "flex", alignItems: "center" }} variant="caption">
        <WatchLaterIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
        {`Completed on ${format(new Date(input?.closedAt), DateFormat.DISPLAY_DATE_TIME)}`}
      </Typography>
    );
    // if milestone was voided
  } else if (input?.closedReason === ClosedReason.VOIDED) {
    return (
      <Typography sx={{ display: "flex", alignItems: "center" }} variant="caption">
        <WatchLaterIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
        {`Voided on ${format(new Date(input?.closedAt), DateFormat.DISPLAY_DATE_TIME)}`}
      </Typography>
    );
    // if milestone has been started
  } else if (!input?.closedAt && input?.createdAt) {
    return (
      <Typography sx={{ display: "flex", alignItems: "center" }} variant="caption">
        <WatchLaterIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
        {`Started on ${format(new Date(input?.createdAt), DateFormat.DISPLAY_DATE_TIME)}`}
      </Typography>
    );
  }
};

export default MilestoneCaption;

import { bridgeV2ApiInstance, routes } from "@/services";
import { useEffect, useState } from "react";
import { RequirementDefinitionDtoModel } from "@/types/Milestones/RequirementDefinition";

export interface UseRequirementsDefinitionsHookInstance {
  requirementDefinitions: Array<RequirementDefinitionDtoModel>;
  loading: boolean;
}

export const useRequirementDefinitions = (milestoneId: string): UseRequirementsDefinitionsHookInstance => {
  const [loading, setLoading] = useState<boolean>(true);
  const [requirementDefinitions, setRequirementDefinitionss] = useState<Array<RequirementDefinitionDtoModel>>([]);

  const getRequirementDefinitions = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<RequirementDefinitionDtoModel>>(routes.GET_REQUIREMENT_DEFINITIONS, {
        params: { milestoneId },
      });
      setRequirementDefinitionss(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequirementDefinitions();
  }, [milestoneId]);

  return {
    requirementDefinitions,
    loading,
  };
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { JourneyMilestoneDtoModel } from "@/types";
import { useEffect, useState } from "react";

export interface UseMilestoneHook {
  loading: boolean;
  getMilestone: () => Promise<JourneyMilestoneDtoModel>;
}

export const useMilestone = (milestoneId: string): UseMilestoneHook => {
  const [loading, setLoading] = useState<boolean>(true);

  const getMilestone = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<JourneyMilestoneDtoModel>(routes.GET_MILESTONE(milestoneId));
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMilestone();
  }, [milestoneId]);

  return {
    loading,
    getMilestone,
  };
};

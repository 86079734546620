import { Route, Routes, useNavigate } from "react-router-dom";

import { ClientEnvelopes, EnvelopeDetail, NotFoundPage, TemplateFillDetail } from "@/components/view";
import { FeatureFlag, Person, ProgramClient } from "@/types";
import { useFeature } from "@/contexts";
import { useEffect } from "react";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

const EnvelopeRouter = (props: Props) => {
  const releaseDocumentAutomation = useFeature(FeatureFlag.RELEASE_DOCUMENT_AUTOMATION);
  const navigate = useNavigate();

  useEffect(() => {
    if (releaseDocumentAutomation === false) navigate("/");
  }, [releaseDocumentAutomation]);

  return (
    <Routes>
      <Route path="/" element={<ClientEnvelopes {...props} />} />
      <Route path="/:envelopeId" element={<EnvelopeDetail {...props} />} />
      <Route path="/:envelopeId/:templateFillId" element={<TemplateFillDetail {...props} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default EnvelopeRouter;

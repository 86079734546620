export enum TemplateFillStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface TemplateFill {
  id: string;
  created: string;
  templateId: string;

  // Template details
  templateName: string;
  templateDescription: string;

  // Computed fields
  status: TemplateFillStatus;
}

export interface TemplateFillFieldValue {
  id: string;
  created: string;
  templateFillId: string;
  templateFieldId: string;
  value: string;
}

import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { UseCashForKeysOffers } from "@/hooks";
import CashForKeysForm from "./CashForKeysForm";
import CashForKeysRow from "./CashForKeysRow";
import { CashForKeysOffer } from "@/types";
import { sortByDate } from "@/utils/sort";
import { SkeletonList } from "@/components/base";

interface Props {
  useCashForKeysOffers: UseCashForKeysOffers;
}

const CashForKeys = ({ useCashForKeysOffers }: Props) => {
  const [editingOffer, setEditingOffer] = useState<CashForKeysOffer>();
  const { cashForKeysOffers, isLoading, loadCashForKeysOffers } = useCashForKeysOffers;

  useEffect(() => {
    loadCashForKeysOffers();
  }, []);

  return (
    <Grid item container flexDirection="column">
      {isLoading && <SkeletonList height={30} count={3} />}
      {!editingOffer &&
        cashForKeysOffers
          ?.sort(sortByDate("offerDate"))
          ?.map((offer) => <CashForKeysRow onEdit={() => setEditingOffer(offer)} offer={offer} key={offer.id} />)}
      <CashForKeysForm onCancel={() => setEditingOffer(undefined)} offer={editingOffer} useCashForKeysOffers={useCashForKeysOffers} />
    </Grid>
  );
};

export default CashForKeys;

// third-party
import { IconClipboardPlus, IconFileSpreadsheet, IconFiles, IconFolders, IconHome2, IconWand, IconNotebook } from "@tabler/icons";
import { FeatureFlag, NavItemType } from "@/types";
import { useFeature } from "@/contexts";

export const useAdminRoutes = (): NavItemType | undefined => {
  const releaseDocumentAutomation = useFeature(FeatureFlag.RELEASE_DOCUMENT_AUTOMATION);

  return {
    id: "admin",
    title: <span>Admin</span>,
    type: "group",
    children: [
      {
        id: "AdminProperties",
        title: <span>All Properties</span>,
        type: "item",
        icon: IconHome2,
        url: `/admin/properties`,
        breadcrumbs: false,
      },
      ...(releaseDocumentAutomation
        ? [
            {
              id: "TemplatedDocuments",
              title: <span>Documents</span>,
              type: "collapse",
              icon: IconFiles,
              children: [
                {
                  id: "TemplatedDocumentsTemplates",
                  title: <span>Templates</span>,
                  type: "item",
                  icon: IconFileSpreadsheet,
                  url: `/admin/documents/templates`,
                },
                {
                  id: "TemplatedDocumentsTemplateFields",
                  title: <span>Template Fields</span>,
                  type: "item",
                  icon: IconClipboardPlus,
                  url: `/admin/documents/template-fields`,
                },
                {
                  id: "TemplatedDocumentsAutofillEntities",
                  title: <span>Autofill Entities</span>,
                  type: "item",
                  icon: IconWand,
                  url: `/admin/documents/autofill-entities`,
                },
                {
                  id: "TemplatedDocumentsDefinedEnvelopes",
                  title: <span>Defined Envelopes</span>,
                  type: "item",
                  icon: IconFolders,
                  url: `/admin/documents/defined-envelopes`,
                },
              ],
            },
          ]
        : []),
      {
        id: "coachingNotes",
        title: <span>Manage Notes</span>,
        type: "item",
        icon: IconNotebook,
        url: "/coaching/notes",
      },
    ],
  };
};

export enum RoleOfAccount {
  CLIENT = "CLIENT",
  MANAGER = "MANAGER",
  PARTNER = "PARTNER",
  SERVICE = "SERVICE",
}

export interface AuthenticatedAccount {
  accountId?: string;
  devAccountId?: string;
  devManagerId?: string;
  devUserRoles?: Array<string>;
  email?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  login?: string;
  managerId?: string;
  managerRole?: string;
  phone?: string;
  role?: RoleOfAccount;
}

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

import { HocPayment } from "@l4s/person-finances-models";

export interface HOCData {
  totalHOCCents: number;
  allHOC: HocPayment[];
}

export interface UsePersonFinancesHOC {
  HOC: HOCData;
  personFinancesHOCLoading: boolean;
  loadPersonFinancesHOC: (programClientId: string) => Promise<void>;
}

export const usePersonFinancesHOC = (): UsePersonFinancesHOC => {
  const [personFinancesHOCLoading, setPersonFinancesHOCLoading] = useState<boolean>(true);
  const [HOC, setHOC] = useState<HOCData>({
    totalHOCCents: 0,
    allHOC: [],
  });

  const loadPersonFinancesHOC = async (programClientId: string): Promise<void> => {
    try {
      setPersonFinancesHOCLoading(true);
      const currentHOCResponse = bridgeV2ApiInstance.get<number>(routes.GET_TOTAL_HOC_BY_PROGRAM_CLIENT_ID(programClientId));
      const allHOCResponse = bridgeV2ApiInstance.get<Array<HocPayment>>(routes.GET_ALL_HOC_BY_PROGRAM_CLIENT_ID(programClientId));

      const data = await Promise.all([currentHOCResponse, allHOCResponse])
        .then(([{ data: totalHOCCents }, { data: allHOC }]) => ({ totalHOCCents, allHOC }))
        .finally(() => setPersonFinancesHOCLoading(false));

      setHOC(data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching person finances HOC data.");
    }
  };

  return {
    HOC,
    personFinancesHOCLoading,
    loadPersonFinancesHOC,
  };
};

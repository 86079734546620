import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, TooltipItem } from "chart.js";
import { backgroundColorOptions } from "./chartUtils";
import { PlaidCategorySortTransactions } from "@l4s/plaid-models";
import { useMemo } from "react";
import { MappedPlaidFinanceCategory } from "@/hooks";
import { displayUsDollars } from "@/utils";

const options: ChartOptions<"pie"> = {
  responsive: true,
  maintainAspectRatio: true,
};
interface Props {
  categorySorts: Array<PlaidCategorySortTransactions>;
}

function CategorySpendingChart({ categorySorts }: Props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const chartData = useMemo(() => {
    const labels: Array<string> = [];
    const data: Array<number> = [];
    const backgroundColor: Array<string> = [];

    categorySorts.forEach((categoryRecord, i) => {
      // remove credits to account
      if (categoryRecord.totalAmountSpentDollars <= 0) {
        return;
      }

      labels.push(MappedPlaidFinanceCategory[categoryRecord.primaryCategory]);
      data.push(categoryRecord.totalAmountSpentDollars);
      backgroundColor.push(backgroundColorOptions[i]);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          tooltip: {
            callbacks: {
              label: function (tooltipItem: TooltipItem<"pie">) {
                return displayUsDollars(Math.floor(tooltipItem.raw as number));
              },
            },
          },
        },
      ],
    };
  }, [categorySorts]);

  return (
    <>
      <Pie data={chartData} options={options} />
    </>
  );
}

export default CategorySpendingChart;

import { TextButton, Drawer } from "@/components/base";
import { MainCard } from "@/components/berry";
import { AddressForm, AddressFormFields } from "@/components/partial";
import { UsePropertyHook, useToast } from "@/hooks";
import { Typography } from "@mui/material";
import { IconPencil } from "@tabler/icons";
import { useState } from "react";

interface Props {
  usePropertyInstance: UsePropertyHook;
}

function AddressSection({ usePropertyInstance }: Props) {
  const [isAddressFormOpen, setAddressFormOpen] = useState<boolean>(false);
  const { property, updateAddress } = usePropertyInstance;

  const { addToast } = useToast();

  const handlePropertyUpdate = async (values: AddressFormFields) => {
    try {
      await updateAddress(values);
      setAddressFormOpen(false);
    } catch (e) {
      addToast({
        type: "error",
        message: e.message,
      });
    }
  };

  if (!property) return null;

  return (
    <>
      <Typography variant="h1">
        {property.addressStreet} {property.addressUnit}
      </Typography>
      <Typography variant="h3">
        {property.addressCity}, {property.addressState} {property.addressZip}
      </Typography>
      <TextButton onClick={() => setAddressFormOpen(true)} sx={{ mt: 1, mb: 0.5 }} StartIcon={IconPencil}>
        Edit address
      </TextButton>
      <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setAddressFormOpen(false)} open={isAddressFormOpen}>
        <MainCard title="Edit Address">
          <AddressForm
            initialValues={{
              addressStreet: property.addressStreet,
              addressUnit: property.addressUnit,
              addressCity: property.addressCity,
              addressState: property.addressState,
              addressZip: property.addressZip,
            }}
            handleSubmit={handlePropertyUpdate}
          />
        </MainCard>
      </Drawer>
    </>
  );
}

export default AddressSection;

import { UsePropertiesHook } from "@/hooks";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Drawer } from "@/components/base";
import { useState } from "react";
import CreatePropertyForm from "./CreatePropertyForm";

interface Props {
  setSearch: (value: string) => void;
  usePropertiesInstance: UsePropertiesHook;
}

function AdminListPropertiesTableHeader({ setSearch, usePropertiesInstance }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const { totalCount } = usePropertiesInstance;

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5} container direction="column" gap={0.5}>
        <Typography variant="h2">All Properties</Typography>
        {!!totalCount && <Typography variant="body2">{totalCount} properties</Typography>}
      </Grid>
      <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
        <TextField onChange={onSearchChange} size="small" variant="outlined" fullWidth placeholder="Search" />
        <Button variant="contained" endIcon={<AddCircleOutline />} onClick={() => setIsAddFormOpen(true)}>
          Add
        </Button>
      </Grid>
      <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setIsAddFormOpen(false)} open={isAddFormOpen}>
        <CreatePropertyForm usePropertiesInstance={usePropertiesInstance} />
      </Drawer>
    </Grid>
  );
}

export default AdminListPropertiesTableHeader;

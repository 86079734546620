import { TemplateFieldToFill, TemplateFieldTypeDisplayMap, isDocusignTemplateField } from "@/types";

import { Chip, Collapse, Divider, Grid, Typography } from "@mui/material";
import TemplateFieldValueForm from "./TemplateFieldValueForm";
import { UseTemplateFillFieldValuesHook } from "@/hooks";
import { CancelOutlined, CheckCircleOutline, ChevronRight, ExpandMore } from "@mui/icons-material";
import { useFieldStatusTheme } from "../util";
import { useEffect, useState } from "react";
import { DocusignIcon } from "@/components/base";
import { DocumentsAutofillResponsePayload } from "@l4s/documents-autofill-models";

interface TemplateFieldRowProps {
  autofillValue: DocumentsAutofillResponsePayload | undefined;
  templateFieldToFill: TemplateFieldToFill;
  templateFillFieldValuesInstance: UseTemplateFillFieldValuesHook;
  templateFillId: string;
  isReadOnly: boolean;
}

function TemplateFieldRow({
  templateFieldToFill,
  templateFillFieldValuesInstance,
  templateFillId,
  isReadOnly,
  autofillValue,
}: TemplateFieldRowProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { themeColor, isSatisfied } = useFieldStatusTheme(templateFieldToFill);

  useEffect(() => {
    if (templateFieldToFill.value) {
      setIsCollapsed(true);
    }
  }, [templateFieldToFill]);

  useEffect(() => {
    if (autofillValue && autofillValue[templateFieldToFill.autofillEntityField] && !templateFieldToFill.value) {
      setIsCollapsed(false);
    }
  }, [autofillValue]);

  return (
    <>
      <Grid sx={{ paddingY: 1, paddingLeft: 1 }} key={templateFieldToFill.id} item container flexWrap="nowrap" spacing={0.5}>
        <Grid item container direction="column" spacing={1}>
          <Grid
            sx={{ cursor: isReadOnly ? "inherit" : "pointer" }}
            onClick={() => setIsCollapsed(!isCollapsed)}
            item
            container
            flexWrap="nowrap"
          >
            <Grid flex={1} item container direction="column" sx={{ gap: 0.5 }}>
              <Typography variant="h4">
                {templateFieldToFill.name}
                {templateFieldToFill.isRequired && (
                  <Typography lineHeight={0} fontSize="large" variant="overline" color="error">
                    *
                  </Typography>
                )}
              </Typography>
              <Typography variant="body1">{templateFieldToFill.value || <i>{templateFieldToFill.description}</i>}</Typography>
            </Grid>
            <Grid item>
              <Chip
                color={themeColor}
                icon={
                  isSatisfied ? (
                    isDocusignTemplateField(templateFieldToFill.type) ? (
                      <DocusignIcon style={{ width: "1rem", borderRadius: "50%" }} />
                    ) : (
                      <CheckCircleOutline />
                    )
                  ) : (
                    <CancelOutlined />
                  )
                }
                size="small"
                label={TemplateFieldTypeDisplayMap[templateFieldToFill.type]}
              />
            </Grid>
            {!isReadOnly && (
              <Grid item alignItems="center" sx={{ height: "100%" }} container flex={0}>
                <Grid item>{isCollapsed ? <ChevronRight /> : <ExpandMore />}</Grid>
              </Grid>
            )}
          </Grid>
          {!isReadOnly && (
            <Collapse in={!isCollapsed} timeout="auto">
              <TemplateFieldValueForm
                autofillValue={autofillValue}
                templateFillId={templateFillId}
                templateFillFieldValuesInstance={templateFillFieldValuesInstance}
                templateFieldToFill={templateFieldToFill}
              />
            </Collapse>
          )}
        </Grid>
      </Grid>
    </>
  );
}

interface TemplateFillFieldsListProps {
  autofillValues: Array<DocumentsAutofillResponsePayload>;
  templateFieldsToFill: Array<TemplateFieldToFill>;
  templateFillFieldValuesInstance: UseTemplateFillFieldValuesHook;
  templateFillId: string;
  isReadOnly: boolean;
}

function TemplateFillFieldsList({
  autofillValues,
  templateFieldsToFill,
  templateFillId,
  templateFillFieldValuesInstance,
  isReadOnly,
}: TemplateFillFieldsListProps) {
  return (
    <Grid container direction="column" spacing={1}>
      {templateFieldsToFill.map((templateFieldToFill, i) => (
        <div key={templateFieldToFill.id}>
          <TemplateFieldRow
            autofillValue={autofillValues.find((afv) => afv.entityType === templateFieldToFill.autofillEntity)}
            isReadOnly={isReadOnly}
            templateFillId={templateFillId}
            templateFillFieldValuesInstance={templateFillFieldValuesInstance}
            templateFieldToFill={templateFieldToFill}
          />
          {i !== templateFieldsToFill.length - 1 && <Divider sx={{ marginY: 1 }} />}
        </div>
      ))}
      {!Boolean(templateFieldsToFill.length) && (
        <Typography variant="body1">
          <i>No template fields have been added yet. Navigate to the templateto add fields..</i>
        </Typography>
      )}
    </Grid>
  );
}

export default TemplateFillFieldsList;

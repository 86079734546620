import { useState } from "react";
import moment from "moment";
import { Accordion, AccordionSummary, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { labelStatusOfPropertyEvaluationMap } from "@/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PropertyEvaluationDto } from "@l4s/client-property-models";
import { UsePropertyEvaluationsHookInstance, useToast } from "@/hooks";
import EvaluationCriteria from "./EvaluationCriteria";

interface Props {
  propertyEvaluation: PropertyEvaluationDto;
  usePropertyEvaluationsInstance: UsePropertyEvaluationsHookInstance;
  isUnsubmittedEvaluation: boolean;
  isMostRecentEvaluation: boolean;
  handleClose: () => void;
}

export default function Evaluation({
  propertyEvaluation,
  usePropertyEvaluationsInstance,
  isUnsubmittedEvaluation,
  isMostRecentEvaluation,
  handleClose,
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(isMostRecentEvaluation);
  const [savingEvaluationCriteria, setSavingEvaluationCriteria] = useState<boolean>(false);
  const { addToast } = useToast();

  const theme = useTheme();

  const { submitPropertyEvaluation, submittingPropertyEvaluation } = usePropertyEvaluationsInstance;

  async function handleSubmitPropertyEvaluation(shouldSendClientUpdateNotification: boolean): Promise<void> {
    try {
      await submitPropertyEvaluation(propertyEvaluation.id, shouldSendClientUpdateNotification);
    } catch (e) {
      addToast({
        type: "error",
        message: e.message,
      });
    }
    handleClose();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                width: "100%",
                padding: 2,
                borderRadius: theme.shape.borderRadius,
                display: "flex",
                gap: "1rem",
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Typography variant="h4">
                {labelStatusOfPropertyEvaluationMap[propertyEvaluation.status]} — {moment(propertyEvaluation.created).format("MM/DD/YY")}
              </Typography>
              {isUnsubmittedEvaluation ? (
                savingEvaluationCriteria ? (
                  <Chip icon={<CircularProgress size="1rem" />} size="small" label="Saving" />
                ) : (
                  <Chip icon={<CheckCircleIcon />} size="small" label="Saved" />
                )
              ) : null}
            </Box>
          </AccordionSummary>

          <EvaluationCriteria
            evaluationCriteria={propertyEvaluation.evaluationCriteria}
            setEvaluationCriteriaSaving={setSavingEvaluationCriteria}
            handleSubmit={handleSubmitPropertyEvaluation}
            submittingPropertyEvaluation={submittingPropertyEvaluation}
            isUnsubmittedEvaluation={isUnsubmittedEvaluation}
          />
        </Accordion>
      </Grid>
    </Grid>
  );
}

import { TabPanel } from "@mui/lab";
import { Tabs } from "@/components/base";
import { Tab } from "@mui/material";
import { ClientJourneyDtoModel, JourneyPathDtoModel } from "@/types";
import CurrentPathMilestoneTab from "./CurrentPathMilestoneTab";

interface Props {
  path: JourneyPathDtoModel;
  onMilestoneUpdate: () => Promise<void>;
  clientJourney: ClientJourneyDtoModel;
}

const MilestoneTabs = ({ path, clientJourney, onMilestoneUpdate }: Props) => {
  if (!path.length || path[0]?.closedAt) {
    return <></>;
  }

  return (
    <div>
      <Tabs
        tabsIds={path.map((mi) => mi.id)}
        tabToStart={path[0].id}
        orientation="horizontal"
        verticalLeftGridItemSize={4}
        TabListComponent={
          <>
            {path.map((milestone) => (
              <Tab key={milestone.id} value={milestone.id} label={milestone.definition.managerName} sx={{ border: 0 }} />
            ))}
          </>
        }
        TabPanelComponent={
          <>
            {path.map((milestone) => (
              <TabPanel key={milestone.id} value={milestone.id} sx={{ padding: "0rem 0rem 1rem 0rem" }}>
                <CurrentPathMilestoneTab
                  milestone={milestone}
                  clientJourney={clientJourney}
                  key={milestone.id}
                  onMilestoneUpdate={onMilestoneUpdate}
                  milestoneCompleted={false}
                />
              </TabPanel>
            ))}
          </>
        }
      />
    </div>
  );
};

export default MilestoneTabs;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useProgramClient, useToast } from "@/hooks";
import { Person, ProgramClient } from "@/types";
import { logToConsoleDebug } from "@/utils";

interface ChildProps {
  programClient: ProgramClient;
  person: Person;
}

interface Props {
  ChildComponent: React.ComponentType<ChildProps>;
  programClientId: string;
  person: Person;
}

const WithProgramClient = (props: Props) => {
  const { programClientId, ChildComponent } = props;
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { programClient, getProgramClient } = useProgramClient(programClientId);

  useEffect(() => {
    (async () => {
      try {
        await getProgramClient();
      } catch (e) {
        addToast({
          message: "Unable to find client.",
          type: "error",
        });
        navigate("/");
      }
    })();
  }, [programClientId]);

  useEffect(() => {
    logToConsoleDebug("WithProgramClient.tsx", "ProgramClient loaded", [programClient]);
  }, [programClient]);

  return programClient ? <ChildComponent {...props} programClient={programClient} /> : <></>;
};

export default WithProgramClient;

import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import { SkeletonList } from "@/components/base";

import { useNavigate } from "react-router-dom";

import AdminListPropertiesTableHeader from "./AdminListPropertiesTableHeader";
import { useProperties } from "@/hooks";
import { formatAddress } from "@/utils";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No properties.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

function AdminListProperties() {
  const [search, setSearch] = useState<string>();

  const usePropertiesInstance = useProperties(search);
  const { properties, loading, page, setPage, totalPages } = usePropertiesInstance;
  const navigate = useNavigate();

  return (
    <TableLayout
      maxHeight="70vh"
      pagination={!!totalPages && <Pagination count={totalPages} page={page} onChange={(_, val) => setPage(val)} />}
      title={<AdminListPropertiesTableHeader usePropertiesInstance={usePropertiesInstance} setSearch={setSearch} />}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!properties.length && !loading && <EmptyBody />}
          {loading && <LoadingBody />}
          {properties.map((row) => (
            <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {formatAddress(row.addressStreet, row.addressUnit, row.addressCity, row.addressZip, row.addressState)}
              </TableCell>
              <TableCell align="right">
                <ChevronRight />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableLayout>
  );
}

export default AdminListProperties;

export const ProgramLengthOptions = [
  { label: "12 Months", value: 12 },
  { label: "18 Months", value: 18 },
  { label: "24 Months", value: 24 },
  { label: "30 Months", value: 30 },
  { label: "36 Months", value: 36 },
  { label: "42 Months", value: 42 },
  { label: "48 Months", value: 48 },
];

export enum ProgramLength {
  TWELVEMONTHS = 12,
  TWENTYFOURMONTHS = 24,
}

export enum InitialDepositPayment {
  FULL = "FULL",
  PARTIAL = "PARTIAL",
}

export const InitialDepositPaymentOptions = [
  { label: "Full", value: InitialDepositPayment.FULL },
  { label: "Partial", value: InitialDepositPayment.PARTIAL },
];

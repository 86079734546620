import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseTaxes, useToast } from "@/hooks";
import { StateCode } from "@/types";
import { Tax, TypeOfTax } from "@/types/PropertyManagement";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Field } from "./useTaxForm";
import * as Yup from "yup";
import { AddressFormNoValidation } from "@/components/partial/AddressFormNoValidation";

interface Props {
  close: () => void;
  tax?: Tax;
  useTaxesInstance: UseTaxes;
  propertyId: string;
}

export const TaxTypeDisplayMap: Record<TypeOfTax, string> = {
  [TypeOfTax.CITY]: "City",
  [TypeOfTax.COUNTY]: "County",
  [TypeOfTax.MUNICIPAL]: "Municipal",
  [TypeOfTax.OTHER]: "Other",
  [TypeOfTax.SCHOOL]: "School",
};

function TaxForm({ tax, useTaxesInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createTax, updateTax } = useTaxesInstance;

  const formTitle = () => (tax?.id ? "Update tax" : "Add tax");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure().optional(),
    parcelNumber: Yup.string().ensure().required("Parcel number is a required field").typeError("Parcel number is a required field"),
    website: Yup.string().url().ensure().optional(),
    phone: Yup.string().ensure().optional(),
    type: Yup.mixed().oneOf(Object.values(TypeOfTax)).required("Type is a required field").typeError("Type is a required field"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      parcelNumber: tax?.parcelNumber ?? "",
      addressStreet: tax?.addressStreet ?? "",
      addressUnit: "",
      addressCity: tax?.addressCity ?? "",
      addressState: tax?.addressState ?? ("" as StateCode),
      addressZip: tax?.addressZip ?? "",
      phone: tax?.phone ?? "",
      email: tax?.email ?? "",
      website: tax?.website ?? "",
      type: tax?.type ?? ("" as TypeOfTax),
      name: tax?.name ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (tax) {
          await updateTax({
            ...values,
            id: tax.id,
          });
        } else {
          await createTax({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              id="name"
              label="Remit To"
              value={formik.values[Field.NAME]}
              onChange={(e) => formik.setFieldValue(Field.NAME, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Parcel Number"
              id="parcelNumber"
              value={formik.values[Field.PARCEL_NUMBER]}
              onChange={(e) => formik.setFieldValue(Field.PARCEL_NUMBER, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.parcelNumber}
              helperText={formik.errors.parcelNumber}
            />
          </Grid>
          <Grid item>
            <Dropdown
              id="type"
              label="Type of Tax"
              size="small"
              items={Object.values(TypeOfTax).map((type) => ({
                label: <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>{TaxTypeDisplayMap[type]}</div>,
                value: type,
              }))}
              value={formik.values[Field.TYPE]}
              handleChange={(value) => formik.setFieldValue(Field.TYPE, value)}
              error={!!formik.errors.type}
              helperText={formik.errors.type}
            />
          </Grid>
          <AddressFormNoValidation formik={formik}></AddressFormNoValidation>
          <Grid item>
            <TextField
              id="phone"
              label="Phone"
              value={formik.values[Field.PHONE]}
              onChange={(e) => formik.setFieldValue(Field.PHONE, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 10 }}
              type="tel"
              error={!!formik.errors.phone}
              helperText={formik.errors.phone}
            />
          </Grid>
          <Grid item>
            <TextField
              id="email"
              label="Email"
              value={formik.values[Field.EMAIL]}
              onChange={(e) => formik.setFieldValue(Field.EMAIL, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              type="email"
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item>
            <TextField
              id="website"
              label="Website"
              value={formik.values[Field.WEBSITE]}
              onChange={(e) => formik.setFieldValue(Field.WEBSITE, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.website}
              helperText={formik.errors.website}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default TaxForm;

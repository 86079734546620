import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { ExpectedPayment, PaymentPlan } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

interface LoadPaymentPlanInput {
  reload?: boolean;
}

interface CreatePaymentPlanInput {
  programClientId: string;
  paymentPlanName: string;
}

interface CreateExpectedPaymentInput {
  planId: string;
  paymentDate: string;
  paymentAmountCents: number;
}

export interface UsePaymentPlans {
  loadPaymentPlans: (input?: LoadPaymentPlanInput) => Promise<Array<PaymentPlan>>;
  createPaymentPlan: (input: CreatePaymentPlanInput) => Promise<PaymentPlan>;
  activatePaymentPlan: (paymentPlanId: string) => Promise<void>;
  createExpectedPayment: (input: CreateExpectedPaymentInput) => Promise<void>;
  updateExpectedPayment: (expectedPayment: ExpectedPayment) => Promise<void>;
  deleteExpectedPaymentById: (expectedPayment: ExpectedPayment) => Promise<void>;
  paymentPlans: Array<PaymentPlan>;
}

export const usePaymentPlans = (programClientId: string): UsePaymentPlans => {
  const [paymentPlans, setPaymentPlans] = useState<Array<PaymentPlan>>([]);

  const loadPaymentPlans: UsePaymentPlans["loadPaymentPlans"] = async (input) => {
    if (paymentPlans.length && !input?.reload) return paymentPlans;
    try {
      const response: { data: Array<PaymentPlan> } = await bridgeV2ApiInstance.get(
        routes.GET_PAYMENT_PLANS_BY_PROGRAM_CLIENT_ID(programClientId)
      );
      setPaymentPlans(response.data);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching payment plans.");
    }
  };

  const createPaymentPlan: UsePaymentPlans["createPaymentPlan"] = async (input) => {
    try {
      const response: { data: PaymentPlan } = await bridgeV2ApiInstance.post(routes.CREATE_PAYMENT_PLAN, input);
      setPaymentPlans((previousPaymentPlans) => [...previousPaymentPlans, response.data]);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating payment plan.");
    }
  };

  const activatePaymentPlan: UsePaymentPlans["activatePaymentPlan"] = async (paymentPlanId) => {
    try {
      await bridgeV2ApiInstance.put(routes.ACTIVATE_PAYMENT_PLAN, {
        paymentPlanId,
      });
      setPaymentPlans((previousPaymentPlans) =>
        previousPaymentPlans.map((paymentPlan) =>
          paymentPlan.id === paymentPlanId
            ? {
                ...paymentPlan,
                isCurrent: true,
              }
            : {
                ...paymentPlan,
                isCurrent: false,
              }
        )
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating expected payment.");
    }
  };

  const createExpectedPayment: UsePaymentPlans["createExpectedPayment"] = async (input) => {
    try {
      const response: { data: ExpectedPayment } = await bridgeV2ApiInstance.post(routes.CREATE_EXPECTED_PAYMENT, input);
      setPaymentPlans((previousPaymentPlans) =>
        previousPaymentPlans.map((paymentPlan) =>
          paymentPlan.id === response.data.planId
            ? { ...paymentPlan, expectedPayments: [...paymentPlan.expectedPayments, response.data] }
            : paymentPlan
        )
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating expected payment.");
    }
  };

  const updateExpectedPayment: UsePaymentPlans["updateExpectedPayment"] = async (expectedPayment) => {
    try {
      const response: { data: ExpectedPayment } = await bridgeV2ApiInstance.put(routes.UPDATE_EXPECTED_PAYMENT, {
        expectedPayment,
      });
      setPaymentPlans((previousPaymentPlans) =>
        previousPaymentPlans.map((paymentPlan) =>
          paymentPlan.id === response.data.planId
            ? {
                ...paymentPlan,
                expectedPayments: paymentPlan.expectedPayments.map((ep) => (ep.id === response.data.id ? { ...ep, ...response.data } : ep)),
              }
            : paymentPlan
        )
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating expected payment.");
    }
  };

  const deleteExpectedPaymentById: UsePaymentPlans["deleteExpectedPaymentById"] = async (expectedPayment) => {
    try {
      await bridgeV2ApiInstance.delete(routes.DELETE_EXPECTED_PAYMENT(expectedPayment.id));
      setPaymentPlans((previousPaymentPlans) =>
        previousPaymentPlans.map((paymentPlan) =>
          paymentPlan.id === expectedPayment.planId
            ? { ...paymentPlan, expectedPayments: paymentPlan.expectedPayments.filter((ep) => ep.id !== expectedPayment.id) }
            : paymentPlan
        )
      );
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error deleting expected payment.");
    }
  };

  return {
    paymentPlans,
    loadPaymentPlans,
    createPaymentPlan,
    createExpectedPayment,
    deleteExpectedPaymentById,
    updateExpectedPayment,
    activatePaymentPlan,
  };
};

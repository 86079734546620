import { bridgeV2ApiInstance } from "@/services";
import { UPDATE_PROPERTY_EVALUATION_CRITERIA } from "@/services/routes";
import { mapUnknownToAxiosError } from "@/utils";
import { PropertyEvaluationCriteriaDto } from "@l4s/client-property-models";
import { useState } from "react";

export interface UsePropertyEvaluationCriteriaHookInstance {
  loading: boolean;
  updatePropertyEvaluationCriteria: (
    propertyEvaluationCriteriaId: string,
    passed: boolean | null,
    notes: string | null
  ) => Promise<PropertyEvaluationCriteriaDto>;
}

export const usePropertyEvaluationCriteria = (): UsePropertyEvaluationCriteriaHookInstance => {
  const [loading, setLoading] = useState<boolean>(false);

  const updatePropertyEvaluationCriteria = async (
    propertyEvaluationCriteriaId: string,
    passed: boolean | null,
    notes: string | null
  ): Promise<PropertyEvaluationCriteriaDto> => {
    try {
      const { data: criteria } = await bridgeV2ApiInstance.put<PropertyEvaluationCriteriaDto>(
        UPDATE_PROPERTY_EVALUATION_CRITERIA(propertyEvaluationCriteriaId),
        {
          passed,
          notes,
        }
      );
      return criteria;
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error updating property evaluation criteria.");
    }
  };

  return {
    loading,
    updatePropertyEvaluationCriteria,
  };
};

import { useEffect, useState } from "react";
import { Envelope } from "@/types";
import { bridgeV2ApiInstance } from "@/services";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { CREATE_EMPTY_ENVELOPE, CREATE_ENVELOPE_FROM_DEFINED_ENVELOPE, GET_ALL_ENVELOPES } from "@/services/routes";

export type CreateEnvelopeInput = {
  name: string;
};

export type CreateEnvelopeFromDefinedEnvelopeInput = {
  definedEnvelopeId: string;
};

export interface UseEnvelopesHook {
  envelopes: Array<Envelope>;
  getEnvelopes: () => Promise<Array<Envelope>>;
  createEnvelope: (input: CreateEnvelopeInput) => Promise<void>;
  createEnvelopeFromDefinedEnvelope: (input: CreateEnvelopeFromDefinedEnvelopeInput) => Promise<void>;
  loading: boolean;
}

const searchEnvelopes = (envelopes: Array<Envelope>, search: string) => {
  return envelopes.filter((envelope) => envelope.name.toLowerCase().includes(search.toLowerCase()));
};

export const useEnvelopes = (personId: string, search?: string): UseEnvelopesHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [envelopes, setEnvelopes] = useState<Array<Envelope>>([]);
  const [filteredEnvelopes, setFilteredEnvelopes] = useState<Array<Envelope>>([]);

  const { addToast } = useToast();

  const getEnvelopes: UseEnvelopesHook["getEnvelopes"] = async () => {
    try {
      const response = await bridgeV2ApiInstance.get<Array<Envelope>>(GET_ALL_ENVELOPES, { params: { personId } });
      setEnvelopes(response.data);
      setLoading(false);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  const createEnvelope: UseEnvelopesHook["createEnvelope"] = async (input: CreateEnvelopeInput) => {
    try {
      const response = await bridgeV2ApiInstance.post<Envelope>(CREATE_EMPTY_ENVELOPE, {
        ...input,
        personId,
      });
      setEnvelopes((existingEnvelopes) => [...existingEnvelopes, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw error;
    }
  };

  const createEnvelopeFromDefinedEnvelope: UseEnvelopesHook["createEnvelopeFromDefinedEnvelope"] = async (input) => {
    try {
      const response = await bridgeV2ApiInstance.post<Envelope>(CREATE_ENVELOPE_FROM_DEFINED_ENVELOPE, {
        ...input,
        personId,
      });
      setEnvelopes((existingEnvelopes) => [...existingEnvelopes, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw error;
    }
  };

  useEffect(() => {
    getEnvelopes();
  }, []);

  useEffect(() => {
    if (envelopes && search) {
      const searchedEnvelopes = searchEnvelopes(envelopes, search);
      setFilteredEnvelopes(searchedEnvelopes);
    }
  }, [search, envelopes]);

  return {
    envelopes: search ? filteredEnvelopes : envelopes,
    getEnvelopes,
    createEnvelopeFromDefinedEnvelope,
    createEnvelope,
    loading,
  };
};

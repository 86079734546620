// project import
import LAYOUT, { Props } from "@/constant";
import MainLayout from "./MainLayout";
import MinimalLayout from "./MinimalLayout";

function Layout({ variant = LAYOUT.main, children }: Props) {
  switch (variant) {
    case LAYOUT.minimal:
      return <MinimalLayout>{children}</MinimalLayout>;
    case LAYOUT.noauth:
      return <MainLayout>{children}</MainLayout>;
    default:
      return <MainLayout>{children}</MainLayout>;
  }
}

export default Layout;

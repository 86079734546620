import moment from "moment";
import { Grid } from "@mui/material";

import { HeaderOption } from "@/components/base";
import { ExitPlan } from "@/types";
import { mapUtilitiesStatusToDisplay } from "./ExitPlanFormTypes";

interface Props {
  exitPlan: ExitPlan;
}

function CoachExitPlanDisplay({ exitPlan }: Props) {
  const renderContent = () => {
    return (
      <Grid container direction="column" item xs={12}>
        <div>
          <HeaderOption
            lg={12}
            label="Expected Move Out Date:"
            value={exitPlan?.scheduledMoveOutDate ? moment(exitPlan.scheduledMoveOutDate).format("MM/DD/YY") : "N/A"}
          />
        </div>
        <div>
          <HeaderOption
            lg={12}
            label="Confirmed Move Out Date:"
            value={exitPlan?.vacancyConfirmedDate ? moment(exitPlan.vacancyConfirmedDate).format("MM/DD/YY") : "N/A"}
          />
        </div>
        <div>
          <HeaderOption
            lg={12}
            label="Utilities On?"
            value={exitPlan?.utilitiesOn ? mapUtilitiesStatusToDisplay[exitPlan.utilitiesOn] : "N/A"}
          />
        </div>
        <div>
          <HeaderOption
            lg={12}
            label="Referred To Eviction Date:"
            value={exitPlan?.referredToEvictionDate ? moment(exitPlan.referredToEvictionDate).format("MM/DD/YY") : "N/A"}
          />
        </div>
      </Grid>
    );
  };

  return (
    <Grid container columnSpacing={4}>
      {renderContent()}
    </Grid>
  );
}

export default CoachExitPlanDisplay;

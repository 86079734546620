import { Entity } from "@l4s/documents-autofill-models";

export enum TemplateFieldType {
  SIGNATURE = "signature",
  INITIAL = "initial",
  DATE_SIGNED = "date_signed",
  NAME = "name",
  EMAIL = "email",
  COMPANY = "company",
  TITLE = "title",
  TEXT = "text",
  CHECKBOX = "checkbox",
}

export const TemplateFieldTypeDisplayMap: Record<TemplateFieldType, string> = {
  [TemplateFieldType.SIGNATURE]: "Signature",
  [TemplateFieldType.INITIAL]: "Initials",
  [TemplateFieldType.DATE_SIGNED]: "Date Signed",
  [TemplateFieldType.NAME]: "Name",
  [TemplateFieldType.EMAIL]: "Email",
  [TemplateFieldType.COMPANY]: "Company",
  [TemplateFieldType.TITLE]: "Title",
  [TemplateFieldType.TEXT]: "Text",
  [TemplateFieldType.CHECKBOX]: "Checkbox",
};

export enum TemplateFieldAudience {
  LANDIS = "landis",
  MAIN_SIGNER = "main_signer",
  CO_SIGNER = "co_signer",
}

export const TemplateFieldAudienceDisplayMap: Record<TemplateFieldAudience, string> = {
  [TemplateFieldAudience.LANDIS]: "Landis Signer",
  [TemplateFieldAudience.MAIN_SIGNER]: "Main Signer",
  [TemplateFieldAudience.CO_SIGNER]: "Co-Signer",
};

export interface TemplateFieldOption {
  id: string;
  name: string;
  description: string;
  type: TemplateFieldType;
  audience: TemplateFieldAudience | null;
  autofillEntity: Entity | null;
  autofillEntityField: string | null;
}

export interface TemplateFieldInTemplate {
  id: string;
  created: string;
  updated: string;
  templateId: string;
  templateFieldId: string;
  templateFieldName: string;
  templateFieldDescription: string | null;
  templateFieldType: TemplateFieldType;
  templateFieldAudience: TemplateFieldAudience | null;
  templateFieldAutofillEntity: Entity | null;
  templateFieldAutofillEntityField: string | null;
  page: number;
  xCoord: number;
  yCoord: number;
  isRequired: boolean;
  isActive: boolean;
}

export type FilledTemplateFieldInTemplate = TemplateFieldInTemplate & {
  value: string | null;
  templateFillFieldValueId?: string;
};

export type TemplateFieldToFill = TemplateFieldOption & {
  value: string | null;
  isRequired: boolean;
  templateFillFieldValueId?: string;
};

export const isDocusignTemplateField = (type: TemplateFieldType): boolean =>
  ![TemplateFieldType.TEXT, TemplateFieldType.CHECKBOX].includes(type);

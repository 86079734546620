export enum TypeOfDocusignEnvelopeEvent {
  ENVELOPE_SENT = "envelope-sent",
  ENVELOPE_DELIVERED = "envelope-delivered",
  ENVELOPE_COMPLETED = "envelope-completed",
  ENVELOPE_CREATED = "envelope-created",
  ENVELOPE_DECLINED = "envelope-declined",
  ENVELOPE_VOIDED = "envelope-voided",
  ENVELOPE_RESENT = "envelope-resent",
  ENVELOPE_CORRECTED = "envelope-corrected",
  ENVELOPE_PURGE = "envelope-purge",
  ENVELOPE_DELETED = "envelope-deleted",
  ENVELOPE_DISCARD = "envelope-discard",
}

export enum TypeOfDocusignRecipientEvent {
  RECIPIENT_SENT = "recipient-sent",
  RECIPIENT_AUTORESPONDED = "recipient-autoresponded",
  RECIPIENT_DELIVERED = "recipient-delivered",
  RECIPIENT_COMPLETED = "recipient-completed",
  RECIPIENT_DECLINED = "recipient-declined",
  RECIPIENT_AUTHENTICATIONFAILED = "recipient-authenticationfailed",
  RECIPIENT_RESENT = "recipient-resent",
  RECIPIENT_DELEGATE = "recipient-delegate",
  RECIPIENT_REASSIGN = "recipient-reassign",
  RECIPIENT_FINISH_LATER = "recipient-finish-later",
}

export type TypeOfDocusignEvent = TypeOfDocusignEnvelopeEvent | TypeOfDocusignRecipientEvent;

export interface DocusignEvent {
  id: string;
  generatedDateTime: string;
  eventType: TypeOfDocusignEvent;
  docusignConfigurationId: string;
  docusignAccountId: string;
  docusignUserId: string;
  docusignRecipientId: string;
  docusignEnvelopeId: string;
  envelopeStatus: string;
  createdDateTime: string;
  lastModifiedDateTime: string;
  statusChangedDateTime: string;
}

export interface DocusignEventDisplay {
  label: string;
  description: string;
}

export const docusignEventDisplayMap: Record<TypeOfDocusignEvent, DocusignEventDisplay> = {
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_SENT]: {
    label: "Envelope Sent",
    description:
      "This event is sent when the email notification, with a link to the envelope, is sent to at least one recipient or when it is a recipient's turn to sign during embedded signing. The envelope remains in this state until all recipients have viewed the envelope.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_DELIVERED]: {
    label: "Envelope Delivered",
    description: "This event is sent when all recipients have opened the envelope through the DocuSign signing website.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_COMPLETED]: {
    label: "Envelope Completed",
    description: "This event is sent when the envelope has been completed by all the recipients.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_CREATED]: {
    label: "Envelope Created",
    description:
      "This event is sent when an envelope is created through the DocuSign eSignature UI or when an envelope's status is set to created after an Envelopes:create API call. ",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_DECLINED]: {
    label: "Envelope Declined",
    description: "This event is sent when the envelope has been declined by one of the recipients.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_VOIDED]: {
    label: "Envelope Voided",
    description:
      "This event is sent when the envelope has been voided by the sender or has expired. The void reason indicates whether the envelope was manually voided or expired.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_RESENT]: {
    label: "Envelope Resent",
    description: "Sent when the envelope is resent within the UI or via the Envelopes:update API call. ",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_CORRECTED]: {
    label: "Envelope Corrected",
    description:
      "Sent when the envelope is corrected within the UI or via any of the Envelopes/EnvelopesRecipients API calls. \nNote: This event can only be generated in the API after an envelope lock is created prior to the envelope update, then subsequently removed.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_PURGE]: {
    label: "Envelope Purged",
    description:
      "Sent when the envelope is queued to be purged or via the Envelopes:update API call. \nNote: The Purge queue has four states that will populate within the purgeState parameter within the envelope summary.\ndocuments_queued\ndocuments_and_metadata_queued\ndocuments_and_metadata_and_redact_queued\ndocuments_dequeued",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_DELETED]: {
    label: "Envelope Deleted",
    description:
      "This event is sent after an already-sent envelope is deleted. \nNote: This event will not trigger for envelopes in created or draft state.",
  },
  [TypeOfDocusignEnvelopeEvent.ENVELOPE_DISCARD]: {
    label: "Envelope Discarded",
    description: "Sent when an envelope in created or draft state is deleted or discarded within the tagging UI. ",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_SENT]: {
    label: "Sent to Recipient",
    description:
      "This event is sent when an email notification is sent to the recipient signifying that it is their turn to sign an envelope.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_AUTORESPONDED]: {
    label: "Recipient Autoresponded",
    description:
      "Sent when DocuSign gets notification that an email delivery has failed. The delivery failure could be for a number of reasons, such as a bad email address or that the recipient’s email system auto-responds to the email from DocuSign.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_DELIVERED]: {
    label: "Delivered to Recipient",
    description:
      "Sent when the recipient has viewed the document(s) in an envelope through the DocuSign signing web site. This does not signify an email delivery of an envelope.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_COMPLETED]: {
    label: "Completed by Recipient",
    description: "Sent when the recipient has completed their actions for the envelope, typically (but not always) by signing.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_DECLINED]: {
    label: "Declined by Recipient",
    description: "Sent when the recipient declines to sign the document(s) in the envelope.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_AUTHENTICATIONFAILED]: {
    label: "Recipient Authentication Failed",
    description:
      "Sent when the recipient fails an authentication check. In cases where a recipient has multiple attempts to pass a check, it means that the recipient failed all the attempts.",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_RESENT]: {
    label: "Resent to Recipient",
    description: "Sent when the recipient selects Finish within the UI on an envelope. ",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_DELEGATE]: {
    label: "Recipient Delegate",
    description: "Sent after a Delegation rule is in place and when a delegated signer is sent an envelope within the UI or the API. ",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_REASSIGN]: {
    label: "Reassign to Recipient",
    description: "Sent when the envelope is reassigned by a recipient within the UI. ",
  },
  [TypeOfDocusignRecipientEvent.RECIPIENT_FINISH_LATER]: {
    label: "Recipient opted to Finish Later",
    description: "Sent when the recipient selects Finish Later on an envelope. ",
  },
};

import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Grid, TextField, CardContent, Typography, Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { LandisButton, DropdownOption } from "@/components/base";
import { MainCard } from "@/components/berry";
import { ClientNoteActions, ClientNoteResponse } from "@/components/partial";
import { GRID_SPACING, DEFAULT_PADDING_2X } from "@/config";
import { FormHelpers } from "@/utils";
import { UseActions, useActionsGoals, useBulkActions, useGoals, useProgramClients } from "@/hooks";
import { Action, TypeOfAction, ProgramClient, ActionPriorityLevel, StatusOfAction } from "@/types";
import ClientNoteGoals from "../ClientNoteActions/ClientNoteGoals";
import moment from "moment";
import { Article } from "@l4s/intercom-models";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Title is a required field").typeError("Title is a required field"),
  description: Yup.string().required("Description is a required field").typeError("Description is a required field"),
  dueDate: Yup.date().typeError("Due date must be a valid date (mm/dd/yyyy)"),
  publishDate: Yup.date().typeError("Publish date must be a valid date (mm/dd/yyyy)"),
  type: Yup.string(),
  buttonText: Yup.string().when("type", {
    is: (type) => type !== TypeOfAction.NO_ACTION,
    then: Yup.string().required("Field must not be empty"),
  }),
});

type TFormik = {
  name: string;
  description: string;
  type: TypeOfAction;
  dueDate?: string;
  publishDate?: string;
  intercomArticleOrLearnMoreLink?: string;
  link?: string;
  buttonText?: string;
  priority?: ActionPriorityLevel;
  goalIds?: string[];
  complete?: boolean;
};

interface Props {
  programClientId?: string;
  onFormSubmission: () => void;
  action?: Partial<Action>;
  useActionsHook?: UseActions;
  isBulk?: boolean;
  showCloseButton?: boolean;
  coachingArticles: Article[];
}

function CreateClientNote({
  programClientId,
  onFormSubmission,
  useActionsHook,
  action,
  coachingArticles,
  isBulk = false,
  showCloseButton = true,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [programClientIds, setProgramClientIds] = useState<Array<string>>([]);
  const [programClientIdsError, setProgramClientIdsError] = useState<string | undefined>(undefined);

  const { createAction, updateAction } = useActionsHook ?? {};
  const { createBulkActions } = useBulkActions();
  const { loadProgramClients, programClients } = useProgramClients();
  const { goalsForAction, assignGoalsToAction, loadGoalsForAction } = useActionsGoals(action?.id);
  const { goals, loadGoals } = useGoals(programClientId);

  useEffect(() => {
    if (isBulk) loadProgramClients();
  }, [isBulk]);

  useEffect(() => {
    loadGoals();
    loadGoalsForAction();
  }, []);

  const headerText = isBulk ? "Bulk Create Client Notes" : action ? "Modify Client Note" : "Create A Client Note";
  const descriptionText = isBulk ? "create notes" : action ? "update a note" : "create a note";

  function getInitialIntercomArticleValue(): string | undefined {
    const article = coachingArticles.find((coachingArticle) => coachingArticle.id === action?.assignedArticle?.article.id);
    return article ? article.title : action?.learnMoreLink;
  }

  //formik hook to manage form states
  const formik = useFormik<TFormik>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: action?.name ?? "",
      description: action?.description ?? "",
      type: action?.type ?? TypeOfAction.NO_ACTION,
      buttonText: action?.buttonText ?? "",
      link: action?.link ?? "",
      intercomArticleOrLearnMoreLink: getInitialIntercomArticleValue(),
      dueDate: action?.dueDate ?? "",
      publishDate: action?.publishDate ?? new Date().toISOString(),
      priority: action?.priority ?? ActionPriorityLevel.LOW,
      complete: action?.completionDate ? true : false,
    },
    validationSchema,
    onSubmit: (values) => {
      // fix any type discrepancies before submission
      values.buttonText = FormHelpers.whenEmptyStrThenNull(values.buttonText);
      values.dueDate = FormHelpers.whenEmptyStrThenNull(values.dueDate);
      values.link = FormHelpers.whenEmptyStrThenNull(values.link);
      values.publishDate = FormHelpers.whenEmptyStrThenNull(values.publishDate);
      values.intercomArticleOrLearnMoreLink = FormHelpers.whenEmptyStrThenNull(values.intercomArticleOrLearnMoreLink);

      let completedDate;

      const intercomArticleId = coachingArticles.find((article) => article.title === values.intercomArticleOrLearnMoreLink)?.id;
      const learnMoreLink = intercomArticleId ? null : values.intercomArticleOrLearnMoreLink;

      if (values.complete) {
        completedDate = moment(new Date()).format();
      } else {
        completedDate = null;
      }
      //create bulk notes
      if (isBulk) {
        if (programClientIdsError) {
          return;
        }

        setIsLoading(true);
        createBulkActions(programClientIds, values);
        setIsLoading(false);
        onFormSubmission();
        return;
      }
      if (values?.goalIds?.length) {
        assignGoalsToAction(values.goalIds);
      }
      //update single note/action
      if (action) {
        let actionStatus = action.status;
        if (completedDate) {
          actionStatus = StatusOfAction.COMPLETE;
        } else {
          actionStatus = StatusOfAction.INCOMPLETE;
        }

        setIsLoading(true);
        updateAction({
          ...values,
          id: action.id,
          status: actionStatus,
          completionDate: completedDate ?? null,
          intercomArticleId,
          learnMoreLink,
        });
        setIsLoading(false);
        onFormSubmission();
        return;
      }

      //create single note/action
      setIsLoading(true);
      createAction({ ...values, programClientId, intercomArticleId, learnMoreLink });
      setIsLoading(false);
      onFormSubmission();
    },
    validate: (values) => {
      if (isBulk && programClientIds.length === 0) {
        setProgramClientIdsError("Must select at least 1 Client");
      } else if (programClientIdsError) {
        setProgramClientIdsError(undefined);
      }

      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (values.type === TypeOfAction.NO_ACTION && values.buttonText) {
        values.type = TypeOfAction.LINK;
      }

      return errors;
    },
  });

  const handleCompletedChange = () => {
    if (!formik.values.complete) {
      formik.setFieldValue("complete", true);
    } else {
      formik.setFieldValue("complete", false);
    }
  };

  //add rules to disable the input button
  function formIsDisabled(): boolean {
    if (isLoading || !formik.isValid) {
      return false;
    }
  }

  //create the label for a client in multi-select dropdown
  const clientDropdownSelectedLabel = (id: string): string => {
    let client: ProgramClient | undefined = programClients.find((programClient) => programClient.id === id);
    return client ? `${client.firstName} ${client.lastName}` : id;
  };

  //ensure that when not using bulk the useActionsHook is provided
  if (!isBulk && !useActionsHook) {
    throw new Error("when isBulk=false must provide a useActionsHook");
  }

  return (
    <MainCard title={headerText} content={false}>
      <CardContent>
        <Box sx={{ paddingBottom: DEFAULT_PADDING_2X }}>
          <Typography variant="body1">
            To {descriptionText} for a client add a title, description, and due date. An action can be attached to a note for a Client to
            complete.
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={GRID_SPACING}>
            {isBulk && (
              <Grid item xs={12}>
                <DropdownOption
                  id="noteClientDropdown"
                  label="Clients"
                  handleChange={setProgramClientIds}
                  required={true}
                  values={programClientIds}
                  multiple={true}
                  items={(programClients ?? []).map((data: ProgramClient): { value: string; data: ProgramClient } => ({
                    value: data.id,
                    data,
                  }))}
                  optionComponent={(data: ProgramClient) => {
                    return (
                      <div>
                        <div>
                          {data.firstName} {data.lastName}
                        </div>
                        <div>{data.id}</div>
                      </div>
                    );
                  }}
                  labelSelected={clientDropdownSelectedLabel}
                  helperText={programClientIdsError}
                  error={typeof programClientIdsError === "string"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Title"
                fullWidth
                required
                spellCheck={false}
                onChange={(e) => {
                  formik.setFieldValue("name", e?.target?.value ?? "");
                }}
                value={formik.values.name}
                helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "name")}
                error={FormHelpers.formikCheckError<TFormik>(formik, "name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Description"
                fullWidth
                multiline
                required
                rows={4}
                spellCheck={false}
                onChange={(e) => {
                  formik.setFieldValue("description", e?.target?.value ?? null);
                }}
                value={formik.values.description}
                helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "description")}
                error={FormHelpers.formikCheckError<TFormik>(formik, "description")}
              />
            </Grid>
            {action && (
              <Grid item xs={12}>
                <FormControlLabel
                  value={formik.values.complete}
                  control={<Checkbox checked={formik.values.complete} onClick={handleCompletedChange} />}
                  label="Is Complete?"
                  labelPlacement="start"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <DatePicker
                label="Publish Date"
                value={formik.values.publishDate}
                onChange={(val) => {
                  formik.setFieldValue("publishDate", val);
                }}
                renderInput={(params) => (
                  <TextField
                    id="publishDate"
                    fullWidth
                    {...params}
                    name="Date"
                    helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "publishDate")}
                    error={FormHelpers.formikCheckError<TFormik>(formik, "publishDate")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Due Date"
                value={formik.values.dueDate}
                onChange={(val) => {
                  formik.setFieldValue("dueDate", val);
                }}
                renderInput={(params) => (
                  <TextField
                    id="dueDate"
                    fullWidth
                    {...params}
                    name="Date"
                    helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "dueDate")}
                    error={FormHelpers.formikCheckError<TFormik>(formik, "dueDate")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="intercomArticleOrLearnMoreLink"
                options={coachingArticles.map((article) => article.title)}
                onChange={(event, newValue) => {
                  formik.setFieldValue("intercomArticleOrLearnMoreLink", newValue ?? "");
                }}
                value={formik.values.intercomArticleOrLearnMoreLink}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Learn More Link"
                    fullWidth
                    onChange={(event) => {
                      formik.setFieldValue("intercomArticleOrLearnMoreLink", event.target.value ?? "");
                    }}
                    helperText={FormHelpers.formikErrorMessage<TFormik>(formik, "intercomArticleOrearnMoreLink")}
                    error={FormHelpers.formikCheckError<TFormik>(formik, "intercomArticleOrLearnMoreLink")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ClientNoteGoals action={action} formik={formik} goalsForAction={goalsForAction} programClientGoals={goals} />
            </Grid>
            <Grid item xs={12}>
              <ClientNoteActions formik={formik} modifying={action ? true : false} isCompleted={action?.completionDate ? true : false} />
            </Grid>
            {action && action.type !== TypeOfAction.NO_ACTION && formik.values.type !== TypeOfAction.NO_ACTION ? (
              <Grid item xs={12}>
                <ClientNoteResponse action={action} />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <LandisButton
                loading={isLoading}
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: "100%" }}
                //disable button on errors
                //make sure to check value + error
                disabled={formIsDisabled()}
              >
                Submit
              </LandisButton>
            </Grid>
            {showCloseButton && (
              <Grid item xs={12}>
                <LandisButton type="reset" size="large" variant="outlined" sx={{ width: "100%" }} onClick={onFormSubmission}>
                  Close
                </LandisButton>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </MainCard>
  );
}

export default CreateClientNote;

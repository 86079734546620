import { DocusignIcon } from "@/components/base";
import { useEnvelopeStatus } from "@/hooks";
import { Envelope, EnvelopeRecipient, EnvelopeStatus, TemplateFill } from "@/types";
import { Chip } from "@mui/material";

interface Props {
  envelope: Envelope;
  templateFills?: Array<TemplateFill>;
  envelopeRecipients?: Array<EnvelopeRecipient>;
}

interface EnvelopeStatusChipStyle {
  color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  label: string;
  icon?: JSX.Element;
}

const docusignIconStyle: React.CSSProperties = { width: "1rem", borderRadius: "50%" };

const EnvelopeStatusChipStyleMap: Record<EnvelopeStatus, EnvelopeStatusChipStyle> = {
  [EnvelopeStatus.EMPTY]: {
    color: "default",
    label: "Empty",
  },
  [EnvelopeStatus.IN_PROGRESS]: {
    color: "warning",
    label: "In Progress",
  },
  [EnvelopeStatus.READY_TO_SEND]: {
    color: "success",
    label: "Ready to Send",
  },
  [EnvelopeStatus.DRAFTED]: {
    color: "warning",
    label: "Drafted",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
  [EnvelopeStatus.SENT]: {
    color: "success",
    label: "Sent",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
  [EnvelopeStatus.COMPLETED]: {
    color: "success",
    label: "Completed",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
  [EnvelopeStatus.DECLINED]: {
    color: "error",
    label: "Declined",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
  [EnvelopeStatus.EXPIRED]: {
    color: "error",
    label: "Expired",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
  [EnvelopeStatus.DELETED]: {
    color: "error",
    label: "Deleted",
    icon: <DocusignIcon style={docusignIconStyle} />,
  },
};

const EnvelopeStatusChip = ({ status }: { status: EnvelopeStatus }) => {
  if (!status) return null;
  const { color, label, icon } = EnvelopeStatusChipStyleMap[status];
  return <Chip color={color} label={label} icon={icon} />;
};

const WrappedEnvelopeStatusChip = ({ envelope, templateFills, envelopeRecipients }: Props) => {
  const computedStatus = useEnvelopeStatus({
    envelope,
    templateFills,
    envelopeRecipients,
  });

  return <EnvelopeStatusChip status={computedStatus} />;
};

export default WrappedEnvelopeStatusChip;

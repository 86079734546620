import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

import { Tax } from "@/types/PropertyManagement";
import { CreateTaxInput, UpdateTaxInput } from "@l4s/client-property-models";

export interface UseTaxes {
  loading: boolean;
  createTax: (input: CreateTaxInput) => Promise<void>;
  updateTax: (input: UpdateTaxInput) => Promise<void>;
  propertyTaxes: Array<Tax>;
  reloadPropertyTaxes: () => Promise<void>;
  error: string | undefined;
}

export const useTaxes = (propertyId: string): UseTaxes => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyTaxes, setPropertyTaxes] = useState<Array<Tax>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getTaxesByPropertyId = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<Tax>>(routes.GET_TAXES_BY_PROPERTY_ID, {
        params: { propertyId },
      });
      setPropertyTaxes(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property taxes.");
    }
    setLoading(false);
  };

  const createTax = async (input: CreateTaxInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<Tax>(routes.CREATE_TAX, {
        ...input,
      });
      setPropertyTaxes((existingTax) => [...existingTax, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating tax.");
    }
  };

  const updateTax = async (input: UpdateTaxInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<Tax>(routes.UPDATE_TAX(input.id), input);
      setPropertyTaxes((existingTaxes) => existingTaxes.map((tax) => (tax.id === response.data.id ? { ...tax, ...response.data } : tax)));
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating Tax.");
    }
  };

  useEffect(() => {
    if (propertyId) getTaxesByPropertyId();
  }, [propertyId]);

  return {
    loading,
    createTax,
    updateTax,
    propertyTaxes,
    reloadPropertyTaxes: getTaxesByPropertyId,
    error,
  };
};

import { TableLayout } from "@/components/layout";
import { DateFormat } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TaxPaymentDetailsForm from "./TaxPaymentDetailsForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import TaxesPaymentsTableHeader from "./TaxesPaymentDetailsTableHeader";
import { UseTaxes, UseTaxesPaymentDetails } from "@/hooks";
import { PortfolioProperty, TaxesPaymentDetails } from "@/types/PropertyManagement";
import { PaymentFrequencyDisplayMap } from "../../useDetailForms";
import moment from "moment";
import { TypeOfTaxDisplayMap } from "./useTaxPaymentDetailsForm";

interface Props {
  useTaxesPaymentDetailsInstance: UseTaxesPaymentDetails;
  useTaxesInstance: UseTaxes;
  propertyId: string;
  portfolioProperty?: PortfolioProperty;
}

const TaxesPaymentDetailsList = ({ useTaxesPaymentDetailsInstance, useTaxesInstance, propertyId, portfolioProperty }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedTaxPaymentDetail, setSelectedTaxPaymentDetail] = useState<TaxesPaymentDetails>(null);
  const { taxesPaymentDetails } = useTaxesPaymentDetailsInstance;
  const { propertyTaxes } = useTaxesInstance;

  function handleClick(row: TaxesPaymentDetails) {
    setDrawerOpen(true);
    setSelectedTaxPaymentDetail(row);
  }

  function findTaxDisplayName(taxId: string) {
    const propertyTax = propertyTaxes.find((tax) => tax.id === taxId);
    return TypeOfTaxDisplayMap[propertyTax?.type];
  }

  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={
          <TaxesPaymentsTableHeader
            propertyId={propertyId}
            useTaxesPaymentDetailsInstance={useTaxesPaymentDetailsInstance}
            useTaxesInstance={useTaxesInstance}
            title="Tax Payment Details"
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payment Frequency</TableCell>
              <TableCell>Initial Payment Date</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Related Tax</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxesPaymentDetails.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} tabIndex={0}>
                <TableCell>{PaymentFrequencyDisplayMap[row.paymentFrequency]}</TableCell>
                <TableCell>{moment(row.initialPaymentDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{moment(row.startDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{row?.endDate ? moment(row.endDate).format(DateFormat.DISPLAY_DATE_RANGE) : ""}</TableCell>
                <TableCell>{findTaxDisplayName(row.taxId)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <TaxPaymentDetailsForm
            close={() => setDrawerOpen(false)}
            taxPaymentDetail={selectedTaxPaymentDetail}
            useTaxesPaymentDetailsInstance={useTaxesPaymentDetailsInstance}
            useTaxesInstance={useTaxesInstance}
            propertyId={propertyId}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default TaxesPaymentDetailsList;

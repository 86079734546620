import moment from "moment";

export const sortByDate = <T>(key: keyof T, asc: boolean = true): ((a: T, b: T) => -1 | 0 | 1) => {
  return function (a: T, b: T): -1 | 0 | 1 {
    const ascFactor = asc ? -1 : 1;

    if (a[key] === b[key]) {
      return 0;
    }

    return moment(a[key]).isBefore(b[key]) ? ascFactor : <-1 | 1>(-1 * ascFactor);
  };
};

interface CreatedEntity {
  created: Date;
}

export const sortByCreated = <T extends CreatedEntity>(resultsArr: Array<T>, ascending = false): Array<T> => {
  return resultsArr.sort(sortByDate("created", ascending));
};

// material-ui
import { Box } from "@mui/material";

import { ClientPersonHeader } from "@/components/partial";
import { Person, ProgramClient } from "@/types";
import { usePerson, useRelatedProgramClientByProgramClientApplicationId } from "@/hooks";
import CreditDashboardTabs from "@/components/partial/ClientCreditDashboard/CreditDashboardTabs";
import { useEffect } from "react";

interface Props {
  person: Person;
  programClient: ProgramClient;
}

function ClientCreditDashboard({ person, programClient }: Props) {
  const { relatedProgramClientByApplicationId, getRelatedProgramClientByApplicationId } =
    useRelatedProgramClientByProgramClientApplicationId(programClient);

  const { person: otherApplicant, loadPerson } = usePerson();

  useEffect(() => {
    (async () => {
      try {
        await getRelatedProgramClientByApplicationId();
      } catch (e) {}
    })();
  }, [person]);

  useEffect(() => {
    if (relatedProgramClientByApplicationId?.personId)
      (async () => {
        try {
          await loadPerson(relatedProgramClientByApplicationId.personId);
        } catch (e) {}
      })();
  }, [relatedProgramClientByApplicationId]);

  return (
    <>
      <ClientPersonHeader person={person} programClient={programClient} />
      <Box sx={{ marginBottom: "12px" }} />
      <Box sx={{ marginBottom: "12px" }} />
      <CreditDashboardTabs persons={otherApplicant ? [person, otherApplicant] : [person]} />
    </>
  );
}

export default ClientCreditDashboard;

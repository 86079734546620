import { MainCard } from "@/components/berry";
import { ExpandMore } from "@mui/icons-material";
import { DocumentsAutofillDataDictionary } from "@l4s/documents-autofill-models";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";

const AutofillEntitiesTable = () => {
  return (
    <MainCard
      contentSX={{ padding: "0 !important" }}
      title={
        <Grid container direction="column">
          <Typography variant="h3">Autofill Entities</Typography>
          <Typography variant="body2" sx={{ mt: 0.5, maxWidth: 600 }}>
            These fields can be assigned to template fields to autofill template values. This page is a directory of all the values we have
            available to assign to template fields for autofill.
          </Typography>
        </Grid>
      }
    >
      {Object.entries(DocumentsAutofillDataDictionary).map(([entity, entityDefinition]) => (
        <Accordion disableGutters key={entity}>
          <AccordionSummary disableRipple={false} expandIcon={<ExpandMore />}>
            <Grid direction="column" container gap={0.5}>
              <Typography variant="h4" sx={{ mb: 0.4 }}>
                {entityDefinition.name}
              </Typography>
              <Typography variant="body2">{entityDefinition.description}</Typography>
              <Typography variant="h6">Root ID: {entityDefinition.entityId}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(entityDefinition.fields).map(([field, fieldDefinition]) =>
              ["displayValue", "urlToResource"].includes(field) ? null : (
                <Grid container key={`${entity}.${field}`}>
                  <Grid item sx={{ flex: 1 }}>
                    <Accordion disableGutters>
                      <AccordionSummary disableRipple={false} expandIcon={<ExpandMore />}>
                        <Typography variant="h5">{fieldDefinition.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: 0 }}>
                        <Typography variant="body1">{fieldDefinition.description}</Typography>
                        <Typography variant="h6">Ex: {fieldDefinition.example}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </MainCard>
  );
};

export default AutofillEntitiesTable;

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClientJourneyDtoModel, JourneyPathDtoModel, JourneyMilestoneDtoModel } from "@/types";
import { useEffect, useState } from "react";
import ClientMilestone from "./ClientMilestone";
import RequirementChip from "./Chip";
import { removeVoidedMilestones, sortByFocus } from "./utils";
import MilestoneCaption from "./MilestoneCaption";
import { MilestoneDefinitionKey } from "@l4s/milestone-models";
import ApplicationAdverseActionList from "../AdverseActions/ApplicationAdverseActionList";
import { useApplicationAdverseActions } from "@/hooks/data-hooks/adverse-actions/useApplicationAdverseActions";
import MilestoneTabs from "./MilestoneTabs";

interface Props {
  path: JourneyPathDtoModel;
  clientJourney: ClientJourneyDtoModel;
  onMilestoneUpdate: () => Promise<void>;
}

const ClientPath = ({ path, clientJourney, onMilestoneUpdate }: Props) => {
  const { applicationAdverseActions } = useApplicationAdverseActions(clientJourney.applicationId);
  const [completedMilestone, setCompletedMilestone] = useState<JourneyMilestoneDtoModel | undefined>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => () => {
    setExpanded(!expanded);
  };

  const nonVoidedPath = removeVoidedMilestones(path);

  const sortedPath = sortByFocus(nonVoidedPath);

  useEffect(() => {
    const milestone = path.find((mi) => {
      if (mi.closedAt) {
        return mi.closedReason === "COMPLETED";
      }
    });
    setCompletedMilestone(milestone);
  }, [path]);

  if (!completedMilestone && sortedPath?.length) {
    return (
      <>
        <MilestoneTabs path={sortedPath} clientJourney={clientJourney} onMilestoneUpdate={onMilestoneUpdate} />
      </>
    );
  }

  if (!completedMilestone && !sortedPath.length) {
    return <></>;
  }

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange()} disableGutters={true} sx={{ borderTop: "1px solid #eee", borderRadius: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Typography>{completedMilestone?.definition?.managerName}</Typography>
              <MilestoneCaption input={completedMilestone} />
            </div>
            <div style={{ justifySelf: "flex-end", marginRight: "2rem" }}>
              <RequirementChip input={completedMilestone} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {[MilestoneDefinitionKey.WITHDRAWN, MilestoneDefinitionKey.PROGRAM_INELIGIBLE].includes(completedMilestone.definition.key) ? (
            <ApplicationAdverseActionList applicationAdverseActions={applicationAdverseActions} />
          ) : null}
          {sortedPath?.map((m) => {
            return (
              <ClientMilestone
                milestone={m}
                clientJourney={clientJourney}
                key={m.id}
                onMilestoneUpdate={onMilestoneUpdate}
                milestoneCompleted={true}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ClientPath;

import { LandisButton, SearchableDropdown } from "@/components/base";
import { UseEnvelopesHook, useToast } from "@/hooks";
import { DefinedEnvelope } from "@/types";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface Props {
  onCreateEnvelope: () => void;
  useEnvelopesInstance: UseEnvelopesHook;
  definedEnvelopes: Array<DefinedEnvelope>;
}

function AddDefinedEnvelopeForm({ useEnvelopesInstance, definedEnvelopes, onCreateEnvelope }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createEnvelopeFromDefinedEnvelope } = useEnvelopesInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      definedEnvelopeId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await createEnvelopeFromDefinedEnvelope(values);
        onCreateEnvelope();
      } catch (err) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.definedEnvelopeId) {
        errors.definedEnvelopeId = "Please specify a defined envelope.";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={2} container direction="column">
        <Grid item xs={12}>
          <SearchableDropdown
            label="Defined Envelopes"
            id="add-envelope-from-defined-envelope"
            items={definedEnvelopes.map((definedEnvelope) => ({
              label: definedEnvelope.name,
              value: definedEnvelope.id,
            }))}
            handleChange={(newValue) => {
              formik.setFieldValue("definedEnvelopeId", newValue);
            }}
            value={formik.values.definedEnvelopeId}
          />
        </Grid>
        <Grid item xs={12}>
          <LandisButton loading={loading} type="submit" endIcon={<Check fontSize="small" />} variant="contained">
            Submit
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddDefinedEnvelopeForm;

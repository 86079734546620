import { Checkbox, CircularProgress, TableCell, TableRow, TextField } from "@mui/material";
import { isBoolean } from "lodash";
import { useEffect, useRef, useState } from "react";
import { usePropertyEvaluationCriteria } from "@/hooks/data-hooks/client-properties/usePropertyEvaluationCriteria";
import { PropertyEvaluationCriteriaDto } from "@l4s/client-property-models";

interface Props {
  evaluationCriteria: PropertyEvaluationCriteriaDto;
  setEvaluationCriteriaSaving: (isSaving: boolean) => void;
  isUnsubmittedEvaluation: boolean;
}

export default function EvaluationCriteriaRow({ evaluationCriteria, setEvaluationCriteriaSaving, isUnsubmittedEvaluation }: Props) {
  const timerRef = useRef(null);

  const [inputValue, setInputValue] = useState(evaluationCriteria.notes || "");
  const [checkPassed, setCheckPassed] = useState<boolean | null>(evaluationCriteria.passed);

  const { updatePropertyEvaluationCriteria, loading: updatingPropertyEvaluationCriteria } = usePropertyEvaluationCriteria();

  const updateCriteria = async () => {
    if (!isUnsubmittedEvaluation) {
      return;
    }

    setEvaluationCriteriaSaving(true);
    await updatePropertyEvaluationCriteria(evaluationCriteria.id, checkPassed, inputValue.length > 0 ? inputValue : null);
    setEvaluationCriteriaSaving(false);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateCriteria();
  }, [checkPassed]);

  const handleCheckPassedChange = () => {
    if (checkPassed === null || checkPassed === false) {
      setCheckPassed(true);
    } else {
      setCheckPassed(null);
    }
  };

  const handleCheckFailedChange = () => {
    if (checkPassed === null || checkPassed === true) {
      setCheckPassed(false);
    } else {
      setCheckPassed(null);
    }
  };

  // Updates the value of notes after 1 second of no typing
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Clear any existing timers
    if (timerRef) {
      clearTimeout(timerRef.current);
    }

    setInputValue(value);

    // Set a new timerRef
    const newTimer = setTimeout(() => {
      updateCriteria();
    }, 1000);

    timerRef.current = newTimer;
  };

  return (
    <TableRow key={evaluationCriteria.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{evaluationCriteria.propertyEvaluationCriteriaDefinition.description}</TableCell>
      <TableCell>
        <Checkbox
          checked={isBoolean(checkPassed) && checkPassed}
          onClick={() => {
            handleCheckPassedChange();
          }}
          disabled={!isUnsubmittedEvaluation}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={isBoolean(checkPassed) && !checkPassed}
          onClick={() => {
            handleCheckFailedChange();
          }}
          disabled={!isUnsubmittedEvaluation}
        />
      </TableCell>
      <TableCell>
        <TextField
          multiline
          value={inputValue}
          onChange={(event) => {
            handleInputChange(event);
          }}
          InputProps={{
            endAdornment: updatingPropertyEvaluationCriteria && (
              <div style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                <CircularProgress color="inherit" size={20} />
              </div>
            ),
          }}
          disabled={!isUnsubmittedEvaluation}
        />
      </TableCell>
    </TableRow>
  );
}

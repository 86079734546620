import { HoasPaymentDetailsList, TaxesPaymentDetailsList, UtilitiesPaymentDetailsList } from "@/components/partial";
import {
  useHoas,
  useHoasPaymentDetails,
  usePropertyManagementCompany,
  useTaxes,
  useTaxesPaymentDetails,
  useUtilities,
  useUtilitiesPaymentDetails,
} from "@/hooks";
import { PortfolioProperty } from "@/types/PropertyManagement";
import { Box, Grid } from "@mui/material";

interface Props {
  propertyId: string;
  portfolioProperty?: PortfolioProperty;
}

function PaymentInformation({ propertyId, portfolioProperty }: Props) {
  const useHoasPaymentDetailsInstance = useHoasPaymentDetails(propertyId);
  const useTaxesPaymentDetailsInstance = useTaxesPaymentDetails(propertyId);
  const useUtilitiesPaymentDetailsInstance = useUtilitiesPaymentDetails(propertyId);
  const useUtilitiesInstance = useUtilities(propertyId);
  const useHoasInstance = useHoas(propertyId);
  const useTaxesInstance = useTaxes(propertyId);
  const usePropertyManagementCompanyInstance = usePropertyManagementCompany();

  return (
    <Grid container spacing={1} sx={{ pb: 10 }}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column" gap={1}>
          <HoasPaymentDetailsList
            useHoasPaymentDetailsInstance={useHoasPaymentDetailsInstance}
            propertyId={propertyId}
            usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance}
            useHoasInstance={useHoasInstance}
          />
          <TaxesPaymentDetailsList
            useTaxesInstance={useTaxesInstance}
            useTaxesPaymentDetailsInstance={useTaxesPaymentDetailsInstance}
            propertyId={propertyId}
          />
          <UtilitiesPaymentDetailsList
            useUtilitiesInstance={useUtilitiesInstance}
            propertyId={propertyId}
            useUtilitiesPaymentDetailsInstance={useUtilitiesPaymentDetailsInstance}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PaymentInformation;

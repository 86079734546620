import { useSearchParamsState } from "@/hooks";
import { StatusOfApplicationProperty } from "@l4s/client-property-models";
import { ApplicationsPropertyDto, SearchParamKeys, statusOfApplicationPropertyOrderMap } from "@/types";
import { IconCalendarTime, IconCash, IconEye, IconFile, IconHammer, IconHome2, IconHomeDollar, IconRouter } from "@tabler/icons";
import { ReactElement, useEffect } from "react";

export enum ClientPropertyTab {
  PROPERTY = "property",
  INSPECTIONS = "inspections",
  OFFERS = "offers",
  CLOSING_REQUIREMENTS = "closing-requirements",
  FINANCES = "finances",
  MAINTENANCE = "maintenance",
  DOCUMENTS = "documents",
  TOUCHPOINTS = "touchpoints",
}

export interface ClientPropertyTabItem {
  label: string;
  value: ClientPropertyTab;
  icon?: ReactElement;
}

const tabItems: Array<ClientPropertyTabItem> = [
  {
    label: "Property",
    value: ClientPropertyTab.PROPERTY,
    icon: <IconHome2 />,
  },
  {
    label: "Inspections",
    value: ClientPropertyTab.INSPECTIONS,
    icon: <IconEye />,
  },
  {
    label: "Offers",
    value: ClientPropertyTab.OFFERS,
    icon: <IconHomeDollar />,
  },
  {
    label: "Closing Requirements",
    value: ClientPropertyTab.CLOSING_REQUIREMENTS,
    icon: <IconCalendarTime />,
  },
  {
    label: "Finances",
    value: ClientPropertyTab.FINANCES,
    icon: <IconCash />,
  },
  {
    label: "Maintenance",
    value: ClientPropertyTab.MAINTENANCE,
    icon: <IconHammer />,
  },
  {
    label: "Documents",
    value: ClientPropertyTab.DOCUMENTS,
    icon: <IconFile />,
  },
  {
    label: "Touchpoints",
    value: ClientPropertyTab.TOUCHPOINTS,
    icon: <IconRouter />,
  },
];

const POST_CLOSE_PROPERTY_TABS = [ClientPropertyTab.FINANCES, ClientPropertyTab.CLOSING_REQUIREMENTS, ClientPropertyTab.MAINTENANCE];

export const useClientPropertyDetailTabs = (applicationsProperty?: ApplicationsPropertyDto): Array<ClientPropertyTabItem> => {
  const [clientPropertyTabValue, setClientPropertyTabValue] = useSearchParamsState<ClientPropertyTab>(
    SearchParamKeys.CLIENT_PROPERTY_TAB,
    ClientPropertyTab.PROPERTY
  );

  useEffect(() => {
    if (!applicationsProperty) return;
    if (!clientPropertyTabValue) setClientPropertyTabValue(ClientPropertyTab.PROPERTY, { replace: true });
    if (
      statusOfApplicationPropertyOrderMap[applicationsProperty.status] <
        statusOfApplicationPropertyOrderMap[StatusOfApplicationProperty.UNDER_CONTRACT] &&
      POST_CLOSE_PROPERTY_TABS.includes(clientPropertyTabValue as ClientPropertyTab)
    ) {
      setClientPropertyTabValue(ClientPropertyTab.PROPERTY, { replace: true });
    }
  }, [applicationsProperty, clientPropertyTabValue]);

  if (!applicationsProperty) return [];

  if (
    statusOfApplicationPropertyOrderMap[applicationsProperty.status] <
    statusOfApplicationPropertyOrderMap[StatusOfApplicationProperty.UNDER_CONTRACT]
  ) {
    return tabItems.filter((tabItem) => !POST_CLOSE_PROPERTY_TABS.includes(tabItem.value));
  }

  return tabItems;
};

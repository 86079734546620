import { AdverseActionReasonType } from "@/types";
import { TypeOfLandisProgram } from "@l4s/program-models";

export const GET_PAYMENT_PLANS_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/remediation/payment-plans/${programClientId}`;
export const CREATE_PAYMENT_PLAN = `/remediation/payment-plans`;
export const ACTIVATE_PAYMENT_PLAN = `/remediation/payment-plans/set-current`;
export const CREATE_EXPECTED_PAYMENT = `/remediation/payment-plans/expected-payment`;
export const UPDATE_EXPECTED_PAYMENT = `/remediation/payment-plans/expected-payment`;
export const DELETE_EXPECTED_PAYMENT = (expectedPaymentId: string) => `/remediation/payment-plans/expected-payment/${expectedPaymentId}`;

export const HARDSHIPS = "/remediation/hardship";

export const EVICTIONS = "/remediation/evictions";

export const GET_DELINQUENCY_DATA_BY_PERSON_ID = (personId: string) => `/remediation/delinquency-data/${personId}`;

export const GET_AUTHENTICATED_ACCOUNT = `/auth/authenticated-account`;
export const CREATE_IMPERSONATION = `/auth/impersonate-account`;

export const GET_PERSON_BY_ID = (personId: string) => `/person/${personId}`;
export const SEARCH_FOR_PERSONS = (searchString: string) => {
  const query = new URLSearchParams({ searchString });

  return `/person?${query.toString()}`;
};
export const GET_COAPPLICANT_BY_APPLICATION_ID = (applicationId: string) => `/applications/${applicationId}/coapplicant`;
export const GET_PROGRAM_CLIENTS_BY_PROGRAM = (program: TypeOfLandisProgram) => `/program-clients?program=${program}`;
export const GET_PROGRAM_CLIENTS_BY_PERSON_ID = (personId: string) => `/program-clients?personId=${personId}`;
export const GET_PROGRAM_CLIENTS_BY_APPLICATION_ID = (applicationId: string) => `/program-clients?applicationId=${applicationId}`;
export const GET_PROGRAM_CLIENT_BY_ID = (programClientId: string) => `/program-clients/${programClientId}`;

export const GET_EXIT_PLAN_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/remediation/exit-plan/${programClientId}`;
export const UPSERT_EXIT_PLAN = `/remediation/exit-plan`;

export const GET_CASH_FOR_KEYS_OFFERS = (exitPlanId: string) => `/remediation/exit-plan/cash-for-keys/${exitPlanId}`;
export const CREATE_CASH_FOR_KEYS_OFFER = "/remediation/exit-plan/cash-for-keys";
export const UPDATE_CASH_FOR_KEYS_OFFER = "/remediation/exit-plan/cash-for-keys";

export const GET_GOALS_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/coaching/goals/${programClientId}`;
export const GET_TYPES_OF_GOALS = "/coaching/typesOfGoals";
export const CREATE_GOAL = (programClientId: string) => `/coaching/goals/${programClientId}`;
export const CREATE_INITIAL_GOALS = (programClientId: string) => `/coaching/initialGoals/${programClientId}`;
export const UPDATE_GOAL = (goalId: string) => `/coaching/goals/${goalId}`;
export const GOALS_CHECKIN = `/coaching/check-in`;
export const DEACTIVATE_GOAL = (goalId: string) => `/coaching/goals/deactivate/${goalId}`;

export const GET_GOAL_HISTORY_BY_GOAL_ID = (goalId: string) => `/coaching/goalsHistory/${goalId}`;

export const GET_CURRENT_CREDIT_BY_PERSON_ID = (personId: string) => `/person-finances/credit/${personId}/current`;
export const GET_ALL_CREDIT_BY_PERSON_ID = (personId: string) => `/person-finances/credit/${personId}`;
export const GET_CURRENT_DTI_BY_PERSON_ID = (personId: string) => `/person-finances/dti/${personId}/current`;
export const GET_ALL_DTI_BY_PERSON_ID = (personId: string) => `/person-finances/dti/${personId}`;
export const GET_TOTAL_HOC_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/person-finances/hoc/${programClientId}/total`;
export const GET_ALL_HOC_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/person-finances/hoc/${programClientId}`;
export const GET_CREDIT_DASHBOARD_BY_PERSON_ID = (personId: string) => `/person-finances/credit/${personId}/dashboard`;

export const COACHING_ARTICLES = "/coaching/intercom-articles";

export const ACTIONS = "/coaching/actions";
export const BULK_CREATE_ACTIONS = "/coaching/actions/bulk";

export const GET_GOALS_BY_ACTION_ID = (actionId: string) => `/coaching/goals/actions/${actionId}`;
export const ASSIGN_GOALS_TO_ACTION = (actionId: string) => `/coaching/actions/assignGoals/${actionId}`;

export const GET_ALL_TEMPLATES = "/template-documents/templates";
export const GET_TEMPLATE_BY_ID = (templateId: string) => `/template-documents/templates/${templateId}`;
export const GET_TEMPLATE_DOCUMENT_BY_TEMPLATE_ID = (templateId: string) => `/template-documents/templates/${templateId}/template-document`;
export const GET_AUTOFILL_DOCUMENT_BY_TEMPLATE_ID = (templateId: string) => `/template-documents/templates/${templateId}/autofill-document`;
export const CREATE_TEMPLATE = "/template-documents/templates";
export const UPDATE_TEMPLATE_AUTOFILL_DOCUMENT = (templateId: string) => `/template-documents/templates/${templateId}/autofill-document`;

export const GET_ALL_ENVELOPES = "/template-documents/envelopes";
export const CREATE_EMPTY_ENVELOPE = "/template-documents/envelopes";
export const CREATE_ENVELOPE_FROM_DEFINED_ENVELOPE = "/template-documents/envelopes/defined";
export const GET_ENVELOPE_BY_ID = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}`;

export const SEND_ENVELOPE = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}/external-envelopes`;
export const GET_CURRENT_EXTERNAL_ENVELOPE = (envelopeId: string) =>
  `/template-documents/envelopes/${envelopeId}/external-envelopes/current`;
export const GET_DOCUSIGN_EVENTS_BY_EXTERNAL_ENVELOPE_ID = (envelopeId: string, externalEnvelopeId: string) =>
  `/template-documents/envelopes/${envelopeId}/external-envelopes/${externalEnvelopeId}/docusign-events`;

export const GET_ENVELOPE_RECIPIENTS_BY_ENVELOPE_ID = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}/recipients`;
export const CREATE_ENVELOPE_RECIPIENT = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}/recipients`;
export const DELETE_ENVELOPE_RECIPIENT = (envelopeId: string, envelopeRecipientId: string) =>
  `/template-documents/envelopes/${envelopeId}/recipients/${envelopeRecipientId}`;

export const GET_TEMPLATE_FILLS_BY_ENVELOPE_ID = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}/template-fills`;
export const CREATE_TEMPLATE_FILL = (envelopeId: string) => `/template-documents/envelopes/${envelopeId}/template-fills`;
export const GET_TEMPLATE_FILL_BY_ID = (envelopeId: string, templateFillId: string) =>
  `/template-documents/envelopes/${envelopeId}/template-fills/${templateFillId}`;

export const GET_TEMPLATE_FIELDS_IN_TEMPLATE = (templateId: string) => `/template-documents/templates/${templateId}/fields`;
export const CREATE_TEMPLATE_FIELD_IN_TEMPLATE = (templateId: string) => `/template-documents/templates/${templateId}/fields`;
export const ASSIGN_TEMPLATE_FIELD_TO_TEMPLATE = (templateId: string, templateFieldId: string) =>
  `/template-documents/templates/${templateId}/fields/${templateFieldId}`;
export const DELETE_TEMPLATE_FIELD_IN_TEMPLATE = (templateId: string, templateFieldInTemplateId: string) =>
  `/template-documents/templates/${templateId}/fields/${templateFieldInTemplateId}`;

export const GET_TEMPLATE_FILL_FIELD_VALUES_IN_TEMPLATE_FILL = (envelopeId: string, templateFillId: string) =>
  `/template-documents/envelopes/${envelopeId}/template-fills/${templateFillId}/field-values`;
export const UPDATE_TEMPLATE_FILL_FIELD_VALUE = (envelopeId: string, templateFillId: string, templateFillFieldValueId: string) =>
  `template-documents/envelopes/${envelopeId}/template-fills/${templateFillId}/field-values/${templateFillFieldValueId}`;
export const DELETE_TEMPLATE_FILL_FIELD_VALUE = (envelopeId: string, templateFillId: string, templateFillFieldValueId: string) =>
  `template-documents/envelopes/${envelopeId}/template-fills/${templateFillId}/field-values/${templateFillFieldValueId}`;
export const CREATE_TEMPLATE_FILL_FIELD_VALUE = (envelopeId: string, templateFillId: string) =>
  `template-documents/envelopes/${envelopeId}/template-fills/${templateFillId}/field-values`;

export const GET_ALL_DEFINED_ENVELOPES = "/template-documents/defined-envelopes";
export const CREATE_DEFINED_ENVELOPE = "/template-documents/defined-envelopes";

export const GET_TEMPLATES_IN_DEFINED_ENVELOPE = (definedEnvelopeId: string) =>
  `/template-documents/defined-envelopes/${definedEnvelopeId}/templates`;
export const ADD_TEMPLATE_TO_DEFINED_ENVELOPE = (definedEnvelopeId: string) =>
  `/template-documents/defined-envelopes/${definedEnvelopeId}/templates`;
export const REMOVE_TEMPLATE_FROM_DEFINED_ENVELOPE = (definedEnvelopeId: string, templateId: string) =>
  `/template-documents/defined-envelopes/${definedEnvelopeId}/templates/${templateId}`;

export const GET_ALL_TEMPLATE_FIELDS = "/template-documents/template-fields";
export const CREATE_TEMPLATE_FIELD = "/template-documents/template-fields";
export const UPDATE_TEMPLATE_FIELD_AUTOFILL = (templateFieldId: string) =>
  `/template-documents/template-fields/${templateFieldId}/autofill-entity`;
export const DELETE_TEMPLATE_FIELD_AUTOFILL = (templateFieldId: string) =>
  `/template-documents/template-fields/${templateFieldId}/autofill-entity`;

export const CREATE_INSPECTION = "/inspections";

export const GET_JOURNEY = (clientJourneyId: string) => `/milestones/client-journeys/${clientJourneyId}/journey`;
export const GET_CLIENT_JOURNEY_BY_PROGRAM_CLIENT_ID = (programClientId: string) => `/program-clients/${programClientId}/client-journey`;
export const GET_REQUIREMENTS = "milestones/requirements";
export const POST_REQUIREMENT = GET_REQUIREMENTS;
export const GET_REQUIREMENT_DEFINITIONS = "milestones/requirement-definitions";
export const UPDATE_REQUIREMENT = (requirementId: string) => `milestones/requirements/${requirementId}`;
export const GET_MILESTONE = (milestoneId: string) => `/milestones/${milestoneId}`;

export const GET_PROPERTIES = `/client-property/properties`;
export const CREATE_PROPERTY = `/client-property/properties`;
export const GET_PROPERTY = (propertyId: string) => `/client-property/properties/${propertyId}`;
export const UPDATE_PROPERTY_ADDRESS = (propertyId: string) => `/client-property/properties/${propertyId}/address`;
export const UPDATE_PROPERTY_HOME_DETAILS = (propertyId: string) => `/client-property/properties/${propertyId}/home-details`;

export const GET_INITIAL_CALCULATOR_VALUES = (personId: string) => `/calculator/budget/initialValues/${personId}`;
export const CALCULATE_BUDGET_AND_PROGRAM_COST = `/calculator/budgetAndProgramCost`;

export const GET_PROSPECTIVE_PROPERTIES = `/client-property/prospective-properties`;
export const CREATE_APPLICATION_PROPERTY_FOR_PROSPECTIVE_PROPERTY = (prospectivePropertyId: string) =>
  `/client-property/prospective-properties/${prospectivePropertyId}/application-property`;

export const GET_APPLICATIONS_PROPERTIES = `/client-property/applications-properties`;
export const CREATE_APPLICATIONS_PROPERTY = `/client-property/applications-properties`;
export const GET_APPLICATIONS_PROPERTY_BY_ID = (applicationsPropertyId: string) =>
  `/client-property/applications-properties/${applicationsPropertyId}`;

export const GET_APPLICATIONS_PROPERTY_FINANCE = (applicationsPropertyId: string) =>
  `/client-property/applications-properties/${applicationsPropertyId}/finance`;

export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_CLIENT_PAYMENTS = (
  applicationsPropertyId: string,
  applicationPropertyFinanceId: string
) => `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/client-payments`;
export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_FIXED_PAYMENTS = (applicationsPropertyId: string, applicationPropertyFinanceId: string) =>
  `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/fixed-payments`;
export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_PROPERTY_FINANCIALS = (
  applicationsPropertyId: string,
  applicationPropertyFinanceId: string
) => `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/property-financials`;
export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_YEARLY_PAYMENTS = (
  applicationsPropertyId: string,
  applicationPropertyFinanceId: string
) => `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/yearly-payments`;
export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_SALES_FINANCIALS = (
  applicationsPropertyId: string,
  applicationPropertyFinanceId: string
) => `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/sales-financials`;
export const UPDATE_APPLICATIONS_PROPERTY_FINANCE_FINANCIAL_RATIOS = (
  applicationsPropertyId: string,
  applicationPropertyFinanceId: string
) => `/client-property/applications-properties/${applicationsPropertyId}/finance/${applicationPropertyFinanceId}/financial-ratios`;

export const GET_ACTIVE_ADVERSE_ACTION_DEFINTIONS_BY_REASON_TYPE = (reasonType: AdverseActionReasonType) =>
  `/adverse-actions/active-definitions?reasonType=${reasonType}`;

export const GET_APPLICATION_ADVERSE_ACTIONS = (applicationId: string) => `/application-adverse-actions?applicationId=${applicationId}`;

export const INSERT_APPLICATION_ADVERSE_ACTION = "/application-adverse-actions";

export const GET_OPTIONAL_HOC_DATA_BY_PROGRAM_CLIENT_ID = (programClientId: string) =>
  `/person-finances/hoc/${programClientId}/optional-hoc-data`;

export const GET_PROPERTY_EVALUATIONS = (propertyId: string) => `/client-property/property-evaluations?propertyId=${propertyId}`;

export const CREATE_PROPERTY_EVALUATION = "/client-property/property-evaluations";

export const SUBMIT_PROPERTY_EVALUATION = "/client-property/property-evaluations/submit";
export const SEND_PROPERTY_EVALUATION_UPDATE_NOTIFICATION = "/client-property/property-evaluations/client-notifications";

export const UPDATE_PROPERTY_EVALUATION_CRITERIA = (propertyEvaluationCriteriaId: string) =>
  `/client-property/property-evaluation-criteria/${propertyEvaluationCriteriaId}`;
export const GET_HOAS_BY_PROPERTY_ID = (propertyId: string) => `/client-property/property-management/hoas/property/${propertyId}`;
export const GET_UTILITIES_BY_PROPERTY_ID = (propertyId: string) => `/client-property/property-management/utilities/property/${propertyId}`;
export const GET_TAXES_BY_PROPERTY_ID = "/client-property/property-management/taxes";
export const GET_RENTAL_INSURANCE_BY_PROPERTY_ID = "/client-property/property-management/rental-insurance";
export const CREATE_RENTAL_INSURANCE = "/client-property/property-management/rental-insurance";
export const UPDATE_RENTAL_INSURANCE = (rentalInsuranceId: string) =>
  `/client-property/property-management/rental-insurance/${rentalInsuranceId}`;
export const GET_RENTAL_REGISTRATION_BY_PROPERTY_ID = "/client-property/property-management/rental-registration";
export const CREATE_RENTAL_REGISTRATION = "/client-property/property-management/rental-registration";
export const UPDATE_RENTAL_REGISTRATION = (rentalRegistrationId: string) =>
  `/client-property/property-management/rental-registration/${rentalRegistrationId}`;
export const CREATE_HOA = "/client-property/property-management/hoas";
export const CREATE_UTILITY = "/client-property/property-management/utilities";
export const CREATE_TAX = "/client-property/property-management/taxes";
export const UPDATE_HOA = (hoaId: string) => `/client-property/property-management/hoas/${hoaId}`;
export const UPDATE_UTILITY = (utilityId: string) => `/client-property/property-management/utilities/${utilityId}`;
export const UPDATE_TAX = (taxId: string) => `/client-property/property-management/taxes/${taxId}`;
export const GET_HOAS_PAYMENT_DETAILS = "/client-property/property-management/hoas-payment-details";
export const CREATE_HOA_PAYMENT_DETAIL = "/client-property/property-management/hoas-payment-details";
export const UPDATE_HOA_PAYMENT_DETAIL = (hoaPaymentDetailId: string) =>
  `/client-property/property-management/hoas-payment-details/${hoaPaymentDetailId}`;
export const GET_TAXES_PAYMENT_DETAILS = "/client-property/property-management/taxes-payment-details";
export const CREATE_TAX_PAYMENT_DETAIL = "/client-property/property-management/taxes-payment-details";
export const UPDATE_TAX_PAYMENT_DETAIL = (taxPaymentDetailId: string) =>
  `/client-property/property-management/taxes-payment-details/${taxPaymentDetailId}`;
export const GET_UTILITIES_PAYMENT_DETAILS = "/client-property/property-management/utilities-payment-details";
export const CREATE_UTILITY_PAYMENT_DETAIL = "/client-property/property-management/utilities-payment-details";
export const UPDATE_UTILITY_PAYMENT_DETAIL = (utilityPaymentDetailId: string) =>
  `/client-property/property-management/utilities-payment-details/${utilityPaymentDetailId}`;
export const GET_PROPERTY_MANAGEMENT_COMPANIES = "/client-property/property-management/companies";
export const CREATE_PROPERTY_MANAGEMENT_COMPANY = "/client-property/property-management/companies";
export const UPDATE_PROPERTY_MANAGEMENT_COMPANY = (propertyManagementCompanyId: string) =>
  `/client-property/property-management/companies/${propertyManagementCompanyId}`;
export const GET_HOUSING_QUESTIONNAIRE = (applicationId: string) => `/applications/${applicationId}/housing-questionnaire`;
export const GET_FINANCIAL_QUESTIONNAIRE = (applicationId: string) => `/applications/${applicationId}/financial-questionnaire`;
export const GET_PORTFOLIO_PROPERTY_BY_PROPERTY_ID = (propertyId: string) => `/client-property/portfolio-property?propertyId=${propertyId}`;
export const UPDATE_PORTFOLIO_PROPERTY_BY_PROPERTY_ID = (propertyId: string) => `/client-property/portfolio-property/${propertyId}`;
export const GET_COACHING_HISTORY = "/coaching/coaching-history";
export const CREATE_COACHING_HISTORY = "/coaching/coaching-history";
export const UPDATE_COACHING_HISTORY = (coachingHistoryId: string) => `/coaching/coaching-history/${coachingHistoryId}`;

export const GET_PLAID_AUTHENTICATIONS = (landisAccountId: string) => `/plaid/authentications/${landisAccountId}`;
export const GET_PLAID_ACCOUNTS = (plaidAuthenticationId: string) => `/plaid/accounts/${plaidAuthenticationId}`;
export const GET_PLAID_ACCOUNT_TRANSACTIONS = (plaidAccountId: string) => `/plaid/transactions/${plaidAccountId}`;
export const GET_PLAID_ACCOUNT_BALANCES = (plaidAccountId: string) => `/plaid/balances/${plaidAccountId}`;
export const GET_PLAID_ACCOUNT_MERCHANT_SORT = (plaidAccountId: string) => `/plaid/merchant-sort/${plaidAccountId}`;
export const GET_PLAID_ACCOUNT_CATEGORY_SORT = (plaidAccountId: string) => `/plaid/category-sort/${plaidAccountId}`;

import { PATH_IMPERSONATION_URL } from "@/config";
import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";

export const impersonateClient = async (impersonatorAccountId: string, impersonatedAccountId: string): Promise<void> => {
  const impersonation = { impersonatorAccountId, impersonatedAccountId };

  try {
    const authResponse = await bridgeV2ApiInstance.post(routes.CREATE_IMPERSONATION, impersonation);

    if (authResponse.status === 200) {
      window.open(PATH_IMPERSONATION_URL, "_blank", "noreferrer");
    }
  } catch (e) {
    const error = mapUnknownToAxiosError(e);
    throw new Error(error?.response?.data?.message || "Error fetching account ID with person ID.");
  }
};

import { MainCard } from "@/components/berry";
import { Cancel, ExpandMore } from "@mui/icons-material";
import { DocumentsAutofillDataDictionary, Entity } from "@l4s/documents-autofill-models";
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";
import { UpdateTemplateFieldAutofillInput, useToast } from "@/hooks";
import { useFeature } from "@/contexts";
import { FeatureFlag, TemplateFieldOption } from "@/types";

interface Props {
  templateField: TemplateFieldOption;
  onRemoveTemplateFieldAutofill: (templateFieldId: string) => Promise<void>;
  onAddTemplateFieldAutofill: (input: UpdateTemplateFieldAutofillInput) => Promise<void>;
}

const DocumentsAutofillConfiguration = ({ templateField, onRemoveTemplateFieldAutofill, onAddTemplateFieldAutofill }: Props) => {
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const releaseDocumentsAutofill = useFeature(FeatureFlag.RELEASE_DOCUMENT_AUTOFILL);

  const { addToast } = useToast();

  const handleAddTemplateFieldAutofill = async (input: UpdateTemplateFieldAutofillInput) => {
    setAddLoading(true);
    await onAddTemplateFieldAutofill(input);
    setAddLoading(false);
  };

  const handleRemoveTemplateFieldAutofill = async () => {
    setRemoveLoading(true);
    await onRemoveTemplateFieldAutofill(templateField.id);
    setRemoveLoading(false);
  };

  if (!releaseDocumentsAutofill) return null;

  return (
    <MainCard
      contentSX={{ padding: "0 !important" }}
      title={
        <Grid container direction="column">
          <Typography variant="h3">Autofill Configuration</Typography>
          {templateField.autofillEntity && templateField.autofillEntityField && (
            <Grid sx={{ mt: 1 }}>
              <Chip
                deleteIcon={removeLoading ? <CircularProgress size={16} /> : <Cancel />}
                disabled={removeLoading}
                onDelete={handleRemoveTemplateFieldAutofill}
                size="small"
                label={
                  <span>
                    {DocumentsAutofillDataDictionary[templateField.autofillEntity]?.name} {" > "}
                    <b>{DocumentsAutofillDataDictionary[templateField.autofillEntity]?.fields[templateField.autofillEntityField]?.name}</b>
                  </span>
                }
              />
            </Grid>
          )}
        </Grid>
      }
    >
      <RadioGroup
        onChange={(e) => {
          const [entity, entityField] = e.target.value.split(".");
          if (!(entity in Entity)) {
            addToast({
              message: `Autofill engine not configured correctly. ${entity} is not a valid entity.`,
              type: "error",
            });
          }
          handleAddTemplateFieldAutofill({ templateFieldId: templateField.id, entity: entity as Entity, entityField });
        }}
        value={
          templateField.autofillEntity && templateField.autofillEntityField
            ? `${templateField.autofillEntity}.${templateField.autofillEntityField}`
            : null
        }
      >
        <Grid container direction="column">
          {Object.entries(DocumentsAutofillDataDictionary).map(([entity, entityDefinition]) => (
            <Accordion disableGutters key={entity}>
              <AccordionSummary disableRipple={false} expandIcon={<ExpandMore />}>
                <Grid direction="column" container>
                  <Typography variant="h4">{entityDefinition.name}</Typography>
                  <Typography variant="h6">{entityDefinition.description}</Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(entityDefinition.fields).map(([field, fieldDefinition]) =>
                  ["displayValue", "urlToResource"].includes(field) ? null : (
                    <Grid container key={`${entity}.${field}`}>
                      <Grid item sx={{ paddingTop: "5px" }}>
                        <Radio disabled={addLoading} value={`${entity}.${field}`} size="small" />
                      </Grid>
                      <Grid item sx={{ flex: 1 }}>
                        <Accordion disableGutters>
                          <AccordionSummary disableRipple={false} expandIcon={<ExpandMore />}>
                            <Typography variant="h5">{fieldDefinition.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ paddingTop: 0 }}>
                            <Typography variant="body1">{fieldDefinition.description}</Typography>
                            <Typography variant="h6">Ex: {fieldDefinition.example}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </RadioGroup>
    </MainCard>
  );
};

export default DocumentsAutofillConfiguration;

import { TableLayout } from "@/components/layout";
import { DateFormat, boolToConfirm, mapConfirmOptions } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import RentalRegistrationForm from "./RentalRegistrationForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import { UseRentalRegistration } from "@/hooks";
import RentalRegistrationTableHeader from "./RentalRegistrationTableHeader";
import moment from "moment";
import { RentalRegistration } from "@/types";

interface Props {
  useRentalRegistrationInstance: UseRentalRegistration;
  propertyId: string;
}

const RentalRegistrationList = ({ useRentalRegistrationInstance, propertyId }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedRentalRegistration, setSelectedRentalRegistration] = useState<RentalRegistration>(null);

  function handleClick(row: RentalRegistration) {
    setDrawerOpen(true);
    setSelectedRentalRegistration(row);
  }

  const { propertyRentalRegistration } = useRentalRegistrationInstance;
  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={
          <RentalRegistrationTableHeader
            title="Rental Registration"
            propertyId={propertyId}
            useRentalRegistrationInstance={useRentalRegistrationInstance}
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Required</TableCell>
              <TableCell>Approval Date</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>Website</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyRentalRegistration.map((row) => (
              <TableRow tabIndex={0} sx={{ width: "100%" }} hover key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{mapConfirmOptions(boolToConfirm(row.required))}</TableCell>
                <TableCell>{row?.approvalDate ? moment(row.approvalDate).format(DateFormat.DISPLAY_DATE_RANGE) : ""}</TableCell>
                <TableCell>{row?.expirationDate ? moment(row.expirationDate).format(DateFormat.DISPLAY_DATE_RANGE) : ""}</TableCell>
                <TableCell>{row.website}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <RentalRegistrationForm
            propertyId={propertyId}
            useRentalRegistrationInstance={useRentalRegistrationInstance}
            rentalRegistration={selectedRentalRegistration}
            close={() => setDrawerOpen(false)}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default RentalRegistrationList;

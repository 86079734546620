import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Checkbox } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import { ClientJourneyDtoModel, ClosedReason, FeatureFlag, JourneyRequirementDtoModel, UpdateRequirementInput } from "@/types";
import { format } from "date-fns";
import { DateFormat } from "@/utils";
import { useEffect, useState } from "react";
import ConfirmCompleteRequirementModal from "./ConfirmCompleteRequirementModal";
import { useTheme } from "@mui/system";
import { useFeature } from "@/contexts";
import { useToast } from "@/hooks";

interface Props {
  leafRequirement: JourneyRequirementDtoModel;
  clientJourney: ClientJourneyDtoModel;
  updateRequirement: (requirementId: string, input: UpdateRequirementInput) => Promise<JourneyRequirementDtoModel>;
  onRequirementUpdate: () => Promise<void>;
  milestoneCompleted: boolean;
  isLastOpenRequirement: boolean;
  topLevelRequirement?: boolean;
}

const LeafRequirement = ({
  leafRequirement,
  clientJourney,
  updateRequirement,
  onRequirementUpdate,
  milestoneCompleted,
  isLastOpenRequirement,
  topLevelRequirement,
}: Props) => {
  const { addToast } = useToast();
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isConfirmCompleteRequirementModalOpen, setIsConfirmCompleteRequirementModalOpen] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (leafRequirement.closedReason === ClosedReason.COMPLETED) {
      setChecked(true);
    } else if (leafRequirement.closedReason === ClosedReason.VOIDED) {
      setDisabled(true);
    }
  }, [leafRequirement]);

  const completeRequirement = async () => {
    try {
      await updateRequirement(leafRequirement.id, { closedReason: ClosedReason.COMPLETED });
      setChecked(true);
      addToast({ message: `Requirement "${leafRequirement.definition.managerName}" completed`, type: "success" });
    } catch (error) {
      addToast({ message: error.message, type: "error" });
    }
  };

  const openRequirement = async () => {
    try {
      await updateRequirement(leafRequirement.id, { closedReason: null });
      setChecked(false);
      addToast({ message: `Requirement "${leafRequirement.definition.managerName}" opened`, type: "info" });
    } catch (error) {
      addToast({ message: error.message, type: "error" });
    }
  };

  const handleChange = async () => {
    if (!checked && isLastOpenRequirement) {
      setIsConfirmCompleteRequirementModalOpen(true);
      return;
    }

    if (!checked) {
      await completeRequirement();
    } else {
      await openRequirement();
    }

    onRequirementUpdate();
  };

  const handleConfirmComplete = async () => {
    setIsConfirmCompleteRequirementModalOpen(false);
    await completeRequirement();
    onRequirementUpdate();
  };

  const handleConfirmCancel = () => {
    setIsConfirmCompleteRequirementModalOpen(false);
  };

  return (
    <AccordionDetails
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "0",
        paddingLeft: `${topLevelRequirement ? "0" : theme.spacing(6)}`,
        paddingBottom: "0",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          inputProps={{ "aria-label": "controlled" }}
          onChange={handleChange}
          checked={checked}
          disabled={disabled || milestoneCompleted}
        />
        <div>
          <Typography>{leafRequirement.definition.managerName}</Typography>
          <Typography sx={{ display: "flex", alignItems: "center" }} variant="caption">
            {leafRequirement.definition.isAutomated && (
              <>
                <OfflineBoltOutlinedIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
                <Typography sx={{ marginRight: "0.3rem" }} variant="caption">
                  Automated
                </Typography>
              </>
            )}
            <CalendarTodayIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
            {leafRequirement.closedAt
              ? `Completed on ${format(new Date(leafRequirement.closedAt), DateFormat.DISPLAY_DATE_TIME)}`
              : `Started on ${format(new Date(leafRequirement.createdAt), DateFormat.DISPLAY_DATE_TIME)}`}
          </Typography>
          {isLastOpenRequirement ? (
            <ConfirmCompleteRequirementModal
              open={isConfirmCompleteRequirementModalOpen}
              requirement={leafRequirement}
              onClickComplete={handleConfirmComplete}
              onClickCancel={handleConfirmCancel}
            />
          ) : null}
        </div>
      </div>
    </AccordionDetails>
  );
};

export default LeafRequirement;

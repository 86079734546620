import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import { Props as ChartProps } from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

// project import
import { useConfig } from "@/hooks";

const getLineChartOptions = ({ navType, primary, darkLight, grey200, secondary, yMin, yMax }): ChartProps => {
  return {
    chart: {
      type: "line",
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      labels: {
        datetimeFormatter: {
          month: "MMM 'yy",
        },
        style: {
          colors: primary,
        },
      },
      type: "datetime",
    },
    yaxis: {
      labels: {
        style: {
          colors: primary,
        },
      },
      min: yMin,
      max: yMax,
    },
    grid: {
      borderColor: navType === "dark" ? darkLight + 20 : grey200,
    },
    tooltip: {
      theme: navType === "dark" ? "dark" : "light",
    },
    colors: [secondary],
    markers: {
      size: 5,
    },
  };
};

const getSeriesData = (data, name) => {
  return [
    {
      name,
      data,
    },
  ];
};

export interface StringifiedDataPoint {
  x: string;
  y: number;
}

interface Props {
  data: StringifiedDataPoint[];
  name: string;
  yMin: number;
  yMax: number;
}

// ==============================|| LINE CHART ||============================== //

const ApexLineChart = ({ data, name, yMin, yMax }: Props) => {
  const theme = useTheme();
  const { navType } = useConfig();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.light;
  const grey200 = theme.palette.grey[200];
  const secondary = theme.palette.secondary.main;

  const series = useMemo(() => getSeriesData(data, name), [data, name]);

  const options = useMemo(
    () => getLineChartOptions({ navType, primary, darkLight, grey200, secondary, yMin, yMax }),
    [navType, primary, darkLight, grey200, secondary, yMin, yMax]
  );

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default ApexLineChart;

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateHoasPaymentDetailsInput, UpdateHoasPaymentDetailsInput } from "@l4s/client-property-models";

import { HoasPaymentDetails, PortfolioProperty } from "@/types/PropertyManagement";

export interface UsePortfolioProperty {
  loading: boolean;
  portfolioProperty: PortfolioProperty;
  updatePortfolioProperty: (input: PortfolioProperty) => Promise<void>;
  error: string | undefined;
}

export const usePortfolioProperty = (propertyId: string): UsePortfolioProperty => {
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioProperty, setPortfolioProperty] = useState<PortfolioProperty>();
  const [error, setError] = useState<string | undefined>(undefined);

  const getPortfolioProperty = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<PortfolioProperty>(routes.GET_PORTFOLIO_PROPERTY_BY_PROPERTY_ID(propertyId));
      setPortfolioProperty(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching application property hoa payment details.");
    }
    setLoading(false);
  };

  const updatePortfolioProperty = async (input: PortfolioProperty): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<PortfolioProperty>(routes.UPDATE_PORTFOLIO_PROPERTY_BY_PROPERTY_ID(input.propertyId), {
        status: input.status,
        entity: input.entity,
      });
      setPortfolioProperty(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating hoa payment detail.");
    }
  };

  useEffect(() => {
    if (propertyId) {
      getPortfolioProperty();
    }
  }, [propertyId]);

  return {
    loading,
    portfolioProperty,
    updatePortfolioProperty,
    error,
  };
};

import { ButtonBase, ButtonBaseProps, Grid, Typography, TypographyProps } from "@mui/material";
import { TablerIcon } from "@tabler/icons";

const iconSizeMap = {
  small: 14,
  medium: 16,
  large: 20,
};

const fontSizeMap = {
  small: 12,
  medium: 14,
  large: 16,
};

interface Props extends ButtonBaseProps {
  StartIcon?: TablerIcon;
  EndIcon?: TablerIcon;
  children: string;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  typographyProps?: TypographyProps;
  color?: string;
}

function TextButton({ children, size = "medium", fullWidth = false, typographyProps = {}, color, StartIcon, EndIcon, ...props }: Props) {
  return (
    <ButtonBase {...props} sx={{ "&:hover": { textDecoration: "underline" }, width: fullWidth ? "100%" : "fit-content", ...props?.sx }}>
      <Grid container gap={0.5}>
        {StartIcon && <StartIcon color={color} size={iconSizeMap[size]} />}
        <Typography {...typographyProps} color={color} sx={{ fontSize: fontSizeMap[size], ...typographyProps?.sx }}>
          {children}
        </Typography>
        {EndIcon && <EndIcon color={color} size={iconSizeMap[size]} />}
      </Grid>
    </ButtonBase>
  );
}

export default TextButton;

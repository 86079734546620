import { FC } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface LandisButtonProps extends ButtonProps {
  /** an [OPTIONAL] boolean value that when try changes the text to a loading icon until false */
  loading?: boolean;
}

const LandisButton: FC<LandisButtonProps> = ({ loading, ...props }) => {
  return loading ? <Button {...props} startIcon={<CircularProgress size={14} />} disabled /> : <Button {...props} />;
};

/**
 * Common button that supports standard loading icon when clicked
 * @demo https://storybook.landis.network?path=/story/base-landisbutton--contained
 *
 * @component LandisButton
 * @interface LandisButtonProps @extends ButtonProps
 * @property {boolean} loading - an [OPTIONAL] boolean value that when try changes the text to a loading icon until false
 * @property {"button" | "submit"} type - Use sumbit when triggering a form
 * @property {"contained" | "outlined" | "text"} variant - the style of the button
 * @property {boolean} disabled - make the but non-clickable and change style to grey
 * @property {Object} sx - MUI styling object: https://mui.com/system/getting-started/the-sx-prop/
 *
 * @note This component extends ButtonProps from MUI.  All properties are available in @demo link in Storybook
 */
export default LandisButton;

import { FC } from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";

interface IProps {
  value: string | undefined | null;
  handleChange: (val: number) => void;
} // Any future custom props here

type IPropsTextField = TextFieldProps & IProps;

const PercentTextField: FC<IPropsTextField> = ({ handleChange, ...props }) => {
  const onChange = (e) => {
    handleChange(e.target.value);
  };

  return (
    <TextField
      {...props}
      inputProps={{
        inputMode: "numeric",
        className: "disable-number-arrows",
      }}
      InputProps={{
        endAdornment: <Typography>%</Typography>,
      }}
      type="number"
      value={props.value}
      onChange={onChange}
    />
  );
};

export default PercentTextField;

import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import UtilityPaymentDetailsForm from "./UtilityPaymentDetailsForm";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import UtilitiesPaymentsTableHeader from "./UtilitiesPaymentDetailsTableHeader";
import { UseUtilitiesPaymentDetails, UseUtilities } from "@/hooks";
import { PortfolioProperty, UtilitiesPaymentDetails } from "@/types/PropertyManagement";
import { PaymentFrequencyDisplayMap, PaymentMethodDisplayMap, TypeOfPartyDisplayMap } from "../../useDetailForms";
import moment from "moment";
import { DateFormat } from "@/utils";

interface Props {
  useUtilitiesPaymentDetailsInstance: UseUtilitiesPaymentDetails;
  useUtilitiesInstance: UseUtilities;
  propertyId: string;
  portfolioProperty?: PortfolioProperty;
}

const UtilitiesPaymentDetailsList = ({
  useUtilitiesPaymentDetailsInstance,
  propertyId,
  useUtilitiesInstance,
  portfolioProperty,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedUtilityPaymentDetail, setSelectedUtilityPaymentDetail] = useState<UtilitiesPaymentDetails>(null);
  const { utilitiesPaymentDetails } = useUtilitiesPaymentDetailsInstance;
  const { propertyUtilities } = useUtilitiesInstance;

  function handleClick(row: UtilitiesPaymentDetails) {
    setDrawerOpen(true);
    setSelectedUtilityPaymentDetail(row);
  }

  function findUtilDisplayName(utilityId: string) {
    const util = propertyUtilities.find((utility) => utility.id === utilityId);
    return util?.name;
  }

  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={
          <UtilitiesPaymentsTableHeader
            propertyId={propertyId}
            useUtilitiesPaymentDetailsInstance={useUtilitiesPaymentDetailsInstance}
            title="Utility Payment Details"
            useUtilitiesInstance={useUtilitiesInstance}
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Responsible Party</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Payment Frequency</TableCell>
              <TableCell>Account Id</TableCell>
              <TableCell>Initial Payment Date</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Related Utility</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {utilitiesPaymentDetails.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} tabIndex={0}>
                <TableCell>{TypeOfPartyDisplayMap[row.responsibleParty]}</TableCell>
                <TableCell>{PaymentMethodDisplayMap[row.paymentMethod]}</TableCell>
                <TableCell>{PaymentFrequencyDisplayMap[row.paymentFrequency]}</TableCell>
                <TableCell>{row.externalAccountId}</TableCell>
                <TableCell>{moment(row.initialPaymentDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{moment(row.startDate).format(DateFormat.DISPLAY_DATE_RANGE)}</TableCell>
                <TableCell>{row?.endDate ? moment(row.endDate).format(DateFormat.DISPLAY_DATE_RANGE) : ""}</TableCell>
                <TableCell>{findUtilDisplayName(row.utilityId)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <UtilityPaymentDetailsForm
            close={() => setDrawerOpen(false)}
            utilityPaymentDetail={selectedUtilityPaymentDetail}
            useUtilitiesPaymentDetailsInstance={useUtilitiesPaymentDetailsInstance}
            useUtilitiesInstance={useUtilitiesInstance}
            propertyId={propertyId}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default UtilitiesPaymentDetailsList;

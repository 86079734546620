import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import queryString from "query-string";

import { DASHBOARD_PATH } from "@/config";
import { OKTA_AUTH, OKTA_AUTH_REDIRECT } from "@/constant";
import { mapUnknownToError } from "@/utils";
import { useToast } from "@/hooks";
import { FullScreenLoading } from "@/components/base";

const CallbackPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      try {
        const { hash } = window.location;

        //if not valid need to give error or direct to login static pag
        if (!hash.includes("access_token=")) {
          navigate("/login");
          return;
        }

        const { access_token, expires_in } = queryString.parse(hash);

        //if not token provided then redirect to login
        //TODO: should add error handling here
        if (typeof access_token !== "string" || typeof expires_in !== "string") {
          navigate("/login");
          return;
        }

        localStorage.setItem(OKTA_AUTH, access_token);

        const lastUrlForRedirect = localStorage.getItem(OKTA_AUTH_REDIRECT);
        const urlForDashboard = `${window.location.origin}${DASHBOARD_PATH}`;
        const toReload = (lastUrlForRedirect ?? "").includes("/login") ? urlForDashboard : lastUrlForRedirect ?? urlForDashboard;
        window.open(toReload, "_self");
      } catch (e) {
        const error = mapUnknownToError(e);
        addToast({ message: error.message, type: "error" });
        navigate("/login");
      }
    })();
  }, [pathname]);

  return <FullScreenLoading />;
};

export default CallbackPage;

import axios from "axios";
import jwtDecode from "jwt-decode";
import { JWTPayload } from "@okta/okta-auth-js";
import { useAuth } from "@/hooks";
import { env } from "@/env";
import { logToConsoleDebug, ERROR } from "@/utils";

// TODO: Auth here
export const bridgeV2ApiInstance = axios.create({
  baseURL: env.BRIDGE_V2_API_BASE_URL,
});
bridgeV2ApiInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers) return config;
    const { oktaAuthToken, logout } = useAuth();
    if (!oktaAuthToken) return config;

    // Decode both stored tokens
    const decodedOktaToken: JWTPayload | null = jwtDecode(oktaAuthToken);
    if (!decodedOktaToken?.exp) return config;

    // If Okta token is expired, redirect to Okta login page
    if (decodedOktaToken.exp * 1000 < Date.now()) logout();
    // If Bridge token is expired, try to get new Bridge token
    config.headers.authorization = `Bearer ${oktaAuthToken}`;

    return config;
  },
  (error) => {
    logToConsoleDebug(
      "bridgev2-api.ts",
      "API Error",
      [error, { message: "Error adding validating OKTA_AUTH." }],
      ERROR.BG_COLOR,
      ERROR.COLOR
    );

    Promise.reject(new Error("Error validating authenticated account token."));
  }
);

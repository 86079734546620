import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { displayUsCents } from "@/utils";
import ClientPropertyFinanceTable from "../ClientPropertyFinanceTable";
import { ClientPropertyFinanceRow } from "../types";
import ClientPropertyFinanceTableHeader from "../ClientPropertyFinanceTableHeader";
import PropertyFinancialsForm from "./PropertyFinancialsForm";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const tableRows: Array<ClientPropertyFinanceRow> = [
  {
    label: "Purchase Price",
    fieldKey: "purchasePriceCents",
    displayFn: displayUsCents,
  },
  {
    label: "Asking Price",
    fieldKey: "askingPriceCents",
    displayFn: displayUsCents,
  },
  {
    label: "Appraised Price",
    fieldKey: "appraisedPriceCents",
    displayFn: displayUsCents,
  },
  {
    label: "All in HUD",
    fieldKey: "allInHudCents",
    displayFn: displayUsCents,
  },
  {
    label: "Loan Amount",
    fieldKey: "loanAmountCents",
    displayFn: displayUsCents,
  },
  {
    label: "Inspection Price",
    fieldKey: "inspectionPriceCents",
    displayFn: displayUsCents,
  },
  {
    label: "Deposit Amount",
    fieldKey: "depositAmountCents",
    displayFn: displayUsCents,
  },
  {
    label: "Resale Price",
    fieldKey: "resalePriceCents",
    displayFn: displayUsCents,
  },
  {
    label: "Closing Costs",
    fieldKey: "closingCosts",
    displayFn: displayUsCents,
  },
  {
    label: "Lender Cost",
    fieldKey: "lenderCostCents",
    displayFn: displayUsCents,
  },
  {
    label: "Seller's Credit",
    fieldKey: "sellersCreditCents",
    displayFn: displayUsCents,
  },
  {
    label: "DD Fee",
    fieldKey: "ddFeeCents",
    displayFn: displayUsCents,
  },
  {
    label: "Appraisal Price",
    fieldKey: "appraisalPriceCents",
    displayFn: displayUsCents,
  },
];

const PropertyFinancialsCard = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const isFormOpenStateInstance = useState<boolean>(false);
  const [, setIsFormOpen] = isFormOpenStateInstance;

  return (
    <ClientPropertyFinanceTable
      useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
      tableRows={tableRows}
      title={
        <ClientPropertyFinanceTableHeader
          FormElement={
            <PropertyFinancialsForm
              handleClose={() => setIsFormOpen(false)}
              useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
            />
          }
          isFormOpenStateInstance={isFormOpenStateInstance}
          title="Property Financials"
        />
      }
    />
  );
};

export default PropertyFinancialsCard;

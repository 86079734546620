import { AssignedArticle } from "@l4s/intercom-models";

export enum StatusOfAction {
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export enum TypeOfAction {
  NO_ACTION = "NO_ACTION",
  LINK = "LINK",
  SHORT_RESPONSE = "SHORT_RESPONSE",
  PULSE = "PULSE",
}

export enum ActionPriorityLevel {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export interface Action {
  id: string;
  name: string;
  description: string;
  type: TypeOfAction;
  status: StatusOfAction;
  dueDate?: string;
  dueDateLabel?: string;
  publishDate?: string;
  link?: string;
  buttonText?: string;
  creatorAccountId: string;
  programClientId: string;
  response?: string;
  pulseRating?: number;
  completionDate?: string;
  learnMoreLink?: string;
  priority?: ActionPriorityLevel;
  // join with action_goals
  goalId?: string;
  created: string;
  assignedArticle?: AssignedArticle;
}

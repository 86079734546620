import { FilledTemplateFieldInTemplate, TemplateFieldType, isDocusignTemplateField } from "@/types";
import { HighlightArea, RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { isFillTemplateFieldInTemplateFilled, useFieldStatusTheme } from "../util";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { DocusignIcon } from "@/components/base";

interface AnchorMarkerProps {
  filledTemplateFieldInTemplate: FilledTemplateFieldInTemplate;
}

function AnchorMarker({ filledTemplateFieldInTemplate }: AnchorMarkerProps) {
  const { color } = useFieldStatusTheme(filledTemplateFieldInTemplate);

  const fontSize = "calc(.5 * (1.5vh + 1.1vw))";
  const padding = "calc(.01 * (1.5vh + 1.1vw))";

  const nameStyle: React.CSSProperties = {
    color: "#000",
    // Adds opacity to the color
    background: color + "20",
    position: "absolute",
    border: "2px solid " + color,
    whiteSpace: "nowrap",
    padding,
    fontSize,
    lineHeight: 0.9,
  };

  if (isDocusignTemplateField(filledTemplateFieldInTemplate.templateFieldType))
    return <DocusignIcon style={{ height: fontSize, width: fontSize }} />;

  if (filledTemplateFieldInTemplate.templateFieldType === TemplateFieldType.CHECKBOX)
    return (
      <span style={nameStyle}>
        {filledTemplateFieldInTemplate.value === "Yes" ? (
          <CheckBox style={{ height: fontSize, width: fontSize }} />
        ) : filledTemplateFieldInTemplate.value === "No" ? (
          <CheckBoxOutlineBlank style={{ height: fontSize, width: fontSize }} />
        ) : filledTemplateFieldInTemplate.isRequired ? (
          "NC"
        ) : (
          "NR"
        )}
      </span>
    );

  return (
    <span style={nameStyle}>
      {isFillTemplateFieldInTemplateFilled(filledTemplateFieldInTemplate)
        ? filledTemplateFieldInTemplate.value
        : filledTemplateFieldInTemplate.isRequired
        ? "NC"
        : "NR"}
    </span>
  );
}

interface Props {
  filledTemplateFieldInTemplate: FilledTemplateFieldInTemplate;
  highlightArea: HighlightArea;
  renderHighlightsProps: RenderHighlightsProps;
}

const PositionedAnchorMarker = (props: Props) => {
  return (
    <div style={Object.assign(props.renderHighlightsProps.getCssProperties(props.highlightArea, props.renderHighlightsProps.rotation))}>
      <div style={{ position: "relative" }}>
        <AnchorMarker {...props} />
      </div>
    </div>
  );
};

export default PositionedAnchorMarker;

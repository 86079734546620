import { ReactElement } from "react";

export const LAYOUT_CONST = {
  VERTICAL_LAYOUT: "vertical",
  HORIZONTAL_LAYOUT: "horizontal",
  DEFAULT_DRAWER: "default",
  MINI_DRAWER: "mini-drawer",
};

export const LAYOUT: any = {
  main: "main",
  noauth: "noauth",
  minimal: "minimal",
};

export interface Props {
  children: ReactElement | ReactElement[];
  variant?: "main" | "minimal" | "noauth";
}

export const DEFAULT_PADDING = "12px";

export const OKTA_AUTH = "OKTA_AUTH";
export const OKTA_AUTH_REDIRECT = "OKTA_AUTH_REDIRECT";

export const CLIENT_MENU_ITEMS = "CLIENT_MENU_ITEMS";

export const DEFAULT_BRIDGE_V2_ACCOUNT_ID = "00000000-5000-0000-0000-a00000000001";

export default LAYOUT;

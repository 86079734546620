import { useCallback } from "react";
import { useDropzone, DropzoneOptions, FileRejection } from "react-dropzone";
import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { IconUpload } from "@tabler/icons";
import { useToast } from "@/hooks";

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  onFilesSelected: (files: Array<File>) => void;
  label?: string;
  // https://react-dropzone.js.org/#section-accepting-specific-file-types
  accept?: DropzoneOptions["accept"];
  maxFiles?: number;
}

function FileInput({ onFilesSelected, label = "Drop or Select Files", accept, maxFiles, ...props }: Props) {
  const { addToast } = useToast();

  const onDropAccepted = useCallback(
    (acceptedFiles: Array<File>) => {
      onFilesSelected(acceptedFiles);
    },
    [onFilesSelected]
  );
  const onDropRejected = (rejectedFiles: Array<FileRejection>) => {
    for (const rejectedFile of rejectedFiles) {
      addToast({ message: rejectedFile.errors.map((e) => e.message).join(" ") || "Error", type: "error" });
    }
  };

  const { getRootProps } = useDropzone({ onDropAccepted, onDropRejected, accept, maxFiles });

  return (
    <div {...getRootProps()}>
      <TextField
        fullWidth
        label={label}
        color="primary"
        size="medium"
        {...props}
        inputProps={{ style: { cursor: "pointer" }, ...props?.inputProps }}
        InputProps={{
          readOnly: true,
          startAdornment: <IconUpload />,
          ...props?.InputProps,
        }}
        InputLabelProps={{ shrink: false, sx: { paddingLeft: 4 }, ...props?.InputLabelProps }}
      />
    </div>
  );
}

export default FileInput;

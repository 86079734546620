import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";

import { UtilitiesPaymentDetails } from "@/types/PropertyManagement";
import { CreateUtilitiesPaymentDetailsInput, UpdateUtilitiesPaymentDetailsInput } from "@l4s/client-property-models";

export interface UseUtilitiesPaymentDetails {
  loading: boolean;
  utilitiesPaymentDetails: Array<UtilitiesPaymentDetails>;
  createUtilityPaymentDetail: (input: CreateUtilitiesPaymentDetailsInput) => Promise<void>;
  updateUtilityPaymentDetail: (input: UpdateUtilitiesPaymentDetailsInput) => Promise<void>;
  reloadUtilitiesPaymentDetails: () => Promise<void>;
  error: string | undefined;
}

export const useUtilitiesPaymentDetails = (propertyId: string): UseUtilitiesPaymentDetails => {
  const [loading, setLoading] = useState<boolean>(false);
  const [utilitiesPaymentDetails, setUtilitiesPaymentDetails] = useState<Array<UtilitiesPaymentDetails>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getUtilitiesPaymentDetails = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<UtilitiesPaymentDetails>>(routes.GET_UTILITIES_PAYMENT_DETAILS, {
        params: { propertyId },
      });
      setUtilitiesPaymentDetails(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching utility payment details.");
    }
    setLoading(false);
  };

  const createUtilityPaymentDetail = async (input: CreateUtilitiesPaymentDetailsInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<UtilitiesPaymentDetails>(routes.CREATE_UTILITY_PAYMENT_DETAIL, {
        ...input,
      });
      setUtilitiesPaymentDetails((existingUtilityPaymentDetails) => [...existingUtilityPaymentDetails, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating utility payment detail.");
    }
  };

  const updateUtilityPaymentDetail = async (input: UpdateUtilitiesPaymentDetailsInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<UtilitiesPaymentDetails>(routes.UPDATE_UTILITY_PAYMENT_DETAIL(input.id), input);
      setUtilitiesPaymentDetails((existingUtilityPaymentDetails) =>
        existingUtilityPaymentDetails.map((utilityPaymentDetail) =>
          utilityPaymentDetail.id === response.data.id ? { ...utilityPaymentDetail, ...response.data } : utilityPaymentDetail
        )
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating utility payment detail.");
    }
  };

  useEffect(() => {
    if (propertyId) {
      getUtilitiesPaymentDetails();
    }
  }, [propertyId]);

  return {
    loading,
    utilitiesPaymentDetails,
    createUtilityPaymentDetail,
    updateUtilityPaymentDetail,
    reloadUtilitiesPaymentDetails: getUtilitiesPaymentDetails,
    error,
  };
};

import useToast from "@/hooks/useToast";
import { bridgeV2ApiInstance } from "@/services";
import {
  CREATE_TEMPLATE_FILL_FIELD_VALUE,
  DELETE_TEMPLATE_FILL_FIELD_VALUE,
  GET_TEMPLATE_FILL_FIELD_VALUES_IN_TEMPLATE_FILL,
  UPDATE_TEMPLATE_FILL_FIELD_VALUE,
} from "@/services/routes";
import { TemplateFillFieldValue } from "@/types";
import { useEffect, useState } from "react";

export interface AddTemplateFillFieldValueInput {
  templateFillId: string;
  templateFieldId: string;
  value: string;
}

export interface UseTemplateFillFieldValuesHook {
  templateFillFieldValues: Array<TemplateFillFieldValue>;
  loading: boolean;
  createTemplateFillFieldValue: (input: AddTemplateFillFieldValueInput) => Promise<void>;
  updateTemplateFillFieldValue: (templateFillFieldValueId: string, value: string) => Promise<void>;
  deleteTemplateFillFieldValue: (templateFillFieldValueId: string) => Promise<void>;
}

export const useTemplateFillFieldValues = (envelopeId: string, templateFillId?: string): UseTemplateFillFieldValuesHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templateFillFieldValues, setTemplateFillFieldValues] = useState<Array<TemplateFillFieldValue>>([]);

  const { addToast } = useToast();

  const getTemplateFillFieldValues = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<TemplateFillFieldValue>>(
        GET_TEMPLATE_FILL_FIELD_VALUES_IN_TEMPLATE_FILL(envelopeId, templateFillId)
      );
      setTemplateFillFieldValues(response.data);
    } catch (e) {
      console.error(e);
      addToast({
        message: e.response?.data?.message || "There was an error getting the field values.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const createTemplateFillFieldValue: UseTemplateFillFieldValuesHook["createTemplateFillFieldValue"] = async (
    templateFillFieldValueInput
  ) => {
    try {
      const response = await bridgeV2ApiInstance.post<TemplateFillFieldValue>(
        CREATE_TEMPLATE_FILL_FIELD_VALUE(envelopeId, templateFillId),
        templateFillFieldValueInput
      );
      setTemplateFillFieldValues((prevTemplateFillFieldValues) => [...prevTemplateFillFieldValues, response.data]);
    } catch (e) {
      console.error(e);
      addToast({
        message: e.response?.data?.message || "There was an error saving the field value.",
        type: "error",
      });
    }
  };

  const updateTemplateFillFieldValue: UseTemplateFillFieldValuesHook["updateTemplateFillFieldValue"] = async (
    templateFillFieldValueId,
    value
  ) => {
    try {
      const response = await bridgeV2ApiInstance.put<TemplateFillFieldValue>(
        UPDATE_TEMPLATE_FILL_FIELD_VALUE(envelopeId, templateFillId, templateFillFieldValueId),
        {
          value,
        }
      );
      setTemplateFillFieldValues((prevTemplateFillFieldValues) =>
        prevTemplateFillFieldValues.map((prevTemplateFillFieldValue) =>
          prevTemplateFillFieldValue.id === templateFillFieldValueId ? response.data : prevTemplateFillFieldValue
        )
      );
    } catch (e) {
      console.error(e);
      addToast({
        message: e.response?.data?.message || "There was an error updating field value.",
        type: "error",
      });
    }
  };

  const deleteTemplateFillFieldValue = async (templateFillFieldValueId: string) => {
    try {
      await bridgeV2ApiInstance.delete<void>(DELETE_TEMPLATE_FILL_FIELD_VALUE(envelopeId, templateFillId, templateFillFieldValueId));
      setTemplateFillFieldValues((prevTemplateFillFieldValues) =>
        prevTemplateFillFieldValues.filter((prevTemplateFillFieldValue) => prevTemplateFillFieldValue.id !== templateFillFieldValueId)
      );
    } catch (e) {
      console.error(e);
      addToast({
        message: e.response?.data?.message || "There was an error deleting field value.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setTemplateFillFieldValues([]);
    if (templateFillId) {
      getTemplateFillFieldValues();
    }
  }, [templateFillId]);

  return {
    templateFillFieldValues,
    loading,
    createTemplateFillFieldValue,
    updateTemplateFillFieldValue,
    deleteTemplateFillFieldValue,
  };
};

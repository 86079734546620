import { snakeCaseToDisplay } from "@/utils";
import { DateFormat } from "@/utils";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import format from "date-fns/format";
import InspectionReportLink from "./InspectionReportLink";
import { InspectionWithReportsDto, inspectionSourceIdToDisplay } from "@/types";

interface Props {
  inspection: InspectionWithReportsDto;
}

const PropertyInspectionSummary: React.FC<Props> = ({ inspection }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Inspection Date</TableCell>
            <TableCell>Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {snakeCaseToDisplay(inspection.inspectionType)}
            </TableCell>
            <TableCell>{snakeCaseToDisplay(inspection.status)}</TableCell>
            <TableCell>{inspectionSourceIdToDisplay(inspection.sourceId)}</TableCell>
            <TableCell>
              {inspection.inspectionDateTime ? format(new Date(inspection.inspectionDateTime), DateFormat.DISPLAY_DATE_TIME) : "-"}
            </TableCell>
            <TableCell>
              {inspection.reports.length
                ? inspection.reports.map(({ id: reportId }) => {
                    return (
                      <div key={reportId}>
                        <InspectionReportLink key={reportId} inspectionReportId={reportId} />
                      </div>
                    );
                  })
                : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyInspectionSummary;

import { MainCard } from "@/components/berry";
import { env } from "@/env";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  bridgeUrl: string;
}

function UnderConstructionCard({ bridgeUrl }: Props) {
  return (
    <MainCard sx={{ paddingY: 5 }}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={1} width="100%">
        <img src="https://i.gifer.com/ZSj2.gif" style={{ height: "15rem" }} />
        <h1>Under Construction</h1>
        <a target="_none" href={`${env.BRIDGE_V1_BASE_URL}${bridgeUrl}`}>
          <Button variant="contained">Go to Bridge</Button>
        </a>
      </Box>
    </MainCard>
  );
}

export default UnderConstructionCard;

import { Box, Button, Chip, Grid, Typography } from "@mui/material";

import { PaymentPlan } from "@/types";
import { ExpectedPaymentForm, ExpectedPaymentRow } from "./ExpectedPaymentRow";
import { useState } from "react";
import { AddOutlined, CheckCircle } from "@mui/icons-material";
import { UsePaymentPlans, useToast } from "@/hooks";
import { sortByDate } from "@/utils/sort";
import { LandisButton } from "@/components/base";
import { mapUnknownToError } from "@/utils";

interface Props {
  paymentPlan: PaymentPlan;
  createExpectedPayment: UsePaymentPlans["createExpectedPayment"];
  deleteExpectedPaymentById: UsePaymentPlans["deleteExpectedPaymentById"];
  updateExpectedPayment: UsePaymentPlans["updateExpectedPayment"];
  activatePaymentPlan: UsePaymentPlans["activatePaymentPlan"];
}

const ViewPaymentPlan = ({
  paymentPlan,
  createExpectedPayment,
  deleteExpectedPaymentById,
  updateExpectedPayment,
  activatePaymentPlan,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { addToast } = useToast();

  const onActivate = async () => {
    setLoading(true);
    try {
      await activatePaymentPlan(paymentPlan.id);
    } catch (e) {
      const error = mapUnknownToError(e);
      addToast({ message: error.message, type: "error" });
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} direction="column">
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{paymentPlan.name}</Typography>
        {paymentPlan.isCurrent && <Chip icon={<CheckCircle fontSize="small" />} label="Active" variant="filled" color="default" />}
        {!paymentPlan.isCurrent && (
          <LandisButton
            disableElevation
            onClick={onActivate}
            loading={loading}
            startIcon={<CheckCircle fontSize="small" />}
            variant="contained"
            size="small"
            sx={{ borderRadius: 1000 }}
          >
            Activate
          </LandisButton>
        )}
      </Grid>
      <Box sx={{ marginTop: "12px" }} />
      <Grid container item xs={12} direction="column" gap="5px">
        {paymentPlan.expectedPayments.sort(sortByDate("paymentDate")).map((expectedPayment) => (
          <ExpectedPaymentRow
            deleteExpectedPaymentById={deleteExpectedPaymentById}
            updateExpectedPayment={updateExpectedPayment}
            key={expectedPayment.id}
            expectedPayment={expectedPayment}
          />
        ))}
      </Grid>
      {!Boolean(paymentPlan.expectedPayments.length) && (
        <Typography>
          <i>No expected payments.</i>
        </Typography>
      )}
      <Box sx={{ marginTop: "24px" }} />
      {isCreating && (
        <ExpectedPaymentForm createExpectedPayment={createExpectedPayment} planId={paymentPlan.id} onCancel={() => setIsCreating(false)} />
      )}
      {!isCreating && (
        <Grid item container xs={12}>
          <Button
            onClick={() => setIsCreating(true)}
            disableElevation
            startIcon={<AddOutlined />}
            variant={paymentPlan.isCurrent ? "contained" : "outlined"}
          >
            Add payment
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ViewPaymentPlan;

import { bridgeV2ApiInstance, routes } from "@/services";
import { ProgramClient } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { TypeOfLandisProgram } from "@l4s/program-models";
import { useState } from "react";

export interface UseProgramClients {
  programClients: ProgramClient[];
  programClientDropdownList: string[];
  loadProgramClients: () => Promise<void>;
}
// Todo - Tear this out!!!! Bad bad bad so many program clients O__O
export const useProgramClients = (): UseProgramClients => {
  const [programClients, setProgramClients] = useState<Array<ProgramClient>>();
  const [programClientDropdownList, setProgramClientDropdownList] = useState<Array<string>>([]);

  const loadProgramClients = async () => {
    try {
      setProgramClients(undefined);
      const response: { data: ProgramClient[] } = await bridgeV2ApiInstance.get(
        routes.GET_PROGRAM_CLIENTS_BY_PROGRAM(TypeOfLandisProgram.LANDIS)
      );
      setProgramClients(response.data);
      const programClientIds = [];
      response?.data.forEach((programClient) => programClientIds.push(programClient.id));

      setProgramClientDropdownList(programClientIds);
    } catch (e) {
      console.error(e);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching program clients.");
    }
  };

  return {
    programClients,
    loadProgramClients,
    programClientDropdownList,
  };
};

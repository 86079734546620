import { MainCard } from "@/components/berry";
import { UseEnvelopeHook } from "@/hooks";
import { Chip, Grid, Typography } from "@mui/material";
import { IconExternalLink } from "@tabler/icons";
import { env } from "@/env";
import moment from "moment";

import DocusignEventsTable from "./DocusignEventsTable";

interface Props {
  useEnvelopeInstance: UseEnvelopeHook;
}

const DocusignEnvelopeDetail = ({ useEnvelopeInstance }: Props) => {
  const { currentExternalEnvelope } = useEnvelopeInstance;

  return (
    <MainCard
      title={
        <Grid container justifyContent="space-between" flexWrap="nowrap">
          <Grid item container direction="column" gap={0.5}>
            <Typography variant="h2">Docusign Envelope Journey</Typography>
            <Typography variant="body1">
              <i>Sent to Docusign on {moment(currentExternalEnvelope.created).format("MMMM Do, YYYY \\at h:mm a")}</i>
            </Typography>
          </Grid>
          <Grid item>
            <a href={`${env.DOCUSIGN_ENVELOPE_DETAIL_BASE_URL}/${currentExternalEnvelope.externalId}`} target="_none">
              <Chip clickable icon={<IconExternalLink size={16} />} color="primary" label="View in Docusign" />
            </a>
          </Grid>
        </Grid>
      }
    >
      <Grid item xs={12} container>
        <DocusignEventsTable useEnvelopeInstance={useEnvelopeInstance} />
      </Grid>
    </MainCard>
  );
};

export default DocusignEnvelopeDetail;

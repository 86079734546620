import { LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { PropertyAutocomplete, PropertyAutocompleteOption } from "@/components/partial";
import { UseApplicationsPropertiesHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

interface Props {
  useApplicationsPropertiesInstance: UseApplicationsPropertiesHook;
}

function AddClientPropertyForm({ useApplicationsPropertiesInstance }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<PropertyAutocompleteOption>();

  const { createApplicationsProperty } = useApplicationsPropertiesInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      propertyId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await createApplicationsProperty(values.propertyId);
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.propertyId) {
        errors.propertyId = "Please select a property.";
      }
      return errors;
    },
  });

  return (
    <MainCard title="Add Client Property">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <PropertyAutocomplete
              onSelectProperty={(autocompleteOption) => {
                formik.setFieldValue("propertyId", autocompleteOption?.id || null);
                setSelectedProperty(autocompleteOption);
              }}
              selectedProperty={selectedProperty}
              formikErrorText={formik.errors.propertyId}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default AddClientPropertyForm;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

interface LoadEviction {
  reload?: boolean;
}

export enum Jv {
  JV1 = "JV1",
  JV2 = "JV2",
}

export enum Entity {
  LANDIS_I = "LANDIS_PROPERTIES_I",
  LANDIS_II = "LANDIS_PROPERTIES_II",
}

export enum EvictionCompany {
  NATIONWIDE_COMPLIANT = "NATIONWIDE_COMPLIANT",
  END2END = "END2END",
}

export enum TypeOfEviction {
  NON_PAYMENT = "NON_PAYMENT",
  LEASE_VIOLATION = "LEASE_VIOLATION",
}

export enum EvictionNoticeSender {
  LANDIS = "LANDIS",
  ATTORNEY = "ATTORNEY",
  NOT_APPLICABLE = "N/A",
}

export enum EvictionFilingStatus {
  YES = "YES",
  NO = "NO",
  PENDING = "PENDING",
}

export enum WorkflowStage {
  EVICTION_SUBMISSION = "EVICTION_SUBMISSION",
  NOTICE_PREP = "NOTICE_PREP",
  NOTICE_FILING = "NOTICE_FILING",
  COMPLAINT_SERVICE = "COMPLAINT_SERVICE",
  HEARING_SCHEDULED = "HEARING_SCHEDULED",
  ADJOURNMENT = "ADJOURNMENT",
  RESCHEDULED = "RESCHEDULED",
  WRIT = "WRIT",
  EVICTION_CLOSED = "EVICTION_CLOSED",
}

export enum EvictionHearingStatus {
  COMPLETED = "COMPLETED",
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  NOT_APPLICABLE = "N/A",
}

export interface CreateEvictionInput {
  programClientId: string;
  jv: Jv;
  entity: Entity;
  company?: EvictionCompany;
  county: string;
  evictionType: TypeOfEviction;
  dateToProvider?: string;
  noticeSender?: EvictionNoticeSender;
  dateNoticeSent?: string;
  bankruptcy?: boolean;
  filingStatus?: EvictionFilingStatus;
  filingDate?: string;
  vacancyDate?: string;
  notes?: string;
  hearingStatus?: EvictionHearingStatus;
  hearingDate?: string;
  workflowStage?: WorkflowStage;
}

export interface UpdateEvictionInput {
  id: string;
  programClientId: string;
  jv?: Jv;
  entity?: Entity;
  company?: EvictionCompany;
  county?: string;
  evictionType: TypeOfEviction;
  dateToProvider?: string;
  noticeSender?: EvictionNoticeSender;
  dateNoticeSent?: string;
  bankruptcy?: boolean;
  filingStatus?: EvictionFilingStatus;
  filingDate?: string;
  vacancyDate?: string;
  creatorAccountId?: string;
  notes?: string;
  hearingStatus?: EvictionHearingStatus;
  hearingDate?: string;
  workflowStage?: WorkflowStage;
}

export interface Eviction {
  id: string;
  programClientId: string;
  jv: Jv;
  entity: Entity;
  company?: EvictionCompany;
  county: string;
  evictionType: TypeOfEviction;
  dateToProvider?: string;
  noticeSender?: EvictionNoticeSender;
  dateNoticeSent?: string;
  bankruptcy?: boolean;
  filingStatus?: EvictionFilingStatus;
  filingDate?: string;
  vacancyDate?: string;
  creatorAccountId: string;
  notes?: string;
  hearingStatus?: EvictionHearingStatus;
  hearingDate?: string;
  workflowStage?: WorkflowStage;
}

export interface UseEviction {
  eviction: Eviction;
  loadEviction: (input?: LoadEviction) => Promise<Eviction>;
  createEviction: (input?: CreateEvictionInput) => Promise<Eviction>;
  updateEviction: (input?: UpdateEvictionInput) => Promise<Eviction>;
}

export const useEviction = (programClientId: string): UseEviction => {
  const [eviction, setEviction] = useState<Eviction>();

  const loadEviction: UseEviction["loadEviction"] = async (input) => {
    if (eviction && !input?.reload) return eviction;
    try {
      const response: { data: Array<Eviction> } = await bridgeV2ApiInstance.get(routes.EVICTIONS, { params: { programClientId } });
      if (response.data) {
        setEviction(response.data[0]);
      }
      return response.data[0];
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching eviction.");
    }
  };

  const createEviction: UseEviction["createEviction"] = async (input) => {
    try {
      const response = await bridgeV2ApiInstance.post<{ eviction: CreateEvictionInput }, { data: Eviction }>(routes.EVICTIONS, {
        eviction: input,
      });
      setEviction(response.data);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating eviction.");
    }
  };

  const updateEviction: UseEviction["updateEviction"] = async (input) => {
    try {
      const response = await bridgeV2ApiInstance.put<{ eviction: UpdateEvictionInput }, { data: Eviction }>(routes.EVICTIONS, {
        eviction: input,
      });
      setEviction(response.data);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error updating eviction.");
    }
  };

  return {
    eviction,
    loadEviction,
    createEviction,
    updateEviction,
  };
};

import { useFormik, FormikProps } from "formik";

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export enum ConfirmOption {
  YES = "YES",
  NO = "NO",
}

export const mapConfirmOptions = (opt: ConfirmOption) => {
  switch (opt) {
    case ConfirmOption.NO:
      return "No";
    case ConfirmOption.YES:
      return "Yes";
    default:
      break;
  }
};

export const CONFIRM_DROPDOWN_ITEMS = Object.keys(ConfirmOption).map((opt: ConfirmOption) => ({
  value: opt,
  label: mapConfirmOptions(opt),
}));

export const confirmToBool = (c: ConfirmOption): boolean => {
  return c === ConfirmOption.YES;
};

export const boolToConfirm = (b: boolean): ConfirmOption => {
  return b ? ConfirmOption.YES : ConfirmOption.NO;
};

export function formikCheckError<T>(formik: FormikProps<T>, fieldName: string): boolean {
  const fieldMeta = formik.getFieldMeta(fieldName);
  return (Boolean(fieldMeta?.value) || Boolean(fieldMeta?.touched)) && Boolean(fieldMeta?.error);
}

export function formikErrorMessage<T>(formik: FormikProps<T>, fieldName: string): string {
  if (formikCheckError<T>(formik, fieldName)) {
    const fieldMeta = formik.getFieldMeta(fieldName);
    return typeof fieldMeta?.error === "string" ? fieldMeta.error : "";
  }
  return "";
}

export function whenEmptyStrThenNull(val?: any) {
  return val === "" ? null : val;
}

export function handleFakeEvent<T>(formik: FormikProps<T>, fieldName: string, value: unknown) {
  return formik.handleChange({ target: { name: fieldName, value } });
}

export function handleCurrencyInput(form: ReturnType<typeof useFormik>, field: string, value: string): void {
  let dollarAmount: string = value;
  // get rid of '$' immediately, but not if user highlights entire input and types a new value
  if (value.startsWith("$")) {
    dollarAmount = dollarAmount.slice(1);
  }

  // if user clears input
  if (dollarAmount === "") {
    form.setFieldValue(field, "");

    return;
  } else if (dollarAmount === "0") {
    form.setFieldValue(field, 0);

    return;
  }

  // if user enters NaN
  if (Number.isNaN(Number.parseInt(dollarAmount))) {
    return;
  }

  form.setFieldValue(field, Number.parseInt(dollarAmount));
}

import LineChart, { StringifiedDataPoint } from "./LineChart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useTheme } from "@mui/material/styles";
import { HoverDataCard, MainCard } from "@/components/berry";
import { Box } from "@mui/system";
import moment from "moment";
import { formatPercentage } from "@/utils";

export interface DataPoint {
  x: Date;
  y: number;
}

interface Props {
  data: DataPoint[];
  dataPointName: string;
  yMin: number;
  yMax: number;
  chartTitle: string;
  cardTitle: string;
  textOverride?: string;
}

interface DataCardOptions {
  text: string;
  icon: typeof HorizontalRuleIcon;
  color: string;
}

function findFirstDataPointOlderThan30Days(data: DataPoint[]): DataPoint | null {
  let dataPoint: DataPoint | null = null;

  const priorDate = new Date(moment(moment().subtract(30, "days")).format());

  for (let dp of data) {
    if (new Date(dp.x) < priorDate) {
      dataPoint = dp;
      return dataPoint;
    }
  }

  return dataPoint;
}

function convertDataPointXToString(data: DataPoint[]): StringifiedDataPoint[] {
  return data.map((dp) => ({
    x: dp.x.toString(),
    y: dp.y,
  }));
}

function LineChartDataCard({ data, dataPointName, yMin, yMax, chartTitle, cardTitle, textOverride }: Props) {
  const theme = useTheme();

  function getIconTextAndColor(): DataCardOptions {
    if (textOverride) {
      return {
        text: textOverride,
        icon: HorizontalRuleIcon,
        color: theme.palette.primary.dark,
      };
    }
    if (data.length >= 2) {
      const mostRecentFigure = data[0].y;
      const previousFigure = findFirstDataPointOlderThan30Days(data)?.y;

      if (!previousFigure) {
        return {
          text: "Not enough data",
          icon: HorizontalRuleIcon,
          color: theme.palette.primary.dark,
        };
      }

      if (mostRecentFigure === previousFigure) {
        return {
          text: "No change in last 30 days",
          icon: HorizontalRuleIcon,
          color: theme.palette.primary.dark,
        };
      }

      if (mostRecentFigure > previousFigure) {
        const relativeChange = formatPercentage((mostRecentFigure - previousFigure) / previousFigure);

        return {
          text: `${relativeChange} increase in last 30 days`,
          icon: ArrowUpwardIcon,
          color: theme.palette.success.dark,
        };
      }

      if (mostRecentFigure < previousFigure) {
        const relativeChange = formatPercentage((mostRecentFigure - previousFigure) / previousFigure);

        return {
          text: `${relativeChange} decrease in last 30 days`,
          icon: ArrowDownwardIcon,
          color: theme.palette.error.main,
        };
      }
    }

    return {
      text: "Not enough data",
      icon: HorizontalRuleIcon,
      color: theme.palette.primary.dark,
    };
  }
  const { icon, text, color } = getIconTextAndColor();
  return (
    <>
      <HoverDataCard title={cardTitle} iconPrimary={icon} primary={data[0] ? data[0].y : 0} secondary={text} color={color} />
      <Box sx={{ marginBottom: "12px" }} />
      <MainCard title={chartTitle}>
        <LineChart data={convertDataPointXToString(data)} name={dataPointName} yMin={yMin} yMax={yMax} />
      </MainCard>
    </>
  );
}

export default LineChartDataCard;

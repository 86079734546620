import { Button, Grid, Typography } from "@mui/material";
import { MainCard } from "@/components/berry";
import { gridSpacing } from "@/store/constant";
import { env } from "@/env";
import { Box } from "@mui/system";

function HomePage() {
  const bridge1Link = `https://${!env.isProdEnv ? "dev-" : ""}bridge.landis.com`;
  const bridge1ClientsLink = `${bridge1Link}/clients`;

  return (
    <>
      <MainCard
        style={{ maxWidth: 500 }}
        title={
          <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
            <Grid item>
              <Typography variant="h3">Welcome to Bridge 2.0</Typography>
            </Grid>
          </Grid>
        }
      >
        <Grid item xs={12} container direction="column" spacing={gridSpacing}>
          <Grid item>
            <Typography variant="body1">
              Bridge 2.0 is an extension of{" "}
              <a href={bridge1Link} target="_none">
                Bridge.
              </a>{" "}
              Currently, this application functions as a client management tool for clients that are past the application process and in a
              home.
            </Typography>
            <Box sx={{ margin: 1 }} />
            <Typography variant="body1">
              To find a client, head to{" "}
              <a target="_none" href={bridge1ClientsLink}>
                Bridge
              </a>{" "}
              and search for the client. From the client detail page, you will be able to open their Bridge 2.0 profile.
            </Typography>
            <Box sx={{ margin: 1 }} />
            <Typography variant="body1">
              Once you navigate to a client's Bridge 2.0 profile, the client profile will be saved in the <b>Client List</b> in the left
              sidebar until cleared.
            </Typography>
            <Box sx={{ margin: 1 }} />
            <Grid container>
              <Grid item xs={12}>
                <a href={bridge1ClientsLink} target="_none">
                  <Button variant="contained">Search Clients</Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

export default HomePage;

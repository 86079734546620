import { bridgeV2ApiInstance, routes } from "@/services";
import { FinancialQuestionnaireDtoModel } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseFinancialQuestionnaire {
  financialQuestionnaire: FinancialQuestionnaireDtoModel;
  getFinancialQuestionnaire: () => Promise<void>;
  isFinancialQuestionnaireLoading: boolean;
}

export const useFinancialQuestionnaire = (applicationId: string): UseFinancialQuestionnaire => {
  const [financialQuestionnaire, setFinancialQuestionnaire] = useState<FinancialQuestionnaireDtoModel>();
  const [isFinancialQuestionnaireLoading, setIsLoading] = useState<boolean>(false);

  const getFinancialQuestionnaire = async () => {
    setIsLoading(true);
    try {
      setFinancialQuestionnaire(undefined);
      const response = await bridgeV2ApiInstance.get<FinancialQuestionnaireDtoModel>(routes.GET_FINANCIAL_QUESTIONNAIRE(applicationId));
      setFinancialQuestionnaire(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching financial questionnaire details.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    financialQuestionnaire,
    getFinancialQuestionnaire,
    isFinancialQuestionnaireLoading,
  };
};

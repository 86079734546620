import { Check, CloudUpload } from "@mui/icons-material";
import { Button, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Template } from "@/types";
import { useState } from "react";
import { LandisButton } from "@/components/base";

interface Props {
  template: Template;
  isAutofillDocumentSaveRequired: boolean;
  onComposeTemplateDocument: () => Promise<void>;
  isFieldNamesShown: boolean;
  setIsFieldNamesShown: (value: boolean) => void;
}

function ConfigureTemplateHeader({
  template,
  onComposeTemplateDocument,
  isFieldNamesShown,
  setIsFieldNamesShown,
  isAutofillDocumentSaveRequired,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleComposeTemplateDocument = async () => {
    setLoading(true);
    await onComposeTemplateDocument();
    setLoading(false);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5} container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h2">{template.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{template.description}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 1 }}>
        <FormControlLabel
          control={<Switch checked={isFieldNamesShown} onChange={(e) => setIsFieldNamesShown(e.target.checked)} />}
          label="Show field names"
        />
        {/* This button will trigger the "Docusign Ready" PDF to be generated and saved to S3 under the template record */}
        {isAutofillDocumentSaveRequired && (
          <LandisButton
            loading={loading}
            endIcon={<CloudUpload />}
            variant="contained"
            color="primary"
            onClick={handleComposeTemplateDocument}
          >
            Save Template
          </LandisButton>
        )}
        {!isAutofillDocumentSaveRequired && (
          <Button onClick={() => window.open(template.autofillReadyDocument?.url, "_none")} variant="outlined" endIcon={<Check />}>
            Template Saved
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default ConfigureTemplateHeader;

import { env } from "@/env";
import { DataDogLogger } from "./DatadogLogger";

const _DataDogLogger: any = DataDogLogger.createLogger("DATADOG_TOKEN", env.ENV, "BridgeV2");

export const ERROR = {
  BG_COLOR: "#8b0000",
  COLOR: "#FFFFFF",
};

export const LOG_LEVEL = {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR",
  FATAL: "FATAL",
};

export function logToConsole(
  fileName: string,
  groupName: string,
  events: any[] = [],
  backgroundColor: string = "#000",
  textColor: string = "#32CD32"
) {
  console.group(`%c(${fileName}): ${groupName.toUpperCase()}`, `background: ${backgroundColor}; color: ${textColor}`);
  if (Array.isArray(events)) {
    for (const opt of events) {
      console.log(opt);
    }
  }
  console.groupEnd();
}

export function logToConsoleDebug(
  fileName: string,
  groupName: string,
  events: any[] = [],
  backgroundColor: string = "#000",
  textColor: string = "#32CD32"
) {
  if (env.DEBUG_MODE) {
    logToConsole(fileName, groupName, events, backgroundColor, textColor);
    return;
  }
}

export async function logToDataDog(
  level: string,
  fileName: string,
  groupName: string,
  events: any[] = [],
  message: string | undefined = undefined
) {
  const isError = level === LOG_LEVEL.ERROR || level === LOG_LEVEL.FATAL;
  const backgroundColor = isError ? "#8b0000" : undefined;
  const textColor = isError ? "#fff" : undefined;
  logToConsoleDebug(fileName, groupName, events, backgroundColor, textColor);

  let toLog: any = {};
  if (Array.isArray(events)) {
    let i = 0;
    for (const opt of events) {
      toLog[`event${i}`] = opt;
      i++;
    }
  }

  const log = {
    ddsource: "BridgeV2",
    ddtags: `env:${env.ENV},app:BridgeV2`,
    message: message ?? groupName,
    level: level,
    data: {
      ...toLog,
      authId: null,
    },
    service: "BridgeV2",
    locationInApp: fileName,
    authId: null,
  };

  if (typeof _DataDogLogger === "function") {
    _DataDogLogger.log(message ?? groupName, log);
  }
}

import { FC } from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { convertUsCentsToDecimalWithCommas } from "@/utils";

interface IProps {
  value: number | undefined | null;
  handleChange: (centsValue: number) => void;
} // Any future custom props here

type IPropsTextField = TextFieldProps & IProps;

const CurrencyCentsTextField: FC<IPropsTextField> = ({ handleChange, ...props }) => {
  // So input can stay empty initially
  const onFocus = () => {
    if (props.value === undefined || props.value === null) handleChange(0);
  };

  const onChange = (e) => {
    if (isNaN(parseInt(e.target.value.substring(e.target.value.length - 1)))) return;
    const commaPurged = e.target.value.replaceAll(",", "");

    if (commaPurged === "0.0" && props.value === 0) {
      // if backspace is hit on a 0 value
      handleChange(null);
    } else {
      // If you hit backspace, divide by 10
      // else get cent value of parsed text value
      handleChange(
        commaPurged.length + 1 < props.value?.toFixed(2).length ? Math.floor(props.value / 10) : Math.floor(parseFloat(commaPurged) * 1000)
      );
    }
  };

  return (
    <TextField
      {...props}
      inputProps={{
        inputMode: "numeric",
      }}
      InputProps={{
        startAdornment: <Typography>$</Typography>,
      }}
      value={props.value === undefined || props.value === null ? "" : convertUsCentsToDecimalWithCommas(props.value)}
      onFocus={onFocus}
      onChange={onChange}
    />
  );
};

export default CurrencyCentsTextField;

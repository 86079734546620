import { useEffect, useMemo } from "react";

// material-ui
import { Box, Grid } from "@mui/material";

import { CreditDashHoverDataCard } from "@/components/partial";
import { GRID_SPACING } from "@/config";
import { useToast } from "@/hooks";
import { UsePersonFinancesCreditDashboard } from "@/hooks/data-hooks/person-finances";
import { DataPoint } from "@/components/partial/LineChartDataCard/LineChartDataCard";
import CreditLiabilityTable from "./CreditLiabilityTable";
import CurrentCreditScoreAndDateHeader from "./CurrentCreditScoreAndDateHeader";
import { BarChartDataCard } from "@/components/partial/ColumnChartDataCard";
import { FiberManualRecordRounded, Flag, Percent } from "@mui/icons-material";
import CreditDashLineChartDataCard from "@/components/partial/LineChartDataCard/CreditDashLineChartDataCard";
import { displayUsDollars } from "@/utils";

interface Props {
  personId: string;
}

interface minMaxData {
  data: DataPoint[];
  min: number;
  max: number;
}

function CreditDataDashboard({ personId }: Props) {
  const { addToast } = useToast();
  const { creditDashboard, loadPersonFinancesCreditDashboard } = UsePersonFinancesCreditDashboard();

  useEffect(() => {
    (async () => {
      try {
        await loadPersonFinancesCreditDashboard(personId);
      } catch (e) {
        addToast({
          message: "Unable to fetch current credit dashboard details.",
          type: "error",
        });
      }
    })();
  }, [personId]);

  const scoreData: DataPoint[] = useMemo(
    () =>
      creditDashboard.creditReportAnalyzedDataOverTime.map((c) => ({
        x: new Date(c.month),
        y: c.score,
      })),
    [creditDashboard]
  );

  const utilizationData: minMaxData = useMemo(() => {
    const min = Math.min(...creditDashboard.creditReportAnalyzedDataOverTime.map((c) => c.utilizationRatePercent || 0));
    const max = Math.max(...creditDashboard.creditReportAnalyzedDataOverTime.map((c) => c.utilizationRatePercent || 0));

    const data = creditDashboard.creditReportAnalyzedDataOverTime.map((c) => {
      return {
        x: new Date(c.month),
        y: c.utilizationRatePercent,
      };
    });

    return {
      data,
      min,
      max,
    };
  }, [creditDashboard]);

  const delinquentTradelinesDataOptions = useMemo(() => {
    let max = 1;
    const min = 0;

    const deliqencyCounts = {
      thirtyDay: [],
      sixtyDay: [],
      ninetyDay: [],
      oneHundredTwentyDay: [],
    };

    creditDashboard.creditReportAnalyzedDataOverTime.map((cr) => {
      for (const key in cr.delinquencyCounts) {
        if (cr.delinquencyCounts[key as keyof typeof cr.delinquencyCounts] > max) {
          max = cr.delinquencyCounts[key as keyof typeof cr.delinquencyCounts];
        }
      }

      deliqencyCounts.thirtyDay.push(cr.delinquencyCounts?.thirtyDayCount || 0);
      deliqencyCounts.sixtyDay.push(cr.delinquencyCounts?.sixtyDayCount || 0);
      deliqencyCounts.ninetyDay.push(cr.delinquencyCounts?.ninetyDayCount || 0);
      deliqencyCounts.oneHundredTwentyDay.push(cr.delinquencyCounts?.oneHundredTwentyDayCount || 0);
    });

    const categories = creditDashboard.creditReportAnalyzedDataOverTime.map((cr) => {
      return cr.month;
    });

    return {
      series: [
        {
          name: "30 Days",
          data: deliqencyCounts.thirtyDay,
        },
        {
          name: "60 Days",
          data: deliqencyCounts.sixtyDay,
        },
        {
          name: "90 Days",
          data: deliqencyCounts.ninetyDay,
        },
        {
          name: "120 Days",
          data: deliqencyCounts.oneHundredTwentyDay,
        },
      ],
      categories,
      max,
      min,
    };
  }, [creditDashboard]);

  const minimumPaymentDataOptions = useMemo(() => {
    const categories = creditDashboard.creditReportAnalyzedDataOverTime.map((cr) => {
      return cr.month;
    });

    const liabilityNames = creditDashboard.creditReportAnalyzedDataOverTime.flatMap((cr) => {
      return cr.liabilitiesData.map((liability) => liability.name);
    });

    const seriesObject = {};

    liabilityNames.map((name) => {
      seriesObject[name] = [];
    });

    let maxCents = 0;
    let minCents = 0;

    creditDashboard.creditReportAnalyzedDataOverTime.map((monthlyCreditReport) => {
      const total = monthlyCreditReport.liabilitiesData.reduce((acc, ld) => acc + ld.minimumPaymentCents, 0);

      maxCents = Math.max(maxCents, total);

      minCents = Math.min(minCents, total);

      for (const key in seriesObject) {
        seriesObject[key].push(monthlyCreditReport.liabilitiesData.find((ld) => ld.name === key)?.minimumPaymentCents / 100 || 0);
      }
    });

    const series = Object.keys(seriesObject).map((key) => {
      return {
        name: key,
        data: seriesObject[key],
      };
    });

    return {
      series,
      categories,
      max: maxCents / 100 + 100,
      min: Math.max(minCents / 100 - 100, 0),
    };
  }, [creditDashboard]);

  const totalDebtBalanceOutstandingDataOptions = useMemo(() => {
    const categories = creditDashboard.creditReportAnalyzedDataOverTime.map((cr) => {
      return cr.month;
    });

    const liabilityNames = creditDashboard.creditReportAnalyzedDataOverTime.flatMap((cr) => {
      return cr.liabilitiesData.map((liability) => liability.name);
    });

    const seriesObject = {};

    liabilityNames.map((name) => {
      seriesObject[name] = [];
    });

    let maxCents = 0;
    let minCents = 0;

    creditDashboard.creditReportAnalyzedDataOverTime.map((monthlyCreditReport) => {
      const total = monthlyCreditReport.liabilitiesData.reduce((acc, ld) => acc + ld.debtBalanceCents, 0);

      maxCents = Math.max(maxCents, total);

      minCents = Math.min(minCents, total);

      for (const key in seriesObject) {
        seriesObject[key].push(monthlyCreditReport.liabilitiesData.find((ld) => ld.name === key)?.debtBalanceCents / 100 || 0);
      }
    });

    const series = Object.keys(seriesObject).map((key) => {
      return {
        name: key,
        data: seriesObject[key],
      };
    });

    return {
      series,
      categories,
      max: maxCents / 100 + 5000,
      min: Math.max(minCents / 100 - 5000, 0),
    };
  }, [creditDashboard]);

  const delinquentBalanceDataOptions = useMemo(() => {
    const categories = creditDashboard.creditReportAnalyzedDataOverTime.map((cr) => {
      return cr.month;
    });

    const liabilityNames = creditDashboard.creditReportAnalyzedDataOverTime.flatMap((cr) => {
      return cr.liabilitiesData.map((liability) => {
        if (liability.delinquentDebtBalanceCents) {
          return liability.name;
        }
      });
    });

    const seriesObject = {};

    liabilityNames.map((name) => {
      if (name) {
        seriesObject[name] = [];
      }
    });

    let maxCents = 0;
    let minCents = 0;

    creditDashboard.creditReportAnalyzedDataOverTime.map((monthlyCreditReport) => {
      const total = monthlyCreditReport.liabilitiesData.reduce((acc, ld) => acc + ld.delinquentDebtBalanceCents, 0);

      maxCents = Math.max(maxCents, total);

      minCents = Math.min(minCents, total);

      for (const key in seriesObject) {
        seriesObject[key].push(monthlyCreditReport.liabilitiesData.find((ld) => ld.name === key)?.delinquentDebtBalanceCents / 100 || 0);
      }
    });

    const series = Object.keys(seriesObject).map((key) => {
      return {
        name: key,
        data: seriesObject[key],
      };
    });

    return {
      series,
      categories,
      max: maxCents / 100 + 100,
      min: Math.max(minCents / 100 - 100, 0),
    };
  }, [creditDashboard]);

  return (
    <>
      <CurrentCreditScoreAndDateHeader latestReportDate={creditDashboard.latestReport.reportDate} />
      <Box sx={{ marginBottom: "12px" }} />
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} md={4}>
          <CreditDashLineChartDataCard
            data={scoreData}
            suppliedIcon={FiberManualRecordRounded}
            dataPointName="Credit Score"
            yMin={300}
            yMax={900}
            chartTitle="Credit Score (Monthly)"
            cardTitle="Current Credit Score"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CreditDashLineChartDataCard
            data={utilizationData.data}
            suppliedIcon={Percent}
            dataPointName="Credit Utilization"
            yMin={utilizationData.min}
            yMax={utilizationData.max}
            chartTitle="Credit Utilization"
            cardTitle="Credit Utilization"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CreditDashHoverDataCard
            suppliedIcon={Flag}
            cardTitle="New Credit Inquiries"
            inquiryCount={creditDashboard.latestReport.newInquiryMonthCount}
          />
          <Box sx={{ marginBottom: "12px" }} />
          <BarChartDataCard
            categories={delinquentTradelinesDataOptions.categories}
            series={delinquentTradelinesDataOptions.series}
            dataPointName="Delinquent Tradelines"
            yMin={delinquentTradelinesDataOptions.min}
            yMax={delinquentTradelinesDataOptions.max}
            formatter={(value) => `${Math.floor(value).toString()}`}
            chartTitle="Delinquent Tradelines"
            cardTitle="Delinquent Tradelines"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BarChartDataCard
            categories={minimumPaymentDataOptions.categories}
            series={minimumPaymentDataOptions.series}
            stacked={true}
            dataPointName="Minimum Payment"
            yMin={minimumPaymentDataOptions.min}
            yMax={minimumPaymentDataOptions.max}
            formatter={(value) => `${displayUsDollars(Math.floor(value))}`}
            chartTitle="Minimum Payment"
            cardTitle="Minimum Payment"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BarChartDataCard
            categories={totalDebtBalanceOutstandingDataOptions.categories}
            series={totalDebtBalanceOutstandingDataOptions.series}
            stacked={true}
            dataPointName="Total Debt Balance Outstanding"
            yMin={totalDebtBalanceOutstandingDataOptions.min}
            yMax={totalDebtBalanceOutstandingDataOptions.max}
            formatter={(value) => `${displayUsDollars(Math.floor(value))}`}
            chartTitle="Total Debt Balance Outstanding"
            cardTitle="Total Debt Balance Outstanding"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BarChartDataCard
            categories={delinquentBalanceDataOptions.categories}
            series={delinquentBalanceDataOptions.series}
            dataPointName="Delinquent Balance"
            yMin={delinquentBalanceDataOptions.min}
            yMax={delinquentBalanceDataOptions.max}
            formatter={(value) => `$${Math.floor(value).toString()}`}
            chartTitle="Delinquent Balance"
            cardTitle="Delinquent Balance"
          />
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: "12px" }} />
      <CreditLiabilityTable liabilities={creditDashboard.latestReport.liabilities} />
      <Box sx={{ marginBottom: "12px" }} />
      <Grid container spacing={GRID_SPACING}></Grid>
    </>
  );
}

export default CreditDataDashboard;

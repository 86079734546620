import { LandisButton, LocationAutocompleteInput } from "@/components/base";
import { Place, StateCode } from "@/types";
import { formatAddress } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import validator from "validator";

export interface AddressFormFields {
  addressStreet: string;
  addressUnit: string | null;
  addressCity: string;
  addressState: StateCode;
  addressZip: string;
}

interface Props {
  initialValues?: AddressFormFields;
  handleSubmit: (values: AddressFormFields) => Promise<void>;
}

function AddressForm({ handleSubmit, initialValues }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      addressStreet: initialValues?.addressStreet || "",
      addressUnit: initialValues?.addressUnit || "",
      addressCity: initialValues?.addressCity || "",
      addressState: initialValues?.addressState || ("" as StateCode),
      addressZip: initialValues?.addressZip || "",
      googlePlaceId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await handleSubmit(values);
      } finally {
        setLoading(false);
      }
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.addressStreet) {
        errors.addressStreet = "Please specify an address.";
      }
      if (!values.addressCity) {
        errors.addressCity = "Please specify a city.";
      }
      if (!values.addressState) {
        errors.addressState = "Please specify a state.";
      }
      if (!values.addressZip) {
        errors.addressZip = "Please specify a zip.";
      }
      if (!validator.isPostalCode(values.addressZip, "US")) {
        errors.addressZip = "Please specify a valid zip.";
      }
      return errors;
    },
  });

  const handlePlaceSelected = (place: Place) => {
    formik.setFieldValue("addressStreet", place.streetNumber + " " + place.street);
    formik.setFieldValue("addressUnit", place.unit || null);
    formik.setFieldValue("addressCity", place.city);
    formik.setFieldValue("addressState", place.state);
    formik.setFieldValue("addressZip", place.zip);
    formik.setFieldValue("googlePlaceId", place.googlePlaceId);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid direction="column" container gap={1.5}>
        <LocationAutocompleteInput
          defaultValue={formatAddress(
            initialValues?.addressStreet,
            initialValues?.addressUnit,
            initialValues?.addressCity,
            initialValues?.addressZip,
            initialValues?.addressState
          )}
          handleClear={formik.resetForm}
          handlePlaceSelected={handlePlaceSelected}
        />
        <Grid item>
          <LandisButton disabled={!formik.isValid} loading={loading} type="submit" variant="contained" endIcon={<Check />}>
            Submit
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddressForm;

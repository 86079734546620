export enum CoachingSnapshotField {
  MONTHLY_INCOME_CENTS = "monthlyIncomeCents",
  CREDIT_SCORE = "creditScore",
  FRONT_END_DTI = "frontEndDti",
  BACK_END_DTI = "backEndDti",
  SAVINGS_TOTAL_CENTS = "savingsTotalCents",
  MONTHS_IN_PROGRAM = "monthsInProgram",
  DEBT_CENTS = "debtCents",
  BALANCE_CENTS = "balanceCents",
  IN_HARDSHIP = "inHardship",
}

import { AutofillTemplateDisplayValue, UsePersonTemplateAutofillValuesHook } from "@/hooks";
import { Grid } from "@mui/material";
import { DocumentAutofillConfigurationDisplay } from "../../DocumentAutofillConfigurationDisplay";

interface Props {
  usePersonTemplateAutofillValuesInstance: UsePersonTemplateAutofillValuesHook;
}

function AutofillConfigurationSection({ usePersonTemplateAutofillValuesInstance }: Props) {
  const { autofillValues, loading } = usePersonTemplateAutofillValuesInstance;

  return (
    <Grid item>
      <DocumentAutofillConfigurationDisplay displayValues={autofillValues as Array<AutofillTemplateDisplayValue>} loading={loading} />
    </Grid>
  );
}

export default AutofillConfigurationSection;

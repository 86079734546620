import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { Body as CreatePropertyManagementCompanyInput } from "@l4s/client-property-models/lib/routes/property-management/companies/POST";
import {
  Body as UpdatePropertyManagementCompanyBody,
  Params as UpdatePropertyManagementCompanyParams,
} from "@l4s/client-property-models/lib/routes/property-management/companies/[companyId]/PUT";

import { PropertyManagementCompany } from "@/types/PropertyManagement";

export interface UsePropertyManagementCompanyInstance {
  loading: boolean;
  propertyManagementCompanies: Array<PropertyManagementCompany>;
  reloadPropertyManagementCompanies: () => Promise<void>;
  error: string | undefined;
  createPropertyManagementCompany: (input: CreatePropertyManagementCompanyInput) => Promise<void>;
  updatePropertyManagementCompany: (input: UpdatePropertyManagementCompanyBody & UpdatePropertyManagementCompanyParams) => Promise<void>;
}

export const usePropertyManagementCompany = (): UsePropertyManagementCompanyInstance => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyManagementCompanies, setPropertyManagementCompanies] = useState<Array<PropertyManagementCompany>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getPropertyManagementCompanies = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<PropertyManagementCompany>>(routes.GET_PROPERTY_MANAGEMENT_COMPANIES);
      setPropertyManagementCompanies(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property management companies.");
    }
    setLoading(false);
  };

  const createPropertyManagementCompany = async (input: CreatePropertyManagementCompanyInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<PropertyManagementCompany>(routes.CREATE_PROPERTY_MANAGEMENT_COMPANY, { ...input });
      setPropertyManagementCompanies((existingPropertyManagementCompany) => [...existingPropertyManagementCompany, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating property management company.");
    }
  };

  const updatePropertyManagementCompany = async (
    input: UpdatePropertyManagementCompanyBody & UpdatePropertyManagementCompanyParams
  ): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<PropertyManagementCompany>(routes.UPDATE_PROPERTY_MANAGEMENT_COMPANY(input.id), input);
      setPropertyManagementCompanies((existingPropertyManagementCompany) =>
        existingPropertyManagementCompany.map((company) => (company.id === response.data.id ? { ...company, ...response.data } : company))
      );
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating property management company.");
    }
  };

  useEffect(() => {
    getPropertyManagementCompanies();
  }, []);

  return {
    loading,
    propertyManagementCompanies,
    reloadPropertyManagementCompanies: getPropertyManagementCompanies,
    error,
    createPropertyManagementCompany,
    updatePropertyManagementCompany,
  };
};

import { ReactElement } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Box, Chip } from "@mui/material";

export interface DropdownOptionItem<T, D> {
  value: T;
  data: D;
}

interface DropdownOptionProps<T, D> {
  /** the options in the drop down that will be selectable by the user, { value: T; data: D; } */
  items: Array<DropdownOptionItem<T, D>>;
  /** the type specific value choosen by the user or set by default */
  values: Array<T>;
  /** the handler function that executes when the user selects an option from the drop */
  handleChange: (values: T[]) => void;
  /** the name of the dropdown shown to the user */
  label: string;
  /** the unique id for html of the drop down */
  id: string;
  /** an [OPTIONAL] message to the user. when error=true then this will show as error */
  helperText?: string;
  /** an [OPTIONAL] value that indicates if the drop down has an error */
  error?: boolean;
  /** an [OPTIONAL] parameter indicating that this dropdown is required to be answered */
  required?: boolean;
  /** an [OPTIONAL] parameter indicating if multiple selections are allowed */
  multiple?: boolean;
  /** a function that returns component to render as an option in the drop down list */
  optionComponent: (data: D) => ReactElement;
  /** a function that returns component to render as an option in the drop down list */
  labelSelected: (id: T) => string;
  /** a function that returns component to render as an option in the drop down list */
  disabled?: boolean;
}

const DropdownOption = <T extends string | number, D>({
  items,
  values,
  handleChange,
  label,
  helperText,
  error,
  required = false,
  id,
  multiple,
  optionComponent,
  labelSelected,
  disabled = false,
}: DropdownOptionProps<T, D>) => {
  const onChange = (e: SelectChangeEvent<T[]>) => {
    const {
      target: { value },
    } = e;
    //@ts-ignore //TODO: figure out why TS doesn't like this
    //this is serialized at initiation
    handleChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select<T[]>
        label={label}
        labelId={`${id}-label`}
        value={values}
        onChange={onChange}
        required={required}
        id={id}
        disabled={disabled}
        multiple={multiple}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={labelSelected(value)} />
            ))}
          </Box>
        )}
      >
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {optionComponent(item.data)}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
/**
 * A base component to create a basic dropdown for a form
 *
 * @component Dropdown
 * @interface DropdownProps
 * @property {Array<DropdownItem<T>>} items - the options in the drop down that will be selectable by the user where { value: T; data: D; }
 * @property {T} value - the handler function that executes when the user selects an option from the drop
 * @property {(value: T) => void} handleChange -
 * @property {string} label - the name of the dropdown shown to the user
 * @property {string} id - the unique id for html of the drop down
 * @property {string} helperText - an [OPTIONAL] message to the user. when error=true then this will show as error
 * @property {boolean} error - an [OPTIONAL] value that indicates if the drop down has an error
 * @property {boolean} required - an [OPTIONAL] parameter indicating that this dropdown is required to be answered
 * @property {boolean} multiple - an [OPTIONAL] parameter indicating if multiple selections are allowed
 * @property {(data: D) => ReactElement} optionComponent - a function that returns component to render as an option in the drop down list
 */
export default DropdownOption;

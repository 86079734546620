import useToast from "@/hooks/useToast";
import { bridgeV2ApiInstance } from "@/services";
import { GET_DOCUSIGN_EVENTS_BY_EXTERNAL_ENVELOPE_ID } from "@/services/routes";
import { DocusignEvent } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { sortByDate } from "@/utils/sort";
import { useEffect, useMemo, useState } from "react";

export interface UseDocusignEventsHook {
  docusignEvents: Array<DocusignEvent>;
  loading: boolean;
}

export const useDocusignEvents = (envelopeId?: string, externalEnvelopeId?: string): UseDocusignEventsHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [docusignEvents, setDocusignEvents] = useState<Array<DocusignEvent>>([]);

  const { addToast } = useToast();

  const getDocusignEvents = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<DocusignEvent>>(
        GET_DOCUSIGN_EVENTS_BY_EXTERNAL_ENVELOPE_ID(envelopeId, externalEnvelopeId)
      );
      setDocusignEvents(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({
        message: error.message,
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (envelopeId && externalEnvelopeId) getDocusignEvents();
  }, [envelopeId, externalEnvelopeId]);

  const sortedDocusignEvents = useMemo(() => docusignEvents.sort(sortByDate("generatedDateTime")), [docusignEvents]);

  return {
    docusignEvents: sortedDocusignEvents,
    loading,
  };
};

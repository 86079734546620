import { HandymanOutlined } from "@mui/icons-material";

export enum MaintenanceTabOptions {
  WORK_ORDERS = "work-orders",
}

export const tabsOptions = [
  {
    id: MaintenanceTabOptions.WORK_ORDERS,
    label: "Work Orders",
    icon: <HandymanOutlined />,
  },
];

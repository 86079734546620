import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseRentalRegistration, useToast } from "@/hooks";
import { RentalRegistration } from "@/types/PropertyManagement";
import { CONFIRM_DROPDOWN_ITEMS, ConfirmOption, DateFormat, FormHelpers, boolToConfirm, confirmToBool } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Field } from "./useRentalInsuranceForm";
import * as Yup from "yup";

interface Props {
  rentalRegistration?: RentalRegistration;
  useRentalRegistrationInstance: UseRentalRegistration;
  propertyId: string;
  close: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().ensure().required("Name is a required field"),
  website: Yup.string().url().ensure().optional(),
  required: Yup.string().ensure().required("Required is a required field"),
  approvalDate: Yup.date().nullable(),
  expirationDate: Yup.date().nullable(),
});

function RentalRegistrationForm({ rentalRegistration, useRentalRegistrationInstance, propertyId, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const formTitle = () => (rentalRegistration?.id ? "Update rental registration" : "Add rental registration");
  const formButton = () => (rentalRegistration?.id ? "Update" : "Add");

  const { addToast } = useToast();
  const { createRentalRegistration, updateRentalRegistration } = useRentalRegistrationInstance;

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: rentalRegistration?.name ?? "",
      website: rentalRegistration?.website ?? "",
      required: boolToConfirm(rentalRegistration?.required) ?? ("" as ConfirmOption),
      approvalDate: rentalRegistration?.approvalDate ?? null,
      expirationDate: rentalRegistration?.expirationDate ?? null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (rentalRegistration) {
          await updateRentalRegistration({
            ...values,
            approvalDate: rentalRegistration?.approvalDate ? new Date(rentalRegistration.approvalDate) : undefined,
            expirationDate: rentalRegistration?.expirationDate ? new Date(rentalRegistration.expirationDate) : undefined,
            required: confirmToBool(values.required),
            id: rentalRegistration.id,
            propertyId,
            portfolioPropertyId: rentalRegistration?.portfolioPropertyId || null,
            applicationPropertyId: rentalRegistration?.applicationPropertyId || null,
          });
        } else {
          await createRentalRegistration({
            ...values,
            required: confirmToBool(values.required),
            propertyId,
            approvalDate: values.approvalDate ? new Date(values.approvalDate) : undefined,
            expirationDate: values.approvalDate ? new Date(values.expirationDate) : undefined,
            portfolioPropertyId: rentalRegistration?.portfolioPropertyId || null,
            applicationPropertyId: rentalRegistration?.applicationPropertyId || null,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              id="name"
              label="Name"
              value={formik.values[Field.NAME]}
              onChange={(e) => formik.setFieldValue(Field.NAME, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
          </Grid>
          <Grid item>
            <TextField
              id="website"
              label="Website"
              value={formik.values[Field.WEBSITE]}
              onChange={(e) => formik.setFieldValue(Field.WEBSITE, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.website}
              helperText={formik.errors.website}
            />
          </Grid>
          <Grid item>
            <Dropdown
              id={Field.REQUIRED}
              disabled={formik.isSubmitting}
              items={CONFIRM_DROPDOWN_ITEMS}
              value={formik.values[Field.REQUIRED]}
              handleChange={(value) => FormHelpers.handleFakeEvent(formik, Field.REQUIRED, value)}
              label="Required"
              helperText={FormHelpers.formikErrorMessage(formik, Field.REQUIRED)}
              error={FormHelpers.formikCheckError(formik, Field.REQUIRED)}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Approval Date"
              value={formik.values.approvalDate}
              onChange={(val) => formik.setFieldValue(Field.APPROVAL_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="approvalDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.approvalDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.APPROVAL_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Expiration Date"
              value={formik.values.expirationDate}
              onChange={(val) => formik.setFieldValue(Field.EXPIRATION_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="expirationDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.expirationDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.EXPIRATION_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              {formButton()}
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default RentalRegistrationForm;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { sortByDate } from "@/utils/sort";
import { useState } from "react";

export enum HardshipReason {
  INCOME_LOSS = "INCOME_LOSS",
  INCOME_CONSISTENCY = "INCOME_CONSISTENCY",
  BUDGETING = "BUDGETING",
  MOVING_COSTS = "MOVING_COSTS",
  PROPERTY_ISSUES = "PROPERTY_ISSUES",
  FAMILY = "FAMILY",
  MEDICAL = "MEDICAL",
  UNEXPECTED_COST = "UNEXPECTED_COST",
  ACT_OF_GOD = "ACT_OF_GOD",
  THEFT = "THEFT",
  FRAUD = "FRAUD",
  UNRESPONSIVE = "UNRESPONSIVE",
  VACANCY = "VACANCY",
  GRADUATION = "GRADUATION",
  PROCESSING_ISSUES = "PROCESSING_ISSUES",
}

export interface CreateHardshipInput {
  hardshipDate: string;
  primaryReason: HardshipReason;
  dueToIncomeReduction: boolean;
  dueToIncreasedCosts: boolean;
  isTemporary: boolean;
  hardshipEndDate?: string;
  description?: string;
}

export interface Hardship {
  id: string;
  created: string;
  createdBy: string;
  hardshipDate: string;
  hardshipEndDate?: string;
  description?: string;
  primaryReason: HardshipReason;
  dueToIncomeReduction: boolean;
  dueToIncreasedCosts: boolean;
  isTemporary: boolean;
  assignedPaymentPlanId?: string;
  assignedPaymentPlanName?: string;
}

export interface UseHardships {
  hardships: Array<Hardship>;
  loadHardships: () => Promise<Array<Hardship>>;
  createHardship: (input: CreateHardshipInput) => Promise<Hardship>;
  editHardship: (hardship: Hardship, input: CreateHardshipInput) => Promise<Hardship>;
}

const sortHardshipDesc = (hs: Array<Hardship>) => {
  return hs.sort(sortByDate("hardshipDate", false));
};

export const useHardships = (programClientId: string): UseHardships => {
  const [hardships, setHardships] = useState<Array<Hardship>>([]);

  const loadHardships: UseHardships["loadHardships"] = async () => {
    try {
      const response: { data: Array<Hardship> } = await bridgeV2ApiInstance.get(routes.HARDSHIPS, { params: { programClientId } });
      setHardships(sortHardshipDesc(response.data));
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching payment plans.");
    }
  };

  const createHardship: UseHardships["createHardship"] = async (input) => {
    const payload = { ...input };

    //TODO: 1. if hardship.hardshipEndDate is null BE shouldn't error
    if (!payload.hardshipEndDate) {
      delete payload.hardshipEndDate;
    }

    if (!payload.description) {
      delete payload.description;
    }

    try {
      const response = await bridgeV2ApiInstance.post<{ hardship: CreateHardshipInput }, { data: Hardship }>(routes.HARDSHIPS, {
        hardship: payload,
        programClientId,
      });
      setHardships((previousHardships) => sortHardshipDesc([...previousHardships, response.data]));
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating hardship.");
    }
  };

  const editHardship: UseHardships["editHardship"] = async (hardship, input) => {
    try {
      const payload = { ...input, id: hardship.id, created: hardship.created };

      //TODO: 1. we should remove the need to pass hardship.created
      //TODO: 2. if hardship.hardshipEndDate is null BE shouldn't error
      if (!payload.hardshipEndDate) {
        delete payload.hardshipEndDate;
      }

      if (!payload.description) {
        delete payload.description;
      }

      const response = await bridgeV2ApiInstance.put<{ input: CreateHardshipInput }, { data: Hardship }>(routes.HARDSHIPS, {
        hardship: payload,
        programClientId,
      });
      setHardships((previousHardships) => sortHardshipDesc([...previousHardships, response.data]));
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating hardship.");
    }
  };

  return {
    hardships,
    loadHardships,
    createHardship,
    editHardship,
  };
};

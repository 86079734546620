import { Autocomplete, TextField } from "@mui/material";
import { DropdownItem } from "./types";

interface Props<T> {
  /** the options in the drop down that will be selectable by the user */
  items: Array<DropdownItem<T>>;
  /** the type specific value choosen by the user or set by default */
  value: T;
  /** the handler function that executes when the user selects an option from the drop */
  handleChange: (value: T) => void;
  /** the name of the dropdown shown to the user */
  label: string;
  /** the unique id for html of the drop down */
  id: string;
  /** size of the display input */
  size?: "small" | "medium";
  /** whether or not the dropdown is in an error state */
  error?: boolean;
  /** the helper text to display when the dropdown is in an error state */
  helperText?: string;
}

/*
  In the future, this component should support multiple selections.
  For now, it only supports single selection.

  Error handling is not supported yet inline as it is in the Dropdown component.
*/
const SearchableDropdown = <T extends string | number>({
  id,
  items,
  value,
  handleChange,
  label,
  size = "medium",
  error,
  helperText,
}: Props<T>) => {
  return (
    <Autocomplete
      fullWidth
      id={id}
      options={items}
      multiple={false}
      onChange={(_, newValue: DropdownItem<T> | null) => {
        handleChange(newValue?.value || null);
      }}
      value={items.find((item) => item.value === value) || null}
      renderInput={(params) => <TextField {...params} autoComplete="off" size={size} label={label} error={error} helperText={helperText} />}
    />
  );
};

export default SearchableDropdown;

import { CurrencyCentsTextField, Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { TypeOfHOAPaymentFrequency } from "@/types";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

function YearlyPaymentsForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updateYearlyPayments } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      insuranceCents: applicationsPropertyFinance.insuranceCents,
      hoaCents: applicationsPropertyFinance.hoaCents,
      hoaPaymentFrequency: applicationsPropertyFinance.hoaPaymentFrequency,
      taxesCents: applicationsPropertyFinance.taxesCents,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateYearlyPayments({
          insuranceCents: values.insuranceCents,
          hoaCents: values.hoaCents,
          hoaPaymentFrequency: values.hoaPaymentFrequency,
          taxesCents: values.taxesCents,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};

      return errors;
    },
  });

  return (
    <MainCard title="Edit Client Payments">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Insurance"
              value={formik.values.insuranceCents}
              handleChange={(val) => formik.setFieldValue("insuranceCents", val)}
              error={!!formik.errors.insuranceCents}
              helperText={formik.errors.insuranceCents}
            />
          </Grid>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="HOA"
              value={formik.values.hoaCents}
              handleChange={(val) => formik.setFieldValue("hoaCents", val)}
              error={!!formik.errors.hoaCents}
              helperText={formik.errors.hoaCents}
            />
          </Grid>
          <Grid item>
            <Dropdown
              id="hoaPaymentFrequencyDropdown"
              fullWidth
              label="HOA Payment Frequency"
              value={formik.values.hoaPaymentFrequency}
              handleChange={(val) => formik.setFieldValue("hoaPaymentFrequency", val)}
              error={!!formik.errors.hoaPaymentFrequency}
              helperText={formik.errors.hoaPaymentFrequency}
              items={Object.values(TypeOfHOAPaymentFrequency).map((value) => ({
                label: value,
                value,
              }))}
            />
          </Grid>
          <Grid item>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Taxes"
              value={formik.values.taxesCents}
              handleChange={(val) => formik.setFieldValue("taxesCents", val)}
              error={!!formik.errors.taxesCents}
              helperText={formik.errors.taxesCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default YearlyPaymentsForm;

import React, { useImperativeHandle } from "react";
import type { PropsWithChildren } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Drawer, Fab, Grid, IconButton, Tooltip } from "@mui/material";
import { IconSettings } from "@tabler/icons";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
//import BorderRadius from "./BorderRadius";
import Layout from "./Layout";
import PresetColor from "./PresetColor";
import FontFamily from "./FontFamily";
//import InputFilled from "./InputFilled";
//import BoxContainer from "./BoxContainer";
import { AnimateButton } from "@/components/berry";
import { gridSpacing } from "@/store/constant";
import { openCustomizationDrawer } from "@/store/slices/customization";
import { dispatch, useSelector } from "@/store";

interface Props {
  showLiveCustomizationWidget?: boolean;
}

const Customization = React.forwardRef(({ showLiveCustomizationWidget = false }: PropsWithChildren<Props>, ref) => {
  const theme = useTheme();
  const { drawerOpen } = useSelector((state) => state.customization);

  const handleToggle = () => {
    dispatch(openCustomizationDrawer(!drawerOpen));
  };

  useImperativeHandle(ref, () => ({
    handleToggle,
  }));

  return (
    <>
      {/* toggle button */}
      {showLiveCustomizationWidget && (
        <Tooltip title="Live Customize">
          <Fab
            component="div"
            onClick={handleToggle}
            size="medium"
            variant="circular"
            color="secondary"
            sx={{
              borderRadius: 0,
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "4px",
              top: "25%",
              position: "fixed",
              right: 10,
              zIndex: theme.zIndex.speedDial,
              boxShadow: theme.customShadows.secondary,
            }}
          >
            <AnimateButton type="rotate">
              <IconButton color="inherit" size="large" disableRipple>
                <IconSettings />
              </IconButton>
            </AnimateButton>
          </Fab>
        </Tooltip>
      )}
      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        {drawerOpen && (
          <PerfectScrollbar component="div">
            <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
              <Grid item xs={12}>
                {/* layout type */}
                <Layout />
              </Grid>
              <Grid item xs={12}>
                {/* Theme Preset Color */}
                <PresetColor />
              </Grid>
              <Grid item xs={12}>
                {/* font family */}
                <FontFamily />
              </Grid>
              <Grid item xs={12}>
                {/* border radius <BorderRadius /> */}
              </Grid>
              <Grid item xs={12}>
                {/* filled with outline textfield <InputFilled /> */}
              </Grid>
              <Grid item xs={12}>
                {/* box container <BoxContainer /> */}
              </Grid>
            </Grid>
          </PerfectScrollbar>
        )}
      </Drawer>
    </>
  );
});

export default Customization;

import { Route, Routes } from "react-router-dom";

import { Layout } from "@/components/layout";
import { ClientManageNote, HomePage, NotFoundPage } from "@/components/view";
import { OktaAuthCheck, UserProvider } from "@/components/providers";

import ClientRouterWithPerson from "./ClientRouter";
import AccountRouter from "./AccountRouter";
import AdminRouter from "./AdminRouter";

const AuthenticatedRouter = () => {
  return (
    <OktaAuthCheck pathsToAllow={[]}>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/client/:personId/*" element={<ClientRouterWithPerson />} />
            <Route path="/coaching/notes" element={<ClientManageNote />} />
            <Route path="/account/*" element={<AccountRouter />} />
            <Route path="/admin/*" element={<AdminRouter />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </UserProvider>
    </OktaAuthCheck>
  );
};

export default AuthenticatedRouter;

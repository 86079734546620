import { FileInput, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseTemplatesHook, useToast } from "@/hooks";
import { pdf } from "@/services";
import { mapUnknownToError } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { IconCircleCheck } from "@tabler/icons";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface Props {
  addTemplate: UseTemplatesHook["addTemplate"];
}

function AddTemplateForm({ addTemplate }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
      description: "",
      file: null,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("file", values.file);
        await addTemplate(formData);
      } catch (err) {
        const error = mapUnknownToError(err);
        addToast({ message: error.message, type: "error" });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.name) {
        errors.name = "Please specify a template name.";
      }
      if (!values.description) {
        errors.description = "Please specify a template description.";
      }
      if (!values.file) {
        errors.file = "Please upload a template document.";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  const onFilesSelected = async (files: Array<File>) => {
    try {
      await pdf.loadFileForValidityCheck(files[0]);
      formik.setFieldValue("file", files[0]);
    } catch (e) {
      addToast({ message: e.message, type: "error" });
    }
  };

  return (
    <MainCard sx={{ width: 500, maxWidth: "85vw" }} title="Add a Document Template">
      <form onSubmit={formik.handleSubmit}>
        <Grid spacing={2} container direction="column">
          <Grid item xs={12}>
            <TextField
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
              size="small"
              fullWidth
              label="Name"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formik.values.description}
              onChange={(e) => formik.setFieldValue("description", e.target.value)}
              multiline
              minRows={5}
              size="small"
              fullWidth
              label="Description"
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FileInput
              InputProps={formik.values.file && { startAdornment: <IconCircleCheck /> }}
              maxFiles={1}
              accept={{ "application/pdf": [".pdf"] }}
              label={formik.values.file?.name || "Template document"}
              onFilesSelected={onFilesSelected}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <LandisButton loading={loading} type="submit" endIcon={<Check fontSize="small" />} variant="contained">
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default AddTemplateForm;

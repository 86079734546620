import { LandisButton } from "@/components/base";
import { UseEnvelopesHook, useToast } from "@/hooks";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

interface Props {
  onCreateEnvelope: () => void;
  useEnvelopesInstance: UseEnvelopesHook;
}

function AddEmptyEnvelopeForm({ useEnvelopesInstance, onCreateEnvelope }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createEnvelope } = useEnvelopesInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await createEnvelope(values);
        onCreateEnvelope();
      } catch (err) {}
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (!values.name) {
        errors.name = "Please specify an envelope name.";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (formik.errors) {
      for (const [, value] of Object.entries(formik.errors)) {
        addToast({ message: value as string, type: "error" });
      }
    }
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid spacing={2} container direction="column">
        <Grid item xs={12}>
          <TextField
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            size="small"
            fullWidth
            label="Name"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12}>
          <LandisButton loading={loading} type="submit" endIcon={<Check fontSize="small" />} variant="contained">
            Submit
          </LandisButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddEmptyEnvelopeForm;

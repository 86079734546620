import { Color, ScriptableLineSegmentContext } from "chart.js";

export const skipped = <T extends Color | Array<number>>(ctx: ScriptableLineSegmentContext, value: T): T | undefined =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

export const backgroundColorOptions = [
  "#5944DB",
  "#4D4A5C",
  "#DBDA44",
  "#DBB544",
  "#B93ADB",
  "#5BDB39",
  "#DBD939",
  "#44BFDB",
  "#8A44DB",
  "#192568",
  "#4490DB",
  "#5944DB",
  "#445FDC",
  "#44BFDB",
  "#8A44DB",
];

import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { useTemplates, useToast } from "@/hooks";
import { Template } from "@/types";
import { mapUnknownToError } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useMemo, useState } from "react";

interface Props {
  onAddTemplateToEnvelope: (templateId: string) => Promise<void>;
  title: string;
  templatesToFilter?: Array<Template>;
}

function AddTemplateToEnvelopeForm({ onAddTemplateToEnvelope, title, templatesToFilter = [] }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const { addToast } = useToast();

  const { templates } = useTemplates();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await onAddTemplateToEnvelope(selectedTemplateId);
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
    setLoading(false);
  };

  const templateOptions = useMemo(() => {
    return templates
      .filter((template) => !templatesToFilter.find((templateToFilter) => templateToFilter.id === template.id))
      .map((data) => ({
        label: data.name,
        value: data.id,
      }));
  }, [templatesToFilter, templates]);

  return (
    <MainCard title={title}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid item spacing={1} container direction="column">
          <Grid item xs={12}>
            <Dropdown
              id="templateToEnvelopeDropdown"
              label="Template"
              handleChange={(value) => setSelectedTemplateId(value)}
              required={true}
              value={selectedTemplateId}
              items={templateOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <LandisButton loading={loading} type="submit" endIcon={<Check fontSize="small" />} variant="contained">
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default AddTemplateToEnvelopeForm;

import { Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UsePropertyHook, useToast } from "@/hooks";
import { TypeOfProperty, typeOfPropertyToLabelMap } from "@/types";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { isInteger, isFinite, isBoolean, isDate } from "lodash";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
  usePropertyInstance: UsePropertyHook;
  handleClose: () => void;
}

function BasicHomeStatsForm({ usePropertyInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { property, updateHomeDetails } = usePropertyInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      beds: property.beds || "",
      baths: property.baths || "",
      sqft: property.sqft || "",
      style: property.style || "",
      built: property.built || "",
      lotSizeAcre: property.lotSizeAcre || "",
      county: property.county || "",
      hoaName: property.hoaName || "",
      type: property.type || ("" as TypeOfProperty),
      hvacFurnaceAge: property.hvacFurnaceAge || "",
      hvacAcAge: property.hvacAcAge || "",
      // format as yyyy-mm-dd
      hvacLastServicedDate: property.hvacLastServicedDate ? new Date(property.hvacLastServicedDate).toISOString().split("T")[0] : "",
      approximateRoofAgeInYears: property.approximateRoofAgeInYears || "",
      evidenceOfWaterInstrusionFromSD: isBoolean(property.evidenceOfWaterInstrusionFromSD) ? property.evidenceOfWaterInstrusionFromSD : "",
      specialFloodHazardArea: property.specialFloodHazardArea || "",
      evidenceOfDamageFromWildlifeIncludingFungi: isBoolean(property.evidenceOfDamageFromWildlifeIncludingFungi)
        ? property.evidenceOfDamageFromWildlifeIncludingFungi
        : "",
      leadPaintPresent: isBoolean(property.leadPaintPresent) ? property.leadPaintPresent : "",
      leadDisclosureReceived: isBoolean(property.leadDisclosureReceived) ? property.leadDisclosureReceived : "",
      hoaRestrictionsOnRentals: property.hoaRestrictionsOnRentals || "",
      waterHeaterAge: property.waterHeaterAge || "",
      stoveRangeAge: property.stoveRangeAge || "",
      refrigeratorAge: property.refrigeratorAge || "",
      dishwasherAge: property.dishwasherAge || "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateHomeDetails({
          beds: parseInt(values.beds.toString()) || null,
          baths: parseFloat(values.baths.toString()) || null,
          sqft: parseInt(values.sqft.toString()) || null,
          style: values.style || null,
          built: parseInt(values.built.toString()) || null,
          lotSizeAcre: parseFloat(values.lotSizeAcre.toString()) || null,
          county: values.county || null,
          hoaName: values.hoaName || null,
          type: values.type || null,
          hvacFurnaceAge: parseInt(values.hvacFurnaceAge.toString()) || null,
          hvacAcAge: parseInt(values.hvacAcAge.toString()) || null,
          hvacLastServicedDate: values.hvacLastServicedDate ? new Date(values.hvacLastServicedDate) : null,
          approximateRoofAgeInYears: parseInt(values.approximateRoofAgeInYears.toString()) || null,
          evidenceOfWaterInstrusionFromSD: isBoolean(values.evidenceOfWaterInstrusionFromSD)
            ? values.evidenceOfWaterInstrusionFromSD
            : null,
          specialFloodHazardArea: values.specialFloodHazardArea || null,
          evidenceOfDamageFromWildlifeIncludingFungi: isBoolean(values.evidenceOfDamageFromWildlifeIncludingFungi)
            ? values.evidenceOfDamageFromWildlifeIncludingFungi
            : null,
          leadPaintPresent: isBoolean(values.leadPaintPresent) ? values.leadPaintPresent : null,
          leadDisclosureReceived: isBoolean(values.leadDisclosureReceived) ? values.leadDisclosureReceived : null,
          hoaRestrictionsOnRentals: values.hoaRestrictionsOnRentals || null,
          waterHeaterAge: parseInt(values.waterHeaterAge.toString()) || null,
          stoveRangeAge: parseInt(values.stoveRangeAge.toString()) || null,
          refrigeratorAge: parseInt(values.refrigeratorAge.toString()) || null,
          dishwasherAge: parseInt(values.dishwasherAge.toString()) || null,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (values.beds && !isInteger(Number(values.beds.toString()))) {
        errors.beds = "Please enter an integer.";
      }
      if (values.baths && !isFinite(Number(values.baths.toString()))) {
        errors.baths = "Please enter a number.";
      }
      if (values.sqft && !isInteger(Number(values.sqft.toString()))) {
        errors.sqft = "Please enter an integer.";
      }
      if (values.built && !isInteger(Number(values.built.toString()))) {
        errors.built = "Please enter an integer.";
      }
      if (values.lotSizeAcre && !isFinite(Number(values.lotSizeAcre.toString()))) {
        errors.lotSizeAcre = "Please enter a number.";
      }
      return errors;
    },
  });

  return (
    <MainCard title="Edit Address">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Dropdown
                items={[
                  { label: "N/A", value: "" as TypeOfProperty },
                  ...Object.values(TypeOfProperty).map((type) => ({
                    label: typeOfPropertyToLabelMap[type],
                    value: type,
                  })),
                ]}
                label="Type of Property"
                id="type"
                value={formik.values.type}
                handleChange={(val) => formik.setFieldValue("type", val)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.beds}
                onChange={(e) => formik.setFieldValue("beds", e.target.value)}
                label="Beds"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.beds}
                helperText={formik.errors.beds}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.baths}
                onChange={(e) => formik.setFieldValue("baths", e.target.value)}
                label="Baths"
                autoComplete="off"
                fullWidth
                type="number"
                error={!!formik.errors.baths}
                helperText={formik.errors.baths}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.sqft}
                onChange={(e) => formik.setFieldValue("sqft", e.target.value)}
                label="Sq Ft"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.sqft}
                helperText={formik.errors.sqft}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.style}
                onChange={(e) => formik.setFieldValue("style", e.target.value)}
                label="Style"
                autoComplete="off"
                fullWidth
                error={!!formik.errors.style}
                helperText={formik.errors.style}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.built}
                onChange={(e) => formik.setFieldValue("built", e.target.value)}
                label="Year Built"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.built}
                helperText={formik.errors.built}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.lotSizeAcre}
                onChange={(e) => formik.setFieldValue("lotSizeAcre", e.target.value)}
                label="Lot Size (Acre)"
                autoComplete="off"
                fullWidth
                type="number"
                error={!!formik.errors.lotSizeAcre}
                helperText={formik.errors.lotSizeAcre}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.county}
                onChange={(e) => formik.setFieldValue("county", e.target.value)}
                label="County"
                fullWidth
                autoComplete="off"
                error={!!formik.errors.county}
                helperText={formik.errors.county}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.hoaName}
                onChange={(e) => formik.setFieldValue("hoaName", e.target.value)}
                label="HOA Name"
                autoComplete="off"
                fullWidth
                error={!!formik.errors.hoaName}
                helperText={formik.errors.hoaName}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.hvacFurnaceAge}
                onChange={(e) => formik.setFieldValue("hvacFurnaceAge", e.target.value)}
                label="HVAC Furnace Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.hvacFurnaceAge}
                helperText={formik.errors.hvacFurnaceAge}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.hvacAcAge}
                onChange={(e) => formik.setFieldValue("hvacAcAge", e.target.value)}
                label="HVAC AC Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.hvacAcAge}
                helperText={formik.errors.hvacAcAge}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                value={formik.values.hvacLastServicedDate}
                onChange={(value) => formik.setFieldValue("hvacLastServicedDate", value)}
                label="HVAC Last Serviced Date"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    name="hvacLastServicedDate"
                    id="hvacLastServicedDate"
                    error={!!formik.errors.hvacLastServicedDate}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.approximateRoofAgeInYears}
                onChange={(e) => formik.setFieldValue("approximateRoofAgeInYears", e.target.value)}
                label="Approximate Roof Age (Yrs)"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.approximateRoofAgeInYears}
                helperText={formik.errors.approximateRoofAgeInYears}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.waterHeaterAge}
                onChange={(e) => formik.setFieldValue("waterHeaterAge", e.target.value)}
                label="Water Heater Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.waterHeaterAge}
                helperText={formik.errors.waterHeaterAge}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.specialFloodHazardArea}
                onChange={(e) => formik.setFieldValue("specialFloodHazardArea", e.target.value)}
                label="Special Flood Hazard Area"
                fullWidth
                autoComplete="off"
                type="string"
                error={!!formik.errors.specialFloodHazardArea}
                helperText={formik.errors.specialFloodHazardArea}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Dropdown
                items={[
                  { label: "N/A", value: "" },
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                label="Evidence of Damage from Wildlife including Fungi"
                value={formik.values.evidenceOfDamageFromWildlifeIncludingFungi as any}
                handleChange={(value) => formik.setFieldValue("evidenceOfDamageFromWildlifeIncludingFungi", value)}
                id="evidenceOfDamageFromWildlifeIncludingFungi"
                fullWidth
                error={!!formik.errors.evidenceOfDamageFromWildlifeIncludingFungi}
                helperText={formik.errors.evidenceOfDamageFromWildlifeIncludingFungi}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                items={[
                  { label: "N/A", value: "" },
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                label="Lead Paint Present"
                value={formik.values.leadPaintPresent as any}
                handleChange={(value) => formik.setFieldValue("leadPaintPresent", value)}
                id="leadPaintPresent"
                fullWidth
                error={!!formik.errors.leadPaintPresent}
                helperText={formik.errors.leadPaintPresent}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Dropdown
                items={[
                  { label: "N/A", value: "" },
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                label="Lead Disclosure Received"
                value={formik.values.leadDisclosureReceived as any}
                handleChange={(value) => formik.setFieldValue("leadDisclosureReceived", value)}
                id="leadDisclosureReceived"
                fullWidth
                error={!!formik.errors.leadDisclosureReceived}
                helperText={formik.errors.leadDisclosureReceived}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.hoaRestrictionsOnRentals}
                onChange={(e) => formik.setFieldValue("hoaRestrictionsOnRentals", e.target.value)}
                label="HOA Restrictions on Rentals"
                fullWidth
                autoComplete="off"
                type="string"
                error={!!formik.errors.hoaRestrictionsOnRentals}
                helperText={formik.errors.hoaRestrictionsOnRentals}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Dropdown
                items={[
                  { label: "N/A", value: "" },
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                label="Evidence of Water Intrusion from SD"
                value={formik.values.evidenceOfWaterInstrusionFromSD as any}
                handleChange={(value) => formik.setFieldValue("evidenceOfWaterInstrusionFromSD", value)}
                id="evidenceOfWaterInstrusionFromSD"
                fullWidth
                error={!!formik.errors.evidenceOfWaterInstrusionFromSD}
                helperText={formik.errors.evidenceOfWaterInstrusionFromSD}
              />
            </Grid>
          </Grid>
          {/* <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.stoveRangeAge}
                onChange={(e) => formik.setFieldValue("stoveRangeAge", e.target.value)}
                label="Stove Range Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.stoveRangeAge}
                helperText={formik.errors.stoveRangeAge}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <TextField
                value={formik.values.refrigeratorAge}
                onChange={(e) => formik.setFieldValue("refrigeratorAge", e.target.value)}
                label="Refrigerator Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.refrigeratorAge}
                helperText={formik.errors.refrigeratorAge}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={formik.values.dishwasherAge}
                onChange={(e) => formik.setFieldValue("dishwasherAge", e.target.value)}
                label="Dishwasher Age"
                fullWidth
                autoComplete="off"
                type="number"
                error={!!formik.errors.dishwasherAge}
                helperText={formik.errors.dishwasherAge}
              />
            </Grid>
          </Grid> */}
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default BasicHomeStatsForm;

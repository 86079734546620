import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { DelinquencyData } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseDelinquencyData {
  loadDelinquencyData: () => Promise<void>;
  delinquencyData: DelinquencyData;
}

export const useDelinquencyData = (personId: string): UseDelinquencyData => {
  const [delinquencyData, setDelinquencyData] = useState<DelinquencyData>();

  const loadDelinquencyData = async () => {
    try {
      const response: { data: DelinquencyData } = await bridgeV2ApiInstance.get(routes.GET_DELINQUENCY_DATA_BY_PERSON_ID(personId));
      setDelinquencyData(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching delinquency data.");
    }
  };

  return {
    delinquencyData,
    loadDelinquencyData,
  };
};

import { useProperties } from "@/hooks";
import { formatAddress } from "@/utils";
import { Autocomplete, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { PropertyAutocompleteOption } from "./types";

interface Props {
  onSelectProperty: (autocompleteOption: PropertyAutocompleteOption) => void;
  selectedProperty: PropertyAutocompleteOption;
  formikErrorText?: string | undefined;
  noOptionsText?: React.ReactNode;
  initialSearch?: string;
  autoFocus?: boolean;
}

function PropertyAutocomplete({
  onSelectProperty,
  selectedProperty,
  formikErrorText,
  noOptionsText,
  initialSearch = "",
  autoFocus = false,
}: Props) {
  // This is to prevent the input from being cleared when the user clicks on the input
  const [hasFocused, setHasFocused] = useState<boolean>(false);
  const [propertySearch, setPropertySearch] = useState<string>(initialSearch);

  const { properties, loading: propertiesLoading } = useProperties(propertySearch);
  const autoCompleteOptions: Array<PropertyAutocompleteOption> = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        label: formatAddress(
          property.addressStreet,
          property.addressUnit,
          property.addressCity,
          property.addressZip,
          property.addressState
        ),
      })),
    [properties]
  );

  return (
    <Autocomplete
      fullWidth
      options={autoCompleteOptions}
      multiple={false}
      onChange={(_, newValue) => onSelectProperty(newValue)}
      value={selectedProperty || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      inputValue={propertySearch}
      onInputChange={(_, newInputValue) => {
        if (hasFocused) setPropertySearch(newInputValue);
      }}
      onFocus={() => setHasFocused(true)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      noOptionsText={noOptionsText}
      loading={propertiesLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          autoComplete="off"
          label="Properties"
          error={!!formikErrorText}
          helperText={formikErrorText}
        />
      )}
    />
  );
}

export default PropertyAutocomplete;

import { Box, CardContent, Grid, Typography } from "@mui/material";
import { SubCard, Accordion } from "@/components/berry";
import { DEFAULT_PADDING, GRID_SPACING } from "@/config";
import { Action, TypeOfAction } from "@/types";
import moment from "moment";

interface Props {
  action: Partial<Action>;
}

function ClientNoteResponse({ action }: Props) {
  return (
    <SubCard title="Action Response" content={false}>
      <CardContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <Box sx={{ paddingTop: DEFAULT_PADDING }}>
              <Typography paddingLeft={"14px"}>
                <b>Completion Date: </b>
                {action.completionDate
                  ? `${moment(action.completionDate).format("MM/DD/YY")}`
                  : "The client hasn't completed this action yet"}
              </Typography>
            </Box>
          </Grid>
          {action.type === TypeOfAction.PULSE ? (
            <>
              <Grid item xs={12}>
                <Typography paddingLeft={"14px"}>
                  <b>Pulse Rating: </b>
                  {action.completionDate ? `${action.pulseRating}` : "The client hasn't completed this action yet"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography paddingLeft={"14px"} paddingBottom={"12px"}>
                  <b>Text Response: </b>
                  {action.completionDate ? action.response || "n/a" : "The client hasn't completed this action yet"}
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
          {action.type === TypeOfAction.SHORT_RESPONSE ? (
            <Grid item xs={12}>
              <Accordion
                data={[
                  {
                    id: "text",
                    title: "Text Response",
                    content: action.completionDate ? action.response || "n/a" : "The client hasn't responded yet",
                  },
                ]}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </CardContent>
    </SubCard>
  );
}

export default ClientNoteResponse;

import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import { useMemo } from "react";
import { GRID_SPACING } from "@/config";
import { Box } from "@mui/system";

type FormArrayItemComponent = React.ComponentType<{ index: number; formik: ReturnType<typeof useFormik<unknown>>; disabled?: boolean }>;

interface Props {
  valueKey: string;
  label: string;
  valueDefault: unknown;
  formik: ReturnType<typeof useFormik<unknown>>;
  formComponent: FormArrayItemComponent;
  disabled?: boolean;
}

const getFormikItems = (formik: ReturnType<typeof useFormik<unknown>>, valueKey: string): Array<unknown> => {
  const items = formik.values[valueKey];
  if (Array.isArray(items)) {
    return items;
  }
  return [];
};

const FormArray: React.FC<Props> = ({ valueKey, label, valueDefault, formik, formComponent, disabled = false }) => {
  const FormComponent = formComponent;
  const items = useMemo(() => {
    return getFormikItems(formik, valueKey);
  }, [formik, valueKey]);
  const addRecord = () => {
    const newKey = `${valueKey}[${items.length}]`;
    formik.setFieldValue(newKey, valueDefault);
  };
  const removeRecord = (index: number) => {
    const formItemsitems = getFormikItems(formik, valueKey);
    const formItemsLessOne = formItemsitems.filter((_, idx) => idx !== index);
    formik.setFieldValue(valueKey, formItemsLessOne);
  };
  return (
    <Card variant="outlined">
      <CardHeader
        title={label}
        action={
          <IconButton aria-label={`Add ${label}`} onClick={addRecord} disabled={disabled}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <ul style={{ margin: 0, padding: 0 }}>
          {items.map((_, index) => {
            const key = `${items.length}-${index}`;
            return (
              <Box key={key} component="li" mb={GRID_SPACING / 2} style={{ listStyleType: "none" }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {label} {index + 1}
                    </Typography>
                    <FormComponent index={index} formik={formik} disabled={disabled} />
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => removeRecord(index)} disabled={disabled}>
                      Remove {label.toLowerCase()}
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            );
          })}
        </ul>
      </CardContent>
    </Card>
  );
};

export default FormArray;

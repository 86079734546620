import { Typography } from "@mui/material";
import { OrderDto, inspectionSourceIdToDisplay } from "@/types";
interface Props {
  order: OrderDto;
}

const OrderMissingInspectionSummary: React.FC<Props> = ({ order }) => {
  return (
    <>
      <Typography variant="body1">
        The order is still being processed. Check <b>{inspectionSourceIdToDisplay(order.sourceId)}</b> for further details.
      </Typography>
    </>
  );
};

export default OrderMissingInspectionSummary;

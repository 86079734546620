import { useFeature } from "@/contexts";
import { FeatureFlag } from "@/types";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";

interface Props {
  setSearch: (value: string) => void;
  showAddForm: () => void;
}

function DefinedEnvelopesTableHeader({ setSearch, showAddForm }: Props) {
  const releaseCreateDefinedEnvelopes = useFeature(FeatureFlag.RELEASE_CREATE_DEFINED_ENVELOPES);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h2" sx={{ mb: 0.5 }}>
          Defined Envelopes
        </Typography>
        <Typography variant="body1">
          Defined envelopes are named groups of templates that are used on a frequent basis (e.g. Ohio Closing Documents).
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
        <TextField onChange={onSearchChange} size="small" variant="outlined" fullWidth placeholder="Search" />
        {releaseCreateDefinedEnvelopes && (
          <Button onClick={showAddForm} variant="contained" endIcon={<AddCircleOutline />}>
            Add
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default DefinedEnvelopesTableHeader;

import { Dropdown, LandisButton, SearchableDropdown } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseUtilities, UseUtilitiesPaymentDetails, useToast } from "@/hooks";
import { FrequencyOfPayments, MethodOfPayment, TypeOfParty, UtilitiesPaymentDetails } from "@/types";
import { DateFormat, FormHelpers } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Field } from "./useUtilityPaymentDetailsForm";
import { PaymentFrequencyOptions, PaymentMethodOptions, ResponsiblePartyOptions } from "../../useDetailForms";
import { DatePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";

interface Props {
  close: () => void;
  utilityPaymentDetail?: UtilitiesPaymentDetails;
  useUtilitiesPaymentDetailsInstance: UseUtilitiesPaymentDetails;
  useUtilitiesInstance: UseUtilities;
  propertyId: string;
}

function UtilityPaymentDetailsForm({
  utilityPaymentDetail,
  useUtilitiesPaymentDetailsInstance,
  useUtilitiesInstance,
  propertyId,
  close,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { createUtilityPaymentDetail, updateUtilityPaymentDetail } = useUtilitiesPaymentDetailsInstance;
  const { propertyUtilities } = useUtilitiesInstance;

  const formTitle = () => (utilityPaymentDetail?.id ? "Update utility payment detail" : "Add utility payment detail");

  const { addToast } = useToast();

  const validationSchema = Yup.object().shape({
    utilityId: Yup.string().ensure().required("Related utility is a required field"),
    paymentFrequency: Yup.string()
      .required("Payment frequency is a required field")
      .typeError("Select payment frequency from the dropdown"),
    responsibleParty: Yup.string()
      .required("Responsible party is a required field")
      .typeError("Select responsible party from the dropdown"),
    paymentMethod: Yup.string().required("Payment method is a required field").typeError("Select payment method from the dropdown"),
    initialPaymentDate: Yup.date()
      .required("Initial payment date is a required field")
      .typeError("Initial payment date is a required field"),
    startDate: Yup.date().required("Start date is a required field").typeError("Start date is a required field"),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      utilityId: utilityPaymentDetail?.utilityId ?? null,
      externalAccountId: utilityPaymentDetail?.externalAccountId ?? "",
      responsibleParty: utilityPaymentDetail?.responsibleParty ?? ("" as TypeOfParty),
      paymentMethod: utilityPaymentDetail?.paymentMethod ?? ("" as MethodOfPayment),
      paymentFrequency: utilityPaymentDetail?.paymentFrequency ?? ("" as FrequencyOfPayments),
      initialPaymentDate: utilityPaymentDetail?.initialPaymentDate ?? null,
      startDate: utilityPaymentDetail?.startDate ?? null,
      endDate: utilityPaymentDetail?.endDate ?? null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (utilityPaymentDetail) {
          await updateUtilityPaymentDetail({
            ...values,
            id: utilityPaymentDetail.id,
            propertyId,
          });
        } else {
          await createUtilityPaymentDetail({
            ...values,
            propertyId,
          });
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
      close();
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item xs={12}>
            <SearchableDropdown
              label="Utility"
              id="add-utility-id-to-payment-detail"
              items={propertyUtilities.map((util) => ({
                label: util.name,
                value: util.id,
              }))}
              handleChange={(newValue) => {
                formik.setFieldValue(Field.UTILITY_ID, newValue);
              }}
              value={formik.values[Field.UTILITY_ID]}
              error={!!formik.errors.utilityId}
              helperText={formik.errors.utilityId}
            />
          </Grid>
          <Grid item>
            <TextField
              id="externalAccountId"
              label="External Account ID"
              value={formik.values[Field.EXTERNAL_ACCOUNT_ID]}
              onChange={(e) => formik.setFieldValue(Field.EXTERNAL_ACCOUNT_ID, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              error={!!formik.errors.externalAccountId}
              helperText={formik.errors.externalAccountId}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={ResponsiblePartyOptions}
              label="Responsible Party"
              id="responsibleParty"
              fullWidth
              value={formik.values[Field.RESPONSIBLE_PARTY]}
              handleChange={(val) => formik.setFieldValue(Field.RESPONSIBLE_PARTY, val)}
              error={!!formik.errors.responsibleParty}
              helperText={formik.errors.responsibleParty}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={PaymentMethodOptions}
              label="Payment Method"
              id="paymentMethod"
              fullWidth
              value={formik.values[Field.PAYMENT_METHOD]}
              handleChange={(val) => formik.setFieldValue(Field.PAYMENT_METHOD, val)}
              error={!!formik.errors.paymentMethod}
              helperText={formik.errors.paymentMethod}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              items={PaymentFrequencyOptions}
              label="Payment Frequency"
              id="paymentFrequency"
              fullWidth
              value={formik.values[Field.PAYMENT_FREQUENCY]}
              handleChange={(val) => formik.setFieldValue(Field.PAYMENT_FREQUENCY, val)}
              error={!!formik.errors.paymentFrequency}
              helperText={formik.errors.paymentFrequency}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Initial Payment Date"
              value={formik.values[Field.INITIAL_PAYMENT_DATE]}
              onChange={(val) => formik.setFieldValue(Field.INITIAL_PAYMENT_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="initialPaymentDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.initialPaymentDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.INITIAL_PAYMENT_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Start Date"
              value={formik.values[Field.START_DATE]}
              onChange={(val) => formik.setFieldValue(Field.START_DATE, val)}
              renderInput={(params) => (
                <TextField
                  id="startDate"
                  fullWidth
                  {...params}
                  error={!!formik.errors.startDate}
                  helperText={FormHelpers.formikErrorMessage(formik, Field.START_DATE)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="End Date"
              value={formik.values[Field.END_DATE]}
              onChange={(val) => formik.setFieldValue(Field.END_DATE, val)}
              renderInput={(params) => <TextField id="endDate" fullWidth {...params} error={!!formik.errors.endDate} />}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default UtilityPaymentDetailsForm;

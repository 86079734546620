import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { GoalHistory } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseGoalHistory {
  loadGoalHistoryRecords: () => Promise<void>;
  goalHistoryRecords: Array<GoalHistory>;
  isLoading: boolean;
}

export const useGoalHistory = (goalId: string): UseGoalHistory => {
  const [goalHistoryRecords, setgoalHistoryRecords] = useState<Array<GoalHistory>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadGoalHistoryRecords = async () => {
    if (!goalId || Boolean(goalHistoryRecords?.length)) return;
    try {
      setIsLoading(true);
      const response: { data: Array<GoalHistory> } = await bridgeV2ApiInstance.get(routes.GET_GOAL_HISTORY_BY_GOAL_ID(goalId));
      setgoalHistoryRecords(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching goals in load.");
    }
    setIsLoading(false);
  };

  return {
    goalHistoryRecords,
    isLoading,
    loadGoalHistoryRecords,
  };
};

import { MainCard } from "@/components/berry";
import { AutofillTemplateDisplayValue } from "@/hooks";
import { Grid, Tooltip, Typography } from "@mui/material";
import { DocumentsAutofillDataDictionary } from "@l4s/documents-autofill-models";
import { IconInfoCircle } from "@tabler/icons";
import { SkeletonList } from "@/components/base";

interface Props {
  displayValues: Array<AutofillTemplateDisplayValue>;
  loading: boolean;
}

const DocumentAutofillConfigurationDisplay = ({ displayValues, loading }: Props) => {
  return (
    <MainCard
      title={
        <Grid container direction="column">
          <Typography variant="h2">Autofill Configuration</Typography>
          <Typography sx={{ maxWidth: "500px", mt: 1 }} variant="body2">
            The resources listed below are the ones that the autofill engine will employ to populate document values.
          </Typography>
        </Grid>
      }
    >
      {loading && <SkeletonList count={3} height={10} />}
      {!loading && !Boolean(displayValues.length) && (
        <Typography variant="body2">
          <i>No autofill configuration exists.</i>
        </Typography>
      )}

      {!loading && (
        <Grid container direction="column" spacing={1}>
          {displayValues.map((displayValue) => (
            <Grid item key={displayValue.entityId} container gap={1}>
              <Tooltip title={DocumentsAutofillDataDictionary[displayValue.entityType]?.description}>
                <Typography variant="h4">
                  {DocumentsAutofillDataDictionary[displayValue.entityType]?.name} <IconInfoCircle size={14} />
                </Typography>
              </Tooltip>
              <Typography variant="body1" sx={{ paddingTop: 0.2 }}>
                <a
                  style={{ pointerEvents: !displayValue.urlToResource ? "none" : "auto" }}
                  href={displayValue.urlToResource}
                  target="_none"
                >
                  {displayValue.displayValue}
                </a>
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </MainCard>
  );
};

export default DocumentAutofillConfigurationDisplay;

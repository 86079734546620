import { DocusignIcon, Drawer } from "@/components/base";
import { TemplateFieldAudienceDisplayMap, TemplateFieldInTemplate, TemplateFieldTypeDisplayMap, isDocusignTemplateField } from "@/types";
import { Edit } from "@mui/icons-material";
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import EditTemplateFieldForm from "./EditTemplateFieldForm";
import { useState } from "react";
import { UseTemplateFieldsHook } from "@/hooks";

interface TemplateFieldRowProps {
  templateField: TemplateFieldInTemplate;
  templateFieldsHookInstance: UseTemplateFieldsHook;
}

function TemplateFieldRow({ templateField, templateFieldsHookInstance }: TemplateFieldRowProps) {
  const [isEditingTemplateField, setIsEditingTemplateField] = useState<boolean>(false);

  return (
    <>
      <Grid sx={{ paddingY: 0.5, paddingLeft: 1 }} key={templateField.id} item container flexWrap="nowrap" spacing={0.5}>
        <Grid item>
          <IconButton sx={{ fontSize: 16, padding: 0 }} onClick={() => setIsEditingTemplateField(true)}>
            <Edit fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item container direction="column" sx={{ gap: 0.5 }}>
          <Typography variant="h4">{templateField.templateFieldName}</Typography>
          <Typography variant="body1">{templateField.templateFieldDescription}</Typography>
          {templateField.templateFieldAudience && (
            <Typography variant="body1" fontSize="small">
              <b>Completed by {TemplateFieldAudienceDisplayMap[templateField.templateFieldAudience]}</b>
            </Typography>
          )}
          <Typography variant="body1" fontSize="small">
            <i>Page {templateField.page + 1}</i>
          </Typography>
        </Grid>
        <Grid item>
          <Chip
            icon={
              isDocusignTemplateField(templateField.templateFieldType) ? (
                <DocusignIcon style={{ width: "1rem", borderRadius: "50%" }} />
              ) : null
            }
            size="small"
            label={TemplateFieldTypeDisplayMap[templateField.templateFieldType]}
          />
        </Grid>
      </Grid>
      <Drawer anchor="right" onClose={() => setIsEditingTemplateField(false)} open={isEditingTemplateField}>
        <EditTemplateFieldForm
          handleClose={() => setIsEditingTemplateField(false)}
          templateField={templateField}
          templateFieldsHookInstance={templateFieldsHookInstance}
        />
      </Drawer>
    </>
  );
}

interface TemplateFieldsListProps {
  templateFieldsHookInstance: UseTemplateFieldsHook;
}

function TemplateFieldsList({ templateFieldsHookInstance }: TemplateFieldsListProps) {
  const { templateFields } = templateFieldsHookInstance;

  return (
    <Grid container direction="column" spacing={1}>
      {templateFields
        .sort((a, b) => a.yCoord - b.yCoord)
        .sort((a, b) => a.page - b.page)
        .map((templateField, i) => (
          <div key={templateField.id}>
            <TemplateFieldRow templateFieldsHookInstance={templateFieldsHookInstance} templateField={templateField} />
            {i !== templateFields.length - 1 && <Divider sx={{ marginY: 1 }} />}
          </div>
        ))}
      {!Boolean(templateFields.length) && (
        <Typography variant="body1">
          <i>No template fields have been added yet. Click the "Add Template Field" button to add a template field.</i>
        </Typography>
      )}
    </Grid>
  );
}

export default TemplateFieldsList;

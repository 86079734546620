import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { GRID_SPACING } from "@/config";
import { CurrencyCentsTextField, Dropdown, LandisButton } from "@/components/base";
import { Add, Check, Close } from "@mui/icons-material";
import { UseCashForKeysOffers, useToast } from "@/hooks";
import { CashForKeysOffer } from "@/types";
import { mapUnknownToError } from "@/utils";
import { getOfferStatus, OfferStatus } from "./getOfferStatus";

interface Props {
  offer?: CashForKeysOffer;
  useCashForKeysOffers: UseCashForKeysOffers;
  onCancel: () => void;
}

const initialFormData = {
  offerDate: null,
  amountOfferedCents: null,
  acceptedDate: null,
  rejectedDate: null,
  status: OfferStatus.PENDING,
};

const CashForKeysForm = ({ offer, useCashForKeysOffers, onCancel }: Props) => {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cashForKeysFormData, setCashForKeysFormData] = useState<Partial<CashForKeysOffer> & { status: OfferStatus }>(initialFormData);

  const { addToast } = useToast();

  const { createCashForKeysOffer, updateCashForKeysOffer } = useCashForKeysOffers;

  const handleCancel = () => {
    onCancel();
    setIsAdding(false);
    setCashForKeysFormData(initialFormData);
  };

  const handleSave = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!offer) {
        await createCashForKeysOffer({
          offerDate: cashForKeysFormData.offerDate,
          amountOfferedCents: cashForKeysFormData.amountOfferedCents,
        });
      } else {
        await updateCashForKeysOffer({ ...offer, ...cashForKeysFormData });
      }
      handleCancel();
    } catch (e) {
      const error = mapUnknownToError(e);
      addToast({ message: error.message, type: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (offer) {
      setCashForKeysFormData({ ...cashForKeysFormData, ...offer, status: getOfferStatus(offer) });
    }
  }, [offer]);

  useEffect(() => {
    if (cashForKeysFormData.status === OfferStatus.ACCEPTED || cashForKeysFormData.status === OfferStatus.PENDING) {
      setCashForKeysFormData({ ...cashForKeysFormData, rejectedDate: null });
    }
    if (cashForKeysFormData.status === OfferStatus.REJECTED || cashForKeysFormData.status === OfferStatus.PENDING) {
      setCashForKeysFormData({ ...cashForKeysFormData, acceptedDate: null });
    }
  }, [cashForKeysFormData.status]);

  if (!offer && !isAdding)
    return (
      <div>
        <LandisButton disableElevation startIcon={<Add />} onClick={() => setIsAdding(true)} variant="contained">
          Add Offer
        </LandisButton>
      </div>
    );

  return (
    <form onSubmit={handleSave}>
      <Grid item xs={12} container flexDirection="column">
        <Grid item xs={12} container spacing={GRID_SPACING}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Offer Date"
              value={cashForKeysFormData.offerDate}
              onChange={(val) => setCashForKeysFormData({ ...cashForKeysFormData, offerDate: val })}
              renderInput={(params) => <TextField required fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Amount"
              value={cashForKeysFormData.amountOfferedCents}
              handleChange={(val) => setCashForKeysFormData({ ...cashForKeysFormData, amountOfferedCents: val })}
              required
            />
          </Grid>
          {offer && (
            <Grid item xs={12} sm={cashForKeysFormData.status === OfferStatus.PENDING ? 12 : 6}>
              <Dropdown
                required
                items={Object.values(OfferStatus).map((value) => ({ value: value, label: value }))}
                label="Status"
                value={cashForKeysFormData.status}
                handleChange={(val) => setCashForKeysFormData({ ...cashForKeysFormData, status: val })}
                id="cashForKeysStatus"
              />
            </Grid>
          )}
          {cashForKeysFormData.status !== OfferStatus.PENDING && (
            <Grid item xs={12} sm={6}>
              {cashForKeysFormData.status === OfferStatus.ACCEPTED && (
                <DatePicker
                  label="Accepted Date"
                  value={cashForKeysFormData.acceptedDate}
                  onChange={(val) => setCashForKeysFormData({ ...cashForKeysFormData, acceptedDate: val })}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
              {cashForKeysFormData.status === OfferStatus.REJECTED && (
                <DatePicker
                  label="Rejected Date"
                  value={cashForKeysFormData.rejectedDate}
                  onChange={(val) => setCashForKeysFormData({ ...cashForKeysFormData, rejectedDate: val })}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
            </Grid>
          )}
          <Grid spacing={GRID_SPACING} container item xs={12}>
            <Grid item xs={6}>
              <LandisButton
                startIcon={<Close />}
                disableElevation
                size="large"
                onClick={handleCancel}
                color="inherit"
                variant="outlined"
                fullWidth
              >
                Cancel
              </LandisButton>
            </Grid>
            <Grid item xs={6}>
              <LandisButton
                disableElevation
                type="submit"
                loading={loading}
                startIcon={<Check />}
                size="large"
                variant="contained"
                fullWidth
              >
                Save
              </LandisButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CashForKeysForm;

import {
  CreateOrderFormInput,
  generateInitialValuesFromProperty,
  INSPECTION_TYPE_OPTIONS,
  ORDER_OCCUPANCY_DROPDOWN_ITEMS,
  // ORDER_AVAILABILITY_INITIAL_VALUES,
  ORDER_STAKEHOLDER_INITIAL_VALUES,
  TYPE_OF_HOME_DROPDOWN_ITEMS,
  useInspectionOrders,
  validationSchema,
} from "@/hooks/data-hooks/inspection-orders";

import { MainCard } from "@/components/berry";
import { GRID_SPACING } from "@/config";
import { Dropdown, DropdownOption, FormArray, LandisButton } from "@/components/base";
import { ApplicationsPropertyDto, OrderDto, PropertyDto, TypeOfInspection, formatPropertyAddress } from "@/types";
import { Field, LABEL } from "@/hooks/data-hooks/inspection-orders";
import { CONFIRM_DROPDOWN_ITEMS, FormHelpers, mapUnknownToAxiosError } from "@/utils";
import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { memoize } from "lodash";
// not doing at this moment
// import OrderAvailabilityFieldset from "./OrderAvailabilityFieldset";
import OrderStakeholderFieldset from "./OrderStakeholderFieldset";
import { useToast } from "@/hooks";

interface Props {
  applicationsProperty: ApplicationsPropertyDto;
  property: PropertyDto;
  onSuccess: (order: OrderDto) => void;
}

const inspectionTypeDropDownSelectedLabel = memoize((typeoOfInspection: TypeOfInspection): string => {
  const typeOfInspectionOption = INSPECTION_TYPE_OPTIONS.find((ito) => ito.value === typeoOfInspection);
  return typeOfInspectionOption.data || typeoOfInspection;
});

function CreateInspectionOrder({ applicationsProperty, property, onSuccess }: Props) {
  const { addToast } = useToast();
  const { createInspectionOrder } = useInspectionOrders(applicationsProperty, property);
  const formik = useFormik<CreateOrderFormInput>({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: generateInitialValuesFromProperty(property),
    // validationSchema,
    onSubmit: async (values) => {
      try {
        const newOrder = await createInspectionOrder(values);
        // TODO: Add refetch here
        addToast({
          message: "Order successfully submitted!",
          type: "success",
        });
        formik.resetForm();
        onSuccess(newOrder);
      } catch (err: unknown) {
        const error = mapUnknownToAxiosError(err);
        addToast({
          message: error?.response?.data?.message || "Unknown error creating inspection order.",
          type: "error",
        });
      }
    },
  });

  return (
    <MainCard title="Create Inspection Order" content={false}>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title={LABEL[Field.PROPERTY_DATA]} />
                <CardContent>
                  <Grid container spacing={GRID_SPACING}>
                    <Grid item xs={12}>
                      <TextField
                        id={Field.PROPERTY_DATA__ADDRESS}
                        name={Field.PROPERTY_DATA__ADDRESS}
                        label={LABEL[Field.PROPERTY_DATA__ADDRESS]}
                        fullWidth
                        disabled
                        spellCheck={false}
                        value={formik.values[Field.PROPERTY_DATA__ADDRESS]}
                        helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__ADDRESS)}
                        error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__ADDRESS)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id={Field.PROPERTY_DATA__SQFT}
                        name={Field.PROPERTY_DATA__SQFT}
                        label={LABEL[Field.PROPERTY_DATA__SQFT]}
                        type="number"
                        fullWidth
                        disabled
                        spellCheck={false}
                        value={formik.values[Field.PROPERTY_DATA__SQFT]}
                        helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__SQFT)}
                        error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__SQFT)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id={Field.PROPERTY_DATA__YEAR_BUILT}
                        name={Field.PROPERTY_DATA__YEAR_BUILT}
                        label={LABEL[Field.PROPERTY_DATA__YEAR_BUILT]}
                        type="number"
                        fullWidth
                        disabled
                        spellCheck={false}
                        value={formik.values[Field.PROPERTY_DATA__YEAR_BUILT]}
                        helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__YEAR_BUILT)}
                        error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.PROPERTY_DATA__YEAR_BUILT)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Dropdown
                        id={Field.PROPERTY_DATA__TYPE_OF_HOME}
                        disabled={formik.isSubmitting}
                        items={TYPE_OF_HOME_DROPDOWN_ITEMS}
                        value={formik.values[Field.PROPERTY_DATA__TYPE_OF_HOME]}
                        handleChange={(value) => FormHelpers.handleFakeEvent(formik, Field.PROPERTY_DATA__TYPE_OF_HOME, value)}
                        label={LABEL[Field.PROPERTY_DATA__TYPE_OF_HOME]}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <DropdownOption
                id={Field.INSPECTION_TYPES}
                disabled
                label={LABEL[Field.INSPECTION_TYPES]}
                handleChange={formik.handleChange}
                values={formik.values[Field.INSPECTION_TYPES]}
                multiple={true}
                items={INSPECTION_TYPE_OPTIONS}
                optionComponent={(data) => <>{data}</>}
                labelSelected={inspectionTypeDropDownSelectedLabel}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.INSPECTION_TYPES)}
              />
            </Grid>
            {/* Not doing at this moment */}
            {/* <Grid item xs={12}>
              <FormArray
                valueKey={Field.ORDER_AVAILABILITIES}
                valueDefault={ORDER_AVAILABILITY_INITIAL_VALUES}
                formik={formik}
                formComponent={OrderAvailabilityFieldset}
                label={LABEL[Field.ORDER_AVAILABILITIES]}

              />
            </Grid> */}
            <Grid item xs={12}>
              <FormArray
                disabled={formik.isSubmitting}
                valueKey={Field.ORDER_STAKEHOLDERS}
                valueDefault={ORDER_STAKEHOLDER_INITIAL_VALUES}
                formik={formik}
                formComponent={OrderStakeholderFieldset}
                label={LABEL[Field.ORDER_STAKEHOLDERS]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={Field.SPECIAL_INSTRUCTIONS}
                name={Field.SPECIAL_INSTRUCTIONS}
                label={LABEL[Field.SPECIAL_INSTRUCTIONS]}
                disabled={formik.isSubmitting}
                fullWidth
                multiline
                spellCheck={false}
                onChange={formik.handleChange}
                value={formik.values[Field.SPECIAL_INSTRUCTIONS]}
                helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.SPECIAL_INSTRUCTIONS)}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.SPECIAL_INSTRUCTIONS)}
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              //
                Business does not want,
                they will add this to Field.SPECIAL_INSTRUCTIONS
              //
              <TextField
                id={Field.ACCESS_INFORMATION}
                label={LABEL[Field.ACCESS_INFORMATION]}
                fullWidth
                required
                spellCheck={false}
                onChange={(e) => {
                  formik.setFieldValue(Field.ACCESS_INFORMATION, e?.target?.value ?? "");
                }}
                value={formik.values[Field.ACCESS_INFORMATION]}
                helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.ACCESS_INFORMATION)}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.ACCESS_INFORMATION)}
              />
            </Grid>
            */}
            <Grid item xs={12}>
              <Dropdown
                id={Field.OCCUPANCY_INFO}
                disabled={formik.isSubmitting}
                items={ORDER_OCCUPANCY_DROPDOWN_ITEMS}
                value={formik.values[Field.OCCUPANCY_INFO]}
                handleChange={(value) => FormHelpers.handleFakeEvent(formik, Field.OCCUPANCY_INFO, value)}
                label={LABEL[Field.OCCUPANCY_INFO]}
                helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.OCCUPANCY_INFO)}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.OCCUPANCY_INFO)}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label={LABEL[Field.CONTINGENCY_DATE]}
                value={formik.values[Field.CONTINGENCY_DATE]}
                disabled={formik.isSubmitting}
                onChange={(value) => FormHelpers.handleFakeEvent(formik, Field.CONTINGENCY_DATE, value)}
                renderInput={(params) => (
                  <TextField
                    id={Field.CONTINGENCY_DATE}
                    fullWidth
                    {...params}
                    name={Field.CONTINGENCY_DATE}
                    helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.CONTINGENCY_DATE)}
                    error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.CONTINGENCY_DATE)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                id={Field.HAS_POOL}
                disabled={formik.isSubmitting}
                items={CONFIRM_DROPDOWN_ITEMS}
                value={formik.values[Field.HAS_POOL]}
                handleChange={(value) => FormHelpers.handleFakeEvent(formik, Field.HAS_POOL, value)}
                label={LABEL[Field.HAS_POOL]}
                helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.HAS_POOL)}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.HAS_POOL)}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                id={Field.HAS_GARAGE}
                disabled={formik.isSubmitting}
                items={CONFIRM_DROPDOWN_ITEMS}
                value={formik.values[Field.HAS_GARAGE]}
                handleChange={(value) => FormHelpers.handleFakeEvent(formik, Field.HAS_GARAGE, value)}
                label={LABEL[Field.HAS_GARAGE]}
                helperText={FormHelpers.formikErrorMessage<CreateOrderFormInput>(formik, Field.HAS_GARAGE)}
                error={FormHelpers.formikCheckError<CreateOrderFormInput>(formik, Field.HAS_GARAGE)}
              />
            </Grid>
            <Grid item xs={12}>
              <LandisButton
                // loading={isLoading}
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: "100%" }}
                disabled={formik.isSubmitting}
              >
                Submit
              </LandisButton>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </MainCard>
  );
}

export default CreateInspectionOrder;

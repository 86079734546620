import { bridgeV2ApiInstance } from "@/services";
import { CREATE_APPLICATIONS_PROPERTY, GET_APPLICATIONS_PROPERTIES } from "@/services/routes";
import { ListApplicationPropertyDto } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface UseApplicationsPropertiesHook {
  loading: boolean;
  applicationsProperties: Array<ListApplicationPropertyDto>;
  createApplicationsProperty: (propertyId: string) => Promise<void>;
  reloadApplicationsProperties: () => void;
  error: string | undefined;
}

export const useApplicationsProperties = (personId: string): UseApplicationsPropertiesHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationsProperties, setApplicationsProperties] = useState<Array<ListApplicationPropertyDto>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const getApplicationsProperties = async () => {
    setApplicationsProperties([]);
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<ListApplicationPropertyDto>>(GET_APPLICATIONS_PROPERTIES, {
        params: { personId },
      });
      setApplicationsProperties(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching client properties.");
    }
    setLoading(false);
  };

  const createApplicationsProperty = async (propertyId: string): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<ListApplicationPropertyDto>(CREATE_APPLICATIONS_PROPERTY, {
        propertyId,
        personId,
      });
      navigate(`${response.data.id}`);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      throw new Error(err.response.data.message || "Error creating client property.");
    }
  };

  useEffect(() => {
    if (personId) getApplicationsProperties();
  }, [personId]);

  return {
    loading,
    applicationsProperties,
    createApplicationsProperty,
    reloadApplicationsProperties: getApplicationsProperties,
    error,
  };
};

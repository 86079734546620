import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  height?: string | number;
}

function FullScreenLoading({ height = "100vh" }: Props) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height }}>
      <CircularProgress size={24} />
    </Box>
  );
}

export default FullScreenLoading;

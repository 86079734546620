import { LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UsePropertyManagementCompanyInstance, useToast } from "@/hooks";
import { PropertyManagementCompany } from "@/types/PropertyManagement";
import { FormHelpers, phoneRegExp } from "@/utils";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Field } from "./usePropertyManagementCompanyForm";
import * as Yup from "yup";
import { AddressFormNoValidation } from "@/components/partial/AddressFormNoValidation";

interface Props {
  propertyManagementCompany?: PropertyManagementCompany;
  usePropertyManagementCompanyInstance: UsePropertyManagementCompanyInstance;
  close: () => void;
}

function PropertyManagementCompanyForm({ propertyManagementCompany, usePropertyManagementCompanyInstance, close }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const formTitle = () => (propertyManagementCompany?.id ? "Update property management company" : "Add property management company");
  const formButton = () => (propertyManagementCompany?.id ? "Update" : "Add");

  const { addToast } = useToast();
  const { createPropertyManagementCompany, updatePropertyManagementCompany } = usePropertyManagementCompanyInstance;

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1).required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid").optional(),
    email: Yup.string().email("Invalid email").optional(),
    website: Yup.string().url("Invalid url").optional(),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: propertyManagementCompany?.name ?? "",
      addressStreet: propertyManagementCompany?.addressStreet ?? "",
      addressUnit: "",
      addressCity: propertyManagementCompany?.addressCity ?? "",
      addressState: propertyManagementCompany?.addressState ?? undefined,
      addressZip: propertyManagementCompany?.addressZip ?? "",
      phone: propertyManagementCompany?.phone ?? "",
      email: propertyManagementCompany?.email ?? "",
      website: propertyManagementCompany?.website ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (propertyManagementCompany) {
          await updatePropertyManagementCompany({
            ...values,
            id: propertyManagementCompany.id,
          });
        } else {
          await createPropertyManagementCompany(values);
        }
      } catch (e) {
        addToast({
          type: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
        close();
      }
    },
  });

  return (
    <MainCard title={formTitle()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <TextField
              id={Field.NAME}
              label="Name"
              value={formik.values[Field.NAME]}
              onChange={(e) => formik.setFieldValue(Field.NAME, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              helperText={FormHelpers.formikErrorMessage(formik, Field.NAME)}
              error={FormHelpers.formikCheckError(formik, Field.NAME)}
            />
          </Grid>
          <AddressFormNoValidation formik={formik}></AddressFormNoValidation>
          <Grid item>
            <TextField
              id={Field.PHONE}
              label="Phone"
              value={formik.values[Field.PHONE]}
              onChange={(e) => formik.setFieldValue(Field.PHONE, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 10 }}
              type="tel"
              helperText={FormHelpers.formikErrorMessage(formik, Field.PHONE)}
              error={FormHelpers.formikCheckError(formik, Field.PHONE)}
            />
          </Grid>
          <Grid item>
            <TextField
              id={Field.EMAIL}
              label="Email"
              value={formik.values[Field.EMAIL]}
              onChange={(e) => formik.setFieldValue(Field.EMAIL, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              type="email"
              helperText={FormHelpers.formikErrorMessage(formik, Field.EMAIL)}
              error={FormHelpers.formikCheckError(formik, Field.EMAIL)}
            />
          </Grid>
          <Grid item>
            <TextField
              id={Field.WEBSITE}
              label="Website"
              value={formik.values[Field.WEBSITE]}
              onChange={(e) => formik.setFieldValue(Field.WEBSITE, e.target.value)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              helperText={FormHelpers.formikErrorMessage(formik, Field.WEBSITE)}
              error={FormHelpers.formikCheckError(formik, Field.WEBSITE)}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              {formButton()}
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default PropertyManagementCompanyForm;

import Typography from "@mui/material/Typography";
import LeafRequirement from "./LeafRequirement";
import { ClientJourneyDtoModel, ClosedReason, JourneyRequirementDtoModel, UpdateRequirementInput } from "@/types";
import { isLastOpenRequirementOnLevel } from "./utils";
import { Checkbox } from "@mui/material";
import { DateFormat } from "@/utils";
import { format } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

interface Props {
  requirement: JourneyRequirementDtoModel;
  clientJourney: ClientJourneyDtoModel;
  updateRequirement: (requirementId: string, input: UpdateRequirementInput) => Promise<JourneyRequirementDtoModel>;
  onRequirementUpdate: () => Promise<void>;
  isLastOpenRequirement: boolean;
  milestoneCompleted: boolean;
}

const ClientRequirement = ({
  requirement,
  clientJourney,
  updateRequirement,
  onRequirementUpdate,
  milestoneCompleted,
  isLastOpenRequirement,
}: Props) => {
  const determineIfParentRequirementHasCompletedChildren = (req: JourneyRequirementDtoModel): boolean => {
    if (req.childRequirements?.length) {
      return req.childRequirements.some((childRequirement) => childRequirement.closedReason === ClosedReason.COMPLETED);
    }
    return false;
  };

  if (!requirement?.childRequirements?.length) {
    return (
      <LeafRequirement
        clientJourney={clientJourney}
        leafRequirement={requirement}
        updateRequirement={updateRequirement}
        onRequirementUpdate={onRequirementUpdate}
        milestoneCompleted={milestoneCompleted}
        isLastOpenRequirement={isLastOpenRequirement}
        topLevelRequirement={true}
      />
    );
  } else {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            indeterminate={!requirement.closedAt && determineIfParentRequirementHasCompletedChildren(requirement)}
            checked={requirement.closedReason === ClosedReason.COMPLETED}
            disabled={milestoneCompleted}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Typography>{requirement.definition.managerName}</Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }} variant="caption">
                <CalendarTodayIcon sx={{ fontSize: 14, marginRight: "0.3rem" }} />
                {requirement.closedAt
                  ? `Completed on ${format(new Date(requirement.closedAt), DateFormat.DISPLAY_DATE_TIME)}`
                  : `Started on ${format(new Date(requirement.createdAt), DateFormat.DISPLAY_DATE_TIME)}`}
              </Typography>
            </div>
          </div>
        </div>
        {requirement.childRequirements?.length &&
          requirement.childRequirements.map((subReq) => {
            return (
              <LeafRequirement
                clientJourney={clientJourney}
                key={subReq.id}
                leafRequirement={subReq}
                updateRequirement={updateRequirement}
                onRequirementUpdate={onRequirementUpdate}
                milestoneCompleted={milestoneCompleted}
                isLastOpenRequirement={isLastOpenRequirementOnLevel(subReq, requirement.childRequirements) && isLastOpenRequirement}
              />
            );
          })}
      </div>
    );
  }
};

export default ClientRequirement;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { ExitPlan } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";

export interface UseExitPlan {
  loadExitPlan: () => Promise<void>;
  upsertExitPlan: (input: Partial<ExitPlan>) => Promise<void>;
  exitPlan: ExitPlan;
  exitPlanLoading: boolean;
}

export const useExitPlan = (programClientId: string): UseExitPlan => {
  const [exitPlan, setExitPlan] = useState<ExitPlan>();
  const [exitPlanLoading, setExitPlanLoading] = useState<boolean>(true);

  const loadExitPlan = async () => {
    if (exitPlan) return;
    try {
      setExitPlanLoading(true);
      const response: { data: ExitPlan } = await bridgeV2ApiInstance.get(routes.GET_EXIT_PLAN_BY_PROGRAM_CLIENT_ID(programClientId));
      setExitPlanLoading(false);
      setExitPlan(response.data);
    } catch (e) {
      setExitPlanLoading(false);
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching exit plan.");
    }
  };

  const upsertExitPlan: UseExitPlan["upsertExitPlan"] = async (input) => {
    try {
      const response: { data: ExitPlan } = await bridgeV2ApiInstance.post(routes.UPSERT_EXIT_PLAN, {
        exitPlan: {
          ...input,
          programClientId,
        },
      });
      setExitPlan({ ...exitPlan, ...response.data });
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error updating exit plan.");
    }
  };

  return {
    exitPlan,
    exitPlanLoading,
    loadExitPlan,
    upsertExitPlan,
  };
};

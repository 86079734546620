import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useState } from "react";
import { Article as CoachingArticle } from "@l4s/intercom-models";

export interface UseCoachingArticles {
  loadCoachingArticles: () => Promise<void>;
  coachingArticles: Array<CoachingArticle>;
  isLoading: boolean;
}

export const useCoachingArticles = (): UseCoachingArticles => {
  const [coachingArticles, setCoachingArticles] = useState<Array<CoachingArticle>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadCoachingArticles = async () => {
    try {
      setIsLoading(true);
      const response: { data: Array<CoachingArticle> } = await bridgeV2ApiInstance.get(routes.COACHING_ARTICLES);
      setCoachingArticles(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error fetching coachingArticles.");
    }
    setIsLoading(false);
  };

  return {
    coachingArticles,
    isLoading,
    loadCoachingArticles,
  };
};

import { OktaAuth, TokenParams } from "@okta/okta-auth-js";

import { useTheme } from "@mui/material/styles";
import { Box, Grid, Stack, Typography, useMediaQuery, Button } from "@mui/material";

import { LogoSection } from "@/components/partial";
import { AuthCardWrapper, AnimateButton } from "@/components/berry";
import { OKTA_AUTH_CLIENT_ID, OKTA_AUTH_CALLBACK, OKTA_AUTH_ISSUER } from "@/config";

const Google = "/assets/images/icons/social-google.svg";

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  function oktaHandlerForGsuite() {
    const authClient: OktaAuth = new OktaAuth({
      clientId: OKTA_AUTH_CLIENT_ID,
      issuer: OKTA_AUTH_ISSUER,
      redirectUri: `${window.location?.origin}${OKTA_AUTH_CALLBACK}`,
      pkce: false,
    });

    const tokenParams: TokenParams = {
      scopes: ["openid", "email", "profile"],
    };
    authClient.token.getWithRedirect(tokenParams);
  }

  return (
    <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100vh" }}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "calc(100vh - 68px)" }}>
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <AuthCardWrapper>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item sx={{ mb: 3 }}>
                  <Box sx={{ paddingTop: 2 }}>
                    <LogoSection href={"/login"} width={120} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction={matchDownSM ? "column-reverse" : "row"} alignItems="center" justifyContent="center">
                    <Grid item>
                      <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? "h3" : "h2"}>
                          Hi, Welcome Back
                        </Typography>
                        <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? "center" : "inherit"}>
                          Log in with your Landis credentials
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column" justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <AnimateButton>
                        <Button
                          disableElevation
                          fullWidth
                          onClick={oktaHandlerForGsuite}
                          size="large"
                          variant="outlined"
                          sx={{
                            color: "grey.700",
                            backgroundColor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.grey[50],
                            borderColor: theme.palette.mode === "dark" ? theme.palette.dark.light + 20 : theme.palette.grey[100],
                          }}
                        >
                          <Box sx={{ display: "flex", mr: { xs: 1, sm: 2 }, width: 20, height: 20, marginRight: matchDownSM ? 8 : 16 }}>
                            <img src={Google} alt="Landis Login" width={"16px"} height={"16px"} />
                          </Box>
                          Sign in with Google
                        </Button>
                      </AnimateButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AuthCardWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;

import { useToast } from "@/hooks";
import { bridgeV2ApiInstance, routes } from "@/services";
import { AdverseActionDefinitionDto, ApplicationAdverseActionsDto } from "@/types";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

export interface UseApplicationAdverseActionsHook {
  loadingApplicationAdverseActions: boolean;
  insertingApplicationAdverseAction: boolean;
  applicationAdverseActions: Array<ApplicationAdverseActionsDto>;
  insertApplicationAdverseAction: (
    adverseActionId: AdverseActionDefinitionDto["id"],
    description?: string
  ) => Promise<ApplicationAdverseActionsDto>;
  getApplicationAdverseActions: () => Promise<Array<ApplicationAdverseActionsDto>>;
}

export const useApplicationAdverseActions = (applicationId: string): UseApplicationAdverseActionsHook => {
  const { addToast } = useToast();
  const [applicationAdverseActions, setApplicationAdverseActions] = useState<Array<ApplicationAdverseActionsDto>>([]);
  const [loadingApplicationAdverseActions, setLoading] = useState<boolean>(true);
  const [insertingApplicationAdverseAction, setInsertingApplicationAdverseAction] = useState<boolean>(false);

  const getApplicationAdverseActions = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<ApplicationAdverseActionsDto>>(
        routes.GET_APPLICATION_ADVERSE_ACTIONS(applicationId)
      );
      setApplicationAdverseActions(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplicationAdverseActions();
  }, [applicationId]);

  const insertApplicationAdverseAction = async (adverseActionId: AdverseActionDefinitionDto["id"], description?: string) => {
    setInsertingApplicationAdverseAction(true);
    try {
      const response = await bridgeV2ApiInstance.post<ApplicationAdverseActionsDto>(routes.INSERT_APPLICATION_ADVERSE_ACTION, {
        applicationId,
        adverseActionId,
        description,
      });
      addToast({ message: "Adverse action added.", type: "success" });
      getApplicationAdverseActions();
      setInsertingApplicationAdverseAction(false);
      return response.data;
    } catch (error) {
      let errorMessage = "Failed to add adverse action.";
      if (error instanceof AxiosError) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }
      addToast({ message: errorMessage, type: "error" });
      setInsertingApplicationAdverseAction(false);
      console.log(error);
      throw error;
    }
  };

  return {
    loadingApplicationAdverseActions,
    insertingApplicationAdverseAction,
    applicationAdverseActions,
    insertApplicationAdverseAction,
    getApplicationAdverseActions,
  };
};

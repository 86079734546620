import { StateCode, StateCodeMap } from "@/types";
import { Grid, TextField } from "@mui/material";
import { Dropdown } from "../../base";
import { FormikProps } from "formik";

export interface AddressFormNoValidationFields {
  addressStreet: string;
  addressUnit: string | null;
  addressCity: string;
  addressState: StateCode;
  addressZip: string;
}

interface Props {
  formik: FormikProps<AddressFormNoValidationFields>;
}

function AddressFormNoValidation({ formik }: Props) {
  const stateValues = Object.entries(StateCodeMap).map((x) => ({ label: x[1], value: x[0] }));

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="addressStreet"
          label="Address Street"
          value={formik.values.addressStreet}
          onChange={(e) => formik.setFieldValue("addressStreet", e.target.value)}
          fullWidth
          inputProps={{ maxLength: 50 }}
          error={!!formik.errors.addressStreet}
          helperText={formik.errors.addressStreet}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="addressUnit"
          label="Address Unit"
          value={formik.values.addressUnit}
          onChange={(e) => formik.setFieldValue("addressUnit", e.target.value)}
          fullWidth
          inputProps={{ maxLength: 50 }}
          error={!!formik.errors.addressUnit}
          helperText={formik.errors.addressUnit}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <TextField
            id="addressCity"
            label="City"
            value={formik.values.addressCity}
            onChange={(e) => formik.setFieldValue("addressCity", e.target.value)}
            inputProps={{ maxLength: 50 }}
            error={!!formik.errors.addressCity}
            helperText={formik.errors.addressCity}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="addressZip"
            label="Zip"
            value={formik.values.addressZip}
            onChange={(e) => formik.setFieldValue("addressZip", e.target.value)}
            inputProps={{ maxLength: 5 }}
            error={!!formik.errors.addressZip}
            helperText={formik.errors.addressZip}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          items={stateValues}
          label="State"
          id="addressState"
          required
          value={formik.values.addressState}
          error={Boolean(formik.errors.addressState)}
          helperText={formik.errors.addressState}
          handleChange={(value) => formik.setFieldValue("addressState", value)}
        />
      </Grid>
    </>
  );
}

export default AddressFormNoValidation;

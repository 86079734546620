import { Grid, Typography, useTheme } from "@mui/material";
import { FinancialQuestionnaireDtoModel } from "@/types";
import { isBoolean } from "lodash";
import { displayYesOrNo } from "../utis";

interface Props {
  financialQuestionnaire: FinancialQuestionnaireDtoModel;
}

const StandaloneCoachingFinancialQuestionnaire = ({ financialQuestionnaire }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} sx={{ padding: theme.spacing(2) }}>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Industry</Typography>
          <Typography>{financialQuestionnaire?.industry || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has Side Hustles</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasSideHustles)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Is Income Seasonal</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.isIncomeSeasonal)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Has purchased a home</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.hasPurchaseHistory)}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Last year of taxes filed</Typography>
          <Typography>{financialQuestionnaire?.taxesFiledYear}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Keeps Budget</Typography>
          <Typography>{displayYesOrNo(financialQuestionnaire?.keepsBudget)}</Typography>
        </Grid>
        {financialQuestionnaire?.keepsBudget ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Budget Tool Name</Typography>
            <Typography>{financialQuestionnaire?.budgetToolName || "-"}</Typography>
          </Grid>
        ) : null}
        {isBoolean(financialQuestionnaire?.keepsBudget) && financialQuestionnaire?.keepsBudget ? (
          <Grid item xs={6} md={4}>
            <Typography fontWeight="bold">Is Interested In Budget</Typography>
            <Typography>{displayYesOrNo(financialQuestionnaire?.isInterestedInBudget)}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Note For Coach</Typography>
          <Typography>{financialQuestionnaire?.noteForCoach || "-"}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography fontWeight="bold">Question For Coach</Typography>
          <Typography>{financialQuestionnaire?.questionForCoach || "-"}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default StandaloneCoachingFinancialQuestionnaire;

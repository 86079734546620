import { TemplateFieldInTemplate } from "@/types";
import { HighlightArea, RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { IconX } from "@tabler/icons";
import { LoopOutlined } from "@mui/icons-material";

const RED_COLOR = "#ff000090";
const GREEN_COLOR = "#00800090";

const fontSize = "calc(.5 * (1.5vh + 1.1vw))";
const padding = "calc(.01 * (1.5vh + 1.1vw))";

const markerStyle: React.CSSProperties = {
  color: "white",
  background: RED_COLOR,
  position: "absolute",
  width: fontSize,
  height: fontSize,
};

const inProgressStyle: React.CSSProperties = {
  color: "white",
  background: GREEN_COLOR,
  position: "absolute",
  left: `calc(${fontSize} / 2.5 * -1)`,
  top: `calc(${fontSize} / 2.5 * -1)`,
  width: `calc(${fontSize} / 1.5)`,
  height: `calc(${fontSize} / 1.5)`,
  borderRadius: "50%",

  zIndex: 100,
};

const nameStyle: React.CSSProperties = {
  color: "white",
  position: "absolute",
  background: RED_COLOR,
  whiteSpace: "nowrap",
  padding,
  fontSize,
};

function AnchorMarker({
  renderHighlightsProps,
  highlightArea,
  templateField,
  isFieldNamesShown,
}: {
  isFieldNamesShown: boolean;
  templateField: Partial<TemplateFieldInTemplate>;
  highlightArea: HighlightArea;
  renderHighlightsProps: RenderHighlightsProps;
}) {
  return (
    <div style={Object.assign(renderHighlightsProps.getCssProperties(highlightArea, renderHighlightsProps.rotation))}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {!templateField.id && <LoopOutlined style={inProgressStyle} />}
        {(!isFieldNamesShown || !templateField.templateFieldName) && <IconX style={markerStyle} />}
        {isFieldNamesShown && templateField.templateFieldName && <span style={nameStyle}>{templateField.templateFieldName}</span>}
      </div>
    </div>
  );
}

export default AnchorMarker;

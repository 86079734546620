import { bridgeV2ApiInstance, routes } from "@/services";
import { useEffect, useState } from "react";
import { JourneyMilestoneDtoModel, JourneyRequirementDtoModel, UpdateRequirementInput } from "@/types";
import { RequirementDefinitionDtoModel } from "@/types/Milestones/RequirementDefinition";
import useToast from "@/hooks/useToast";
import axios from "axios";
import { extractAxiosErrorMessage } from "@/utils";

export interface UseRequirementsHook {
  requirements: Array<JourneyRequirementDtoModel>;
  loading: boolean;
  updateRequirement: (requirementId: string, input: UpdateRequirementInput) => Promise<JourneyRequirementDtoModel>;
  refetchRequirements: () => Promise<void>;
}

class RequirementUpdateError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "RequirementUpdateError";
  }
}

export const useRequirements = (milestoneId: string): UseRequirementsHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [requirements, setRequirements] = useState<Array<JourneyRequirementDtoModel>>([]);

  const getRequirements = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<JourneyRequirementDtoModel>>(routes.GET_REQUIREMENTS, {
        params: { milestoneId },
      });
      setRequirements(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateRequirement = async (requirementId: string, input: UpdateRequirementInput) => {
    if (!requirementId) return;
    try {
      const response = await bridgeV2ApiInstance.put<JourneyRequirementDtoModel>(routes.UPDATE_REQUIREMENT(requirementId), input, {
        params: { requirementId },
      });
      setRequirements((existingRequirements) =>
        existingRequirements.map((requirement) =>
          requirement.id === response.data.id ? { ...requirement, ...response.data } : requirement
        )
      );
      return response.data;
    } catch (error) {
      const errorMessage = extractAxiosErrorMessage(error, "Error updating requirement.");
      throw new RequirementUpdateError(errorMessage);
    }
  };

  useEffect(() => {
    getRequirements();
  }, [milestoneId]);

  return {
    requirements,
    loading,
    updateRequirement,
    refetchRequirements: getRequirements,
  };
};

interface AddRequirementToMilestoneInput {
  requirementDefinitionKey: RequirementDefinitionDtoModel["key"];
  milestoneId: JourneyMilestoneDtoModel["id"];
}

export interface UseAddRequirementToMilestoneHookInstance {
  saving: boolean;
  addRequirementToMilestone: (input: AddRequirementToMilestoneInput) => Promise<JourneyRequirementDtoModel>;
}

export const useAddRequirementToMilestone = (): UseAddRequirementToMilestoneHookInstance => {
  const { addToast } = useToast();
  const [saving, setSaving] = useState(false);
  const addRequirementToMilestone = async (input: AddRequirementToMilestoneInput): Promise<JourneyRequirementDtoModel> => {
    setSaving(true);
    try {
      const response = await bridgeV2ApiInstance.post<JourneyRequirementDtoModel>(routes.POST_REQUIREMENT, input);
      setSaving(false);
      addToast({ message: "Requirement added.", type: "success" });
      return response.data;
    } catch (error) {
      let errorMessage = extractAxiosErrorMessage(error, "Failed to save requirement.");
      addToast({ message: errorMessage, type: "error" });
      setSaving(false);
      console.log(error);
      throw error;
    }
  };

  return {
    saving,
    addRequirementToMilestone,
  };
};

import { Divider, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CashForKeysOffer } from "@/types";
import { HeaderOption } from "@/components/base";
import { displayUsCents } from "@/utils";
import moment from "moment";
import { getOfferStatus, OfferStatus } from "./getOfferStatus";
import { CancelOutlined, CheckCircleOutline, Edit, HourglassEmpty } from "@mui/icons-material";

interface Props {
  offer: CashForKeysOffer;
  onEdit: () => void;
}

const CashForKeysRow = ({ offer, onEdit }: Props) => {
  const theme = useTheme();
  const offerStatus = getOfferStatus(offer);

  const renderIcon = () => {
    switch (offerStatus) {
      case OfferStatus.ACCEPTED:
        return <CheckCircleOutline sx={{ color: theme.palette.success.dark }} fontSize="small" />;
      case OfferStatus.REJECTED:
        return <CancelOutlined color="error" fontSize="small" />;
      default:
        return <HourglassEmpty fontSize="small" />;
    }
  };

  const editButton = (
    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", height: "100%" }}>
      <Edit onClick={onEdit} style={{ cursor: "pointer" }} />
    </div>
  );

  return (
    <>
      <Grid item container style={{ position: "relative" }}>
        <HeaderOption Icon={renderIcon()} lg={4} label="Offer Date:" value={moment(offer.offerDate).format("M/D/YY")} />
        <HeaderOption lg={4} label="Amount:" value={displayUsCents(offer.amountOfferedCents)} />
        {offerStatus === OfferStatus.PENDING && (
          <HeaderOption RightComponent={editButton} lg={4} label="Status:" value={getOfferStatus(offer)} />
        )}
        {offerStatus === OfferStatus.ACCEPTED && (
          <HeaderOption RightComponent={editButton} lg={4} label="Accepted Date:" value={moment(offer.acceptedDate).format("M/D/YY")} />
        )}
        {offerStatus === OfferStatus.REJECTED && (
          <HeaderOption RightComponent={editButton} lg={4} label="Rejected Date:" value={moment(offer.rejectedDate).format("M/D/YY")} />
        )}
      </Grid>
      <Divider sx={{ marginY: "10px" }} />
    </>
  );
};

export default CashForKeysRow;

import { bridgeV2ApiInstance, routes } from "@/services";
import { mapUnknownToAxiosError } from "@/utils";
import { useEffect, useState } from "react";
import { CreateHoaInput, HoaDto, UpdateHoaInput } from "@l4s/client-property-models";

import { Hoa } from "@/types/PropertyManagement";

export interface UseHoas {
  loading: boolean;
  propertyHoas: Array<Hoa>;
  createHoa: (input: CreateHoaInput) => Promise<void>;
  updateHoa: (input: UpdateHoaInput) => Promise<void>;
  reloadPropertyHoas: () => Promise<void>;
  error: string | undefined;
}

export const useHoas = (propertyId: string): UseHoas => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyHoas, setPropertyHoas] = useState<Array<Hoa>>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getHoasByPropertyId = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<Array<Hoa>>(routes.GET_HOAS_BY_PROPERTY_ID(propertyId), {
        params: { propertyId },
      });
      setPropertyHoas(response.data);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error fetching property HOAs.");
    }
    setLoading(false);
  };

  const createHoa = async (input: CreateHoaInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.post<HoaDto>(routes.CREATE_HOA, {
        ...input,
      });
      setPropertyHoas((existingHoas) => [...existingHoas, response.data]);
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error creating HOA.");
    }
  };

  const updateHoa = async (input: UpdateHoaInput): Promise<void> => {
    try {
      const response = await bridgeV2ApiInstance.put<HoaDto>(routes.UPDATE_HOA(input.id), input);
      setPropertyHoas((existingHoas) => existingHoas.map((hoa) => (hoa.id === response.data.id ? { ...hoa, ...response.data } : hoa)));
    } catch (e) {
      const err = mapUnknownToAxiosError(e);
      setError(err.response.data.message || "Error updating HOA.");
    }
  };

  useEffect(() => {
    if (propertyId) getHoasByPropertyId();
  }, [propertyId]);

  return {
    loading,
    propertyHoas,
    createHoa,
    updateHoa,
    reloadPropertyHoas: getHoasByPropertyId,
    error,
  };
};

import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, ReactNode, useState } from "react";

const autoDismissTimeout = 5000;

interface AddToastProps {
  message: string;
  type?: AlertColor;
}

interface State {
  open: boolean;
  addToast: ({ message, type }: AddToastProps) => void;
  message: string;
  type: AlertColor; // 'success' | 'info' | 'warning' | 'error'
}

const initialState: State = {
  open: false,
  addToast: () => {},
  message: "",
  type: "info",
};

const ToastContext = createContext(initialState);

type ToastProviderProps = {
  children: ReactNode;
};

function ToastProvider({ children }: ToastProviderProps) {
  const [toastState, setToastState] = useState<State>(initialState);

  const addToast = ({ message, type }: AddToastProps) => {
    setToastState({
      ...toastState,
      open: true,
      type: type || "info",
      message,
    });

    setTimeout(() => setToastState({ ...toastState, open: false }), autoDismissTimeout);
  };

  return (
    <ToastContext.Provider
      value={{
        ...toastState,
        addToast,
      }}
    >
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={toastState.open}>
        <Alert severity={toastState.type}>{toastState.message}</Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext };

import { useEffect, useState } from "react";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { TemplateFill } from "@/types";
import { bridgeV2ApiInstance } from "@/services";
import { GET_TEMPLATE_FILL_BY_ID } from "@/services/routes";

interface UseTemplateFillHook {
  templateFill: TemplateFill;
  loading: boolean;
}

export const useTemplateFill = (envelopeId: string, templateFillId: string): UseTemplateFillHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templateFill, setTemplateFill] = useState<TemplateFill>();

  const { addToast } = useToast();

  const getTemplateFill = async () => {
    setLoading(true);
    try {
      const response = await bridgeV2ApiInstance.get<TemplateFill>(GET_TEMPLATE_FILL_BY_ID(envelopeId, templateFillId));
      setTemplateFill(response.data);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTemplateFill();
  }, [templateFillId]);

  return { templateFill, loading };
};

import { Drawer, TextButton } from "@/components/base";
import { Grid, Typography, useTheme } from "@mui/material";
import { IconEdit } from "@tabler/icons";
import { Dispatch, SetStateAction } from "react";

interface Props {
  title: string;
  isFormOpenStateInstance: [boolean, Dispatch<SetStateAction<boolean>>];
  FormElement: React.ReactNode;
}

function ClientPropertyFinanceTableHeader({ FormElement, title, isFormOpenStateInstance }: Props) {
  const [isFormOpen, setFormOpen] = isFormOpenStateInstance;
  const theme = useTheme();

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
          <TextButton onClick={() => setFormOpen(true)} color={theme.palette.primary.main} StartIcon={IconEdit} size="large">
            Edit
          </TextButton>
        </Grid>
      </Grid>
      <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setFormOpen(false)} open={isFormOpen}>
        {FormElement}
      </Drawer>
    </>
  );
}

export default ClientPropertyFinanceTableHeader;

import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { SkeletonList } from "@/components/base";
import { statusOfApplicationPropertyLabels } from "@/types";
import { UseApplicationsPropertiesHook } from "@/hooks";
import { formatAddress, sortApplicationPropertiesByPriority } from "@/utils";
import { useNavigate } from "react-router-dom";
import ClientPropertiesTableHeader from "./ClientPropertiesTableHeader";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No properties.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={1} height={50} />
      </TableCell>
    </TableRow>
  );
}

interface Props {
  useApplicationsPropertiesInstance: UseApplicationsPropertiesHook;
  title: string | React.ReactNode;
  disableAddButton?: boolean;
}

function ClientPropertiesTable({ useApplicationsPropertiesInstance, title, disableAddButton = false }: Props) {
  const { applicationsProperties, error, loading } = useApplicationsPropertiesInstance;
  const navigate = useNavigate();

  if (!applicationsProperties) return null;

  return (
    <TableLayout
      maxHeight="70vh"
      title={
        <ClientPropertiesTableHeader
          disableAddButton={disableAddButton}
          title={title}
          useApplicationsPropertiesInstance={useApplicationsPropertiesInstance}
        />
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {error && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography color="error">
                  <i>{error}</i>
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!applicationsProperties.length && !loading && !error && <EmptyBody />}
          {loading && <LoadingBody />}
          {applicationsProperties.sort(sortApplicationPropertiesByPriority).map((row) => (
            <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
              <TableCell>{row.seq}</TableCell>
              <TableCell>
                {formatAddress(
                  row.propertyAddressStreet,
                  row.propertyAddressUnit,
                  row.propertyAddressCity,
                  row.propertyAddressZip,
                  row.propertyAddressState
                )}
              </TableCell>
              <TableCell>{statusOfApplicationPropertyLabels[row.status]}</TableCell>
              <TableCell align="right">
                <ChevronRight />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableLayout>
  );
}

export default ClientPropertiesTable;

import { LandisButton } from "@/components/base";
import { useToast, UsePaymentPlans } from "@/hooks";
import { mapUnknownToError } from "@/utils";
import { AddOutlined } from "@mui/icons-material";
import { ClickAwayListener, Grid, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
  programClientId: string;
  createPaymentPlan: UsePaymentPlans["createPaymentPlan"];
}

const AddPaymentPlanBtn = ({ programClientId, createPaymentPlan }: Props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const { addToast } = useToast();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createPaymentPlan({ paymentPlanName: name, programClientId });
      setName("");
      setOpen(false);
    } catch (err) {
      const error = mapUnknownToError(err);
      addToast({ message: error.message, type: "error" });
    }
    setLoading(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <form onSubmit={onSubmit}>
        <Grid container item xs={12} sx={{ marginTop: "20px" }}>
          {!open && (
            <LandisButton disableElevation size="large" variant="contained" fullWidth onClick={() => setOpen(!open)}>
              Add New Plan
            </LandisButton>
          )}
          {open && (
            <Grid item xs={12} container>
              <Grid item xs={9}>
                <TextField
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ paddingRight: 1 }}
                  size="medium"
                  fullWidth
                  label="Plan Name"
                />
              </Grid>
              <Grid item xs={3} sx={{ height: "100%" }}>
                <LandisButton
                  fullWidth
                  startIcon={<AddOutlined />}
                  disableElevation
                  sx={{ height: "100%", borderRadius: "8px" }}
                  variant="contained"
                  loading={loading}
                  type="submit"
                >
                  Plan
                </LandisButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </ClickAwayListener>
  );
};

export default AddPaymentPlanBtn;

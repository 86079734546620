import { AddCircleOutline } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Drawer } from "@/components/base";
import { useState } from "react";
import RentalInsuranceForm from "./RentalInsuranceForm";
import { UseRentalInsurance } from "@/hooks";

interface Props {
  title: string | React.ReactNode;
  disableAddButton?: boolean;
  propertyId: string;
  useRentalInsuranceInstance: UseRentalInsurance;
}

function RentalInsuranceTableHeader({ title, useRentalInsuranceInstance, propertyId, disableAddButton = false }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={5} container direction="column" gap={0.5}>
        <Typography variant="h2">{title}</Typography>
      </Grid>
      {!disableAddButton && (
        <>
          <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
            <Button variant="contained" endIcon={<AddCircleOutline />} onClick={() => setIsAddFormOpen(true)}>
              Add
            </Button>
          </Grid>
          <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setIsAddFormOpen(false)} open={isAddFormOpen}>
            <RentalInsuranceForm
              close={() => setIsAddFormOpen(false)}
              useRentalInsuranceInstance={useRentalInsuranceInstance}
              propertyId={propertyId}
            />
          </Drawer>
        </>
      )}
    </Grid>
  );
}

export default RentalInsuranceTableHeader;

import { ClosedReason, JourneyPathDtoModel, JourneyRequirementDtoModel } from "@/types";

export const sortByFocus = (milestones: JourneyPathDtoModel): JourneyPathDtoModel => {
  return milestones.sort((mi) => {
    if (mi.focus) return -1;
    return 1;
  });
};

export const removeVoidedMilestones = (milestones: JourneyPathDtoModel): JourneyPathDtoModel => {
  return milestones.filter((mi) => {
    return mi.closedReason !== ClosedReason.VOIDED;
  });
};

export const isLastOpenRequirementOnLevel = (req: JourneyRequirementDtoModel, requirements: JourneyRequirementDtoModel[]) => {
  if (requirements.length) {
    const openRequirements = requirements.filter((requirement) => !requirement.closedAt);

    // If there is only one open child requirement and it is the same as the current child requirement, return true
    if (openRequirements.length === 1 && req.id === openRequirements[0].id) {
      return true;
    }
  }
  return false;
};

import { MainCard } from "@/components/berry";
import { EnvelopeStatusChip } from "@/components/partial";
import { UseEnvelopeHook } from "@/hooks";
import { EnvelopeRecipient, TemplateFill } from "@/types";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

interface Props {
  useEnvelopeInstance: UseEnvelopeHook;
  templateFills: Array<TemplateFill>;
  envelopeRecipients: Array<EnvelopeRecipient>;
}

function EnvelopeDetailHeader({ useEnvelopeInstance, templateFills, envelopeRecipients }: Props) {
  const { envelope } = useEnvelopeInstance;

  return (
    <MainCard
      title={
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{envelope.name}</Typography>
          <EnvelopeStatusChip templateFills={templateFills} envelopeRecipients={envelopeRecipients} envelope={envelope} />
        </Grid>
      }
    >
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={10} container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <i>Created {moment(envelope.created).format("MMMM Do, YYYY")}</i>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default EnvelopeDetailHeader;

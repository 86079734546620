// material-ui
import { Grid, TableContainer, TableProps } from "@mui/material";
import { MainCard } from "@/components/berry";

interface Props {
  title: React.ReactNode | string;
  // Element to appear in the rop right corner of the table
  rightHeader?: React.ReactNode;
  children: React.ReactElement<TableProps>;
  pagination?: React.ReactNode;
  maxHeight?: string | number;
}

function TableLayout({ title, rightHeader, children, pagination, maxHeight }: Props) {
  return (
    <Grid item xs={12}>
      <MainCard contentSX={{ padding: "0 !important" }} title={title} secondary={rightHeader}>
        <Grid container direction="column" alignItems="center">
          {/* table */}
          <TableContainer style={{ maxHeight }}>{children}</TableContainer>
          {pagination && <Grid sx={{ padding: 2 }}>{pagination}</Grid>}
        </Grid>
      </MainCard>
    </Grid>
  );
}

export default TableLayout;

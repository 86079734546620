import React from "react";
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from "@react-pdf-viewer/highlight";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Document, FilledTemplateFieldInTemplate } from "@/types";

import AnchorMarker from "./AnchorMarker";
import { PDFViewer } from "@/components/base";

interface Props {
  document: Document | undefined;
  filledTemplateFieldsInTemplate: Array<FilledTemplateFieldInTemplate>;
}

const FillTemplatePDFViewer: React.FC<Props> = ({ document, filledTemplateFieldsInTemplate }) => {
  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {filledTemplateFieldsInTemplate.map((_, i) => (
        <React.Fragment key={i}>
          {filledTemplateFieldsInTemplate
            .filter((filledTemplateFieldInTemplate) => filledTemplateFieldInTemplate.page === props.pageIndex)
            .map((filledTemplateFieldInTemplate): [FilledTemplateFieldInTemplate, HighlightArea] => [
              filledTemplateFieldInTemplate,
              {
                left: filledTemplateFieldInTemplate.xCoord,
                top: filledTemplateFieldInTemplate.yCoord,
                width: 0,
                height: 0,
                pageIndex: filledTemplateFieldInTemplate.page,
              },
            ])
            .map(([filledTemplateFieldInTemplate, highlightArea], idx) => (
              <AnchorMarker
                key={idx}
                filledTemplateFieldInTemplate={filledTemplateFieldInTemplate}
                highlightArea={highlightArea}
                renderHighlightsProps={props}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  return (
    <div>
      <PDFViewer defaultScale={SpecialZoomLevel.PageWidth} document={document} plugins={[highlightPluginInstance]} />
    </div>
  );
};

export default FillTemplatePDFViewer;

import { Envelope, EnvelopeRecipient, EnvelopeStatus, TemplateFieldAudience, TemplateFill, TemplateFillStatus } from "@/types";
import { useMemo } from "react";

interface UseEnvelopeStatusHookProps {
  envelope?: Envelope;
  templateFills?: Array<TemplateFill>;
  envelopeRecipients?: Array<EnvelopeRecipient>;
}

const PRESEND_ENVELOPE_STATUSES = [EnvelopeStatus.EMPTY, EnvelopeStatus.IN_PROGRESS, EnvelopeStatus.READY_TO_SEND];

export const useEnvelopeStatus = ({
  envelope,
  templateFills,
  envelopeRecipients,
}: UseEnvelopeStatusHookProps): EnvelopeStatus | undefined => {
  const computedStatus: EnvelopeStatus | undefined = useMemo(() => {
    if (!envelope) return;
    // If the envelope is presend, we need to update the status in real time
    // If the envelope is sent, we are waiting for webhook events and do not
    // need to update the status in real time
    if (!PRESEND_ENVELOPE_STATUSES.includes(envelope.status)) return envelope.status;
    if (!templateFills || !envelopeRecipients) return envelope.status;
    return !Boolean(templateFills.length)
      ? EnvelopeStatus.EMPTY
      : templateFills.every((templateFill) => templateFill.status === TemplateFillStatus.COMPLETED) &&
        envelopeRecipients.find((recipient) => recipient.audience === TemplateFieldAudience.LANDIS) &&
        envelopeRecipients.find((recipient) => recipient.audience === TemplateFieldAudience.MAIN_SIGNER)
      ? EnvelopeStatus.READY_TO_SEND
      : EnvelopeStatus.IN_PROGRESS;
  }, [envelope, templateFills, envelopeRecipients]);

  return computedStatus;
};

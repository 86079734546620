import axios from "axios";

export const extractAxiosErrorMessage = (error: unknown, defaultMessage: string) => {
  let errorMessage = defaultMessage;

  if (error instanceof Error) {
    errorMessage = error.message;
    if (axios.isAxiosError(error)) {
      const responseData = error.response?.data;
      if (typeof responseData === "object" && "message" in responseData && typeof responseData.message === "string") {
        errorMessage = responseData.message || "Error updating requirement.";
      }
    }
  }

  return errorMessage;
};

import { TableLayout } from "@/components/layout";
import { formatAddress } from "@/utils";
import { ChevronRight } from "@mui/icons-material";
import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { DEFAULT_PADDING } from "@/constant";
import { UsePropertyManagementCompanyInstance } from "@/hooks";
import { PropertyManagementCompany, StateCode } from "@/types";
import PropertyManagementCompaniesTableHeader from "./PropertyManagementCompaniesTableHeader";
import PropertyManagementCompanyForm from "./PropertyManagementCompanyForm";

interface Props {
  usePropertyManagementCompanyInstance: UsePropertyManagementCompanyInstance;
}

const PropertyManagementCompaniesList = ({ usePropertyManagementCompanyInstance }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedPropertyManagementCompany, setSelectedPropertyManagementCompany] = useState<PropertyManagementCompany>(null);

  function handleClick(row: PropertyManagementCompany) {
    setDrawerOpen(true);
    setSelectedPropertyManagementCompany(row);
  }

  const { propertyManagementCompanies } = usePropertyManagementCompanyInstance;
  return (
    <>
      <TableLayout
        maxHeight="70vh"
        title={
          <PropertyManagementCompaniesTableHeader
            title="Property Management Companies"
            usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance}
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Website</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyManagementCompanies.map((row) => (
              <TableRow tabIndex={0} sx={{ width: "100%" }} hover key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {formatAddress(row.addressStreet, null, row.addressCity, row.addressZip, row.addressState as StateCode)}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.website}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleClick(row)}>
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      <Drawer
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
      >
        <Box sx={{ padding: DEFAULT_PADDING }}>
          <PropertyManagementCompanyForm
            usePropertyManagementCompanyInstance={usePropertyManagementCompanyInstance}
            propertyManagementCompany={selectedPropertyManagementCompany}
            close={() => setDrawerOpen(false)}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default PropertyManagementCompaniesList;

import React, { useEffect, useRef } from "react";
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from "@react-pdf-viewer/highlight";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Document, TemplateFieldInTemplate } from "@/types";

import { AddTemplateInFieldInput } from "../types";
import AnchorMarker from "./AnchorMarker";
import { PDFViewer, detectClicksPlugin } from "@/components/base";

interface Props {
  document: Document | undefined;
  isSelectMode: boolean;
  isFieldNamesShown: boolean;
  templateFields: Array<Partial<TemplateFieldInTemplate>>;
  onSelectFieldLocation: (input: AddTemplateInFieldInput) => void;
}

const ConfigureTemplatePDFViewer: React.FC<Props> = ({
  document,
  isSelectMode,
  isFieldNamesShown,
  templateFields,
  onSelectFieldLocation,
}) => {
  const isSelectModeRef = useRef<boolean>(false);

  useEffect(() => {
    isSelectModeRef.current = isSelectMode;
  }, [isSelectMode]);

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {templateFields.map((_, i) => (
        <React.Fragment key={i}>
          {templateFields
            .filter((tField) => tField.page === props.pageIndex)
            .map((tField): [Partial<TemplateFieldInTemplate>, HighlightArea] => [
              tField,
              {
                left: tField.xCoord,
                top: tField.yCoord,
                width: 0,
                height: 0,
                pageIndex: tField.page,
              },
            ])
            .map(([tField, highlightArea], idx) => (
              <AnchorMarker
                key={idx}
                templateField={tField}
                isFieldNamesShown={isFieldNamesShown}
                highlightArea={highlightArea}
                renderHighlightsProps={props}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const detectClicksPluginInstance = detectClicksPlugin({
    handleClick: (input) => {
      const { x, y, pageIndex } = input;
      if (isSelectModeRef.current) {
        onSelectFieldLocation({
          page: pageIndex,
          xCoord: x - 0.75,
          yCoord: y - 0.75,
        });
      }
    },
  });

  return (
    <div
      style={{
        cursor: isSelectMode ? "crosshair" : "auto",
      }}
    >
      <PDFViewer
        defaultScale={SpecialZoomLevel.PageWidth}
        document={document}
        plugins={[highlightPluginInstance, detectClicksPluginInstance]}
      />
    </div>
  );
};

export default ConfigureTemplatePDFViewer;

import { CurrencyCentsTextField, Dropdown, LandisButton } from "@/components/base";
import { MainCard } from "@/components/berry";
import { UseApplicationsPropertyFinanceHook, useToast } from "@/hooks";
import { TypeOfProgram, TypeOfValuation, typeOfProgramLabelMap, typeOfValuationLabelMap } from "@/types";
import { Check } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
  handleClose: () => void;
}

function FixedPaymentsForm({ useApplicationsPropertyFinanceInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { applicationsPropertyFinance, updateFixedPayments } = useApplicationsPropertyFinanceInstance;

  const { addToast } = useToast();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      leaseStartDate: applicationsPropertyFinance.leaseStartDate
        ? moment(applicationsPropertyFinance.leaseStartDate).format("M/D/YYYY")
        : null,
      currentLeaseTerm: applicationsPropertyFinance.currentLeaseTerm,
      valuationType: applicationsPropertyFinance.valuationType,
      recordingFeeCents: applicationsPropertyFinance.recordingFeeCents,
      titleFeeCents: applicationsPropertyFinance.titleFeeCents,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateFixedPayments({
          leaseStartDate: values.leaseStartDate,
          currentLeaseTerm: values.currentLeaseTerm,
          valuationType: values.valuationType,
          recordingFeeCents: values.recordingFeeCents,
          titleFeeCents: values.titleFeeCents,
        });
        handleClose();
      } catch (err) {
        addToast({
          type: "error",
          message: err.message,
        });
      }
      setLoading(false);
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      return errors;
    },
  });

  return (
    <MainCard title="Edit Fixed Payments">
      <form onSubmit={formik.handleSubmit}>
        <Grid direction="column" container gap={1.5}>
          <Grid item>
            <DatePicker
              value={formik.values.leaseStartDate}
              onChange={(val) => {
                formik.setFieldValue("leaseStartDate", moment(val).format("M/D/YYYY"));
              }}
              label="Lease Start Date"
              renderInput={(params) => (
                <TextField
                  id="leaseStartDate"
                  fullWidth
                  {...params}
                  name="Date"
                  helperText={formik.errors.leaseStartDate}
                  error={!!formik.errors.leaseStartDate}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Dropdown
              id="currentLeaseTerm"
              label="Current Lease Term"
              value={formik.values.currentLeaseTerm}
              handleChange={(value) => formik.setFieldValue("currentLeaseTerm", value)}
              error={!!formik.errors.currentLeaseTerm}
              helperText={formik.errors.currentLeaseTerm}
              items={Object.values(TypeOfProgram).map((typeOfProgram) => ({
                label: typeOfProgramLabelMap[typeOfProgram],
                value: typeOfProgram,
              }))}
            />
          </Grid>
          <Grid>
            <Dropdown
              id="valuationType"
              label="Valuation Type"
              value={formik.values.valuationType}
              handleChange={(value) => formik.setFieldValue("valuationType", value)}
              error={!!formik.errors.valuationType}
              helperText={formik.errors.valuationType}
              items={Object.values(TypeOfValuation).map((typeOfValuation) => ({
                label: typeOfValuationLabelMap[typeOfValuation],
                value: typeOfValuation,
              }))}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Recording Fee"
              value={formik.values.recordingFeeCents}
              handleChange={(val) => formik.setFieldValue("recordingFeeCents", val)}
              error={!!formik.errors.recordingFeeCents}
              helperText={formik.errors.recordingFeeCents}
            />
          </Grid>
          <Grid>
            <CurrencyCentsTextField
              variant="outlined"
              fullWidth
              label="Title Fee"
              value={formik.values.titleFeeCents}
              handleChange={(val) => formik.setFieldValue("titleFeeCents", val)}
              error={!!formik.errors.titleFeeCents}
              helperText={formik.errors.titleFeeCents}
            />
          </Grid>
          <Grid item>
            <LandisButton loading={loading} type="submit" variant="contained" endIcon={<Check />}>
              Submit
            </LandisButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default FixedPaymentsForm;

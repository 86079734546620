import { UseApplicationsPropertyFinanceHook } from "@/hooks";
import { displayUsCents } from "@/utils";
import ClientPropertyFinanceTable from "../ClientPropertyFinanceTable";
import { ClientPropertyFinanceRow } from "../types";
import ClientPropertyFinanceTableHeader from "../ClientPropertyFinanceTableHeader";
import { useState } from "react";
import ClientPaymentsForm from "./ClientPaymentsForm";

interface Props {
  useApplicationsPropertyFinanceInstance: UseApplicationsPropertyFinanceHook;
}

const tableRows: Array<ClientPropertyFinanceRow> = [
  {
    label: "First Month Prorated Rent",
    fieldKey: "firstMonthProratedRentCents",
    displayFn: displayUsCents,
  },
  {
    label: "Monthly Rent",
    fieldKey: "monthlyRentCents",
    displayFn: displayUsCents,
  },
  {
    label: "Security Deposit",
    fieldKey: "securityDepositCents",
    displayFn: displayUsCents,
  },
  {
    label: "Monthly HOC",
    fieldKey: "monthlyHocCents",
    displayFn: displayUsCents,
  },
  {
    label: "Upfront HOC",
    fieldKey: "upfrontHocCents",
    displayFn: displayUsCents,
  },
];

const ClientPaymentsCard = ({ useApplicationsPropertyFinanceInstance }: Props) => {
  const isFormOpenStateInstance = useState<boolean>(false);
  const [, setIsFormOpen] = isFormOpenStateInstance;
  return (
    <ClientPropertyFinanceTable
      tableRows={tableRows}
      title={
        <ClientPropertyFinanceTableHeader
          FormElement={
            <ClientPaymentsForm
              handleClose={() => setIsFormOpen(false)}
              useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
            />
          }
          isFormOpenStateInstance={isFormOpenStateInstance}
          title="Client Payments"
        />
      }
      useApplicationsPropertyFinanceInstance={useApplicationsPropertyFinanceInstance}
    />
  );
};

export default ClientPaymentsCard;

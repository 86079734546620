import { PDFDocument } from "pdf-lib";

export const toBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const loadFileForValidityCheck = async (file: File): Promise<void> => {
  await PDFDocument.load(await toBase64(file));
};

import { bridgeV2ApiInstance, routes } from "@/services";
import { AuthenticatedAccount } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";

export interface UseAuthenticatedAccount {
  getAuthenticatedAccount: () => Promise<AuthenticatedAccount>;
}

export const useAuthenticatedAccount = (): UseAuthenticatedAccount => {
  const getAuthenticatedAccount = async (): Promise<AuthenticatedAccount> => {
    try {
      const response: { data: { authenticatedAccount: AuthenticatedAccount } } = await bridgeV2ApiInstance.get(
        routes.GET_AUTHENTICATED_ACCOUNT
      );
      return response.data.authenticatedAccount;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error getting authenticated account for logged in user.");
    }
  };

  return {
    getAuthenticatedAccount,
  };
};

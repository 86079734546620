import { FinancialQuestionnaireDtoModel } from "@/types";
import { TypeOfLandisProgram } from "@l4s/program-models";
import { ProgramClient } from "@/types";
import StandaloneCoachingFinancialQuestionnaire from "./StandaloneCoachingFinancialQuestionnaire";
import RentToOwnFinancialQuestionnaire from "./RentToOwnFinancialQuestionnaire";

interface Props {
  financialQuestionnaire: FinancialQuestionnaireDtoModel;
  programClient: ProgramClient;
}

const ClientFinancialQuestionnaire = ({ financialQuestionnaire, programClient }: Props) => {
  return programClient.program === TypeOfLandisProgram.COACHING_ONLY ? (
    <StandaloneCoachingFinancialQuestionnaire financialQuestionnaire={financialQuestionnaire} />
  ) : (
    <RentToOwnFinancialQuestionnaire financialQuestionnaire={financialQuestionnaire} />
  );
};

export default ClientFinancialQuestionnaire;

import { bridgeV2ApiInstance } from "@/services";
import { routes } from "@/services";
import { Action, StatusOfAction, TypeOfAction } from "@/types";
import { mapUnknownToAxiosError } from "@/utils";

interface CreateActionInput {
  name: string;
  description: string;
  type: TypeOfAction;
  status?: StatusOfAction;
  dueDate?: string;
  dueDateLabel?: string;
  publishDate?: string;
  link?: string;
  buttonText?: string;
  learnMoreLink?: string;
  // join with actions_goals
  goalId?: string;
}

export interface UseBulkActions {
  createBulkActions: (programClientIds: string[], input: CreateActionInput) => Promise<Array<Action>>;
}

export const useBulkActions = (): UseBulkActions => {
  const createBulkActions = async (programClientIds: string[], input: CreateActionInput) => {
    const bulkActions = programClientIds.map((programClientId) => {
      return { ...input, programClientId };
    });
    try {
      const response: { data: Action[] } = await bridgeV2ApiInstance.post(routes.BULK_CREATE_ACTIONS, bulkActions);
      return response.data;
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      throw new Error(error?.response?.data?.message || "Error creating actions.");
    }
  };

  return {
    createBulkActions,
  };
};

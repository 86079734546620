import {
  PlaidAuthentication,
  PlaidAccount,
  PlaidTransaction,
  PlaidBalance,
  PlaidMerchantSortTransactions,
  PlaidCategorySortTransactions,
  PlaidFinanceCategory,
} from "@l4s/plaid-models";
import { bridgeV2ApiInstance, routes } from "@/services";
import { useEffect, useState } from "react";
import { mapUnknownToError } from "@/utils";

export const MappedPlaidFinanceCategory: Record<PlaidFinanceCategory, string> = {
  INCOME: "Income",
  TRANSFER_IN: "Transfer In",
  TRANSFER_OUT: "Transfer Out",
  LOAN_PAYMENTS: "Loan Payments",
  BANK_FEES: "Bank Fees",
  ENTERTAINMENT: "Entertainment",
  FOOD_AND_DRINK: "Food and Drink",
  GENERAL_MERCHANDISE: "General Merchandise",
  HOME_IMPROVEMENT: "Home Improvement",
  MEDICAL: "Medical",
  PERSONAL_CARE: "Personal Care",
  GENERAL_SERVICES: "General Services",
  GOVERNMENT_AND_NON_PROFIT: "Government and Non-Profit",
  TRANSPORTATION: "Transportation",
  TRAVEL: "Travel",
  RENT_AND_UTILITIES: "Rent And Utilities",
  UNDEFINED: "Undefined",
};

const getAuthenticationsForLandisAccount = async (landisAccountId: string): Promise<Array<PlaidAuthentication>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidAuthentication>>(routes.GET_PLAID_AUTHENTICATIONS(landisAccountId));
  return response.data;
};

const getAccountsForAuthentication = async (plaidAuthenticationId: string): Promise<Array<PlaidAccount>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidAccount>>(routes.GET_PLAID_ACCOUNTS(plaidAuthenticationId));
  return response.data;
};

const getTransactionsForAccount = async (plaidAccountId: string, fromDate: Date, toDate: Date): Promise<Array<PlaidTransaction>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidTransaction>>(routes.GET_PLAID_ACCOUNT_TRANSACTIONS(plaidAccountId), {
    params: { fromDate, toDate },
  });
  return response.data;
};

const getBalancesForAccount = async (plaidAccountId: string, fromDate: Date, toDate: Date): Promise<Array<PlaidBalance>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidBalance>>(routes.GET_PLAID_ACCOUNT_BALANCES(plaidAccountId), {
    params: { fromDate, toDate },
  });
  return response.data;
};

const getMerchantSortForAccount = async (
  landisAccountId: string,
  fromDate: Date,
  toDate: Date
): Promise<Array<PlaidMerchantSortTransactions>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidMerchantSortTransactions>>(
    routes.GET_PLAID_ACCOUNT_MERCHANT_SORT(landisAccountId),
    {
      params: { fromDate, toDate },
    }
  );
  return response.data;
};

const getCategorySortForAccount = async (
  plaidAccountId: string,
  fromDate: Date,
  toDate: Date
): Promise<Array<PlaidCategorySortTransactions>> => {
  const response = await bridgeV2ApiInstance.get<Array<PlaidCategorySortTransactions>>(
    routes.GET_PLAID_ACCOUNT_CATEGORY_SORT(plaidAccountId),
    {
      params: { fromDate, toDate },
    }
  );
  return response.data;
};

export interface AccountTransactions extends PlaidAccount {
  transactions: Array<PlaidTransaction>;
}

export const usePlaidAccountsTransactions = (landisAccountId: string, fromDate: Date, toDate: Date) => {
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [TransactionsError, setTransactionsError] = useState<null | string>(null);
  const [accountsTransactions, setAccountsTransactions] = useState<Array<AccountTransactions>>([]);

  const fetchTransactions = async () => {
    setLoadingTransactions(true);
    try {
      const authenticationsResponse = await getAuthenticationsForLandisAccount(landisAccountId);
      const authenticationAccountsResponse = await Promise.all(
        authenticationsResponse.map((authentication) => getAccountsForAuthentication(authentication.id))
      );
      const accounts = authenticationAccountsResponse.flatMap((authenticationAccounts) => authenticationAccounts);
      const _accountsTransactions = await Promise.all(
        accounts.map(async (account) => {
          const accountTransactions = await getTransactionsForAccount(account.plaidAccountId, fromDate, toDate);
          return {
            ...account,
            transactions: accountTransactions,
          };
        })
      );
      setAccountsTransactions(_accountsTransactions);
    } catch (error: unknown) {
      const err = mapUnknownToError(error);
      setTransactionsError(err.message);
    } finally {
      setLoadingTransactions(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [landisAccountId]);

  return {
    loading: loadingTransactions,
    error: TransactionsError,
    result: accountsTransactions,
  };
};

export interface AccountBalances extends PlaidAccount {
  balances: Array<PlaidBalance>;
}

export const usePlaidAccountsBalances = (landisAccountId: string, fromDate: Date, toDate: Date) => {
  const [loadingBalances, setLoadingBalances] = useState(false);
  const [balancesError, setBalancesError] = useState<null | string>(null);
  const [accountsBalances, setAccountsBalances] = useState<Array<AccountBalances>>([]);

  const fetchBalances = async () => {
    setLoadingBalances(true);
    try {
      const authenticationsResponse = await getAuthenticationsForLandisAccount(landisAccountId);
      const authenticationAccountsResponse = await Promise.all(
        authenticationsResponse.map((authentication) => getAccountsForAuthentication(authentication.id))
      );
      const accounts = authenticationAccountsResponse.flatMap((authenticationAccounts) => authenticationAccounts);
      const _accountsBalances = await Promise.all(
        accounts.map(async (account) => {
          const accountBalances = await getBalancesForAccount(account.plaidAccountId, fromDate, toDate);
          return {
            ...account,
            balances: accountBalances,
          };
        })
      );
      setAccountsBalances(_accountsBalances);
    } catch (error: unknown) {
      const err = mapUnknownToError(error);
      setBalancesError(err.message);
    } finally {
      setLoadingBalances(false);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, [landisAccountId]);

  return {
    loading: loadingBalances,
    error: balancesError,
    result: accountsBalances,
  };
};

export const usePlaidMerchantSorts = (landisAccountId: string, fromDate: Date, toDate: Date) => {
  const [loadingMerchantSorts, setLoadingMerchantSorts] = useState(false);
  const [merchantSortsError, setMerchantSortsError] = useState<null | string>(null);
  const [merchantSorts, setMerchantSorts] = useState<Array<PlaidMerchantSortTransactions>>([]);

  const fetchMerchantSorts = async () => {
    setLoadingMerchantSorts(true);
    try {
      const _merchantSorts = await getMerchantSortForAccount(landisAccountId, fromDate, toDate);
      setMerchantSorts(_merchantSorts);
    } catch (error: unknown) {
      const err = mapUnknownToError(error);
      setMerchantSortsError(err.message);
    } finally {
      setLoadingMerchantSorts(false);
    }
  };

  useEffect(() => {
    fetchMerchantSorts();
  }, [landisAccountId]);

  return {
    loading: loadingMerchantSorts,
    error: merchantSortsError,
    result: merchantSorts,
  };
};

export const usePlaidCategorySorts = (landisAccountId: string, fromDate: Date, toDate: Date) => {
  const [loadingCategorySorts, setLoadingCategorySorts] = useState(false);
  const [categorySortsError, setCategorySortsError] = useState<null | string>(null);
  const [categorySorts, setCategorySorts] = useState<Array<PlaidCategorySortTransactions>>([]);

  const fetchCategorySorts = async () => {
    setLoadingCategorySorts(true);
    try {
      const _categorySorts = await getCategorySortForAccount(landisAccountId, fromDate, toDate);
      setCategorySorts(_categorySorts);
    } catch (error: unknown) {
      const err = mapUnknownToError(error);
      setCategorySortsError(err.message);
    } finally {
      setLoadingCategorySorts(false);
    }
  };

  useEffect(() => {
    fetchCategorySorts();
  }, [landisAccountId]);

  return {
    loading: loadingCategorySorts,
    error: categorySortsError,
    result: categorySorts,
  };
};

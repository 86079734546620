import { Drawer, TextButton } from "@/components/base";
import { UsePropertyHook } from "@/hooks";
import { Grid, Typography, useTheme } from "@mui/material";
import { IconEdit } from "@tabler/icons";
import { useState } from "react";

import BasicHomeStatsForm from "./BasicHomeStatsForm";

interface Props {
  usePropertyInstance: UsePropertyHook;
  isReadOnly: boolean;
}

function PropertyBasicHomeStatsHeader({ usePropertyInstance, isReadOnly }: Props) {
  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const theme = useTheme();

  const { property } = usePropertyInstance;

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h3">Home Details</Typography>
        </Grid>
        <Grid item xs={12} md={7} container justifyContent="flex-end" alignItems="center" flexWrap="nowrap" sx={{ gap: 2 }}>
          {property && !isReadOnly && (
            <TextButton onClick={() => setFormOpen(true)} color={theme.palette.primary.main} StartIcon={IconEdit} size="large">
              Edit
            </TextButton>
          )}
        </Grid>
      </Grid>
      <Drawer sx={{ width: 500, maxWidth: "85vw" }} anchor="right" onClose={() => setFormOpen(false)} open={isFormOpen}>
        <BasicHomeStatsForm handleClose={() => setFormOpen(false)} usePropertyInstance={usePropertyInstance} />
      </Drawer>
    </>
  );
}

export default PropertyBasicHomeStatsHeader;

import moment from "moment";
import { AddTemplateToEnvelopeForm, TemplateFillStatusChip } from "@/components/partial";
import { TableLayout } from "@/components/layout";
import { ChevronRight } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { UseTemplateFillsHook, useToast } from "@/hooks";
import { useState } from "react";
import { Drawer, SkeletonList } from "@/components/base";

import { useNavigate } from "react-router-dom";

import EnvelopeDocumentsTableHeader from "./EnvelopeDocumentsTableHeader";

function EmptyBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography>No documents in envelope.</Typography>
      </TableCell>
    </TableRow>
  );
}

function LoadingBody() {
  return (
    <TableRow>
      <TableCell colSpan={3}>
        <SkeletonList count={3} height={50} />
      </TableCell>
    </TableRow>
  );
}

interface Props {
  useTemplateFillsInstance: UseTemplateFillsHook;
  isReadOnly: boolean;
}

function EnvelopeDocumentsTable({ isReadOnly, useTemplateFillsInstance }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>();

  const { addToast } = useToast();

  const navigate = useNavigate();

  const { templateFills, loading, createTemplateFill } = useTemplateFillsInstance;

  const onAddTemplateToEnvelope = async (templateId: string) => {
    try {
      await createTemplateFill(templateId);
      addToast({ message: "Document added successfully", type: "success" });
      setIsDrawerOpen(false);
    } catch (e) {}
  };

  return (
    <>
      <TableLayout title={<EnvelopeDocumentsTableHeader isReadOnly={isReadOnly} showAddForm={() => setIsDrawerOpen(true)} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!templateFills.length && !loading && <EmptyBody />}
            {loading && <LoadingBody />}
            {templateFills.map((row) => (
              <TableRow sx={{ width: "100%" }} hover key={row.id} onClick={() => navigate(row.id)}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.templateName}</TableCell>
                <TableCell>{moment(row.created).format("MMMM Do, YYYY")}</TableCell>
                <TableCell>
                  <TemplateFillStatusChip status={row.status} />
                </TableCell>
                <TableCell align="right">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableLayout>
      {!isReadOnly && (
        <Drawer anchor="right" onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen} sx={{ width: 500, maxWidth: "85vw", mb: "12px" }}>
          <AddTemplateToEnvelopeForm title="Add Document to Envelope" onAddTemplateToEnvelope={onAddTemplateToEnvelope} />
        </Drawer>
      )}
    </>
  );
}

export default EnvelopeDocumentsTable;

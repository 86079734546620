import { Place, StateCode } from "@/types";

export const findInAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  targetType: string
): string | undefined => {
  return addressComponents?.find((comp) => comp.types.includes(targetType))?.short_name;
};

export const placeFromGooglePlaceResult = (result: google.maps.places.PlaceResult): Place | undefined => {
  if (!result.address_components) return;
  return {
    streetNumber: findInAddressComponents(result.address_components, "street_number"),
    street: findInAddressComponents(result.address_components, "route"),
    unit: findInAddressComponents(result.address_components, "subpremise"),
    city:
      findInAddressComponents(result.address_components, "locality") ||
      findInAddressComponents(result.address_components, "administrative_area_level_3"),
    state: findInAddressComponents(result.address_components, "administrative_area_level_1") as StateCode,
    zip: findInAddressComponents(result.address_components, "postal_code"),
    country: findInAddressComponents(result.address_components, "country"),
    lat: result.geometry?.location.lat(),
    lng: result.geometry?.location.lng(),
    googlePlaceId: result.place_id,
  };
};

export const isValidResidentialProperty = (place: Place): boolean =>
  Boolean(place.streetNumber) && Boolean(place.street) && Boolean(place.city) && Boolean(place.state) && Boolean(place.zip);

import { Route, Routes, useNavigate } from "react-router-dom";

import {
  AdminDocumentTemplateDetail,
  NotFoundPage,
  AdminDocumentTemplates,
  AdminDocumentTemplateFields,
  AdminDocumentDefinedEnvelopes,
  AdminDocumentAutofillEntities,
} from "@/components/view";
import { FeatureFlag } from "@/types";
import { useFeature } from "@/contexts";
import { useEffect } from "react";

const DocumentsAdminRouter = () => {
  const releaseDocumentAutomation = useFeature(FeatureFlag.RELEASE_DOCUMENT_AUTOMATION);
  const navigate = useNavigate();

  useEffect(() => {
    if (releaseDocumentAutomation === false) navigate("/");
  }, [releaseDocumentAutomation]);

  return (
    <Routes>
      <Route path="/templates" element={<AdminDocumentTemplates />} />
      <Route path="/template-fields" element={<AdminDocumentTemplateFields />} />
      <Route path="/autofill-entities" element={<AdminDocumentAutofillEntities />} />
      <Route path="/defined-envelopes" element={<AdminDocumentDefinedEnvelopes />} />
      <Route path="/templates/:templateId" element={<AdminDocumentTemplateDetail />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default DocumentsAdminRouter;

import { useEffect, useState } from "react";
import { Template } from "@/types";
import { bridgeV2ApiInstance } from "@/services";
import { Blob } from "buffer";
import useToast from "@/hooks/useToast";
import { mapUnknownToAxiosError } from "@/utils";
import { CREATE_TEMPLATE, GET_ALL_TEMPLATES } from "@/services/routes";

export interface AddTemplateInput {
  name: string;
  description: string;
  file: Blob;
}

export interface AddTemplateFormData extends FormData {
  append(name: keyof AddTemplateInput, value: string | Blob): void;
}

export interface UseTemplatesHook {
  templates: Array<Template>;
  addTemplate: (input: AddTemplateFormData) => Promise<void>;
  loading: boolean;
}

const searchTemplates = (templates: Array<Template>, search: string) => {
  return templates.filter(
    (template) =>
      template.name.toLowerCase().includes(search.toLowerCase()) || template.description.toLowerCase().includes(search.toLowerCase())
  );
};

export const useTemplates = (search?: string): UseTemplatesHook => {
  const [loading, setLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<Array<Template>>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Array<Template>>([]);

  const { addToast } = useToast();

  const getTemplates = async () => {
    try {
      const response = await bridgeV2ApiInstance.get<Array<Template>>(GET_ALL_TEMPLATES);
      setTemplates(response.data);
      setLoading(false);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
    }
  };

  // Form data is necessary to upload the file with the backend middleware multer
  const addTemplate: UseTemplatesHook["addTemplate"] = async (formData: AddTemplateFormData) => {
    try {
      const response = await bridgeV2ApiInstance.post<Template>(CREATE_TEMPLATE, formData);
      setTemplates((existingTemplates) => [...existingTemplates, response.data]);
    } catch (e) {
      const error = mapUnknownToAxiosError(e);
      addToast({ message: error.response.data.message, type: "error" });
      throw error;
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (templates && search) {
      const searchedTemplates = searchTemplates(templates, search);
      setFilteredTemplates(searchedTemplates);
    }
  }, [search, templates]);

  return {
    templates: search ? filteredTemplates : templates,
    addTemplate,
    loading,
  };
};

import React, { ReactElement } from "react";
import { useTheme } from "@mui/material/styles";
import { CardContent, Grid, Tab, Box } from "@mui/material";
import { TabList, TabPanel } from "@mui/lab";

import { MainCard, SubCard } from "@/components/berry";
import { GRID_SPACING } from "@/config";
import { useConfig } from "@/hooks";

interface Props {
  verticalTabListCardTitle?: string;
  verticalLeftGridItemSize?: number;
  TabListComponent: ReactElement;
  BelowTabListComponent?: ReactElement;
  TabPanelComponent: ReactElement;
  orientation: "vertical" | "horizontal";
  currentTab: string;
  handleChangeTab: (_event: React.SyntheticEvent, id: string) => void;
}

//this allows the component to render in an empty state while still being complient with MUI
export const DOES_NOT_EXIST = "DOES_NOT_EXIST";

function TabsOrientation({
  orientation,
  TabListComponent,
  BelowTabListComponent = <span />,
  TabPanelComponent,
  verticalTabListCardTitle = "",
  verticalLeftGridItemSize = 6,
  currentTab,
  handleChangeTab,
}: Props) {
  const theme = useTheme();
  const { borderRadius } = useConfig();

  //JSX does not allow Component array in props.
  //This function extracts the array so MUI Tabs works correctly
  function ExtractChildren(Component: ReactElement): ReactElement | ReactElement[] {
    return Component?.props?.children ?? <span />;
  }

  if (orientation === "vertical") {
    return (
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <SubCard title={verticalTabListCardTitle} content={false}>
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={12} lg={verticalLeftGridItemSize}>
                <CardContent>
                  <TabList
                    // value={currentTab}
                    onChange={handleChangeTab}
                    orientation="vertical"
                    variant="scrollable"
                    sx={{
                      "& button": {
                        color: theme.palette.mode === "dark" ? theme.palette.grey[600] : theme.palette.grey[600],
                        minHeight: "auto",
                        minWidth: "100%",
                        py: 1.5,
                        px: 2,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        textAlign: "left",
                        justifyContent: "flex-start",
                        borderRadius: `${borderRadius}px`,
                      },
                      "& button.Mui-selected": {
                        color: theme.palette.primary.main,
                        background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.grey[200],
                      },
                      "& > div > span": {
                        display: "none",
                      },
                      ".MuiTabs-flexContainer": {
                        border: "none",
                      },
                      ".MuiTabs-flexContainerVertical > button": {
                        alignItems: "center",
                      },
                    }}
                  >
                    {ExtractChildren(TabListComponent)}
                    <Tab
                      sx={{ position: "fixed", top: "-9999px", left: "-9999px" }}
                      key={DOES_NOT_EXIST}
                      value={DOES_NOT_EXIST}
                      label="DOES_NOT_EXIST"
                    />
                  </TabList>
                  {BelowTabListComponent}
                </CardContent>
              </Grid>
              <Grid item xs={12} lg={12 - verticalLeftGridItemSize}>
                <CardContent
                  sx={{
                    borderLeft: "1px solid",
                    borderColor: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.grey[200],
                    height: "100%",
                  }}
                >
                  <TabPanel value={DOES_NOT_EXIST} />
                  {TabPanelComponent}
                </CardContent>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
    );
  }

  return (
    <MainCard>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <TabList
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            // value={currentTab}
            onChange={handleChangeTab}
            sx={{
              margin: "-12px -12px 12px",
            }}
          >
            {ExtractChildren(TabListComponent)}
            <Tab
              sx={{ position: "fixed", top: "-9999px", left: "-9999px" }}
              key={DOES_NOT_EXIST}
              value={DOES_NOT_EXIST}
              label="DOES_NOT_EXIST"
            />
          </TabList>
          {BelowTabListComponent}
          <Box>
            <TabPanel value={DOES_NOT_EXIST} />
            {TabPanelComponent}
          </Box>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default TabsOrientation;

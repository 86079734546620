import { TemplateFieldAudience } from "./TemplateField";

export enum EnvelopeStatus {
  EMPTY = "EMPTY",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_SEND = "READY_TO_SEND",
  DRAFTED = "DRAFTED",
  SENT = "SENT",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
}

export const EnvelopeStatusOrder: Record<EnvelopeStatus, number> = {
  [EnvelopeStatus.EMPTY]: 0,
  [EnvelopeStatus.IN_PROGRESS]: 1,
  [EnvelopeStatus.READY_TO_SEND]: 2,
  [EnvelopeStatus.DRAFTED]: 3,
  [EnvelopeStatus.SENT]: 4,
  [EnvelopeStatus.COMPLETED]: 5,
  [EnvelopeStatus.DECLINED]: 5,
  [EnvelopeStatus.EXPIRED]: 5,
  [EnvelopeStatus.DELETED]: 5,
};

export interface Envelope {
  id: string;
  created: string;
  name: string;
  creatorAccountId: string;
  status: EnvelopeStatus;
  personId: string;
}

export interface EnvelopeRecipient {
  id: string;
  created: string;
  envelopeId: string;
  audience: TemplateFieldAudience;
  name: string;
  email: string;
}

// material-ui
import { Button, CardActions, Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import { MainCard } from "@/components/berry";

// assets
import { FiberManualRecord } from "@mui/icons-material";

// =========================|| DATA WIDGET - INCOMING REQUESTS CARD ||========================= //

const IncomingRequests = () => (
  <MainCard title="Incoming Requests" content={false}>
    <PerfectScrollbar style={{ height: 290 }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "success.main" }} />
          </ListItemIcon>
          <ListItemText primary="Incoming requests" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "error.main" }} />
          </ListItemIcon>
          <ListItemText primary="You have 2 pending requests.." />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "warning.main" }} />
          </ListItemIcon>
          <ListItemText primary="You have 3 pending tasks" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "primary.main" }} />
          </ListItemIcon>
          <ListItemText primary="New order received" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "success.main" }} />
          </ListItemIcon>
          <ListItemText primary="Incoming requests" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "error.main" }} />
          </ListItemIcon>
          <ListItemText primary="You have 2 pending requests.." />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "warning.main" }} />
          </ListItemIcon>
          <ListItemText primary="You have 3 pending tasks" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemIcon>
            <FiberManualRecord sx={{ color: "primary.main" }} />
          </ListItemIcon>
          <ListItemText primary="New order received" />
        </ListItemButton>
      </List>
    </PerfectScrollbar>

    <Divider />
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Button variant="text" size="small">
        Show more
      </Button>
    </CardActions>
  </MainCard>
);

export default IncomingRequests;

import { FullScreenLoading, ManualBreadcrumbs } from "@/components/base";
import { useEnvelope, useEnvelopeRecipients, useEnvelopeStatus, useTemplateFills, useToast } from "@/hooks";
import { EnvelopeStatus, EnvelopeStatusOrder, Person } from "@/types";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EnvelopeDetailHeader from "./EnvelopeDetailHeader";
import { EnvelopeDocumentsTable } from "./EnvelopeDocumentsTable";
import { EnvelopeRecipients } from "./EnvelopeRecipients";
import SendToDocusignBanner from "./SendToDocusignBanner";
import { DocusignEnvelopeDetail } from "./DocusignEnvelopeDetail";
import { EnvelopeAutofillDetail } from "./EnvelopeAutofillDetail";

interface Props {
  person: Person;
}

function EnvelopeDetail({ person }: Props) {
  const { envelopeId } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const useEnvelopeInstance = useEnvelope(envelopeId);
  const { envelope, loading } = useEnvelopeInstance;

  const useTemplateFillsInstance = useTemplateFills(envelope?.id);
  const useEnvelopeRecipientsInstance = useEnvelopeRecipients(envelope?.id);

  const envelopeStatus = useEnvelopeStatus({
    envelope,
    templateFills: useTemplateFillsInstance.templateFills,
    envelopeRecipients: useEnvelopeRecipientsInstance.envelopeRecipients,
  });

  const isReadOnly: boolean = useMemo(() => {
    return EnvelopeStatusOrder[envelopeStatus] >= EnvelopeStatusOrder[EnvelopeStatus.DRAFTED];
  }, [envelopeStatus]);

  useEffect(() => {
    if (!envelope && !loading) {
      addToast({
        message: "Envelope not found",
        type: "error",
      });
      navigate("/");
    }
  }, [envelope, loading]);

  if (loading || !envelope) return <FullScreenLoading height="200px" />;

  return (
    <>
      <ManualBreadcrumbs
        isHomeIncluded={false}
        title
        links={[
          {
            title: person.nameFirst + "'s Envelopes",
            url: `/client/${person.id}/envelopes`,
          },
          {
            title: envelope.name,
            url: `/client/${person.id}/envelopes/${envelopeId}`,
          },
        ]}
      />
      <Box sx={{ mt: 1 }} />
      <EnvelopeDetailHeader
        templateFills={useTemplateFillsInstance.templateFills}
        envelopeRecipients={useEnvelopeRecipientsInstance.envelopeRecipients}
        useEnvelopeInstance={useEnvelopeInstance}
      />
      {useEnvelopeInstance.currentExternalEnvelope && (
        <>
          <Box sx={{ mt: 1 }} />
          <DocusignEnvelopeDetail useEnvelopeInstance={useEnvelopeInstance} />
        </>
      )}
      {envelopeStatus === EnvelopeStatus.READY_TO_SEND && (
        <>
          <Box sx={{ mt: 1 }} />
          <SendToDocusignBanner useEnvelopeInstance={useEnvelopeInstance} />
        </>
      )}
      {!!useTemplateFillsInstance.templateFills.length &&
        EnvelopeStatusOrder[envelopeStatus] < EnvelopeStatusOrder[EnvelopeStatus.DRAFTED] && (
          <>
            <Box sx={{ mt: 1 }} />
            <EnvelopeAutofillDetail
              personId={person.id}
              templateIds={useTemplateFillsInstance.templateFills.map((tfill) => tfill.templateId)}
            />
          </>
        )}
      <Box sx={{ mt: 1 }} />
      <EnvelopeRecipients isReadOnly={isReadOnly} useEnvelopeRecipientsInstance={useEnvelopeRecipientsInstance} />
      {EnvelopeStatusOrder[envelopeStatus] < EnvelopeStatusOrder[EnvelopeStatus.DRAFTED] && (
        <>
          <Box sx={{ mt: 1 }} />
          <EnvelopeDocumentsTable isReadOnly={isReadOnly} useTemplateFillsInstance={useTemplateFillsInstance} />
        </>
      )}
    </>
  );
}

export default EnvelopeDetail;

import { ExpectedPayment } from "@/types";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { displayUsCents } from "@/utils";
import moment from "moment";
import ExpectedPaymentForm from "./ExpectedPaymentForm";
import { Edit } from "@mui/icons-material";
import { UsePaymentPlans } from "@/hooks";

interface Props {
  expectedPayment: ExpectedPayment;
  deleteExpectedPaymentById: UsePaymentPlans["deleteExpectedPaymentById"];
  updateExpectedPayment: UsePaymentPlans["updateExpectedPayment"];
}

const ExpectedPaymentRow = ({ expectedPayment, deleteExpectedPaymentById, updateExpectedPayment }: Props) => {
  const [editing, setEditing] = useState(false);

  if (editing)
    return (
      <Grid item xs={12} container sx={{ marginY: "24px" }}>
        <ExpectedPaymentForm
          deleteExpectedPaymentById={deleteExpectedPaymentById}
          updateExpectedPayment={updateExpectedPayment}
          onCancel={() => setEditing(false)}
          planId={expectedPayment.planId}
          expectedPayment={expectedPayment}
        />
      </Grid>
    );

  return (
    <Grid item xs={12} justifyContent="space-between" container>
      <Grid item xs={4}>
        <Typography>
          <b>Payment Date: </b> {moment(expectedPayment.paymentDate).format("M/D/YY")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          <b>Payment Amount: </b> {displayUsCents(expectedPayment.paymentAmountCents)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <ButtonBase onClick={() => setEditing(true)}>
          <Edit fontSize="small" />
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default ExpectedPaymentRow;

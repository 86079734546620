// Leverages https://www.npmjs.com/package/react-google-autocomplete

import { env } from "@/env";
import { Place } from "@/types";
import { ReactGoogleAutocompleteInputProps, usePlacesWidget } from "react-google-autocomplete";
import { isValidResidentialProperty, placeFromGooglePlaceResult } from "./util";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { IconX } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks";

interface Props {
  handlePlaceSelected: (place: Place) => void;
  handleClear: () => void;
  reactGoogleAutocompleteProps?: Omit<ReactGoogleAutocompleteInputProps, "apiKey" | "onPlaceSelected" | "defaultValue">;
  defaultValue?: string;
}

function LocationAutocompleteInput({ handlePlaceSelected, reactGoogleAutocompleteProps, handleClear, defaultValue }: Props) {
  const [value, setValue] = useState<string>("");

  const { addToast } = useToast();

  const { ref } = usePlacesWidget({
    apiKey: env.GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      const mappedPlace = placeFromGooglePlaceResult(place);
      if (!isValidResidentialProperty(mappedPlace)) {
        addToast({
          type: "error",
          message: "Please select a valid residential address.",
        });
        return;
      }
      handlePlaceSelected(mappedPlace);
      setValue(place.formatted_address || "");
    },
    options: {
      types: ["address"], // Update to '(cities)' to restrict to cities only
      ...reactGoogleAutocompleteProps?.options,
    },
    ...reactGoogleAutocompleteProps,
  });

  const onClear = () => {
    setValue("");
    handleClear();
  };

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      inputRef={ref}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: Boolean(value) && (
          <InputAdornment position="end">
            <IconButton onClick={onClear}>
              <IconX />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default LocationAutocompleteInput;

// import { CLIENT_MENU_ITEMS } from "@/constant";
import { useSelector } from "@/store";
import { NavItemType, OverrideIcon, ProgramClient } from "@/types";
// import useLocalStorage from "./useLocalStorage";
import { ClientMenuItem } from "@/types/menu";
import {
  AccountBalance,
  Payments,
  CreditScore,
  Route,
  Description,
  Calculate,
  Checklist,
  Home,
  Folder,
  Inventory,
  Person,
  Savings,
} from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { getProgramClientsByPersonId } from "./data-hooks";
import { TypeOfLandisProgram, StatusOfLandisProgram } from "@l4s/program-models";

interface UseClientMenuItems {
  // initializeGlobalClientMenuItems: () => (dispatch: AppDispatch) => void;
  clientMenu: NavItemType;
}

enum ClientMenuTopLevelTab {
  // ALL
  MILESTONES = "MILESTONES",

  // Rental Programs
  APPLICATION = "APPLICATION",
  REMEDIATION = "REMEDIATION",
  BUDGET_CALCULATOR = "BUDGET_CALCULATOR",
  PROPERTIES = "PROPERTIES",
  DOCUMENTS = "DOCUMENTS",

  // Coaching
  GOALS = "GOALS",
  CREDIT_DASHBOARD = "CREDIT_DASHBOARD",
  FINANCIAL_INSIGHTS = "FINANCIAL_INSIGHTS",
}

async function getProgramMenuItemsForProgramClient(programClient: ProgramClient, personId: string): Promise<Array<NavItemType>> {
  const clientMenuTopLevelTabs = {
    [ClientMenuTopLevelTab.MILESTONES]: {
      id: "milestones",
      title: <span>Milestones</span>,
      icon: Route,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/milestones`,
    },
    [ClientMenuTopLevelTab.APPLICATION]: {
      id: "application",
      title: <span>Application</span>,
      icon: Description,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/application`,
    },
    [ClientMenuTopLevelTab.REMEDIATION]: {
      id: "remediation",
      title: <span>Rent & Collections</span>,
      icon: Payments,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/remediation`,
    },
    [ClientMenuTopLevelTab.BUDGET_CALCULATOR]: {
      id: "budgetCalculator",
      title: <span>Budget Calculator</span>,
      icon: Calculate,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/budget-calculator`,
    },
    [ClientMenuTopLevelTab.GOALS]: {
      id: "coachingGoals",
      title: <span>Goals Planning</span>,
      icon: Checklist,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/goals`,
    },
    [ClientMenuTopLevelTab.CREDIT_DASHBOARD]: {
      id: "creditDashboard",
      title: <span>Credit Dashboard</span>,
      icon: CreditScore,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/credit-dashboard`,
    },
    [ClientMenuTopLevelTab.FINANCIAL_INSIGHTS]: {
      id: "financialInsights",
      title: <span>Financial Insights</span>,
      icon: AccountBalance,
      type: "item",
      url: `/client/${personId}/program-client/${programClient.id}/financial-insights`,
    },

    [ClientMenuTopLevelTab.PROPERTIES]: {
      id: "properties",
      title: <span>Properties</span>,
      icon: Home,
      type: "item",
      // breadcrumbs: false,
      url: `/client/${personId}/program-client/${programClient.id}/properties`,
    },
    // [ClientMenuTopLevelTab.DOCUMENTS]: {
    //   id: "documents",
    //   title: <span>Documents</span>,
    //   icon: Folder,
    //   type: "item",
    //   url: `/client/${personId}/program-client/${programClient.id}/documents`,
    // },
  };

  let tabs = [];

  // Get tabs based on type of program
  if (
    [
      TypeOfLandisProgram.RENTAL,
      TypeOfLandisProgram.LANDIS,
      TypeOfLandisProgram.LANDIS_FLEXIBLE,
      TypeOfLandisProgram.LANDIS_FLEXIBLE_WITH_SAVINGS,
    ].includes(programClient.programDetails.type)
  ) {
    tabs = [
      ClientMenuTopLevelTab.MILESTONES,
      ClientMenuTopLevelTab.APPLICATION,
      ClientMenuTopLevelTab.REMEDIATION,
      ClientMenuTopLevelTab.BUDGET_CALCULATOR,
      ClientMenuTopLevelTab.PROPERTIES,
      // ClientMenuTopLevelTab.DOCUMENTS,
    ];
  } else if (programClient.programDetails.type === TypeOfLandisProgram.COACHING) {
    tabs = [
      ClientMenuTopLevelTab.MILESTONES,
      ClientMenuTopLevelTab.CREDIT_DASHBOARD,
      ClientMenuTopLevelTab.FINANCIAL_INSIGHTS,
      ClientMenuTopLevelTab.GOALS,
    ];
  } else if (programClient.program === TypeOfLandisProgram.COACHING_ONLY) {
    tabs = [
      ClientMenuTopLevelTab.MILESTONES,
      ClientMenuTopLevelTab.CREDIT_DASHBOARD,
      ClientMenuTopLevelTab.FINANCIAL_INSIGHTS,
      ClientMenuTopLevelTab.GOALS,
      ClientMenuTopLevelTab.APPLICATION,
    ];
  } else if (programClient.programDetails.type === TypeOfLandisProgram.ONBOARDING) {
    tabs = [ClientMenuTopLevelTab.MILESTONES];
  }

  return tabs.map((tab) => clientMenuTopLevelTabs[tab]);
}

const getProgramIcon = (programClient: ProgramClient): OverrideIcon => {
  switch (programClient.programDetails.type) {
    case TypeOfLandisProgram.ONBOARDING:
      return Person;
    case TypeOfLandisProgram.COACHING:
      return Savings;
    default:
      return Home;
  }
};

async function getProgramMenuForProgramClient(programClient: ProgramClient, clientMenuItem: ClientMenuItem): Promise<unknown> {
  return {
    id: programClient.id,
    title: (
      <Tooltip title={programClient.programDetails.name} placement="right">
        <Typography style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {programClient.programDetails.name} </Typography>
      </Tooltip>
    ),
    type: "collapse",
    icon: getProgramIcon(programClient),
    children: await getProgramMenuItemsForProgramClient(programClient, clientMenuItem.personId),
  };
}

const getClientSubRoutes = async (clientMenuItem: ClientMenuItem): Promise<Array<NavItemType>> => {
  const programClients = await getProgramClientsByPersonId(clientMenuItem.personId);

  programClients.sort((a, b) => {
    if (a.created > b.created) return -1;
    if (a.created < b.created) return 1;
    return 0;
  });

  const activePrograms: Array<ProgramClient> = [];
  const inactivePrograms: Array<ProgramClient> = [];

  for (const programClient of programClients) {
    if (programClient.status === StatusOfLandisProgram.ACTIVE) {
      activePrograms.push(programClient);
    } else {
      inactivePrograms.push(programClient);
    }
  }

  const [activeProgramMenus, inactiveProgramMenus] = await Promise.all([
    Promise.all(activePrograms.map((programClient) => getProgramMenuForProgramClient(programClient, clientMenuItem))),
    Promise.all(inactivePrograms.map((programClient) => getProgramMenuForProgramClient(programClient, clientMenuItem))),
  ]);

  if (inactivePrograms.length) {
    activeProgramMenus.push({
      id: "inactivePrograms",
      title: "Inactive Programs",
      type: "collapse",
      icon: Inventory,
      children: inactiveProgramMenus,
    });
  }

  return activeProgramMenus;
};

const getClientRoute = async (clientMenuItem: ClientMenuItem): Promise<NavItemType> => ({
  id: clientMenuItem.personId,
  title: clientMenuItem.name,
  type: "collapse",
  initials: true,
  clientDeletable: true,
  children: await getClientSubRoutes(clientMenuItem),
});

export function useClientMenuItems(): UseClientMenuItems {
  // const [initialized, setInitialized] = useState(false);
  // const [storedClientMenuItems, setStoredClientMenuItems] = useLocalStorage<Array<ClientMenuItem>>(CLIENT_MENU_ITEMS, []);
  const clientMenuItems = useSelector((state) => state.clientMenuItems);
  const [children, setChildren] = useState<Array<NavItemType>>([]);

  const clientMenu = useMemo<NavItemType>(() => {
    return {
      id: "client-list",
      caption: clientMenuItems?.length ? null : (
        <span>
          <i>No clients saved.</i>
        </span>
      ),
      type: "group",
      children,
    };
  }, [clientMenuItems, children]);

  // useEffect(() => {
  //   if (initialized) {
  //     setStoredClientMenuItems(clientMenuItems);
  //   }
  // }, [clientMenuItems, initialized]);

  const composeChildren = async (menuItems: Array<ClientMenuItem>) => {
    const navChildren = await Promise.all(menuItems.map((clientMenuItem) => getClientRoute(clientMenuItem)));
    setChildren(navChildren);
  };

  useEffect(() => {
    composeChildren(clientMenuItems);
  }, [clientMenuItems]);

  // const initializeGlobalClientMenuItems = () => () => {
  //   dispatch(setClientMenuItems(storedClientMenuItems));
  //   setInitialized(true);
  // };

  // const addClientMenuItem = (clientMenuItem: ClientMenuItem) => () => {
  //   if (clientMenuItems.filter((item: ClientMenuItem) => item.personId === clientMenuItem.personId).length) return;
  //   dispatch(setClientMenuItems([...clientMenuItems, clientMenuItem]));
  // };

  // const removeClientMenuItem = (clientMenuItemId: string) => () => {
  //   const filtered = clientMenuItems.filter((item: ClientMenuItem) => item.personId !== clientMenuItemId);
  //   dispatch(setClientMenuItems(filtered));
  // };

  return {
    // initializeGlobalClientMenuItems,
    clientMenu,
  };
}
